/* eslint-disable no-useless-concat */
import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Warning } from 'icons/warning.svg';
import { ReactComponent as Information } from 'icons/info.svg';
import { showDownloadTabInsideExport } from 'store/actions/toolkit-export-panel-actions';
import { getStore } from 'store/store';
import {
  REPORT_DOWNLOAD_PROCESSED,
  REPORT_DOWNLOAD_PROCESSING,
} from 'models/api/toolkit-export-download-list-model';
import { REPORT_TYPES } from 'store/actions/statement-list/statement-list-actions';
import {
  downloadElementReportFromGuid,
  downloadReportFromGuid,
} from 'api/statement-list-api';
import { BLACKLINE_VIEW_STATUS } from 'constants/feature/statement-content-constants';
import { getConfigForBatchUpdateLimitRequest } from 'api/statement-content-api';
import {
  MAX_ELEMENTS_FOR_BATCH,
  SESSION_STORAGE_KEY_FOR_BATCH_UPDATE_CONFIG,
} from 'constants/feature/batch-panel-constants';
import ElementDetails from 'models/api/element-details-api-model';

const STATEMENT_PAGE_BLOCK = 'statement-page';
const BANNER_ICON_SIZE = '18px';

// Tieout 7.2 release date
const releaseDate = new Date('2024-09-20');

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  PROCESSED: 'processed',
};

// This function is used to identify the reason - why the statement is read only.
// Should be called only you are sure the statement is read-only
// We have a function to identify if the statement is read only.

export const getReadOnlyBannerId = (
  selectedStatement,
  urlParams,
  workflowsMap,
) => {
  if (selectedStatement.isPendingApproval()) {
    return 'selected-statement.read-only-banner.pending-approval-state';
  } else if (selectedStatement.isWrappedUp()) {
    return 'selected-statement.read-only-banner.wrapped-up-statement';
  } else if (selectedStatement.isSoftDeleted()) {
    return 'selected-statement.read-only-banner.soft-deleted';
  } else if (selectedStatement.statementIsOldRevisions()) {
    if (
      workflowsMap.statementHasProcessingRevision({
        statementId: urlParams.statementId,
      })
    ) {
      return 'selected-statement.read-only-banner.revision-currently-processed';
    } else {
      return 'selected-statement.read-only-banner.previously-uploaded';
    }
  } else {
    return 'selected-statement.read-only-banner';
  }
};

export const downloadReportNotification = (reportType, notificationType) => {
  const { dispatch } = getStore();
  switch (notificationType) {
    case NOTIFICATION_TYPE.FAILURE:
      toast.error(
        <>
          <Warning
            name={'warning'}
            className={`${STATEMENT_PAGE_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage
            id="notifications.report-download-failed.message"
            values={{ reportType: reportType }}
          />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
      break;
    case NOTIFICATION_TYPE.SUCCESS:
      toast.success(
        <>
          <Information
            name={'information'}
            className={`${STATEMENT_PAGE_BLOCK}__info_icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage
            id="notifications.report-download-in-progress.message"
            values={{ reportType: reportType }}
          />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
      break;
    case NOTIFICATION_TYPE.PROCESSED:
      toast.success(
        <>
          <Information
            name={'information'}
            className={`${STATEMENT_PAGE_BLOCK}__info_icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage
            id="notifications.report-download-ready.message"
            values={{
              downloadButton: (...chunks) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => dispatch(showDownloadTabInsideExport())}
                  target="_blank"
                  className={'download-ready-message'}
                >
                  <FormattedMessage id="common.downloads" />
                </a>
              ),
            }}
          />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
      break;
    default:
      return null;
  }
};

export const getDownloadDataExceptFailed = (reportList) => {
  return (
    Array.isArray(reportList) &&
    reportList.filter(
      (downloadObj) =>
        downloadObj &&
        (downloadObj.downloadStatus === REPORT_DOWNLOAD_PROCESSED ||
          downloadObj.downloadStatus === REPORT_DOWNLOAD_PROCESSING),
    )
  );
};

export const getListOfProcessedAndNotDownloadedReports = (reportList) =>
  Array.isArray(reportList) &&
  reportList.filter(
    (downloadObj) =>
      downloadObj &&
      downloadObj.downloadStatus === REPORT_DOWNLOAD_PROCESSED &&
      downloadObj.userDownloadedReportStatus !== true,
  );

export const getExportReportType = (reportType) => {
  switch (reportType) {
    case 'WORKPAPER':
      return REPORT_TYPES.WORKPAPER;
    case 'ELEMENTREPORT':
      return REPORT_TYPES.ELEMENT_REPORT;
    case 'CLIENT_NOTES':
      return REPORT_TYPES.CLIENT_NOTES;
    case 'CUSTOMREPORT':
      return REPORT_TYPES.BUILD_CUSTOMIZED_REPORT;
    case 'AUDIT_TRAIL':
      return REPORT_TYPES.AUDIT_TRAIL_REPORT;
    case 'COMFORTLETTERREPORT':
      return REPORT_TYPES.COMFORT_LETTER_REPORT;
    default:
      return reportType;
  }
};

export const downloadReportFromGuidApiSelector = async ({
  revisionId,
  fileName,
  storageGuid,
  userDownloadedReportStatus,
  reportType,
  id,
}) => {
  let apiResponse;
  switch (reportType) {
    case REPORT_TYPES.ELEMENT_REPORT:
      apiResponse = await downloadElementReportFromGuid({
        revisionId,
        fileName,
        storageGuid,
        userDownloadedReportStatus,
        reportId: id,
      });
      break;
    default:
      apiResponse = await downloadReportFromGuid({
        revisionId,
        fileName,
        storageGuid,
        userDownloadedReportStatus,
      });
  }

  return apiResponse;
};

export const blacklineTogleNotification = (notificationType) => {
  switch (notificationType) {
    case BLACKLINE_VIEW_STATUS.FAILED:
      toast.error(
        <>
          <Warning
            name={'warning'}
            className={`${STATEMENT_PAGE_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage id="notifications.blackline-generation-failed.message" />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
      break;
    case BLACKLINE_VIEW_STATUS.COMPLETED:
      toast.success(
        <>
          <Information
            name={'information'}
            className={`${STATEMENT_PAGE_BLOCK}__info_icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage id="notifications.blackline-generation-success.message" />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
      break;
    case BLACKLINE_VIEW_STATUS.BLK_REQ_RECEIVED:
      toast.success(
        <>
          <Information
            name={'information'}
            className={`${STATEMENT_PAGE_BLOCK}__info_icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage id="notifications.blackline-generation-request-received.message" />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
      break;
    case BLACKLINE_VIEW_STATUS.GENERATING:
      toast.warn(
        <>
          <Warning
            name={'warning'}
            className={`${STATEMENT_PAGE_BLOCK}__info_icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage id="notifications.blackline-generation-inprogress.message" />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
      break;
    default:
      return null;
  }
};

export const setSideBySideViewParams = (
  selectedProject,
  selectedStatement,
  selectedStatementRevision,
  statementProcessType,
) => {
  let sourceStatementParams = {};
  sourceStatementParams.projectId = selectedProject;
  sourceStatementParams.statementId = selectedStatement;
  sourceStatementParams.revisionId = selectedStatementRevision;
  sourceStatementParams.readOnly = false;
  sourceStatementParams.documentProcessType = statementProcessType;
  return sourceStatementParams;
};

export const getGeoByStatementId = (statementId) => {
  const store = getStore();
  const { data: { statementList, currentUser } = {} } = store.getState();
  const { projectToGeoMap } = currentUser && currentUser.data;
  const { statementList: { data } = {} } = statementList || {};
  const statement = data.statements.filter((item) => item.id === statementId);
  const [{ clientId } = {}] = statement || [];
  return projectToGeoMap.get(`${clientId}`).geoCode;
};

/** The below 3 generic function which is used configure full userName as "lastName, firstName"
 * which is used across the apprlication.
 * getUserNameByUserId
 * getUserNameByUserData
 * getUserNameByUserDataObject
 */
//this function is used to configure full userName based on userId from project users lists of redux store
export const getUserNameByUserId = (id) => {
  const store = getStore();
  const { projectUsersList } = store.getState().data;
  const userName =
    projectUsersList &&
    projectUsersList.data &&
    projectUsersList.data.users &&
    projectUsersList.data.users.find((item) => item.id === id);
  return userName && userName.preferredFullName;
};

//this function is used to configure full userName based on userId from lists of users
export const getUserNameByUserData = (id, usersData) => {
  const userName = usersData && usersData.filter((item) => item.id === id)[0];
  return userName && userName.preferredFullName;
};

//this function is used to configure full userName based revision history object which is used in revision history model
export const getUserNameByUserDataObject = (userDataObject) => {
  return userDataObject && userDataObject.preferredFullName;
};

//this function is used to fetch batch update limit object fron session store
export const getConfigForBatchUpdateFromSessionStore = () =>
  JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEY_FOR_BATCH_UPDATE_CONFIG),
  );

//this function is used to fetch element batch update limit fron session store
export const getMaxElementForBatchUpdateLimit = () => {
  const bacthConfigObject = getConfigForBatchUpdateFromSessionStore();
  if (
    bacthConfigObject &&
    bacthConfigObject.data &&
    parseInt(bacthConfigObject.data.elementBatch)
  )
    return parseInt(bacthConfigObject.data.elementBatch);
  return MAX_ELEMENTS_FOR_BATCH;
};

export const fetchAndUpdateBatchLimit = async () => {
  const batchConfigResponse = getConfigForBatchUpdateFromSessionStore();

  // if batch update limit is not present in session store, then /tieout/batch/config call will be triggered and will store in session store.
  if (!(batchConfigResponse && batchConfigResponse.data)) {
    try {
      const response = await getConfigForBatchUpdateLimitRequest();
      const batchConfigData = response &&
        response.data && {
          data: response.data,
        };

      sessionStorage.setItem(
        SESSION_STORAGE_KEY_FOR_BATCH_UPDATE_CONFIG,
        JSON.stringify(batchConfigData),
      );
    } catch (e) {
      console.error(e);
    }
  }
};

export const getSystemUserOrUserNameByUserId = (entry) => {
  return entry.changedBy < 0
    ? entry.changedByName
    : getUserNameByUserId(entry.changedBy);
};

export const hasComfortLetterExistInBatchUpdate = (
  comfortLetterId,
  selectedComfortLetterList,
) => {
  return selectedComfortLetterList.filter((item) => {
    return item.comfortLetterId === comfortLetterId;
  }).length;
};

export const isStatementExistsInPinnedDetails = (statementId) => {
  const store = getStore();
  const {
    pinnedDetails,
    selectedProject: { id },
  } = store.getState().data;

  return pinnedDetails.pinnedStatements.filter(
    (item) =>
      item.clientId === parseInt(id) && item.statementId === statementId,
  ).length;
};

export const shouldRestrictAutomaticIdentifier = () => {
  const store = getStore();
  const { revision } = store.getState().data;
  //statement falls behind the restrictedDate it return true else false
  return releaseDate > new Date(revision.processedDate);
};

// return true if element has any annotations
export const isElementHasAnyAnnotations = (element) => {
  return new ElementDetails().isElementHasAnnotation(element);
};

// return elementDetails based on previous and new updated elements data and updates the marker and callout values for element
export const isElementAnnotationChanged = (prevElementData, newElementData) => {
  const prevElement = new ElementDetails().setLoadedFromElementData({
    data: prevElementData,
  });
  const newElement = new ElementDetails().setLoadedFromElementData({
    data: newElementData,
  });
  if (prevElement.isIncluded === newElement.isIncluded) {
    if (
      prevElement.hasFootedFormula() === newElement.hasFootedFormula() ||
      prevElement.hasRecalcFormula() === newElement.hasRecalcFormula() ||
      prevElement.hasCrossFootedFormula() ===
        newElement.hasCrossFootedFormula() ||
      prevElement.internalReferenceGroupName ===
        newElement.internalReferenceGroupName ||
      prevElement.workpaperReferenceNumberList.length ===
        newElement.workpaperReferenceNumberList.length ||
      prevElement.comfortLetterLabelList.length ===
        newElement.comfortLetterLabelList.length ||
      prevElement.tickmarks === newElement.tickmarks
    ) {
      return {
        ...newElementData,
        marker: prevElement.marker,
        callout: prevElement.callout,
      };
    } else {
      return {
        ...newElementData,
        marker: false,
        callout: true,
      };
    }
  }
  return { ...newElementData, marker: false, callout: false };
};

// return the updated webSocketPayload based on the annotations update of the element/s
export const getWebsocketElementsForCalloutChange = (
  elementCache,
  webSocketPayload,
) => {
  const webSocketPayloadMap = webSocketPayload.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const prevElementList = elementCache.getElementsListByElementIds({
    elementIds: Object.keys(webSocketPayloadMap),
  });

  const webSocketElementsData = prevElementList.map((item) =>
    isElementAnnotationChanged(item, webSocketPayloadMap[item.id]),
  );

  return webSocketElementsData;
};

export const getSectionNameBySectionId = (sectionId) => {
  const { statementOCRNavigation } =
    getStore().getState().ui.statementPage.statementNavigatorPanel;
  const { navigationData } = statementOCRNavigation;
  const sectionName = navigationData.filter(
    (item) => item.sectionId === sectionId,
  );
  return sectionName.length ? sectionName[0].sectionName : '';
};

export const shouldShowOptionsInOmniaFileShareModal = () => {
  const store = getStore();
  const { selectedStatement } = store.getState().data;

  const currentDate = new Date();
  const releaseDate = new Date('2024-09-20'); // Tieout 7.2 release Date

  // returns only true when selected statement falls ahead of the date of the 7.2 release date or else returns false
  return !selectedStatement.isWrappedUp() && currentDate > releaseDate;
};

export const getElementDataFromElementCache = ({ elementId, sectionId }) => {
  const store = getStore();
  const {
    statementContent: { elementCache },
  } = store.getState().data;

  return elementCache.getElement({ elementId, sectionId });
};
