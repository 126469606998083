import { createAction } from 'redux-actions';
import {
  getElementDetailsRequest,
  updateElementRequest,
  excludeElementFromElementListRequest,
  getHistoryForElementRequest,
} from 'api/element-api';
import {
  getFormulasForElementRequest,
  deleteFormulaRequest,
} from 'api/formula-api';
import {
  clearSelectedElementsContentPanel,
  fetchClientEntityList,
} from 'store/actions/statement-content-actions';

import {
  updateElementCacheByCalloutAction,
  updateElementInCacheAction,
} from 'store/actions/element-cache-actions';
import {
  getElementInternalReference,
  getInternalReferenceWithoutLoading,
} from 'store/actions/internal-reference-actions';

import {
  getWorkpapersForElementRequest,
  detachElementWorkpaperRequest,
  updateWorkpaperRequest,
} from 'api/workpaper-api';

import { replaceSelectedElementsContentPanel } from 'store/actions/statement-content-actions';
import {
  getElementTickmarksRequest,
  attachTickmarkToElementRequest,
  detachTickmarkFromElementRequest,
} from 'api/tickmark-api';
import { updateCacheWithMostRecentElementChanges } from 'store/actions/element-changes-since-tracker-actions';
import {
  fetchSectionHTMLSegmentsStatementContent,
  fetchElementsBySection,
  selectElementContentPanel,
  sectionHTMLSegmentsStatementContentLoading,
  sectionHTMLSegmentsStatementContentLoadedWithoutResponse,
} from 'store/actions/statement-content-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import {
  linkElementInComfortLetterElementsMapAndUpdateCountAction,
  unlinkElementInComfortLetterElementsMapAndUpdateCountAction,
  updateComfortLettersElementsMapArray,
} from './comfort-annotations-list-actions';

import {
  showElementPanelAction,
  hideElementPanelAction,
} from './panel-controller-actions';
import { clearBatchSelectedElementsAction } from './batch-panel-actions';
import { updateElementsSearchByIds } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { getElementHightlightState } from 'constants/feature/statement-content-constants';
import {
  excludeElementFromElementsMapAction,
  fetchTickmarkListForRevisionWithoutLoading,
} from 'store/actions/tickmark-list-panel-actions';
import {
  updateWorkpaperElementsMap,
  unlinkElementFromElementsMap,
  updateWorkpaperElementsMapFromArray,
  removeElementFromElementsMap,
  updateWorkpaperWithSocketPayloadAction,
} from 'store/actions/workpaper-toolkit-list-actions';
import {
  updateTickmarkElementsMap,
  fetchElementsFromTickmark,
  updateTickmarkElementsMapFromArray,
  excludeElementFromTickmarkElementsMap,
} from 'store/actions/tickmark-list-panel-actions';
import { LEFT_PANELS, RIGHT_PANELS } from 'constants/feature/panel-constants';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { fetchSuggestedListed } from 'store/actions/internal-reference-suggestion-list-actions';
import { ELEMENT_ANNOTATIONS_TABS } from 'constants/feature/element-panel-constants';
import { cancelCopyFormula } from './copy-formula-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  attachComfortLetterToElementRequest,
  detachComfortLetterToElementRequest,
  getComfortLetterForElementRequest,
} from 'api/comfort-letter-api';
import { isArray } from 'lodash';

export const removeWPFromElementPanelAction = createAction(
  'REMOVE_WORKPAPER_FROM_ELEMENT_PANEL_ACTION',
);

export const updateWPFromElementPanelAction = createAction(
  'UPDATE_WORKPAPER_FROM_ELEMENT_PANEL_ACTION',
);
export const updateTickmarkInElementPanelWithSocketPayloadAction = createAction(
  'UPDATE_TICKMARK_IN_ELEMENT_PANEL_ACTION',
);

export const removeTickmarkFromElementPanelWithSocketPayloadAction =
  createAction('REMOVE_TICKMARK_FROM_ELEMENT_PANEL_ACTION');

export const elementTickmarkListFromWebSocketPayloadAction = createAction(
  'SET_ELEMENT_TICKMARK_LIST_FROM_WEB_SOCKET_ACTION',
);

export const removeTickmarkWithSocketPayloadInBulk = createAction(
  'REMOVE_TICKMARK_WITH_SOCKET_PAYLOAD_IN_BULK',
);

export const elementTickmarkListLoadedWithoutResponse = createAction(
  'ELEMENT_TICKMARK_LIST_LOADED_WITHOUT_RESPONSE',
);

export const elementDetailsLoading = createAction('ELEMENT_DETAILS_LOADING');
export const elementDetailsLoaded = createAction('ELEMENT_DETAILS_LOADED');
export const elementDetailsError = createAction('ELEMENT_DETAILS_ERROR');

export const updateElementVerify = createAction('UPDATE_ELEMENT_VERIFY');
export const updateElementReview = createAction('UPDATE_ELEMENT_REVIEW');
export const updateElementUserFlag = createAction('UPDATE_ELEMENT_USER_FLAG');
export const updateElementOverrideSystemFlag = createAction(
  'UPDATE_ELEMENT_OVERRIDE_SYSTEM_FLAG',
);

export const updateElementComfortAssignAnnotation = createAction(
  'UPDATE_ELEMENT_COMFORT_ASSIGN_ANNOTATION',
);

export const updateElementScaling = createAction('UPDATE_ELEMENT_SCALING');
export const updateElementUnits = createAction('UPDATE_ELEMENT_UNITS');
export const updateElementPeriod = createAction('UPDATE_ELEMENT_PERIOD');

export const updateElementEntity = createAction(
  'UPDATE_ELEMENT_ATTRIBUTE_ENTITY',
);

export const elementFormulaListLoading = createAction(
  'ELEMENT_FORMULA_LIST_LOADING',
);
export const elementFormulaListLoaded = createAction(
  'ELEMENT_FORMULA_LIST_LOADED',
);
export const elementFormulaListError = createAction(
  'ELEMENT_FORMULA_LIST_ERROR',
);

export const deleteFormulaError = createAction('DELETE_FORMULA_ERROR');

export const elementWorkpaperListLoading = createAction(
  'ELEMENT_WORKPAPER_LIST_LOADING',
);
export const elementWorkpaperListLoaded = createAction(
  'ELEMENT_WORKPAPER_LIST_LOADED',
);
export const elementWorkpaperListLoadedWithoutResponse = createAction(
  'ELEMENT_WORKPAPER_LIST_LOADED_WITHOUT_RESPONSE',
);
export const elementWorkpaperListError = createAction(
  'ELEMENT_WORKPAPER_LIST_ERROR',
);

export const elementWorkpaperListFromWebSocketPayloadAction = createAction(
  'SET_WORKPAPER_LIST_FROM_WEB_SOCKET_ACTION',
);

export const elementTickmarkListLoading = createAction(
  'ELEMENT_TICKMARK_LIST_LOADING',
);
export const elementTickmarkListLoaded = createAction(
  'ELEMENT_TICKMARK_LIST_LOADED',
);
export const elementTickmarkListError = createAction(
  'ELEMENT_TICKMARK_LIST_ERROR',
);
export const selectCreatedElementAction = createAction(
  'SELECT_CREATED_ELEMENT_ACTION',
);
export const setElementHistoryLoading = createAction('ELEMENT_HISTORY_LOADING');
export const setElementHistoryLoaded = createAction('ELEMENT_HISTORY_LOADED');
export const setElementHistoryError = createAction('ELEMENT_HISTORY_ERROR');
export const clearElementHistory = createAction('CLEAR_ELEMENT_HISTORY');

export const updateElementSectionName = createAction(
  'SET_ELEMENT_SECTION_NAME',
);

export const updateElementPanelFromSocketPayload = createAction(
  'UPDATE_ELEMENT_PANEL_FROM_SOCKET_PAYLOAD',
);

export const removeElementFromFormulaTabFromSocketPayloadAction = createAction(
  'REMOVE_ELEMENT_FROM_FORMULA_TAB_FROM_SOCKET_PAYLOAD_ACTION',
);

export const addFormulaToFormulaListFromSocketPayloadAction = createAction(
  'ADD_FORMULA_TO_FORMULA_LIST_FROM_SOCKET_PAYLOAD_ACTION',
);

export const elementFormulaListLoadedWithoutResponse = createAction(
  'ELEMENT_FORMULA_LIST_LOADING_WITHOUT_RESPONSE_ACTION',
);

export const removeWorkpaperFromElementPanelWithSocketPayload = createAction(
  'REMOVE_WORKPAPER_FROM_ELEMENT_PANEL_WITH_SOCKET_PAYLOAD',
);

export const elementComfortLetterListLoading = createAction(
  'ELEMENT_COMFORT_LETTER_LIST_LOADING_ACTION',
);
export const elementComfortLetterListLoaded = createAction(
  'ELEMENT_COMFORT_LETTER_LIST_LOADED_ACTION',
);
export const elementComfortLetterListLoadedWithoutResponse = createAction(
  'ELEMENT_COMFORT_LETTER_LIST_LOADED_WITHOUT_RESPONSE_ACTION',
);

export const elementComfortLetterListError = createAction(
  'ELEMENT_COMFORT_LETTER_LIST_ERROR',
);

export const initElementPanel =
  ({ elementId, color }) =>
  async (dispatch, getStore) => {
    await dispatch(showElementPanelAction());
    await dispatch(fetchSelectedElementDetails({ elementId }));
    const { right } = getStore().ui.statementPage.panels;
    const selectedElements = getStore().ui.statementPage.selectedElementsMap;
    const isElementSelected = elementId in selectedElements;
    const isElementLocated = isElementSelected
      ? selectedElements[elementId].color ===
        ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED
      : null;
    const { elementDetails } = getStore().data.elementPanel;
    dispatch(
      replaceSelectedElementsContentPanel({
        elementIds: [elementId],
        color: color
          ? color
          : isElementLocated
          ? ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED
          : getElementHightlightState(right, elementDetails.id, elementId),
      }),
    );
    // clear batch panel if there are any selected elements there
    dispatch(clearBatchSelectedElementsAction());
  };

export const fetchSelectedElementDetails =
  ({ elementId }) =>
  async (dispatch, getStore) => {
    const selectedStatement =
      getStore().data &&
      getStore().data.selectedStatement &&
      getStore().data.selectedStatement.data &&
      getStore().data.selectedStatement.data.statement;

    dispatch(elementDetailsLoading());
    await dispatch(getElementDetailsWithoutLoading(elementId));
    dispatch(getElementFormulas({ elementId }));
    dispatch(getElementInternalReference());
    dispatch(getElementWorkpapers({ elementId }));
    dispatch(getElementTickmarks({ elementId }));

    // check if comfort letter feature is enabled before making api call for fetching list of comfort letter
    selectedStatement &&
      selectedStatement.comfortLetter &&
      dispatch(getElementComfortLetters({ elementId }));
  };

export const getElementDetailsWithoutLoading =
  (elementId) => async (dispatch, getStore) => {
    const {
      revision,
      statementContent: { elementCache },
    } = getStore().data;
    try {
      const response = await getElementDetailsRequest({
        elementId,
        revisionId: revision.id,
      });
      if (response && response.data && response.data.result) {
        const elementData = elementCache.getElementsListByElementIds({
          elementIds: [elementId],
        });
        if (elementData.length) {
          const { callout, marker } = elementData[0];
          response.data.result['marker'] = !isNullOrUndefined(marker) && marker;
          response.data.result['callout'] =
            !isNullOrUndefined(callout) && callout;
        }
      }
      dispatch(
        elementDetailsLoaded({
          response,
        }),
      );
    } catch (error) {
      dispatch(elementDetailsError(error));
    }
  };

export const getElementFormulas =
  ({ elementId }) =>
  async (dispatch, getStore) => {
    const { revision } = getStore().data;
    dispatch(elementFormulaListLoading());
    try {
      const response = await getFormulasForElementRequest({
        elementId,
        revisionId: revision.id,
      });
      dispatch(
        elementFormulaListLoaded({
          response,
        }),
      );
    } catch (error) {
      dispatch(elementFormulaListError(error));
    }
  };

export const getElementFormulasWithoutLoading =
  ({ elementId }) =>
  async (dispatch, getStore) => {
    const { revision } = getStore().data;
    const socketModel = getStore().sockets;
    const { socketHasBeenDisconnected } =
      socketModel && socketModel.statementSocket;
    try {
      const response = await getFormulasForElementRequest({
        elementId,
        revisionId: revision.id,
      });
      await dispatch(
        elementFormulaListLoaded({
          response,
        }),
      );
      if (
        !isNullOrUndefined(socketHasBeenDisconnected) &&
        socketHasBeenDisconnected
      ) {
        await dispatch(_updateElementDetailsInStoreAndElementCache());
      }
    } catch (error) {
      dispatch(elementFormulaListError(error));
    }
  };

export const _updateStatementSummaryElements = async (dispatch, getState) => {
  const { revisionId } = getState().data.elementPanel.elementDetails;
  dispatch(fetchStatementSummaryElements({ revisionId }));
};

export const updateVerify =
  ({ verified }) =>
  async (dispatch, getState) => {
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    await dispatch(updateElementVerify({ verified }));
    await dispatch(_updateElementDetailsInStoreAndElementCache());
    await dispatch(getHistoryForElementPanel());
    if (socketHasBeenDisconnected) {
      dispatch(_updateStatementSummaryElements);
    }
  };

export const updateReviewed =
  ({ reviewed }) =>
  async (dispatch, getState) => {
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    await dispatch(updateElementReview({ reviewed }));
    await dispatch(_updateElementDetailsInStoreAndElementCache());
    await dispatch(getHistoryForElementPanel());
    if (socketHasBeenDisconnected) {
      dispatch(_updateStatementSummaryElements);
    }
  };

export const updateUserFlag =
  ({ userFlag }) =>
  async (dispatch, getState) => {
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    await dispatch(updateElementUserFlag({ userFlag }));
    await dispatch(_updateElementDetailsInStoreAndElementCache());
    await dispatch(getHistoryForElementPanel());
    if (socketHasBeenDisconnected) {
      dispatch(_updateStatementSummaryElements);
    }
    // TODO maybe update elementDetails from response?
    // right now end point returns an html changes object
    // which won't be useful in Aruba
  };

export const updateOverrideSystemFlag =
  ({ systemFlag }) =>
  async (dispatch, getState) => {
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    await dispatch(updateElementOverrideSystemFlag({ systemFlag }));
    await dispatch(_updateElementDetailsInStoreAndElementCache());
    await dispatch(getHistoryForElementPanel());
    if (socketHasBeenDisconnected) {
      dispatch(_updateStatementSummaryElements);
    }
  };

export const updateComfortAssignAnnotation =
  ({ comfortAssignVal }) =>
  async (dispatch, getState) => {
    await dispatch(updateElementComfortAssignAnnotation({ comfortAssignVal }));
    await dispatch(_updateElementDetailsInStoreAndElementCache());
  };

const _updateElementDetailsInStoreAndElementCache =
  (selectedElementPanelAnnotationsTab) => async (dispatch, getState) => {
    try {
      const storeData = getState().data;
      const { revision } = storeData;
      const { elementDetails } = storeData.elementPanel;
      const socketModel = getState().sockets;
      const { socketHasBeenDisconnected } = socketModel.statementSocket;

      await updateElementRequest({
        revisionId: revision.id,
        elementId: elementDetails.id,
        elementDetails,
      });
      if (
        selectedElementPanelAnnotationsTab &&
        selectedElementPanelAnnotationsTab.id ===
          ELEMENT_ANNOTATIONS_TABS.INTERNAL_REFERENCE.id
      ) {
        await dispatch(
          fetchSuggestedListed({
            revisionId: elementDetails.revisionId,
            elementId: elementDetails.id,
          }),
        );
      }
      // rely on these methods of updaing the ui if the client fails to connect to signalr
      if (socketHasBeenDisconnected) {
        // dispatch(getElementDetailsWithoutLoading(elementDetails.id));
        dispatch(updateCacheWithMostRecentElementChanges());
        // Update elements search results
        dispatch(
          updateElementsSearchByIds({ elementIds: [elementDetails.id] }),
        );
      }
    } catch (error) {
      dispatch(elementDetailsError(error));
    }
  };

/** This thunk both creates a new entity when entity doesn't exist
 * or updates   the entity value selected from the entity dropdown menu */
export const updateOrCreateEntity =
  (entity, selectedElementPanelAnnotationsTab) =>
  async (dispatch, getState) => {
    const { project } = getState().data.selectedProject;
    await dispatch(updateElementEntity(entity));
    await dispatch(
      _updateElementDetailsInStoreAndElementCache(
        selectedElementPanelAnnotationsTab,
      ),
    );
    dispatch(fetchClientEntityList(project.id));
  };

export const updateScaling =
  (scaling, selectedElementPanelAnnotationsTab) =>
  async (dispatch, getState) => {
    await dispatch(updateElementScaling({ scaling }));
    dispatch(
      _updateElementDetailsInStoreAndElementCache(
        selectedElementPanelAnnotationsTab,
      ),
    );
  };

export const updateUnits =
  (units, selectedElementPanelAnnotationsTab) => async (dispatch, getState) => {
    await dispatch(updateElementUnits({ units }));
    dispatch(
      _updateElementDetailsInStoreAndElementCache(
        selectedElementPanelAnnotationsTab,
      ),
    );
  };

export const updatePeriod =
  (period, selectedElementPanelAnnotationsTab) =>
  async (dispatch, getState) => {
    await dispatch(updateElementPeriod(period.value));
    dispatch(
      _updateElementDetailsInStoreAndElementCache(
        selectedElementPanelAnnotationsTab,
      ),
    );
  };

export const hideElementPanel = () => (dispatch) => {
  dispatch(hideElementPanelAction());
  dispatch(clearSelectedElementsContentPanel());
  dispatch(cancelCopyFormula());
};

export const deleteFormulaFromList =
  (formulaId, creationType, type) => async (dispatch, getState) => {
    const storeData = getState().data;
    const { revision } = storeData;
    const { elementDetails } = storeData.elementPanel;
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    try {
      await deleteFormulaRequest({
        revisionId: revision.id,
        elementId: elementDetails.id,
        formulaId,
        creationType,
        type,
      });

      if (socketHasBeenDisconnected) {
        dispatch(
          replaceSelectedElementsContentPanel({
            elementIds: [elementDetails.id],
          }),
        );
        dispatch(_updateStatementSummaryElements);
        /**
         * We have to fetch the element again as its flaggging display may have changed
         * after a formula is successfully deleted.
         */
        await dispatch(
          fetchSelectedElementDetails({ elementId: elementDetails.id }),
        );
        dispatch(getElementFormulas({ elementId: elementDetails.id }));
        dispatch(updateElementAnnotationDetails());
        dispatch(
          updateElementsSearchByIds({ elementIds: [elementDetails.id] }),
        );
      } else {
        dispatch(elementFormulaListLoading());
        setTimeout(() => {
          dispatch(elementFormulaListLoadedWithoutResponse());
        }, 5000);
      }
    } catch (error) {
      dispatch(deleteFormulaError(error));
    }
  };

export const getElementWorkpapers =
  ({ elementId }) =>
  async (dispatch, getState) => {
    dispatch(elementWorkpaperListLoading());
    try {
      const { revision } = getState().data;
      const response = await getWorkpapersForElementRequest({
        elementId,
        revisionId: revision.id,
      });
      dispatch(
        elementWorkpaperListLoaded({
          response,
        }),
      );
    } catch (error) {
      dispatch(elementWorkpaperListError(error));
    }
  };

/** Function used to fetch the currently selected element's attached workpapers
 * and then update the element cache if necessary
 */
export const getElementWorkpapersAndUpdateElementCache =
  () => async (dispatch, getState) => {
    const { elementDetails } = getState().data.elementPanel;
    // fetch the new list of workpapers and update the elementDetails object with them
    await dispatch(getElementWorkpapers({ elementId: elementDetails.id }));

    // Get the updated elementDetails with their new workpapers
    const updatedStoreData = getState().data;
    const updatedElementWPList = updatedStoreData.elementPanel.workpaperList;
    const { elementCache } = updatedStoreData.statementContent;
    const _needToUpdateCacheToo = elementCache.hasElement({
      sectionId: elementDetails.sectionId,
      elementId: elementDetails.id,
    });
    if (_needToUpdateCacheToo) {
      const updatedElementForCache = elementCache
        .getElement({
          sectionId: elementDetails.sectionId,
          elementId: elementDetails.id,
        })
        .setElementAnnotations({
          workpaperReferenceNumberList: updatedElementWPList.workpapers.map(
            (workpaper) => workpaper.referenceNumber,
          ),
        });
      dispatch(updateElementInCacheAction({ element: updatedElementForCache }));
    }
    dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
    dispatch(updateElementAnnotationDetails());
  };

export const getElementWorkpapersAndUpdateElementCacheWithParameters =
  ({ elementId, sectionId }) =>
  async (dispatch, getState) => {
    Number.isInteger(elementId) &&
      (await dispatch(getElementWorkpapers({ elementId })));

    // Get the updated elementDetails with their new workpapers
    const updatedStoreData = getState().data;
    const updatedElementWPList = updatedStoreData.elementPanel.workpaperList;
    const { elementCache } = updatedStoreData.statementContent;
    const _needToUpdateCacheToo = elementCache.hasElement({
      sectionId,
      elementId,
    });
    if (_needToUpdateCacheToo) {
      const updatedElementForCache = elementCache
        .getElement({
          sectionId,
          elementId,
        })
        .setElementAnnotations({
          workpaperReferenceNumberList: updatedElementWPList.workpapers.map(
            (workpaper) => workpaper.referenceNumber,
          ),
        });
      dispatch(updateElementInCacheAction({ element: updatedElementForCache }));
    }
    dispatch(updateElementsSearchByIds({ elementIds: [elementId.id] }));
  };

export const editWorkpaperDetailsAction =
  ({ revisionId, workpaper }) =>
  async (dispatch, getState) => {
    const storeData = getState().data;
    const { elementDetails } = storeData.elementPanel;

    const { socketHasBeenDisconnected } = getState().sockets.statementSocket;

    await updateWorkpaperRequest({
      revisionId,
      workpaper,
    });

    const updatedWorkpaper = workpaper && {
      ...workpaper.data,
      wpRefId: workpaper.workpaperRefId,
    };

    if (socketHasBeenDisconnected) {
      // update the workpaper toolkit
      updatedWorkpaper &&
        (await dispatch(
          updateWorkpaperWithSocketPayloadAction(updatedWorkpaper),
        ));

      // update cache. This will update the element in statement content page without fetching summary.
      // Since updating workpaper does not have to result in update of statement summary.
      dispatch(updateCacheWithMostRecentElementChanges(false, true));

      //update element panel if it is opened
      updatedWorkpaper &&
        Number.isInteger(elementDetails.id) &&
        dispatch(updateWPFromElementPanelAction(updatedWorkpaper));

      // This will update searched elements in statement nav only if statement nav is opened
      dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
    }
  };

export const detachElementWorkpaper =
  ({ workpaper }) =>
  async (dispatch, getState) => {
    const storeData = getState().data;
    const { revision } = storeData;
    const { elementDetails } = storeData.elementPanel;
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;

    try {
      dispatch(elementWorkpaperListLoading());
      await detachElementWorkpaperRequest({
        revisionId: revision.id,
        elementId: elementDetails.id,
        workpaperId: workpaper.workpaperRefId,
      });

      if (socketHasBeenDisconnected) {
        await dispatch(
          getElementWorkpapersAndUpdateElementCache({
            elementId: elementDetails.id,
          }),
        );

        dispatch(
          unlinkElementFromElementsMap({
            elementId: elementDetails.id,
            wpRefId: workpaper.workpaperRefId,
          }),
        );
      } else {
        setTimeout(() => {
          dispatch(elementWorkpaperListLoadedWithoutResponse());
        }, 5000);
      }
    } catch (error) {
      dispatch(elementWorkpaperListError(error));
    }
  };

export const getElementTickmarksWithoutLoading =
  ({ elementId }) =>
  async (dispatch, getState) => {
    try {
      const { revision } = getState().data;
      const response = await getElementTickmarksRequest({
        elementId,
        revisionId: revision.id,
      });
      await dispatch(elementTickmarkListLoaded({ response }));
    } catch (error) {
      dispatch(elementTickmarkListError(error));
    }
  };

export const getElementComfortLetters =
  ({ elementId }) =>
  async (dispatch) => {
    await dispatch(elementComfortLetterListLoading());
    await dispatch(getElementComfortLettersWithoutLoading({ elementId }));
  };

export const getElementComfortLettersWithoutLoading =
  ({ elementId }) =>
  async (dispatch, getState) => {
    try {
      const { revision } = getState().data;
      const response = await getComfortLetterForElementRequest({
        elementId,
        revisionId: revision.id,
      });
      await dispatch(elementComfortLetterListLoaded({ response }));
    } catch (error) {
      dispatch(elementComfortLetterListError(error));
    }
  };

export const getElementTickmarks =
  ({ elementId }) =>
  async (dispatch) => {
    await dispatch(elementTickmarkListLoading());
    await dispatch(getElementTickmarksWithoutLoading({ elementId }));
  };

export const getElementTickmarksAndUpdateElementCache =
  (element) => async (dispatch, getState) => {
    // When attaching tickmark for some element in element panel then element value is sent as undefined
    // It should by default understood as element belongs to element panel
    // eslint-disable-next-line no-unused-vars
    let isElementFromElementPanel = true;
    let response;
    const openedPanel = getState().ui.statementPage.panels.right;
    let { elementDetails } = getState().data.elementPanel;
    // Get the updated elementDetails with their new tikcmarks
    let updatedStoreData = getState().data;
    if (element) {
      isElementFromElementPanel =
        element.id === elementDetails.id && openedPanel === RIGHT_PANELS.ELEMENT
          ? true
          : false;
      elementDetails = element;
    }

    if (isElementFromElementPanel) {
      // If element belongs to element panel and element panel is opened
      // then refresh tickmark in element panel
      await dispatch(
        getElementTickmarks({
          elementId: getState().data.elementPanel.elementDetails.id,
        }),
      );
      updatedStoreData = getState().data;
      response = updatedStoreData.elementPanel.tickmarkList.tickmarks.sort(
        (a, b) => (a.noteOrdinal < b.noteOrdinal ? -1 : 1),
      );
    } else {
      // If element does not belongs to element panel or element panel is not opened
      // then no need to update element panel and instead make api request for updated
      // tickmark directly, which has to be further used for updating element cache.
      const rawResponse = await getElementTickmarksRequest({
        elementId: elementDetails.id,
        revisionId: elementDetails.revisionId,
      });
      response = rawResponse.data.result.sort((a, b) =>
        a.noteOrdinal < b.noteOrdinal ? -1 : 1,
      );
    }

    const { elementCache } = updatedStoreData.statementContent;
    const _needToUpdateCacheToo = elementCache.hasElement({
      sectionId: elementDetails.sectionId,
      elementId: elementDetails.id,
    });
    if (_needToUpdateCacheToo) {
      const updatedElementForCache = elementCache
        .getElement({
          sectionId: elementDetails.sectionId,
          elementId: elementDetails.id,
        })
        .setElementTickmarkAnnotations({
          tickmark: response.reduce(
            (resultTickmark, currentTickmark) =>
              resultTickmark + `{${currentTickmark.noteOrdinal}}`,
            '',
          ),
        });
      await dispatch(
        updateElementInCacheAction({ element: updatedElementForCache }),
      );
    }
    dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
    dispatch(updateElementAnnotationDetails());
  };

export const getElementComfortLettersAndUpdateElementCache =
  (elementDetails = undefined) =>
  async (dispatch, getState) => {
    let response;
    const openedPanel = getState().ui.statementPage.panels.right;

    // let's say element details is sent from component requesting detach action, then we do not want to query
    // redux store. Else if element details is not sent in the parameter then we can assume that element panel is open
    // and we are required to query redux store to get element details
    if (
      !(
        elementDetails &&
        Number.isInteger(elementDetails.id) &&
        elementDetails.revisionId
      )
    ) {
      elementDetails = getState().data.elementPanel.elementDetails;
    }
    // Get the updated elementDetails with their new comfort letters
    let updatedStoreData = getState().data;

    const isElementBelongsToElementPanelAndElementPanelIsOpen =
      openedPanel === RIGHT_PANELS.ELEMENT &&
      elementDetails.id === updatedStoreData.elementPanel.elementDetails.id;

    if (isElementBelongsToElementPanelAndElementPanelIsOpen) {
      // If element belongs to element panel and element panel is opened
      // then refresh comfort letters in element panel
      await dispatch(
        getElementComfortLetters({
          elementId: elementDetails.id,
        }),
      );
      updatedStoreData = getState().data;
      response =
        updatedStoreData.elementPanel.comfortLetterList.comfortLetters.sort(
          (b, a) =>
            b.comfortLetterCreatedDate.localeCompare(
              a.comfortLetterCreatedDate,
            ),
        );
      updatedStoreData.elementPanel.elementDetails.data.elementAnnotations.comfortLetterlabelList =
        response.map((comfortLabel) => {
          return comfortLabel.customLabel;
        });
    } else {
      // If element does not belongs to element panel or element panel is not opened
      // then no need to update element panel and instead make api request for updated
      // comfort letter directly, which has to be further used for updating element cache.
      const rawResponse = await getComfortLetterForElementRequest({
        elementId: elementDetails.id,
        revisionId: elementDetails.revisionId,
      });
      response = rawResponse.data.result.sort((b, a) =>
        b.comfortLetterCreatedDate.localeCompare(a.comfortLetterCreatedDate),
      );
    }
    const { elementCache } = updatedStoreData.statementContent;
    const _needToUpdateCacheToo = elementCache.hasElement({
      sectionId: elementDetails.sectionId,
      elementId: elementDetails.id,
    });
    if (_needToUpdateCacheToo) {
      const updatedElementForCache = elementCache
        .getElement({
          sectionId: elementDetails.sectionId,
          elementId: elementDetails.id,
        })
        .setElementComfortLetterAnnotations({
          comfortLetterLabelList:
            isArray(response) &&
            response.map((comfortLetter) =>
              comfortLetter.customLabel
                ? comfortLetter.customLabel
                : comfortLetter.label,
            ),
        });
      await dispatch(
        updateElementInCacheAction({ element: updatedElementForCache }),
      );
    }
    dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
  };

export const attachComfortLetterAction =
  ({ comfortLetter }) =>
  async (dispatch, getState) => {
    const { elementPanel } = getState().data;
    let { elementDetails } = elementPanel;
    await attachComfortLetterToElementRequest({
      elementId: elementDetails.id,
      revisionId: elementDetails.revisionId,
      comfortLetterId: comfortLetter.comfortLetterId,
    });
    //updating cache
    await dispatch(getElementComfortLettersAndUpdateElementCache());
    //taking the latest elements from the cache and updating the elementDetails
    elementDetails = getState().data.elementPanel.elementDetails;

    dispatch(
      linkElementInComfortLetterElementsMapAndUpdateCountAction({
        elementDetails,
        comfortLetterId: comfortLetter.comfortLetterId,
      }),
    );
    dispatch(
      updateElementCacheByCalloutAction({
        elementIds: [elementDetails.id],
        showCallout: true,
      }),
    );
  };

export const createNewComfortLetterAction = {};

export const detachComfortLetterAction =
  ({ comfortLetter, elementDetails = undefined }) =>
  async (dispatch, getState) => {
    // let's say element details is sent from component requesting detach action, then we do not want to query
    // redux store. Else if element details is not sent in the parameter then we can assume that element panel is open
    // and we are required to query redux store to get element details
    if (
      !(
        elementDetails &&
        Number.isInteger(elementDetails.id) &&
        elementDetails.revisionId
      )
    ) {
      elementDetails = getState().data.elementPanel.elementDetails;
    }
    await detachComfortLetterToElementRequest({
      elementId: elementDetails.id,
      revisionId: elementDetails.revisionId,
      comfortLetterId: comfortLetter.comfortLetterId,
    });
    await dispatch(
      getElementComfortLettersAndUpdateElementCache(elementDetails),
    );
    await dispatch(
      unlinkElementInComfortLetterElementsMapAndUpdateCountAction({
        elementId: elementDetails.id,
        comfortLetterId: comfortLetter.comfortLetterId,
      }),
    );
  };

export const getElementTickmarksAndUpdateElementCacheWithParameters =
  ({ elementId, revisionId }) =>
  async (dispatch) => {
    dispatch(getElementTickmarks({ elementId: elementId }));
    // will update the element cache and content panel, cache listens for the elementDetailsLoaded action
    dispatch(getElementDetailsWithoutLoading(elementId));
    dispatch(
      fetchTickmarkListForRevisionWithoutLoading({
        revisionId: revisionId,
      }),
    );
    dispatch(updateElementsSearchByIds({ elementIds: [elementId] }));
    dispatch(updateElementAnnotationDetails());
  };

export const attachTickmarkToElement =
  ({ tickmark }) =>
  async (dispatch, getState) => {
    const { id, revisionId, sectionId } =
      getState().data.elementPanel.elementDetails;
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    await attachTickmarkToElementRequest({
      elementId:
        typeof tickmark.elementId !== 'number' ? id : tickmark.elementId,
      revisionId: revisionId,
      tickmarkId: tickmark.tickmarkId,
    });
    dispatch(
      updateElementCacheByCalloutAction({
        elementIds: [id],
        showCallout: true,
      }),
    );
    if (socketHasBeenDisconnected) {
      dispatch(fetchTickmarkListForRevisionWithoutLoading({ revisionId }));

      // This case will be hit in copy annotation scenario
      // Where you want to copy multiple tickamrk linked to multiple element, to all the
      // element in the IR group
      if (typeof tickmark.elementId === 'number') {
        let element = {
          id: tickmark.elementId,
          revisionId: tickmark.revisionId,
          sectionId,
        };
        dispatch(getElementTickmarksAndUpdateElementCache(element));
      } else {
        dispatch(getElementTickmarksAndUpdateElementCache());
      }
      dispatch(
        fetchElementsFromTickmark({
          revisionId: revisionId,
          tickmarkId: tickmark.tickmarkId,
        }),
      );
    }
    updateElementCacheByCalloutAction({
      elementIds: [tickmark.elementId],
      showCallout: true,
    });
  };
/*giving an element as parameter to this function covers the cases in which there is no element details
data on data.elementPanel */
export const detachTickmarkFromElement =
  ({ tickmark, element }) =>
  async (dispatch, getState) => {
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    const openedPanel = getState().ui.statementPage.panels.right;
    let { internalReference } = getState().data.elementPanel;
    let { id, revisionId } = getState().data.elementPanel.elementDetails;
    //if receives an element as parameter(element !== null), it will use this element data and not the one from the right panel
    if (element) {
      id = element.id;
      revisionId = element.revisionId;
      element = isNullOrUndefined(element.sectionId)
        ? {
            ...element,
            sectionId: getState().data.elementPanel.elementDetails.sectionId,
          }
        : element;
    }

    await detachTickmarkFromElementRequest({
      elementId: id,
      revisionId: revisionId,
      tickmarkId: tickmark.tickmarkId,
    });
    if (socketHasBeenDisconnected) {
      dispatch(fetchTickmarkListForRevisionWithoutLoading({ revisionId }));
      await dispatch(
        getElementTickmarksAndUpdateElementCache(element ? element : null),
      );

      dispatch(excludeElementFromElementsMapAction(id));

      openedPanel === RIGHT_PANELS.ELEMENT &&
        internalReference.elements.some((details) => details.id === id) &&
        (await dispatch(getInternalReferenceWithoutLoading()));
    }
  };

export const excludeElement = () => async (dispatch, getState) => {
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;
  dispatch(elementDetailsLoading());
  try {
    const {
      elementPanel: { elementDetails },
      selectedStatement,
    } = getState().data;

    await excludeElementFromElementListRequest({
      elementId: elementDetails.id,
      revisionId: elementDetails.revisionId,
    });
    if (socketHasBeenDisconnected) {
      dispatch(_updateStatementSummaryElements);
    }
    dispatch(hideElementPanelAction());
    if (
      !selectedStatement.isOCR ||
      (socketHasBeenDisconnected && selectedStatement.isOCR)
    ) {
      dispatch(updateCacheWithMostRecentElementChanges(false, false));
    }

    if (socketHasBeenDisconnected && !selectedStatement.isOCR) {
      dispatch(
        fetchSectionHTMLSegmentsStatementContent({
          sectionIdList: [elementDetails.sectionId],
        }),
      );
    } else {
      if (!selectedStatement.isOCR) {
        const sectionId = elementDetails.sectionId;
        dispatch(sectionHTMLSegmentsStatementContentLoading([sectionId]));
        setTimeout(() => {
          dispatch(
            sectionHTMLSegmentsStatementContentLoadedWithoutResponse([
              sectionId,
            ]),
          );
        }, 5000);
      }
    }

    dispatch(
      fetchElementsBySection({
        sectionId: elementDetails.sectionId,
      }),
    );

    if (socketHasBeenDisconnected) {
      dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
      //remove elements from workpaper references elements list
      dispatch(removeElementFromElementsMap({ elementId: elementDetails.id }));
      //remove elements from tickmarks elements list
      dispatch(
        excludeElementFromTickmarkElementsMap({ elementId: elementDetails.id }),
      );
    }
  } catch (error) {
    dispatch(elementDetailsError(error));
  }
};

export const getHistoryForElementPanel = () => async (dispatch, getState) => {
  const { elementPanel, selectedStatement } = getState().data;
  const elementId = elementPanel.elementDetails.id;
  const statementId = selectedStatement.statement.id;
  dispatch(setElementHistoryLoading());
  try {
    const response = await getHistoryForElementRequest({
      elementId,
      statementId,
    });
    dispatch(setElementHistoryLoaded(response));
  } catch (error) {
    dispatch(setElementHistoryError(error));
  }
};

export const updateElementAnnotationDetails =
  () => async (dispatch, getState) => {
    const openedPanel = getState().ui.statementPage.panels.left;
    if (
      openedPanel !== LEFT_PANELS.WORKPAPER &&
      openedPanel !== LEFT_PANELS.TICKMARK
    ) {
      return;
    }

    try {
      const storeData = getState().data;
      const { revision } = storeData;
      const { elementDetails } = storeData.elementPanel;

      const response = await updateElementRequest({
        revisionId: revision.id,
        elementId: elementDetails.id,
        elementDetails,
      });
      dispatch(
        updateWorkpaperElementsMap({
          response,
        }),
      );
      //updates tickmark elements map if tickmark left panel is open
      dispatch(
        updateTickmarkElementsMap({
          response,
        }),
      );
    } catch (error) {
      dispatch(elementDetailsError(error));
    }
  };
export const updateElementDetailsFromLeftPanel =
  ({ elementsArray }) =>
  async (dispatch, getState) => {
    const openedPanel = getState().ui.statementPage.panels.left;
    if (
      openedPanel !== LEFT_PANELS.WORKPAPER &&
      openedPanel !== LEFT_PANELS.TICKMARK &&
      openedPanel !== LEFT_PANELS.COMFORT_ANNOTATION
    ) {
      return;
    }
    //updates workpaper elements map if workpaper left panel is open
    dispatch(
      updateWorkpaperElementsMapFromArray({
        elementsArray,
      }),
    );
    //updates tickmark elements map if tickmark left panel is open
    dispatch(
      updateTickmarkElementsMapFromArray({
        elementsArray,
      }),
    );
    //updates comfort letters elements map if comfort letter left panel is open
    dispatch(updateComfortLettersElementsMapArray({ elementsArray }));
  };

export const selectElementCreated =
  ({ elementId }) =>
  (dispatch, getState) => {
    const elementIds = [elementId];
    dispatch(selectCreatedElementAction({ elementIds }));

    /** Set one time click event listener to clear green selected elements after
     * successful creation of element
     */
    document.addEventListener(
      'click',
      (e) => {
        if (!e.ctrlKey) {
          if (
            getState().ui.statementPage.panels.right === RIGHT_PANELS.ELEMENT
          ) {
            dispatch(
              selectElementContentPanel({
                elementIds: [elementId],
                color: ELEMENT_HIGHLIGHT_STATES.OPENED,
              }),
            );
          } else {
            dispatch(
              selectElementContentPanel({
                elementIds: [elementId],
              }),
            );
          }
        }
      },
      {
        once: true,
      },
    );
  };
