import {
  LEFT_VIEW_DOCUMENT_ID,
  RIGHT_VIEW_DOCUMENT_ID,
  SIDE_BY_SIDE_VIEW_LEGACY_COMBINATION_STATEMENT,
} from 'constants/feature/statement-content-constants';
import { SCALE_ALTERATION } from 'constants/feature/statement-content-constants';
import { isBothLegacyOrOCRInSideBySideView } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { getStore } from 'store/store';

const STATEMENT_PAGE_PADDING = 16;
const SYNC_SCROLL_TIMEOUT = 2000;

export const onSyncScroll = (isUserScrollingLeftView) => {
  const {
    syncScrollIds,
    modes: { blacklineViewMode },
  } = getStore().getState().ui.statementPage;
  let leftView = !blacklineViewMode
    ? document.getElementById(syncScrollIds.sourceId)
    : document.getElementById(LEFT_VIEW_DOCUMENT_ID);
  let rightView = !blacklineViewMode
    ? document.getElementById(syncScrollIds.targetId)
    : document.getElementById(RIGHT_VIEW_DOCUMENT_ID);

  if (isUserScrollingLeftView && leftView && rightView) {
    rightView.scrollTop = leftView.scrollTop;
  } else {
    leftView.scrollTop = rightView.scrollTop;
  }
};

const onScrollRightStatementPage = () => onSyncScroll(false);
const onScrollLeftStatementPage = () => onSyncScroll(true);

export const registerLeftScrollListeners = (leftView) => {
  leftView && leftView.addEventListener('scroll', onScrollLeftStatementPage);
};

export const registerRightScrollListeners = (rightView) => {
  rightView && rightView.addEventListener('scroll', onScrollRightStatementPage);
};

export const deRegisterLeftScrollListeners = (leftView) => {
  leftView && leftView.removeEventListener('scroll', onScrollLeftStatementPage);
};

export const deRegisterRightScrollListeners = (rightView) => {
  rightView &&
    rightView.removeEventListener('scroll', onScrollRightStatementPage);
};

const getElementPositionOnPage = (dpi, zoom, elementDetails) => {
  const scale = SCALE_ALTERATION * (150 / dpi);
  const scaleWithZoom = scale * zoom.zoomRatio;
  const [x, y] = JSON.parse(elementDetails.polygonInPixel);
  const elementY = y * scaleWithZoom;
  const elementX = x * scaleWithZoom;
  return { elementX, elementY };
};

export const getTotalSectionHeight = (
  sectionId,
  isLegacyStatementsSelected = false,
  documentId,
) => {
  const canvas =
    isLegacyStatementsSelected && documentId
      ? document.getElementById(documentId)
      : document.getElementById('canvas-1');
  const { height } = canvas.getBoundingClientRect();
  const heightOfOnePage = height + STATEMENT_PAGE_PADDING * 2;
  const totalPageHeight = heightOfOnePage * (sectionId - 1);
  return totalPageHeight;
};

export const syncScrollIntoView = ({
  isSyncScrollingMode,
  rowTitle,
  elementDetails,
  zoom,
  dpi,
}) => {
  const rightView = document.getElementById(RIGHT_VIEW_DOCUMENT_ID);
  const leftView = document.getElementById(LEFT_VIEW_DOCUMENT_ID);

  const totalPageHeight = getTotalSectionHeight(elementDetails.sectionId);
  const { elementX, elementY } = getElementPositionOnPage(
    dpi,
    zoom,
    elementDetails,
  );

  const top = totalPageHeight + elementY;
  const left = elementX - STATEMENT_PAGE_PADDING * 2;
  const options = { top, left, behavior: 'smooth' };

  if (isSyncScrollingMode) {
    deRegisterLeftScrollListeners(leftView);
    deRegisterRightScrollListeners(rightView);

    if (leftView && rightView) {
      leftView.scrollTo(options);
      rightView.scrollTo(options);
    }

    setTimeout(() => {
      registerLeftScrollListeners(leftView);
      registerRightScrollListeners(rightView);
    }, SYNC_SCROLL_TIMEOUT);
  } else {
    const isLeftSectionScroll = rowTitle === 'Deleted';
    const view = isLeftSectionScroll ? leftView : rightView;
    view.scrollTo(options);
  }
};

export const syncSideBySideScrollIntoView = async ({
  sectionId,
  isLeftView,
}) => {
  const {
    syncScrollIds,
    modes: { sideBySideSyncScroll },
  } = getStore().getState().ui.statementPage;
  const leftView = document.getElementById(syncScrollIds.sourceId);
  const rightView = document.getElementById(syncScrollIds.targetId);
  const isLegacyStatementsSelected =
    isBothLegacyOrOCRInSideBySideView() ===
    SIDE_BY_SIDE_VIEW_LEGACY_COMBINATION_STATEMENT;

  const documentId = isLeftView
    ? syncScrollIds.sourceId
    : syncScrollIds.targetId;
  const totalPageHeight = getTotalSectionHeight(
    sectionId,
    isLegacyStatementsSelected,
    documentId,
  );

  const top = totalPageHeight;
  const options = { top, behavior: 'smooth' };

  if (sideBySideSyncScroll) {
    deRegisterLeftScrollListeners(leftView);
    deRegisterRightScrollListeners(rightView);

    if (leftView && rightView) {
      leftView.scrollTo(options);
      rightView.scrollTo(options);
    }

    setTimeout(() => {
      registerLeftScrollListeners(leftView);
      registerRightScrollListeners(rightView);
    }, SYNC_SCROLL_TIMEOUT);
  }
};
