import ApiModel from 'models/api-model';
import Container from 'models/data/container-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { MenuOption } from '../utils/common/menu/menu-option-model';
import { isArray, orderBy } from 'lodash';

export default class ContainerForm extends ApiModel({
  data: {
    containersList: [],
    containersOptions: [],
    users: [],
  },
}) {
  get users() {
    if (isArray(this.data.users)) {
      return this.data.users;
    }
    return [];
  }
  get containersList() {
    if (isArray(this.data.containersList)) {
      return this.data.containersList;
    }
    return [];
  }
  get containersOptions() {
    if (!isNullOrUndefined(this.data.containersOptions)) {
      return this.data.containersOptions;
    }
    return [];
  }

  processResponse(response) {
    const containers = response.data.containers;
    let orderedContainers = orderBy([...containers], 'countryName', 'asc');
    return {
      data: {
        containersList: orderedContainers.map((container) => {
          return new Container(container);
        }),
        containersOptions: orderedContainers.map((item) => {
          return new MenuOption({
            id: item.containerCode,
            title: item.containerName,
            value: `${item.containerCode}`,
            isIntl: false,
          });
        }),
        users: response.data.users,
      },
    };
  }

  getContainerDetailsByContainerCode(containerCode) {
    return this.containersList.find(
      (container) => container.containerCode === containerCode,
    );
  }
}
