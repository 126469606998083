import React from 'react';
import PropTypes from 'prop-types';
import { checkIsArrayOfArrays } from 'utils/ocr-text-selection-utils';

const RenderPolygonBasedStatementDetails = ({
  scale,
  coordinates,
  children,
  displayIcon,
  loopCount = null,
}) => {
  // Use React.Children.map to iterate over children and React.cloneElement to add props
  const childrenWithProps = React.Children.map(children, (child) => {
    // Ensure the child is a valid React element
    if (React.isValidElement(child)) {
      if (coordinates && checkIsArrayOfArrays(coordinates)) {
        return coordinates.map((coord, index) => {
          // loopCount: prop check to place marker or callout at the first element in case of multi-line element scenario.
          let showIcon = false;
          if (loopCount === index && displayIcon) {
            showIcon = true;
          }

          return React.cloneElement(child, {
            polygonInPixel: coord.map((childCoord) => {
              return parseFloat(childCoord) * scale;
            }),
            showIcon,
          });
        });
      } else if (coordinates && coordinates.length) {
        let showIcon = displayIcon ? true : false;
        return React.cloneElement(child, {
          polygonInPixel: coordinates.map((coord) => {
            return parseFloat(coord) * scale;
          }),
          showIcon,
        });
      }
    }
  });

  return <>{childrenWithProps}</>;
};

RenderPolygonBasedStatementDetails.prototype = {
  children: PropTypes.node.isRequired,
};

export default RenderPolygonBasedStatementDetails;
