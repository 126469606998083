import ApiModel from 'models/api-model';

export default class SectionContentDataModel extends ApiModel({
  data: {
    ocr: null,
    sectionId: null,
  },
}) {
  get sectionId() {
    if (this.data) {
      return this.data.sectionId;
    }
    return null;
  }

  get ocrWords() {
    if (this.data.ocr && this.data.ocr.words) {
      return this.data.ocr.words;
    }
    return [];
  }

  get ocrLines() {
    if (this.data.ocr && this.data.ocr.lines) {
      return this.data.ocr.lines;
    }
    return [];
  }

  get ocrTables() {
    if (this.data.ocr && this.data.ocr.tables) {
      return this.data.ocr.tables;
    }
    return [];
  }

  processResponse(response) {
    return {
      data: {
        ocr: response.ocr,
        sectionId: response.sectionId,
      },
    };
  }

  clearOCRDataContent() {
    return this.merge({
      isLoaded: false,
      isLoading: true,
      data: {
        ...this.data,
        ocr: null,
      },
    });
  }
}
