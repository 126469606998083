import { connect } from 'react-redux';

import GlobalHeader from 'components/common/global-header-component';

import { toggleBlacklineViewModeAndRefetchSectionsInView } from 'store/actions/blackline-view-actions';
import { setCurrentStatementContentTab } from 'store/actions/statement-content-actions';
import { push } from 'connected-react-router';

const mapStateToProps = (
  {
    data: {
      currentUser,
      revision,
      selectedProject: { project },
      statementContent: { statementContentFilters },
      selectedStatement,
      activeUsers,
      blacklineSourceGuidList,
      blacklineTargetGuidList,
    },
    ui: {
      statementPage: {
        zoom,
        modes: { blacklineViewMode, contentViewMode },
        statementNavigatorPanel: { showNavigationRefreshButton },
      },
    },
  },
  { urlParams },
) => ({
  currentUser,
  revision,
  project,
  urlParams,
  zoom,
  statementContentFilters,
  blacklineViewMode,
  contentViewMode,
  selectedStatement,
  activeUsers,
  showNavigationRefreshButton,
  blacklineSourceGuidList,
  blacklineTargetGuidList,
});

const mapDispatchToProps = {
  setCurrentStatementContentTab,
  push,
  toggleBlacklineViewMode: toggleBlacklineViewModeAndRefetchSectionsInView,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalHeader);
