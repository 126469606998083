import { createAction } from 'redux-actions';
import { documentChangesSinceRequest } from 'api/element-api';
import { updateMultipleElementsInCacheAction } from 'store/actions/element-cache-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  updateElementSectionName,
  updateElementDetailsFromLeftPanel,
} from 'store/actions/element-panel-actions';
export const setElementChangesSinceAction = createAction(
  'SET_ELEMENT_CHANGES_SINCE',
);

/** Thunk to request changes to elements and document content which then updates the element cache
 * Should be called after an element meta data update request that would cause other elements to require
 * content panel updates
 */
export const updateCacheWithMostRecentElementChanges =
  (fetchElementsSummary = true, updateTMorWPpanel = true) =>
  async (dispatch, getState) => {
    const stateData = getState().data;
    const { elementChangesSinceTracker } = stateData.statementContent;
    const { revision } = stateData;
    try {
      const response = await documentChangesSinceRequest({
        revisionId: revision.id,
        sinceTimestamp: elementChangesSinceTracker.since,
      });
      // ensure cache tracker's "since" property is synced with the exact moment the BE retrieved the changes
      dispatch(setElementChangesSinceAction(parseInt(response.data.asof)));

      const _haveElementChangesToUpdate =
        !isNullOrUndefined(response.data) &&
        !isNullOrUndefined(response.data.result) &&
        Array.isArray(response.data.result.elementEntityList) &&
        response.data.result.elementEntityList.length > 0;
      const _haveSectionChangesToUpdate =
        !isNullOrUndefined(response.data) &&
        !isNullOrUndefined(response.data.result) &&
        Array.isArray(response.data.result.sectionEntityList) &&
        response.data.result.sectionEntityList.length > 0;
      if (_haveElementChangesToUpdate) {
        dispatch(
          updateMultipleElementsInCacheAction({
            elements: response.data.result.elementEntityList,
          }),
        );
        const updatedElementsArray = response.data.result.elementEntityList;
        //if one of  tickmarks or workpapers panels is open, is going to update element details from elements on the elements list
        // TODO: need to investigate if this is even needed
        if (updateTMorWPpanel) {
          await dispatch(
            updateElementDetailsFromLeftPanel({
              elementsArray: updatedElementsArray,
            }),
          );
        }
      }
      if (_haveSectionChangesToUpdate) {
        dispatch(
          updateElementSectionName(
            response.data.result.sectionEntityList[0].name,
          ),
        );
      }
    } catch (error) {
      console.error('Error while requesting element changes since');
    }

    // Fire action to update elements in statement summary to capture changes by the system
    // TODO: find a better way to fetch flag counts in statement summary
    // TODO: investigate if we even need fetchStatementSummaryElements in this action
    if (fetchElementsSummary) {
      dispatch(
        fetchStatementSummaryElements({
          revisionId: revision.id,
          withoutLoading: true,
        }),
      );
    }
  };

/** Thunk to request changes to elements and document content which then updates the element cache
 * Should be called after an element meta data update request that would cause other elements to require
 * content panel updates
 */
export const updateCacheWithMostRecentSideBySideElementChanges =
  () => async (dispatch, getState) => {
    const stateData = getState().data;
    const { elementChangesSinceTracker } = stateData.statementContent;
    const { revision } = stateData;
    try {
      const response = await documentChangesSinceRequest({
        revisionId: revision.id,
        sinceTimestamp: elementChangesSinceTracker.since,
      });
      // ensure cache tracker's "since" property is synced with the exact moment the BE retrieved the changes
      dispatch(setElementChangesSinceAction(parseInt(response.data.asof)));

      const _haveElementChangesToUpdate =
        !isNullOrUndefined(response.data) &&
        !isNullOrUndefined(response.data.result) &&
        Array.isArray(response.data.result.elementEntityList) &&
        response.data.result.elementEntityList.length > 0;

      if (_haveElementChangesToUpdate) {
        dispatch(
          updateMultipleElementsInCacheAction({
            elements: response.data.result.elementEntityList,
          }),
        );
      }
    } catch (error) {
      console.error('Error while requesting element changes since');
    }
  };
