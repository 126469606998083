import { handleActions } from 'redux-actions';
import {
  setAnnotationDisplayOption,
  resetAnnotationDisplayAndPositionOption,
} from 'store/actions/annotation-display-actions';
import AnnotationDisplayOptions from 'models/data/annotation-display-filter-options-model';

export default handleActions(
  {
    [setAnnotationDisplayOption]: (statementSumDisplayOption, { payload }) => {
      return statementSumDisplayOption.merge(payload);
    },
    [resetAnnotationDisplayAndPositionOption]: () => {
      return new AnnotationDisplayOptions();
    },
  },
  new AnnotationDisplayOptions(),
);
