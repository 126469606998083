import { handleActions } from 'redux-actions';
import {
  leftSectionIdListStatementContentLoading,
  leftSectionIdListStatementContentLoaded,
  leftSectionIdListStatementContentError,
  clearLeftOCRSectionContentWithIdsFromCache,
  clearLeftStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import {
  leftSectionOCRDataSegmentsStatementContentLoaded,
  leftSectionOCRDataSegmentsStatementContentError,
} from 'store/actions/statement-content-actions-ocr';
import { LeftSectionCacheOCR } from 'models/api/left-sections-cache-model-ocr-data';
export default handleActions(
  {
    [leftSectionIdListStatementContentLoading]: (leftSectionCache) => {
      return leftSectionCache.setLoading();
    },
    [leftSectionIdListStatementContentLoaded]: (
      leftSectionCache,
      { payload },
    ) => {
      return leftSectionCache.setLoaded(payload);
    },
    [leftSectionIdListStatementContentError]: (
      leftSectionCache,
      { payload },
    ) => {
      return leftSectionCache.setError(payload);
    },
    [leftSectionOCRDataSegmentsStatementContentLoaded]: (
      leftSectionCache,
      { payload },
    ) => {
      return leftSectionCache.mergeOCRSectionDataLoaded(payload);
    },
    [leftSectionOCRDataSegmentsStatementContentError]: (
      leftSectionCache,
      { payload },
    ) => {
      return leftSectionCache.setError(payload);
    },
    [clearLeftOCRSectionContentWithIdsFromCache]: (
      leftSectionCache,
      { payload },
    ) => {
      return leftSectionCache.clearLeftSectionOCRContentFromMap(payload);
    },
    [clearLeftStatementPageStoreAction]: () => {
      return new LeftSectionCacheOCR();
    },
  },
  new LeftSectionCacheOCR(),
);
