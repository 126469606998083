import {
  getPinnedDetailsRequest,
  pinRequest,
  unPinRequest,
} from 'api/project-list-api';
import { createAction } from 'redux-actions';

export const pinnedDetailsLoading = createAction('PINNED_DETAILS_LOADING');
export const pinnedDetailsError = createAction('PINNED_DETAILS_ERROR');
export const pinnedDetailsLoaded = createAction('PINNED_DETAILS_LOADED');
export const updatePinnedDetails = createAction('ADD_NEW_PINNED_ITEM');
export const removePinnedDetails = createAction('REMOVE_PINNED_ITEM');

export const getPinnedDetails = () => async (dispatch, getState) => {
  const { id } = getState().data.currentUser;

  dispatch(pinnedDetailsLoading());
  try {
    const pinnedDetails = await getPinnedDetailsRequest(id);
    dispatch(pinnedDetailsLoaded(pinnedDetails));
  } catch (e) {
    dispatch(pinnedDetailsError(e));
  }
};

export const updatePinDetails =
  (projectId, statementId = 0) =>
  async (dispatch, getState) => {
    let payload = {};
    payload.clientId = parseInt(projectId);
    payload.statementId = statementId;
    payload.pinType = statementId === 0 ? 1 : 2;

    try {
      const data = await pinRequest(projectId, payload);
      dispatch(updatePinnedDetails(data));
    } catch (e) {
      console.error('error while pining project or statement', e);
    }
  };

export const updateUnpinDetails =
  (projectId, statementId = 0) =>
  async (dispatch, getState) => {
    let payload = {};
    payload.clientId = parseInt(projectId);
    payload.statementId = statementId;
    payload.pinType = statementId === 0 ? 1 : 2;

    try {
      const data = await unPinRequest(projectId, payload);
      dispatch(removePinnedDetails(data));
    } catch (e) {
      console.error('error while unpinning project or statement', e);
    }
  };
