import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';

export default class ContentSearchResultEntry extends BaseModel({
  id: null,
  sectionId: null,
  siblingIndex: null,
  context: '',
  selector: '',
  index: 0,
}) {
  constructor(props) {
    super(props);
    const {
      sectionId,
      selector,
      siblingIndex,
      polygonDetails,
      context,
      index,
    } = props;
    this.id =
      !isNullOrUndefined(polygonDetails) && polygonDetails.length
        ? `${sectionId}-${context.replace(/[^a-zA-Z0-9]/g, '-')}-${index}`
        : `${sectionId}-${selector}-${siblingIndex}`;
    this.selector =
      !isNullOrUndefined(polygonDetails) && polygonDetails.length
        ? `${context.replace(/[^a-zA-Z0-9]/g, '-')}-${index}`
        : selector;
  }
}
