import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { addElementRequestOCR } from 'api/element-api';
import { toast } from 'react-toastify';
import Notification from 'components/common/notification-component';
import { ReactComponent as Checkmark } from 'icons/checkmark.svg';
import { connect } from 'react-redux';
import {
  initElementPanel,
  selectElementCreated,
} from 'store/actions/element-panel-actions';
import Permissions from 'permissions/permissions';
import { initCreateNoteFromSelection } from 'store/actions/notes-panel-actions';
import { ReactComponent as CloseButton } from 'icons/close-button-red.svg';

const StatementContextMenu = ({
  contextMenuData,
  clearSelection,
  intl,
  BLOCK,
  initElementPanel,
  revision,
  selectElementCreated,
  selectedProject,
  initCreateNoteFromSelection,
  overlayCanvas,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [topPostion, setTopPostion] = useState(contextMenuData.y);
  const [leftPosition, setLeftPosition] = useState(contextMenuData.x);

  const _userCanChangeContent = Permissions.Statement.canChangeContent();
  // const _permissionToEdit = Permissions.Element.canEditElement(selectedProject); we will use it once creation of bookmarks is completed
  const canCreateNotes =
    Permissions.Note.canManageCoeNotesType(selectedProject) ||
    _userCanChangeContent;

  useEffect(() => {
    if (
      overlayCanvas &&
      overlayCanvas.current &&
      // Here 120 is the approx width of context-menu.
      // We are subtracting it from its position so that it does not overlaps with the edge of statement content border on right side.
      // Subtracting will position it such that its right boundary ends up at the starting of word.
      leftPosition + 120 > overlayCanvas.current.width
    )
      setLeftPosition(leftPosition - 120);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlayCanvas.current]);

  const addElement = async () => {
    if (contextMenuData.filteredTableData.length > 1) {
      toast.error(
        <Notification
          icon={<CloseButton />}
          message={{ id: 'notifications.add-element.error.message' }}
          title={{ id: 'notifications.add-element.error.title' }}
        />,
        { autoClose: 10000 },
      );
      clearSelection();
      return;
    }

    const {
      preText,
      postText,
      filtered,
      selectedText,
      contextLabel,
      rowIndex,
      columnIndex,
      polygon,
      isTable,
      tableId,
      sectionId,
    } = contextMenuData;
    try {
      const { data } = await addElementRequestOCR({
        revisionId: revision.id,
        elementDetails: {
          preText,
          postText,
          filtered,
          selectedText,
          contextLabel,
          rowIndex,
          columnIndex,
          polygon,
          isTable,
          tableId,
          sectionId,
        },
      });
      await initElementPanel({ elementId: data.id });
      selectElementCreated({ elementId: data.id });
      toast.success(
        <Notification
          elementDetails={data}
          icon={<Checkmark />}
          message={data.label}
          title={{ id: 'notifications.add-element.success.title' }}
          truncateMessage
        >
          <strong className="Toastify__toast-text--truncated">
            {data.display}
          </strong>
        </Notification>,
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  const addNote = () => {
    const {
      preText,
      postText,
      selectedText,
      contextLabel,
      polygon,
      sectionId,
      containsElement,
      containesElementArray,
    } = contextMenuData;
    initCreateNoteFromSelection({
      data: {
        selector: '',
        selectedText,
        polygon,
        elementId: containesElementArray.length ? containesElementArray[0] : -1,
        preText,
        postText,
        contextLabel,
        length: selectedText.length,
        hasElement: containsElement,
        revisionNumber: revision.revisionNumber,
        sectionId: sectionId,
        revisionId: revision.id,
        isOCR: true,
      },
    });
  };

  return (
    <ul
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseUp={() => clearSelection()}
      style={{ top: topPostion, left: leftPosition, zIndex: 55 }}
      className={`${BLOCK}__context-menu`}
    >
      {_userCanChangeContent &&
        !contextMenuData.containsElement &&
        !contextMenuData.containsNote && (
          <li>
            <button
              onClick={() => {
                addElement();
                clearSelection();
              }}
            >
              {intl.formatMessage({
                id: 'section-content.context-menu.add-element',
              })}
            </button>
          </li>
        )}
      {canCreateNotes &&
        !contextMenuData.containsNote &&
        contextMenuData.filteredTableData.length <= 1 && (
          <li>
            <button
              onClick={() => {
                addNote();
                clearSelection();
              }}
            >
              {intl.formatMessage({
                id: 'section-content.context-menu.add-note',
              })}
            </button>
          </li>
        )}
      {/*Once bookmark creation is completed we will enable this feature again/*}
      {/* {_permissionToEdit &&
        !contextMenuData.containsElement &&
        contextMenuData.filteredTableData.length === 0 && (
          <li>
            <button
              onClick={() => {
                clearSelection();
              }}
            >
              {intl.formatMessage({
                id: 'section-content.context-menu.add-navigation',
              })}
            </button>
          </li>
        )} */}
    </ul>
  );
};

const mapStateToProps = ({
  data: {
    revision,
    selectedProject: { id },
  },
  ui: { statementPage },
}) => ({
  revision,
  zoomRatio: statementPage.zoom.zoomRatio,
  selectedProject: id,
});

const mapDispatchToProps = {
  initElementPanel,
  selectElementCreated,
  initCreateNoteFromSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(StatementContextMenu));
