import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { DataGridSort } from 'components/common/data-grid/data-grid-component';
import { ADMIN_MODULE_SORT_COLUMN_KEYS } from 'constants/feature/admin-module-constants';
import { orderBy } from 'lodash';
import { getCountryNameByCountryCode } from 'utils/project-utils';

const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};
export default class MonitoringForm extends ApiModel({
  data: {
    monitoringList: [],
    monitoringCountrySelectedOptions: [],
    sort: new DataGridSort({
      key: ADMIN_MODULE_SORT_COLUMN_KEYS
        ? ADMIN_MODULE_SORT_COLUMN_KEYS.country
        : null,
      order: SORT_ORDER.ASC,
    }),
  },
}) {
  get containerCodes() {
    if (!isNullOrUndefined(this.data.monitoringCountrySelectedOptions)) {
      return this.data.monitoringCountrySelectedOptions.map(
        ({ value }) => value,
      );
    }
    return '';
  }

  get totalStatementsInEachStatus() {
    if (!isNullOrUndefined(this.data.monitoringList)) {
      let totalObj = {
        clientName: 'Total',
        activeStatements: 0,
        wrappedupStatements: 0,
        deletedStatements: 0,
        containerCode: '',
      };
      this.data.monitoringList.map((item) => {
        totalObj.activeStatements += item.activeStatements;
        totalObj.wrappedupStatements += item.wrappedupStatements;
        totalObj.deletedStatements += item.deletedStatements;
      });
      return [totalObj];
    }
    return [];
  }

  get monitoringOptionsSelected() {
    return this.data.monitoringCountrySelectedOptions;
  }

  processResponse(response) {
    const monitoringData = response.data;
    let changeContainerCodesToCountryNameUpdatedData = monitoringData.map(
      (item) => {
        return {
          ...item,
          containerCode: getCountryNameByCountryCode(item.containerCode),
        };
      },
    );
    let orderedData = orderBy(
      changeContainerCodesToCountryNameUpdatedData,
      [
        ADMIN_MODULE_SORT_COLUMN_KEYS.containerCode,
        ADMIN_MODULE_SORT_COLUMN_KEYS.clientName,
      ],
      [SORT_ORDER.ASC, SORT_ORDER.ASC],
    );
    return {
      data: {
        ...this.data,
        monitoringList: orderedData,
      },
    };
  }

  setCountryOption(option) {
    return this.merge({
      data: {
        ...this.data,
        monitoringCountrySelectedOptions: option,
      },
    });
  }
}
