import React, { useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { STATEMENT_PSEUDO_ELEMENT_ID_PREFIX } from 'constants/feature/statement-content-constants';
import {
  getNoteStatusIcon,
  getNoteStatusColor,
} from 'constants/feature/notes-panel-constants';
import Note from 'models/data/note-model';

import AnnotationDisplayOptions from 'models/data/annotation-display-filter-options-model';
import { isElementReportPreviewPage } from 'utils/ocr-annotation-utils';

export const NOTE_ANCHOR_BLOCK = 'note-anchor';
const ICON_SIZE = '20';
const BORDER_THICKNESS = '1px';

const handleHighlightNote = ({
  className,
  isNoteNew,
  onHover,
  panelSelected,
  changeIsNewPropertyFromSelectedNote,
}) => {
  // if a note is created that spans multiple html elements, each of those elements
  // ends up with a pseudo note span.CFTO_PSEUDO_ELEMENT_<pseudoElementId>.CFTO_PSEUDO_ELEMENT_<pseudoElementId>_<spanIncrement>
  // first we find all pseudo note parts for this specific note
  const pseudoNoteIdClass = String(className)
    .split(' ')
    .find((c) => c.includes(STATEMENT_PSEUDO_ELEMENT_ID_PREFIX));
  const allNoteParts = document.querySelectorAll(`.${pseudoNoteIdClass}`);
  if (allNoteParts.length > 0) {
    for (let i = 0; i < allNoteParts.length; i++) {
      allNoteParts[i].classList.add(
        panelSelected ? 'panel-selected' : 'highlight',
      );
      if (onHover) {
        allNoteParts[i].classList.add('hover-highlight');
      } else {
        allNoteParts[i].classList.remove('hover-highlight');
        allNoteParts[i].classList.add('highlight');
      }
      if (isNoteNew) {
        allNoteParts[i].classList.add('created-note');
        document.addEventListener(
          'click',
          (e) => {
            if (!e.ctrlKey) {
              allNoteParts[i].classList.remove('created-note');
              if (typeof changeIsNewPropertyFromSelectedNote === 'function') {
                // set the isNew property of the selected Note as false when user clicks away
                changeIsNewPropertyFromSelectedNote(false);
              }
            }
          },
          {
            once: true,
          },
        );
      }
    }
  }
};
// clear highlight when the user is not hovering over note icon
const handleRemoveNoteHighlight = (options = {}) => {
  const { className, isNoteSelected } = options;
  let querySelector = '';
  if (className) {
    querySelector = String(className)
      .split(' ')
      .find((c) => c.includes(STATEMENT_PSEUDO_ELEMENT_ID_PREFIX));
  } else {
    querySelector = 'highlight';
  }
  const allNoteParts = document.querySelectorAll(`.${querySelector}`);
  if (allNoteParts.length > 0) {
    for (let i = 0; i < allNoteParts.length; i++) {
      if (!isNoteSelected) {
        allNoteParts[i].classList.remove('highlight');
        allNoteParts[i].classList.remove('created-note');
        allNoteParts[i].classList.remove('hover-highlight');
        allNoteParts[i].classList.remove('panel-selected');
      } else {
        allNoteParts[i].classList.remove('hover-highlight');
      }
    }
  }
};

const NoteAnchor = ({
  id,
  onNoteClick,
  children,
  className,
  pseudoElementId,
  note,
  isNoteSelected,
  selectMode,
  isNoteNew,
  panelSelected,
  isNotesPanelOpen,
  changeIsNewPropertyFromSelectedNote,
  annotationDisplayOptions,
  handleNoteClick,
  isCursorAlias,
  shouldNotesDisabled,
  displayElementDetailsOnStatementPage,
}) => {
  useEffect(() => {
    if (isNoteSelected) {
      handleHighlightNote({
        className,
        isNoteNew,
        panelSelected,
        changeIsNewPropertyFromSelectedNote,
      });
    } else {
      handleRemoveNoteHighlight({ className });
    }
  }, [
    changeIsNewPropertyFromSelectedNote,
    className,
    isNoteNew,
    isNoteSelected,
    panelSelected,
    isNotesPanelOpen,
  ]);
  useEffect(() => {
    if (!isNotesPanelOpen) {
      handleRemoveNoteHighlight({ className });
    }
  }, [className, isNotesPanelOpen]);

  const iconProps = {
    className: `${NOTE_ANCHOR_BLOCK}__icon`,
    onClick: (event) => {
      handleNoteClick(event, selectMode, note, pseudoElementId, onNoteClick);
    },
    onMouseEnter: () => {
      handleHighlightNote({ className, onHover: true });
    },
    onMouseLeave: () => {
      handleRemoveNoteHighlight({ className, isNoteSelected });
    },
    height: ICON_SIZE,
    width: ICON_SIZE,
  };

  const notesCursor = {
    className: classnames(
      isCursorAlias && `${NOTE_ANCHOR_BLOCK}--cursor-alias`,
      shouldNotesDisabled && `${NOTE_ANCHOR_BLOCK}--disabled`,
      `${NOTE_ANCHOR_BLOCK}__icon`,
    ),
    closedPriorityClassName: `${NOTE_ANCHOR_BLOCK}__closed-priority`,
  };

  return !isElementReportPreviewPage(window.location.pathname) ? (
    <>
      {/*note anchor as sibling of content allows for easy icon hover styling */}
      <span id={id}>
        {
          <span
            className={classnames(`${NOTE_ANCHOR_BLOCK}__content`, className)}
            style={{
              border:
                displayElementDetailsOnStatementPage &&
                annotationDisplayOptions.note
                  ? `${BORDER_THICKNESS} solid ${getNoteStatusColor({
                      note,
                    })}`
                  : 'none',
            }}
          >
            {children}
          </span>
        }
        {displayElementDetailsOnStatementPage &&
          annotationDisplayOptions.note &&
          getNoteStatusIcon({ note, iconProps, notesCursor })}
      </span>
    </>
  ) : (
    <>{children}</>
  );
};

NoteAnchor.propTypes = {
  /** unique html id for this note anchor (should be CFTO_PSEUDO_ELEMENT_XXXXXXX) */
  id: PropTypes.string.isRequired,
  /** function fired on click of this note anchor */
  onNoteClick: PropTypes.func.isRequired,
  /** Optional classname applied to top level wrapper element */
  className: PropTypes.string,
  /** Data model for this note */
  note: PropTypes.instanceOf(Note).isRequired,
  /** Indicates if note is selected */
  isNoteSelected: PropTypes.bool.isRequired,
  /** string value to determine which select mode is activated */
  selectMode: PropTypes.string,
  /* bool that indicates if a note is new */
  isNoteNew: PropTypes.bool,
  /* action to manually change the isNew property of the selected note */
  changeIsNewPropertyFromSelectedNote: PropTypes.func.isRequired,
  /** Obj containing display options from store */
  annotationDisplayOptions: PropTypes.instanceOf(AnnotationDisplayOptions),
  /** callback  function which is used to handles the notes api call */
  handleNoteClick: PropTypes.func,
};

export default NoteAnchor;
