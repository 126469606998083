/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import DataGrid from 'components/common/data-grid/data-grid-component';
import Loading from 'components/common/loading-component';
import DataGridConstants from 'constants/common/data-grid-constants';
import { getAllElements } from 'api/element-api';
import { setSelectedElementDetails } from 'store/actions/report-preview-page-actions';
import {
  onToggleSyncScroll,
  resetSyncScrolling,
} from 'store/actions/preview-report-page-actions';
import { DataGridDataApi } from 'models/utils/common/data-grid/data-grid-data-model';
import { clearSelectedElementsContentPanel } from 'store/actions/statement-content-actions';
import {
  disableRefreshIconAction,
  fetchOCRSectionContent,
  fetchSectionContent,
  setSyncScrolling,
} from 'store/actions/preview-report-page-actions';
import { ReactComponent as RefreshIcon } from 'icons/refresh-heading.svg';
import ToolTip from 'components/common/tool-tip-component';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import { isNullOrUndefined } from 'utils/object-utils';
import ReportPreviewPageDisplayOptions from './report-preview-page-display-options';
import { displayOptionsConfig } from 'constants/feature/report-preview-page-contants';
import {
  getElementReoprtColumns,
  getElementListBySections,
  getElementPositionInElementList,
} from 'pages/report-preview/report-preview-utils';
import SlideToggle from 'components/common/slide-toggle-component';

const { ROWS_AMOUNT } = DataGridConstants;
export const REPORT_PREVIEW_RIGHT_BLOCK = 'report-preview-page-right-panel';
export const REPORT_PREVIEW_RIGHT_BLOCK_ID =
  'report-preview-page-right-panel-id';
const ROW_HIGHLIGHT = 'data-grid__row--highlight';
const ROW_SELECTED = 'data-grid__row--selected';
const REFRESH_LIST_ICON_SIZE = '25px';
const ROW_HEIGHT = 48; //px

const ReportPreviewRightSideView = ({ toggleViews }) => {
  const dispatch = useDispatch();
  const {
    data: {
      revision,
      statementContent: { elementCache, sectionIdList },
      selectedStatement: { isOCR, isCftEnabled },
    },
    ui: {
      reportPreviewPage,
      previewPage: {
        shouldEnableRefreshIcon,
        visibleFirstElementFromTop,
        isSyncScrollingEnabled,
      },
      statementPage: { sectionsInView },
    },
  } = useSelector((state) => state);

  const getDisplayOptions = () => {
    let displayOptions = JSON.parse(
      sessionStorage.getItem('reportPreviewPageDisplayOptions'),
    );

    if (!displayOptions) {
      displayOptions = { ...displayOptionsConfig };
      if (!isCftEnabled) {
        delete displayOptions.comfortLetter;
      }
      sessionStorage.setItem(
        'reportPreviewPageDisplayOptions',
        JSON.stringify(displayOptions),
      );
    } else if (!isCftEnabled) {
      delete displayOptions.comfortLetter;
    }

    return displayOptions;
  };
  const listRef = useRef(null);
  const scrollLock = useRef(false);
  const [isRefreshing, setRefreshing] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [isListLoading, setListLoading] = useState(true);
  const [isListLoaded, setListLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [allList, setAllList] = useState([]);
  const [index, setIndex] = useState(ROWS_AMOUNT);
  const [renderedElementList, setRenderedElementList] = useState([]);
  const [elementReportData, setElementReportData] = useState([]);
  const [reportPreviewPageDisplayOptions, setReportPreviewPageDisplayOptions] =
    useState(getDisplayOptions());

  const useVirtualizedList = elementReportData.length > ROWS_AMOUNT;

  const setPreviewPageDisplayOptionAction = ({ key }) => {
    setReportPreviewPageDisplayOptions((prevState) => {
      const updatedState = { ...prevState };

      if (key === 'displayAll') {
        const newValue = !prevState.displayAll;
        updatedState.displayAll = newValue;
        Object.keys(updatedState).forEach((k) => {
          if (k !== 'displayAll' && k !== 'elementId') {
            updatedState[k] = newValue;
          }
        });
      } else {
        updatedState[key] = !prevState[key];

        const allTrue = Object.keys(updatedState).every(
          (k) => k === 'displayAll' || k === 'elementId' || updatedState[k],
        );

        const allFalse = Object.keys(updatedState).every(
          (k) => k === 'displayAll' || k === 'elementId' || !updatedState[k],
        );

        if (allTrue) {
          updatedState.displayAll = true;
        } else if (allFalse) {
          updatedState.displayAll = false;
        }
      }

      sessionStorage.setItem(
        'reportPreviewPageDisplayOptions',
        JSON.stringify(updatedState),
      );

      return updatedState;
    });
  };

  const setDataLoaded = () => {
    setLoading(false);
    setLoaded(true);
  };

  const setDataLoading = () => {
    setLoading(true);
    setLoaded(false);
  };

  const setListDataLoaded = () => {
    setListLoading(false);
    setListLoaded(true);
  };

  const setListDataLoading = () => {
    setListLoading(true);
    setListLoaded(false);
  };

  //scroll to top everytime sync scroll toggles
  useEffect(() => {
    const dataGridList = useVirtualizedList
      ? document.getElementById('data-grid-list')
      : document.getElementById('data-grid');
    if (dataGridList) {
      setTimeout(() => {
        dataGridList.scrollTo({
          top: 0,
          behaviour: 'smooth',
          block: 'center',
        });
      }, 500);
    }
  }, [isSyncScrollingEnabled]);

  //updated sync scroll toggle when component mounts/unmount
  useEffect(() => {
    dispatch(setSyncScrolling());
    return () => {
      dispatch(resetSyncScrolling());
    };
  }, []);

  //get all elements data during component mount, no need to fetch everytime (expensive)
  useEffect(() => {
    dispatch(clearSelectedElementsContentPanel());
    let isMounted = true;
    setListDataLoading();
    setError(null);
    if (revision.id && !elementReportData.length) {
      getAllElements({ revisionId: revision.id })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.result &&
            response.data.result.allElements &&
            isMounted
          ) {
            setAllList(response.data.result.allElements);
            setListDataLoaded();
          }
        })
        .catch((e) => {
          console.error(e);
          setError(e);
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision.id]);

  //collect all element data for the sections that are present in view.
  useEffect(() => {
    if (isSyncScrollingEnabled) {
      const sectionIdListInView = sectionsInView.sectionIds;
      const isElementLoaded = sectionIdListInView.some(
        (item) => elementCache[item] && elementCache[item].isLoaded === true,
      );
      if (!isElementLoaded) {
        setDataLoading();
      } else {
        setDataLoaded();
        const elementList = getElementListBySections(
          sectionIdListInView,
          elementCache,
        );

        let filteredElementList = elementList.map((element) => element.data);
        filteredElementList = filteredElementList.sort((a, b) => {
          if (isNullOrUndefined(a.previewSequenceID)) return 1; // push null to end
          if (isNullOrUndefined(b.previewSequenceID))
            return -1; // push null to end
          else
            return (
              parseInt(a.previewSequenceID) - parseInt(b.previewSequenceID)
            ); // sort in ascending order
        });
        setElementReportData(filteredElementList);
        const useVirtualizedList = filteredElementList.length > ROWS_AMOUNT;
        setRenderedElementList(
          useVirtualizedList
            ? filteredElementList.slice(0, index)
            : filteredElementList,
        );
      }
    } else {
      setElementReportData(allList);
      const useVirtualizedList = allList.length > ROWS_AMOUNT;
      setRenderedElementList(
        useVirtualizedList ? allList.slice(0, index) : allList,
      );
    }
  }, [
    sectionsInView,
    elementCache,
    isSyncScrollingEnabled,
    allList.length,
    isListLoaded,
  ]);

  //Logic when user scrolls the virtualized list
  const listScroll = (scrollTop) => {
    const elementReportDataLength = elementReportData.length;
    const calcIndex = Math.floor(scrollTop / ROW_HEIGHT);
    if (calcIndex !== elementReportDataLength) {
      if (calcIndex + ROWS_AMOUNT <= elementReportDataLength) {
        setIndex(calcIndex + ROWS_AMOUNT);
        setRenderedElementList(
          elementReportData.slice(0, calcIndex + ROWS_AMOUNT),
        );
      } else {
        setIndex(elementReportDataLength);
        setRenderedElementList(
          elementReportData.slice(0, elementReportDataLength),
        );
      }
    }
  };

  const disableScrollCallback =
    elementReportData && index === elementReportData.length;
  const renderFooter = disableScrollCallback ? () => null : () => <Loading />;

  const onRowClick = (rowData) => {
    if (isSyncScrollingEnabled) {
      scrollLock.current = true;
      setTimeout(() => {
        scrollLock.current = false;
      }, 3000);
    }
    dispatch(
      setSelectedElementDetails({
        selectedElementId: rowData.id,
        isClickedOnReport: true,
      }),
    );
    //scroll the left view
    scrollElementIntoView({
      elementId: rowData.id,
      sectionId: rowData.sectionId,
    });
  };

  //Use to scroll the right view when user click on right view and element list is refreshed
  useEffect(() => {
    if (!isSyncScrollingEnabled && reportPreviewPage.isClickedOnReport) {
      const position = getElementPositionInElementList(
        reportPreviewPage.selectedElementId,
        elementReportData,
      );
      const dataGridList = useVirtualizedList
        ? document.getElementById('data-grid-list')
        : document.getElementById('data-grid');
      if (dataGridList) {
        typeof dataGridList.scrollTo === 'function' &&
          dataGridList.scrollTo({
            top: position,
            behaviour: 'smooth',
            block: 'center',
          });
      }
    }
  }, [reportPreviewPage.selectedElementId, elementReportData.length]);

  //Use to scroll the right view when user click on left side element
  useEffect(() => {
    if (!reportPreviewPage.isClickedOnReport) {
      const position = getElementPositionInElementList(
        reportPreviewPage.selectedElementId,
        elementReportData,
      );
      const dataGridList = useVirtualizedList
        ? document.getElementById('data-grid-list')
        : document.getElementById('data-grid');
      if (dataGridList) {
        typeof dataGridList.scrollTo === 'function' &&
          dataGridList.scrollTo({
            top: position,
            behaviour: 'smooth',
            block: 'center',
          });
      }
    }
  }, [reportPreviewPage.selectedElementId, useVirtualizedList]);

  useEffect(() => {
    if (isSyncScrollingEnabled && !scrollLock.current) {
      const position = getElementPositionInElementList(
        parseInt(visibleFirstElementFromTop),
        elementReportData,
      );
      const dataGridList = useVirtualizedList
        ? document.getElementById('data-grid-list')
        : document.getElementById('data-grid');
      if (dataGridList) {
        typeof dataGridList.scrollTo === 'function' &&
          dataGridList.scrollTo({
            top: position,
            behaviour: 'smooth',
            block: 'center',
          });
      }
    }
  }, [visibleFirstElementFromTop, elementReportData.length]);

  //Logic when clicked on refresh icon
  const onRefreshData = () => {
    setRefreshing(true);
    try {
      if (isOCR) {
        let sectionMetaData = [];
        sectionsInView.sectionIds.forEach((id) => {
          sectionMetaData.push(
            sectionIdList.sectionIds.find(
              (section) => section.sectionId === parseInt(id),
            ),
          );
        });
        if (sectionMetaData.length > 0) {
          dispatch(fetchOCRSectionContent(sectionMetaData));
        }
      } else dispatch(fetchSectionContent(sectionsInView.sectionIds));
    } catch (ex) {
      console.info(
        'An error occured while refreshing section data in preview mode',
      );
      console.error(ex);
    } finally {
      setTimeout(() => {
        setRefreshing(false);
        dispatch(disableRefreshIconAction());
      }, 5000);
    }
  };

  const rowClassNames = (rowData) => {
    if (rowData.id === reportPreviewPage.selectedElementId) {
      if (reportPreviewPage.isClickedOnReport) {
        return ROW_SELECTED;
      } else {
        return ROW_HIGHLIGHT;
      }
    }
  };

  return (
    <div className={REPORT_PREVIEW_RIGHT_BLOCK}>
      <div className={`${REPORT_PREVIEW_RIGHT_BLOCK}__element-report-header`}>
        <div
          className={classNames({
            [`${REPORT_PREVIEW_RIGHT_BLOCK}__element-report-header--title`]: true,
            [`${REPORT_PREVIEW_RIGHT_BLOCK}__element-report-header--title-reverse`]:
              toggleViews,
          })}
        >
          <FormattedMessage
            id={'statement.navigation.report.preview.right.view.subtitle'}
          />
        </div>
        <div className={`${REPORT_PREVIEW_RIGHT_BLOCK}__tools`}>
          <SlideToggle
            label="compare-panel.sync-scroll.toggle.label"
            name={'id'}
            onChange={() => dispatch(onToggleSyncScroll())}
            checked={isSyncScrollingEnabled}
            id={'sync-scroll-toggle'}
            className={`${REPORT_PREVIEW_RIGHT_BLOCK}__sync-scroll-toggle`}
          />
          {shouldEnableRefreshIcon && (
            <div
              className={`${REPORT_PREVIEW_RIGHT_BLOCK}__element-report-header--refresh-icon`}
            >
              <ToolTip
                id={`${REPORT_PREVIEW_RIGHT_BLOCK_ID}-tooltip-refresh-button`}
                text={
                  'workapaper-report-preview.page.element-report.refresh-tooltip'
                }
                position={'left'}
              >
                <RefreshIcon
                  className={classNames({
                    'rotate-icon': isRefreshing,
                  })}
                  onClick={() => {
                    shouldEnableRefreshIcon && !isRefreshing && onRefreshData();
                  }}
                  width={REFRESH_LIST_ICON_SIZE}
                  height={REFRESH_LIST_ICON_SIZE}
                />
              </ToolTip>
            </div>
          )}
          <ReportPreviewPageDisplayOptions
            reportPreviewPageDisplayOptions={reportPreviewPageDisplayOptions}
            setPreviewPageDisplayOptionAction={
              setPreviewPageDisplayOptionAction
            }
            isCftEnabled={isCftEnabled}
            toggleViews={toggleViews}
          />
        </div>
      </div>
      <DataGrid
        primaryKeyColumn={'id'}
        listScroll={useVirtualizedList ? listScroll : null}
        useVirtualizedList={useVirtualizedList}
        virtualizedListFooter={useVirtualizedList ? renderFooter : null}
        virtualizedListDisableScrollCallback={
          useVirtualizedList && disableScrollCallback
        }
        virtualizedListRef={listRef}
        columns={getElementReoprtColumns({
          reportPreviewPageDisplayOptions,
          isSyncScrollingEnabled,
        })}
        tableId={'element-report-list'}
        dataModel={
          new DataGridDataApi({
            apiModel: {
              isLoaded: isSyncScrollingEnabled ? isLoaded : isListLoaded,
              isLoading: isSyncScrollingEnabled ? isLoading : isListLoading,
              error,
              data: elementReportData,
            },
            rowItems: renderedElementList,
          })
        }
        totalRows={elementReportData.length}
        executeScrollImmediately
        onRowClick={onRowClick}
        rowClassnames={rowClassNames}
      />
    </div>
  );
};
export default ReportPreviewRightSideView;
