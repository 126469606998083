import React from 'react';
import ToolTip from 'components/common/tool-tip-component';
import { FormattedMessage } from 'react-intl';

const AUTOMATICALLY_IDENTIFIED_ANNOTATIONS =
  'automatically-identified-annotation';

const AutomaticallyIdentifiedAnnotations = ({ tooltipId }) => {
  return (
    <div className={`${AUTOMATICALLY_IDENTIFIED_ANNOTATIONS}`}>
      <ToolTip
        id={`${tooltipId}__element-automation-tooltip`}
        text={'tieout.elements.automatically.identified'}
        position={'top'}
      >
        <span
          className={`${AUTOMATICALLY_IDENTIFIED_ANNOTATIONS}__automatically-identified-tooltip`}
        >
          <FormattedMessage id="tieout.elements.automatically.identified.display.text" />
        </span>
      </ToolTip>
    </div>
  );
};

export default AutomaticallyIdentifiedAnnotations;
