import React, { memo, useCallback, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import {
  MAX_STATEMENT_FILE_SIZE,
  SUPPORTED_STATEMENT_FILE_TYPES,
} from 'constants/feature/statement-creation-constants';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as Document } from 'icons/document.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';
import Statement from 'models/data/statement-model';
import shortid from 'shortid';
import StatementApi from 'models/form/statement-form-model';
import StatementData from 'models/data/statement-model';

const ADD_REVISION_BLOCK = 'add-revision';
const ADD_REVISION_ID_BLOCK = 'id-add-revision';
const DOCUMENT_ICON_SIZE = '70px';
const CLEAR_ICON_SIZE = '20px';
const STATEMENT_DOC_TYPE = 1;
const STATEMENT_PDF_TYPE = 2;
const PDF_FILE_EXTENSION = 'pdf';
/** For dropzone documentation visit https://react-dropzone.netlify.com/ */
const AddRevisionModal = ({
  setUploadedFilesAddRevision,
  setUploadedFilesAddRevisionError,
  toggleAddRevisionModal,
  revisionInProgress,
  statementToUploadRevisionOn,
  uploadRevision,
  statement,
}) => {
  const [filePDFError, setFilePDFError] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length) {
        const acceptedFileNameExtension =
          acceptedFiles[0].name.split('.')[
            acceptedFiles[0].name.split('.').length - 1
          ];
        if (
          statement.documentProcessType === STATEMENT_DOC_TYPE &&
          acceptedFileNameExtension === PDF_FILE_EXTENSION
        ) {
          setFilePDFError(true);
          setUploadedFilesAddRevisionError(acceptedFiles);
        } else if (
          statement.documentProcessType === STATEMENT_PDF_TYPE &&
          acceptedFileNameExtension !== PDF_FILE_EXTENSION
        ) {
          setFilePDFError(true);
          setUploadedFilesAddRevisionError(acceptedFiles);
        } else {
          setUploadedFilesAddRevision(acceptedFiles);
        }
      }
      if (rejectedFiles.length) {
        //We can only upload one file in this modal
        setUploadedFilesAddRevisionError(rejectedFiles);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUploadedFilesAddRevision, setUploadedFilesAddRevisionError],
  );

  const handleModalClose = () => {
    setUploadedFilesAddRevision(null);
    toggleAddRevisionModal();
  };

  const [selectRevisionButtonDisabled, setSelectRevisionButtonDisabled] =
    useState(false);
  const onFileDialogCancel = () => {
    setSelectRevisionButtonDisabled(false);
  };
  const { getInputProps, open } = useDropzone({
    accept: SUPPORTED_STATEMENT_FILE_TYPES,
    multiple: false,
    onDrop,
    maxSize: MAX_STATEMENT_FILE_SIZE,
    onFileDialogCancel,
    noDrag: true,
  });
  const _isFileError = revisionInProgress.hasFileError();

  const getErrorText = () => {
    return (
      <>
        <h5 className={`${ADD_REVISION_BLOCK}__banner--error-header`}>
          <FormattedMessage id="add-revision-modal.error.unsupported-file-pdf-header" />
        </h5>
        <ol className={`${ADD_REVISION_BLOCK}__banner--error-list-container`}>
          <li className={`${ADD_REVISION_BLOCK}__banner--error-list-item`}>
            <FormattedMessage id="add-revision-modal.error.unsupported-file-pdf-list-one" />
          </li>
          <li>
            <FormattedMessage id="add-revision-modal.error.unsupported-file-pdf-list-two" />
          </li>
        </ol>
      </>
    );
  };
  return (
    <Modal
      id={`${ADD_REVISION_ID_BLOCK}-add-revision-modal`}
      title={'add-revision-modal.title'}
      onClose={() => {
        handleModalClose();
      }}
      primaryModalButton={
        new ModalButton({
          text: 'common.add',
          onClick: () => {
            uploadRevision({
              statement: statementToUploadRevisionOn,
              revision: revisionInProgress,
            });
            toggleAddRevisionModal();
          },
          disabled: !revisionInProgress.hasUploadedFiles() || _isFileError,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: () => {
            handleModalClose();
          },
        })
      }
    >
      <div className={`${ADD_REVISION_BLOCK}__banner`}>
        <Banner
          id={`${ADD_REVISION_ID_BLOCK}-info-banner`}
          type={BANNER_TYPES.INFORMATION}
          bannerCopy={{
            id: 'statement-create.select-statement.revision-upload.info-banner',
            values: {
              b: (...chunks) => <strong>{chunks}</strong>,
              lineBreak: (
                <br
                  key={`${ADD_REVISION_ID_BLOCK}-brTag-${shortid.generate()}}`}
                />
              ),
            },
          }}
          isInternationalized={true}
          isInteractive={false}
          width={'100%'}
        />
      </div>
      {_isFileError ? (
        <div className={`${ADD_REVISION_BLOCK}__banner--error`}>
          <Banner
            id={`${ADD_REVISION_ID_BLOCK}-error-banner`}
            type={BANNER_TYPES.ERROR}
            bannerCopy={
              filePDFError
                ? getErrorText()
                : 'add-revision-modal.error.unsupported-file'
            }
            isInternationalized={!filePDFError}
            isInteractive={false}
            width={'100%'}
          />
        </div>
      ) : null}
      <div>
        <input {...getInputProps()} />
        <div className={`${ADD_REVISION_BLOCK}__drop-container`}>
          <Document
            id={`${ADD_REVISION_ID_BLOCK}-document-icon`}
            width={DOCUMENT_ICON_SIZE}
            height={DOCUMENT_ICON_SIZE}
          />
          <div className={`${ADD_REVISION_BLOCK}__drop-title`}>
            <FormattedMessage id={'add-revision-modal.drop-title'} />
          </div>
          <div className={`${ADD_REVISION_BLOCK}__drop-copy`}>
            <FormattedMessage id={'add-revision-modal.drop-copy'} />
          </div>
          {revisionInProgress.hasUploadedFiles() ? (
            <div className={`${ADD_REVISION_BLOCK}__accepted-files-list`}>
              {revisionInProgress.files.map((file, i) => (
                <div
                  className={`${ADD_REVISION_BLOCK}__accepted-files-row`}
                  key={`${ADD_REVISION_ID_BLOCK}-file-row-${i}`}
                >
                  <div
                    className={classnames(
                      `${ADD_REVISION_BLOCK}__accepted-file-name`,
                      _isFileError
                        ? `${ADD_REVISION_BLOCK}__accepted-file-name--error`
                        : null,
                    )}
                  >
                    {file.name}
                  </div>
                  <Clear
                    id={`${ADD_REVISION_ID_BLOCK}-clear-button-${i}`}
                    className={`${ADD_REVISION_BLOCK}__clear-icon`}
                    role={'button'}
                    width={CLEAR_ICON_SIZE}
                    height={CLEAR_ICON_SIZE}
                    onClick={(e) => {
                      e.stopPropagation(); //nessarry because all clicks inside the dropzone trigger upload
                      setUploadedFilesAddRevision(null);
                      setSelectRevisionButtonDisabled(false);
                      setFilePDFError(false);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : null}
          <Button
            id={`${ADD_REVISION_ID_BLOCK}-select-statement-button`}
            className={`${ADD_REVISION_BLOCK}__select-statement-button`}
            type={BUTTON_TYPES.primary}
            disabled={selectRevisionButtonDisabled}
            onClick={(e) => {
              e.stopPropagation(); //nessarry because all clicks inside the dropzone trigger upload
              setSelectRevisionButtonDisabled(true);
              open();
            }}
          >
            <FormattedMessage
              id={'add-revision-modal.select-statement-button'}
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AddRevisionModal.propTypes = {
  /** Action that fires on adding a revision to a statement */
  uploadRevision: PropTypes.func.isRequired,
  /** Action that sets the uploaded files to the revision in progress */
  setUploadedFilesAddRevision: PropTypes.func.isRequired,
  /** Action that triggers to indicate that react dropzone rejected a new revision on the revision in progress */
  setUploadedFilesAddRevisionError: PropTypes.func.isRequired,
  /** Object representing the working copy of the revision being created.*/
  revisionInProgress: PropTypes.instanceOf(StatementApi).isRequired,
  /** Object representing statement we are uploading a revision on.*/
  statementToUploadRevisionOn: PropTypes.instanceOf(StatementData).isRequired,
  /** Method on the statement list component that opens the add revision modal.*/
  toggleAddRevisionModal: PropTypes.func.isRequired,
  /** Statement for which revision is being added */
  statement: PropTypes.instanceOf(Statement).isRequired,
};

export default memo(AddRevisionModal);
