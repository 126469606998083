import { handleActions } from 'redux-actions';
import { setHideOrDisplayElements } from 'store/actions/statement-summary/statement-summary-elements-display-actions';
import StatementSummaryElementsDisplay from 'models/data/statement-summary-elements-display-model';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [setHideOrDisplayElements]: (
      statementSummaryElementsDisplay,
      { payload },
    ) => {
      return statementSummaryElementsDisplay.setDisplayElementDetailsOnStatementPage(
        payload,
      );
    },
    [clearStatementPageStoreAction]: () => {
      return new StatementSummaryElementsDisplay();
    },
  },
  new StatementSummaryElementsDisplay(),
);
