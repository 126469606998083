import React, { useState, useEffect } from 'react';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import StatementNavBulkSelectUsers from './_statement-nav-bulk-select-users';
import StatementNavBulkSelectSections from './_statement-nav-bulk-select-sections';
import {
  BULK_ACTIONS_RADIO_OPTIONS_ARRAY,
  SECTIONS_BULK_ACTIONS_TYPE,
} from 'constants/feature/statement-navigator/section-bulk-assign-review-constants';
import Checkbox from 'components/common/checkbox-component';
import Radio from 'components/common/radio-component';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import SectionAssignmentsList from 'models/api/section-assignments-list-api-model';
import {
  createRevisionSectionAssignment,
  unassignRevisionSectionAssignment,
} from 'api/bulk-assign-unassign-api';
import shortid from 'shortid';
import { getUserNameByUserId } from 'utils/statement-content-page-utils';

export const BULK_ASSIGN_SECTIONS_MODAL_BLOCK = 'bulk-assign-sections';
export const BULK_ASSIGN_SECTIONS_MODAL_ID = 'bulk-assign-sections-id';

const BulkAssignSectionsModal = ({
  onClose,
  usersList,
  revisionId,
  sectionTreeList,
  sectionAssignments,
  currentSection,
  updateBulkSectionAssignmentsList,
  updateBulkSectionsReviewRequest,
  updateBulkSectionsUnReviewRequest,
  socketHasBeenDisconnected,
}) => {
  const [collapsibleNodesState, setCollapsibleNodesState] = useState(null);
  const [sectionsChecked, setSectionschecked] = useState(new Map());
  const [checkAll, setCheckAll] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [sectionBulkAction, setSectionBulkAction] = useState(
    SECTIONS_BULK_ACTIONS_TYPE.ASSIGN,
  );
  const [assignedUsers, setAssignedUsers] = useState({});
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const max_count = useRef(0);
  const BULK_ASSIGNMENT_DEFAULT_EXPANDED_VALUE = true;

  useEffect(() => {
    setCollapsibleNodesState(
      sectionTreeList.createCollapseStateMapFromNodeIds({
        initNodeValue: BULK_ASSIGNMENT_DEFAULT_EXPANDED_VALUE,
      }),
    );
    setSectionschecked(
      sectionTreeList.createContentSearchFilterState({ initNodeValue: false }),
    );
    max_count.current = [
      ...sectionTreeList.createContentSearchFilterState({
        initNodeValue: false,
      }),
    ].length;
  }, [BULK_ASSIGNMENT_DEFAULT_EXPANDED_VALUE, sectionTreeList]);

  useEffect(() => {
    setSectionschecked(
      sectionTreeList.createContentSearchFilterState({
        initNodeValue: checkAll,
      }),
    );
    setCheckedCount(checkAll === true ? max_count.current : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAll]);

  const toggleNode = (id) => {
    setCollapsibleNodesState(
      new Map([...collapsibleNodesState, [id, !collapsibleNodesState.get(id)]]),
    );
  };

  const setChecked = (id, val) => {
    setSectionschecked((prevSectionsChecked) =>
      new Map(prevSectionsChecked).set(id, val),
    );
    val === true
      ? setCheckedCount((prev) => prev + 1)
      : setCheckedCount((prev) => prev - 1);
  };

  const setAllChecked = (val = true) => {
    setCheckAll(val);
  };

  const renderTree = (nodes) =>
    Array.isArray(nodes) &&
    nodes.map((node, i) => {
      return (
        <StatementNavBulkSelectSections
          key={`${node.id}`}
          id={node.id}
          label={node.name}
          expanded={collapsibleNodesState.get(node.id)}
          toggle={(id) => toggleNode(id)}
          checked={sectionsChecked.get(node.id) === true}
          setChecked={setChecked}
          sectionAssignments={sectionAssignments}
          usersList={usersList}
        >
          {renderTree(node.children)}
        </StatementNavBulkSelectSections>
      );
    });

  const handleUserSelect = (user) => {
    setAssignedUsers({
      ...assignedUsers,
      [user.id]: {
        name: getUserNameByUserId(user.id),
      },
    });
  };

  const handleChipRemove = (key) => {
    let assignedUsersMap = { ...assignedUsers };
    if (key in assignedUsers) {
      delete assignedUsersMap[key];
    }
    setAssignedUsers(assignedUsersMap);
  };

  const handlesectionAssign = async () => {
    setDisableSaveButton(true);
    onClose();
    let sectionIds = [];
    sectionsChecked.forEach((value, key) => {
      value && sectionIds.push(key);
    });

    const userIds = Object.keys(assignedUsers);
    await createRevisionSectionAssignment({ revisionId, sectionIds, userIds });
    if (socketHasBeenDisconnected) {
      await updateBulkSectionAssignmentsList({
        revisionId,
        sectionId: currentSection.id,
        usersList: usersList,
      });
    }
  };

  const handleSectionUnassign = async () => {
    setDisableSaveButton(true);
    onClose();
    let sectionIds = [];
    sectionsChecked.forEach((value, key) => {
      value && sectionIds.push(key);
    });

    const userIds = Object.keys(assignedUsers);
    await unassignRevisionSectionAssignment({
      revisionId,
      sectionIds,
      userIds,
    });
    if (socketHasBeenDisconnected) {
      await updateBulkSectionAssignmentsList({
        revisionId,
        sectionId: currentSection.id,
        usersList: usersList,
      });
    }
  };

  const handleSectionReview = async () => {
    setDisableSaveButton(true);
    onClose();
    const sectionIds = [];
    sectionsChecked.forEach((value, key) => {
      value && sectionIds.push(key);
    });

    await updateBulkSectionsReviewRequest({
      revisionId,
      sectionIds,
      currentSection,
    });
  };

  const handleSectionUnReview = async () => {
    setDisableSaveButton(true);
    onClose();
    const sectionIds = [];
    sectionsChecked.forEach((value, key) => {
      value && sectionIds.push(key);
    });

    await updateBulkSectionsUnReviewRequest({
      revisionId,
      sectionIds,
      currentSection,
    });
  };

  const disableSaveButtonForAssignAction = () => {
    return (
      ![...sectionsChecked.values()].some((ele) => ele) ||
      !Object.keys(assignedUsers).length ||
      disableSaveButton
    );
  };

  const disableSaveButtonForOtherThanAssignAction = () => {
    return (
      ![...sectionsChecked.values()].some((ele) => ele) || disableSaveButton
    );
  };

  const bulkActionToHandlerMap = {
    [SECTIONS_BULK_ACTIONS_TYPE.ASSIGN]: handlesectionAssign,
    [SECTIONS_BULK_ACTIONS_TYPE.UNASSIGN]: handleSectionUnassign,
    [SECTIONS_BULK_ACTIONS_TYPE.REVIEW]: handleSectionReview,
    [SECTIONS_BULK_ACTIONS_TYPE.UNREVIEW]: handleSectionUnReview,
  };

  const bulkActionToDisableFunctionMap = {
    [SECTIONS_BULK_ACTIONS_TYPE.ASSIGN]: disableSaveButtonForAssignAction,
    [SECTIONS_BULK_ACTIONS_TYPE.UNASSIGN]:
      disableSaveButtonForOtherThanAssignAction,
    [SECTIONS_BULK_ACTIONS_TYPE.REVIEW]:
      disableSaveButtonForOtherThanAssignAction,
    [SECTIONS_BULK_ACTIONS_TYPE.UNREVIEW]:
      disableSaveButtonForOtherThanAssignAction,
  };
  return (
    <>
      <Modal
        id={BULK_ASSIGN_SECTIONS_MODAL_ID}
        title={'statement-nav-bulk-assign-modal-title.bulk.heading.assign'}
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'common.save',
            onClick: bulkActionToHandlerMap[sectionBulkAction],
            disabled: bulkActionToDisableFunctionMap[sectionBulkAction](),
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.cancel',
            onClick: onClose,
          })
        }
      >
        <div className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__wrapper`}>
          <Banner
            width={'100%'}
            id={`${BULK_ASSIGN_SECTIONS_MODAL_ID}-warning-guidance`}
            className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__guidance`}
            type={BANNER_TYPES.WARNING}
            bannerCopy={{
              id: 'statement-nav-bulk-assign-modal-warning-guidance.select.multiple.heading.sections',
              values: {
                b: (...chunks) => <strong>{chunks}</strong>,
                lineBreak: (
                  <br
                    key={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}-brTag-${shortid.generate()}}`}
                  />
                ),
              },
            }}
            isInternationalized
          />

          <div className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__actions`}>
            <div
              className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__actions-title`}
            >
              <FormattedMessage id="statement-navigator.bulk-action.radio.title" />
            </div>

            <Radio
              id={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__radio-id`}
              className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__radio`}
              options={BULK_ACTIONS_RADIO_OPTIONS_ARRAY}
              isValid
              selectedOption={sectionBulkAction}
              onChange={(val) => setSectionBulkAction(val)}
            />
          </div>

          <div>
            {sectionBulkAction === SECTIONS_BULK_ACTIONS_TYPE.ASSIGN && (
              <StatementNavBulkSelectUsers
                usersList={usersList}
                handleUserSelect={handleUserSelect}
                handleChipRemove={handleChipRemove}
                assignedUsers={assignedUsers}
              />
            )}
            <div className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__bookmarks`}>
              <div
                className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__bookmarks-title`}
              >
                <FormattedMessage id="statement-navigator.bulk-action.bookmarks.title" />
              </div>
              <div
                className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__bookmarks-list`}
              >
                <div
                  className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__select-all-and-count`}
                >
                  <Checkbox
                    masterChecked={
                      checkedCount > 0 && checkedCount < max_count.current
                    }
                    checked={checkAll}
                    className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__item--col1__checkbox`}
                    disabled={false}
                    id={`${BULK_ASSIGN_SECTIONS_MODAL_ID}-select-all-checkbox`}
                    name={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}-select-all-checkbox`}
                    onChange={() => {
                      setAllChecked((prev) => !prev);
                    }}
                    isNotIntl={false}
                    label={'common.master-checkbox'}
                  />
                  <p className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__count`}>
                    <FormattedMessage
                      id={
                        'custom-menu-panel-content-total-selected.description'
                      }
                      values={{ count: checkedCount }}
                    />
                  </p>
                </div>
                <div
                  className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__tree-view-container`}
                >
                  {collapsibleNodesState &&
                    renderTree(sectionTreeList.sectionsHierarchy)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

BulkAssignSectionsModal.propTypes = {
  /**function fired to close the modal */
  onClose: PropTypes.func.isRequired,
  /**Array which contains objects containing user details */
  usersList: PropTypes.array.isRequired,
  /**An object which contains list of sections */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList).isRequired,
  /** the id of the selected revision */
  revisionId: PropTypes.number.isRequired,
  /** A reference to the assignments of the selected section */
  sectionAssignments: PropTypes.instanceOf(SectionAssignmentsList).isRequired,
  /** Section from the section detail store */
  currentSection: PropTypes.object.isRequired,
  /** Action to update/fetch bulk assignments for a section */
  updateBulkSectionAssignmentsList: PropTypes.func.isRequired,
  /** Action to update/fetch bulk review for a section */
  updateBulkSectionsReviewRequest: PropTypes.func.isRequired,
  /** Action to update/fetch bulk un review for a section */
  updateBulkSectionsUnReviewRequest: PropTypes.func.isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
};

export default BulkAssignSectionsModal;
