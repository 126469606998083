import BaseModel from 'models/base-model';
import { SocketClient, SOCKET_CONNECTION_TYPE } from 'socket/socketio-client';
import { SignalrClient } from 'socket/signalr-client';
import { getStore } from 'store/store';
import { statementWorkflowsLoaded } from 'store/actions/statement-workflows-actions';
import { STATEMENT_LIST_TABS } from 'constants/feature/statement-list-constants';
import { NOTIFICATION_EVENT_TYPES } from 'constants/web-socket-constants';
import {
  requestGetStatementList,
  onCopyStatementSuccess,
  onCopyStatementFailure,
  _isCopyErrorForWrongSourceProject,
  copyErrorNotificationForWrongSourceProject,
} from 'store/actions/statement-list/statement-list-actions';
import { clearStatementWorkflows } from 'store/actions/statement-workflows-actions';
import {
  getWebSocketNotificationType,
  getWebSocketPayload,
} from 'constants/util/web-socket-utils';
import { isSignalRForSocketConnectionEnabled } from 'api/api-authentication';
import { toast } from 'react-toastify';
import { updateSelectedProject } from 'store/actions/selected-project-actions';

export default class ProjectSocketModel extends BaseModel({
  client: null,
  socketHasBeenDisconnected: true,
}) {
  init(config) {
    if (isSignalRForSocketConnectionEnabled) {
      const client = new SignalrClient({
        ...config,
        isProjectLevelConnection: true,
      });
      return this.merge({ ...this, client, socketHasBeenDisconnected: false });
    } else {
      const client = new SocketClient({
        ...config,
        socketConnectionType: SOCKET_CONNECTION_TYPE.PROJECT,
      });
      return this.merge({ ...this, client, socketHasBeenDisconnected: false });
    }
  }

  setProjectSocketClient() {
    if (this.client) {
      this.client.on('newMessage', this.onEventHandler);
    }
    return this;
  }

  stopProjectSocketConnection() {
    //if application connected to signalr
    if (
      isSignalRForSocketConnectionEnabled &&
      this.client &&
      this.client.connection
    ) {
      this.client.connection.stop();
    }
    //if application connected to socketio
    if (!isSignalRForSocketConnectionEnabled && this.client) {
      this.client._closeSocketConnection();
      this.client.disconnect();
      return this.merge({ ...this, socketHasBeenDisconnected: true });
    }
    return this;
  }

  setProjectSocketDisconnect(payload) {
    return this.merge({ ...this, socketHasBeenDisconnected: payload });
  }

  onEventHandler = async (event) => {
    const { dispatch, getState } = getStore();
    const webSocketPayload = getWebSocketPayload(event);
    const response = {
      data: webSocketPayload && webSocketPayload.documentProcessWorkflowEntity,
    };
    switch (getWebSocketNotificationType(event)) {
      case NOTIFICATION_EVENT_TYPES.WORKFLOW_STATUS:
        {
          await dispatch(statementWorkflowsLoaded({ response }));
          const updatedWorkflowMap = getState().data.workflowsMap;
          const selectedStatementsTab =
            getState().data.statementList.statementListFilters.selectedTab;
          const { statementWorkflowSteps } = getState().ui.statementPage;
          if (updatedWorkflowMap) {
            updatedWorkflowMap.getWorkflowsListData().forEach((workflow) => {
              if (
                workflow.status ===
                statementWorkflowSteps.isCompletedWorkFlowStep(
                  workflow.documentProcessTypeEnum,
                ).status
              ) {
                if (webSocketPayload['clientLegalHold']) {
                  dispatch(
                    updateSelectedProject({
                      projectId: webSocketPayload.clientId,
                      clientLegalHold: webSocketPayload.clientLegalHold,
                    }),
                  );
                }
                if (selectedStatementsTab === STATEMENT_LIST_TABS.ACTIVE)
                  dispatch(requestGetStatementList({ withLoading: false }));
                dispatch(clearStatementWorkflows());
              }
            });
          }
        }
        break;

      case NOTIFICATION_EVENT_TYPES.STATEMENT_COPY_SUCCESSFUL:
        await dispatch(onCopyStatementSuccess());
        break;
      case NOTIFICATION_EVENT_TYPES.STATEMENT_COPY_FAILED:
        if (_isCopyErrorForWrongSourceProject(webSocketPayload)) {
          toast.error(copyErrorNotificationForWrongSourceProject());
          return;
        }
        await dispatch(onCopyStatementFailure());
        break;
      default:
        break;
    }
  };
}
