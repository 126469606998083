import { getRevisionsSectionsRequest } from 'api/sections-api';
import { createAction } from 'redux-actions';

export const sectionTreeListLoading = createAction('SECTION_TREE_LIST_LOADING');
export const sectionTreeListError = createAction('SECTION_TREE_LIST_ERROR');
export const sectionTreeListLoaded = createAction('SECTION_TREE_LIST_LOADED');
export const setCreateSectionAction = createAction('SET_CREATE_SECTION_ACTION');
export const addNewSectionAction = createAction('ADD_NEW_SECTION_ACTION');
export const removeNewSectionAction = createAction('REMOVE_NEW_SECTION_ACTION');
export const clearSectionTreeListAction = createAction(
  'CLEAR_SECTION_TREE_LIST_ACTION',
);
export const setNewSectionIdAction = createAction('SET_NEW_SECTION_ID_ACTION');

export const fetchSectionTreeAction =
  ({ revisionId, statementId }) =>
  async (dispatch) => {
    try {
      const response = await getRevisionsSectionsRequest({
        statementId,
        revisionId,
        onlyBookmarkSection: true,
        asTreeList: true,
      });
      dispatch(sectionTreeListLoaded(response));
    } catch (error) {
      dispatch(sectionTreeListError(error));
    }
  };
