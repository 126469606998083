import React from 'react';
import { isNullOrUndefined } from 'utils/object-utils';

import MultilineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { DataGridColumn } from 'components/common/data-grid/data-grid-component';

const REPORT_PREVIEW_COLUMN = 'report_preview_column';
const ITEM_HEIGHT = 48;

export const SCALING_TYPES = {
    1: 'Ones',
    2: 'Thousands',
    3: 'Millions',
    4: 'Billions',
    5: 'Thousands',
    6: 'Other',
};

export const UNIT_TYPES = {
    1: 'Currency',
    2: 'Percentage',
    3: 'Shares',
    4: 'Text',
    5: 'Other',
};

const statusConstants = {
    STATUS_REVIEWED_FLAGGED: 'Reviewed and Flagged',
    STATUS_REVIEWED: 'Reviewed',
    STATUS_VERFIED_FLAGGED: 'Verified and Flagged',
    STATUS_VERIFIED: 'Verified',
    STATUS_UNVERIFIED_FLAGGED: 'Unverified and Flagged',
    STATUS_UNVERIFIED: 'Unverified',
};

const buildStatus = (elementEntity) => {
    let elementFlagged = false;
    if (
        elementEntity.flagInternalRef ||
        elementEntity.flagUser ||
        elementEntity.flagFormula ||
        elementEntity.flagOverride
    ) {
        elementFlagged = true;
    }
    if (elementEntity.reviewed) {
        return elementFlagged ?
            statusConstants.STATUS_REVIEWED_FLAGGED :
            statusConstants.STATUS_REVIEWED;
    } else if (elementEntity.internalVerified) {
        return elementFlagged ?
            statusConstants.STATUS_VERFIED_FLAGGED :
            statusConstants.STATUS_VERIFIED;
    } else {
        return elementFlagged ?
            statusConstants.STATUS_UNVERIFIED_FLAGGED :
            statusConstants.STATUS_UNVERIFIED;
    }
};

export const getElementReoprtColumns = ({
    reportPreviewPageDisplayOptions,
}) => {
    let elementReportColumns = {
        elementId: new DataGridColumn({
            key: 'id',
            header: 'statement-review.table.column.elementId',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-elementId-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { rowData.previewSequenceID }
                    isNotInternationalized /
                    >
                );
            },
        }),
        displayValue: new DataGridColumn({
            key: 'display',
            header: 'statement-review.table.column.display',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-display-value-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { rowData.display }
                    isNotInternationalized /
                    >
                );
            },
        }),
        scaledValue: new DataGridColumn({
            key: 'amount',
            header: 'statement-review.table.column.actual',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-actual-value-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { `${rowData.amount}` }
                    isNotInternationalized /
                    >
                );
            },
        }),
        pageNo: new DataGridColumn({
            key: 'sectionId',
            header: 'statement-review.table.column.sectionId',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-section-id-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { rowData.sectionId }
                    isNotInternationalized /
                    >
                );
            },
        }),
        label: new DataGridColumn({
            key: 'label',
            header: 'statement-review.table.column.label',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-label-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { rowData.label }
                    isNotInternationalized /
                    >
                );
            },
        }),
        status: new DataGridColumn({
            key: 'status',
            header: 'statement-review.table.column.status',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-status-${rowKey}`;
                const status = buildStatus(rowData);
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { status }
                    isNotInternationalized /
                    >
                );
            },
        }),
        workpaper: new DataGridColumn({
            key: 'workpaperReferenceNumbers',
            header: 'statement-review.table.column.workpaper',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const workpapers =
                    rowData &&
                    rowData.elementAnnotations &&
                    rowData.elementAnnotations.workpaperReferenceNumberList &&
                    rowData.elementAnnotations.workpaperReferenceNumberList.toString();
                const colId = `${REPORT_PREVIEW_COLUMN}-workpaper-reference-number-${rowKey}`;

                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { workpapers }
                    isNotInternationalized /
                    >
                );
            },
        }),
        tickmarks: new DataGridColumn({
            key: 'tickmarks',
            header: 'statement-review.table.column.tickmark',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const tickmarks =
                    rowData &&
                    rowData.elementAnnotations &&
                    rowData.elementAnnotations.tickmarks &&
                    rowData.elementAnnotations.tickmarks.replace(/}{/g, '},{');
                const colId = `${REPORT_PREVIEW_COLUMN}-tickmarks-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { tickmarks }
                    isNotInternationalized /
                    >
                );
            },
        }),
        formula: new DataGridColumn({
            key: 'formulaCell',
            header: 'statement-review.table.column.formula',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-formula-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { rowData.formulaCell }
                    isNotInternationalized /
                    >
                );
            },
        }),
        internalRef: new DataGridColumn({
            key: 'internalReference',
            header: 'statement-review.table.column.internalreference',
            width: '160px',
            formatter: (rowData) => {
                const internalReference =
                    rowData &&
                    rowData.elementAnnotations &&
                    rowData.elementAnnotations.irGroupName;
                return <div > { internalReference } < /div>;
            },
        }),
        comfortLetter: new DataGridColumn({
            key: 'comfortLetterlabelList',
            header: 'statement-review.table.column.comfortletter',
            width: '160px',
            className: 'comfort-letter',
            formatter: (rowData, { rowKey }) => {
                const comfortLetters =
                    rowData &&
                    rowData.elementAnnotations &&
                    rowData.elementAnnotations.comfortLetterDto &&
                    rowData.elementAnnotations.comfortLetterDto
                    .map((item) => `[${item.label}]- ${item.text}`)
                    .join(', ');
                const colId = `${REPORT_PREVIEW_COLUMN}-comfort-letters-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { comfortLetters }
                    isNotInternationalized /
                    >
                );
            },
        }),
        scaling: new DataGridColumn({
            key: 'scaling',
            header: 'statement-review.table.column.scaling',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-scaling-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { SCALING_TYPES[rowData.scalingId] }
                    isNotInternationalized /
                    >
                );
            },
        }),
        units: new DataGridColumn({
            key: 'units',
            header: 'statement-review.table.column.units',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-units-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { UNIT_TYPES[rowData.unitsId] }
                    isNotInternationalized /
                    >
                );
            },
        }),
        period: new DataGridColumn({
            key: 'period',
            header: 'statement-review.table.column.period',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-period-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { rowData.periodType + rowData.fiscalYear }
                    isNotInternationalized /
                    >
                );
            },
        }),
        entity: new DataGridColumn({
            key: 'entity',
            header: 'statement-review.table.column.entity',
            width: '160px',
            formatter: (rowData, { rowKey }) => {
                const colId = `${REPORT_PREVIEW_COLUMN}-entity-${rowKey}`;
                return ( <
                    MultilineEllipsis toolTipID = { colId }
                    text = { rowData.entity }
                    isNotInternationalized /
                    >
                );
            },
        }),
    };

    const elementColumns = [];

    Object.keys(elementReportColumns).map((item) => {
        if (reportPreviewPageDisplayOptions[item]) {
            elementColumns.push(elementReportColumns[item]);
        }
    });

    return elementColumns;
};

export const getElementListBySections = (sectionsInView, elementCache) => {
    const elementList = [];
    for (let i of sectionsInView) {
        const sectionElementDetails = elementCache[i];
        if (!isNullOrUndefined(sectionElementDetails) &&
            sectionElementDetails.isLoaded
        ) {
            const elements = Object.values(sectionElementDetails.elements);
            elementList.push(...elements);
        }
    }
    return elementList;
};

export const getElementPositionInElementList = (
    elementId,
    elementReportData,
) => {
    const index = !isNullOrUndefined(elementId) &&
        elementReportData.findIndex(
            (element) => parseInt(element.id) === elementId,
        );

    const position = index * ITEM_HEIGHT;
    return position;
};