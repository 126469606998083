import { createAction } from 'redux-actions';
import { getNotesByRevisionAndSectionRequest } from 'api/notes-api';
import { CONTEXT_KEY } from 'api/api-client';

export const notesCacheSectionLoading = createAction(
  'NOTES_CACHE_SECTION_LOADING',
);
export const notesCacheSectionLoaded = createAction(
  'NOTES_CACHE_SECTION_LOADED',
);
export const notesCacheSectionError = createAction('NOTES_CACHE_SECTION_ERROR');

export const leftNotesCacheSectionLoading = createAction(
  'LEFT_NOTES_CACHE_SECTION_LOADING',
);
export const leftNotesCacheSectionLoaded = createAction(
  'LEFT_NOTES_CACHE_SECTION_LOADED',
);
export const leftNotesCacheSectionError = createAction(
  'LEFT_NOTES_CACHE_SECTION_ERROR',
);

export const removeNoteFromCacheWithSocketPayloadAction = createAction(
  'REMOVE_NOTE_FROM_CACHE_WITH_SOCKET_PAYLOAD_ACTION',
);

export const updateNoteFromNoteCacheWithSocketPayloadAction = createAction(
  'UPDATE_NOTE_FROM_NOTE_CACHE_WITH_SOCKET_PAYLOAD',
);

export const fetchNotesBySection =
  ({ sectionId }) =>
  (dispatch, getStore) => {
    const { revision } = getStore().data;
    dispatch(notesCacheSectionLoading({ sectionId }));
    getNotesByRevisionAndSectionRequest({
      sectionId,
      revisionId: revision.id,
    })
      .then((response) => {
        dispatch(
          notesCacheSectionLoaded({
            response,
            sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(notesCacheSectionError({ sectionId, error }));
      });
  };

export const fetchLeftNotesBySection =
  ({ sectionId, statementId, projectId }) =>
  (dispatch, getStore) => {
    const {
      sourceStatementParams: { revisionId },
    } = getStore().ui;
    dispatch(leftNotesCacheSectionLoading({ sectionId }));
    getNotesByRevisionAndSectionRequest({
      statementId,
      sectionId,
      revisionId,
      contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
      contextValue: projectId,
    })
      .then((response) => {
        dispatch(
          leftNotesCacheSectionLoaded({
            response,
            sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(leftNotesCacheSectionError({ sectionId, error }));
      });
  };
