import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'components/common/tool-tip-component';
import SummarySections from 'models/api/statement-summary-sections-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { ReactComponent as TaskReviewedIcon } from 'icons/task-reviewed-ocr.svg';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';
import classnames from 'classnames';

export const STATEMENT_SUMMARY_SECTIONS_BLOCK = 'statement-summary-sections';

const StatementSummarySectionsComponent = ({
  summarySections,
  displayOptions,
  disabled,
}) => {
  if (isNullOrUndefined(summarySections)) {
    return null;
  }

  const TASK_REVIEWED_ICON = (
    <TaskReviewedIcon className={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__icon`} />
  );

  return (
    displayOptions.signedOff && (
      <div className={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}`}>
        <Tooltip
          id={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__tooltip`}
          text={
            disabled ? null : 'statement-summary.sections.label-tooltip-ocr'
          }
          position="bottom"
        >
          <button
            className={classnames({
              [`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__button`]: true,
              [`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__button--disabled`]:
                disabled,
            })}
            disabled={disabled}
          >
            {TASK_REVIEWED_ICON}
            <div className={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__sign-off`}>
              {displayOptions.percentage &&
                `${summarySections.reviewedPercentage()}%`}
              {displayOptions.count &&
                ` (${summarySections.reviewed}/${summarySections.total})`}
            </div>
          </button>
        </Tooltip>
      </div>
    )
  );
};

StatementSummarySectionsComponent.propTypes = {
  /** The count of notes for the selected revision id */
  summarySections: PropTypes.instanceOf(SummarySections),
  /** Obj containing display options from store */
  displayOptions: PropTypes.instanceOf(StatementSummaryDisplayOptions)
    .isRequired,
};

export default StatementSummarySectionsComponent;
