import { handleActions } from 'redux-actions';

import ChatBot from 'models/api/chatbot-model';
import {
  chatBotPushIndexLoading,
  chatBotPushIndexLoaded,
  chatBotPushIndexError,
  clearSelectedStatement,
} from 'store/actions/selected-statement-actions';

export default handleActions(
  {
    [chatBotPushIndexLoading]: (chatbot) => {
      return chatbot.setLoading();
    },
    [chatBotPushIndexLoaded]: (chatbot, { payload }) => {
      return chatbot.setLoaded(payload);
    },
    [chatBotPushIndexError]: (chatbot, { payload }) => {
      return chatbot.setError(payload);
    },
    [clearSelectedStatement]: (chatbot) => {
      return new ChatBot();
    },
  },
  new ChatBot(),
);
