import React from 'react';
import { connect } from 'react-redux';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import { BLOCK_ID } from 'components/feature/statement-content-panel/statement-content-panel-component-ocr';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';

const Citation = ({
  BLOCK,
  groupedCitations,
  setOcrCurrentViewPageNumberDispatch,
}) => {
  const navigateToSelectedPage = (page) => {
    setOcrCurrentViewPageNumberDispatch(page);
    const element = document.getElementById(`${BLOCK_ID}${page}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  return (
    <>
      <div className={`${BLOCK}__message-bubble--message-citation-title`}>
        <FormattedMessage id="chatbot-response.sources" />
      </div>
      {Object.keys(groupedCitations).map((pageNumber) => {
        const page = (
          <span
            className={`${BLOCK}__message-bubble--page-link`}
            onClick={() => navigateToSelectedPage(pageNumber)}
          >
            <FormattedMessage
              id="chatbot-response.page-number"
              values={{ pageNumber: pageNumber }}
            />
          </span>
        );
        const citation = groupedCitations[pageNumber].map((citationString) => {
          return (
            <div className={`${BLOCK}__message-bubble--citation-content`}>
              <ReactMarkdown>{citationString}</ReactMarkdown>
            </div>
          );
        });
        return (
          <div className={`${BLOCK}__message-bubble--citation-container`}>
            {[page, citation]}
          </div>
        );
      })}
    </>
  );
};

const mapDispatchToProps = {
  setOcrCurrentViewPageNumberDispatch,
};

export default connect(null, mapDispatchToProps)(Citation);
