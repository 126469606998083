import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import Card from 'components/common/card-component';
import TextInput from 'components/common/text-input-component';
import Dropdown from 'components/common/dropdown-component';
import CustomDatePicker from 'constants/feature/dateComponent/customDatePicker';
import Checkbox from 'components/common/checkbox-component';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import {
  STATEMENT_TYPE_OPTIONS,
  QUARTER_OPTIONS,
  FISCAL_YEAR_OPTIONS,
  ACCOUNTING_BASIS_OPTIONS,
  REGISTRANT_OPTIONS,
  SCALING_OPTIONS,
  UNIT_OPTIONS,
  LEGAL_HOLD_TOOLTIP,
  ENABLE_LEGAL_HOLD_TOOLTIP,
  DISABLE_LEGAL_HOLD_TOOLTIP,
  ENABLE_AUTO_IR_TOOLTIP,
  AUTO_IR_TOOLTIP,
  ENABLE_COMFORT_LETTER_TOOLTIP,
  DISABLED_COMFORT_LETTER_TOOLTIP,
} from 'constants/feature/statement-creation-constants';
import StatementForm from 'models/form/statement-form-model';
import RolesUtil from 'permissions/utils/roles';
import { fetchStatementCreationPageData } from 'store/actions/statement-creation-page-data-actions';
import StatementCreationPageData from 'models/api/statement-creation-page-data-api-model';
import { ReactComponent as InfoIcon } from 'icons/icon-information-tooltip.svg';
import { SearchDropdownApi } from 'components/common/search-component';
import { SEARCH_DROPDOWN_CLOSE_ICON_SIZE } from '../statement-creation/statement-creation-statement-info-component';

export const STATEMENT_EDIT_INFO_BLOCK = 'statement-edit-info';
const STATEMENT_EDIT_INFO_ID_BLOCK = 'statement-edit-info-id';
const INFORMATION_ICON_SIZE = '18px';

export class StatementEditInfo extends Component {
  constructor() {
    super();
    this.state = {
      copyActiveStatements: new StatementForm(),
    };
  }

  componentDidMount() {
    const { fetchStatementCreationPageData } = this.props;
    fetchStatementCreationPageData();
    this.setState({
      copyActiveStatements: JSON.parse(
        JSON.stringify(this.props.statementInProgress),
      ),
    });
  }
  render() {
    const {
      statementInProgress,
      setStatementName,
      setLanguageType,
      setDateFormat,
      setNumberFormat,
      setStatementType,
      setQuarter,
      setFiscalYear,
      setPeriodEndingDate,
      setAccountingBasis,
      setRegistrant,
      setWrapUpDate,
      setLegalHold,
      setAutoIdentifyIrs,
      setComfortLetter,
      setDefaultScaling,
      setDefaultUnit,
      statementCreationPageData,
    } = this.props;
    const { copyActiveStatements } = this.state;
    const projectId = statementInProgress.data.clientId;
    const selectedLanguage = statementCreationPageData.selectedLanguageValue(
      statementInProgress.languageType,
    );

    const hasPermissionToDisableLegalHold =
      RolesUtil.doesUserHavePPMDRoleForProject(projectId);
    const hasPermissionToEnableLegalHold =
      RolesUtil.doesUserHavePPMDRoleForProject(projectId) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectId);
    const isLegalHoldCheckboxDisabled = copyActiveStatements.data.legalHold
      ? !hasPermissionToDisableLegalHold
      : !hasPermissionToEnableLegalHold;

    const hasPermissionToEnableAutoIr =
      RolesUtil.doesUserHavePPMDRoleForProject(projectId) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectId) ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectId);

    const hasPermissionToEnableComfortLetter =
      RolesUtil.doesUserHavePPMDRoleForProject(projectId) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectId) ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectId);

    return (
      <div className={`${STATEMENT_EDIT_INFO_BLOCK}`}>
        <Card>
          <h3 className={`${STATEMENT_EDIT_INFO_BLOCK}__title`}>
            <FormattedMessage id={'statement-edit.statement-info.title'} />
          </h3>
          <React.Fragment>
            <div className={`${STATEMENT_EDIT_INFO_BLOCK}__info`}>
              <Banner
                id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-correct-parameters-banner`}
                className={`${STATEMENT_EDIT_INFO_BLOCK}__banner`}
                type={BANNER_TYPES.WARNING}
                bannerCopy={
                  'statement-edit.statement-warning.banner.correct-parameters'
                }
                isInternationalized={true}
                width={'100%'}
              />
              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__input-row`}>
                <TextInput
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-statement-name`}
                  className={`${STATEMENT_EDIT_INFO_BLOCK}__statement-name`}
                  label={'statement-edit.statement-info.statement-name.label'}
                  placeholder={
                    'statement-edit.statement-info.statement-name.placeholder'
                  }
                  width={'25%'}
                  onChange={setStatementName}
                  isValid={statementInProgress.isStatementNameValid()}
                  errorText={
                    statementInProgress.doesStatementNameExist()
                      ? 'statement-edit.statement-info.statement-name.too-long-error'
                      : 'common.error-text.required'
                  }
                  type={'text'}
                  value={statementInProgress.statementName}
                />
                <SearchDropdownApi
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-language-type`}
                  label={'statement-edit.statement-info.language-type.label'}
                  placeholder={'common.placeholder.select'}
                  width={'25%'}
                  value={selectedLanguage ? selectedLanguage.title : null}
                  clearIconSize={SEARCH_DROPDOWN_CLOSE_ICON_SIZE}
                  isValid
                  disabled
                  showSearchButton
                />
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-date-format`}
                  label={'statement-edit.statement-info.dateFormat.label'}
                  placeholder={'common.placeholder.select'}
                  width={'25%'}
                  onSelectOption={(option) => {
                    setDateFormat(option);
                  }}
                  options={statementCreationPageData.dateFormatMenuOptions}
                  value={statementCreationPageData.selectedDateFormatValue(
                    statementInProgress.dateformatId,
                  )}
                  disabled
                />
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-number-format`}
                  label={'statement-edit.statement-info.numberFormat.label'}
                  placeholder={'common.placeholder.select'}
                  width={'25%'}
                  onSelectOption={(option) => {
                    setNumberFormat(option);
                  }}
                  options={statementCreationPageData.numberFormatMenuOptions}
                  value={statementCreationPageData.selectedNumberFormatValue(
                    statementInProgress.numberFormatId,
                  )}
                  disabled
                />
              </div>
              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__input-row`}>
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-statement-type`}
                  label={'statement-edit.statement-info.statement-type.label'}
                  placeholder={'common.placeholder.select'}
                  width={'25%'}
                  onSelectOption={(option) => {
                    setStatementType(option);
                    // clear quarter if statement type changes so not stored for values other than 10-Q and Private Quarterly
                    setQuarter(null);
                  }}
                  options={STATEMENT_TYPE_OPTIONS}
                  value={statementInProgress.statementTypeId}
                />
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-quarter`}
                  label={'statement-edit.statement-info.quarter.label'}
                  placeholder={
                    statementInProgress.isQuarterRequired()
                      ? 'common.placeholder.select'
                      : 'common.NA'
                  }
                  width={'35%'}
                  onSelectOption={setQuarter}
                  disabled={!statementInProgress.isQuarterRequired()}
                  options={QUARTER_OPTIONS}
                  value={statementInProgress.quarter}
                />
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-fiscal-year`}
                  label={'statement-edit.statement-info.fiscal-year.label'}
                  placeholder={'common.placeholder.select'}
                  width={'9%'}
                  onSelectOption={setFiscalYear}
                  isValid={true}
                  options={FISCAL_YEAR_OPTIONS}
                  value={statementInProgress.fiscalYear}
                />
                <CustomDatePicker
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-period-ending`}
                  label={'statement-edit.statement-info.period-ending.label'}
                  errorText={'common.error-text.invalid-date'}
                  isFormItemRequired={true}
                  isValid={statementInProgress.isPeriodEndingValid()}
                  width={'20%'}
                  onDateChange={setPeriodEndingDate}
                  selectedDate={statementInProgress.periodEnding}
                />
              </div>
              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__input-row`}>
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-accounting-basis`}
                  label={'statement-edit.statement-info.accounting-basis.label'}
                  placeholder={'common.placeholder.select'}
                  width={'21%'}
                  onSelectOption={setAccountingBasis}
                  isValid={true}
                  options={ACCOUNTING_BASIS_OPTIONS}
                  value={statementInProgress.accountingBasis}
                />
                <CustomDatePicker
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-wrap-up`}
                  label={'statement-edit.statement-info.wrap-up.label'}
                  width={'30%'}
                  onDateChange={setWrapUpDate}
                  isFormItemRequired={true}
                  selectedDate={statementInProgress.archiveDate}
                  errorText={'common.error-text.invalid-date'}
                  isValid={statementInProgress.isWrapUpValid()}
                />
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-scaling`}
                  label={'statement-edit.statement-info.scaling.label'}
                  placeholder={
                    'statement-edit.statement-info.scaling.placeholder.select'
                  }
                  width={'25%'}
                  onSelectOption={setDefaultScaling}
                  isValid={true}
                  options={SCALING_OPTIONS}
                  value={statementInProgress.defaultScaling}
                  iconConfig={{
                    infoText:
                      'statement-edit.statement-info.scaling.tooltip.text',
                    tooltipInfoId: `${STATEMENT_EDIT_INFO_ID_BLOCK}-scaling-info-tooltip`,
                    iconComponent: InfoIcon,
                  }}
                  disabled
                />
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-unit`}
                  label={'statement-edit.statement-info.unit.label'}
                  placeholder={
                    'statement-edit.statement-info.unit.placeholder.select'
                  }
                  width={'25%'}
                  onSelectOption={setDefaultUnit}
                  isValid={true}
                  options={UNIT_OPTIONS}
                  value={statementInProgress.defaultUnit}
                  iconConfig={{
                    infoText: 'statement-edit.statement-info.unit.tooltip.text',
                    tooltipInfoId: `${STATEMENT_EDIT_INFO_ID_BLOCK}-unit-info-tooltip`,
                    iconComponent: InfoIcon,
                  }}
                  disabled
                />
              </div>
              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__input-row`}>
                <Dropdown
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-registrant`}
                  label={'statement-edit.statement-info.registrant.label'}
                  placeholder={'common.placeholder.select'}
                  width={'16%'}
                  onSelectOption={setRegistrant}
                  isValid={true}
                  options={REGISTRANT_OPTIONS}
                  value={statementInProgress.registrant}
                />
              </div>
              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__checkbox`}>
                <Checkbox
                  id={`${STATEMENT_EDIT_INFO_BLOCK}-auto-ir-checkbox`}
                  className={`${STATEMENT_EDIT_INFO_BLOCK}__auto-ir`}
                  checked={statementInProgress.autoIdentifyIrs}
                  value={'auto-ir'}
                  onChange={() => {
                    setAutoIdentifyIrs(!statementInProgress.autoIdentifyIrs);
                  }}
                  label={'statement-edit.statement-info.auto-ir.label'}
                  width={'auto'}
                  isValid={true}
                  disabled={!hasPermissionToEnableAutoIr}
                  tooltip={
                    !hasPermissionToEnableAutoIr ? ENABLE_AUTO_IR_TOOLTIP : null
                  }
                />
                <Tooltip {...AUTO_IR_TOOLTIP}>
                  <Information
                    name={'information-legal-hold'}
                    className={`${STATEMENT_EDIT_INFO_BLOCK}__information-icon`}
                    width={INFORMATION_ICON_SIZE}
                    height={INFORMATION_ICON_SIZE}
                  />
                </Tooltip>
              </div>

              {statementInProgress.autoIdentifyIrs ? (
                <div className={`${STATEMENT_EDIT_INFO_BLOCK}__banner`}>
                  <Banner
                    id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-auto-ir-banner`}
                    type={BANNER_TYPES.WARNING}
                    bannerCopy={'statement-edit.statement-info.auto-ir.banner'}
                    isInternationalized={true}
                    isInteractive={false}
                    width={'75%'}
                  />
                </div>
              ) : null}

              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__checkbox`}>
                <Checkbox
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-legal-hold-checkbox`}
                  className={`${STATEMENT_EDIT_INFO_BLOCK}__legal-hold`}
                  checked={statementInProgress.legalHold === 1}
                  value={'legal-hold'}
                  onChange={() => setLegalHold(!statementInProgress.legalHold)}
                  label={'statement-edit.statement-info.legal-hold.label'}
                  width={'auto'}
                  isValid={true}
                  disabled={isLegalHoldCheckboxDisabled}
                  tooltip={
                    isLegalHoldCheckboxDisabled
                      ? copyActiveStatements.data.legalHold
                        ? DISABLE_LEGAL_HOLD_TOOLTIP
                        : ENABLE_LEGAL_HOLD_TOOLTIP
                      : null
                  }
                />
                <Tooltip {...LEGAL_HOLD_TOOLTIP}>
                  <Information
                    name={'information-legal-hold'}
                    className={`${STATEMENT_EDIT_INFO_BLOCK}__information-icon`}
                    width={INFORMATION_ICON_SIZE}
                    height={INFORMATION_ICON_SIZE}
                  />
                </Tooltip>
              </div>
              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__legalhold-info`}>
                <FormattedMessage
                  id={'statement-edit.statement-info.legalhold-info'}
                />
              </div>
              {statementInProgress.legalHold ? (
                <div className={`${STATEMENT_EDIT_INFO_BLOCK}__banner`}>
                  <Banner
                    id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-legal-hold-banner`}
                    type={BANNER_TYPES.WARNING}
                    bannerCopy={
                      'statement-edit.statement-info.legal-hold.banner'
                    }
                    isInternationalized={true}
                    isInteractive={false}
                    width={'75%'}
                  />
                </div>
              ) : null}
              <div className={`${STATEMENT_EDIT_INFO_BLOCK}__checkbox`}>
                <Checkbox
                  id={`${STATEMENT_EDIT_INFO_ID_BLOCK}-enable-comfort-letter-checkbox`}
                  className={`${STATEMENT_EDIT_INFO_BLOCK}__comfort-letter`}
                  checked={statementInProgress.comfortLetter}
                  value={'enable-comfort-letter'}
                  onChange={() =>
                    setComfortLetter(!statementInProgress.comfortLetter)
                  }
                  label={
                    'statement-edit.statement-info.enable-comfort-letter.label'
                  }
                  width={'auto'}
                  disabled={!hasPermissionToEnableComfortLetter}
                  tooltip={
                    !hasPermissionToEnableComfortLetter
                      ? DISABLED_COMFORT_LETTER_TOOLTIP
                      : null
                  }
                  isValid={true}
                />
                <Tooltip {...ENABLE_COMFORT_LETTER_TOOLTIP}>
                  <Information
                    name={'information-enable-comfort-letter'}
                    className={`${STATEMENT_EDIT_INFO_BLOCK}__information-icon`}
                    width={INFORMATION_ICON_SIZE}
                    height={INFORMATION_ICON_SIZE}
                  />
                </Tooltip>
              </div>
            </div>
          </React.Fragment>
        </Card>
      </div>
    );
  }
}

StatementEditInfo.propTypes = {
  /** Form model of the statement that is to be edited */
  statementInProgress: PropTypes.instanceOf(StatementForm).isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setStatementName: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setStatementType: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setQuarter: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setFiscalYear: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setPeriodEndingDate: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setAccountingBasis: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setRegistrant: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setWrapUpDate: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setLegalHold: PropTypes.func.isRequired,
  /** Action that sets appropriate value on the statement in progress model */
  setAutoIdentifyIrs: PropTypes.func.isRequired,
  /** model representing statement creation page data for language, date & number format dropdowns*/
  statementCreationPageData: PropTypes.instanceOf(StatementCreationPageData),
};
const mapStateToProps = ({
  ui: {
    statementPage: { statementCreationPageData },
  },
}) => ({
  statementCreationPageData: statementCreationPageData,
});
const mapDispatchToProps = {
  fetchStatementCreationPageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementEditInfo);
