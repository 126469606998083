import { CONTEXT_KEY, getAPIClient } from 'api/api-client';
import axios from 'axios';
import socketConfig from 'socket/socketio-client';
import signalrConfig from 'socket/signalr-client';
import {
  UNAUTHORIZED,
  // FORBIDDEN,
  INTERNAL_SERVER_ERROR,
} from 'http-status-codes';
import moment from 'moment';
import { PublicClientApplication } from '@azure/msal-browser';
import { removeUserFromRoom } from 'api/statement-socket-client-api';
import { withDefaultContextAsHomeGeo } from './api-default-context';

const port = window.location.port ? `:${window.location.port}` : '';
const redirectUri = `${window.location.protocol}//${window.location.hostname}${port}/authsuccess`;
const LOGOUT_URL = '/logout';
export const isSignalRForSocketConnectionEnabled =
  window.TIEOUT.ENV.FEATURE.ENABLE_SIGNALR_SOCKET_CONNECTION;
/**
 * This is the first API request we make on any page, which gets the current user. This is used for
 * token handling with ADAL as well. This MUST be updated if we ever change the order of our API calls
 * throughout the application to be something else besides getting the current user to start.
 */
// const FIRST_API_REQUEST_PATH = '/tieout/currentuser';

// enabling the signalrConfig based on the signalr socket connection with isSignalRForSocketConnectionEnabled as true
const getConfigForSocketConnection = () => {
  if (isSignalRForSocketConnectionEnabled) {
    return signalrConfig;
  } else {
    return socketConfig;
  }
};

let msalInstance;

export const createMSALInstance = () => {
  if (!(msalInstance instanceof PublicClientApplication)) {
    msalInstance = new PublicClientApplication({
      auth: {
        clientId:
          window.TIEOUT.ENV.GEOS[window.TIEOUT.ENV.CURRENT_GEO].AD_CLIENT,
        authority: `${window.TIEOUT.ENV.AD_INSTANCE}${window.TIEOUT.ENV.AD_TENANT}`,
        redirectUri: redirectUri,
        knownAuthorities: [
          `${window.TIEOUT.ENV.AD_INSTANCE}${window.TIEOUT.ENV.AD_TENANT}`,
        ],
        postLogoutRedirectUri: 'https://www.deloitte.com/',
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    });
  }
};

export const createAuthContext = (geoKey) => {
  createMSALInstance();
  const apiClient = {};

  apiClient[geoKey] = getAPIClient(geoKey);
  apiClient[geoKey].socketURL = window.TIEOUT.ENV.GEOS[geoKey].BASE_HUB_URL;

  /**
   * Here we are intercepting each request and adding the auth token in the header using the
   * helper function "_getAuthTokenForRequestInterceptors"
   */
  apiClient[geoKey].api.interceptors.request.use(
    async (config) => {
      const isLogoutAPI = config.url === LOGOUT_URL;
      const token = await _getAuthTokenForRequestInterceptors(
        geoKey,
        isLogoutAPI,
      );
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['localDate'] = moment();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  /**
   * Here we are constructing an interceptor for catching certain response exceptions and handling the refresh of oauth tokens based
   * on response error codes.
   */
  apiClient[geoKey].api.interceptors.response.use(undefined, (err) => {
    const { response } = err;
    /**
     * This boolean is used to track that the user has not submitted multiple requests to the application
     * as receiving the same error twice for the same api call implies there is a problem with their session from our
     * authentication provider or that they are trying to brute force our authentication setup.
     *  */
    const _isValidResponseAndNotRetry =
      response &&
      response.config &&
      typeof response.config.url === 'string' &&
      !response.config.__isRetryRequest;

    /**
     * This case covers when we have made the first API request to the back-end (which fetches the current user).
     * For some reason it always makes the request without a token, so we receive a 403 - Forbidden response.
     * We need to retry with an obtained auth token for all future requests to work.
     */
    /**
     * UPDATE: Commenting this out because this case should never happen since we are adding the bearer token to each
     * request using the request interceptor
     */
    // const _emptyTokenResponseForFirstRequest =
    //   _isValidResponseAndNotRetry &&
    //   response.config.url.includes(FIRST_API_REQUEST_PATH) &&
    //   response.status === FORBIDDEN;

    /**
     * This case covers when we have an expired token and try to make an API request. In this case, we receive
     * a 401 - UNAUTHORIZED response. We have to refresh our token in this case for further API requests to work.
     */
    const _tokenTimeOutResponse =
      _isValidResponseAndNotRetry && response.status === UNAUTHORIZED;

    /**
     * This final case covers when a user has been idle on their computer for an incredibly long time (more than a few hours).
     * In this case we receive a 500 - INTERNAL_SERVER_ERROR response with a very specific socket timeout exception message.
     * This specific error case is a trigger for us to refresh our authorization token.
     */
    const _socketTimeOutResponse =
      _isValidResponseAndNotRetry &&
      response.status === INTERNAL_SERVER_ERROR &&
      response.data &&
      response.data.rootCause === 'SocketTimeoutException: connect timed out';

    /**
     * These cases above are the only error response cases in which we should obtain or refresh our authentication token.
     * In every other case we should allow the request (and its response) to fail.
     */
    const _shouldAcquireToken =
      // _emptyTokenResponseForFirstRequest ||
      _tokenTimeOutResponse || _socketTimeOutResponse;

    if (_shouldAcquireToken) {
      return _acquireToken(err, response, apiClient[geoKey], geoKey);
    } else {
      return Promise.reject(err);
    }
  });
  return apiClient[geoKey];
};

/**
 * A helper function used to obtain or refresh the token from ADAL used in our application using an implicit-grant oauth flow.
 * @param {Object} err : The error response wrapper that we receive from the back-end when we lack a token, or send an invalid one.
 * @param {Object} response: The HTTP Response object which contains things like status codes, error messages, etc... from the API.
 * @param {Object} apiClient: The api client wrapper that we use to retain tokens and track other responses from the back-end.
 */
const _acquireToken = (err, response, apiClient, geoKey) => {
  response.config.__isRetryRequest = true;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const tokenResponse = await handleLoginResponse(geoKey);
    const token = tokenResponse.accessToken;
    if (!token) {
      return;
    }

    // eslint-disable-next-line require-atomic-updates
    apiClient.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // eslint-disable-next-line require-atomic-updates
    apiClient.flags.authenticated = true;
    // eslint-disable-next-line require-atomic-updates
    apiClient.token = token;
    err.config.headers.Authorization = `Bearer ${token}`;
    getConfigForSocketConnection().autoConnect = true;
    axios(err.config).then(resolve, reject);
  });
};

export const _getAuthTokenForRequestInterceptors = async (
  geoKey,
  isLogoutAPI = false,
) => {
  const tokenResponse = await handleLoginResponse(geoKey, isLogoutAPI);

  getConfigForSocketConnection().autoConnect = true;
  return tokenResponse.accessToken;
};

export const authenticateAcrossAllGeos = async () => {
  return authenticate(window.TIEOUT.ENV.CURRENT_GEO);
};

export const authenticate = async (geoKey) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (window !== window.parent) {
      return;
    }

    const tokenResponse = await handleLoginResponse(geoKey);
    const apiClientForGeo = getAPIClient(geoKey);

    if (apiClientForGeo.api)
      _authenticateAPI(
        apiClientForGeo,
        tokenResponse && tokenResponse.accessToken,
        // eslint-disable-next-line no-loop-func
        () => {
          resolve();
        },
      );
  });
};

async function handleLoginResponse(geoKey, isLogoutAPI = false) {
  if (isLogoutAPI) {
    return await handleLogoutResponse(geoKey);
  }
  let tokenResponse = await msalInstance.handleRedirectPromise();

  console.debug('got token for geo::', geoKey);
  let accountObj;

  if (tokenResponse) {
    accountObj = tokenResponse.account;
  } else {
    accountObj = msalInstance.getAllAccounts()[0];
  }

  if (accountObj && tokenResponse) {
    const resourceId = window.TIEOUT.ENV.GEOS[geoKey].AD_AUD;
    const scopes = [resourceId + '/.default'];
    try {
      tokenResponse = await msalInstance.acquireTokenSilent({
        account: msalInstance.getAllAccounts()[0],
        scopes: scopes,
      });
    } catch (err) {
      await msalInstance.acquireTokenRedirect({ scopes: scopes });
    }
  } else {
    await msalInstance.loginRedirect();
  }

  return tokenResponse;
}

async function handleLogoutResponse(geoKey) {
  let tokenResponse;
  const resourceId = window.TIEOUT.ENV.GEOS[geoKey].AD_AUD;
  const scopes = [resourceId + '/.default'];
  try {
    tokenResponse = await msalInstance.acquireTokenSilent({
      account: msalInstance.getAllAccounts()[0],
      scopes: scopes,
    });
  } catch (err) {
    await msalInstance.acquireTokenRedirect({ scopes: scopes });
  }
  return tokenResponse;
}

// This function will fetch the existing token from cache
// and check if it is valid and return the same (if valid).
// In case token is expired or close to expiry, it (acquireTokenSilent method)
// will silently make an api call to OAuth provider for new access token in exchange of existing refresh token.
// If there is not already existing token/refresh token it will throw error, that essentially make our 'tokenResponse'
// variable null in the catch block. A null value returned for tokenResponse will prevent any further call to BE .
export async function checkIfExistingTokenIsValidAndFetchNewIfExpired(geoKey) {
  let tokenResponse = null;
  const resourceId = window.TIEOUT.ENV.GEOS[geoKey].AD_AUD;
  const scopes = [resourceId + '/.default'];
  try {
    // This code will try to parse the token containing in cache/session.
    // It will return the token in cache if it is valid else will make an api
    // request to get new token from Oauth provider flow
    tokenResponse = await msalInstance.acquireTokenSilent({
      account: msalInstance.getAllAccounts()[0],
      scopes: scopes,
    });
  } catch (err) {
    console.debug(err);
    tokenResponse = null;
  }

  // it will return null if existing token is null
  return tokenResponse && tokenResponse.accessToken;
}

const _authenticateAPI = (apiClient, token, callback) => {
  if (!apiClient.flags.authenticated) {
    if (token) {
      apiClient.token = token;
      apiClient.flags.authenticated = true;
      callback();
    }
  }
};

// Handle authentication success
export const authenticateSuccess = () => {
  msalInstance
    .handleRedirectPromise()
    .then(() => handleLoginResponse(window.TIEOUT.ENV.CURRENT_GEO));
};

export const clearAuthData = () => {
  sessionStorage.clear();
  // createAuthContext();
};

export const userLogOut = async (
  revisionId,
  projectUsersList,
  selectedProjectId,
) => {
  try {
    // This will verify if we are in statement page or any other page.
    // Any page other than statement page will not have revisionId and hence we do not want to make
    // removeUserFromRoom call for those pages.
    Number.isInteger(revisionId) &&
      projectUsersList &&
      (await removeUserFromRoom({
        revisionId,
        projectUserIds: Object.keys(projectUsersList),
        projectId: selectedProjectId,
      }));
    const geoKeys = Object.keys(window.TIEOUT.ENV.GEOS);
    for (let i = 0; i < geoKeys.length; i++) {
      await withDefaultContextAsHomeGeo({
        contextKey: CONTEXT_KEY.SELECTED_GEO_CONTEXT,
        contextValue: geoKeys[i],
      })
        .post('/logout', null, {
          headers: {
            'Content-Type': undefined,
          },
        })
        .catch((e) => console.error(e));
    }
  } catch (e) {
    console.debug(e);
  } finally {
    sessionStorage.clear();
  }
};

export const authContextLogout = (geoKey) => {
  msalInstance.logoutRedirect({
    account: msalInstance.getAllAccounts()[0],
  });
};
