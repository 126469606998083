import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import InternalReference from 'models/api/internal-reference-model';
import ConditionalRender from 'components/util/conditional-render-component';
import ToolTip from 'components/common/tool-tip-component';
import { ReactComponent as Unlink } from 'icons/unlink.svg';
import { FormattedMessage } from 'react-intl';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import AmountAttributeDetails from 'components/common/element-amount-attributes-details-component';
import InternalReferenceSuggestedReferences from './_internal-reference-panel-suggested-references-component';
import InternalReferenceListing from './_internal-reference-panel-listing-component';
import {
  getElementFlaggedIcon,
  getOCRElementStyleByElementStatus,
} from 'constants/feature/element-panel-constants';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import classNames from 'classnames';

export const INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK =
  'internal-reference-panel-content';
export const INTERNAL_REFERENCE_PANEL_CONTENT_ID_BLOCK =
  'internal-reference-panel-content-id';

const UNLINK_ICON_SIZE = '28px';

class InternalReferencePanelContent extends Component {
  state = { isSuggestedRefExpanded: true };
  componentDidMount() {
    const { getInternalReferenceWithoutLoading } = this.props;
    getInternalReferenceWithoutLoading();
  }

  _toggleSuggestedRefExpandableRow = () => {
    this.setState({
      isSuggestedRefExpanded: !this.state.isSuggestedRefExpanded,
    });
  };

  render() {
    const {
      elementDetails,
      internalReference,
      selectElementFromInternalReference,
      detachElementFromInternalReference,
      addSuggestionToInternalReference,
      selectElementContentPanel,
      deallocateItems,
      updateWorkpaper,
      attachTickmarkToElement,
      getElementInternalReference,
      checkIfFeatureFlagEnabled,
    } = this.props;
    return (
      <ConditionalRender dependencies={[elementDetails, internalReference]}>
        <div className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}`}>
          <div
            className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__selected-value-container`}
          >
            <div
              className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__element-details-container`}
            >
              <div
                className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__selected-value`}
              >
                <FormattedMessage id="common.selected-value" />
              </div>
              <div
                className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__display_container`}
              >
                <button
                  id={`${INTERNAL_REFERENCE_PANEL_CONTENT_ID_BLOCK}-element-value`}
                  className={classNames(
                    getOCRElementStyleByElementStatus(elementDetails),
                    `${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__element-value`,
                  )}
                  onClick={() => {
                    scrollElementIntoView({
                      elementId: elementDetails.id,
                      sectionId: elementDetails.sectionId,
                    });
                    deallocateItems();
                    selectElementContentPanel({
                      elementIds: [elementDetails.id],
                      color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
                    });
                  }}
                >
                  {getElementFlaggedIcon({ elementDetails })}
                  {elementDetails.display}
                </button>
                {internalReference.hasElements() && (
                  <span
                    className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__internal-refrence-number`}
                  >
                    IR{internalReference.number}
                  </span>
                )}
              </div>
              <AmountAttributeDetails
                className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__amount-attributes`}
                elementDetails={elementDetails}
              />
            </div>
            {!internalReference.hasElements() ? null : (
              <div
                className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__unlink-button-container`}
              >
                <ToolTip
                  id={`${INTERNAL_REFERENCE_PANEL_CONTENT_ID_BLOCK}__unlink-tooltip-${elementDetails.id}`}
                  className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__unlink-tooltip`}
                  text="common.unlink"
                  position={'left'}
                >
                  <button
                    id={`${INTERNAL_REFERENCE_PANEL_CONTENT_ID_BLOCK}-unlink-${elementDetails.id}`}
                    className={`${INTERNAL_REFERENCE_PANEL_CONTENT_BLOCK}__unlink`}
                    onClick={() =>
                      detachElementFromInternalReference({
                        internalReferenceId: internalReference.id,
                        elementId: elementDetails.id,
                      })
                    }
                  >
                    <Unlink
                      width={UNLINK_ICON_SIZE}
                      height={UNLINK_ICON_SIZE}
                    />
                  </button>
                </ToolTip>
              </div>
            )}
          </div>
          <InternalReferenceSuggestedReferences
            elementId={elementDetails.id}
            revisionId={elementDetails.revisionId}
            onAddSuggestion={addSuggestionToInternalReference}
            selectElementFromInternalReference={
              selectElementFromInternalReference
            }
            showExpandableRow={this.state.isSuggestedRefExpanded}
            expandExpandableRow={this._toggleSuggestedRefExpandableRow}
            minimizeExpandableRow={this._toggleSuggestedRefExpandableRow}
            selectedElement={elementDetails}
            selectElementContentPanel={selectElementContentPanel}
          />
          <InternalReferenceListing
            elementDetails={elementDetails}
            internalReference={internalReference}
            selectElementFromInternalReference={
              selectElementFromInternalReference
            }
            detachElementFromInternalReference={
              detachElementFromInternalReference
            }
            selectElementContentPanel={selectElementContentPanel}
            updateWorkpaper={updateWorkpaper}
            attachTickmarkToElement={attachTickmarkToElement}
            getElementInternalReference={getElementInternalReference}
            checkIfFeatureFlagEnabled={checkIfFeatureFlagEnabled}
          />
        </div>
      </ConditionalRender>
    );
  }
}

InternalReferencePanelContent.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /**The model that represents the internal reference which is used in the internal reference panel. */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /**The action that is fired to refresh the internal reference model used in the panel, as it is also used in the element panel */
  getInternalReferenceWithoutLoading: PropTypes.func.isRequired,
  /**The action that is fired to highlight elements from the content panel after we click on them in the internal reference */
  selectElementFromInternalReference: PropTypes.func.isRequired,
  /**The action that is fired when we wish to detach an element from an internal reference */
  detachElementFromInternalReference: PropTypes.func.isRequired,
  /**The action that is fired when we wish to add a suggested reference to an internal reference */
  addSuggestionToInternalReference: PropTypes.func.isRequired,
  /* Action to highlight an element on content panel */
  selectElementContentPanel: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /**To create/update workpaper and refresh the corresponding element */
  updateWorkpaper: PropTypes.func.isRequired,
  /**To attach tickmark to an element and refresh the corresponding element*/
  attachTickmarkToElement: PropTypes.func.isRequired,
  /**This function will refresh IR element data */
  getElementInternalReference: PropTypes.func.isRequired,
  // action method to check if feature flag is enabled for the feature with mentioned name
  checkIfFeatureFlagEnabled: PropTypes.func.isRequired,
};

export default InternalReferencePanelContent;
