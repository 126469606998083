const useHoverEffect = (classPrefix, ID) => {
  const handleMouseEnter = () => {
    const className = `${classPrefix}-${ID}`;
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach((element) =>
      element.classList.add(`${classPrefix}__hover-effect`),
    );
  };

  const handleMouseLeave = () => {
    const className = `${classPrefix}-${ID}`;
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach((element) =>
      element.classList.remove(`${classPrefix}__hover-effect`),
    );
  };

  return { handleMouseEnter, handleMouseLeave };
};

export default useHoverEffect;
