import React from 'react';

import { DataGridColumn } from 'components/common/data-grid/data-grid-component';
import MultilineEllipsis from 'components/common/multi-line-ellipsis-text-component';

import TabModel from 'models/utils/common/tab-model';
import Switch from 'react-switch';
import AdminModuleAPISwitch from 'components/admin-module-api-switch';
import { GEO_NAMES } from 'constants/feature/project-list-header-constants';
import { getCountryNameByCountryCode } from 'utils/project-utils';

const SWITCH_HEIGHT = 20;
const SWITCH_WIDTH = 45;

const blankChangeHandler = () => {};

export const ADMIN_MODULE_SORT_COLUMN_KEYS = {
  clientName: 'clientName',
  containerCode: 'containerCode',
};

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const getMonitoringColumns = () => {
  let monitoringColumns = [
    new DataGridColumn({
      key: 'clientName',
      header: 'admin.module.monitoring.client.name-heading',
      width: '25%',
      formatter: (rowData, { rowKey }) => {
        const colId = `admin-module-client-name-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.clientName}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'activeStatements',
      header: 'admin.module.monitoring.active.statements-heading',
      width: '20%',
      formatter: (rowData, { rowKey }) => {
        const colId = `admin-module-active-statements-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.activeStatements}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'wrappedStatements',
      header: 'admin.module.monitoring.wrapped.statements-heading',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `admin-module-wrapped-statements-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.wrappedupStatements}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'deletedStatements',
      header: 'admin.module.monitoring.deleted.statements-heading',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `admin-module-deleted-statements-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.deletedStatements}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'country',
      header: 'admin.module.monitoring.country-heading',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `admin-module-country-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.containerCode}
            isNotInternationalized
          />
        );
      },
    }),
  ];
  return monitoringColumns;
};

export const getAddedUserColumnsForAdminModule = (
  currentUser,
  _updateUserEntityAgainstEmail,
) => {
  let addedUserColumns = [
    new DataGridColumn({
      key: 'preferredFullName',
      header: 'admin-module.manage-users.user-heading.name',
      width: '25%',
      formatter: (rowData, { rowKey }) => {
        const colId = `added-users-fullName-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.preferredFullName}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'email',
      header: 'admin-module.manage-users.user-heading.email',
      width: '20%',
      formatter: (rowData, { rowKey }) => {
        const colId = `added-users-email-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.email}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'region',
      header: 'admin-module.manage-users.user-heading.region',
      width: '10%',
      formatter: (rowData, { rowKey }) => {
        const colId = `added-users-region-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.region}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'function',
      header: 'admin-module.manage-users.user-heading.function',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `user-search-function-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.function}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'enabled',
      header: 'admin-module.manage-users.user-heading.application_access',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `enabled-switch-${rowKey}`;

        return (
          <AdminModuleAPISwitch
            switchKey={colId}
            switchData={rowData}
            currentField={'ENABLER'}
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'admin',
      header: 'admin-module.manage-users.user-heading.admin_access',
      width: '25%',
      formatter: (rowData, { rowKey }) => {
        const colId = `admin-switch-${rowKey}`;

        return (
          <AdminModuleAPISwitch
            switchKey={colId}
            switchData={rowData}
            currentField={'ADMIN'}
            _updateUserEntityAgainstEmail={_updateUserEntityAgainstEmail}
            isIconVisible={rowData.admin}
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'country',
      header: 'admin-module.manage-users.user-heading.user-countryName',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `added-users-country-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={getCountryNameByCountryCode(rowData.countryCode)}
            isNotInternationalized
          />
        );
      },
    }),
  ];

  if (currentUser.geoCode === GEO_NAMES.AME) {
    addedUserColumns.push(
      new DataGridColumn({
        key: 'coe',
        header: 'admin-module.manage-users.user-heading.coe_access',
        width: '15%',
        formatter: (rowData, { rowKey }) => {
          const colId = `coe-switch-${rowKey}`;

          return (
            <AdminModuleAPISwitch
              switchKey={colId}
              switchData={rowData}
              currentField={'COE'}
            />
          );
        },
      }),
    );
  }
  return addedUserColumns;
};

export const getSearchUserColumnsForAdminModule = (currentUser) => {
  let searchColumns = [
    new DataGridColumn({
      key: 'preferredFullName',
      header: 'admin-module.manage-users.user-heading.name',
      width: '20%',
      formatter: (rowData, { rowKey }) => {
        const colId = `user-search-fullName-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.preferredFullName}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'email',
      header: 'admin-module.manage-users.user-heading.email',
      width: '30%',
      formatter: (rowData, { rowKey }) => {
        const colId = `user-search-email-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.email}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'region',
      header: 'admin-module.manage-users.user-heading.region',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `user-search-region-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.region}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'function',
      header: 'admin-module.manage-users.user-heading.function',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `user-search-function-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.function}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'admin',
      header: 'admin-module.manage-users.user-heading.admin_access',
      width: '25%',
      formatter: (rowData) => {
        return (
          <Switch
            checked={rowData.admin}
            uncheckedIcon={false}
            checkedIcon={false}
            height={SWITCH_HEIGHT}
            width={SWITCH_WIDTH}
            disabled={true}
            onChange={blankChangeHandler}
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'country',
      header: 'admin-module.manage-users.user-heading.user-countryName',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `user-search-country-col-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={getCountryNameByCountryCode(rowData.countryCode)}
            isNotInternationalized
          />
        );
      },
    }),
  ];

  if (currentUser.geoCode === GEO_NAMES.AME) {
    searchColumns.push(
      new DataGridColumn({
        key: 'coe',
        header: 'admin-module.manage-users.user-heading.coe_access',
        width: '15%',
        formatter: (rowData) => {
          return (
            <Switch
              checked={rowData.coe}
              uncheckedIcon={false}
              checkedIcon={false}
              height={SWITCH_HEIGHT}
              width={SWITCH_WIDTH}
              disabled={true}
              onChange={blankChangeHandler}
            />
          );
        },
      }),
    );
  }

  return searchColumns;
};

export const ADMIN_MODULE_TABS = {
  MANAGEUSERS: new TabModel({
    id: 1,
    apiParam: 'manageuserstab',
    intlTitle: 'admin-module.tabs.manageuserstab',
  }),
  MAINTENANCEBANNER: new TabModel({
    id: 2,
    apiParam: 'maintenancebannertab',
    intlTitle: 'admin-module.tabs.maintenancebannertab',
  }),
  SUMMARY_OF_KEY_CHANGES: new TabModel({
    id: 3,
    apiParam: 'summaryOfKeyChangesTab',
    intlTitle: 'admin-module.tabs.summary-of-key-changes',
  }),
  MONITORING: new TabModel({
    id: 4,
    apiParam: 'monitoringTab',
    intlTitle: 'admin-module.tabs.monitoring',
  }),
};

export const getAdminModuleTabs = (currentUser) => {
  if (currentUser.applicationManager && currentUser.admin) {
    return {
      MANAGEUSERS: ADMIN_MODULE_TABS.MANAGEUSERS,
      MAINTENANCEBANNER: ADMIN_MODULE_TABS.MAINTENANCEBANNER,
      SUMMARY_OF_KEY_CHANGES: ADMIN_MODULE_TABS.SUMMARY_OF_KEY_CHANGES,
      MONITORING: ADMIN_MODULE_TABS.MONITORING,
    };
  } else if (currentUser.applicationManager) {
    return {
      MAINTENANCEBANNER: ADMIN_MODULE_TABS.MAINTENANCEBANNER,
      SUMMARY_OF_KEY_CHANGES: ADMIN_MODULE_TABS.SUMMARY_OF_KEY_CHANGES,
    };
  } else if (currentUser.admin) {
    return {
      MANAGEUSERS: ADMIN_MODULE_TABS.MANAGEUSERS,
      MONITORING: ADMIN_MODULE_TABS.MONITORING,
    };
  } else {
    return { MANAGEUSERS: ADMIN_MODULE_TABS.MANAGEUSERS };
  }
};
