import { createAction } from 'redux-actions';

import { getStatementRequest } from 'api/statement-api';
import Statement from 'models/data/statement-model';
import { fetchLeftRevision } from 'store/actions/left-revision-actions';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import { CONTEXT_KEY } from 'api/api-client';
import { getLeftContentSectionMapRequest } from './section-cache-actions';
import { fetchLeftSectionReviews } from './side-by-side-statement/side-by-side-statement-actions';
import { fetchLeftSectionTreeAction } from './side-by-side-statement/left-statement-section-tree-list-actions';
import { OCR_DOC_TYPE } from 'models/api/selected-statement-model';
import { fetchLeftSectionIdListStatementContent } from './statement-content-actions';
import { getSelectedStatementOCRNavigationData } from './ocr-navigation-actions';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

export const leftSelectedStatementLoading = createAction(
  'SELECTED_STATEMENT_LOADING',
);
export const leftSelectedStatementLoaded = createAction(
  'LEFT_SELECTED_STATEMENT_LOADED',
);
export const leftSelectedStatementError = createAction(
  'LEFT_SELECTED_STATEMENT_ERROR',
);
export const clearLeftSelectedStatement = createAction(
  'CLEAR_LEFT_SELECTED_STATEMENT',
);

export const fetchLeftSelectedStatement =
  ({ revisionId, statementId, projectId, documentProcessType }) =>
  async (dispatch, getState) => {
    const _isSideBySideView = window.location.pathname.includes(
      ROUTE_CONSTANTS.SIDE_BY_SIDE,
    );
    try {
      _isSideBySideView &&
        (await dispatch(
          fetchLeftSectionIdListStatementContent(
            revisionId,
            statementId,
            projectId,
            documentProcessType === OCR_DOC_TYPE,
          ),
        ));
      await dispatch(fetchLeftRevision(revisionId, statementId, projectId));
      if (_isSideBySideView && documentProcessType !== OCR_DOC_TYPE) {
        // fetch contentSectionMap for relationships between content and bookmark sections
        dispatch(
          getLeftContentSectionMapRequest({
            statementId,
            revisionId,
            projectId,
          }),
        );
        // fetch source Statements section reviews on particular revisions
        dispatch(fetchLeftSectionReviews(statementId));

        // It will fetch section tree list used for rendering statement-nav panel
        dispatch(
          fetchLeftSectionTreeAction({
            statementId,
            revisionId,
            projectId,
          }),
        );
      }
      const { data: statementData } = await getStatementRequest(statementId, {
        contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
        contextValue: projectId,
      });
      //dispatch(leftSelectedStatementLoading());
      const statement = new Statement(statementData, statementId);
      const { leftRevision } = getState().data;
      const isLatestRevision =
        leftRevision.revisionNumber === statement.latestRevision;
      dispatch(
        leftSelectedStatementLoaded({
          statement,
          readOnlyOverride: leftRevision.readOnly || !isLatestRevision,
        }),
      );
      if (_isSideBySideView && statement.documentProcessType === OCR_DOC_TYPE) {
        await dispatch(
          getSelectedStatementOCRNavigationData(projectId, revisionId, true),
        );
      }
    } catch (error) {
      dispatch(leftSelectedStatementError(error));
    }
  };

export const checkIfFeatureFlagEnabled =
  ({ featureName }) =>
  (dispatch, getState) => {
    const selectedStatement =
      getState().data &&
      getState().data.leftselectedStatement &&
      getState().data.leftselectedStatement.data &&
      getState().data.leftselectedStatement.data.statement;
    switch (featureName) {
      case FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE:
      case FEATURE_NAMES.COMFORT_LETTER_FEATURE:
        return selectedStatement && selectedStatement.comfortLetter; // We are maintaining a common flag to enable/disable comfort letter and comfort assigned(comfortAssign)

      default:
        return true;
    }
  };
