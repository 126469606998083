import React from 'react';
import RenderPolygonBasedStatementDetails from 'utils/ocr-statement-details-utils';
import StatementContentSearchOverlayMap from './section-content-search-overlay-map';

const StatementContentSearchComponentOCR = ({
  contentSearchResult,
  scale,
  dpi,
  pageSectionId,
}) => {
  const pageElements = [];
  contentSearchResult.isLoaded &&
    !!contentSearchResult.occurrences.length &&
    contentSearchResult.occurrences.forEach((content, index) => {
      const { polygonDetails, sectionId } = content;
      const polygonInPixel =
        polygonDetails.length &&
        polygonDetails.map((subArr) =>
          Array.isArray(subArr)
            ? subArr.map((element) => element * dpi)
            : subArr * dpi,
        );
      pageSectionId === sectionId &&
        pageElements.push(
          <RenderPolygonBasedStatementDetails
            coordinates={polygonInPixel}
            scale={scale}
            key={content.id}
          >
            <StatementContentSearchOverlayMap
              content={content}
              contentSearchResult={contentSearchResult}
              index={index}
            />
          </RenderPolygonBasedStatementDetails>,
        );
    });
  return <>{pageElements}</>;
};

export default StatementContentSearchComponentOCR;
