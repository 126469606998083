import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Page from 'components/util/page-component';
import ProjectDetailsContainer from 'containers/feature/project-details-container';
import ProjectListContainer from 'containers/feature/project-list-container';
import StatementListContainer from 'containers/feature/statement-list/statement-list-container';
import StatementProcessing from 'containers/feature/statement-processing-container';
import queryString from 'query-string';
import OmniaConfirmModal from 'components/feature/omnia/_omnia-engagements-confirmation-modal';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import OmniaEngagement from 'models/api/omnia-engagement-model';
import Footer from 'components/common/footer-component';
import { isGoogleChrome } from 'utils/browser-utils';
import { ReactComponent as InfoIcon } from 'icons/info-alt.svg';
import RecentUpdatesModal from 'components/feature/home-page/recent-updates-modal';
import MaintenanceBanner from 'components/feature/home-page/maintenance-banner';

const HOME_PAGE_BLOCK = 'home-page';
//LOCAL_STORAGE_DATA_VERSION if we need to release a new set of updates, changing this constant
// will remove the last version local storage and will add a new one for the new version
// so the modal will show up again
export const LOCAL_STORAGE_DATA_VERSION = 1;

const cookieChangeEventController = new AbortController();

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.projectListRef = React.createRef();
    this.homePageRightRef = React.createRef();
    this.state = {
      showConfirmModal: false,
      showRecentUpdatesModal: false,
      OptanonAlertBoxClosedCookie: false,
      refProjectList: this.projectListRef.current,
      refHomePageRight: this.homePageRightRef.current,
    };
  }

  componentWillUnmount() {
    this.props.cleanUpHomePageStore();
    // remove event listener on unmount
    cookieChangeEventController.abort();

    let cookieStore = window.cookieStore;
    if (
      cookieStore &&
      window.TIEOUT.ENV.FEATURE.ENABLE_COOKIE_CONSENT_NOTIFICATION
    ) {
      window.cookieStore.removeEventListener('change', () => {
        console.log(
          'Removed event listener for cookie on unmounting of component',
        );
      });
    }
  }

  _setLocalStorageValueForShowingRecentUpdates(value) {
    localStorage.setItem(
      'SHOW_UPDATES_MODAL',
      JSON.stringify({ version: LOCAL_STORAGE_DATA_VERSION, show: value }),
    );
  }
  _setSessionStorageValueForShowingRecentUpdates(value) {
    sessionStorage.setItem('TEMPORARY_SHOW_UPDATES_MODAL', value);
  }

  componentDidMount() {
    // set ref when component is mounted
    this.setState({
      refProjectList: this.projectListRef.current,
      refHomePageRight: this.homePageRightRef.current,
    });
    // get the current state of deloitte cookie pop up.
    // With this information we want to make sure that our summary
    // of key changes pop up do not stacks over cookie pop up.
    this.setState({
      OptanonAlertBoxClosedCookie: document.cookie.includes(
        'OptanonAlertBoxClosed',
      ),
    });
    let cookieStore = window.cookieStore;
    if (
      cookieStore &&
      window.TIEOUT.ENV.FEATURE.ENABLE_COOKIE_CONSENT_NOTIFICATION
    ) {
      window.cookieStore.addEventListener(
        'change',
        () => {
          this.setState({
            OptanonAlertBoxClosedCookie: document.cookie.includes(
              'OptanonAlertBoxClosed',
            ),
          });
        },
        { signal: cookieChangeEventController.signal },
      );
    }
    const { location, verifyOmniaTokenAndGetEngagement } = this.props;
    const omniaToken = queryString.parse(location.search);
    if (omniaToken.token) {
      verifyOmniaTokenAndGetEngagement({
        token: omniaToken.token,
      });
    }
    /*recent updates local storage management block of code */
    const displayProductUpdatesData =
      localStorage.getItem('SHOW_UPDATES_MODAL');
    // we first check if the ENABLE_RECENT_UPDATES_NOTIFICATION env variable true or false
    if (!window.TIEOUT.ENV.FEATURE.ENABLE_RECENT_UPDATES_NOTIFICATION) {
      this.setState({ showRecentUpdatesModal: false });
    } else {
      // we check if the data exist in the local storage
      if (isNullOrUndefined(displayProductUpdatesData)) {
        //we add the current version local storage data and set the state to true to show the modal.
        this._setLocalStorageValueForShowingRecentUpdates(true);
        this._setSessionStorageValueForShowingRecentUpdates(true);
        this.setState({ showRecentUpdatesModal: true });
      } else {
        // we use try to avoid error in thr application if the user changes the local storage data manually
        try {
          const localStorageData = JSON.parse(displayProductUpdatesData);
          // if local storage data exist and the version is different we need to set the data for the new version
          if (
            localStorageData.version &&
            localStorageData.version !== LOCAL_STORAGE_DATA_VERSION
          ) {
            this._setLocalStorageValueForShowingRecentUpdates(true);
            this._setSessionStorageValueForShowingRecentUpdates(true);
            this.setState({ showRecentUpdatesModal: true });
          } else {
            //if the local storage data exist and we didn't changed the version, we check the value
            // if its true, we set the state to true to show the modal
            /*recent updates local storage management block of code */
            let displayProductUpdatesSessionData = sessionStorage.getItem(
              'TEMPORARY_SHOW_UPDATES_MODAL',
            );
            if (isNullOrUndefined(displayProductUpdatesSessionData)) {
              this._setSessionStorageValueForShowingRecentUpdates(true);
              displayProductUpdatesSessionData = sessionStorage.getItem(
                'TEMPORARY_SHOW_UPDATES_MODAL',
              );
            }

            if (localStorageData.show && localStorageData.show === true) {
              if (
                displayProductUpdatesSessionData &&
                displayProductUpdatesSessionData === 'true'
              ) {
                this.setState({ showRecentUpdatesModal: true });
              }
            } else {
              this._setSessionStorageValueForShowingRecentUpdates(false);
            }
          }
        } catch (error) {
          this._setLocalStorageValueForShowingRecentUpdates(true);
          this._setSessionStorageValueForShowingRecentUpdates(true);
          this.setState({ showRecentUpdatesModal: true });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { omniaEngagement, location, push } = this.props;
    if (
      prevState.refHomePageRight !== this.homePageRightRef.current ||
      prevState.refProjectList !== this.projectListRef.current
    ) {
      this.setState({
        refProjectList: this.projectListRef.current,
        refHomePageRight: this.homePageRightRef.current,
      });
    }
    const omniaToken = queryString.parse(location.search);
    // store the engagementId in session will need later
    sessionStorage.setItem('omEngagementId', omniaEngagement.getEngagementId());
    /** Navigate directly to homepage when there is an omnia token present, an omnia engagement was already linked and there are projects associated to current user */
    if (
      omniaToken.token &&
      omniaEngagement.hasOmniaEngagementBeenLinked() &&
      omniaEngagement.areThereTieoutEngagementEntities()
    ) {
      push(ROUTE_CONSTANTS.HOME_PAGE);
    } else if (
      prevProps.omniaEngagement !== omniaEngagement &&
      !isNullOrUndefined(omniaEngagement.omniaEngagementEntity) &&
      omniaEngagement.areThereTieoutEngagementEntities()
    ) {
      this.setState({ showConfirmModal: true });
    }
  }

  _navigateToOmniaEngagementPage = () => {
    const { push } = this.props;
    push(ROUTE_CONSTANTS.OMNIA_ENGAGEMENT_PAGE);
  };

  render() {
    const {
      showConfirmModal,
      showRecentUpdatesModal,
      OptanonAlertBoxClosedCookie,
    } = this.state;
    const { push } = this.props;
    const isNotChrome = !isGoogleChrome();
    return (
      <Page
        className={classnames(
          HOME_PAGE_BLOCK,
          isNotChrome && `${HOME_PAGE_BLOCK}--show-browser-warning`,
        )}
      >
        <MaintenanceBanner
          projectListRef={this.state.refProjectList}
          homePageRightRef={this.state.refHomePageRight}
        />
        {isNotChrome && (
          <div className={`${HOME_PAGE_BLOCK}__warning-banner`}>
            <InfoIcon className={`${HOME_PAGE_BLOCK}__warning-banner-icon`} />
            <strong>
              <FormattedMessage id="route.homepage.browser-warning-banner" />
            </strong>
          </div>
        )}
        <div className={`${HOME_PAGE_BLOCK}__container`}>
          <div
            className={`${HOME_PAGE_BLOCK}__project-list`}
            ref={this.projectListRef}
          >
            <ProjectListContainer />
          </div>
          <div
            className={`${HOME_PAGE_BLOCK}__right`}
            ref={this.homePageRightRef}
          >
            <div className={`${HOME_PAGE_BLOCK}__project-details`}>
              <ProjectDetailsContainer />
            </div>
            <div className={`${HOME_PAGE_BLOCK}__statements`}>
              <StatementListContainer />
            </div>
            <StatementProcessing />
          </div>
          {showConfirmModal && (
            <OmniaConfirmModal
              onClickYes={() => this._navigateToOmniaEngagementPage()}
              onClickNo={() => {
                push(ROUTE_CONSTANTS.HOME_PAGE);
                this.setState({ showConfirmModal: false });
              }}
            />
          )}
          {showRecentUpdatesModal &&
            /**We want to check if deloitte cookie consent notice is already shown.
             * If it is not shown then we do not want to want to show recent- update modal
             * Since it would stack over the cookie notice modal.
             */
            (!window.TIEOUT.ENV.FEATURE.ENABLE_COOKIE_CONSENT_NOTIFICATION ||
              // If cookies is explicitly disabled then don't check if it is shown or not
              OptanonAlertBoxClosedCookie) && (
              <RecentUpdatesModal
                onClose={() => {
                  this.setState({ showRecentUpdatesModal: false });
                  this._setSessionStorageValueForShowingRecentUpdates(false);
                }}
                onSave={() => {
                  this._setLocalStorageValueForShowingRecentUpdates(false);
                  this._setSessionStorageValueForShowingRecentUpdates(false);
                  this.setState({ showRecentUpdatesModal: false });
                }}
              />
            )}
        </div>
        <Footer />
      </Page>
    );
  }
}

HomePage.propTypes = {
  /** On unmount clears all page specific redux store data */
  cleanUpHomePageStore: PropTypes.func.isRequired,
  /** Redux action to verify the omnia token from browser */
  verifyOmniaTokenAndGetEngagement: PropTypes.func.isRequired,
  /** the omnia engagement obj */
  omniaEngagement: PropTypes.instanceOf(OmniaEngagement).isRequired,
};

export default HomePage;
