import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearBlacklineDetails } from 'store/actions/blackline-view-actions';
import BlacklinePreviousVersionView from './blackline-previous-version-component';
import BlacklineCurrentVersionView from './blackline-current-version-component';
import {
  registerLeftScrollListeners,
  registerRightScrollListeners,
  deRegisterLeftScrollListeners,
  deRegisterRightScrollListeners,
} from 'utils/sync-scroll-utils';
import {
  LEFT_VIEW_DOCUMENT_ID,
  RIGHT_VIEW_DOCUMENT_ID,
} from 'constants/feature/statement-content-constants';

export const BLACK_LINE_VERSION_BLOCK = 'blackline-version-panel';
export const BLACK_LINE_VERSION_BLOCK_ID = 'blackline-version-panel-id';

const BlacklineVersionView = () => {
  const dispatch = useDispatch();
  const {
    leftStatementContent: { leftSectionIdList },
    selectedStatement: { isOCR },
  } = useSelector((store) => store.data);
  const {
    statementPage: {
      modes: { blacklineViewSyncScrollingMode },
    },
  } = useSelector((store) => store.ui);

  useEffect(() => {
    const rightView = document.getElementById(RIGHT_VIEW_DOCUMENT_ID);
    const leftView = document.getElementById(LEFT_VIEW_DOCUMENT_ID);

    registerLeftScrollListeners(leftView);
    registerRightScrollListeners(rightView);

    return () => {
      deRegisterLeftScrollListeners(leftView);
      deRegisterRightScrollListeners(rightView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftSectionIdList.isLoaded]);

  useEffect(() => {
    const rightView = document.getElementById(RIGHT_VIEW_DOCUMENT_ID);
    const leftView = document.getElementById(LEFT_VIEW_DOCUMENT_ID);

    if (blacklineViewSyncScrollingMode) {
      registerLeftScrollListeners(leftView);
      registerRightScrollListeners(rightView);
    } else {
      deRegisterLeftScrollListeners(leftView);
      deRegisterRightScrollListeners(rightView);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blacklineViewSyncScrollingMode]);

  useEffect(() => {
    return () => {
      dispatch(clearBlacklineDetails());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={BLACK_LINE_VERSION_BLOCK}>
      <div className={`${BLACK_LINE_VERSION_BLOCK}__previous`}>
        <BlacklinePreviousVersionView isOCR={isOCR} />
      </div>
      <div className={`${BLACK_LINE_VERSION_BLOCK}__current`}>
        <BlacklineCurrentVersionView isOCR={isOCR} />
      </div>
    </div>
  );
};

export default BlacklineVersionView;
