import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import Page from 'components/util/page-component';
import Tooltip from 'components/common/tool-tip-component';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as InfoIcon } from 'icons/bulb.svg';
import { ReactComponent as SwapIcon } from 'icons/swap.svg';
import { ReactComponent as DoubleArrow } from 'icons/double-arrow.svg';
import LeftSideView from 'pages/side-by-side-compare/left-side/left-side-view';
import RightSideView from 'pages/side-by-side-compare/right-side/right-side-view';
import Dropdown from 'pages/side-by-side-compare/dropdown/side-by-side-dropdown';
import { setAnnotationsForMapping } from 'store/actions/side-by-side-view-annotations-actions';
import { setLeftStatementSelected } from 'store/actions/source-statement-actions';
import ToolTip from 'components/common/tool-tip-component';
import { ReactComponent as ExpandedIcon } from 'icons/annotation-expanded.svg';
import { ReactComponent as CollapsedIcon } from 'icons/annotation-collapsed.svg';
import {
  dropdownOptionsForRevision,
  dropdownOptionsForStatements,
  TAB_NAME,
} from 'constants/util/side-by-side-utils';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import {
  clearSideBySideElementsMap,
  isBothLegacyOrOCRInSideBySideView,
  setSideBySideSyncScroll,
} from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import {
  clearAllLeftStatementData,
  mapSideBySideElementsRequest,
} from 'store/actions/statement-content-actions';
import SlideToggle from 'components/common/slide-toggle-component';
import {
  deRegisterLeftScrollListeners,
  deRegisterRightScrollListeners,
  registerLeftScrollListeners,
  registerRightScrollListeners,
} from 'utils/sync-scroll-utils';
import {
  SIDE_BY_SIDE_VIEW_LEGACY_COMBINATION_STATEMENT,
  SIDE_BY_SIDE_VIEW_OCR_COMBINATION_STATEMENT,
} from 'constants/feature/statement-content-constants';
import AnnotationDisplayOptions from 'models/data/annotation-display-filter-options-model';
import {
  resetAnnotationDisplayAndPositionOption,
  setAnnotationDisplayOption,
} from 'store/actions/annotation-display-actions';
import { disableAllElementsCallout } from 'store/actions/statement-content-annotation-creation-actions';
import { ReactComponent as CalloutDirectionSetterLeft } from 'icons/callouts-direction-setter-left.svg';
import { ReactComponent as CalloutDrodownArrow } from 'icons/callouts-dropdown-arrow.svg';
import { setAnnotationsPosition } from 'store/actions/statement-summary/statement-annotations-update-actions';
import { ANNOTATION_POSITIONS } from 'constants/feature/annotation-elements-constants';
import IconDropDown from 'components/common/icon-dropdown';

const CLOSE_ICON_DIMENSION = '28px';
export const SIDE_BY_SIDE_BLOCK = 'side-by-side-page';
export const SIDE_BY_SIDE_BLOCK_ID = 'side-by-side-page-id';
const ANNOTATION_SUMMARY_ICON_SIZE = '25px';
const ANNOTATION_MARKER_ICON_SIZE = '30px';
const CALLOUT_DROP_DOWN_ARROW_WIDTH = 15;
const CALLOUT_DROP_DOWN_ARROW_HEIGHT = 30;
const ICON_SIZE = 25;

const Header = ({
  push,
  selectedTab,
  setAnnotationsForMapping,
  sideBySideSyncScroll,
  setSideBySideSyncScroll,
  shouldDisableSyncScroll,
  displayOptions,
  setAnnotationDisplayOption,
  disableAllElementsCallout,
  setAnnotationsPosition,
  annotationPosition,
}) => {
  const { projectId, readOnly, revisionId, statementId } = useParams();

  const onBackButtonClick = () => {
    push(
      parseRoute(ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE, {
        params: {
          projectId,
          statementId,
          revisionId,
          readOnly,
        },
      }),
    );
  };
  return (
    <div className={`${SIDE_BY_SIDE_BLOCK}__header`}>
      <div className={`${SIDE_BY_SIDE_BLOCK}__header-container`}>
        <Button.IconButton
          id={`${SIDE_BY_SIDE_BLOCK_ID}__header-back-button`}
          className={`${SIDE_BY_SIDE_BLOCK}__header-back-button`}
          type={BUTTON_TYPES.icon}
          Icon={BackButton}
          onClick={onBackButtonClick}
        />
        <div className={`${SIDE_BY_SIDE_BLOCK}__header-title`}>
          <FormattedMessage id={'toolkit.icon.sidebyside.tooltip'} />
        </div>
        <Tooltip
          text="side-by-side-view.compare.header.tooltip.info"
          id={`${SIDE_BY_SIDE_BLOCK_ID}-info-tooltip`}
        >
          <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
        </Tooltip>
      </div>
      <div className={`${SIDE_BY_SIDE_BLOCK}__scroll-toggle-container`}>
        <div className={`${SIDE_BY_SIDE_BLOCK}__scroll-title`}>
          <FormattedMessage id={'compare-panel.sync-scroll.toggle.label'} />
        </div>
        <div className={`${SIDE_BY_SIDE_BLOCK}__scroll-toggle`}>
          <SlideToggle
            name={'compare-panel.sync-scroll.toggle.name'}
            onChange={() => {
              setSideBySideSyncScroll(!sideBySideSyncScroll);
            }}
            disabled={shouldDisableSyncScroll}
            checked={sideBySideSyncScroll}
            id={'synchronizing-scroll-toggle'}
          />
        </div>
        <div
          className={`${SIDE_BY_SIDE_BLOCK}__element-callout`}
          onClick={() => {
            let updatedOptions = displayOptions.merge({
              showAll: !displayOptions['showAll'],
            });
            if (updatedOptions.showAll) {
              let options = new AnnotationDisplayOptions();
              options.showAll = true;
              setAnnotationDisplayOption(options);
              disableAllElementsCallout({
                showCallout: true,
              });
            } else {
              setAnnotationDisplayOption(updatedOptions);
              disableAllElementsCallout({ showCallout: false });
            }
          }}
        >
          <ToolTip
            id={
              displayOptions.showAll
                ? `${SIDE_BY_SIDE_BLOCK_ID}-collapse`
                : `${SIDE_BY_SIDE_BLOCK_ID}-expand`
            }
            text={
              displayOptions.showAll
                ? 'statement-summary-annotaions-collapse-all'
                : 'statement-summary-annotaions-expand-all'
            }
            position={'top'}
          >
            <div>
              {displayOptions.showAll ? (
                <ExpandedIcon
                  width={ANNOTATION_SUMMARY_ICON_SIZE}
                  height={ANNOTATION_SUMMARY_ICON_SIZE}
                />
              ) : (
                <CollapsedIcon
                  width={ANNOTATION_SUMMARY_ICON_SIZE}
                  height={ANNOTATION_SUMMARY_ICON_SIZE}
                />
              )}
            </div>
          </ToolTip>
        </div>
        <div className={`${SIDE_BY_SIDE_BLOCK}__icon-dropdown-container`}>
          <div className={`${SIDE_BY_SIDE_BLOCK}__icons`}>
            <IconDropDown
              id={`${SIDE_BY_SIDE_BLOCK_ID}__icon-dropdown`}
              displayIconPlaceholder={
                <CalloutDirectionSetterLeft
                  width={ANNOTATION_MARKER_ICON_SIZE}
                  height={ANNOTATION_MARKER_ICON_SIZE}
                />
              }
              tooltip={{
                id: SIDE_BY_SIDE_BLOCK_ID,
                text: 'statement-annotations-update-block-tooltip',
                position: 'top',
              }}
              arrowIcon={
                <CalloutDrodownArrow
                  width={CALLOUT_DROP_DOWN_ARROW_WIDTH}
                  height={CALLOUT_DROP_DOWN_ARROW_HEIGHT}
                />
              }
              options={ANNOTATION_POSITIONS}
              value={annotationPosition.annotationPosition}
              onSelectOption={(position) =>
                setAnnotationsPosition({ position })
              }
            />
          </div>
        </div>
      </div>
      <Dropdown
        options={
          selectedTab === TAB_NAME.LEFT
            ? dropdownOptionsForRevision
            : dropdownOptionsForStatements
        }
        setAnnotationsForMapping={setAnnotationsForMapping}
      />
    </div>
  );
};

const HeaderWrapper = connect(null, { push })(Header);

const SubHeader = ({ onClick }) => {
  return (
    <div className={`${SIDE_BY_SIDE_BLOCK}__subheader`}>
      <div className={`${SIDE_BY_SIDE_BLOCK}__subheader-container`}>
        <Tooltip
          text="side-by-side-view.compare.subheader.tooltip.info"
          id={`${SIDE_BY_SIDE_BLOCK_ID}-subheader-info-tooltip`}
          className={`${SIDE_BY_SIDE_BLOCK}__swap-icon-tooltip`}
        >
          <div
            className={`${SIDE_BY_SIDE_BLOCK}__subheader-wrap`}
            onClick={onClick}
          >
            <div className={`${SIDE_BY_SIDE_BLOCK}__swap-icon`}>
              <SwapIcon width={ICON_SIZE} height={ICON_SIZE} />
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export const SideBySideView = (props) => {
  const {
    setLeftStatementSelected,
    clearSideBySideElementsMap,
    match,
    mapSideBySideElementsRequest,
    setAnnotationsForMapping,
    numberOfTargetElementSelected,
    numberOfSourceElementSelected,
    clearAllLeftStatementData,
    sideBySideSyncScroll,
    setSideBySideSyncScroll,
    selectedStatement,
    leftSelectedStatement,
    revision,
    leftRevision,
    syncScrollIds,
    displayOptions,
    setAnnotationDisplayOption,
    disableAllElementsCallout,
    setAnnotationsPosition,
    annotationPosition,
    resetAnnotationDisplayAndPositionOption,
  } = props;
  const [loadSourceStatement, setLoadSourceStatement] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TAB_NAME.LEFT);
  const [isSwapped, setIsSwapped] = useState(false);
  const [shouldDisableMapBtn, setShouldDisableMapBtn] = useState(false);
  const onSwapStatements = () => setIsSwapped((oldState) => !oldState);
  const [leftStatementNavPanel, setLeftStatementNavPanel] = useState(false);
  const [rightStatementNavPanel, setRightStatementNavPanel] = useState(false);
  const [shouldDisableSyncScroll, setShouldDisableSyncScroll] = useState(false);

  useEffect(() => {
    setShouldDisableMapBtn(
      numberOfTargetElementSelected === 0 &&
        numberOfSourceElementSelected === 0,
    );
  }, [numberOfTargetElementSelected, numberOfSourceElementSelected]);

  const onConfirmStatement = () => {
    setLoadSourceStatement(!loadSourceStatement);
    setLeftStatementSelected(!loadSourceStatement);
    onClearSelection();
    clearAllLeftStatementData();
  };

  useEffect(() => {
    const viewType = isBothLegacyOrOCRInSideBySideView();
    const isBothNotLegacyOrOCRStatements = !(
      viewType === SIDE_BY_SIDE_VIEW_LEGACY_COMBINATION_STATEMENT ||
      viewType === SIDE_BY_SIDE_VIEW_OCR_COMBINATION_STATEMENT
    );

    if (leftSelectedStatement && leftSelectedStatement.isLoaded) {
      if (isBothNotLegacyOrOCRStatements) {
        if (
          (leftStatementNavPanel || rightStatementNavPanel) &&
          sideBySideSyncScroll
        ) {
          setSideBySideSyncScroll(false);
          setShouldDisableSyncScroll(true);
        } else if (
          !leftStatementNavPanel &&
          !rightStatementNavPanel &&
          !sideBySideSyncScroll
        ) {
          setSideBySideSyncScroll(true);
          setShouldDisableSyncScroll(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftStatementNavPanel, rightStatementNavPanel, leftSelectedStatement]);

  useEffect(() => {
    const leftStatementView =
      syncScrollIds.sourceId && document.getElementById(syncScrollIds.sourceId);
    if (sideBySideSyncScroll) {
      registerLeftScrollListeners(leftStatementView);
    } else {
      deRegisterLeftScrollListeners(leftStatementView);
    }
    return () => {
      deRegisterLeftScrollListeners(leftStatementView);
    };
  });

  useEffect(() => {
    if (sideBySideSyncScroll) {
      const leftStatementView = document.getElementById(syncScrollIds.sourceId);
      const rightStatementView = document.getElementById(
        syncScrollIds.targetId,
      );
      if (leftStatementView && rightStatementView) {
        leftStatementView.scrollTop = rightStatementView.scrollTop = 0;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideBySideSyncScroll]);

  useEffect(() => {
    resetAnnotationDisplayAndPositionOption();
    const rightStatementView =
      syncScrollIds.targetId && document.getElementById(syncScrollIds.targetId);
    if (
      sideBySideSyncScroll &&
      revision.isLoaded &&
      selectedStatement.isLoaded &&
      leftSelectedStatement.isLoaded
    ) {
      registerRightScrollListeners(rightStatementView);
    } else {
      deRegisterRightScrollListeners(rightStatementView);
    }
    return () => {
      deRegisterRightScrollListeners(rightStatementView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leftRevision.isLoaded,
    leftSelectedStatement.isLoaded,
    revision.isLoaded,
    selectedStatement.isLoaded,
    sideBySideSyncScroll,
    syncScrollIds.sourceId,
    syncScrollIds.targetId,
  ]);

  const onClearSelection = () => clearSideBySideElementsMap();

  const onMapElements = () => {
    setShouldDisableMapBtn(true);
    const { statementId, revisionId } = match.params;
    mapSideBySideElementsRequest(statementId, revisionId);
  };

  return (
    <Page className={SIDE_BY_SIDE_BLOCK}>
      <HeaderWrapper
        selectedTab={selectedTab}
        setAnnotationsForMapping={setAnnotationsForMapping}
        sideBySideSyncScroll={sideBySideSyncScroll}
        setSideBySideSyncScroll={setSideBySideSyncScroll}
        shouldDisableSyncScroll={shouldDisableSyncScroll}
        displayOptions={displayOptions}
        setAnnotationDisplayOption={setAnnotationDisplayOption}
        disableAllElementsCallout={disableAllElementsCallout}
        setAnnotationsPosition={setAnnotationsPosition}
        annotationPosition={annotationPosition}
      />
      <SubHeader onClick={onSwapStatements} />
      <div
        className={classnames(`${SIDE_BY_SIDE_BLOCK}__body-section`, {
          [`${SIDE_BY_SIDE_BLOCK}__body-section__swap`]: isSwapped,
        })}
      >
        <LeftSideView
          loadSourceStatement={loadSourceStatement}
          isSwapped={isSwapped}
          onConfirmStatement={onConfirmStatement}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setLeftStatementNavPanel={setLeftStatementNavPanel}
          leftStatementNavPanel={leftStatementNavPanel}
        />
        <RightSideView
          loadSourceStatement={loadSourceStatement}
          isSwapped={isSwapped}
          setRightStatementNavPanel={setRightStatementNavPanel}
          rightStatementNavPanel={rightStatementNavPanel}
        />
      </div>
      {loadSourceStatement && (
        <div className={`${SIDE_BY_SIDE_BLOCK}__footer`}>
          <div
            className={classnames(`${SIDE_BY_SIDE_BLOCK}__footer-wrap`, {
              [`${SIDE_BY_SIDE_BLOCK}__footer-wrap__swap`]: isSwapped,
            })}
          >
            <div
              className={classnames(`${SIDE_BY_SIDE_BLOCK}__footer-left`, {
                [`${SIDE_BY_SIDE_BLOCK}__footer-left__swap`]: isSwapped,
              })}
            >
              <div
                className={classnames(
                  `${SIDE_BY_SIDE_BLOCK}__footer-left-title`,
                  {
                    [`${SIDE_BY_SIDE_BLOCK}__display-title-modifier`]:
                      numberOfSourceElementSelected > 0,
                  },
                )}
              >
                {numberOfSourceElementSelected ? (
                  <>
                    <div
                      className={`${SIDE_BY_SIDE_BLOCK}__element-count-label`}
                    >
                      <FormattedMessage
                        id="side-by-side-view.compare.footer.selected-element.count"
                        values={{
                          count: props.numberOfSourceElementSelected,
                        }}
                      />
                    </div>
                    <div>
                      <Tooltip
                        id={`${SIDE_BY_SIDE_BLOCK}-clearSelection-btn`}
                        position={'top'}
                        text="side-by-side-view.compare.button.cancel.tooltip"
                        key={`${SIDE_BY_SIDE_BLOCK_ID}__clearSelection-btn`}
                      >
                        <CloseButton
                          className={`${SIDE_BY_SIDE_BLOCK}__clearSelection-btn`}
                          onClick={onClearSelection}
                          height={CLOSE_ICON_DIMENSION}
                          width={CLOSE_ICON_DIMENSION}
                          role="button"
                        />
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <>
                    <FormattedMessage id="side-by-side-view.compare.footer.left.info" />
                  </>
                )}
              </div>
            </div>
            <div
              className={classnames({
                [`${SIDE_BY_SIDE_BLOCK}__double-arrow-icon__swap`]: isSwapped,
              })}
            >
              <DoubleArrow width={ICON_SIZE} height={ICON_SIZE} />
            </div>
            <div
              className={classnames(`${SIDE_BY_SIDE_BLOCK}__footer-right`, {
                [`${SIDE_BY_SIDE_BLOCK}__footer-right__swap`]: isSwapped,
              })}
            >
              <div
                className={classnames(
                  `${SIDE_BY_SIDE_BLOCK}__footer-right-title`,
                  {
                    [`${SIDE_BY_SIDE_BLOCK}__display-title-modifier`]:
                      props.numberOfSourceElementSelected > 0,
                  },
                )}
              >
                {numberOfTargetElementSelected ? (
                  <>
                    <div
                      className={`${SIDE_BY_SIDE_BLOCK}__element-count-label`}
                    >
                      <FormattedMessage
                        id="side-by-side-view.compare.footer.selected-element.count"
                        values={{
                          count: numberOfTargetElementSelected,
                        }}
                      />
                    </div>
                    <div>
                      <Tooltip
                        id={`${SIDE_BY_SIDE_BLOCK}-clearSelection-btn`}
                        position={'top'}
                        text="side-by-side-view.compare.button.cancel.tooltip"
                        key={`${SIDE_BY_SIDE_BLOCK_ID}__clearSelection-btn`}
                      >
                        <CloseButton
                          className={`${SIDE_BY_SIDE_BLOCK}__clearSelection-btn`}
                          onClick={onClearSelection}
                          height={CLOSE_ICON_DIMENSION}
                          width={CLOSE_ICON_DIMENSION}
                          role="button"
                        />
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <>
                    <FormattedMessage id="side-by-side-view.compare.footer.right.info" />
                  </>
                )}
              </div>
            </div>
          </div>
          <Button
            id={`${SIDE_BY_SIDE_BLOCK}-save`}
            className={`${SIDE_BY_SIDE_BLOCK}__save`}
            onClick={onMapElements}
            type={BUTTON_TYPES.primary}
            disabled={
              !numberOfTargetElementSelected ||
              !numberOfSourceElementSelected ||
              !(
                numberOfTargetElementSelected === numberOfSourceElementSelected
              ) ||
              // if side by side feature is disabled, then user should not be able to send map request
              !(
                window.TIEOUT &&
                window.TIEOUT.ENV &&
                window.TIEOUT.ENV.FEATURE &&
                window.TIEOUT.ENV.FEATURE
                  .ENABLE_SIDE_BY_SIDE_STATEMENT_ELEMENTS_MAP
              ) ||
              // if user clicks on map button to map annotations from source to target statement, it will be disabled until unless response is received
              shouldDisableMapBtn
            }
          >
            <FormattedMessage id="side-by-side-view.compare.button.map" />
          </Button>
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = ({
  data: {
    selectedStatement,
    leftSelectedStatement,
    revision,
    leftRevision,
    annotationDisplayOptions,
    statementSummary: { annotationPosition },
  },
  ui: {
    sideBySideView: { sideBySideElementMap },
    statementPage: {
      syncScrollIds,
      modes: { sideBySideSyncScroll },
    },
  },
}) => ({
  numberOfSourceElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping,
  numberOfTargetElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping,
  sideBySideSyncScroll,
  selectedStatement,
  leftSelectedStatement,
  revision,
  displayOptions: annotationDisplayOptions,
  leftRevision,
  syncScrollIds,
  annotationPosition,
});

const mapDispatchToProps = {
  setLeftStatementSelected,
  clearSideBySideElementsMap,
  mapSideBySideElementsRequest,
  setAnnotationsForMapping,
  clearAllLeftStatementData,
  setSideBySideSyncScroll,
  setAnnotationDisplayOption,
  disableAllElementsCallout,
  setAnnotationsPosition,
  resetAnnotationDisplayAndPositionOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBySideView);
