import {
  CONTEXT_KEY,
  getProjectApiClient,
  getSelectedProjectApiClient,
  getHomeGeoApiClient,
  getCurrentGeoApiClient,
  getSelectedGeoApiClient,
} from './api-client';
import { getDocChatApiClient } from 'api/docChat-api-client';

export const withDefaultContextAsSelectedProject = (
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) => {
  switch (contextKey) {
    case CONTEXT_KEY.PROJECT_CONTEXT:
      return getProjectApiClient(contextValue);
    case CONTEXT_KEY.SELECTED_PROJECT_CONTEXT:
      return getSelectedProjectApiClient();
    default:
      return getSelectedProjectApiClient();
  }
};

export const withDefaultContextAsProject = (
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
  valueForDefaultContext,
) => {
  switch (contextKey) {
    case CONTEXT_KEY.SELECTED_PROJECT_CONTEXT:
      return getSelectedProjectApiClient();
    case CONTEXT_KEY.HOME_GEO_CONTEXT:
      return getHomeGeoApiClient();
    default:
      return getProjectApiClient(valueForDefaultContext);
  }
};

export const withDefaultContextAsCurrentGeo = (
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) => {
  switch (contextKey) {
    case CONTEXT_KEY.HOME_GEO_CONTEXT:
      return getHomeGeoApiClient();
    default:
      return getCurrentGeoApiClient();
  }
};

export const withDefaultContextAsHomeGeo = (
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) => {
  switch (contextKey) {
    case CONTEXT_KEY.HOME_GEO_CONTEXT:
      return getHomeGeoApiClient();
    case CONTEXT_KEY.SELECTED_GEO_CONTEXT:
      return getSelectedGeoApiClient(contextValue);
    default:
      return getHomeGeoApiClient();
  }
};

export const withDefaultContextAsSelectedProjectDocChat = (
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) => {
  switch (contextKey) {
    default:
      return getDocChatApiClient();
  }
};

export const withDefaultContextAsSelectedGeo = (
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
  valueForDefaultContext,
) => {
  switch (contextKey) {
    case CONTEXT_KEY.HOME_GEO_CONTEXT:
      return getHomeGeoApiClient();
    case CONTEXT_KEY.SELECTED_PROJECT_CONTEXT:
      return getProjectApiClient(contextValue);
    default:
      return getSelectedGeoApiClient(valueForDefaultContext);
  }
};
