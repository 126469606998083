import { handleActions } from 'redux-actions';
import {
  enableRefreshIconAction,
  disableRefreshIconAction,
} from 'store/actions/preview-report-page-actions';

export default handleActions(
  {
    [enableRefreshIconAction]: () => true,
    [disableRefreshIconAction]: () => false,
  },
  false,
);
