import { useEffect } from 'react';
import useScreenSize from 'components/hooks/useScreenSize';
import { isNullOrUndefined } from 'utils/object-utils';

const PADDING_HEIGHT = 8; //px
const DEFAULT_VISIBLE_ROWS = 2;

function useAutoSizeTextArea(ref, value, maxVisibleRows) {
  const screenSize = useScreenSize();
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.height = '0px';
      const scrollHeight = ref.current.scrollHeight;
      const styles = window.getComputedStyle(ref.current);
      const lineHeight = parseInt(styles.lineHeight, 10);
      maxVisibleRows =
        isNullOrUndefined(maxVisibleRows) ||
        maxVisibleRows <= DEFAULT_VISIBLE_ROWS
          ? DEFAULT_VISIBLE_ROWS
          : maxVisibleRows;
      const textAreaMaxHeight =
        maxVisibleRows * lineHeight + PADDING_HEIGHT * 2;

      if (scrollHeight <= textAreaMaxHeight) {
        ref.current.style.height = scrollHeight + 'px';
        ref.current.style.overflow = 'hidden';
      } else {
        ref.current.style.height = textAreaMaxHeight + 'px';
        ref.current.style.overflow = 'auto';
      }
    }
  }, [ref.current, value, screenSize]);
}

export default useAutoSizeTextArea;
