import React from 'react';
import { useSelector } from 'react-redux';
import { ANNOTATION_DISPLAY_FUNCTIONS } from 'constants/feature/tieout-element-constants';
import { isNullOrUndefined } from 'utils/object-utils';
const ANNOTATION_ICON_SIZE = '10px';

const ElementFlagComponentOCR = ({
  elementDetails,
  polygonTop,
  polygonLeft,
}) => {
  const { annotationDisplayOptions: annotationDisplayFilter } = useSelector(
    (store) => store.data,
  );

  return (
    <>
      {!isNullOrUndefined(annotationDisplayFilter) &&
        annotationDisplayFilter.flag && (
          <div
            style={{
              position: 'absolute',
              top: `${polygonTop - 14}px`,
              left: `${polygonLeft - 2}px`,
              width: 'fit-content',
              zIndex: 48,
            }}
          >
            {ANNOTATION_DISPLAY_FUNCTIONS.flagged({
              elementDetails: elementDetails,
              iconSize: ANNOTATION_ICON_SIZE,
            })}
          </div>
        )}
    </>
  );
};
export default ElementFlagComponentOCR;
