/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Permissions from 'permissions/permissions';
import { connect, useDispatch } from 'react-redux';
import TieoutElement, {
  onElementClick,
} from 'components/feature/statement-content-panel/tieout-element-component';
import {
  setSourceStatementElementMapAction,
  setTargetStatementElementMapAction,
} from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { getExcessiveElementSelectionNotification } from 'constants/feature/tieout-element-utils';
import { updateSideBySideElementCacheByCallouts } from 'store/actions/statement-content-annotation-creation-actions';
import { STATEMENT_ELEMENT_ID_PREFIX } from 'constants/feature/statement-content-constants';
import { isNullOrUndefined } from 'utils/object-utils';

export const ElementSelectionLimit = 250;

export const TieoutElementHOC = (props) => {
  const {
    elementData,
    leftSideView,
    elementDataparts,
    zoom,
    leftZoom,
    rightZoom,
  } = props;

  // on page load, store the props in a ref.
  const latestProps = useRef(props);

  useEffect(() => {
    // update the ref with the latest props. based on the on change of props
    latestProps.current = props;
  }, [props]);

  const pathName = useLocation().pathname;
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const hasPermission = useMemo(
    () =>
      Permissions.SideBySideStatementViewPermissions.canSelectElement(
        projectId,
      ),
    [projectId],
  );

  const _isSideBySideView = useMemo(
    () => pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE),
    [pathName],
  );

  const getCountForSelectedElement = useCallback(() => {
    const elementCount =
      props.sideBySideElementMap &&
      props.sideBySideElementMap.getCountOfElementsSelected();
    return elementCount + 1;
  }, [props.sideBySideElementMap]);

  const handleElementClick = useCallback(
    ({ elementId, sectionId, isDisabled }) => {
      if (pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE) && hasPermission) {
        if (getCountForSelectedElement() <= ElementSelectionLimit) {
          let elementDataFromCache = !isNullOrUndefined(elementData)
            ? elementData.data
            : latestProps.current.elementData &&
              latestProps.current.elementData.data;
          if (leftSideView)
            elementDataFromCache &&
              dispatch(
                setSourceStatementElementMapAction(elementDataFromCache),
              );
          else
            _shouldEnableTargetElementForSelection(elementId) &&
              elementDataFromCache &&
              dispatch(
                setTargetStatementElementMapAction(elementDataFromCache),
              );
        } else {
          getExcessiveElementSelectionNotification(ElementSelectionLimit);
        }
      } else if (!_isSideBySideView) {
        return dispatch(onElementClick({ elementId, sectionId, isDisabled }));
      }
    },
    [
      pathName,
      hasPermission,
      leftSideView,
      _isSideBySideView,
      latestProps.current.sideBySideElementMap,
    ],
  );

  const handleAnnotationClick = useCallback(
    ({ elementId, showCallout }) => {
      dispatch(
        updateSideBySideElementCacheByCallouts({
          elementIds: [elementId],
          showCallout,
          isLeftSideView: leftSideView,
        }),
      );
    },
    [leftSideView],
  );

  const isElementSelectedForSideBySideMap = useCallback(
    (elementId) => {
      if (_isSideBySideView) {
        if (leftSideView) {
          return (
            props.sideBySideElementMap &&
            props.sideBySideElementMap.getSourceElementMapByElementId(elementId)
          );
        }
        return (
          props.sideBySideElementMap &&
          props.sideBySideElementMap.getTargetElementMapByElementId(elementId)
        );
      } else return false;
    },
    [_isSideBySideView, leftSideView, props.sideBySideElementMap],
  );

  const getCountForSelectedElementForSideBySideMapping = useCallback(
    (elementId) => {
      if (_isSideBySideView) {
        if (leftSideView) {
          return (
            props.sideBySideElementMap &&
            props.sideBySideElementMap.getCountForMappedSourceElement(elementId)
          );
        }
        return (
          props.sideBySideElementMap &&
          props.sideBySideElementMap.getCountForMappedTargetElement(elementId)
        );
      } else return null;
    },
    [_isSideBySideView, leftSideView, props.sideBySideElementMap],
  );

  const _shouldEnableTargetElementForSelection = useCallback(
    (elementId) => {
      if (
        _isSideBySideView &&
        !leftSideView &&
        latestProps.current.sideBySideElementMap
      ) {
        return (
          latestProps.current.sideBySideElementMap
            .mapKeyOfFirstUnmappedTargetElement ||
          latestProps.current.sideBySideElementMap.getTargetElementMapByElementId(
            elementId,
          )
        );
      }
    },
    [_isSideBySideView, leftSideView, latestProps.current.sideBySideElementMap],
  );

  const handleSideBySideIsDisabled = useCallback(
    (elementId) => {
      return _isSideBySideView
        ? leftSideView
          ? !hasPermission
          : !(
              hasPermission && _shouldEnableTargetElementForSelection(elementId)
            )
        : false;
    },
    [_isSideBySideView, leftSideView],
  );

  const isCursorAlias = useMemo(() => {
    return hasPermission // return true if user has permission to the respective statement for elements mapping in side-by-side view
      ? leftSideView // returns true if it is left side statement in side-by-side view
        ? true
        : latestProps.current.numberOfSourceElementSelected >
          latestProps.current.numberOfTargetElementSelected // returns true if number of selected source elements is greater than number of selected target elements to change the pointer(disabled to selection pointer) of right statement accordingly in side-by-side view
      : false;
  }, [
    leftSideView,
    hasPermission,
    latestProps.current.numberOfSourceElementSelected,
    latestProps.current.numberOfTargetElementSelected,
  ]);

  return (
    <TieoutElement
      {...props}
      isCursorAlias={isCursorAlias}
      isSideBySideView={pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)}
      isSideBySideViewDisabled={handleSideBySideIsDisabled}
      leftSideView={leftSideView}
      elementDataparts={elementDataparts}
      elementData={elementData}
      handleElementClick={handleElementClick}
      handleAnnotationClick={handleAnnotationClick}
      isElementSelectedForSideBySideMap={isElementSelectedForSideBySideMap}
      getCountForSelectedElementForSideBySideMapping={
        getCountForSelectedElementForSideBySideMapping
      }
      zoom={_isSideBySideView ? (leftSideView ? leftZoom : rightZoom) : zoom}
    />
  );
};
const mapStateToProps = (
  {
    data: {
      elementPanel: {
        elementDetails: { id: elementPanelElementId },
      },
      copyFormula: { tableMatrix },
      annotationDisplayOptions: annotationDisplayFilter,
      selectedStatement: { isCftEnabled },
      statementContent: { elementsDataparts, elementCache },
      leftStatementContent: { leftElementsDataparts, leftElementCache },
      statementSummary: {
        annotationPosition,
        statementSummaryElementsDisplay: {
          displayElementDetailsOnStatementPage,
        },
      },
    },
    ui: {
      sideBySideView: { sideBySideElementMap },
      statementPage: {
        zoom,
        leftZoom,
        rightZoom,
        selectedElementsMap,
        panels: { right },
        highlightedElementId,
      },
    },
  },
  ownProps,
) => {
  let elementId = ownProps.id.substring(STATEMENT_ELEMENT_ID_PREFIX.length);
  if (elementId && elementId.indexOf('_') >= 0) {
    elementId = elementId.substr(0, elementId.indexOf('_'));
  }
  const sectionId = ownProps.sectionId;
  const elementData = ownProps.leftSideView
    ? leftElementCache.getElement({ elementId, sectionId: sectionId })
    : elementCache.getElement({ elementId, sectionId: sectionId });

  const isElementSelected = selectedElementsMap.has(elementId);
  const elementHighlightState =
    selectedElementsMap.getElementHighlightColor(elementId);

  const rightPanelState =
    parseInt(elementId) === elementPanelElementId ? right : null;

  let isPartOfTableMatrix = false;
  if (tableMatrix.length > 0) {
    tableMatrix[elementId]
      ? (isPartOfTableMatrix = true)
      : (isPartOfTableMatrix = false);
  } else {
    isPartOfTableMatrix = true;
  }

  const elementDataparts = ownProps.leftSideView
    ? leftElementsDataparts
    : elementsDataparts;

  const renderElementDataParts =
    elementDataparts &&
    elementDataparts.elements &&
    !!Object.keys(elementDataparts.elements).length &&
    elementId in elementDataparts.elements &&
    !isNullOrUndefined(elementDataparts.elements[elementId]);

  return {
    isElementSelected,
    elementHighlightState,
    elementData,
    annotationDisplayFilter,
    isCftEnabled,
    isPartOfTableMatrix,
    elementPanelElementId: elementPanelElementId,
    highlightedElementId,
    rightPanelState,
    annotationPosition,
    elementDataparts,
    renderElementDataParts,
    sideBySideElementMap,
    zoom,
    numberOfSourceElementSelected:
      sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping,
    numberOfTargetElementSelected:
      sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping,
    displayElementDetailsOnStatementPage,
    sectionId,
    leftZoom,
    rightZoom,
  };
};

// Custom comparison function for React.memo
const areEqual = (prevProps, nextProps) => {
  // For example, only re-render if `elementData` or `leftSideView` changes
  return (
    prevProps.elementData === nextProps.elementData &&
    prevProps.leftSideView === nextProps.leftSideView &&
    prevProps.sideBySideElementMap === nextProps.sideBySideElementMap &&
    prevProps.leftSelectedElementsMap === nextProps.leftSelectedElementsMap &&
    prevProps.isElementSelected === nextProps.isElementSelected && // updating selectedElementsMap use in tieout element
    prevProps.elementHighlightState === nextProps.elementHighlightState && // updating selectedElementsMap use in tieout element
    prevProps.numberOfSourceElementSelected ===
      nextProps.numberOfSourceElementSelected &&
    prevProps.numberOfTargetElementSelected ===
      nextProps.numberOfTargetElementSelected &&
    prevProps.renderElementDataParts === nextProps.renderElementDataParts && // To render when change in elementDataparts
    prevProps.annotationDisplayFilter === nextProps.annotationDisplayFilter &&
    prevProps.isCftEnabled === nextProps.isCftEnabled &&
    prevProps.isPartOfTableMatrix === nextProps.isPartOfTableMatrix &&
    prevProps.highlightedElementId === nextProps.highlightedElementId &&
    prevProps.rightPanelState === nextProps.rightPanelState &&
    prevProps.annotationPosition.position ===
      nextProps.annotationPosition.position &&
    prevProps.zoom === nextProps.zoom &&
    prevProps.displayElementDetailsOnStatementPage ===
      nextProps.displayElementDetailsOnStatementPage &&
    prevProps.leftZoom === nextProps.leftZoom &&
    prevProps.rightZoom === nextProps.rightZoom
  );
};

export default connect(
  mapStateToProps,
  null,
)(React.memo(TieoutElementHOC, areEqual));
