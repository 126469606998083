import React from 'react';
import { isNullOrUndefined } from 'utils/object-utils';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { ANNOTATION_DISPLAY_FUNCTIONS } from 'constants/feature/tieout-element-constants';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';

export const hasAnnotations = (elementDetails, isCftEnabled = true) => {
  return (
    !isNullOrUndefined(elementDetails) &&
    (elementDetails.hasInternalReferenceGroup() ||
      elementDetails.hasWorkpapers() ||
      elementDetails.hasTickmarks() ||
      (elementDetails.hasComfortLetters() && isCftEnabled) ||
      elementDetails.hasFormula())
  );
};

export const isElementReportPreviewPage = (url) =>
  url.includes(ROUTE_CONSTANTS.REPORT_PREVIEW);

export const getAnnotation = (elementDetails) => {
  const ELEMENT_BLOCK = 'tieout-element';
  return (
    <div className={`${ELEMENT_BLOCK}`}>
      <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
        {ANNOTATION_DISPLAY_FUNCTIONS.comfortLetter({
          elementDetails,
        })}
      </FeatureFlagComponent>
      {ANNOTATION_DISPLAY_FUNCTIONS.tickmarks({
        elementDetails,
      })}
      {ANNOTATION_DISPLAY_FUNCTIONS.formula({
        elementDetails,
      })}
      {ANNOTATION_DISPLAY_FUNCTIONS.internalRef({
        elementDetails,
      })}
      {ANNOTATION_DISPLAY_FUNCTIONS.workpaper({
        elementDetails,
      })}
    </div>
  );
};

export const getElementHighlightStatus = ({
  elementDetails,
  isElementSelected,
  isElementHighlighted,
  isPreviewPage = false,
  showElementStatusAndFlag = true,
  blacklineViewMode,
}) => {
  if (isNullOrUndefined(elementDetails)) {
    return {
      isReviewed: false,
      isVerified: false,
      isUnverified: false,
      isSelected: false,
    };
  }
  isElementSelected =
    typeof isElementSelected === 'function'
      ? isElementSelected()
      : isElementSelected;
  let isReviewed =
    elementDetails.isReviewed() && !isElementSelected && !isElementHighlighted;
  let isVerified =
    elementDetails.isVerified() && !isElementSelected && !isElementHighlighted;
  let isUnverified = !(
    elementDetails.isReviewed() ||
    elementDetails.isVerified() ||
    isElementSelected ||
    isElementHighlighted
  );

  if (isPreviewPage || blacklineViewMode) {
    isReviewed = showElementStatusAndFlag ? isReviewed : false;
    isVerified = showElementStatusAndFlag ? isVerified : false;
    isUnverified = showElementStatusAndFlag ? isUnverified : false;
    return {
      isReviewed,
      isVerified,
      isUnverified,
      isSelected: isElementSelected,
      isHighlighted: isElementHighlighted,
    };
  } else {
    return {
      isReviewed,
      isVerified,
      isUnverified,
      isSelected: isElementSelected,
      isHighlighted: isElementHighlighted,
    };
  }
};

export const getCssModifierForView = (
  url,
  blacklineViewMode,
  blacklineViewShowElementMode,
) => {
  if (blacklineViewMode && blacklineViewShowElementMode) {
    return 'blackline-view';
  }
  if (isElementReportPreviewPage(url)) {
    return 'report-preview';
  }
  return 'default';
};

export const getCalloutPosition = ({
  position,
  polygonInPixel,
  canvasHeight,
  canvasWidth,
  offsetHeight,
  offsetWidth,
  elementId,
  isPreviewPage,
}) => {
  let callout = {};
  const [x1, y1, , , x3, y3] = polygonInPixel;
  const topLeftYCoordinate = y1;
  const topLeftXCoordinate = x1;
  const bottomLeftYCoordinate = y3;
  const bottomLeftXCoordinate = x3;
  const verticleMidPointOfElement = (y3 - y1) / 2;
  const horizontalMidPointOfElement = (x3 - x1) / 2;
  const arrowPadding = 3;
  const spacePadding = isPreviewPage ? -1.8 : 2;

  switch (position) {
    case 'left':
      callout = {
        bottom: `${
          canvasHeight -
          topLeftYCoordinate -
          (offsetHeight && Object.keys(offsetHeight).length > 0
            ? offsetHeight[elementId]
            : 0) /
            2 -
          verticleMidPointOfElement
        }px`,
        right: `${
          canvasWidth - topLeftXCoordinate + arrowPadding + spacePadding
        }px`,
      };
      break;
    case 'right':
      callout = {
        bottom: `${
          canvasHeight -
          topLeftYCoordinate -
          (offsetHeight && Object.keys(offsetHeight).length > 0
            ? offsetHeight[elementId]
            : 0) /
            2 -
          verticleMidPointOfElement
        }px`,
        right: `${canvasWidth - bottomLeftXCoordinate - arrowPadding - 2}px`,
      };
      break;
    case 'top':
      callout = {
        bottom: `${
          canvasHeight - topLeftYCoordinate + arrowPadding + spacePadding
        }px`,
        right: `${
          canvasWidth -
          topLeftXCoordinate -
          (offsetWidth && Object.keys(offsetWidth).length > 0
            ? offsetWidth[elementId]
            : 0) /
            2 -
          horizontalMidPointOfElement
        }px`,
      };
      break;
    case 'bottom':
      callout = {
        bottom: `${
          canvasHeight -
          bottomLeftYCoordinate -
          offsetHeight[elementId] -
          arrowPadding -
          spacePadding
        }px`,
        right: `${
          canvasWidth -
          topLeftXCoordinate -
          (offsetWidth && Object.keys(offsetWidth).length > 0
            ? offsetWidth[elementId]
            : 0) /
            2 -
          horizontalMidPointOfElement
        }px`,
      };
      break;

    default:
      break;
  }
  return callout;
};
export const getShowCalloutValue = (elementData, annotationDisplayFilter) => {
  if (isNullOrUndefined(elementData)) {
    return true;
  }
  const annotationsConfig = {
    tickmarks: elementData.hasTickmarks(),
    formula: elementData.hasFormula(),
    internalRef: elementData.hasInternalReferenceGroup(),
    workpaper: elementData.hasWorkpapers(),
    comfortLetter: elementData.hasComfortLetters(),
  };
  const enabled = {};
  let disabledCount = 0;

  for (const [key, value] of Object.entries(annotationsConfig || {})) {
    if (value) {
      enabled[key] = value;
    }
  }
  const enabledKeys = Object.keys(enabled);

  enabledKeys.forEach((annotation) => {
    if (!annotationDisplayFilter[annotation]) {
      disabledCount += 1;
    }
  });
  return !(disabledCount === enabledKeys.length);
};

export const getMarkerPosition = ({
  position,
  polygonInPixel,
  canvasHeight,
}) => {
  let marker = {};
  const [x1, y1, , , x3, y3] = polygonInPixel;

  const topLeftYCoordinate = y1;
  const topLeftXCoordinate = x1;
  const bottomLeftYCoordinate = y3;
  const bottomLeftXCoordinate = x3;
  const verticleMidPointOfElement = (y3 - y1) / 2;
  const horizontalMidPointOfElement = (x3 - x1) / 2;
  const markerWidth = 7;
  const markerPadding = 3;
  const markerHeight = 2;
  const spacePadding = 3;

  switch (position) {
    case 'left':
      marker = {
        bottom: `${
          canvasHeight -
          topLeftYCoordinate -
          verticleMidPointOfElement -
          markerPadding
        }px`,
        left: `${topLeftXCoordinate - markerWidth - spacePadding}px`,
      };
      break;
    case 'top':
      marker = {
        bottom: `${canvasHeight - topLeftYCoordinate - markerHeight}px`,
        left: `${
          topLeftXCoordinate +
          horizontalMidPointOfElement -
          markerWidth / 2 -
          markerHeight
        }px`,
      };
      break;
    case 'right':
      marker = {
        bottom: `${
          canvasHeight -
          topLeftYCoordinate -
          verticleMidPointOfElement -
          markerPadding
        }px`,
        left: `${bottomLeftXCoordinate - markerHeight}px`,
      };
      break;
    case 'bottom':
      marker = {
        bottom: `${
          canvasHeight - bottomLeftYCoordinate - markerWidth - spacePadding
        }px`,
        left: `${
          topLeftXCoordinate +
          horizontalMidPointOfElement -
          markerWidth / 2 -
          markerHeight
        }px`,
      };
      break;

    default:
      break;
  }
  return marker;
};

export const getInitialCalloutVisibilityDetails = (
  config,
  annotationDisplayFilter,
) => {
  const displayMarker = annotationDisplayFilter.showAll ? false : config.marker;
  const displayCallout = annotationDisplayFilter.showAll
    ? true
    : config.callout;
  const displayAnnotation = annotationDisplayFilter.showAll
    ? true
    : config.display;

  return { displayMarker, displayCallout, displayAnnotation };
};

export const getCalloutVisibilityDetails = (
  calloutVisibilityDetails,
  annotationsConfig,
  annotationDisplayFilter,
) => {
  const enabled = {};
  let disabledCount = 0;
  let displayMarker = calloutVisibilityDetails.displayMarker;
  let displayCallout = calloutVisibilityDetails.displayCallout;
  let displayAnnotation = calloutVisibilityDetails.displayAnnotation;

  for (const [key, value] of Object.entries(annotationsConfig || {})) {
    if (value) {
      enabled[key] = value;
    }
  }

  const enabledKeys = Object.keys(enabled);

  enabledKeys.forEach((annotation) => {
    if (!annotationDisplayFilter[annotation]) {
      disabledCount += 1;
    }
  });

  if (disabledCount === enabledKeys.length) {
    displayMarker = true;
    displayCallout = false;
    displayAnnotation = false;
  }

  return {
    displayMarker,
    displayCallout,
    displayAnnotation,
  };
};
