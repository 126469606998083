import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import StatementList from 'components/feature/statement-list/statement-list-component';
import {
  softDeleteCurrentRevision,
  softDeleteAllRevisions,
  permanentlyDeleteRevision,
  permanentlyDeleteAllSoftDeletedRevisionsForStatement,
  navigateToStatement,
  setUploadedFilesAddRevision,
  setUploadedFilesAddRevisionError,
  uploadRevision,
  requestStatementListWithParams,
  requestGetStatementList,
  copyStatement,
} from 'store/actions/statement-list/statement-list-actions';
import {
  updatePinDetails,
  updateUnpinDetails,
} from 'store/actions/pinned-details-actions';

const mapStateToProps = ({
  data: {
    currentUser,
    pinnedDetails,
    statementList: {
      statementList,
      statementListFilters: { selectedTab, sort },
      revision,
    },
    workflowsMap,
    selectedProject: { project },
  },
  ui: {
    statementPage: { statementWorkflowSteps },
  },
}) => ({
  statementList,
  project,
  selectedTab,
  sortModel: sort,
  revisionInProgress: revision,
  workflowsMap,
  currentUser,
  pinnedDetails,
  statementWorkflowSteps,
});

const mapDispatchToProps = {
  push,
  softDeleteCurrentRevision,
  softDeleteAllRevisions,
  permanentlyDeleteAllSoftDeletedRevisionsForStatement,
  permanentlyDeleteRevision,
  navigateToStatement,
  selectTabAndGetStatements: requestStatementListWithParams,
  setUploadedFilesAddRevision,
  setUploadedFilesAddRevisionError,
  uploadRevision,
  requestSortedStatementList: requestStatementListWithParams,
  refreshStatementList: requestGetStatementList,
  copyStatement,
  updatePinDetails,
  updateUnpinDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementList);
