import { connect } from 'react-redux';

import { selectNoteFromContentPanel } from 'store/actions/notes-panel-actions';
import {
  requestCreateNewFormula,
  requestUpdateFormula,
} from 'store/actions/formula-actions';
import {
  toggleFormulaCancelModal,
  openCancelModalAfterModalConfirm,
  storeSelectedNoteId,
  storeSelectedNotePseudoId,
} from 'store/actions/panel-cancel-modal-actions';
import FormulaPanel from 'components/feature/element-panel/formula-panel/formula-panel-component';
import { toggleNotesPanel } from 'store/actions/panel-controller-actions';
import { onSectionClick } from 'store/actions/statement-content-actions';
import { cancelCopyFormula } from 'store/actions/copy-formula-actions';

const mapStateToProps = (
  {
    data: {
      formulaPanel: { formula },
      selectedStatement: { isOCR },
    },
    ui: {
      statementPage: { cancelModal },
    },
  },
  ownProps,
) => ({
  ...ownProps,
  formulaInProgress: formula,
  showCancelModalBoolean: formula.showCancelModalBoolean,
  shouldOpenNotePanelAfterCancel: formula.openNotePanelAfterCancel,
  selectedNoteId: formula.selectedNoteAfterFormulaCancel,
  selectedNotePseudoId: formula.selectedNotePseudoId,
  cancelModal,
  isOCR,
});
const mapDispatchToProps = {
  saveFormula: requestCreateNewFormula,
  updateFormula: requestUpdateFormula,
  toggleFormulaCancelModal,
  toggleNotesPanel,
  selectNoteFromContentPanel,
  openCancelModalAfterModalConfirm,
  storeSelectedNoteId,
  storeSelectedNotePseudoId,
  onSectionClick,
  cancelCopyFormula,
};
export default connect(mapStateToProps, mapDispatchToProps)(FormulaPanel);
