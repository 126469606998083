export const displayOptionsConfig = {
  displayAll: true,
  elementId: true,
  displayValue: true,
  scaledValue: true,
  pageNo: true,
  label: true,
  status: true,
  workpaper: true,
  tickmarks: true,
  formula: true,
  internalRef: true,
  comfortLetter: true,
  scaling: true,
  units: true,
  period: true,
  entity: true,
};

const REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID = 'report-preview-page-id';

export const OPTIONS_CHECKBOX_ARRAY = [
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-displayAll-checkbox`,
    name: 'displayAll',
    key: '1',
    label: 'report.preview.displayAll.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-elementId-checkbox`,
    name: 'elementId',
    key: '2',
    label: 'report.preview.elementId.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-displayValue-checkbox`,
    name: 'displayValue',
    key: '3',
    label: 'report.preview.displayValue.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-scaledValue-checkbox`,
    name: 'scaledValue',
    key: '4',
    label: 'report.preview.scaledValue.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-pageNo-checkbox`,
    name: 'pageNo',
    key: '5',
    label: 'report.preview.pageNo.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-label-checkbox`,
    name: 'label',
    key: '6',
    label: 'report.preview.label.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-status-checkbox`,
    name: 'status',
    key: '7',
    label: 'report.preview.status.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-workpaperReference-checkbox`,
    name: 'workpaper',
    key: '8',
    label: 'report.preview.workpaper.reference.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-tickmarks-checkbox`,
    name: 'tickmarks',
    key: '9',
    label: 'report.preview.tickmarks.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-footedFormulas-checkbox`,
    name: 'formula',
    key: '10',
    label: 'report.preview.footed.formulas.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-internalReferences-checkbox`,
    name: 'internalRef',
    key: '11',
    label: 'report.preview.internal.references.label',
  },

  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-comfortLetter-checkbox`,
    name: 'comfortLetter',
    key: '12',
    label: 'report.preview.comfort.letter.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-scaling-checkbox`,
    name: 'scaling',
    key: '13',
    label: 'report.preview.scaling.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-units-checkbox`,
    name: 'units',
    key: '14',
    label: 'report.preview.units.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-period-checkbox`,
    name: 'period',
    key: '15',
    label: 'report.preview.period.label',
  },
  {
    id: `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-entity-checkbox`,
    name: 'entity',
    key: '16',
    label: 'report.preview.entity.label',
  },
];
