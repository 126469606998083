import SyncScrollIds from 'models/api/sync-scroll-ids-modal';
import { handleActions } from 'redux-actions';
import { clearSideBySideElementsMap } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import {
  clearStatementPageStoreAction,
  setSourceSyncScrollId,
  setTargetSyncScrollId,
} from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [setSourceSyncScrollId]: (syncScrollIds, { payload }) => {
      return syncScrollIds.setSourceId(payload);
    },
    [setTargetSyncScrollId]: (syncScrollIds, { payload }) => {
      return syncScrollIds.setTargetId(payload);
    },
    [clearSideBySideElementsMap]: (syncScrollIds) => {
      return syncScrollIds.clearSourceId();
    },
    [clearStatementPageStoreAction]: () => {
      return new SyncScrollIds();
    },
  },
  new SyncScrollIds(),
);
