import BaseModel from 'models/base-model';
import {
  STATEMENT_STATUS_LABELS,
  STATEMENT_STATUS_ENUM,
} from 'constants/feature/statement-list-constants';

export default class Statement extends BaseModel({
  accountingBasis: null,
  originalAccountingBasis: null,
  archiveDate: null,
  clientEntity: null,
  clientId: null,
  fiscalYear: null,
  originalFiscalYear: null,
  hasBeenOpenedByUser: false,
  id: null,
  statementDisplayId: null,
  lastModifiedBy: null,
  lastModifiedDate: null,
  revisionId: null,
  revisionLastModifiedBy: null,
  revisionLastModifiedByName: null,
  revisionLastModifiedDate: null,
  revisionNumber: null,
  legalHoldDisabled: null,
  numElements: null,
  numElementsCompleted: null,
  percentageCompleted: null,
  periodEnding: null,
  originalPeriodEnding: null,
  quarter: null,
  originalQuarter: null,
  registrant: null,
  originalRegistrant: null,
  signedDate: null,
  signedUser: null,
  statementName: null,
  statementTypeId: null,
  originalStatementTypeId: null,
  status: null,
  type: null,
  originalType: null,
  wrapUpSubmissionUserId: null, // id of user who submitted statement for wrap up approval
  activeRevisionsCount: 0,
  sentBy: null,
  sentDate: null,
  omDocumentState: null,
  omExportStatus: null,
  omExportErrorInfo: null,
  legalHold: null,
  comfortLetter: null,
  isImported: false,
  techSupportAccess: false,
  pinnedDatetime: null,
}) {
  constructor(props) {
    super(props);
    // First setup the original fields which cannot be edited on the model.
    const {
      accountingBasis,
      fiscalYear,
      periodEnding,
      quarter,
      registrant,
      statementTypeId,
      type,
      legalHold,
      isCftEnabled,
      techSupportAccess,
    } = props;
    this.originalAccountingBasis = accountingBasis;
    this.originalFiscalYear = fiscalYear;
    this.originalPeriodEnding = periodEnding;
    this.originalQuarter = quarter;
    this.originalRegistrant = registrant;
    this.originalStatementTypeId = statementTypeId;
    this.originalType = type;
    this.legalHold = legalHold;
    this.comfortLetter = isCftEnabled;
    this.techSupportAccess = techSupportAccess;
    // Next map the editable and display fields to the model.
    if (props.statementDisplayEntity) {
      const { statementDisplayEntity } = props;
      this.statementDisplayId = statementDisplayEntity.id;
      this.accountingBasis = statementDisplayEntity.accountingBasis;
      this.fiscalYear = statementDisplayEntity.fiscalYear;
      this.periodEnding = statementDisplayEntity.periodEnding;
      this.quarter = statementDisplayEntity.quarter;
      this.registrant = statementDisplayEntity.registrant;
      this.statementTypeId = statementDisplayEntity.statementTypeId;
      this.type = statementDisplayEntity.type;
    }

    delete this.statementDisplayEntity;
  }

  toEditApiFormat() {
    return {
      ...this,
      clientId: parseInt(this.clientId),
      accountingBasis: this.originalAccountingBasis,
      fiscalYear: this.originalFiscalYear,
      periodEnding: this.originalPeriodEnding,
      quarter: this.orignalQuarter,
      registrant: this.originalRegistrant,
      statementTypeId: this.originalStatementTypeId,
      legalHold: this.legalHold,
      autoIdentifyIrs: this.autoIdentifyIrs,
      statementDisplayEntity: {
        accountingBasis: this.accountingBasis,
        fiscalYear: this.fiscalYear,
        id: this.statementDisplayId,
        periodEnding: this.periodEnding,
        quarter: this.quarter,
        registrant: this.registrant,
        statementId: this.statementId,
        statementTypeId: this.statementTypeId,
      },
    };
  }

  get percentageComplete() {
    return (
      //this equation gets the % verified to two decimal places. That is what multiplying by 100 a second time does.
      //if you wanted 3 decimal places you would multiply by 1000.
      Math.round(this.percentageCompleted * 100)
    );
  }

  get quarterNumber() {
    if (this.quarter && this.quarter !== 'NONE') {
      return this.quarter.slice(-1);
    }
    return 0;
  }

  get originalQuarterNumber() {
    if (this.originalQuarter && this.originalQuarter !== 'NONE') {
      return this.originalQuarter.slice(-1);
    }
    return 0;
  }

  getStatementRevisions() {
    return this.activeRevisionEntities
      .concat(this.deletedRevisionEntities)
      .sort((a, b) => {
        return parseInt(a.revisionNumber) < parseInt(b.revisionNumber) ? -1 : 1;
      });
  }

  statementHasMultipleRevisions() {
    return this.revisionNumber > 1;
  }

  isSoftDeleted() {
    return this.getStatementStatus().id === STATEMENT_STATUS_LABELS.DELETED.id;
  }

  getStatementStatus() {
    return STATEMENT_STATUS_LABELS[STATEMENT_STATUS_ENUM[this.status]];
  }

  setHasBeenOpenedByUser(hasBeenOpenedByUser) {
    return this.merge({ ...this, hasBeenOpenedByUser });
  }

  isInArchivalFlow() {
    const status = this.getStatementStatus();
    const { PENDING_APPROVAL, ARCHIVED, APPROVAL_INITIATED } =
      STATEMENT_STATUS_LABELS;
    return (
      status === PENDING_APPROVAL ||
      status === ARCHIVED ||
      status === APPROVAL_INITIATED
    );
  }
  isLegalHoldSet() {
    return this.legalHold;
  }
  isPendingApproval() {
    return (
      this.getStatementStatus() === STATEMENT_STATUS_LABELS.PENDING_APPROVAL
    );
  }

  hasApprovalInitiated() {
    return (
      this.getStatementStatus() === STATEMENT_STATUS_LABELS.APPROVAL_INITIATED
    );
  }

  isWrappedUp() {
    return this.getStatementStatus() === STATEMENT_STATUS_LABELS.ARCHIVED;
  }

  shouldShowRevertToPreviousRevisionOption() {
    return parseInt(this.activeRevisionsCount) >= 2;
  }
}
