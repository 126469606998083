import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';
export default class Workflow extends BaseModel({
  endTime: null,
  initiatorId: null,
  previousStatus: null,
  retryable: null,
  revisionId: null,
  startTime: null,
  statementName: null,
  statementId: null,
  status: null,
  errorInfo: null,
}) {
  getWorkFlowStatus(statementWorkflowSteps) {
    return this._getWorkFlowStatus(this.status, statementWorkflowSteps);
  }
  getPreviousWorkflowStatus(statementWorkflowSteps) {
    return this._getWorkFlowStatus(this.previousStatus, statementWorkflowSteps);
  }
  _getWorkFlowStatus = (status, statementWorkflowSteps) => {
    return !isNullOrUndefined(status)
      ? statementWorkflowSteps[status] &&
          this.getWorkFlowStatusDetails(statementWorkflowSteps[status])
      : null;
  };

  getWorkFlowStatusDetails(stepByStatus) {
    return {
      status: stepByStatus.status,
      statusDisplay: stepByStatus.displayMessage,
      currentStep: stepByStatus.currentStep,
      totalSteps: stepByStatus.numberOfSteps,
    };
  }

  isEqual(anotherWorkflow, statementWorkflowSteps) {
    return (
      !isNullOrUndefined(anotherWorkflow) &&
      this.startTime === anotherWorkflow.startTime &&
      this.initiatorId === anotherWorkflow.initiatorId &&
      this.endTime === anotherWorkflow.endTime &&
      JSON.stringify(this.getPreviousWorkflowStatus(statementWorkflowSteps)) ===
        JSON.stringify(
          anotherWorkflow.getPreviousWorkflowStatus(statementWorkflowSteps),
        ) &&
      JSON.stringify(this.getWorkFlowStatus(statementWorkflowSteps)) ===
        JSON.stringify(
          anotherWorkflow.getWorkFlowStatus(statementWorkflowSteps),
        ) &&
      this.retryable === anotherWorkflow.retryable
    );
  }
}
