import ApiModel from 'models/api-model';
import BlacklineDetails from 'models/api/blackline-detail-api-model';

export default class BlacklineDataBySectionMap extends ApiModel({
  data: {
    blacklineChanges: [],
    sectionId: null,
  },
}) {
  get blacklineDetails() {
    return this.data;
  }
  processResponse({ response, sectionId }) {
    const blacklineChanges = [];
    response.data.forEach((blacklineData) => {
      const blacklineDetail = new BlacklineDetails().setLoadedFromBlacklineData(
        {
          ...blacklineData,
        },
      );
      blacklineChanges.push(blacklineDetail);
    });
    return {
      data: {
        blacklineChanges,
        sectionId,
      },
    };
  }

  clearBlacklineContent() {
    return this.merge({
      isLoaded: false,
      isLoading: true,
      data: {
        ...this.data,
        blacklineChanges: [],
      },
    });
  }
}
