import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as StatementNavIcon } from 'icons/statement-navigator.svg';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import {
  initStatementContent,
  onStatementNavSectionSelect,
} from 'store/actions/statement-content-actions';
import SlideToggle from 'components/common/slide-toggle-component';
import PreviewReportStatementNav from './_preview-report-statement-nav';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import StatementContentPanelContainerOCR from 'containers/feature/statement-content/statement-content-panel-container-ocr';
import { hideStatementNavPanelAction } from 'store/actions/panel-controller-actions';
import { ANNOTATION_POSITIONS } from 'constants/feature/annotation-elements-constants';
import { setAnnotationsPosition } from 'store/actions/statement-summary/statement-annotations-update-actions';
import LeftSideHTMLStatementPreview from './_report-preview-left-side-html-statement';
import {
  hideElementIdAction,
  showElementIdAction,
  showStatusAndFlagAction,
} from 'store/actions/preview-report-page-actions';
import {
  hideStatusAndFlagAction,
  setFirstElementVisibleInViewPortAction,
} from 'store/actions/preview-report-page-actions';
import { BLOCK_ID } from 'components/feature/statement-content-panel/statement-content-panel-component-ocr';
import ToolTip from 'components/common/tool-tip-component';
import ZoomControlHOC from 'higher-order-components/zoom-control-hoc-component';

export const REPORT_PREVIEW_LEFT_BLOCK = 'report-preview-page-left-panel';
export const REPORT_PREVIEW_LEFT_BLOCK_ID = 'report-preview-page-left-panel-id';
const SCROLL_TIMEOUT = 150;
let scrollingTimeout = null;
export const REPORT_PREVIEW_LEFT_VIEW_STATEMENT_NAV_ICON_SIZE = '28px';

const ReportPreviewLeftSideView = ({
  ocrCurrentViewPageNumber,
  selectedStatement,
  showStatusAndFlagAction,
  hideStatusAndFlagAction,
  showElementIdAction,
  hideElementIdAction,
  toggleViews,
  elementCache,
  setOcrCurrentViewPageNumberDispatch,
  isSyncScrollingEnabled,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showElementId, setShowElementId] = useState(true);
  const [showElementStatusAndFlag, setShowElementStatusAndFlag] =
    useState(false);
  const [isContentPanelScrolling, setContentPanelScrolling] = useState(false);

  const [showStatementNav, setShowStatementNav] = useState(false);

  const _toggleStatementNav = () => {
    setShowStatementNav((prevState) => !prevState);
  };

  const navigateToSelectedPage = (page) => {
    setOcrCurrentViewPageNumberDispatch(page);
    const element = document.getElementById(`${BLOCK_ID}${page}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  const isOCRStatement = selectedStatement.isOCR;

  const statementRef = useRef(null);

  useEffect(() => {
    showElementStatusAndFlag
      ? showStatusAndFlagAction()
      : hideStatusAndFlagAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showElementStatusAndFlag]);

  useEffect(() => {
    showElementId ? showElementIdAction() : hideElementIdAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showElementId]);

  useEffect(() => {
    dispatch(initStatementContent(params));
    dispatch(setAnnotationsPosition({ position: ANNOTATION_POSITIONS[0] }));
    dispatch(hideStatementNavPanelAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);


  //set first element in viewport in legacy only
  useEffect(() => {
    if (!isOCRStatement && isSyncScrollingEnabled) {
      dispatch(setFirstElementVisibleInViewPortAction(isOCRStatement));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentPanelScrolling, elementCache]);

  useEffect(() => {
    statementRef.current &&
      statementRef.current.addEventListener('scroll', (event) => {
        if (!isContentPanelScrolling) {
          setContentPanelScrolling(true);
        }
        window.clearTimeout(scrollingTimeout);
        scrollingTimeout = setTimeout(() => {
          setContentPanelScrolling(false);
        }, SCROLL_TIMEOUT);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentPanelScrolling, statementRef]);

  return (
    <div className={REPORT_PREVIEW_LEFT_BLOCK} style={{ height: '100%' }}>
      <div
        className={classNames(
          `${REPORT_PREVIEW_LEFT_BLOCK}__statement-header`,
          toggleViews &&
            `${REPORT_PREVIEW_LEFT_BLOCK}__statement-header__reverse`,
        )}
      >
        <ToolTip
          id={`${REPORT_PREVIEW_LEFT_BLOCK_ID}__statement-header-tooltip`}
          position={'bottom'}
          text={'toolkit.icon.statement-nav.tooltip'}
          key={`${REPORT_PREVIEW_LEFT_BLOCK_ID}__statement-header-tooltip`}
        >
          <button
            id={`${REPORT_PREVIEW_LEFT_BLOCK_ID}__statement-nav-button-id`}
            className={classnames(
              `${REPORT_PREVIEW_LEFT_BLOCK}__statement-nav-button`,
              {
                [`${REPORT_PREVIEW_LEFT_BLOCK}__statement-nav-button--selected`]:
                  showStatementNav,
              },
            )}
            onClick={_toggleStatementNav}
          >
            <StatementNavIcon
              className={classnames(`${REPORT_PREVIEW_LEFT_BLOCK}__nav-icon`)}
              width={REPORT_PREVIEW_LEFT_VIEW_STATEMENT_NAV_ICON_SIZE}
              height={REPORT_PREVIEW_LEFT_VIEW_STATEMENT_NAV_ICON_SIZE}
            />
          </button>
        </ToolTip>
        <div
          className={classNames({
            [`${REPORT_PREVIEW_LEFT_BLOCK}__statement-header--title`]: true,
            [`${REPORT_PREVIEW_LEFT_BLOCK}__statement-header--title-reverse`]:
              toggleViews,
          })}
        >
          <FormattedMessage
            id={'statement.navigation.report.preview.left.view.subtitle'}
          />
        </div>
        <div
          className={classNames({
            [`${REPORT_PREVIEW_LEFT_BLOCK}__statement-header-controls`]: true,
            [`${REPORT_PREVIEW_LEFT_BLOCK}__statement-header-controls--reverse`]:
              toggleViews,
          })}
        >
          <ZoomControlHOC
            isSideBySidePage={false}
            isLeftSideView={false}
            shouldDisplayExpandIcon={false}
          />
          <SlideToggle
            label="statement.navigation.report.preview.left.view.toggle.element.status"
            name={'status-and-flag'}
            onChange={() =>
              setShowElementStatusAndFlag(!showElementStatusAndFlag)
            }
            checked={showElementStatusAndFlag}
            id={'element-status-and-flag'}
            className={`${REPORT_PREVIEW_LEFT_BLOCK}__status-and-flag-toggle`}
          />

          <SlideToggle
            label="statement.navigation.report.preview.left.view.toggle.element.id"
            name={'id'}
            onChange={() => setShowElementId(!showElementId)}
            checked={showElementId}
            id={'elementId'}
            className={`${REPORT_PREVIEW_LEFT_BLOCK}__element-id-toggle`}
          />
        </div>
      </div>
      {isOCRStatement ? (
        <div
          className={`${REPORT_PREVIEW_LEFT_BLOCK}__left-preview-ocr`}
          id={`${REPORT_PREVIEW_LEFT_BLOCK_ID}__left-preview-ocr`}
        >
          <StatementContentPanelContainerOCR
            setOcrCurrentViewPageNumberDispatch={
              setOcrCurrentViewPageNumberDispatch
            }
            ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
            markerDisplayValue={false}
            calloutDisplayValue={showElementId}
            showElementStatusAndFlag={showElementStatusAndFlag}
            statementRef={statementRef}
          />
          <PreviewReportStatementNav
            showStatementNav={showStatementNav}
            _toggleStatementNav={_toggleStatementNav}
            navigateToSelectedPage={navigateToSelectedPage}
          />
        </div>
      ) : (
        <div
          className={`${REPORT_PREVIEW_LEFT_BLOCK}__left-preview-html-wrapper`}
        >
          <PreviewReportStatementNav
            showStatementNav={showStatementNav}
            _toggleStatementNav={_toggleStatementNav}
            navigateToSelectedPage={navigateToSelectedPage}
          />
          <div
            ref={statementRef}
            className={`${REPORT_PREVIEW_LEFT_BLOCK}__left-preview-html`}
            id={`${REPORT_PREVIEW_LEFT_BLOCK_ID}__left-preview-html`}
          >
            <LeftSideHTMLStatementPreview
              params={params}
              statementRef={statementRef}
              isContentPanelScrolling={isContentPanelScrolling}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  data: {
    revision,
    selectedStatement,
    statementContent: { elementCache },
    sectionTreeList,
    statementContent: {
      sectionsCache: { contentSectionMap },
      sectionIdList,
    },
    sectionPanel,
  },
  ui: {
    statementPage: {
      sectionsInView,
      modes: { ocrCurrentViewPageNumber },
      statementNavigatorPanel: { statementOCRNavigation },
    },
    previewPage: { isSyncScrollingEnabled },
  },
}) => ({
  revision,
  ocrCurrentViewPageNumber,
  selectedStatement,
  sectionsInView,
  elementCache,
  sectionTreeList,
  contentSectionMap,
  sectionIdList,
  sectionReview: sectionPanel.sectionReviewList,
  statementOCRNavigation,
  isSyncScrollingEnabled,
});

export default connect(mapStateToProps, {
  setOcrCurrentViewPageNumberDispatch,
  showStatusAndFlagAction,
  hideStatusAndFlagAction,
  showElementIdAction,
  hideElementIdAction,
  onStatementNavSectionSelect,
})(ReportPreviewLeftSideView);
