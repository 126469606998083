import React from 'react';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { ReactComponent as CalloutDirectionSetterLeft } from 'icons/callouts-direction-setter-left.svg';
import { ReactComponent as CalloutDirectionSetterTop } from 'icons/callouts-direction-setter-top.svg';
import { ReactComponent as CalloutDirectionSetterRight } from 'icons/callouts-direction-setter-right.svg';
import { ReactComponent as CalloutDirectionSetterBottom } from 'icons/callouts-direction-setter-bottom.svg';

export const ANNOTATION_POSITION_TYPES = {
  LEFT: 1,
  TOP: 2,
  RIGHT: 3,
  BOTTOM: 4,
};

const ANNOTATION_SUMMARY_ICON_SIZE = '30px';

export const ANNOTATION_POSITION_OPTIONS_MAP = new Map();

ANNOTATION_POSITION_OPTIONS_MAP.set(
  ANNOTATION_POSITION_TYPES.LEFT,
  new MenuOption({
    id: ANNOTATION_POSITION_TYPES.LEFT,
    title: 'annotations-direction-left',
    value: ANNOTATION_POSITION_TYPES.LEFT,
    icon: (
      <CalloutDirectionSetterLeft
        width={ANNOTATION_SUMMARY_ICON_SIZE}
        height={ANNOTATION_SUMMARY_ICON_SIZE}
      />
    ),
  }),
);
ANNOTATION_POSITION_OPTIONS_MAP.set(
  ANNOTATION_POSITION_TYPES.TOP,
  new MenuOption({
    id: ANNOTATION_POSITION_TYPES.TOP,
    title: 'annotations-direction-top',
    value: ANNOTATION_POSITION_TYPES.TOP,
    icon: (
      <CalloutDirectionSetterTop
        width={ANNOTATION_SUMMARY_ICON_SIZE}
        height={ANNOTATION_SUMMARY_ICON_SIZE}
      />
    ),
  }),
);
ANNOTATION_POSITION_OPTIONS_MAP.set(
  ANNOTATION_POSITION_TYPES.RIGHT,
  new MenuOption({
    id: ANNOTATION_POSITION_TYPES.RIGHT,
    title: 'annotations-direction-right',
    value: ANNOTATION_POSITION_TYPES.RIGHT,
    icon: (
      <CalloutDirectionSetterRight
        width={ANNOTATION_SUMMARY_ICON_SIZE}
        height={ANNOTATION_SUMMARY_ICON_SIZE}
      />
    ),
  }),
);
ANNOTATION_POSITION_OPTIONS_MAP.set(
  ANNOTATION_POSITION_TYPES.BOTTOM,
  new MenuOption({
    id: ANNOTATION_POSITION_TYPES.BOTTOM,
    title: 'annotations-direction-bottom',
    value: ANNOTATION_POSITION_TYPES.BOTTOM,
    icon: (
      <CalloutDirectionSetterBottom
        width={ANNOTATION_SUMMARY_ICON_SIZE}
        height={ANNOTATION_SUMMARY_ICON_SIZE}
      />
    ),
  }),
);
export const ANNOTATION_POSITIONS = Array.from(
  ANNOTATION_POSITION_OPTIONS_MAP.values(),
);
