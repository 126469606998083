import { handleActions } from 'redux-actions';
import {
  sectionIdListStatementContentLoading,
  sectionIdListStatementContentLoaded,
  sectionIdListStatementContentError,
  clearOCRSectionImageWithIdsFromCache,
} from 'store/actions/statement-content-actions';
import {
  sectionOCRDataSegmentsStatementContentLoaded,
  sectionOCRDataSegmentsStatementContentError,
} from 'store/actions/statement-content-actions-ocr';
import { SectionCacheOCR } from 'models/api/sections-cache-model-ocr-data';
export default handleActions(
  {
    [sectionIdListStatementContentLoading]: (sectionCache) => {
      return sectionCache.setLoading();
    },
    [sectionIdListStatementContentLoaded]: (sectionCache, { payload }) => {
      return sectionCache.setLoaded(payload);
    },
    [sectionIdListStatementContentError]: (sectionCache, { payload }) => {
      return sectionCache.setError(payload);
    },
    [sectionOCRDataSegmentsStatementContentLoaded]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeOCRSectionDataLoaded(payload);
    },
    [sectionOCRDataSegmentsStatementContentError]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.setError(payload);
    },
    [clearOCRSectionImageWithIdsFromCache]: (sectionCache, { payload }) => {
      return sectionCache.clearSectionOCRContentFromMap(payload);
    },
  },
  new SectionCacheOCR(),
);
