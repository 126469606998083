import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/common/loading-component';
import ApiModel from 'models/api-model';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  createErrorStackTraceAction,
  createCatchedErrorJsonAction,
} from 'store/actions/api-error-handler-actions';
import * as sourceStackTrace from 'sourcemapped-stacktrace';
import { toast } from 'react-toastify';
import ApiErrorNotification, {
  API_ERROR_NOTIFICATION_ID,
} from 'components/util/api-error-notificiation-component';
import CloseToastButton from 'components/util/close-toast-button-component';
class ConditionalRender extends Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(...args) {
    console.log('Error caught in error boundary', args);

    // render error notification in the case of runtime errors
    let stack = '';
    for (let index = 0; index < args.length; index++) {
      const element = args[index];
      if (element.componentStack) {
        stack += element.componentStack;
      } else {
        stack += element;
      }
    }
    const { createErrorStackTraceAction, locale } = this.props;
    createErrorStackTraceAction({ locale, response: stack });
    toast.error(<ApiErrorNotification />, {
      className: 'Toastify__toast Toastify__toast__no-hover',
      autoClose: false,
      closeOnClick: false,
      closeButton: <CloseToastButton />,
      draggable: false,
      toastId: API_ERROR_NOTIFICATION_ID,
    });
  }

  render() {
    const {
      children,
      dependencies,
      errorComponent: ErrorComponent = () => (
        <div>
          <FormattedMessage id={'error-message'} />
        </div>
      ),
      loadingComponent: LoadingComponent = () => <Loading />,
      createErrorStackTraceAction,
      createCatchedErrorJsonAction,
      locale,
    } = this.props;

    const { error } = this.state;

    if (error || dependencies.some(({ error }) => error)) {
      if (dependencies.some(({ error }) => error)) {
        let browserStack = new Error().stack;
        let catchedErrors;
        // First try with stack for the failed object and then if there is some
        // issue with the approach switch to browser stack.
        // Error stack generated using the above approach(browserStack) is generally not recommeneded
        // suggested reading : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
        // Hence our first try is to get it from failed Object.
        try {
          // sourceStackTrace.mapStackTrace is used to undo any unintentional transformation
          // of code that is sometimes done by browser to execute/optimise it.
          sourceStackTrace.mapStackTrace(browserStack, (stackTrace) => {
            browserStack = stackTrace.join('\n');
          });
          // filter all the dependecies which has a valid error object.
          catchedErrors = dependencies
            .filter(
              (possibleFailedObj) =>
                possibleFailedObj && possibleFailedObj.error,
            )
            .reduce((prev, current) => [...prev, current.error], []);
          // set strack trace values in the redux store.
          catchedErrors.forEach((catchedError) => {
            // If your object contains error message and stack of type non-enumerable
            // then it is not possible to extract it as an Json string and
            // update the redux store. Hence we need to check if it is non-enumerbale
            // then re-define it and make it enumerable.
            catchedError.message &&
              !{}.propertyIsEnumerable.call('message', catchedError) &&
              Object.defineProperty(catchedError, 'message', {
                enumerable: true,
                value: catchedError.message,
              });
            catchedError.stack &&
              !{}.propertyIsEnumerable.call('stack', catchedError) &&
              Object.defineProperty(catchedError, 'stack', {
                enumerable: true,
                value: catchedError.stack,
              });
            // sourceStackTrace.mapStackTrace is used to undo any unintentional transformation
            // of code that is sometimes done by browser to execute/optimise it.
            catchedError.stack &&
              sourceStackTrace.mapStackTrace(
                catchedError.stack,
                (catchedStack) =>
                  Object.defineProperty(catchedError, 'stack', {
                    enumerable: true,
                    value: catchedStack.join('\n'),
                  }),
              );
            //Added setTimeout method to fix the issue: Cannot update a component while rendering a different component.
            //This error occurs when you're trying to update a component's state while rendering a different component.
            //This is not allowed in React because it can cause infinite loops and other unexpected behavior.
            //To fix this issue, we need to identify the state update call that's causing the problem and move it to a safe location.
            //currently added this timer method, we will optimize this later using some other approach
            setTimeout(() => {
              // Although browser stack trace is not standard. We want to get this info as well.
              // Since sometimes it gives worthy info and our intention is to get as much information
              // as we can get.
              createErrorStackTraceAction({ locale, response: browserStack });
              // JSON.stringify(catchedError, null, 2) with 2 passed for space will prettify it
              // Hence when we copy it in clipboard it looks indented and good.
              createCatchedErrorJsonAction({
                locale,
                response: JSON.stringify(catchedError, null, 2).replaceAll(
                  '\\n',
                  '\n',
                ),
              });
            }, 0);
          });
        } catch (e) {
          // switch to browser stack
          createErrorStackTraceAction({ locale, response: browserStack });
        }
      }

      if (!window.TIEOUT.ENV.FEATURE.ENABLE_UNHANDLED_API_NOTIFCATION) {
        toast.error(<ApiErrorNotification />, {
          className: 'Toastify__toast Toastify__toast__no-hover',
          autoClose: false,
          closeOnClick: false,
          closeButton: <CloseToastButton />,
          draggable: false,
          toastId: API_ERROR_NOTIFICATION_ID,
        });
      }

      return <ErrorComponent />;
    }

    if (dependencies.some(({ isLoading }) => isLoading)) {
      return <LoadingComponent />;
    }

    if (!dependencies.every(({ isLoaded }) => isLoaded)) {
      return null;
    }

    return children;
  }
}

ConditionalRender.propTypes = {
  /** Node to be condtionaly rendered inside of the condition render component */
  children: PropTypes.node,
  /** Array of Api Models passed in that indicate the state of the api call and its returned data */
  dependencies: PropTypes.arrayOf(ApiModel),
  /** React component to be displayed for loading state */
  loadingComponent: PropTypes.element,
  /** React component to be displayed for error state */
  errorComponent: PropTypes.element,
};

const mapStateToProps = ({ ui: { locale } }) => ({
  locale,
});

const mapDispatchToProps = {
  createErrorStackTraceAction,
  createCatchedErrorJsonAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalRender);
