import { isNullOrUndefined } from 'utils/object-utils';

export const chatBotFailApiResponse =
  "I'm sorry, but I am unable to answer your question. This may be because the information that you are looking for is not present in the given document, or it is only available in tabular format, which I am unable to process. I am also unable to provide accounting guidance or recommendations.\n\nPlease consider rephrasing your question if you'd like to try again, or ask a different question.";

export const goodResponseDefaultState = {
  completeness: false,
  accuracy: false,
  understandability: false,
  other: false,
};

export const badResponseDefaultState = {
  completeness: false,
  accuracy: false,
  understandability: false,
  incorrect_topic: false,
  incorrect_sources: false,
  inappropriate: false,
  other: false,
};

export const DOC_INDEXING_STATUS = {
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const CHAT_BOT_TYPING_ID = 'typing';
export const THUMBS_UP = 'thumbs_up';
export const THUMBS_DOWN = 'thumbs_down';

export const getInitials = (string) => {
  if (isNullOrUndefined(string)) return;
  const initials = string.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  return initials[0] + initials[1];
};

export const goodReasonCheckboxOptions = [
  {
    id: `completeness`,
    label: 'chatbot.response.type.good.completeness',
    value: 'completeness',
  },
  {
    id: `accuracy`,
    label: 'chatbot.response.type.good.accuracy',
    value: 'accuracy',
  },
  {
    id: `understandability`,
    label: 'chatbot.response.type.good.understandability',
    value: 'understandability',
  },
  {
    id: `other`,
    label: 'chatbot.response.type.good.other',
    value: 'other',
  },
];

export const badReasonCheckboxOptions = [
  {
    id: `completeness`,
    label: 'chatbot.response.type.bad.completeness',
    value: 'completeness',
  },
  {
    id: `accuracy`,
    label: 'chatbot.response.type.bad.accuracy',
    value: 'accuracy',
  },
  {
    id: `understandability`,
    label: 'chatbot.response.type.bad.understandability',
    value: 'understandability',
  },
  {
    id: `incorrect_topic`,
    label: 'chatbot.response.type.bad.incorrect.topic',
    value: 'incorrect_topic',
  },
  {
    id: `incorrect_sources`,
    label: 'chatbot.response.type.bad.incorrect.sources',
    value: 'incorrect_sources',
  },
  {
    id: `inappropriate`,
    label: 'chatbot.response.type.bad.inappropriate',
    value: 'inappropriate',
  },
  {
    id: `other`,
    label: 'chatbot.response.type.bad.other',
    value: 'other',
  },
];

export const groupCitation = (message) => {
  let groupedCitations = {};

  message.documentSource &&
    message.documentSource.forEach((doc) => {
      let pageNumber = doc.page_number;
      let citation = doc.citation_string.replace(/\n+/g, ' ');

      // Format the citation string
      let formattedCitation =
        citation.length > 40
          ? citation.slice(0, 20) + '[....]' + citation.slice(-20)
          : citation; // If the string is too short, leave it as is

      if (!groupedCitations[pageNumber]) {
        groupedCitations[pageNumber] = [];
      }
      groupedCitations[pageNumber].push(formattedCitation);
    });

  return groupedCitations;
};

export const createPreviousMessages = (messages) => {
  if (messages.length <= 1) {
    return [];
  }

  let groupedArray = [];

  for (let i = 0; i < messages.length; i += 2) {
    if (i + 1 < messages.length) {
      let groupedObject = {
        timestamp: messages[i + 1].timestamp,
        message: messages[i].message,
        response: messages[i + 1].response,
      };
      groupedArray.push(groupedObject);
    }
  }

  return groupedArray.length > 3 ? groupedArray.slice(-3) : groupedArray;
};
