import React from 'react';

const HtmlOverlay = ({ box }) => {
  return (
    <div>
      {box && (
        <div
          style={{
            position: 'absolute',
            border: '2px solid black',
            borderStyle: 'dotted',
            left: box.left + 'px',
            top: box.top + 'px',
            width: box.width + 'px',
            height: box.height + 'px',
          }}
        ></div>
      )}
    </div>
  );
};

export default HtmlOverlay;
