import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CreateComfortLetterModal } from '../comfort-letter-panel/create-comfort-letter-modal';
import ElementDetails from '../../../../models/api/element-details-api-model';
import {
  createElementComfortLetterRequest,
  getMatchingComfortLettersForElementRequest,
} from 'api/comfort-letter-api';
import ConditionalRender from 'components/util/conditional-render-component';
import ElementComfortLetterList from 'models/api/element-comfort-letter-list-model';
import shortid from 'shortid';
import { AttachComfortLetter } from '../comfort-letter-panel/_comfort-letter-panel-attach-component';
import { attachComfortLetterAction } from 'store/actions/element-panel-actions';
import ComfortLetterEntryWithDetach from './comfort-letter-entry/comfort-letter-entry-with-detach-component';
import Permissions from 'permissions/permissions';
import { fetchComfortAnnotationListForRevisionWithoutLoading } from 'store/actions/comfort-annotations-list-actions';

export const COMFORT_LETTER_LIST_ID_BLOCK = 'comfort-letter-block-id';
export const COMFORT_LETTER_LIST_BLOCK = 'comfort-letter-block';

export const ElementComfortLetterListComponent = ({
  elementDetails,
  comfortLetterList,
  getElementComfortLettersAndUpdateElementCache,
  revisionId,
  attachComfortLetterAction,
  onDetachComfortLetter,
  fetchComfortAnnotationListForRevisionWithoutLoading,
  readOnly,
}) => {
  const [showCreateComfortModal, setShowCreateComfortModal] =
    React.useState(false);

  const _handleCreateNewComfortLetter = async ({ comfortLetterForm }) => {
    await createElementComfortLetterRequest({ comfortLetterForm });
    getElementComfortLettersAndUpdateElementCache();
    fetchComfortAnnotationListForRevisionWithoutLoading({ revisionId });
    _toggleCreateComfortLettterModal();
  };

  const _canDetachOrAttachComfortLetter =
    Permissions.Statement.canChangeContent();

  const _toggleCreateComfortLettterModal = () => {
    setShowCreateComfortModal((prevModalState) => !prevModalState);
  };

  const _searchComfortLetters = async ({ searchTerm }) => {
    return await getMatchingComfortLettersForElementRequest({
      revisionId,
      searchTerm,
      elementId: elementDetails.id,
    });
  };

  const [currentComfortLetterID, setCurrentComfortLetterID] =
    React.useState(undefined);

  const updateExpandedComfortLetterID = (toggleStatus, id) => {
    !toggleStatus && setCurrentComfortLetterID(id);
  };

  return (
    <div className={COMFORT_LETTER_LIST_BLOCK}>
      <AttachComfortLetter
        onCreate={_toggleCreateComfortLettterModal}
        onSearchComfortLetter={_searchComfortLetters}
        onAttach={attachComfortLetterAction}
        disabled={!_canDetachOrAttachComfortLetter || readOnly}
        readOnly={readOnly}
      />
      <div className={`${COMFORT_LETTER_LIST_BLOCK}__listing-container`}>
        <ConditionalRender dependencies={[comfortLetterList]}>
          {comfortLetterList.hasComfortLetter() ? (
            comfortLetterList
              .getComfortLettersSortedAlphabetically()
              .map((comfortLetter) => (
                <ComfortLetterEntryWithDetach
                  key={`${COMFORT_LETTER_LIST_BLOCK}__entry-component-key-${shortid.generate()}`}
                  comfortLetter={comfortLetter}
                  className={`${COMFORT_LETTER_LIST_BLOCK}__entry-component`}
                  onDetachComfortLetter={onDetachComfortLetter}
                  disabled={!_canDetachOrAttachComfortLetter || readOnly}
                  currentlyExpandedComfortLetterID={currentComfortLetterID}
                  updateExpandedComfortLetterID={updateExpandedComfortLetterID}
                />
              ))
          ) : (
            <div className={`${COMFORT_LETTER_LIST_BLOCK}__no-comfort-letters`}>
              <FormattedMessage id="element-panel.annotation.content.no-comfort-letter" />
            </div>
          )}
        </ConditionalRender>
      </div>
      {showCreateComfortModal && (
        <CreateComfortLetterModal
          onClose={_toggleCreateComfortLettterModal}
          elementDetails={elementDetails}
          onCreate={_handleCreateNewComfortLetter}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  attachComfortLetterAction,
  fetchComfortAnnotationListForRevisionWithoutLoading,
};

export default connect(
  undefined,
  mapDispatchToProps,
)(ElementComfortLetterListComponent);

ElementComfortLetterListComponent.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /**Action fired to get latest comfort letter details and update cache and element panel */
  getElementComfortLettersAndUpdateElementCache: PropTypes.func.isRequired,
  /**List of comfort letters */
  comfortLetterList: PropTypes.instanceOf(ElementComfortLetterList).isRequired,
  /**Number to represent revision id */
  revisionId: PropTypes.number.isRequired,
  /**ation method fired to detach comfort letter */
  onDetachComfortLetter: PropTypes.func.isRequired,
  /**action method to fetch comfort letter list with element count. It is used to refresh comfort letter toolkit */
  fetchComfortAnnotationListForRevisionWithoutLoading:
    PropTypes.func.isRequired,
  /** action used get element id's by comfort letter is attached to enable callout*/
  comfortLetterElementIdsUpdatedOrDeleted: PropTypes.func,
};
