import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  UNIT_OPTIONS_MAP,
  SCALING_OPTIONS_MAP,
} from 'constants/feature/element-attribute-constants';

export default class ElementDetails extends ApiModel({
  data: {
    amount: null,
    carryForwardStatus: null,
    clientNotesClosed: null,
    clientNotesClosedList: null,
    clientNotesOpen: null,
    clientNotesOpenList: null,
    columnNum: null,
    display: null,
    elementAnnotations: {
      flagFootedFormula: null,
      flagRecalcFormula: null,
      hasFootedFormula: null,
      hasRecalcFormula: null,
      flagCrossFootedFormula: null,
      hasCrossFootedFormula: null,
      irGroupName: null,
      tickmarks: null,
      workpaperReferenceNumberList: [],
      comfortLetterlabelList: [],
    },
    elementIds: null,
    entity: null,
    entityDetails: [],
    fiscalYear: null,
    flagInternalRef: null,
    flagUser: null,
    flagOverride: null,
    comfortAssign: null,
    formulaCell: null,
    id: null,
    included: null,
    internalNotesClosed: null,
    internalNotesClosedList: null,
    internalNotesOpen: null,
    internalNotesOpenList: null,
    internalVerified: null,
    label: null,
    lastModifiedDate: null,
    name: null,
    order: null,
    orderNum: null,
    periodToPeriod: null,
    period: null, //A form field used to store the fiscalYear and the periodType on the model.
    periodType: null,
    revisionId: null,
    rolloverStatus: null,
    rowNum: null,
    scaling: null,
    scalingId: null,
    section: null,
    sectionId: null,
    sectionname: null,
    signFlag: null,
    significantDigits: null,
    singleCelled: null,
    status: null,
    tableId: null,
    tag: null,
    tickmarkCell: null,
    tickmarks: null,
    tickmarkText: null,
    units: null,
    unitsId: null,
    wpReference: null,
    reviewed: null,
    polygon: null,
    polygonInPixel: null,
    creationType: null,
    marker: false,
    callout: false,
  },
}) {
  get display() {
    if (this.data) {
      return this.data.display;
    }
    return null;
  }

  get id() {
    if (this.data) {
      return this.data.id;
    }
    return null;
  }

  get label() {
    if (this.data) {
      return this.data.label;
    }
    return null;
  }

  get columnNum() {
    if (this.data) {
      return this.data.columnNum;
    }
    return null;
  }

  get sectionId() {
    if (this.data) {
      return this.data.sectionId;
    }
    return null;
  }

  get sectionname() {
    if (this.data) {
      return this.data.sectionname;
    }
    return null;
  }

  get status() {
    if (this.data) {
      return this.data.status;
    }
    return null;
  }

  get scaling() {
    if (this.data) {
      return this.data.scaling;
    }
    return null;
  }

  get units() {
    if (this.data) {
      return this.data.units;
    }
    return null;
  }

  get unitsId() {
    if (this.data) {
      return this.data.unitsId;
    }
    return null;
  }

  get amount() {
    if (this.data) {
      return this.data.amount;
    }
    return 0;
  }

  get significantDigits() {
    if (this.data) {
      return this.data.significantDigits;
    }
    return null;
  }

  get internalVerified() {
    if (this.data) {
      return this.data.internalVerified;
    }
    return null;
  }

  get reviewed() {
    if (this.data) {
      return this.data.reviewed;
    }
    return null;
  }

  get flagUser() {
    if (this.data) {
      return this.data.flagUser;
    }
    return null;
  }

  get flagOverride() {
    if (this.data) {
      return this.data.flagOverride;
    }
    return null;
  }

  get comfortAssign() {
    if (this.data) {
      return this.data.comfortAssign;
    }
    return null;
  }

  get entity() {
    if (this.data) {
      return this.data.entity;
    }
    return null;
  }

  get entityDetails() {
    if (this.data) {
      return this.data.entityDetails;
    }
    return [];
  }

  get period() {
    if (this.data) {
      return this.data.period;
    }
    return null;
  }

  get periodType() {
    if (this.data) {
      return this.data.periodType;
    }
    return null;
  }

  get fiscalYear() {
    if (this.data) {
      return this.data.fiscalYear;
    }
    return null;
  }

  get revisionId() {
    if (this.data) {
      return this.data.revisionId;
    }
    return null;
  }

  get creationType() {
    if (this.data) {
      return this.data.creationType;
    }
    return null;
  }

  get marker() {
    if (this.data) {
      return this.data.marker;
    }
    return false;
  }

  get callout() {
    if (this.data) {
      return this.data.callout;
    }
    return false;
  }

  get flagInternalRef() {
    if (this.data) {
      return this.data.flagInternalRef && !this.isSystemOverrideFlagged();
    }
    return null;
  }

  get internalReferenceGroupName() {
    if (this.data && this.data.elementAnnotations) {
      return this.data.elementAnnotations.irGroupName;
    }
    return null;
  }

  get workpaperReferenceNumberList() {
    if (this.hasWorkpapers()) {
      return this.data.elementAnnotations.workpaperReferenceNumberList;
    }
    return [];
  }

  get comfortLetterLabelList() {
    if (this.hasComfortLetters()) {
      return this.data.elementAnnotations.comfortLetterlabelList;
    }
    return [];
  }

  get tickmarks() {
    if (this.hasTickmarks()) {
      return this.data.elementAnnotations.tickmarks;
    }
    return null;
  }

  get tickmarkText() {
    if (this.hasTickmarkText()) {
      return this.data.tickmarkText;
    }
    return null;
  }

  get coordinatesInPixel() {
    if (this.data) {
      return this.data.polygonInPixel;
    }
    return null;
  }

  get coordinatesInInches() {
    if (this.data) {
      return this.data.polygon;
    }
    return null;
  }

  get isIncluded() {
    if (this.data) {
      return !!this.data.included;
    }
    return false;
  }

  get previewSequenceID() {
    if (this.data) {
      return this.data.previewSequenceID;
    }
    return null;
  }

  isInitialized() {
    return this.isLoaded && !isNullOrUndefined(this.data.id);
  }

  processResponse({ response }) {
    return {
      data: {
        ...response.data.result,
        polygonInPixel:
          typeof response.data.result.polygonInPixel === 'string'
            ? JSON.parse(response.data.result.polygonInPixel)
            : response.data.result.polygonInPixel,
        internalVerified:
          response.data.result.internalVerified === 1 ||
          response.data.result.internalVerified === true,
        reviewed:
          response.data.result.reviewed === 1 ||
          response.data.result.reviewed === true,
        flagUser:
          response.data.result.flagUser === 1 ||
          response.data.result.flagUser === true,
        flagOverride:
          response.data.result.flagOverride === 1 ||
          response.data.result.flagOverride === true,
        comfortAssign:
          response.data.result.comfortAssign === 1 ||
          response.data.result.comfortAssign === true,
        units: UNIT_OPTIONS_MAP.get(response.data.result.unitsId),
        scaling: SCALING_OPTIONS_MAP.get(response.data.result.scalingId),
        period: `${response.data.result.periodType} ${response.data.result.fiscalYear}`,
        flagInternalRef:
          response.data.result.flagInternalRef === 1 ||
          response.data.result.flagInternalRef === true,
        marker:
          response.data.result.elementAnnotations &&
          this.isElementHasAnnotation(response.data.result) &&
          response.data.result.marker
            ? response.data.result.marker
            : this.isElementHasAnnotation(response.data.result),
        callout:
          response.data.result.elementAnnotations &&
          !isNullOrUndefined(response.data.result.callout) &&
          response.data.result.callout,
      },
    };
  }

  isElementHasAnnotation(response) {
    const { included, elementAnnotations } = response;
    if (!included || !elementAnnotations) return false;

    const {
      hasFootedFormula,
      hasRecalcFormula,
      irGroupName,
      hasCrossFootedFormula,
      workpaperReferenceNumberList,
      comfortLetterlabelList,
      tickmarks,
    } = elementAnnotations || {};
    return !!(
      irGroupName ||
      (workpaperReferenceNumberList && workpaperReferenceNumberList.length) ||
      (tickmarks && tickmarks.length) ||
      (comfortLetterlabelList && comfortLetterlabelList.length) ||
      hasRecalcFormula ||
      hasFootedFormula ||
      hasCrossFootedFormula
    );
  }

  updateElementCallout(value) {
    return this.merge({
      data: {
        ...this.data,
        marker: value ? !value : this.isElementHasAnnotation(this.data),
        callout: value,
      },
    });
  }

  convertElementDetailsEntityToElementDetailsModel({
    elementDetails,
    sectionFullName,
  }) {
    return this.merge({
      data: {
        ...elementDetails,
        internalVerified: elementDetails.internalVerified === 1,
        reviewed: elementDetails.reviewed === 1,
        flagUser: elementDetails.flagUser === 1,
        flagOverride: elementDetails.flagOverride === 1,
        comfortAssign: elementDetails.comfortAssign === 1,
        units: UNIT_OPTIONS_MAP.get(elementDetails.unitsId),
        scaling: SCALING_OPTIONS_MAP.get(elementDetails.scalingId),
        period: `${elementDetails.periodType} ${elementDetails.fiscalYear}`,
        flagInternalRef: elementDetails.flagInternalRef === 1,
        sectionname: sectionFullName
          ? sectionFullName
          : elementDetails.sectionname,
      },
      isLoading: false,
      isLoaded: true,
      error: null,
    });
  }

  toEditApiFormat() {
    return {
      ...this.data,
      internalVerified: this.data.internalVerified === true ? 1 : 0,
      flagUser: this.data.flagUser === true ? 1 : 0,
      flagOverride: this.data.flagOverride === true ? 1 : 0,
      comfortAssign: this.data.comfortAssign === true ? 1 : 0,
      unitsId: this.data.units.id,
      units: this.data.units.value,
      scaling: this.data.scaling.value,
      scalingId: this.data.scaling.id,
      polygonInPixel: JSON.stringify(this.data.polygonInPixel),
      /**
       * Our dropdowns contain the full period, eg. FY 2015 in them, so they need to be split to be saved.
       */
      periodType: this.data.period
        ? this.data.period.split(' ')[0]
        : this.data.periodType,
      fiscalYear: this.data.period
        ? this.data.period.split(' ')[1]
        : this.data.fiscalYear,
      flagInternalRef: this.flagInternalRef ? 1 : 0,
      reviewed: this.data.reviewed === true ? 1 : 0,
    };
  }

  setVerify({ verified }) {
    return this.merge({
      data: {
        ...this.data,
        internalVerified: verified,
      },
    });
  }

  setReview({ reviewed }) {
    return this.merge({
      data: {
        ...this.data,
        reviewed: reviewed,
      },
    });
  }

  setUserFlag({ userFlag }) {
    return this.merge({
      data: {
        ...this.data,
        flagUser: userFlag,
      },
    });
  }

  setOverrideSystemFlag({ systemFlag }) {
    return this.merge({
      data: {
        ...this.data,
        flagOverride: systemFlag,
      },
    });
  }

  setComfortAssign({ comfortAssignVal }) {
    return this.merge({
      data: {
        ...this.data,
        comfortAssign: comfortAssignVal,
      },
    });
  }

  setEntity(entity) {
    return this.merge({ data: { ...this.data, entity } });
  }

  setScaling({ scaling }) {
    return this.merge({ data: { ...this.data, scaling } });
  }

  setPeriod(period) {
    return this.merge({ data: { ...this.data, period } });
  }

  setSectionName(sectionname) {
    return this.merge({ data: { ...this.data, sectionname } });
  }

  setUnit({ units }) {
    return this.merge({
      data: {
        ...this.data,
        units,
      },
    });
  }

  isVerified() {
    return this.data && this.data.internalVerified === true;
  }

  isReviewed() {
    return this.data && this.data.reviewed === true;
  }

  isUserFlagged() {
    return this.data && this.data.flagUser === true;
  }

  isSystemOverrideFlagged() {
    return this.data && this.data.flagOverride === true;
  }

  isComfortAssign() {
    return this.data && this.data.comfortAssign === true;
  }

  hasFootedFormula() {
    return (
      this.data &&
      this.data.elementAnnotations &&
      this.data.elementAnnotations.hasFootedFormula
    );
  }

  hasRecalcFormula() {
    return (
      this.data &&
      this.data.elementAnnotations &&
      this.data.elementAnnotations.hasRecalcFormula
    );
  }

  hasCrossFootedFormula() {
    return (
      this.data &&
      this.data.elementAnnotations &&
      this.data.elementAnnotations.hasCrossFootedFormula
    );
  }

  hasFormula() {
    return (
      this.hasRecalcFormula() ||
      this.hasFootedFormula() ||
      this.hasCrossFootedFormula()
    );
  }

  get elementHasFormula() {
    return (
      this.hasRecalcFormula() ||
      this.hasFootedFormula() ||
      this.hasCrossFootedFormula()
    );
  }

  hasFootedFormulaFlagged(ignoreSystemFlag) {
    return ignoreSystemFlag
      ? this.hasFootedFormula() &&
          this.data.elementAnnotations.flagFootedFormula
      : this.hasFootedFormula() &&
          this.data.elementAnnotations.flagFootedFormula &&
          !this.isSystemOverrideFlagged();
  }

  hasRecalcFormulaFlagged(ignoreSystemFlag) {
    return ignoreSystemFlag
      ? this.hasRecalcFormula() &&
          this.data.elementAnnotations.flagRecalcFormula
      : this.hasRecalcFormula() &&
          this.data.elementAnnotations.flagRecalcFormula &&
          !this.isSystemOverrideFlagged();
  }

  hasCrossFootedFormulaFlagged(ignoreSystemFlag) {
    return ignoreSystemFlag
      ? this.hasCrossFootedFormula() &&
          this.data.elementAnnotations.flagCrossFootedFormula
      : this.hasCrossFootedFormula() &&
          this.data.elementAnnotations.flagCrossFootedFormula &&
          !this.isSystemOverrideFlagged();
  }

  isFormulaFlagged(ignoreSystemFlag = false) {
    return (
      this.hasFootedFormulaFlagged(ignoreSystemFlag) ||
      this.hasRecalcFormulaFlagged(ignoreSystemFlag) ||
      this.hasCrossFootedFormulaFlagged(ignoreSystemFlag)
    );
  }

  isInternalRefFlagged(ignoreSystemFlag = false) {
    return ignoreSystemFlag
      ? this.data && this.data.flagInternalRef
      : this.data &&
          this.data.flagInternalRef &&
          !this.isSystemOverrideFlagged();
  }

  shouldFlagIR(selectedElement) {
    return (
      selectedElement.display === this.data.display &&
      selectedElement.scaling === this.data.scaling &&
      selectedElement.units === this.data.units &&
      selectedElement.period === this.data.period &&
      selectedElement.entity === this.data.entity
    );
  }

  isFlagged() {
    return (
      this.isUserFlagged() ||
      this.isFormulaFlagged() ||
      this.isInternalRefFlagged()
    );
  }

  isFlaggedAndReviewed() {
    return this.isReviewed() && this.isFlagged();
  }

  isFlaggedAndVerified() {
    return this.isVerified() && this.isFlagged();
  }

  hasInternalReferenceGroup() {
    return this.data && !isNullOrUndefined(this.internalReferenceGroupName);
  }

  hasWorkpapers() {
    return (
      this.data &&
      !isNullOrUndefined(this.data.elementAnnotations) &&
      !isNullOrUndefined(
        this.data.elementAnnotations.workpaperReferenceNumberList,
      ) &&
      this.data.elementAnnotations.workpaperReferenceNumberList.length > 0
    );
  }

  hasComfortLetters() {
    return (
      this.data &&
      !isNullOrUndefined(this.data.elementAnnotations) &&
      !isNullOrUndefined(this.data.elementAnnotations.comfortLetterlabelList) &&
      this.data.elementAnnotations.comfortLetterlabelList.length > 0
    );
  }

  hasTickmarks() {
    return (
      this.data &&
      !isNullOrUndefined(this.data.elementAnnotations) &&
      !isNullOrUndefined(this.data.elementAnnotations.tickmarks) &&
      this.data.elementAnnotations.tickmarks.length > 0
    );
  }

  hasTickmarkText() {
    return this.data && !isNullOrUndefined(this.data.tickmarkText);
  }

  isExcluded() {
    return this.data && this.data.included === 0;
  }

  highlightSearchTermInLabel({ term }) {
    if (isNullOrUndefined(this.label)) {
      return '';
    }
    if (isNullOrUndefined(term) || term === '') {
      return this.label;
    }
    return this.highlightTermInString(this.label, term);
  }

  setElementTickmarkAnnotations({ irGroupName, tickmark }) {
    const updatedElementAnnotations = { ...this.data.elementAnnotations };
    if (irGroupName !== undefined) {
      // irGroupName is cleared by coming in as Null
      // undefined means the argument was not set
      updatedElementAnnotations.irGroupName = irGroupName;
    }
    if (!isNullOrUndefined(tickmark)) {
      updatedElementAnnotations.tickmarks = tickmark;
    }
    return this.merge({
      data: {
        ...this.data,
        elementAnnotations: updatedElementAnnotations,
        marker: false,
        callout: true,
      },
      isLoaded: true,
      isLoading: false,
    });
  }

  highlightSearchTermInDisplayValue({ term }) {
    if (isNullOrUndefined(this.display)) {
      return '';
    }
    if (isNullOrUndefined(term) || term === '') {
      return this.display;
    }
    return this.highlightTermInString(this.display, term);
  }

  highlightTermInString(str, term) {
    const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return str.replace(
      new RegExp(escapedTerm, 'gi'),
      `<span class="highlight">${term}</span>`,
    );
  }

  updateElementDetailsFromSocket(payload) {
    const response = payload;
    const data = { ...this.data, ...response };
    return this.merge({
      data: {
        ...data,
        internalVerified: data.internalVerified === 1,
        reviewed: data.reviewed === 1,
        flagUser: data.flagUser === 1,
        flagOverride: data.flagOverride === 1,
        comfortAssign: data.comfortAssign === 1,
        units: UNIT_OPTIONS_MAP.get(data.unitsId),
        scaling: SCALING_OPTIONS_MAP.get(data.scalingId),
        period: `${data.periodType} ${data.fiscalYear}`,
        flagInternalRef: data.flagInternalRef === 1,
        marker: data.marker,
        callout: data.callout,
      },
    });
  }

  /**
   * Reducer function for setting an element as loaded from raw api data,
   * likely called from a place that receives an array of raw element data
   * like the "elements by section" request, or the "document changes since" requeset
   * @param {object} param.data this elements data to be merged
   */
  setLoadedFromElementData({ data }) {
    return this.merge({
      data: {
        ...data,
        polygonInPixel:
          typeof data.polygonInPixel === 'string'
            ? JSON.parse(data.polygonInPixel)
            : data.polygonInPixel,
        internalVerified: !!data.internalVerified,
        reviewed: !!data.reviewed,
        flagUser: !!data.flagUser,
        flagOverride: !!data.flagOverride,
        comfortAssign: !!data.comfortAssign,
        flagInternalRef: !!data.flagInternalRef,
        units: UNIT_OPTIONS_MAP.get(data.unitsId),
        scaling: SCALING_OPTIONS_MAP.get(data.scalingId),
        period: `${data.periodType} ${data.fiscalYear}`,
        marker:
          this.isElementHasAnnotation(data) && !isNullOrUndefined(data.marker)
            ? data.marker
            : this.isElementHasAnnotation(data),
        callout: !isNullOrUndefined(data.callout) && data.callout,
      },
      isLoaded: true,
      isLoading: false,
    });
  }

  get name() {
    if (this.data) {
      return this.data.name;
    }
    return null;
  }

  setElementAnnotations({ irGroupName, workpaperReferenceNumberList }) {
    const updatedElementAnnotations = { ...this.data.elementAnnotations };
    if (irGroupName !== undefined) {
      // irGroupName is cleared by coming in as Null
      // undefined means the argument was not set
      updatedElementAnnotations.irGroupName = irGroupName;
    }
    if (!isNullOrUndefined(workpaperReferenceNumberList)) {
      updatedElementAnnotations.workpaperReferenceNumberList =
        workpaperReferenceNumberList;
    }
    return this.merge({
      data: {
        ...this.data,
        elementAnnotations: updatedElementAnnotations,
        marker: false,
        callout: true,
      },
      isLoaded: true,
      isLoading: false,
    });
  }

  setElementComfortLetterAnnotations({ irGroupName, comfortLetterLabelList }) {
    const updatedElementAnnotations = { ...this.data.elementAnnotations };
    if (irGroupName !== undefined) {
      // irGroupName is cleared by coming in as Null
      // undefined means the argument was not set
      updatedElementAnnotations.irGroupName = irGroupName;
    }
    if (!isNullOrUndefined(comfortLetterLabelList)) {
      updatedElementAnnotations.comfortLetterlabelList = comfortLetterLabelList;
    }
    return this.merge({
      data: {
        ...this.data,
        elementAnnotations: updatedElementAnnotations,
        marker: false,
        callout: true,
      },
      isLoaded: true,
      isLoading: false,
    });
  }
}
