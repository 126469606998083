import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import ConditionalRender from 'components/util/conditional-render-component';
import TableOfContentsTreeView from 'components/feature/toolkit/statement-nav/table-of-contents-treeview';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import { SectionIdList } from 'models/api/section-id-list-model';
import SelectedStatement from 'models/api/selected-statement-model';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import OcrNavigation from 'components/feature/toolkit/statement-nav/ocr-navigation';
import OCRNavigationModel from 'models/api/ocr-navigation-api-model';

const SIDE_BY_SIDE_STATEMENT_NAV_FLYOUT_BLOCK =
  'side-by-side-statement-nav-flyout-block';

export const SideBySideStatementNavFlyout = ({
  show,
  sectionTreeList,
  onSectionClick,
  contentSectionMap,
  sectionIdList,
  selectedStatement,
  currentSectionIds,
  sectionReview,
  toggleStatementNavPanel,
  statementOCRNavigationData,
  navigateToSelectedPage,
  projectId,
  ocrCurrentViewPageNumber,
}) => {
  const flyoutRef = useRef(null);
  const { isOCR } = selectedStatement.isLoaded && selectedStatement;
  const sectionClick = ({ sectionId }) => {
    onSectionClick({
      sectionId: sectionId,
      color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
      sectionClick: true,
    });
  };

  const getNavigationData = () => {
    if (isOCR) {
      return (
        <ConditionalRender dependencies={[statementOCRNavigationData]}>
          <OcrNavigation
            sectionDetails={
              statementOCRNavigationData &&
              statementOCRNavigationData.navigationData
            }
            navigateToSelectedPage={navigateToSelectedPage}
            selectedProjectId={projectId}
            ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
          />
        </ConditionalRender>
      );
    } else {
      return (
        <ConditionalRender
          dependencies={[
            sectionTreeList,
            sectionIdList,
            contentSectionMap,
            sectionReview,
            selectedStatement,
          ]}
        >
          <TableOfContentsTreeView
            isSideBySideViewMode={true}
            sectionTreeList={sectionTreeList}
            onSectionClick={sectionClick}
            contentSectionMap={contentSectionMap}
            sectionIds={
              sectionIdList &&
              sectionIdList.data &&
              sectionIdList.data.sectionIds
            }
            selectedStatement={selectedStatement}
            currentSectionIds={currentSectionIds}
            sectionReview={sectionReview}
          />
        </ConditionalRender>
      );
    }
  };

  return (
    <>
      <Flyout
        className={SIDE_BY_SIDE_STATEMENT_NAV_FLYOUT_BLOCK}
        show={show}
        position={'left'}
        flyoutRef={flyoutRef}
      >
        <FlyoutHeader enableClose onClose={toggleStatementNavPanel}>
          <h3 className={`${SIDE_BY_SIDE_STATEMENT_NAV_FLYOUT_BLOCK}__title`}>
            <FormattedMessage id="statement-nav-panel.title" />
          </h3>
        </FlyoutHeader>
        <FlyoutPanelContent>{getNavigationData()}</FlyoutPanelContent>
      </Flyout>
    </>
  );
};

SideBySideStatementNavFlyout.propTypes = {
  /**boolean value that indicates if statement nav should be opened or hidden  */
  show: PropTypes.bool.isRequired,
  /** Tree list of section hirearchy */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList),
  /** Function fired on section click  */
  onSectionClick: PropTypes.func.isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap),
  /** Api model of all the section id's */
  sectionIdList: PropTypes.instanceOf(SectionIdList).isRequired,
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** contains list of section reviews */
  sectionReview: PropTypes.instanceOf(SectionReviewList),
  /** function to toggle state so as to show/hide statement naviagtion panel */
  toggleStatementNavPanel: PropTypes.func.isRequired,
  /** OCR statement Navigation data(thumbnails) */
  statementOCRNavigationData: PropTypes.instanceOf(OCRNavigationModel),
  /** function used to navigate to respective page in OCR statements */
  navigateToSelectedPage: PropTypes.func,
  /** selected project id */
  projectId: PropTypes.string,
  /** current page number in view in OCR statement */
  ocrCurrentViewPageNumber: PropTypes.number,
};

export default SideBySideStatementNavFlyout;
