import { createApiErrorDataAction } from 'store/actions/api-error-handler-actions';
import { getStore } from 'store/store';
import { getAPIClient } from 'api/api-client';
import React from 'react';
import { toast } from 'react-toastify';
import ApiErrorNotification, {
  API_ERROR_NOTIFICATION_ID,
} from 'components/util/api-error-notificiation-component';
import CloseToastButton from 'components/util/close-toast-button-component';

export const globalApiErrorHandler = (geoKey) => {
  const apiClient = getAPIClient(geoKey);
  /*
    Here we are intercepting any potential api errors that may occur and then
    dispatching the response from axios to redux.
    After conducting some research, it was deteremined that there is some limitation with
    accurate stack tracing when using axios interceptors.
    See: https://github.com/axios/axios/issues/2069
    As a result, we need to dispatch the stack trace from conditional-render-component.jsx
    to get an accurate stack trace of where the error occured.
  */
  apiClient.api.interceptors.response.use(undefined, (err) => {
    const { response } = err;

    /*
    In the case where there is an error with approving a wrap-up statement, we do not 
    want to capture the error. We already have a way to handle this error 
    */
    const isErrorWithWrapUpApproval = () => {
      const approveStatementSubString = '/approve';
      if (
        response.config &&
        response.config.url.includes(approveStatementSubString)
      ) {
        return true;
      }
      return false;
    };

    const isErrorForDuplicateComfortLetterValidation = (response) => {
      const descriptionValidation =
        'ValidationException: A comfort letter annotation with this description already exists';
      const labelValidation =
        'ValidationException: A comfort letter annotation with this label already exists.';
      return (
        response.data &&
        response.data.rootCause &&
        (response.data.rootCause.includes(descriptionValidation) ||
          response.data.rootCause.includes(labelValidation))
      );
    };
    /*
    In the case where there is an error with copying a statement, we do not
    want to intercept the error because we already have a way of handling it
    */
    const isErrorWithCopyStatement = () => {
      const copyStatementApiSubString = 'tieout/copy/statement';
      if (
        response.config &&
        response.config.url.includes(copyStatementApiSubString) &&
        response.config.url === 'post'
      ) {
        return true;
      }
      return false;
    };

    /*
    In the case where there is an 401 error with auth call, we do not
    want to intercept the error because we are already showing a message on screen
    */
    const isErrorWithUserAuthorization = () => {
      const authApiSubString = 'tieout/auth';
      if (
        response.config &&
        response.config.url.includes(authApiSubString) &&
        response.data.status === 401
      ) {
        return true;
      }
      return false;
    };

    const isErrorWithFetchingLanguageTranslations = () => {
      const languageTranslationApiSubString = 'translations/languages';
      if (
        response.config &&
        response.config.url.includes(languageTranslationApiSubString)
      ) {
        return true;
      }
      return false;
    };

    /*
    In a multi geo scenario, We make current user call to get project details and user roles in project
    for project assigned to user across different geo. If server from region A, for example, does not works,
    we dont want to show any error or block loading FE for all other regions.  
    */
    const isErrorWithFetchingCurrentUserDetailsFromGeo = () => {
      const currentUserApiSubString = '/tieout/currentuser';
      if (
        response.config &&
        response.config.url.includes(currentUserApiSubString)
      ) {
        return true;
      }
      return false;
    };

    /*
    In the case where there is an error with creating a WP that already exists, we 
    do not want to intercept the error because we already have a way of handling this case
    */
    const isErrorWithCreatingExistingWP = (response) => {
      const alreadyExistingWPRootCause =
        'ValidationException: This work paper reference number already exists.';
      const wpReftsApiSubString = '/wprefs';
      if (
        response &&
        response.data &&
        response.data.rootCause === alreadyExistingWPRootCause &&
        response.config.url.includes(wpReftsApiSubString)
      ) {
        return true;
      }
      return false;
    };

    const isErrorWithElementMapping = () => {
      const mapRequest = '/map';
      if (
        response.config &&
        response.config.url.includes(mapRequest) &&
        response.data.status === 500
      ) {
        return true;
      }
      return false;
    };

    // The first call to download report will be made such that it initiates report download from blob
    // storage using guid (once report is processed). Let's say blob service is not running or there is some
    // issue, in that case we want to fallback to old way of downloading report. Old way- Generate
    // report and send instantly as api response without storing it in blob.
    const isErrorForReportInFallbackMode = (response) => {
      const reportFallbackReponseMsg =
        'Blob is not available at the moment, falling back to old approach for report generation';
      let isReportFallbackMessage = false;
      try {
        isReportFallbackMessage =
          response &&
          response.data &&
          typeof response.data === 'string' &&
          response.data.includes(reportFallbackReponseMsg);
      } catch (e) {
        console.error(
          'error while evaluating, if failed response is for report in fallback mode:',
          e,
        );
      }
      return isReportFallbackMessage;
    };

    if (
      response &&
      !isErrorWithWrapUpApproval() &&
      !isErrorWithCopyStatement() &&
      !isErrorWithFetchingLanguageTranslations() &&
      !isErrorWithCreatingExistingWP(response) &&
      !isErrorForReportInFallbackMode(response) &&
      !isErrorForDuplicateComfortLetterValidation(response) &&
      !isErrorWithUserAuthorization() &&
      !isErrorWithElementMapping() &&
      !isErrorWithFetchingCurrentUserDetailsFromGeo()
    ) {
      const { locale } = getStore().getState().ui;
      getStore().dispatch(createApiErrorDataAction({ locale, response }));
      // render error notification
      if (window.TIEOUT.ENV.FEATURE.ENABLE_UNHANDLED_API_NOTIFCATION) {
        toast.error(<ApiErrorNotification />, {
          className: 'Toastify__toast Toastify__toast__no-hover',
          autoClose: false,
          closeOnClick: false,
          closeButton: <CloseToastButton />,
          draggable: false,
          toastId: API_ERROR_NOTIFICATION_ID,
        });
      }
    }
    return Promise.reject(err);
  });
  return apiClient;
};
