import { getStatementSummaryElementsRequest } from 'api/statement-summary-api';
import { createAction } from 'redux-actions';

export const statementSummaryElementsLoading = createAction(
  'STATEMENT_SUMMARY_ELEMENTS_LOADING',
);
export const statementSummaryElementsLoaded = createAction(
  'STATEMENT_SUMMARY_ELEMENTS_LOADED',
);
export const statementSummaryElementsError = createAction(
  'STATEMENT_SUMMARY_ELEMENTS_ERROR',
);

export const updateElementSummaryFromSocketPayloadAction = createAction(
  'UPDATE_ELEMENT_SUMMARY_FROM_SOCKET_PAYLOAD_ACTION',
);

export const fetchStatementSummaryElements =
  ({ revisionId, withoutLoading = false }) =>
  async (dispatch) => {
    !withoutLoading && (await dispatch(statementSummaryElementsLoading()));
    getStatementSummaryElementsRequest({ revisionId })
      .then((response) => {
        dispatch(statementSummaryElementsLoaded({ response }));
      })
      .catch((error) => {
        dispatch(statementSummaryElementsError({ error }));
      });
  };
