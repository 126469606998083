import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Permissions from 'permissions/permissions';
import { useLocation } from 'react-router-dom';
import {
  isElementReportPreviewPage,
  getElementHighlightStatus,
  getCssModifierForView,
  getCalloutVisibilityDetails,
} from 'utils/ocr-annotation-utils';
import { getElementBoundaryInfo } from 'utils/ocr-element-utils';
import {
  STATEMENT_ELEMENT_ID_PREFIX,
  STATEMENT_LEFT_ELEMENT_ID_PREFIX,
} from 'constants/feature/statement-content-constants';
import { setSelectedElementDetails } from 'store/actions/report-preview-page-actions';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { onElementClick } from 'components/feature/statement-content-panel/tieout-element-component';
import ElementFlagComponentOCR from './element-flag-component-ocr';
import Tooltip from 'components/common/tool-tip-component';
import { ElementSelectionLimit } from 'higher-order-components/tieout-element-hoc-component';
import {
  setSourceStatementElementMapAction,
  setTargetStatementElementMapAction,
} from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { getExcessiveElementSelectionNotification } from 'constants/feature/tieout-element-utils';
import { isNullOrUndefined } from 'utils/object-utils';
import { updateSideBySideElementCacheByCallouts } from 'store/actions/statement-content-annotation-creation-actions';
import useHoverEffect from 'components/hooks/useHoverEffects';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import { checkIfFeatureFlagEnabled } from 'store/actions/selected-statement-actions';
import { isModeForAddCursor } from 'utils/modes-utils';

const TIEOUT_ELEMENT = 'tieout-element-ocr';
const SIDE_BY_SIDE_STATEMENT_ELEMENT = 'side-by-side-selected-element';
const SIDE_BY_SIDE_ELEMENT_IDENTIFIER = 'side-by-side-element-identifier';
const TIEOUT_ELEMENT_LEFT = 'tieout-element-ocr-left';

const TieoutElementOCR = ({
  clearSelection,
  elementDetails,
  polygonInPixel,
  tooltip,
  showElementStatusAndFlag,
  annotationDisplayFilter,
  annotation,
  isLeftView,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const url = useLocation().pathname;
  const isSideBySideView = url.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE);
  const { ui, data } = useSelector((store) => store);
  const {
    sideBySideView: { sideBySideElementMap },
    reportPreviewPage,
    statementPage: {
      blacklineHighlightedElementId,
      selectedElementsMap,
      leftSelectedElementsMap,
      modes: { selectMode, blacklineViewMode, blacklineViewShowElementMode },
    },
  } = ui;
  const TIEOUT_ELEMENT_CLASS = isLeftView
    ? TIEOUT_ELEMENT_LEFT
    : TIEOUT_ELEMENT;

  const TIEOUT_ELEMENT_ID_PREFIX = isLeftView
    ? STATEMENT_LEFT_ELEMENT_ID_PREFIX
    : STATEMENT_ELEMENT_ID_PREFIX;

  const {
    selectedProject: { id },
    statementSummary: {
      statementSummaryElementsDisplay: { displayElementDetailsOnStatementPage },
    },
  } = data;

  //side-by-side view
  const hasPermission =
    Permissions.SideBySideStatementViewPermissions.canSelectElement(id);

  const { handleMouseEnter, handleMouseLeave } = useHoverEffect(
    TIEOUT_ELEMENT_CLASS,
    elementDetails.id,
  );

  const isPreviewPage = isElementReportPreviewPage(url);
  const isElementSelected = useMemo(() => {
    if (isPreviewPage) {
      return (
        reportPreviewPage.selectedElementId !== null &&
        elementDetails.id === reportPreviewPage.selectedElementId &&
        !reportPreviewPage.isClickedOnReport
      );
    } else if (blacklineViewMode) {
      const { addedOrChanged } = blacklineHighlightedElementId;
      if (addedOrChanged) {
        return (
          !isLeftView &&
          blacklineViewShowElementMode &&
          elementDetails.id === addedOrChanged
        );
      }

      return false;
    } else if (isLeftView) {
      return leftSelectedElementsMap.has(elementDetails.id);
    } else {
      return selectedElementsMap.has(elementDetails.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blacklineViewMode,
    blacklineViewShowElementMode,
    elementDetails.id,
    isLeftView,
    isPreviewPage,
    leftSelectedElementsMap,
    reportPreviewPage.isClickedOnReport,
    reportPreviewPage.selectedElementId,
    blacklineHighlightedElementId.deleted,
    blacklineHighlightedElementId.addedOrChanged,
    selectedElementsMap,
  ]);

  //side-by-side view
  const _shouldEnableTargetElementForSelection = (elementId) => {
    if (isSideBySideView && !isLeftView && sideBySideElementMap) {
      return (
        sideBySideElementMap.mapKeyOfFirstUnmappedTargetElement ||
        sideBySideElementMap.getTargetElementMapByElementId(elementId)
      );
    }
  };

  //side-by-side view
  const isSideBySideViewDisabled = (elementId) =>
    isSideBySideView
      ? isLeftView
        ? !hasPermission
        : !(hasPermission && _shouldEnableTargetElementForSelection(elementId))
      : false;

  const isElementHighlighted = useMemo(() => {
    return isPreviewPage
      ? reportPreviewPage.selectedElementId === elementDetails.id &&
          reportPreviewPage.isClickedOnReport
      : false;
  }, [
    elementDetails.id,
    isPreviewPage,
    reportPreviewPage.isClickedOnReport,
    reportPreviewPage.selectedElementId,
  ]);

  const elementHighlightState = () => {
    return isLeftView
      ? leftSelectedElementsMap.getElementHighlightColor(elementDetails.id)
      : selectedElementsMap.getElementHighlightColor(elementDetails.id);
  };

  const { polygonWidth, polygonHeight, polygonLeft, polygonTop } =
    getElementBoundaryInfo(polygonInPixel);

  const styles = {
    cursor:
      !isSideBySideView && isModeForAddCursor(selectMode) ? 'alias' : 'pointer',
    top: polygonTop,
    left: polygonLeft,
    width: polygonWidth,
    height: polygonHeight,
  };

  //side-by-side view
  const getCountForSelectedElementForSideBySideMapping = (elementId) => {
    if (isSideBySideView) {
      if (isLeftView) {
        return (
          sideBySideElementMap &&
          sideBySideElementMap.getCountForMappedSourceElement(elementId)
        );
      }
      return (
        sideBySideElementMap &&
        sideBySideElementMap.getCountForMappedTargetElement(elementId)
      );
    } else return null;
  };

  const _countForSelectedElementForSideBySideMapping =
    getCountForSelectedElementForSideBySideMapping(elementDetails.id);

  //side-by-side view
  const getCountForSelectedElement = () => {
    const elementCount =
      sideBySideElementMap && sideBySideElementMap.getCountOfElementsSelected();
    return elementCount + 1;
  };

  const onClick = (e, elementDetails) => {
    e.stopPropagation();
    clearSelection();
    if (isSideBySideView && hasPermission) {
      //side-by-side view
      if (getCountForSelectedElement() <= ElementSelectionLimit) {
        if (isLeftView)
          dispatch(setSourceStatementElementMapAction(elementDetails));
        else
          _shouldEnableTargetElementForSelection(elementDetails.id) &&
            dispatch(setTargetStatementElementMapAction(elementDetails));
      } else {
        getExcessiveElementSelectionNotification(ElementSelectionLimit);
      }
    } else {
      if (elementDetails) {
        const { id, sectionId } = elementDetails;
        if (isPreviewPage) {
          dispatch(
            setSelectedElementDetails({
              selectedElementId: id,
              isClickedOnReport: false,
            }),
          );
        } else if (!isPreviewPage && !blacklineViewMode) {
          dispatch(
            onElementClick({ elementId: id, sectionId, isDisabled: false }),
          );
        }
      }
    }
  };

  const isElementSelectedForSideBySideMap = (elementId) => {
    if (isSideBySideView) {
      if (isLeftView) {
        return (
          sideBySideElementMap &&
          sideBySideElementMap.getSourceElementMapByElementId(elementId)
        );
      }
      return (
        sideBySideElementMap &&
        sideBySideElementMap.getTargetElementMapByElementId(elementId)
      );
    } else return false;
  };

  const { isReviewed, isVerified, isUnverified, isSelected, isHighlighted } =
    getElementHighlightStatus({
      elementDetails,
      isElementSelected,
      isElementHighlighted,
      isPreviewPage,
      showElementStatusAndFlag,
      reportPreviewPage,
      blacklineViewMode,
    });

  //side-by-side view
  const numberOfSourceElementSelected =
    sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping;
  const numberOfTargetElementSelected =
    sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping;

  //side-by-side view
  const isCursorAlias =
    isSideBySideView && hasPermission // return true if user has permission to the respective statement for elements mapping in side-by-side view
      ? isLeftView // returns true if it is left side statement in side-by-side view
        ? true
        : numberOfSourceElementSelected > numberOfTargetElementSelected // returns true if number of selected source elements is greater than number of selected target elements to change the pointer(disabled to selection pointer) of right statement accordingly in side-by-side view
      : false;

  const modifier = getCssModifierForView(
    url,
    blacklineViewMode,
    blacklineViewShowElementMode,
  );

  const handleIndicatorOnHover = (elementId, showCallout) => {
    if (!annotationDisplayFilter.showAll) {
      const initialCalloutVisibilityDetails = {
        displayMarker: !annotation['marker'],
        displayCallout: !annotation['callout'],
        displayAnnotation: !annotation['display'],
      };

      const { displayCallout, displayAnnotation } = getCalloutVisibilityDetails(
        initialCalloutVisibilityDetails,
        annotation.annotationsConfig,
        annotationDisplayFilter,
      );

      if (!(displayCallout || displayAnnotation)) {
        showCallout = false;
      }
      dispatch(
        updateSideBySideElementCacheByCallouts({
          elementIds: [elementId],
          showCallout,
          isLeftSideView: isLeftView,
        }),
      );
    }
  };

  const isFeatureFlagEnabledForComfortAssigned = dispatch(
    checkIfFeatureFlagEnabled({
      featureName: FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE,
    }),
  );
  const showComfortAssignedStatus =
    showElementStatusAndFlag &&
    isFeatureFlagEnabledForComfortAssigned &&
    elementDetails &&
    elementDetails.isComfortAssign();

  const getElementCSSFromSelectedElementMap = () => {
    switch (true) {
      case elementHighlightState() === ELEMENT_HIGHLIGHT_STATES.POST_BATCH:
        return `${TIEOUT_ELEMENT}_added`;
      case elementHighlightState() ===
        ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_SUCCESS:
        return `${TIEOUT_ELEMENT}_side-by-side-map-success`;
      case elementHighlightState() ===
        ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_FAILURE:
        return `${TIEOUT_ELEMENT}_side-by-side-map-failure`;
      default:
        return '';
    }
  };

  const renderElements = () => (
    <>
      {!isNullOrUndefined(_countForSelectedElementForSideBySideMapping) ? (
        <div style={{ ...styles, position: 'absolute' }}>
          <div
            className={classNames({
              [`${SIDE_BY_SIDE_STATEMENT_ELEMENT}`]:
                isElementSelectedForSideBySideMap(elementDetails.id),
            })}
            onClick={(e) => onClick(e, elementDetails)}
            onMouseEnter={() => {
              handleIndicatorOnHover(elementDetails.id, true);
            }}
            onMouseLeave={() => {
              handleIndicatorOnHover(elementDetails.id, false);
            }}
          ></div>
          <div className={SIDE_BY_SIDE_ELEMENT_IDENTIFIER}>
            <span
              className={classNames([
                `${SIDE_BY_SIDE_ELEMENT_IDENTIFIER}__count`,
              ])}
            >
              {_countForSelectedElementForSideBySideMapping}
            </span>
          </div>
        </div>
      ) : (
        <div
          id={`${TIEOUT_ELEMENT_ID_PREFIX}${elementDetails.id}`}
          style={{ ...styles, position: 'absolute', zIndex: 48 }}
          className={classNames(
            `${TIEOUT_ELEMENT_CLASS}-${elementDetails.id}`,
            {
              [`${TIEOUT_ELEMENT_CLASS}`]: true,
              [`${TIEOUT_ELEMENT_CLASS}--disabled`]: isDisabled,
              [`${TIEOUT_ELEMENT_CLASS}__added`]:
                elementHighlightState() === ELEMENT_HIGHLIGHT_STATES.POST_BATCH,
              [`${TIEOUT_ELEMENT}--cursor-alias`]:
                isSideBySideView && isCursorAlias,
              [getElementCSSFromSelectedElementMap()]: true,
              [`${TIEOUT_ELEMENT_CLASS}__verified`]: isVerified,
              [`${TIEOUT_ELEMENT_CLASS}__reviewed`]: isReviewed,
              [`${TIEOUT_ELEMENT_CLASS}__unverified`]: isUnverified,
              [`${TIEOUT_ELEMENT_CLASS}--selected--${modifier}`]:
                !isSideBySideView && isSelected,
              [`${TIEOUT_ELEMENT_CLASS}--highlighted`]: isHighlighted,
              [`${TIEOUT_ELEMENT}--cursor-disabled`]: isSideBySideViewDisabled(
                elementDetails.id,
              ),
              [`${TIEOUT_ELEMENT_CLASS}--comfort-assigned`]:
                showComfortAssignedStatus,
            },
          )}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => onClick(e, elementDetails)}
          onMouseEnter={() => {
            handleIndicatorOnHover(elementDetails.id, true);
            handleMouseEnter();
          }}
          onMouseLeave={() => {
            if (
              !annotationDisplayFilter.expandAllElements ||
              !isElementSelected
            ) {
              handleIndicatorOnHover(elementDetails.id, false);
            }
            handleMouseLeave();
          }}
        ></div>
      )}
    </>
  );

  return (
    <>
      {polygonInPixel && polygonInPixel.length ? (
        <>
          {showElementStatusAndFlag && displayElementDetailsOnStatementPage && (
            <ElementFlagComponentOCR
              elementDetails={elementDetails}
              polygonTop={polygonTop}
              polygonLeft={polygonLeft}
            />
          )}
          {tooltip ? (
            <Tooltip {...tooltip} active={tooltip}>
              {renderElements()}
            </Tooltip>
          ) : (
            renderElements()
          )}
        </>
      ) : null}
    </>
  );
};
export default TieoutElementOCR;

TieoutElementOCR.propTypes = {
  /** func to clear text selection */
  clearSelection: PropTypes.func.isRequired,
  /** Element details object */
  elementDetails: PropTypes.object.isRequired,
  /** Array containing boundary information */
  polygonInPixel: PropTypes.array,
  /** Object containing config of tooltip */
  tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /** Boolean to decide the visibility of element status */
  showElementStatusAndFlag: PropTypes.bool,
  /** Object containing the filter config from summary bar */
  annotationDisplayFilter: PropTypes.object.isRequired,
  /** Object having properties fordisplaying marker or callout */
  annotation: PropTypes.object.isRequired,
  /** Booloean to check left or right view */
  isLeftView: PropTypes.bool,
};
