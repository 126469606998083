import { createAction } from 'redux-actions';
import { fetchAllSectionRenderingData } from './statement-content-actions';
import { isElementReportPreviewPage } from 'utils/ocr-annotation-utils';
import { fetchAllOCRSectionRenderingData } from './statement-content-actions-ocr';

export const showStatusAndFlagAction = createAction(
  'SHOW_STATUS_AND_FLAG_ACTION',
);

export const hideStatusAndFlagAction = createAction(
  'HIDE_STATUS_AND_FLAG_ACTION',
);

export const showElementIdAction = createAction(
  'SHOW_ELEMENT_ID_FOR_PREVIEW_PAGE_ACTION',
);

export const hideElementIdAction = createAction(
  'HIDE_ELEMENT_ID_FOR_PREVIEW_PAGE_ACTION',
);

export const enableRefreshIconAction = createAction(
  'ENABLE_REFRESH_ICON_FOR_PREVIEW_PAGE_ACTION',
);

export const disableRefreshIconAction = createAction(
  'DISABLE_REFRESH_ICON_FOR_PREVIEW_PAGE_ACTION',
);

export const setFirstElementVisibleInViewPort = createAction(
  'SET_FIRST_ELEMENT_VISIBLE_IN_VIEW_PORT',
);

export const resetFirstElementVisibleInViewPort = createAction(
  'RESET_FIRST_ELEMENT_VISIBLE_IN_VIEW_PORT',
);

export const toggleSyncScrolling = createAction('TOGGLE_SYNC_SCROLLING');
export const setSyncScrolling = createAction('SET_SYNC_SCROLLING');
export const resetSyncScrolling = createAction('RESET_SYNC_SCROLLING');

export const fetchSectionContent = (sectionsInView) => (dispatch, getState) => {
  const isReportPreviewPage = isElementReportPreviewPage(
    window.location.pathname,
  );
  Array.isArray(sectionsInView) &&
    sectionsInView.forEach((sectionId) =>
      dispatch(
        fetchAllSectionRenderingData({ sectionId, isReportPreviewPage }),
      ),
    );
};

export const fetchOCRSectionContent =
  (sectionMetaDataList) => (dispatch, getState) => {
    Array.isArray(sectionMetaDataList) &&
      sectionMetaDataList.forEach((sectionMetaData) =>
        dispatch(fetchAllOCRSectionRenderingData({ sectionMetaData })),
      );
  };

export const setFirstElementVisibleInViewPortAction =
  (isOCRStatement) => (dispatch, getState) => {
    const allElementIds = [];
    const leftView = document.getElementById(
      'report-preview-page-left-panel-id__left-preview-html',
    );
    const ocrLeftView = document.getElementById(
      'statement-content-panel-ocr__document-id',
    );
    const { sectionsInView } = getState().ui && getState().ui.statementPage;
    const view = isOCRStatement ? ocrLeftView : leftView;
    const leftViewBoundary = view && view.getBoundingClientRect();
    const sectionName = isOCRStatement
      ? 'statement-content-panel-page-'
      : 'REACT_SECTION_';
    for (let i of sectionsInView.sectionIds) {
      const id = `${sectionName}${i}`;
      const section = document.getElementById(id);
      if (section) {
        const elements = section.querySelectorAll('[id^=CFTO_ELEMENT_]');
        allElementIds.push(...elements);
      }
    }
    const viewPortElement = allElementIds.filter((element) => {
      if (element) {
        const boundary = element.getBoundingClientRect();
        return (
          boundary.top >= leftViewBoundary.top &&
          boundary.bottom <= leftViewBoundary.bottom
        );
      }
    });

    if (viewPortElement.length) {
      if (isOCRStatement) {
        const sortedElement = viewPortElement.sort((a, b) => {
          const posA = a.getBoundingClientRect().top;
          const posB = b.getBoundingClientRect().top;
          return posA - posB;
        });
        const firstViewPortElementId = parseInt(
          sortedElement[0].id.replace(/\D/g, ''),
        );
        dispatch(setFirstElementVisibleInViewPort(firstViewPortElementId));
      } else {
        const firstViewPortElementId = viewPortElement[0].dataset.elementId;
        dispatch(setFirstElementVisibleInViewPort(firstViewPortElementId));
      }
    }
  };

export const onToggleSyncScroll = () => (dispatch, getState) => {
  const { isSyncScrollingEnabled } = getState().ui.previewPage;
  if (isSyncScrollingEnabled) {
    dispatch(resetFirstElementVisibleInViewPort());
  }
  dispatch(toggleSyncScrolling());
};
