import React from 'react';
import {
  getNoteStatusIcon,
  getNoteStatusColor,
} from 'constants/feature/notes-panel-constants';
import useOutsideClick from 'components/hooks/useOutsideClick';
import useHoverEffect from 'components/hooks/useHoverEffects';
import classnames from 'classnames';
import { STATEMENT_PSEUDO_ELEMENT_ID_PREFIX } from 'constants/feature/statement-content-constants';

const MAX_POLYGON_WIDTH = 5;
const PADDING = 6;
const FIXED_SIZE = 20;
const BORDER_THICKNESS = '1px';
const newColor = 'rgba(216, 221, 64, 0.7)';
const NoteSelectedColor = 'rgba(0, 0, 0, 0.1)';
const NOTE_ANCHOR_BLOCK = 'note-anchor';
const NOTE_DETAILS_OCR = 'note-details-ocr';

const highlightNotes = ({ isNoteNew, isNotesPanelOpen, isNoteSelected }) => {
  if (isNotesPanelOpen && isNoteSelected && !isNoteNew) {
    return NoteSelectedColor;
  }
  if (isNoteSelected && isNoteNew) {
    return newColor;
  }
  return 'unset';
};

const NoteDetailsOcr = ({
  polygonInPixel,
  note,
  showIcon,
  isNoteSelected,
  onNoteClick,
  selectMode,
  pseudoElementId,
  handleNoteClick,
  isNoteNew,
  panelSelected,
  isNotesPanelOpen,
  changeIsNewPropertyFromSelectedNote,
  isCursorAlias,
  shouldNotesDisabled,
}) => {
  const polygonWidth = polygonInPixel[2] - polygonInPixel[0];
  const polygonHeight = polygonInPixel[7] - polygonInPixel[1];
  const updatedPolygonWidth =
    polygonWidth > MAX_POLYGON_WIDTH ? polygonWidth : polygonWidth + PADDING;
  const updatedPolygonHeight =
    polygonHeight > MAX_POLYGON_WIDTH ? polygonHeight : polygonHeight + PADDING;
  const polygonTop =
    polygonHeight > MAX_POLYGON_WIDTH
      ? polygonInPixel[1]
      : polygonInPixel[1] - PADDING / 2;
  const polygonLeft =
    polygonWidth > MAX_POLYGON_WIDTH
      ? polygonInPixel[0]
      : polygonInPixel[0] - PADDING / 2;

  const [noteElement] = useOutsideClick(
    () => isNoteNew && changeIsNewPropertyFromSelectedNote(false),
  );

  let remainingSpaceToAddOrSubtract = updatedPolygonHeight - 20;

  const styles = {
    top: polygonTop,
    left: polygonLeft,
    width: updatedPolygonWidth,
    height: updatedPolygonHeight,
    border: `${BORDER_THICKNESS} solid ${getNoteStatusColor({ note })}`,
    backgroundColor: highlightNotes({
      isNoteNew,
      panelSelected,
      isNotesPanelOpen,
      isNoteSelected,
    }),
  };

  const { handleMouseEnter, handleMouseLeave } = useHoverEffect(
    `${NOTE_DETAILS_OCR}`,
    note.noteId,
  );

  const iconProps = {
    onMouseDown: (e) => {
      e.stopPropagation();
    },
    onClick: (event) => {
      handleNoteClick(event, selectMode, note, pseudoElementId, onNoteClick);
    },
    onMouseEnter: () => {
      handleMouseEnter();
    },
    onMouseLeave: () => {
      handleMouseLeave();
    },
    height: FIXED_SIZE,
    width: FIXED_SIZE,
  };

  const notesCursor = {
    className: classnames(
      isCursorAlias && `${NOTE_ANCHOR_BLOCK}--cursor-alias`,
      shouldNotesDisabled && `${NOTE_ANCHOR_BLOCK}--disabled`,
    ),
  };

  return (
    <>
      <div
        style={styles}
        ref={noteElement}
        className={classnames(
          `${NOTE_DETAILS_OCR}-${note.noteId}`,
          `${NOTE_DETAILS_OCR}--container`,
        )}
        id={`${STATEMENT_PSEUDO_ELEMENT_ID_PREFIX}${note.pseudoElementId}_0`}
      ></div>
      {showIcon && (
        <div
          style={{
            top: polygonTop + remainingSpaceToAddOrSubtract,
            left: polygonInPixel[2],
            backgroundColor: `${getNoteStatusColor({ note })}`,
          }}
          className={classnames(`${NOTE_DETAILS_OCR}--note-icon`)}
        >
          {getNoteStatusIcon({ note, iconProps, notesCursor })}
        </div>
      )}
    </>
  );
};

export default NoteDetailsOcr;
