import React from 'react';
import classNames from 'classnames';
import {
  CONTENT_SEARCH_BLOCK,
  CONTENT_SEARCH_ID_BLOCK,
} from '../toolkit/statement-nav/statement-nav-content-search-results-component';

const MAX_POLYGON_WIDTH = 5;
const PADDING = 6;

const TieoutContentSearchOCR = ({
  contentDetails,
  polygonInPixel,
  contentSearchResult,
  index,
}) => {
  const polygonWidth = polygonInPixel[2] - polygonInPixel[0];
  const polygonHeight = polygonInPixel[7] - polygonInPixel[1];

  const updatedPolygonWidth =
    polygonWidth > MAX_POLYGON_WIDTH ? polygonWidth : polygonWidth + PADDING;
  const updatedPolygonHeight =
    polygonHeight > MAX_POLYGON_WIDTH ? polygonHeight : polygonHeight + PADDING;
  const polygonTop =
    polygonHeight > MAX_POLYGON_WIDTH
      ? polygonInPixel[1]
      : polygonInPixel[1] - PADDING / 2;
  const polygonLeft =
    polygonWidth > MAX_POLYGON_WIDTH
      ? polygonInPixel[0]
      : polygonInPixel[0] - PADDING / 2;

  const styles = {
    position: 'absolute',
    zIndex: 50,
    top: polygonTop,
    left: polygonLeft,
    width: updatedPolygonWidth,
    height: updatedPolygonHeight,
  };
  const CONTENT_SEARCH_RESULT_BLOCK = `${CONTENT_SEARCH_BLOCK}__entry-ocr`;
  const renderElements = () => (
    <div
      id={`${CONTENT_SEARCH_ID_BLOCK}-${contentDetails.selector}`}
      key={contentDetails.id}
      style={styles}
      className={classNames(`${CONTENT_SEARCH_RESULT_BLOCK}`, {
        [`${CONTENT_SEARCH_RESULT_BLOCK}--active`]:
          contentSearchResult.selectedIndex === index,
      })}
      onMouseDown={(e) => e.stopPropagation()}
    ></div>
  );

  return (
    <>
      {polygonInPixel && polygonInPixel.length ? <>{renderElements()}</> : null}
    </>
  );
};
export default TieoutContentSearchOCR;
