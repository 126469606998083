import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Modal from 'components/common/modal-component';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import {
  goodReasonCheckboxOptions,
  badReasonCheckboxOptions,
  goodResponseDefaultState,
  badResponseDefaultState,
  THUMBS_UP,
  THUMBS_DOWN,
} from './toolkit-chatbot-utils';
import TextArea from 'components/common/text-area-component';
import { submitChatbotResponseFeedback } from 'api/chatbot-api';
import { useSelector } from 'react-redux';
import Checkbox from 'components/common/checkbox-component';

export const RESPONSE_FEEDBACK_MODAL_ID = 'chatbot-response-feedback-block-id';
export const RESPONSE_FEEDBACK_MODAL = 'chatbot-response-feedback-block';
const MAX_LENGTH = 200;

const ChatbotPanelResponseFeedback = ({
  setShowModal,
  isGoodResponse,
  message,
  setMessages,
  containerCode,
  geoCode,
}) => {
  const defaultState = isGoodResponse
    ? goodResponseDefaultState
    : badResponseDefaultState;
  const [textAreaInput, setTextAreaInput] = useState('');
  const [reasons, setReasons] = useState(defaultState);
  const { revision } = useSelector((store) => store.data);

  const onCancel = () => {
    setShowModal(false);
  };

  const onSave = () => {
    const feedback_reason = Object.keys(reasons).reduce((acc, key) => {
      acc[key] = reasons[key] === false ? 'NA' : reasons[key];
      return acc;
    }, {});

    const requestParams = {
      data: {
        _type: 'feedback',
        _transaction_date: Date.now(),
        release_id: '1.0.0',
        file_key: `${parseInt(revision.clientId)}_${revision.statementId}_${
          revision.id
        }`,
        feedback_type: isGoodResponse ? THUMBS_UP : THUMBS_DOWN,
        feedback_select: [feedback_reason],
        feedback_comment: textAreaInput.trim(),
        human_message: message.human_message,
        gpt_response: {
          actual_gpt_response: message.response,
          document_source: message.documentSource,
        },
        containerCode,
        geoCode,
      },
    };
    setShowModal(false);
    submitChatbotResponseFeedback(requestParams).then((response) => {
      if (response.status === 'success') {
        setMessages((previousMessages) => {
          return previousMessages.map((messageObj) => {
            if (messageObj.id === message.id) {
              return {
                ...messageObj,
                isFeedbackTaken: true,
                isGoodFeedbackTaken: isGoodResponse,
                isBadFeedbackTaken: !isGoodResponse,
              };
            } else {
              return messageObj;
            }
          });
        });
      }
    }).catch(err => {
      console.log(err);
    })
  };

  const onTextAreaChange = (val) => {
    setTextAreaInput(val);
  };

  const onOptionsChange = (value) => {
    setReasons((prevState) => {
      return {
        ...prevState,
        [value]: !prevState[value],
      };
    });
  };

  const isAnyReasonSelected = Object.values(reasons).some((item) => item);
  const isSubmitBtnDisabled = !(textAreaInput.trim() || isAnyReasonSelected);
  const reasonList = isGoodResponse
    ? goodReasonCheckboxOptions
    : badReasonCheckboxOptions;
  return (
    <Modal
      id={`${RESPONSE_FEEDBACK_MODAL_ID}__modal`}
      title={
        isGoodResponse
          ? 'chatbot.feedback.header.good.text'
          : 'chatbot.feedback.header.bad.text'
      }
      onClose={() => setShowModal(false)}
      primaryModalButton={
        new ModalButton({
          text: 'common.submit',
          onClick: onSave,
          disabled: isSubmitBtnDisabled,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onCancel,
        })
      }
    >
      <div className={`${RESPONSE_FEEDBACK_MODAL}`}>
        <Banner
          id={`${RESPONSE_FEEDBACK_MODAL}-feedback-banner`}
          type={BANNER_TYPES.WARNING}
          bannerCopy={{
            id: 'chatbot.response.feedback.modal.banner.message',
            values: {
              b: (...chunks) => <strong>{chunks}</strong>,
              br: <br key={`${RESPONSE_FEEDBACK_MODAL}-brTag`} />,
            },
          }}
          isInternationalized={true}
        />
        <div className={`${RESPONSE_FEEDBACK_MODAL}__reason-list`}>
          <div className={`${RESPONSE_FEEDBACK_MODAL}__reason-label`}>
            <FormattedMessage
              id={
                isGoodResponse
                  ? 'chatbot.response.feedback.good.reason.label'
                  : 'chatbot.response.feedback.bad.reason.label'
              }
            />
          </div>
          {reasonList.map((option) => {
            return (
              <Checkbox
                id={option.id}
                className={`${RESPONSE_FEEDBACK_MODAL}__${option.value}`}
                checked={reasons[option.value]}
                value={option.value}
                onChange={onOptionsChange}
                label={option.label}
                width={'100%'}
                isValid={true}
              />
            );
          })}
        </div>
        <div className={`${RESPONSE_FEEDBACK_MODAL}__additional-comment`}>
          <TextArea
            label="chatbot.response.feedback.additional.comment.label"
            isValid={true}
            placeholder={
              isGoodResponse
                ? 'chatbot.response.good.feedback.additional.comment.placeholder'
                : 'chatbot.response.bad.feedback.additional.comment.placeholder'
            }
            value={textAreaInput}
            width={'100%'}
            readonly={false}
            onChange={onTextAreaChange}
            id={`${RESPONSE_FEEDBACK_MODAL}-additional-comment`}
            rows={3}
            maxLength={MAX_LENGTH}
          />
          <div
            className={`${RESPONSE_FEEDBACK_MODAL}__text-count`}
          >{`${textAreaInput.length} / ${MAX_LENGTH}`}</div>
        </div>
      </div>
    </Modal>
  );
};

ChatbotPanelResponseFeedback.propTypes = {
  /** function to toggle state in order to show/hide support ID modal */
  toggleSupportIDModal: PropTypes.func.isRequired,
  /**Function fired when input text from modal is saved. */
  onSave: PropTypes.func.isRequired,
  /**Function fired when cancel button on modal is clicked */
  onCancel: PropTypes.func.isRequired,
};

export default ChatbotPanelResponseFeedback;
