import { handleActions } from 'redux-actions';
import { setOcrCurrentViewPageNumber } from 'store/actions/ocr-current-view-page-number-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [setOcrCurrentViewPageNumber]: (store, { payload }) => {
      return payload;
    },
    [clearStatementPageStoreAction]: () => {
      return 1;
    },
  },
  1,
);
