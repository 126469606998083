import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const addNavigationRequest = ({ revisionId, elementDetails }) =>
  withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/addnavigation`,
      elementDetails,
      {
        params: {
          since: Date.now().toString(),
        },
      },
    )
    .then((response) => {
      return response;
    });

export const getStatementNavigationData = (projectId, revisionId) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/clients/${parseInt(
        projectId,
      )}/revisions/${revisionId}/images/thumbnails`,
    )
    .then((response) => {
      return response;
    });

export const getThumbnailBySectionId = ({ projectId, section }) => {
  return withDefaultContextAsSelectedProject()
    .get(
      `/tieout/clients/${parseInt(projectId)}/revisions/${
        section.revisionId
      }/guids/${section.storageGuid}/keyVersions/${
        section.keyVersion
      }/contentTypes/png`,
      {
        responseType: 'arraybuffer',
      },
    )
    .then((response) => {
      return response.data;
    });
};
