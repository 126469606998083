import BaseModel from 'models/base-model';

export default class StatementSummaryElementsDisplay extends BaseModel({
  displayElementDetailsOnStatementPage: true,
}) {
  setDisplayElementDetailsOnStatementPage(displayOption) {
    return this.merge({
      displayElementDetailsOnStatementPage: displayOption,
    });
  }
}
