import { handleActions } from 'redux-actions';
import ElementCache from 'models/data/element-cache-model';
import {
  leftSectionElementsLoading,
  leftSectionElementsLoaded,
  leftSectionElementsError,
  clearStatementPageStoreAction,
  clearLeftStatementContent,
  clearLeftStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import { clearLeftSectionContentWithIdsFromCache } from 'store/actions/left-statement-section-content-actions';
import { updateLeftElementCacheByCalloutAction } from 'store/actions/element-cache-actions';

export default handleActions(
  {
    [leftSectionElementsLoading]: (elementCache, { payload }) => {
      return elementCache.setSectionLoading(payload);
    },
    [leftSectionElementsLoaded]: (elementCache, { payload }) => {
      return elementCache.setSectionLoaded(payload);
    },
    [leftSectionElementsError]: (elementCache, { payload }) => {
      return elementCache.setSectionError(payload);
    },
    [updateLeftElementCacheByCalloutAction]: (elementCache, { payload }) => {
      return elementCache.updateElementCallout({ payload });
    },
    [clearLeftSectionContentWithIdsFromCache]: (elementCache, { payload }) => {
      return elementCache.clearSections(payload);
    },
    [clearLeftStatementContent]: (elementCache, { payload }) => {
      return new ElementCache();
    },
    /** External listeners */
    [clearStatementPageStoreAction]: (elementCache, { payload }) => {
      return new ElementCache();
    },
    [clearLeftStatementPageStoreAction]: (elementCache, { payload }) => {
      return new ElementCache();
    },
    [updateLeftElementCacheByCalloutAction]: (elementCache, { payload }) => {
      return elementCache.updateElementCallout({ payload });
    },
  },
  new ElementCache(),
);
