import { getStatementNavigationData } from 'api/navigation-api';
import { createAction } from 'redux-actions';

export const navigationDataLoading = createAction(
  'OCR_NAVIGATION_DATA_LOADING',
);
export const navigationDataLoaded = createAction('OCR_NAVIGATION_DATA_LOADED');
export const navigationDataError = createAction('OCR_NAVIGATION_DATA_ERROR');

export const leftNavigationDataLoading = createAction(
  'LEFT_OCR_NAVIGATION_DATA_LOADING',
);
export const leftNavigationDataLoaded = createAction(
  'LEFT_OCR_NAVIGATION_DATA_LOADED',
);
export const leftNavigationDataError = createAction(
  'LEFT_OCR_NAVIGATION_DATA_ERROR',
);

export const getSelectedStatementOCRNavigationData =
  (projectId, revisionId, isLeftview = false) =>
  async (dispatch, getState) => {
    isLeftview
      ? dispatch(leftNavigationDataLoading())
      : dispatch(navigationDataLoading());
    try {
      const response = await getStatementNavigationData(projectId, revisionId);
      isLeftview
        ? dispatch(leftNavigationDataLoaded(response))
        : dispatch(navigationDataLoaded(response));
    } catch (e) {
      isLeftview
        ? dispatch(leftNavigationDataError(e))
        : dispatch(navigationDataError(e));
    }
  };
