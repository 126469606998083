import { createAction } from 'redux-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  postMaintenanceBannerRequest,
  getAllMaintenancePageBannerData,
} from '../../api/admin-module-api';
import {
  getContainerListWhereUserIsCountryManager,
  getContainerListWhereUserIsCountryManagerWithContainerCodes,
} from 'api/project-list-api';
import { ADMIN_MODULE_TABS } from 'constants/feature/admin-module-constants';

export const setCurrentAdminModuleTab = createAction(
  'SET_CURRENT_ADMIN_MODULE_TAB',
);

export const setMaintenacePageLoading = createAction(
  'SET_MAINTENANCE_PAGE_LOADING_ACTION',
);

export const setMaintenacePageLoaded = createAction(
  'SET_MAINTENANCE_PAGE_LOADED_ACTION',
);

export const setMaintenancePageError = createAction(
  'SET_MAINTENANCE_PAGE_ERROR_ACTION',
);

export const countryManagercontainerListLoading = createAction(
  'COUNTRY_MANAGER_CONTAINER_LIST_LOADING_ACTION',
);

export const countryManagercontainerListLoaded = createAction(
  'COUNTRY_MANAGER_CONTAINER_LIST_LOADED_ACTION',
);

export const countryManagercontainerListError = createAction(
  'COUNTRY_MANAGER_CONTAINER_LIST_ERROR_ACTION',
);

export const monitoringListLoading = createAction(
  'MONITORING_COUNTRY_LIST_LOADING_ACTION',
);

export const monitoringListLoaded = createAction(
  'MONITORING_COUNTRY_LIST_LOADED_ACTION',
);

export const monitoringListError = createAction(
  'MONITORING_COUNTRY_LIST_ERROR_ACTION',
);

export const clearMonitoringListLoaded = createAction(
  'CLEAR_MONITORING_COUNTRY_LIST_DATA_ACTION',
);

export const setCountryOption = createAction(
  'SET_COUNTRY_LIST_SELECTED_OPTIONS',
);
export const clearAdminPageDataAction = createAction(
  'CLEAR_ADMIN_PAGE_DATA_ACTION',
);

export const requestCurrentAdminModuleTab =
  ({ tab }) =>
  async (dispatch, getState) => {
    if (!isNullOrUndefined(tab)) {
      await dispatch(setCurrentAdminModuleTab(tab));
      if (tab === ADMIN_MODULE_TABS.MAINTENANCEBANNER) {
        await dispatch(getAllMaintenanceBannerData());
      }
    }
  };

export const getAllMaintenanceBannerData = () => async (dispatch, getState) => {
  dispatch(setMaintenacePageLoading());
  try {
    let bannerList = await getAllMaintenancePageBannerData();
    dispatch(setMaintenacePageLoaded(bannerList));
  } catch (e) {
    //Loaded with error;
    dispatch(setMaintenancePageError(e));
  }
};
export const maintenanceBannerSaveAction =
  (isActive, id, text, startDate, endDate) => async (dispatch, getState) => {
    dispatch(setMaintenacePageLoading());
    await postMaintenanceBannerRequest(isActive, id, text, startDate, endDate);
    await dispatch(getAllMaintenanceBannerData());
  };

//THis country list we are using on admin module's Manage Users page
export const getContainerListWhereUserIsCountryManagerAction =
  () => async (dispatch, getState) => {
    dispatch(countryManagercontainerListLoading());
    try {
      const data = await getContainerListWhereUserIsCountryManager();
      await dispatch(countryManagercontainerListLoaded(data));
    } catch (error) {
      dispatch(countryManagercontainerListError(error));
    }
  };

export const getContainerMonitoringDropDownList =
  (countryCodes) => async (dispatch, getState) => {
    dispatch(monitoringListLoading());
    try {
      const monitoringList =
        await getContainerListWhereUserIsCountryManagerWithContainerCodes(
          countryCodes,
        );
      await dispatch(monitoringListLoaded(monitoringList));
    } catch (error) {
      dispatch(monitoringListError(error));
    }
  };

export const setMonitoringDropDownSelectedOptions =
  (option) => async (dispatch, getState) => {
    dispatch(setCountryOption(option));
  };
