import React, { Component } from 'react';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';

const DELETE_SECTION_ID_BLOCK = 'delete-section';

class DeleteConfirmationModal extends Component {
  render() {
    const { onClose, onDelete } = this.props;

    return (
      <Modal
        id={DELETE_SECTION_ID_BLOCK}
        title={'delete-section-modal.section.delete.text'}
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'common.yes',
            onClick: () => onDelete(),
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.no',
            onClick: onClose,
          })
        }
      >
        <div className={`${DELETE_SECTION_ID_BLOCK}__delete-bookmark-message`}>
          <FormattedMessage id="edit-section-modal.confirm.delete.has.child.title" />
        </div>
        <Banner
          id={`${DELETE_SECTION_ID_BLOCK}-confirm-modal-warning-banner`}
          type={BANNER_TYPES.WARNING}
          bannerCopy={'delete-section-modal.section.delete.warning-text'}
          isInternationalized={true}
          isInteractive={false}
        />
      </Modal>
    );
  }
}

DeleteConfirmationModal.propTypes = {
  /** Modal panel onClose fucntion  */
  onClose: PropTypes.func.isRequired,
  /** Confirm deletion fucntion  */
  onDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmationModal;
