import React from 'react';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import myGif from 'icons/Guidance_popup.gif';
import shortid from 'shortid';

export const STATEMENT_RECENT_UPDATES_ID_BLOCK = 'statement-recent-update-id';
export const STATEMENT_RECENT_UPDATES_BLOCK = 'statement-recent-updates';

const RecentStatementUpdatesModal = ({ onClose, onSave }) => {
  return (
    <Modal
      id={STATEMENT_RECENT_UPDATES_ID_BLOCK}
      onClose={onClose}
      title={'statement-recent-updates-information-modal.title'}
      className={`${STATEMENT_RECENT_UPDATES_BLOCK}__modal`}
      primaryModalButton={
        new ModalButton({
          text: 'recent-updates-information-modal.button',
          onClick: () => onSave(),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.close',
          onClick: () => onClose(),
        })
      }
    >
      <div className={`${STATEMENT_RECENT_UPDATES_BLOCK}__container`}>
        <div
          className={`${STATEMENT_RECENT_UPDATES_BLOCK}__recent-updates-message`}
        >
          <FormattedMessage
            id={'statement-recent-updates-information-modal.message'}
            values={{
              ol: (...chunks) => (
                <ol
                  key={`${STATEMENT_RECENT_UPDATES_ID_BLOCK}-olTag-${shortid.generate()}`}
                  className={`${STATEMENT_RECENT_UPDATES_BLOCK}`}
                >
                  {chunks}
                </ol>
              ),
              li: (...chunks) => (
                <li
                  key={`${STATEMENT_RECENT_UPDATES_ID_BLOCK}-liTag-${shortid.generate()}`}
                >
                  {chunks}
                </li>
              ),
              b: (...chunks) => (
                <b
                  key={`${STATEMENT_RECENT_UPDATES_ID_BLOCK}-bTag-${shortid.generate()}`}
                >
                  {chunks}
                </b>
              ),
            }}
          />
        </div>
        <div className={`${STATEMENT_RECENT_UPDATES_BLOCK}__video`}>
          <img src={myGif} width="550" height="200" alt="guidance pop gif" />
        </div>
      </div>
    </Modal>
  );
};

RecentStatementUpdatesModal.propTypes = {
  /** Modal panel onClose function  */
  onClose: PropTypes.func.isRequired,
  /** Modal panel onSave function  */
  onSave: PropTypes.func.isRequired,
};

export default RecentStatementUpdatesModal;
