import {
  withDefaultContextAsHomeGeo,
  withDefaultContextAsSelectedProject,
} from './api-default-context';

export const getStatementRequest = (
  statementId,
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue }).get(
    `/tieout/statements/${statementId}`,
  );

export const getStatementWorkflowStepsMetaData = () => {
  return withDefaultContextAsHomeGeo()
    .get('/tieout/documentupload/workflows/steps')
    .then((response) => {
      return response;
    });
};
