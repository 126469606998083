import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/common/dropdown-component';
import { getProjectNameMenuOptions } from 'constants/feature/statement-creation-constants';
import { ProjectList } from 'models/api/project-list-model';
import { getProjectsInParticularGeo } from 'api/project-list-api';
import Radio from 'components/common/radio-component';
import ConditionalRender from 'components/util/conditional-render-component';
import { StatementList } from 'models/api/statement-list-model';
import { getStatementNameMenuOptions } from 'constants/util/side-by-side-utils';
import { useParams } from 'react-router-dom';
import { getStatementListForProjectRequest } from 'api/statement-list-api';
import { FormattedMessage } from 'react-intl';
import { setSideBySideViewParams } from 'utils/statement-content-page-utils';
import { isArray } from 'lodash';
import SelectedStatement from 'models/api/selected-statement-model';

export const SELECT_STATEMENT_TAB_BLOCK = 'left-side-view-select-statement';
export const SELECT_STATEMENT_TAB_ID = 'left-side-view-select-statement-id';

const SelectStatement = ({
  isButtonDisabled,
  setSourceStatementParamsValues,
  selectedProjectData,
}) => {
  const { projectId, statementId } = useParams();
  const [projectList, setProjectList] = useState(new ProjectList());
  const [selectedProject, setSelectedProject] = useState(null);
  const [statementList, setStatementList] = useState(new StatementList());
  const [selectedStatement, setSelectedStatement] = useState(null);
  const [selectedStatementProcessType, setSelectedStatementProcessType] =
    useState(1);

  const [selectedStatementRevision, setSelectedStatementRevision] =
    useState(null);

  const projectOptions = useRef([]);
  const statementOptions = useRef([]);

  useEffect(() => {
    let isMounted = true;
    const _fetchProjectList = async () => {
      try {
        setProjectList(projectList.setLoading());
        const response = await getProjectsInParticularGeo();
        response.data.result = isArray(response.data.result)
          ? response.data.result.filter(
              (_project) =>
                _project.containerCode === selectedProjectData.containerCode,
            )
          : [];
        if (isMounted) {
          setProjectList(projectList.setLoaded({ response }));
        }
      } catch (error) {
        setProjectList(projectList.setError(error));
      }
    };

    _fetchProjectList();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, selectedProjectData.containerCode]);

  useEffect(() => {
    if (projectList.hasProjects()) {
      //set project options
      projectOptions.current = getProjectNameMenuOptions(
        projectList.getActiveProjects(),
      );
      // set default project
      setSelectedProject(
        // eslint-disable-next-line eqeqeq
        projectOptions.current.find((project) => project.id == projectId),
      );
    }
  }, [projectList, projectId]);

  useEffect(() => {
    let isMounted = true;
    const _fetchStatementList = async () => {
      try {
        setStatementList(statementList.setLoading());
        const response = await getStatementListForProjectRequest({
          projectId: selectedProject.id,
          selectedProject: selectedProjectData,
        });
        if (
          response &&
          response.data &&
          response.data.result &&
          response.data.result.elements &&
          response.data.result.elements.length
        ) {
          response.data.result.elements.forEach((item, index, arr) => {
            if (item.id === parseInt(statementId)) {
              arr.splice(index, 1);
            }
          });
        }

        isMounted && setStatementList(statementList.setLoaded({ response }));
      } catch (error) {
        setStatementList(statementList.setError(error));
      }
    };

    selectedProject && selectedProject.id && _fetchStatementList();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  useEffect(() => {
    setSourceStatementParamsValues(
      setSideBySideViewParams(
        selectedProject && selectedProject.id,
        selectedStatement,
        selectedStatementRevision,
        selectedStatementProcessType,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, selectedStatement, selectedStatementRevision]);

  const onStatementSelection = (val) => {
    setSelectedStatement(val);
    setSelectedStatementRevision(
      statementList.getStatements().find((item) => item.id === val).revisionId,
    );
    setSelectedStatementProcessType(
      statementList.getStatements().find((item) => item.id === val)
        .documentProcessType,
    );
  };

  const onProjectSelection = (val) => {
    setSelectedProject(val);
  };

  useEffect(() => {
    if (statementList.hasStatements()) {
      // set statement options
      statementOptions.current = getStatementNameMenuOptions(
        statementList.getStatements(),
      );
      setSelectedStatement(statementList.getStatements()[0].id);
      setSelectedStatementRevision(statementList.getStatements()[0].revisionId);
      setSelectedStatementProcessType(
        statementList.getStatements()[0].documentProcessType,
      );
      isButtonDisabled(false);
    } else {
      statementOptions.current = [];
      isButtonDisabled(true);
    }
  }, [isButtonDisabled, statementList]);

  return (
    <>
      <ConditionalRender dependencies={[projectList]}>
        <Dropdown
          id={`${SELECT_STATEMENT_TAB_ID}-project-list`}
          className={`${SELECT_STATEMENT_TAB_BLOCK}_project_list-dropdown`}
          label={'side-by-side-view.compare.left-panel.dropdown.label'}
          placeholder={'common.placeholder.select'}
          width={'100%'}
          onSelectOption={(option) => onProjectSelection(option)}
          options={projectOptions.current}
          value={selectedProject}
        />
      </ConditionalRender>
      <ConditionalRender dependencies={[statementList]}>
        {statementList && statementList.hasStatements() ? (
          <Radio
            id={`${SELECT_STATEMENT_TAB_ID}-statements`}
            label={
              'side-by-side-view.compare.left-panel.statements.radio.label'
            }
            className={`${SELECT_STATEMENT_TAB_BLOCK}_radio_wrap-statement-list`}
            radioWrapperClass={`${SELECT_STATEMENT_TAB_BLOCK}_radio`}
            options={statementOptions.current}
            width="auto"
            isValid
            selectedOption={selectedStatement}
            onChange={(val) => onStatementSelection(val)}
          />
        ) : (
          <div className={`${SELECT_STATEMENT_TAB_BLOCK}__no-data-msg`}>
            <FormattedMessage id="side-by-side-view.compare.left-panel.data-not-found" />
          </div>
        )}
      </ConditionalRender>
    </>
  );
};

SelectStatement.propTypes = {
  /** func to set disable the confirm button */
  isButtonDisabled: PropTypes.func.isRequired,
  /** action is used to set projectId, statementId and revisionId as per selection of user in sideBySide view*/
  setSourceStatementParamsValues: PropTypes.func.isRequired,
  /** statement data of a target statement in side-by-side view page */
  selectedProjectData: PropTypes.instanceOf(SelectedStatement),
};

export default SelectStatement;
