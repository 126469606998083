import ApiModel from 'models/api-model';

export class StatementWorkflowStepsData extends ApiModel({
  data: {
    REGULAR: {}, // type Statement
    OCR: {},
  },
}) {
  get REGULAR() {
    return this.data.REGULAR;
  }

  get OCR() {
    return this.data.OCR;
  }

  getWorkflowStepsByDocProcessType(documentProcessTypeEnum) {
    return this[documentProcessTypeEnum];
  }

  isFailedWorkflowStep(documentProcessTypeEnum) {
    return this[documentProcessTypeEnum].FAILED;
  }

  isCompletedWorkFlowStep(documentProcessTypeEnum) {
    return this[documentProcessTypeEnum].COMPLETED;
  }

  isScanningWorkflowStep(documentProcessTypeEnum) {
    return this[documentProcessTypeEnum].SCANNING;
  }

  processResponse(response) {
    return {
      data: {
        REGULAR: response.data.REGULAR,
        OCR: response.data.OCR,
      },
    };
  }
}
