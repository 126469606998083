import BaseModel from 'models/base-model';

export default class BlacklineDetails extends BaseModel({
  type: null,
  content: null,
  polygon: null,
}) {
  setLoadedFromBlacklineData({ type, content, polygon }) {
    return this.merge({
      type,
      content,
      polygon,
    });
  }
}
