import ElementFilters from 'models/data/element-filters-model';
import { SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID } from 'components/feature/statement-summary/statement-summary-display-options-dropdown';

export const unverifiedElementFilter = new ElementFilters({ verified: false });

export const verifiedElementFilter = new ElementFilters({ verified: true });

export const reviewedElementFilter = new ElementFilters({ reviewed: true });

export const flaggedElementFilter = new ElementFilters({
  userFlag: true,
  formulaFlag: true,
  internalReferenceFlag: true,
  parentFlagged: true,
});

export const DISPLAY_SETTINGS_OPTIONS_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-viewAll-checkbox`,
    name: 'viewAll',
    key: '1',
    label: 'statement-summary.elements-view-all.label',
  },
];

export const OPTIONS1_CHECKBOX_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-unverified-checkbox`,
    name: 'unverified',
    key: '2',
    label: 'statement-summary.elements-unverified.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-verified-checkbox`,
    name: 'verified',
    key: '3',
    label: 'statement-summary.elements-verified.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-reviewed-checkbox`,
    name: 'reviewed',
    key: '4',
    label: 'statement-summary.elements-reviewed.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-sign-off-checkbox`,
    name: 'signedOff',
    key: '5',
    label: 'statement-summary.section-sign-off.label',
  },
];

export const OPTIONS2_CHECKBOX_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-notes-checkbox`,
    name: 'notes',
    key: '7',
    label: 'statement-summary.notes.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-flags-checkbox`,
    name: 'flags',
    key: '8',
    label: 'statement-summary.elements-flagged.label',
  },
];

export const METRICS_CHECKBOX_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-percentage-checkbox`,
    name: 'percentage',
    key: '10',
    label: 'statement-summary.metrics.percentages.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-count-checkbox`,
    name: 'count',
    key: '11',
    label: 'statement-summary.metrics.count.label',
  },
];

export const OPTIONS3_CHECKBOX_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-workpaperReference-checkbox`,
    name: 'workpaper',
    key: '12',
    label: 'statement-summary.workpaper.reference.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-footedFormulas-checkbox`,
    name: 'formula',
    key: '13',
    label: 'statement-summary.footed.formulas.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-internalReferences-checkbox`,
    name: 'internalRef',
    key: '14',
    label: 'statement-summary.internal.references.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-tickmarks-checkbox`,
    name: 'tickmarks',
    key: '15',
    label: 'statement-summary.tickmarks.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-comfortLetter-checkbox`,
    name: 'comfortLetter',
    key: '16',
    label: 'statement-summary.comfort.letter.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-notes-checkbox`,
    name: 'note',
    key: '17',
    label: 'statement-summary.notes.annotations.label',
  },
];
