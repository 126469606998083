import FileSaver from 'file-saver';
import { getStore } from 'store/store';

export const downloadFile = (response) => {
  const fileName = response.headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim();
  const contentType = response.headers['content-type'];
  let blob = new Blob([response.data], { type: contentType });
  FileSaver.saveAs(blob, fileName);
};

export const getStatementIdUtil = (statementId) => {
  if (statementId) {
    return statementId;
  }
  const { selectedStatement } = getStore().getState().data;
  let id =
    selectedStatement &&
    selectedStatement.statement &&
    parseInt(selectedStatement.statement.id);
  // if we are unsuccessful in getting selected statement id from redux then try getting it from url params.
  const locationPath = window.location.pathname.split('/');
  if (!id && locationPath.includes('statement')) {
    id = locationPath[locationPath.indexOf('statement') + 1];
  }
  return id;
};
