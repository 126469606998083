import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as LikeIcon } from 'icons/like.svg';
import { ReactComponent as DislikeIcon } from 'icons/dislike.svg';
import ChatbotPanelResponseFeedback from './toolkit-chatbot-response-feedback-modal';
import { FormattedMessage } from 'react-intl';
import {
  groupCitation,
  CHAT_BOT_TYPING_ID,
  getInitials,
} from './toolkit-chatbot-utils';
import Citation from './toolkit-chatbot-panel-citation';
import ReactMarkdown from 'react-markdown';

const ICON_SIZE = '25px';
const RESPONSE_TYPE = {
  GOOD: 1,
  BAD: 2,
};

const MessageBubble = ({
  message,
  BLOCK,
  setMessages,
  currentUser,
  containerCode,
  cbFeedbackEnabled,
  geoCode,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isGoodResponse, setGoodResponse] = useState(false);

  const onResponseTypeClick = (responseType) => {
    if (message.isGoodFeedbackTaken || message.isBadFeedbackTaken) {
      return;
    }

    if (responseType === RESPONSE_TYPE.GOOD) {
      setGoodResponse(true);
    } else {
      setGoodResponse(false);
    }
    cbFeedbackEnabled && setShowModal(true);
  };

  const shouldShowThumbsUpIcon =
    !message.isFeedbackTaken ||
    (message.isFeedbackTaken && message.isGoodFeedbackTaken);
  const shouldShowThumbsDownIcon =
    !message.isFeedbackTaken ||
    (message.isFeedbackTaken && message.isBadFeedbackTaken);

  const displayResponse = (message) => {
    if (message.isUser) {
      return message.message;
    } else {
      // Group citation by page number
      let groupedCitations = groupCitation(message);

      return (
        <>
          {message.response && message.id !== CHAT_BOT_TYPING_ID && (
            <div className={`${BLOCK}__message-bubble--message-response-title`}>
              Response
            </div>
          )}
          <ReactMarkdown>{message.response}</ReactMarkdown>
          {!!Object.keys(groupedCitations).length && (
            <Citation BLOCK={BLOCK} groupedCitations={groupedCitations} />
          )}
        </>
      );
    }
  };

  return (
    <>
      <div
        className={classNames(`${BLOCK}__message-bubble`, {
          [`${BLOCK}__user`]: message.isUser,
          [`${BLOCK}__bot`]: !message.isUser,
        })}
      >
        <div
          className={classNames(`${BLOCK}__initials`, {
            [`${BLOCK}__user-initials`]: message.isUser,
          })}
        >
          {message.isUser
            ? getInitials(currentUser.data.preferredFullName)
            : 'AI'}
        </div>
        <div
          className={classNames(`${BLOCK}__message-content`, {
            [`${BLOCK}__message-content-user`]: message.isUser,
            [`${BLOCK}__message-content-bot`]: !message.isUser,
          })}
        >
          {displayResponse(message)}
        </div>
      </div>
      {!message.isUser && message.id !== 'typing' && (
        <div className={`${BLOCK}__feedback-section`}>
          {cbFeedbackEnabled && shouldShowThumbsUpIcon && (
            <div
              className={classNames(`${BLOCK}__response-type`, {
                [`${BLOCK}__response-type--highlight`]:
                  message.isGoodFeedbackTaken,
              })}
              onClick={() => onResponseTypeClick(RESPONSE_TYPE.GOOD)}
            >
              <LikeIcon
                width={ICON_SIZE}
                height={ICON_SIZE}
                className={classNames(`${BLOCK}__like-icon`, {
                  [`${BLOCK}__icon-highlight`]: message.isGoodFeedbackTaken,
                })}
              />
              <FormattedMessage id="chatbot.response.type.good" />
            </div>
          )}
          {cbFeedbackEnabled && shouldShowThumbsDownIcon && (
            <div
              className={classNames(`${BLOCK}__response-type`, {
                [`${BLOCK}__response-type--highlight`]:
                  message.isBadFeedbackTaken,
              })}
              onClick={() => onResponseTypeClick(RESPONSE_TYPE.BAD)}
            >
              <DislikeIcon
                width={ICON_SIZE}
                height={ICON_SIZE}
                className={classNames(`${BLOCK}__dislike-icon`, {
                  [`${BLOCK}__icon-highlight`]: message.isBadFeedbackTaken,
                })}
              />
              <FormattedMessage id="chatbot.response.type.bad" />
            </div>
          )}
        </div>
      )}
      {showModal && (
        <ChatbotPanelResponseFeedback
          setShowModal={setShowModal}
          isGoodResponse={isGoodResponse}
          message={message}
          setMessages={setMessages}
          containerCode={containerCode}
          geoCode={geoCode}
        />
      )}
    </>
  );
};

export default MessageBubble;
