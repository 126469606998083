import { handleActions } from 'redux-actions';
import {
  toggleSyncScrolling,
  setSyncScrolling,
  resetSyncScrolling,
} from 'store/actions/preview-report-page-actions';

export default handleActions(
  {
    [toggleSyncScrolling]: (syncScrollingMode) => {
      return !syncScrollingMode;
    },
    [setSyncScrolling]: () => {
      return true;
    },
    [resetSyncScrolling]: () => {
      return false;
    },
  },
  false,
);
