import React from 'react';
import { useSelector } from 'react-redux';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import NoteDetailsOcr from './note-details-ocr';
import RenderPolygonBasedStatementDetails from 'utils/ocr-statement-details-utils';
import { useLocation } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import {
  toggleInternalReferenceCancelModal,
  toggleFormulaCancelModal,
  openCancelModalAfterModalConfirm,
  storeSelectedNoteId,
  storeSelectedNotePseudoId,
} from 'store/actions/panel-cancel-modal-actions';
import { selectNoteFromContentPanel as onNoteClick } from 'store/actions/notes-panel-actions';
import { useDispatch } from 'react-redux';
import { changeIsNewPropertyFromSelectedNote } from 'store/actions/notes-panel-actions';

const NotesDetailsContainerOCR = ({ notesList, scale, dpi, leftSideView }) => {
  const pathName = useLocation().pathname;
  const dispatch = useDispatch();

  const {
    data: {
      statementSummary: {
        statementSummaryElementsDisplay: {
          displayElementDetailsOnStatementPage,
        },
      },
      notesPanel: { selectedNote },
      annotationDisplayOptions: annotationDisplayFilter,
    },
    ui: {
      statementPage: {
        modes: { selectMode },
        panels: { right },
      },
    },
  } = useSelector((state) => state);

  const handleNoteClick = (
    event,
    selectMode,
    note,
    pseudoElementId,
    onNoteClick,
  ) => {
    if (pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)) {
      return () => {};
    } else {
      return onClick(event, selectMode, note, pseudoElementId, onNoteClick);
    }
  };

  const onClick = (event, selectMode, note, pseudoElementId, onNoteClick) => {
    if (selectMode === ELEMENT_SELECT_MODES.FORMULA.title) {
      dispatch(toggleFormulaCancelModal(true));
      dispatch(
        storeSelectedNoteId(note.elementId === null ? -1 : note.elementId),
      );
      dispatch(storeSelectedNotePseudoId(pseudoElementId));
      dispatch(openCancelModalAfterModalConfirm(true));
    } else if (selectMode === ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.title) {
      dispatch(toggleInternalReferenceCancelModal(true));
      dispatch(openCancelModalAfterModalConfirm(true));
      dispatch(
        storeSelectedNoteId(note.elementId === null ? -1 : note.elementId),
      );
      dispatch(storeSelectedNotePseudoId(pseudoElementId));
    } else {
      if (event) {
        event.stopPropagation();
      }
      dispatch(
        onNoteClick({
          elementId: note.elementId === null ? -1 : note.elementId,
          pseudoElementId,
        }),
      );
    }
  };

  const pageNotes = [];

  !!notesList.length &&
    notesList.map((note) => {
      if (!note.ghost) {
        const {
          ocrSelector: { polygon },
        } = note;

        let loopCount = polygon.length - 1;

        displayElementDetailsOnStatementPage &&
          annotationDisplayFilter.note &&
          pageNotes.push(
            <RenderPolygonBasedStatementDetails
              coordinates={polygon}
              scale={scale * dpi}
              key={note.noteId}
              loopCount={loopCount}
              displayIcon={true}
            >
              <NoteDetailsOcr
                note={note}
                isNoteSelected={selectedNote.noteId === note.noteId}
                handleNoteClick={handleNoteClick}
                onNoteClick={onNoteClick}
                selectMode={selectMode.title}
                pseudoElementId={note.pseudoElementId}
                isNoteNew={selectedNote.data.isNew}
                panelSelected={selectedNote.data.color}
                isNotesPanelOpen={right === RIGHT_PANELS.NOTES}
                changeIsNewPropertyFromSelectedNote={() =>
                  dispatch(changeIsNewPropertyFromSelectedNote(false))
                }
                isCursorAlias={leftSideView}
                shouldNotesDisabled={
                  pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)
                    ? !leftSideView
                    : leftSideView
                }
              />
            </RenderPolygonBasedStatementDetails>,
          );
      }
    });

  return <>{pageNotes}</>;
};

export default NotesDetailsContainerOCR;
