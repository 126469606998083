import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import InternalReferencePanelHeader from './_internal-reference-panel-header-component';
import InternalReferencePanelContentContainer from 'containers/feature/internal-reference/internal-reference-panel-content-container';
import InternalReferenceCancelModal from 'components/feature/element-panel/internal-reference-panel/_internal-reference-cancel-modal-component';
import { isNullOrUndefined } from 'utils/object-utils';

export const INTERNAL_REFERENCE_PANEL_BLOCK = 'internal-reference-panel';
export const INTERNAL_REFERENCE_PANEL_ID_BLOCK = 'internal-reference-panel-id';
class InternalReferencePanel extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.showInternalReferencePanel &&
      this.props.showInternalReferencePanel
    ) {
      this.props.initInternalReferencePanel();
    }
  }

  confirmCancelModal() {
    const {
      toggleInternalReferenceCancelModal,
      openCancelModalAfterModalConfirm,
      toggleNotesPanel,
      cancelModal,
      storeSelectedNoteId,
      storeSelectedNotePseudoId,
      selectNoteFromContentPanel,
      onSectionClick,
    } = this.props;
    const {
      selectedNoteId,
      selectedPseudoNoteId,
      openInternalReferenceCancelModal,
      openNotePanelAfterConfirm,
      openSectionPanelAfterconfirm,
      selectedSectionId,
    } = cancelModal;
    if (openInternalReferenceCancelModal && openNotePanelAfterConfirm) {
      // This will close the IR cancel modal
      toggleInternalReferenceCancelModal(false);

      // This is used to set a boolean value in cancel modal object which represent if we want to open notes panel.
      // It will just indicate that opening notes panel is required but not open notes panel. Name might be misleading
      // . It is an old code. Will take Infra Enabler and try to fix these things
      openCancelModalAfterModalConfirm(false);
      if (selectedNoteId && selectedPseudoNoteId) {
        selectNoteFromContentPanel({
          elementId: selectedNoteId,
          pseudoElementId: selectedPseudoNoteId,
        });
        storeSelectedNotePseudoId(null);
        storeSelectedNoteId(null);
      } else toggleNotesPanel();
    }
    if (
      openSectionPanelAfterconfirm &&
      openInternalReferenceCancelModal &&
      !isNullOrUndefined(selectedSectionId)
    ) {
      toggleInternalReferenceCancelModal(false);
      openCancelModalAfterModalConfirm(false);
      onSectionClick({ sectionId: selectedSectionId });
    } else {
      toggleInternalReferenceCancelModal(false);
      openCancelModalAfterModalConfirm(false);
    }
  }

  closeModal() {
    const {
      toggleInternalReferenceCancelModal,
      openCancelModalAfterModalConfirm,
      storeSelectedNoteId,
      storeSelectedNotePseudoId,
    } = this.props;
    toggleInternalReferenceCancelModal(false);
    openCancelModalAfterModalConfirm(false);
    storeSelectedNotePseudoId(null);
    storeSelectedNoteId(null);
  }

  render() {
    const {
      showInternalReferencePanel,
      closeInternalReferencePanel,
      cancelModal,
    } = this.props;
    const { openInternalReferenceCancelModal } = cancelModal;
    return (
      <>
        <Flyout
          className={`${INTERNAL_REFERENCE_PANEL_BLOCK}__flyout`}
          show={showInternalReferencePanel}
          isAnimated
        >
          {showInternalReferencePanel && (
            <>
              <FlyoutHeader enableBack onBack={closeInternalReferencePanel}>
                <InternalReferencePanelHeader />
              </FlyoutHeader>
              <FlyoutPanelContent>
                <InternalReferencePanelContentContainer />
              </FlyoutPanelContent>
            </>
          )}
        </Flyout>
        {openInternalReferenceCancelModal && (
          <InternalReferenceCancelModal
            onClickNo={() => {
              this.closeModal();
            }}
            onClickYes={() => {
              closeInternalReferencePanel();
              this.confirmCancelModal();
            }}
          />
        )}
      </>
    );
  }
}

InternalReferencePanel.propTypes = {
  /**A boolean indicating if we should show the internal reference panel or not. */
  showInternalReferencePanel: PropTypes.bool.isRequired,
  /**The function that is fired when we need to hide the internal reference panel. */
  closeInternalReferencePanel: PropTypes.func.isRequired,
  /** Function fired when internal reference panel is shown, initializes redux information */
  initInternalReferencePanel: PropTypes.func.isRequired,
  // It is an Action method.This is used to set a boolean value in cancel modal object which represent if we want to open notes panel.
  // It will just indicate that opening notes panel is required but not open notes panel. Name might be misleading
  // . It is an old code. Will take Infra Enabler and try to fix these things
  openCancelModalAfterModalConfirm: PropTypes.func.isRequired,
  // It is an action method. It is used to close the IR panel cancel modal
  toggleInternalReferenceCancelModal: PropTypes.func.isRequired,
  /**The action to open the section. */
  onSectionClick: PropTypes.func,
  /**The action to open the section panel. */
  openSectionPanelAfterconfirm: PropTypes.func,
  /**The id of selectedsection. */
  selectedSectionId: PropTypes.number,
};

export default InternalReferencePanel;
