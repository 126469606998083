import ApiModel from 'models/api-model';

export class SectionReviewList extends ApiModel({
  data: {
    sectionReviews: [],
    sectionReviewsMap: {},
  },
}) {
  get sectionReviews() {
    if (this.data && this.data.sectionReviews) {
      return this.data.sectionReviews;
    }
    return null;
  }

  get sectionReviewsMap() {
    if (this.data && this.data.sectionReviewsMap) {
      return this.data.sectionReviewsMap;
    }
    return {};
  }

  processResponse({ response }) {
    let sectionsMap = {};
    for (let i = 0; i < response.data.length; i++) {
      const review = response.data[i];
      if (!(review.sectionId in sectionsMap)) {
        sectionsMap[review.sectionId] = {
          [review.appUserId]: true,
        };
      } else {
        sectionsMap[review.sectionId][review.appUserId] = true;
      }
    }

    return {
      data: {
        sectionReviews: response.data,
        sectionReviewsMap: sectionsMap,
      },
    };
  }

  appendSectionReview(review) {
    let sectionReviewsMapCopy = { ...this.data.sectionReviewsMap };
    const sectionReviewsCopy = [...this.sectionReviews];

    sectionReviewsCopy.push(review);
    sectionReviewsCopy.sort(
      (a, b) => a.sectionId - b.sectionId || b.updatedDate - a.updatedDate,
    );

    if (review.sectionId in sectionReviewsMapCopy) {
      sectionReviewsMapCopy[review.sectionId][review.appUserId] = true;
    } else {
      sectionReviewsMapCopy[review.sectionId] = {
        [review.appUserId]: true,
      };
    }

    return this.merge({
      isLoaded: true,
      isLoading: false,
      error: null,
      data: {
        sectionReviews: sectionReviewsCopy,
        sectionReviewsMap: sectionReviewsMapCopy,
      },
    });
  }

  mergeSectionsReviewList(reviewList) {
    const sectionReviewsMapCopy = { ...this.data.sectionReviewsMap };
    const sectionReviewsCopy = [...this.sectionReviews];

    reviewList.forEach((review) => {
      const index = sectionReviewsCopy
        .map((item) => item.sectionId)
        .indexOf(review.sectionId);
      if (index >= 0) {
        sectionReviewsCopy[index] = review;
      } else {
        sectionReviewsCopy.push(review);
      }

      if (review.sectionId in sectionReviewsMapCopy) {
        sectionReviewsMapCopy[review.sectionId][review.appUserId] = true;
      } else {
        sectionReviewsMapCopy[review.sectionId] = {
          [review.appUserId]: true,
        };
      }
    });

    sectionReviewsCopy.sort(
      (a, b) => a.sectionId - b.sectionId || b.updatedDate - a.updatedDate,
    );

    return this.merge({
      isLoaded: true,
      isLoading: false,
      error: null,
      data: {
        sectionReviews: sectionReviewsCopy,
        sectionReviewsMap: sectionReviewsMapCopy,
      },
    });
  }

  removeBulkSectionReview(payload) {
    let sectionReviewsMapCopy = { ...this.data.sectionReviewsMap };
    payload.forEach((item) => {
      if (item.sectionId in sectionReviewsMapCopy) {
        delete sectionReviewsMapCopy[item.sectionId];
      }
    });

    const sectionReviewedCopy = this.sectionReviews.filter((entry) => {
      const removeSection = payload.find(
        (item2) => entry.sectionId === item2.sectionId,
      );
      return !removeSection;
    });

    return this.merge({
      isLoaded: true,
      isLoading: false,
      error: null,
      data: {
        sectionReviews: sectionReviewedCopy,
        sectionReviewsMap: sectionReviewsMapCopy,
      },
    });
  }

  removeSectionReview(payload) {
    const { sectionId, userId } = payload;
    let sectionReviewsMapCopy = { ...this.data.sectionReviewsMap };
    if (
      sectionId in sectionReviewsMapCopy &&
      userId in sectionReviewsMapCopy[sectionId]
    ) {
      delete sectionReviewsMapCopy[sectionId][userId];
      if (Object.values(sectionReviewsMapCopy[sectionId]).length === 0) {
        delete sectionReviewsMapCopy[sectionId];
      }
    }

    return this.merge({
      isLoaded: true,
      isLoading: false,
      error: null,
      data: {
        sectionReviews: this.sectionReviews.filter(
          (entry) =>
            !(entry.sectionId === sectionId && entry.appUserId === userId),
        ),
        sectionReviewsMap: sectionReviewsMapCopy,
      },
    });
  }

  checkIfReviewAlreadyExist(currentVal, accumulator) {
    return accumulator.some((item) => {
      return (
        item.appUserId === currentVal.appUserId &&
        item.sectionId === currentVal.sectionId
      );
    });
  }

  hasSectionBeenReviewed(sectionId) {
    return this.sectionReviews.some((review) => review.sectionId === sectionId);
  }

  hasSectionBeenReviewedByOtherUser(currUserId, sectionId) {
    return this.sectionReviews.some(
      (review) =>
        review.sectionId === sectionId && review.appUserId !== currUserId,
    );
  }

  userAlreadyReviewed(currUserId, sectionId) {
    return this.sectionReviews.some(
      (review) =>
        review.appUserId === currUserId && review.sectionId === sectionId,
    );
  }
}
