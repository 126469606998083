import ApiModel from 'models/api-model';
import ElementDetails from './element-details-api-model';
import { isNullOrUndefined } from 'utils/object-utils';
export default class InternalReference extends ApiModel({
  data: {
    id: null,
    revisionId: null,
    name: null,
    created: null, //The timestamp at which this internal reference was created.
    updated: null, //The timestamp at which the internal reference was last updated.
    number: null,
    elements: [],
    internalReferenceElements: [],
  },
  status: null,
}) {
  get id() {
    return this.data.id;
  }
  get revisionId() {
    return this.data.revisionId;
  }
  get name() {
    return this.data.name;
  }
  get created() {
    return this.data.created;
  }
  get updated() {
    return this.data.updated;
  }
  get number() {
    return this.data.number;
  }

  get elements() {
    if (!isNullOrUndefined(this.data) && this.data.elements.length > 0) {
      return this.data.elements;
    }
    return [];
  }

  get internalReferenceElements() {
    if (
      !isNullOrUndefined(this.data) &&
      this.data.internalReferenceElements.length > 0
    ) {
      return this.data.internalReferenceElements;
    }
    return [];
  }

  processResponse({ response }) {
    if (response && response.data) {
      return {
        data: {
          ...response.data,
          elements: response.data.internalReferenceElements.map(
            (internalReferenceElement) => {
              const elementDetailsEntry = new ElementDetails({
                dateCreated: internalReferenceElement.dateCreated,
                dateUpdated: internalReferenceElement.dateUpdated,
              });
              return elementDetailsEntry.convertElementDetailsEntityToElementDetailsModel(
                {
                  elementDetails: internalReferenceElement.elementEntity,
                  sectionFullName: internalReferenceElement.sectionFullname,
                },
              );
            },
          ),
        },
        status: response.status,
      };
    }
  }

  isIntialized() {
    return !isNullOrUndefined(this.data);
  }

  hasElements() {
    return this.data && this.data.elements && this.data.elements.length > 0;
  }

  hasElement(elementId) {
    return !isNullOrUndefined(
      this.elements.find(
        (element) => parseInt(element.id) === parseInt(elementId),
      ),
    );
  }

  getOtherElements({ elementId }) {
    if (this.hasElements()) {
      return this.data.elements.filter((element) => {
        return element.id !== elementId && element.data.included === 1;
      });
    }
    return [];
  }

  getInternalReferencesSortedById({ elementId }) {
    return this.getOtherElements({ elementId }).sort((iR1, iR2) =>
      iR1.id < iR2.id ? -1 : 1,
    );
  }

  getInternalReferencesByTimeCreated({ elementId }) {
    return this.getOtherElements({ elementId }).sort(
      (iR1, iR2) => iR1.dateCreated < iR2.dateCreated,
    );
  }

  getOtherElementsCount({ elementId }) {
    return this.getOtherElements({ elementId: elementId }).length;
  }

  updateInternalReferenceElementsFromSocket(payload) {
    let elementsCopy = [...this.data.elements];

    for (let i = 0; i < elementsCopy.length; i++) {
      const element = elementsCopy[i];
      if (element.data.id in payload) {
        elementsCopy[i] = payload[element.data.id];
      }
    }
    return this.mergeData({ elements: elementsCopy });
  }
  updateInternalReferenceFromSocket(payload) {
    if (payload) {
      return this.mergeData({
        ...payload,
        elements: payload.internalReferenceElements.map(
          (internalReferenceElement) => {
            const elementDetailsEntry = new ElementDetails({
              dateCreated: internalReferenceElement.dateCreated,
              dateUpdated: internalReferenceElement.dateUpdated,
            });
            return elementDetailsEntry.convertElementDetailsEntityToElementDetailsModel(
              {
                elementDetails: internalReferenceElement.elementEntity,
                sectionFullName: internalReferenceElement.sectionFullname,
              },
            );
          },
        ),
      });
    } else {
      return this.mergeData({
        id: 0,
        revisionId: 0,
        name: null,
        created: null,
        updated: null,
        number: 0,
        elements: [],
      });
    }
  }
}
