import { createAction } from 'redux-actions';
import {
  setElementSelectModeFormulaAction,
  setElementSelectModeDefaultAction,
} from 'store/actions/modes-actions';
import {
  replaceSelectedElementsContentPanel,
  selectElementContentPanel,
  deselectElementContentPanel,
} from 'store/actions/statement-content-actions';
import {
  getElementFromCache,
  getMultipleElementsFromCache,
} from 'store/actions/element-cache-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import {
  createNewFormulaRequest,
  getFormulaDetailsRequest,
  editFormulaRequest,
} from 'api/formula-api';
import { updateElementsSearchByIds } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { updateElementAnnotationDetails } from 'store/actions/element-panel-actions';

export const setLastElementSelectedFormula = createAction(
  'SET_LAST_ELEMENT_SELECTED_FORMULA',
);

export const setFormulaLoading = createAction('FORMULA_LOADING');

export const setFormulaLoaded = createAction('SET_FORMULA_LOADED');

export const setFormulaResult = createAction('SET_FORMULA_RESULT');

export const setFormulaError = createAction('FORMULA_ERROR');

export const setFormulaElementRowsLoaded = createAction(
  'FORMULA_ELEMENT_ROWS_LOADED',
);

export const initFormulaCreateAction = createAction('INIT_FORMULA_CREATE');

export const setFormulaOperator = createAction('SET_FORMULA_OPERATOR');

export const addManualFormulaRow = createAction('ADD_MANUAL_FORMULA_ROW');

export const updateRowOperator = createAction('UPDATE_FORMULA_ROW_OPERATOR');
export const setFormulaManualNumber = createAction('UPDATE_FORMULA_MANUAL_ROW');

export const removeFormulaRowAction = createAction('REMOVE_FORMULA_ROW');
export const addFormulaRowOperator = createAction('ADD_FORMULA_ROW_OPERATOR');
export const removeFormulaRowOperator = createAction(
  'REMOVE_FORMULA_ROW_OPERATOR',
);
export const removeAllRowsAction = createAction('REMOVE_ALL_FORMULA_ROWS');

export const clearFormulaAction = createAction('CLEAR_FORMULA');

export const setSelectedRowAction = createAction('SET_SELECTED_ROW_ACTION');
export const setRoundingResultAction = createAction(
  'SET_COMPUTED_RESULT_ACTION',
);
export const setNewFormulaAction = createAction('SET_NEW FORMULA_ACTION');

export const removeFormulaWithSocketPayloadAction = createAction(
  'REMOVE_FORMULA_WITH_SOCKET_PAYLOAD_ACTION',
);

export const initFormulaCreate = () => (dispatch, getState) => {
  const { elementDetails } = getState().data.elementPanel;
  dispatch(setElementSelectModeFormulaAction());
  dispatch(initFormulaCreateAction(elementDetails));
};

export const removeAllFormulaRows = () => (dispatch, getState) => {
  const { formula } = getState().data.formulaPanel;
  dispatch(removeAllRowsAction());
  dispatch(
    replaceSelectedElementsContentPanel({ elementIds: [formula.elementId] }),
  );
};

export const addElementFormulaRow =
  ({ elementId, sectionId }) =>
  async (dispatch, getState) => {
    dispatch(selectElementContentPanel({ elementIds: [elementId] }));
    let elementData = await dispatch(
      getElementFromCache({
        elementId,
        sectionId,
        loadingAction: setFormulaLoading,
      }),
    );
    dispatch(setFormulaElementRowsLoaded({ selectedElements: [elementData] }));
  };

export const removeFormulaRow =
  ({ formulaRow, formulaRowIndex }) =>
  (dispatch) => {
    dispatch(removeFormulaRowAction({ formulaRowIndex }));
    if (formulaRow.isElement()) {
      dispatch(
        deselectElementContentPanel({ elementId: formulaRow.elementId }),
      );
    }
  };

export const batchAddElementFormulaRows =
  ({ elements }) =>
  async (dispatch, getState) => {
    const selectedElements = await dispatch(
      getMultipleElementsFromCache({
        elements,
        loadingAction: setFormulaLoading,
      }),
    );
    dispatch(setFormulaElementRowsLoaded({ selectedElements }));
  };

export const clearFormula =
  ({ elementId }) =>
  (dispatch) => {
    dispatch(clearFormulaAction());
    //Replace the potential list of many selected elements in the content panel with the formula's base element only.
    dispatch(replaceSelectedElementsContentPanel({ elementIds: [elementId] }));
    dispatch(setElementSelectModeDefaultAction());
  };

export const _updateStatementSummaryElements = async (dispatch, getState) => {
  const revisionId = getState().data.revision.id;
  dispatch(fetchStatementSummaryElements({ revisionId }));
};

export const requestCreateNewFormula = () => async (dispatch, getState) => {
  const { formula } = getState().data.formulaPanel;
  const { elementDetails } = getState().data.elementPanel;
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;
  dispatch(setFormulaLoading());
  try {
    await createNewFormulaRequest({
      formulaModel: formula,
    });
    if (socketHasBeenDisconnected) {
      // no need to set loaded, if it is successful we will wind up closing this panel
      dispatch(clearFormula({ elementId: formula.elementId }));
      dispatch(_updateStatementSummaryElements);
      dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
      dispatch(updateElementAnnotationDetails());
    }
  } catch (error) {
    dispatch(setFormulaError(error));
  }
};

export const requestUpdateFormula = () => async (dispatch, getState) => {
  const storeData = getState().data;
  const { formula } = storeData.formulaPanel;
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;
  dispatch(setFormulaLoading());
  try {
    await editFormulaRequest({
      formulaModel: formula,
    });
    if (socketHasBeenDisconnected) {
      // no need to set loaded, if it is successful we will wind up closing this panel
      dispatch(clearFormula({ elementId: formula.elementId }));
      dispatch(_updateStatementSummaryElements);
    }
  } catch (error) {
    dispatch(setFormulaError(error));
  }
};

export const fetchSelectedFormulaDetails =
  (formulaId) => async (dispatch, getState) => {
    dispatch(setFormulaLoading());
    try {
      const storeData = getState().data;
      const { revision } = storeData;
      const { elementDetails } = storeData.elementPanel;
      const response = await getFormulaDetailsRequest({
        revisionId: revision.id,
        elementId: elementDetails.id,
        formulaId,
      });
      dispatch(setFormulaLoaded({ response }));
      /* Triggering this below action to compute the result, computedResult 
    and roundingResult to show the data on flyout out panel */
      dispatch(setFormulaResult());
    } catch (error) {
      dispatch(setFormulaError(error));
    }
  };
