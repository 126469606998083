import React, { useRef, useState, useEffect } from 'react';
import shortid from 'shortid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import ChatBotPanelHeader from 'components/feature/toolkit/chatbot-toolkit-panel/toolkit-chatbot-header-component';
import ChatBotPanelGuidanceMessage from 'components/feature/toolkit/chatbot-toolkit-panel/toolkit-chatbot-guidance-message-component';
import ChatBotPanelFooter from 'components/feature/toolkit/chatbot-toolkit-panel/toolkit-chatbot-panel-footer-component';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import ChatWindow from './toolkit-chatbot-panel-chat-window';
import {
  sendChatBotUserResponse,
  getDocumentIndexingStatus,
} from 'api/chatbot-api';
import {
  createPreviousMessages,
  CHAT_BOT_TYPING_ID,
  chatBotFailApiResponse,
  DOC_INDEXING_STATUS,
} from './toolkit-chatbot-utils';
import { ChatBotClearSessionAcknowledgeModal } from 'components/common/chat-bot-clear-session-acknowledge-modal';

export const CHATBOT_PANEL_BLOCK = 'chatbot-panel';
export const CHATBOT_PANEL_BLOCK_ID = 'chatbot-panel-id';
const REQUEST_ID = 'dummy';
const FOOTER_HEIGHT = 72; //px

export const ChatBotPanel = ({
  show,
  statement,
  currentUser,
  geoCode,
  containerCode,
  cbFeedbackEnabled,
}) => {
  const textAreaRef = useRef(null);
  const bannerRef = useRef(null);
  const footerRef = useRef(null);
  const [text, setText] = useState('');
  const [bannerHeight, setBannerHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(FOOTER_HEIGHT);
  const [showSecondaryMessage, setShowSecondaryMessage] = useState(false);
  const [isIndexingCompleted, setIndexingCompleted] = useState(true);
  const [messages, setMessages] = useState([]);
  const [clearSessionModal, setClearSessionModal] = useState(false);
  const [inputFieldDisable, setInputFieldDisable] = useState(false);

  const handleSendChatData = (msg) => {
    setMessages([...messages, msg]);

    // Add a "Typing..." indicator
    const typingMessage = {
      id: CHAT_BOT_TYPING_ID,
      response: 'Typing...',
      isUser: false,
    };

    const userResponse = {
      userFilterSelected: {
        file_key: `${parseInt(statement.clientId)}_${statement.statementId}_${
          statement.id
        }`,
      },
      currentMessage: msg.message,
      previousMessages: createPreviousMessages(
        messages.filter((message) => message.id !== CHAT_BOT_TYPING_ID),
      ),
      containerCode,
      geoCode,
      requestId: REQUEST_ID,
    };

    setMessages((prevMessages) => [...prevMessages, typingMessage]);

    sendChatBotUserResponse({ userResponse })
      .then((data) => {
        setInputFieldDisable(false);
        const { documentSource, response } = data;
        setMessages((prevMessages) => {
          return [
            ...prevMessages.filter(
              (message) => message.id !== CHAT_BOT_TYPING_ID,
            ),
            {
              timestamp: Date.now(),
              response,
              isUser: false,
              documentSource,
              id: shortid.generate(),
              isFeedbackTaken: false,
              isGoodFeedbackTaken: false,
              isBadFeedbackTaken: false,
              human_message: msg.message,
            },
          ];
        });
      })
      .catch((err) => {
        setInputFieldDisable(false);
        setMessages((prevMessages) => {
          return [
            ...prevMessages.filter(
              (message) => message.id !== CHAT_BOT_TYPING_ID,
            ),
            {
              timestamp: Date.now(),
              response: chatBotFailApiResponse,
              isUser: false,
              id: shortid.generate(),
              isFeedbackTaken: false,
              isGoodFeedbackTaken: false,
              isBadFeedbackTaken: false,
              human_message: msg.message,
            },
          ];
        });
      });
  };

  const onTextAreaChange = (value) => {
    setText(value);
  };

  useEffect(() => {
    if (bannerRef.current) {
      setBannerHeight(bannerRef.current.offsetHeight);
    }
  }, [show]);

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight);
    }
  }, [text.length]);

  const handleSend = () => {
    if (text.trim()) {
      const fileKey = `${parseInt(statement.clientId)}_${
        statement.statementId
      }_${statement.id}`;
      setInputFieldDisable(true);
      getDocumentIndexingStatus(fileKey)
        .then((response) => {
          const { status } = response.result;
          if (status === DOC_INDEXING_STATUS.COMPLETED) {
            handleSendChatData({
              timestamp: Date.now(),
              message: text,
              response: '',
              isUser: true,
              id: shortid.generate(),
            });
            setText('');
          } else {
            setIndexingCompleted(false);
            setInputFieldDisable(false);
          }
        })
        .catch((err) => {
          setIndexingCompleted(false);
          setInputFieldDisable(false);
          console.log(err);
        });
    }
  };

  const handleClearSession = () => {
    setClearSessionModal(false);
    setShowSecondaryMessage(true);
    setIndexingCompleted(true);
    setMessages([]);
    setText('');
  };

  return (
    <>
      <Flyout className={CHATBOT_PANEL_BLOCK} show={show} position={'left'}>
        <ChatBotPanelHeader
          setClearSessionModal={setClearSessionModal}
          clearSessionModal={clearSessionModal}
          setShowSecondaryMessage={setShowSecondaryMessage}
          setIndexingCompleted={setIndexingCompleted}
          setText={setText}
        />
        <FlyoutPanelContent>
          <div className={`${CHATBOT_PANEL_BLOCK}__body`}>
            <div ref={bannerRef}>
              <Banner
                id={`${CHATBOT_PANEL_BLOCK_ID}-disclaimer-banner`}
                className={`${CHATBOT_PANEL_BLOCK}__banner`}
                type={BANNER_TYPES.SUCCESS}
                bannerCopy={'chatbot.disclaimer.banner.message'}
                isInternationalized={true}
              />
            </div>

            <div
              className={`${CHATBOT_PANEL_BLOCK}__chat-section`}
              style={{
                height: `calc(100% - ${bannerHeight + footerHeight}px)`,
              }}
            >
              {/* We will not show the guidance message if message has length */}
              {!text.length && !messages.length && isIndexingCompleted ? (
                <ChatBotPanelGuidanceMessage
                  showSecondaryMessage={showSecondaryMessage}
                />
              ) : (
                <ChatWindow
                  BLOCK={CHATBOT_PANEL_BLOCK}
                  statement={statement}
                  messages={messages}
                  setMessages={setMessages}
                  createPreviousMessages={createPreviousMessages}
                  currentUser={currentUser}
                  containerCode={containerCode}
                  geoCode={geoCode}
                  cbFeedbackEnabled={cbFeedbackEnabled}
                  isIndexingCompleted={isIndexingCompleted}
                />
              )}
              {clearSessionModal && (
                <ChatBotClearSessionAcknowledgeModal
                  onClose={() => setClearSessionModal(false)}
                  onSubmit={handleClearSession}
                />
              )}
            </div>
          </div>
          <ChatBotPanelFooter
            show={show}
            text={text}
            ref={textAreaRef}
            footerRef={footerRef}
            onTextAreaChange={onTextAreaChange}
            showSecondaryMessage={showSecondaryMessage}
            setShowSecondaryMessage={setShowSecondaryMessage}
            handleSend={handleSend}
            messages={messages}
            inputFieldDisable={inputFieldDisable}
          />
        </FlyoutPanelContent>
      </Flyout>
    </>
  );
};

ChatBotPanel.propTypes = {
  /** Indicates if the batch panel should be shown */
  show: PropTypes.bool.isRequired,
  // action method fired to close toolkit-export flyout panel
  onCancelPanel: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    revision,
    currentUser,
    selectedProject: {
      project: { geoCode, containerCode, cbFeedbackEnabled },
    },
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  geoCode,
  containerCode,
  currentUser,
  statement: revision,
  show: left === LEFT_PANELS.CHAT_BOT,
  cbFeedbackEnabled,
});

export default connect(mapStateToProps, null)(ChatBotPanel);
