import { connect } from 'react-redux';

import {
  addManualFormulaRow,
  updateRowOperator,
  setFormulaManualNumber,
  removeFormulaRow,
  addFormulaRowOperator,
  removeFormulaRowOperator,
  removeAllFormulaRows,
  setSelectedRowAction,
  setRoundingResultAction,
} from 'store/actions/formula-actions';
import {
  selectElementContentPanel,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import { updateElementCacheByCalloutAction } from 'store/actions/element-cache-actions';

import FormulaPanelEdit from 'components/feature/element-panel/formula-panel/_formula-panel-edit-formula-component';
const mapStateToProps = (
  {
    data: {
      elementPanel: { elementDetails },
      statementContent: { elementCache },
      selectedStatement: { statement },
      formulaPanel: { formula },
    },
  },
  ownProps,
) => ({
  ...ownProps,
  elementDetails,
  formulaInProgress: formula,
  numberFormatId: statement && statement.numberFormatId,
  elementCache,
});
const mapDispatchToProps = {
  addManualRow: addManualFormulaRow,
  updateRowOperator,
  setManualNumber: setFormulaManualNumber,
  removeFormulaRow,
  addOperator: addFormulaRowOperator,
  removeRowOperator: removeFormulaRowOperator,
  removeAllRows: removeAllFormulaRows,
  selectElement: selectElementContentPanel,
  setSelectedRow: setSelectedRowAction,
  deallocateItems,
  setRoundingResult: setRoundingResultAction,
  updateElementCacheByCalloutAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(FormulaPanelEdit);
