import React from 'react';
import BlacklineSubHeader from 'pages/blackline-version/blackline-version-subheader-component';
import BlacklineOCRPreviousPanel from 'pages/blackline-version/blackline-ocr-previous-version-component';

const BlacklineOCRPreviousVersionView = ({ isOCR }) => {
  return (
    <>
      <BlacklineSubHeader
        title={'selected-statement.blackline-banner.previous'}
      />
      {isOCR ? <BlacklineOCRPreviousPanel /> : null}
    </>
  );
};

export default BlacklineOCRPreviousVersionView;
