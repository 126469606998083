import { connect } from 'react-redux';

import StatementProcessing from 'components/feature/statement-processing/statement-processing-component';
import {
  permanentlyDeleteWorkflowRevision,
  retryFailedDocumentUpload,
  clearProjectIdWorkflowExpanded,
} from 'store/actions/statement-workflows-actions';
import { isNullOrUndefined } from 'utils/object-utils';

const mapStateToProps = ({
  data: {
    workflowsMap,
    selectedProject: { id },
  },
  ui: {
    homepage: { projectIdOfWorkflowToExpand },
    statementPage: { statementWorkflowSteps },
  },
}) => ({
  workflowsMap,
  /**
   * set to override the default isOpen logic of the popover, example of use would be a user creates a statement and is navigated back to home page
   * for the project where the user just created a statement, the popover should be open by default
   */
  selectedProjectID: id,
  statementWorkflowSteps,
  expandByDefault:
    !isNullOrUndefined(projectIdOfWorkflowToExpand) &&
    projectIdOfWorkflowToExpand === id,
});

const mapDispatchToProps = {
  permanentlyDeleteWorkflowRevision,
  retryFailedDocumentUpload,
  clearProjectIdWorkflowExpanded,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatementProcessing);
