import { handleActions } from 'redux-actions';

import OCRNavigationModel from 'models/api/ocr-navigation-api-model';
import {
  clearLeftStatementContent,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import {
  leftNavigationDataError,
  leftNavigationDataLoaded,
  leftNavigationDataLoading,
} from 'store/actions/ocr-navigation-actions';

export default handleActions(
  {
    [leftNavigationDataLoading]: (ocrnavigation) => {
      return ocrnavigation.setLoading();
    },
    [leftNavigationDataLoaded]: (ocrnavigation, { payload }) => {
      return ocrnavigation.setLoaded(payload);
    },
    [leftNavigationDataError]: (ocrnavigation, { payload }) => {
      return ocrnavigation.setError(payload);
    },
    [clearLeftStatementContent]: () => {
      return new OCRNavigationModel();
    },
    [clearStatementPageStoreAction]: () => {
      return new OCRNavigationModel();
    },
  },
  new OCRNavigationModel(),
);
