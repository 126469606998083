import React, { memo } from 'react';
import classnames from 'classnames';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { getUserNameByUserData } from 'utils/statement-content-page-utils';

export const USER_GRID_BLOCK = 'user-grid';

const UserGrid = ({ id, userType, users, numOfColumns }) => {
  const cols = [];
  for (let i = 0; i < numOfColumns; i++) {
    cols[i % numOfColumns] = [];
  }
  if (users.length) {
    users.forEach((user, index) => {
      cols[index % numOfColumns].push(user);
    });
  } else {
    cols[0].push({ fullName: 'none' });
  }
  return (
    <div id={id} className={USER_GRID_BLOCK}>
      {cols.map((col, colIndex) => (
        <div
          key={`${colIndex}-col-${id}`}
          className={`${USER_GRID_BLOCK}__col`}
        >
          <div className={`${USER_GRID_BLOCK}__label`}>
            {colIndex === 0 ? <FormattedMessage id={userType} /> : ''}
          </div>
          {col.map((user, cellIndex) => (
            <div
              key={`${cellIndex}-cell-${id}`}
              className={classnames(
                `${USER_GRID_BLOCK}__cell`,
                user.fullName !== 'none' &&
                  (!user.active || !user.enabled) &&
                  `${USER_GRID_BLOCK}__cell--striked`,
              )}
            >
              <MultiLineEllipsis
                toolTipID={`${colIndex}-col-${id}-${user.fullName}`}
                text={
                  user.fullName && user.fullName !== 'none' ? (
                    getUserNameByUserData(user.id, cols[colIndex])
                  ) : (
                    <FormattedMessage id="project-details.body.none" />
                  )
                }
                isNotInternationalized
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

UserGrid.propTypes = {
  /** string representing one of the jobType constants*/
  userType: PropTypes.string,
  /** Array of users of current jobType*/
  users: PropTypes.array,
  /** Number of columns that a should be rendered*/
  numOfColumns: PropTypes.number,
};

export default memo(UserGrid);
