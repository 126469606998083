import { handleActions } from 'redux-actions';
import { setSideBySideSyncScroll } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [setSideBySideSyncScroll]: (state, { payload }) => {
      return payload;
    },
    [clearStatementPageStoreAction]: () => {
      return true;
    },
  },
  true,
);
