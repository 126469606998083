import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { ReactComponent as Information } from 'icons/info.svg';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import { clearModeIfPossible } from 'store/actions/modes-actions';
import {
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
} from 'store/actions/panel-cancel-modal-actions';
import { STATEMENT_CONTENT_BLOCK } from './statement-content-page';
import { cancelBatchUpdatePanel } from 'store/actions/batch-panel-actions';
import { setAnnotationDisplayOption } from 'store/actions/annotation-display-actions';

export const BatchBanner = ({
  selectMode,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  clearBatchModeIfPossible,
  cancelBatchUpdatePanel,
  annotationDisplayOptions,
  setAnnotationDisplayOption,
}) => {
  const [showBatchBanner, setShowBatchBanner] = useState(false);

  const getBannerObject = () => {
    let bannerObj = {};
    switch (selectMode.id) {
      case ELEMENT_SELECT_MODES.BATCH_WITH_BANNER.id:
        bannerObj.title = annotationDisplayOptions.expandAllElements
          ? 'selection-mode-banner-title.expand-all-elements'
          : 'selection-mode-banner-title';
        bannerObj.message = annotationDisplayOptions.expandAllElements
          ? 'selection-mode-banner-message.expand-all-elements'
          : 'selection-mode-banner-message';
        break;
      case ELEMENT_SELECT_MODES.FORMULA.id:
        bannerObj.title = 'batch.selection-mode-banner-title.formula';
        bannerObj.message = 'formula-mode-banner-message';
        break;
      case ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.id:
        bannerObj.title = 'batch.selection-mode-banner-title.ir';
        bannerObj.message = 'internal-reference-mode-banner-message';
        break;
      default:
        bannerObj = null;
    }
    return bannerObj;
  };

  const exitBatchBannerMode = () => {
    switch (selectMode.id) {
      case ELEMENT_SELECT_MODES.BATCH_WITH_BANNER.id:
        cancelBatchUpdatePanel();
        clearBatchModeIfPossible();
        setAnnotationDisplayOption({
          ...annotationDisplayOptions,
          expandAllElements: false,
        });
        break;
      case ELEMENT_SELECT_MODES.FORMULA.id:
        toggleFormulaCancelModal(true);
        break;
      case ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.id:
        toggleInternalReferenceCancelModal(true);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    switch (selectMode.id) {
      case ELEMENT_SELECT_MODES.BATCH_WITH_BANNER.id:
      case ELEMENT_SELECT_MODES.FORMULA.id:
      case ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.id:
        setShowBatchBanner(true);
        break;
      default:
        setShowBatchBanner(false);
    }
  }, [selectMode]);

  return (
    showBatchBanner &&
    getBannerObject() &&
    getBannerObject().title &&
    getBannerObject().message && (
      <div className={`${STATEMENT_CONTENT_BLOCK}__selection-mode-banner`}>
        <div
          className={`${STATEMENT_CONTENT_BLOCK}__selection-mode-banner-header`}
        >
          <Information
            className={`${STATEMENT_CONTENT_BLOCK}__selection-mode-banner-icon`}
          />
          <FormattedMessage
            id={getBannerObject().title}
            values={{
              b: (...chunks) => (
                <b
                  key={`${STATEMENT_CONTENT_BLOCK}-bTag-${shortid.generate()}`}
                  className={`${STATEMENT_CONTENT_BLOCK}__selection-mode-batch-update-info`}
                >
                  {chunks}
                </b>
              ),
            }}
          />
          <div
            className={`${STATEMENT_CONTENT_BLOCK}__selection-mode-exit-button`}
            onClick={exitBatchBannerMode}
          >
            <FormattedMessage id="selection-mode-button-text.exit" />
          </div>
        </div>
        <div
          className={`${STATEMENT_CONTENT_BLOCK}__selection-mode-banner-body`}
        >
          <FormattedMessage id={getBannerObject().message} />
        </div>
      </div>
    )
  );
};

const mapStateToProps = ({
  data: { annotationDisplayOptions },
  ui: {
    statementPage: {
      modes: { selectMode },
    },
  },
}) => ({
  selectMode,
  annotationDisplayOptions,
});

const mapDispatchToProps = {
  clearBatchModeIfPossible: clearModeIfPossible,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  cancelBatchUpdatePanel,
  setAnnotationDisplayOption,
};

BatchBanner.propTypes = {
  // This is an object we are getting from redux store. It contains info about selected mode,i.e Formula or batch etc.
  selectMode: PropTypes.object.isRequired,
  // This is an action method. This is used to show formula cancel modal when passed true as a parameter and hide it when
  // passed false as a parameter.
  toggleFormulaCancelModal: PropTypes.func.isRequired,
  // This is an action method. This is used to show IR cancel modal when passed true as a parameter and hide it when
  // passed false as a parameter.
  toggleInternalReferenceCancelModal: PropTypes.func.isRequired,
  // This is an action method. It is called to exit from batch mode. It changes the mode to default and hence our banner is closed/hidden
  clearBatchModeIfPossible: PropTypes.func.isRequired,
  // This is an action method used to cancel/clean up all batch update related things. Like it will clear the highlight of elements
  //, close batch panel
  cancelBatchUpdatePanel: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchBanner);
