import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import SideBySideStatementNavFlyout from 'pages/side-by-side-compare/side-by-side-toolkits/side-by-side-statement-nav/side-by-side-statement-nav-flyout';
import { onStatementNavSectionSelect } from 'store/actions/statement-content-actions';

const PreviewReportStatementNav = ({
  ocrCurrentViewPageNumber,
  selectedStatement,
  sectionTreeList,
  contentSectionMap,
  sectionIdList,
  currentSectionIds,
  sectionReview,
  statementOCRNavigation,
  showStatementNav,
  _toggleStatementNav,
  navigateToSelectedPage,
  onStatementNavSectionSelect,
}) => {
  const params = useParams();
  return (
    <SideBySideStatementNavFlyout
      show={showStatementNav}
      onSectionClick={onStatementNavSectionSelect}
      sectionTreeList={sectionTreeList}
      contentSectionMap={contentSectionMap}
      sectionIdList={sectionIdList}
      selectedStatement={selectedStatement}
      currentSectionIds={currentSectionIds}
      sectionReview={sectionReview}
      toggleStatementNavPanel={_toggleStatementNav}
      statementOCRNavigationData={statementOCRNavigation}
      navigateToSelectedPage={navigateToSelectedPage}
      projectId={params.projectId}
      ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
    />
  );
};

const mapStateToProps = ({
  data: {
    selectedStatement,
    sectionTreeList,
    statementContent: {
      sectionsCache: { contentSectionMap },
      sectionIdList,
    },
    sectionPanel,
  },
  ui: {
    statementPage: {
      currentSectionIds,
      modes: { ocrCurrentViewPageNumber },
      statementNavigatorPanel: { statementOCRNavigation },
    },
  },
}) => ({
  ocrCurrentViewPageNumber,
  selectedStatement,
  sectionTreeList,
  contentSectionMap,
  sectionIdList,
  currentSectionIds,
  sectionReview: sectionPanel.sectionReviewList,
  statementOCRNavigation,
});

export default connect(mapStateToProps, {
  onStatementNavSectionSelect,
})(PreviewReportStatementNav);
