import { createAction } from 'redux-actions';

import { getElementDetailsRequest } from 'api/element-api';
import ElementDetails from 'models/api/element-details-api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export const updateMultipleElementsInCacheAction = createAction(
  'UPDATE_MULTIPLE_CACHE_ELEMENTS',
);

export const updateElementInCacheAction = createAction(
  'UPDATE_SINGLE_CACHE_ELEMENT',
);

export const updateElementCacheFromSocketPayloadAction = createAction(
  'UPDATE_ELEMENT_CACHE_FROM_SOCKET_PAYLOAD',
);

export const updateElementCacheByCalloutAction = createAction(
  'UPDATE_ELEMENT_CACHE_BY_CALLOUT_ACTION',
);
export const updateLeftElementCacheByCalloutAction = createAction(
  'UPDATE_LEFT_ELEMENT_CACHE_BY_CALLOUT_ACTION',
);

/**
 * This wrapper function should be used when fetching elements from the cache.
 */
export const getElementFromCache =
  ({ elementId, sectionId, loadingAction }) =>
  async (dispatch, getStore) => {
    let elementData = getStore().data.statementContent.elementCache.getElement({
      sectionId,
      elementId,
    });
    if (isNullOrUndefined(elementData)) {
      dispatch(loadingAction());
      const { revision } = getStore().data;
      let elementDetails = new ElementDetails();
      // If we do not have a copy of this element in the cache, then we fetch the element to work with.
      const response = await getElementDetailsRequest({
        elementId,
        revisionId: revision.id,
      });
      elementData = elementDetails.setLoaded({ response });
    }
    return elementData;
  };

export const getMultipleElementsFromCache =
  ({ elements, loadingAction, isSideBySideView }) =>
  async (dispatch, getState) => {
    const dataState = getState().data;
    const { elementCache } = dataState.statementContent;
    const { revision } = dataState;
    let selectedElements = [];
    let initiatedRequest = false;
    elements.forEach(async ({ elementId, sectionId }) => {
      if (!isNullOrUndefined(elementId)) {
        let elementData = elementCache.getElement({ elementId, sectionId });
        if (isNullOrUndefined(elementData)) {
          if (!initiatedRequest) {
            initiatedRequest = true;
            !isSideBySideView && dispatch(loadingAction());
          }
          const response = await getElementDetailsRequest({
            elementId,
            revisionId: revision.id,
          });
          elementData = new ElementDetails().setLoaded({ response });
        }
        selectedElements.push(elementData);
      }
    });

    return selectedElements;
  };
