import { isNullOrUndefined } from 'utils/object-utils';
import ElementSelection from 'models/data/element-selection-model';

const HighlightOptions = {
  padding: 1,
  color: 'rgba(255, 230, 127,0.5)',
  baseColor: 'rgba(160, 220, 255, 0.6)',
  bookends: '#00A3E0',
};

const getMinDistance = (word, point) => {
  let dx = Math.max(word[0] - point.x, 0, point.x - word[4]);
  let dy = Math.max(word[1] - point.y, 0, point.y - word[5]);
  return Math.sqrt(dx * dx + dy * dy);
};

const getClosestWord = (x, y, ocr, metadata) => {
  let closest = {
    min: null,
    content: null,
    polygon: null,
    span: null,
    page: null,
  };
  if (!ocr) {
    return;
  }
  ocr.forEach(({ content, polygon, span }) => {
    const isInside =
      polygon[0] < x && x < polygon[4] && polygon[1] < y && y < polygon[5];
    let distance = 0;
    if (!isInside) {
      distance = getMinDistance(polygon, { x, y });
    }
    if ((closest.min === null || distance < closest.min) && distance < 15) {
      closest = {
        min: distance,
        content: content,
        polygon: polygon,
        span: span,
        page: metadata.sectionId,
      };
    }
  });
  return closest;
};

const getText = (start, end, ocr) => {
  let firstIndex = ocr.findIndex(({ span }) => span.offset === start);
  let secondIndex = ocr.findIndex(({ span }) => span.offset === end);
  let characterRange = ocr.slice(firstIndex, secondIndex + 1);
  let text = '';
  characterRange.forEach(({ content }) => {
    text += content + ' ';
  });
  return { text, characterRange };
};

const textBlockEnds = (firstElement, lastElement, canvas) => {
  //Thickness of bookends is based on the height of the text on the appropriate element
  let w1 = (firstElement.y2 - firstElement.y1) / 5;
  let w2 = (lastElement.y2 - lastElement.y1) / 5;
  const padding = HighlightOptions.padding;
  let ctx = canvas.getContext('2d');
  ctx.beginPath();
  ctx.fillStyle = HighlightOptions.bookends;
  //First bookend
  ctx.fillRect(
    firstElement.x1 - w1,
    firstElement.y1 - padding,
    w1,
    firstElement.y2 - firstElement.y1 + padding * 2,
  );
  ctx.arc(
    firstElement.x1 - w1 + w1 / 2,
    firstElement.y1 - w1 * 1.5,
    w1 * 1.5,
    0,
    2 * Math.PI,
  );
  ctx.fill();
  //Last bookend
  ctx.fillRect(
    lastElement.x2,
    lastElement.y1 - padding,
    w2,
    lastElement.y2 - lastElement.y1 + padding * 2,
  );
  ctx.arc(
    lastElement.x2 + w2 / 2,
    lastElement.y2 + w2 * 1.5,
    w2 * 1.5,
    0,
    2 * Math.PI,
  );
  ctx.fill();
  ctx.closePath();
};

const renderSelection = (lines, canvas) => {
  const context = canvas.getContext('2d');
  context.fillStyle = HighlightOptions.baseColor;
  lines.forEach((line) => {
    let { x1, x2, y1, y2 } = line;
    context.beginPath();
    context.fillRect(
      x1,
      y1 - HighlightOptions.padding,
      x2 - x1,
      y2 - y1 + 2 * HighlightOptions.padding,
    );
    context.stroke();
  });
  if (lines.length) textBlockEnds(lines[0], lines[lines.length - 1], canvas);
};

const processCharacterRangeAsTextLines = (characterRange) => {
  if (characterRange && characterRange.length > 0) {
    let lines = [];
    let currentLine = {
      x1: characterRange[0].polygon[0],
      x2: characterRange[0].polygon[4],
      y1: characterRange[0].polygon[1],
      y2: characterRange[0].polygon[5],
    };
    characterRange.forEach(({ polygon }) => {
      let line_grouping_percentage = 0.5;
      let height = currentLine.y2 - currentLine.y1;
      if (
        polygon[1] >= currentLine.y1 - height * line_grouping_percentage &&
        polygon[5] <= currentLine.y2 + height * line_grouping_percentage
      ) {
        currentLine = {
          x1: currentLine.x1,
          x2: polygon[4],
          y1: currentLine.y1 <= polygon[1] ? currentLine.y1 : polygon[1],
          y2: currentLine.y2 >= polygon[5] ? currentLine.y2 : polygon[5],
        };
      } else {
        lines.push(currentLine);
        currentLine = {
          x1: polygon[0],
          x2: polygon[4],
          y1: polygon[1],
          y2: polygon[5],
        };
      }
    });
    lines.push(currentLine);
    return lines;
  }
  return [];
};

const highlightElement = (context, boundary, style, padding) => {
  if (style) {
    context.fillStyle = style;
  }
  context.fillRect(
    boundary[0],
    boundary[1],
    boundary[2] - boundary[0] + padding,
    boundary[5] - boundary[3],
  );
};

const checkIfPolygonIsInsideAnotherPolygon = (
  parentX1,
  parentY1,
  parentX3,
  parentY3,
  childX1,
  childY1,
  childX3,
  childY3,
  table = false,
) => {
  const minX = Math.min(parentX1, parentX3);
  const maxX = Math.max(parentX1, parentX3);
  const minY = Math.min(parentY1, parentY3);
  const maxY = Math.max(parentY1, parentY3);

  let innerRectangleIntersectsOuterRectangle =
    (childX1 >= minX &&
      childX1 <= maxX &&
      childY1 >= minY &&
      childY1 <= maxY) ||
    (childX3 >= minX &&
      childX3 <= maxX &&
      childY3 >= minY &&
      childY3 <= maxY) ||
    (childX1 >= minX &&
      childX1 <= maxX &&
      childY3 >= minY &&
      childY3 <= maxY) ||
    (childX3 >= minX && childX3 <= maxX && childY1 >= minY && childY1 <= maxY);

  let outerRectangleIntersectsInnerRectangle =
    (minX >= childX1 &&
      minX <= childX3 &&
      minY >= childY1 &&
      minY <= childY3) ||
    (minX >= childX1 &&
      minX <= childX3 &&
      maxY >= childY1 &&
      maxY <= childY3) ||
    (maxX >= childX1 &&
      maxX <= childX3 &&
      minY >= childY1 &&
      minY <= childY3) ||
    (maxX >= childX1 && maxX <= childX3 && maxY >= childY1 && maxY <= childY3);

  let innerRectangleLiesWithinOuterRectangle;

  if (table) {
    const tolerance = 2;
    innerRectangleLiesWithinOuterRectangle =
      childX1 >= parentX1 - tolerance &&
      childY1 >= parentY1 - tolerance &&
      childX3 <= parentX3 + tolerance &&
      childY3 <= parentY3 + tolerance;
  } else {
    innerRectangleLiesWithinOuterRectangle =
      childX1 < maxX && childX3 > minX && childY1 < maxY && childY3 > minY;
  }

  return {
    innerRectangleIntersectsOuterRectangle,
    outerRectangleIntersectsInnerRectangle,
    innerRectangleLiesWithinOuterRectangle,
  };
};

export const checkIsArrayOfArrays = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }
  return arr.every((element) => Array.isArray(element));
};

const selectedElementInBatch = ({
  context,
  elementDetails,
  scale,
  batchUpdateCoordinates = {},
  batchUpdateElements = [],
}) => {
  if (isNullOrUndefined(elementDetails)) {
    return null;
  } else if (
    batchUpdateCoordinates &&
    batchUpdateCoordinates.starting &&
    batchUpdateCoordinates.starting.x &&
    batchUpdateCoordinates.ending.x &&
    batchUpdateCoordinates.starting.y &&
    batchUpdateCoordinates.ending.y
  ) {
    const { coordinatesInPixel } = elementDetails;

    if (coordinatesInPixel && checkIsArrayOfArrays(coordinatesInPixel)) {
      coordinatesInPixel.forEach((coord) => {
        let polygonInPixel = coord.map((childCoord) => {
          return parseFloat(childCoord) * scale;
        });
        const {
          innerRectangleIntersectsOuterRectangle,
          outerRectangleIntersectsInnerRectangle,
          innerRectangleLiesWithinOuterRectangle,
        } = checkIfPolygonIsInsideAnotherPolygon(
          batchUpdateCoordinates.starting.x,
          batchUpdateCoordinates.starting.y,
          batchUpdateCoordinates.ending.x,
          batchUpdateCoordinates.ending.y,
          polygonInPixel[0],
          polygonInPixel[1],
          polygonInPixel[4],
          polygonInPixel[5],
        );
        if (
          innerRectangleIntersectsOuterRectangle ||
          outerRectangleIntersectsInnerRectangle ||
          innerRectangleLiesWithinOuterRectangle
        ) {
          batchUpdateElements.push(
            new ElementSelection({
              elementId: Number.parseInt(elementDetails.id),
              sectionId: Number.parseInt(elementDetails.sectionId),
            }),
          );
        }
      });
    } else if (coordinatesInPixel && coordinatesInPixel.length) {
      let polygonInPixel = coordinatesInPixel.map((coord) => {
        return parseFloat(coord) * scale;
      });
      const {
        innerRectangleIntersectsOuterRectangle,
        outerRectangleIntersectsInnerRectangle,
        innerRectangleLiesWithinOuterRectangle,
      } = checkIfPolygonIsInsideAnotherPolygon(
        batchUpdateCoordinates.starting.x,
        batchUpdateCoordinates.starting.y,
        batchUpdateCoordinates.ending.x,
        batchUpdateCoordinates.ending.y,
        polygonInPixel[0],
        polygonInPixel[1],
        polygonInPixel[4],
        polygonInPixel[5],
      );
      if (
        innerRectangleIntersectsOuterRectangle ||
        outerRectangleIntersectsInnerRectangle ||
        innerRectangleLiesWithinOuterRectangle
      ) {
        batchUpdateElements.push(
          new ElementSelection({
            elementId: Number.parseInt(elementDetails.id),
            sectionId: Number.parseInt(elementDetails.sectionId),
          }),
        );
      }
    }
  }
};

const clearCanvas = (canvas) => {
  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
};

//Function to get mouse coordinates on mouse event
const getMouseCoordinates = (e, overlayCanvas) => {
  const canvas = overlayCanvas.current;
  return {
    x: e.clientX - canvas.getBoundingClientRect().left,
    y: e.clientY - canvas.getBoundingClientRect().top,
  };
};

export {
  getMouseCoordinates,
  checkIfPolygonIsInsideAnotherPolygon,
  clearCanvas,
  getText,
  getClosestWord,
  renderSelection,
  highlightElement,
  processCharacterRangeAsTextLines,
  selectedElementInBatch,
};
