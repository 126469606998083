import React, { memo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

export const BLACK_LINE_VERSION_SUBHEADER_BLOCK = 'blackline-version-subheader';
export const BLACK_LINE_VERSION_SUBHEADER_BLOCK_ID =
  'blackline-version-panel-id';

const BlacklineVersionSubHeader = ({ title, showToggle = false }) => {
  return (
    <div className={`${BLACK_LINE_VERSION_SUBHEADER_BLOCK}__statement-header`}>
      <div
        className={`${BLACK_LINE_VERSION_SUBHEADER_BLOCK}__statement-header--title`}
      >
        <FormattedMessage id={title} />
      </div>
    </div>
  );
};

export default memo(injectIntl(BlacklineVersionSubHeader));
