import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ChevronIcon } from 'icons/down-arrow.svg';
import { PRIMARY_FILTER_CATEGORIES } from 'constants/feature/statement-navigator/statement-nav-element-filter-constants';
import Radio from 'components/common/radio-component';
import ElementFilters from 'models/data/element-filters-model';
import ElementFilterChips from './_statement-nav-element-filters-chips-component';
import ElementFiltersFlaggedTertiary from './statement-nav-element-filters-tertiary-flagged-component';
import ElementFiltersUnFlaggedTertiary from './statement-nav-element-filters-tertiary-unflagged-component';

import PeriodFilters from './_statement-nav-element-filters-period-component';
import SegmentFilters from './_statement-nav-element-filters-statement-segments-component';
import ScalingFilters from './_statement-nav-element-filters-scaling-component';
import UnitFilters from './_statement-nav-element-filters-unit-component';
import EntityFilters from './_statement-nav-element-filters-entity-component';
import ElementFiltersFormulaTertiary from './statement-nav-element-filters-tertiary-formula-component';
import ElementFiltersWPRTertiary from 'components/feature/toolkit/statement-nav/elements/statement-nav-element-filters-tertiary-wpr-component.jsx';
import ElementFiltersTickmarkTertiary from 'components/feature/toolkit/statement-nav/elements/statement-nav-element-filters-tertiary-tickmarks-component';
import ElementFiltersComfortAnnotationTertiary from 'components/feature/toolkit/statement-nav/elements/statement-nav-element-filters-tertiary-comfort-annotation-component';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import ElementFiltersIRTertiary from './statement-nav-element-filters-tertiary-internalReferences-component';
import ElementFiltersStatementPages from './_statement-nav-element-filters-statement-pages-component';

const {
  VERIFY,
  REVIEW,
  FLAG,
  PERIOD,
  SCALING,
  UNIT,
  ENTITY,
  WPR,
  IR,
  TICKMARKS,
  FORMULA,
  STATEMENT_SEGMENTS,
  COMFORT_ASSIGNED,
  COMFORT_ANNOTATIONS,
  PAGES,
} = PRIMARY_FILTER_CATEGORIES;

export const ELEMENT_FILTERS_BLOCK = 'stat-nav-element-filters-modal';
const ELEMENT_FILTERS_ID_BLOCK = 'stat-nav-element-filters-modal-id';

const StatementNavElementFiltersModal = ({
  toggleModal,
  onApply,
  currFilters,
  clearElementFilterResults,
  isOCR,
}) => {
  const [selectedPrimaryFilter, setSelectedPrimaryFitler] = useState(null);

  // init with currFilters from parent, fine because parent should not send updated props after
  // first render
  const [filters, setFilters] = useState(currFilters);

  const FilterCategory = ({ filter, onClick, selectedFilter, children }) => (
    <button
      id={`${filter.id}-category`}
      onClick={onClick}
      className={classnames(
        `${ELEMENT_FILTERS_BLOCK}__filter-category`,
        selectedFilter === filter &&
          `${ELEMENT_FILTERS_BLOCK}__filter-category--selected`,
      )}
    >
      {children ? children : <FormattedMessage id={filter.heading} />}
      <ChevronIcon className={`${ELEMENT_FILTERS_BLOCK}__chevron`} />
    </button>
  );
  const getSecondaryFilterCategories = () => {
    switch (selectedPrimaryFilter) {
      case STATEMENT_SEGMENTS: {
        return (
          <SegmentFilters
            filters={filters}
            setFilters={setFilters}
            className={`${ELEMENT_FILTERS_BLOCK}`}
          />
        );
      }
      case PAGES: {
        return (
          <ElementFiltersStatementPages
            filters={filters}
            setFilters={setFilters}
            className={`${ELEMENT_FILTERS_BLOCK}`}
          />
        );
      }
      case VERIFY: {
        return (
          <Radio
            {...VERIFY}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.verified}
            width="auto"
            onChange={(val) => setFilters(filters.setVerified(val))}
          />
        );
      }
      case REVIEW: {
        return (
          <Radio
            {...REVIEW}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.reviewed}
            width="auto"
            onChange={(val) => setFilters(filters.setReviewed(val))}
          />
        );
      }

      case COMFORT_ASSIGNED: {
        return (
          <Radio
            {...COMFORT_ASSIGNED}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.comfortAssign}
            width="auto"
            onChange={(val) => setFilters(filters.setComfortAssign(val))}
          />
        );
      }
      case FLAG: {
        return (
          <Radio
            {...FLAG}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.parentFlagged}
            width="auto"
            onChange={(val) => setFilters(filters.setParentFlagged(val))}
          />
        );
      }
      case PERIOD: {
        return (
          <PeriodFilters
            filters={filters}
            setFilters={setFilters}
            className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
          />
        );
      }
      case SCALING: {
        return (
          <ScalingFilters
            filters={filters}
            setFilters={setFilters}
            className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
          />
        );
      }
      case UNIT: {
        return (
          <UnitFilters
            filters={filters}
            setFilters={setFilters}
            className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
          />
        );
      }
      case ENTITY: {
        return (
          <EntityFilters
            filters={filters}
            setFilters={setFilters}
            className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
          />
        );
      }
      case WPR: {
        return (
          <Radio
            {...WPR}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.wpReference}
            width="auto"
            onChange={(val) => setFilters(filters.setWPR(val))}
          />
        );
      }
      case IR: {
        return (
          <Radio
            {...IR}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.internalReference}
            width="auto"
            onChange={(val) => setFilters(filters.setIR(val))}
          />
        );
      }
      case TICKMARKS: {
        return (
          <Radio
            {...TICKMARKS}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.tickmarks}
            width="auto"
            onChange={(val) => setFilters(filters.setTickmarks(val))}
          />
        );
      }
      case COMFORT_ANNOTATIONS: {
        return (
          <Radio
            {...COMFORT_ANNOTATIONS}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.comfortLetter}
            width="auto"
            onChange={(val) => setFilters(filters.setComfortLetter(val))}
          />
        );
      }
      case FORMULA: {
        return (
          <Radio
            {...FORMULA}
            className={`${ELEMENT_FILTERS_BLOCK}__radio`}
            isValid
            selectedOption={filters.parentFormula}
            width="auto"
            onChange={(val) => setFilters(filters.setParentFormula(val))}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const getTertiaryFilterCategories = () => {
    switch (selectedPrimaryFilter) {
      case FLAG: {
        if (filters.parentFlagged === true) {
          return (
            <ElementFiltersFlaggedTertiary
              className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
              filters={filters}
              setFilters={setFilters}
            />
          );
        }
        if (filters.parentFlagged === false) {
          return (
            <ElementFiltersUnFlaggedTertiary
              className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
              filters={filters}
              setFilters={setFilters}
            />
          );
        }
        break;
      }
      case FORMULA: {
        if (filters.parentFormula === true) {
          return (
            <ElementFiltersFormulaTertiary
              className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
              filters={filters}
              setFilters={setFilters}
            />
          );
        }
        break;
      }
      case WPR: {
        if (filters.wpReference === true) {
          return (
            <ElementFiltersWPRTertiary
              className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
              filters={filters}
              setFilters={setFilters}
            />
          );
        }
        break;
      }

      case COMFORT_ANNOTATIONS: {
        if (filters.comfortLetter === true) {
          return (
            <ElementFiltersComfortAnnotationTertiary
              className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
              filters={filters}
              setFilters={setFilters}
            />
          );
        }
        break;
      }
      case TICKMARKS: {
        if (filters.tickmarks === true) {
          return (
            <ElementFiltersTickmarkTertiary
              className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
              filters={filters}
              setFilters={setFilters}
            />
          );
        }
        break;
      }
      case IR: {
        if (filters.internalReference) {
          return (
            <ElementFiltersIRTertiary
              className={`${ELEMENT_FILTERS_BLOCK}__checkbox-group`}
              filters={filters}
              setFilters={setFilters}
            />
          );
        }
        break;
      }
      default: {
        return null;
      }
    }
  };
  return (
    <Modal
      id={ELEMENT_FILTERS_ID_BLOCK}
      className={ELEMENT_FILTERS_BLOCK}
      title="statement-nav-panel.tab.elements.filters.modal.title"
      onClose={toggleModal}
      primaryModalButton={
        new ModalButton({
          text: 'common.apply',
          onClick: () => {
            onApply({ filters });
          },
          disabled: !filters.hasFilters(),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: toggleModal,
        })
      }
      tertiaryModalButton={
        new ModalButton({
          text: 'common.clear-all',
          onClick: () => {
            setFilters(new ElementFilters());
            clearElementFilterResults();
          },
        })
      }
    >
      <p className={`${ELEMENT_FILTERS_BLOCK}__body-text`}>
        <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.body" />
      </p>
      <div className={`${ELEMENT_FILTERS_BLOCK}__chips-container`}>
        <ElementFilterChips
          baseId={ELEMENT_FILTERS_ID_BLOCK}
          filters={filters}
          onRemoveChip={setFilters}
          isOCR={isOCR}
        />
      </div>
      <div className={`${ELEMENT_FILTERS_BLOCK}__filters-container`}>
        <div className={`${ELEMENT_FILTERS_BLOCK}__col-1`}>
          <h6 className={`${ELEMENT_FILTERS_BLOCK}__section-title`}>
            <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.col-1.section.statement-segment" />
          </h6>
          {isOCR ? (
            <FilterCategory
              filter={PAGES}
              selectedFilter={selectedPrimaryFilter}
              onClick={() => setSelectedPrimaryFitler(PAGES)}
            />
          ) : (
            <FilterCategory
              filter={STATEMENT_SEGMENTS}
              selectedFilter={selectedPrimaryFilter}
              onClick={() => setSelectedPrimaryFitler(STATEMENT_SEGMENTS)}
            />
          )}
          <h6 className={`${ELEMENT_FILTERS_BLOCK}__section-title`}>
            <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.col-1.section.status" />
          </h6>
          <FilterCategory
            filter={VERIFY}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(VERIFY)}
          />
          <FilterCategory
            filter={REVIEW}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(REVIEW)}
          />
          <FilterCategory
            filter={FLAG}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(FLAG)}
          />
          <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE}>
            <FilterCategory
              filter={COMFORT_ASSIGNED}
              selectedFilter={selectedPrimaryFilter}
              onClick={() => setSelectedPrimaryFitler(COMFORT_ASSIGNED)}
            />
          </FeatureFlagComponent>
          <h6 className={`${ELEMENT_FILTERS_BLOCK}__section-title`}>
            <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.col-1.section.amount-attributes" />
          </h6>
          <FilterCategory
            filter={PERIOD}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(PERIOD)}
          />
          <FilterCategory
            filter={SCALING}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(SCALING)}
          />
          <FilterCategory
            filter={UNIT}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(UNIT)}
          />
          <FilterCategory
            filter={ENTITY}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(ENTITY)}
          />
          <h6 className={`${ELEMENT_FILTERS_BLOCK}__section-title`}>
            <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.col-1.section.annotations" />
          </h6>
          <FilterCategory
            filter={WPR}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(WPR)}
          />
          <FilterCategory
            filter={IR}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(IR)}
          />
          <FilterCategory
            filter={TICKMARKS}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(TICKMARKS)}
          />
          <FilterCategory
            filter={FORMULA}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(FORMULA)}
          />
          <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
            <FilterCategory
              filter={COMFORT_ANNOTATIONS}
              selectedFilter={selectedPrimaryFilter}
              onClick={() => setSelectedPrimaryFitler(COMFORT_ANNOTATIONS)}
            />
          </FeatureFlagComponent>
        </div>
        <div className={`${ELEMENT_FILTERS_BLOCK}__col-2`}>
          {getSecondaryFilterCategories()}
        </div>
        <div className={`${ELEMENT_FILTERS_BLOCK}__col-3`}>
          {getTertiaryFilterCategories()}
        </div>
      </div>
    </Modal>
  );
};

StatementNavElementFiltersModal.propTypes = {
  /** Function fired to close modal */
  toggleModal: PropTypes.func.isRequired,
  /** function fired on click of Apply button */
  onApply: PropTypes.func.isRequired,
  /** Currently applied filters, come from statement nav panel */
  currFilters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Action called to clear element filters */
  clearElementFilterResults: PropTypes.func.isRequired,
  /**flag to check if the statement is of type OCR or html */
  isOCR: PropTypes.bool.isRequired,
};

export default StatementNavElementFiltersModal;
