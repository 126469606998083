import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DynamicTextArea from 'components/common/dynamic-text-area-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as ChatBotSubmitButton } from 'icons/chat-bot-chat-submit-button.svg';
import Tooltip from 'components/common/tool-tip-component';
import classNames from 'classnames';

export const CHATBOT_PANEL_BLOCK = 'chatbot-panel';
export const CHATBOT_PANEL_BLOCK_ID = 'chatbot-panel-id';
export const CHAT_SUBMIT_BUTTON_SIZE = '30px';
const MAX_LENGTH = 500;
const MAX_VISIBLE_ROWS = 3;

export const ChatBotPanelFooter = (
  {
    text,
    show,
    onTextAreaChange,
    showSecondaryMessage,
    setShowSecondaryMessage,
    handleSend,
    messages,
    inputFieldDisable,
    footerRef,
  },
  ref,
) => {
  const disabled =
    text.trim() === '' ||
    (text.length < 3 && text.trim() !== '') ||
    inputFieldDisable;
  return (
    <div
      className={`${CHATBOT_PANEL_BLOCK}__chat-footer-section`}
      ref={footerRef}
    >
      {showSecondaryMessage && (
        <div>
          <div className={`${CHATBOT_PANEL_BLOCK}__chat-input`}>
            <div className={`${CHATBOT_PANEL_BLOCK}__text-area`}>
              <DynamicTextArea
                id={'chat-bot-text-area'}
                ref={ref}
                value={text}
                placeholder={'chatbot-input.placeholder'}
                focusOnMount={show}
                maxLength={MAX_LENGTH}
                onChange={onTextAreaChange}
                maxVisibleRows={MAX_VISIBLE_ROWS}
                disabled={inputFieldDisable}
              />
            </div>

            <Tooltip
              id={`${CHATBOT_PANEL_BLOCK_ID}__button-tooltip`}
              text="chatbot.send.button"
              key={`${CHATBOT_PANEL_BLOCK_ID}__button-tooltip`}
              position={'top'}
            >
              <div className={`${CHATBOT_PANEL_BLOCK}__submit-btn`}>
                <ChatBotSubmitButton
                  width={CHAT_SUBMIT_BUTTON_SIZE}
                  height={CHAT_SUBMIT_BUTTON_SIZE}
                  className={classNames(
                    `${CHATBOT_PANEL_BLOCK}__chat-submit-btn`,
                    {
                      [`${CHATBOT_PANEL_BLOCK}__chat-submit-btn--disabled`]:
                        disabled,
                    },
                  )}
                  onClick={() => !disabled && handleSend()}
                />
              </div>
            </Tooltip>
          </div>
          <div className={`${CHATBOT_PANEL_BLOCK}__word-count`}>
            {text.length}/{MAX_LENGTH}
          </div>
        </div>
      )}
      {!(showSecondaryMessage || messages.length) && (
        <div>
          <Button
            id={'abc'}
            type={BUTTON_TYPES.primary}
            onClick={() => setShowSecondaryMessage(true)}
            className={`${CHATBOT_PANEL_BLOCK}__btn`}
          >
            <FormattedMessage id={'chatbot.understand.button'} />
          </Button>
        </div>
      )}
    </div>
  );
};

ChatBotPanelFooter.propTypes = {
  // text entered in text area component
  text: PropTypes.string,
  // boolean value to show guidance messages
  showSecondaryMessage: PropTypes.bool.isRequired,
  // action to switch b/w guidance messages
  setShowSecondaryMessage: PropTypes.func.isRequired,
};

export default forwardRef(ChatBotPanelFooter);
