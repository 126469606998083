import {
  checkIfPolygonIsInsideAnotherPolygon,
  checkIsArrayOfArrays,
} from 'utils/ocr-text-selection-utils';
const MAX_POLYGON_WIDTH = 5;
const PADDING = 6;

export const getElementBoundaryInfo = (polygonInPixel, extraHeight = 0) => {
  const width = polygonInPixel[2] - polygonInPixel[0];
  const height = polygonInPixel[7] - polygonInPixel[1];

  const polygonWidth = width > MAX_POLYGON_WIDTH ? width : width + PADDING;
  const polygonHeight =
    height > MAX_POLYGON_WIDTH
      ? height + extraHeight
      : height + PADDING + extraHeight;
  const polygonTop =
    height > MAX_POLYGON_WIDTH
      ? polygonInPixel[1]
      : polygonInPixel[1] - PADDING / 2;
  const polygonLeft =
    width > MAX_POLYGON_WIDTH
      ? polygonInPixel[0]
      : polygonInPixel[0] - PADDING / 2;
  return { polygonWidth, polygonHeight, polygonLeft, polygonTop };
};

export const isPolygonExistInElementList = (
  elementList,
  polygonInPixel,
  scaleWithZoom,
) => {
  let isElementFound = false;
  let isPolyExistInElementList = false;
  let elementId = null;
  const elementListLength = elementList.length;
  for (let i = 0; i < elementListLength; i++) {
    const {
      coordinatesInPixel: elementPolygon,
      isIncluded,
      id,
    } = elementList[i];
    const isArrayOfPolygons = checkIsArrayOfArrays(elementPolygon);
    if (isIncluded) {
      if (isArrayOfPolygons) {
        for (let j = 0; j < elementPolygon.length; j++) {
          const outerPolygonInPixel = elementPolygon[j].map((coord) => {
            return parseFloat(coord) * scaleWithZoom;
          });
          const {
            innerRectangleIntersectsOuterRectangle,
            outerRectangleIntersectsInnerRectangle,
          } = checkIfPolygonIsInsideAnotherPolygon(
            outerPolygonInPixel[0],
            outerPolygonInPixel[1],
            outerPolygonInPixel[4],
            outerPolygonInPixel[5],
            polygonInPixel[0],
            polygonInPixel[1],
            polygonInPixel[4],
            polygonInPixel[5],
          );
          isElementFound =
            innerRectangleIntersectsOuterRectangle ||
            outerRectangleIntersectsInnerRectangle;
          if (isElementFound) {
            isPolyExistInElementList = true;
            elementId = id;
            break;
          }
        }
        break;
      } else {
        const outerPolygonInPixel = elementPolygon.map((coord) => {
          return parseFloat(coord) * scaleWithZoom;
        });
        const {
          innerRectangleIntersectsOuterRectangle,
          outerRectangleIntersectsInnerRectangle,
        } = checkIfPolygonIsInsideAnotherPolygon(
          outerPolygonInPixel[0],
          outerPolygonInPixel[1],
          outerPolygonInPixel[4],
          outerPolygonInPixel[5],
          polygonInPixel[0],
          polygonInPixel[1],
          polygonInPixel[4],
          polygonInPixel[5],
        );
        isElementFound =
          innerRectangleIntersectsOuterRectangle ||
          outerRectangleIntersectsInnerRectangle;
        if (isElementFound) {
          isPolyExistInElementList = true;
          elementId = id;
          break;
        }
      }
    }
  }
  return { isElement: isPolyExistInElementList, elementId };
};
