import { handleActions } from 'redux-actions';
import {
  setBlacklineSelectedElementId,
  clearBlacklineSelectedElementId,
} from 'store/actions/blackline-view-actions';

export default handleActions(
  {
    [setBlacklineSelectedElementId]: (prevState, { payload }) => {
      return payload.type === 'Deleted'
        ? { ...prevState, deleted: payload.id, addedOrChanged: null }
        : { ...prevState, addedOrChanged: payload.id, deleted: null };
    },
    [clearBlacklineSelectedElementId]: () => {
      return { deleted: null, addedOrChanged: null };
    },
  },
  { deleted: null, addedOrChanged: null },
);
