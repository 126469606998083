import { handleActions } from 'redux-actions';
import {
  setDisplayOptionAction,
  setIsComfortLetterEnabledAction,
} from 'store/actions/statement-summary/statement-summary-actions';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [setDisplayOptionAction]: (statementSumDisplayOption, { payload }) => {
      return statementSumDisplayOption.setDisplayOption(payload);
    },
    [setIsComfortLetterEnabledAction]: (
      statementSumDisplayOption,
      { payload },
    ) => {
      return statementSumDisplayOption.setIsComfortLetterEnabled(payload);
    },
    [clearStatementPageStoreAction]: () => {
      return new StatementSummaryDisplayOptions();
    },
  },
  new StatementSummaryDisplayOptions(),
);
