import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'components/common/tool-tip-component';
import SummaryElements from 'models/api/statement-summary-elements-model';
import {
  verifiedElementFilter,
  reviewedElementFilter,
  unverifiedElementFilter,
} from 'constants/feature/statement-summary-constants';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';
import classnames from 'classnames';

export const STATEMENT_SUMMARY_ELEMENTS_BLOCK = 'statement-summary-elements';

const StatementSummaryElementsComponent = ({
  showElementListPanel,
  summaryElements,
  displayOptions,
  disabled,
}) =>
  (displayOptions.verified ||
    displayOptions.reviewed ||
    displayOptions.unverified) && (
    <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}`}>
      {displayOptions.unverified && (
        <Tooltip
          id={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-unverified__tooltip`}
          text={
            disabled
              ? null
              : 'statement-summary.elements-unverified.label-tooltip'
          }
          position="bottom"
        >
          <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}--button-block`}>
            <button
              className={classnames(
                `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button`,
                `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--unverified`,
                {
                  [`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--disabled`]:
                    disabled,
                },
              )}
              disabled={disabled}
              onClick={() => showElementListPanel(unverifiedElementFilter)}
            >
              <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__label`}>
                {displayOptions.percentage &&
                  `${summaryElements.unverifiedPercentage}%`}
                {displayOptions.count && ` (${summaryElements.unverified})`}
              </div>
            </button>
          </div>
        </Tooltip>
      )}
      {displayOptions.verified && (
        <Tooltip
          id={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-verified__tooltip`}
          text={
            disabled
              ? null
              : 'statement-summary.elements-verified.label-tooltip'
          }
          position="bottom"
        >
          <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}--button-block`}>
            <button
              className={classnames(
                `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button`,
                `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--verified`,
                {
                  [`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--disabled`]:
                    disabled,
                },
              )}
              disabled={disabled}
              onClick={() => showElementListPanel(verifiedElementFilter)}
            >
              <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__label`}>
                {displayOptions.percentage &&
                  `${summaryElements.verifiedPercentage}%`}
                {displayOptions.count && ` (${summaryElements.verified})`}
              </div>
            </button>
          </div>
        </Tooltip>
      )}
      {displayOptions.reviewed && (
        <Tooltip
          id={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-reviewed`}
          text={
            disabled
              ? null
              : 'statement-summary.elements-reviewed.label-tooltip'
          }
          position="bottom"
        >
          <div>
            <button
              className={classnames(
                `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button`,
                `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--reviewed`,
                {
                  [`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--disabled`]:
                    disabled,
                },
              )}
              disabled={disabled}
              onClick={() => showElementListPanel(reviewedElementFilter)}
            >
              <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__label`}>
                {displayOptions.percentage &&
                  `${summaryElements.reviewedPercentage}%`}
                {displayOptions.count && ` (${summaryElements.reviewed})`}
              </div>
            </button>
          </div>
        </Tooltip>
      )}
    </div>
  );

StatementSummaryElementsComponent.propTypes = {
  /** Action fired when button is clicked to open element list panel */
  showElementListPanel: PropTypes.func.isRequired,
  /** The count of elements for the selected revision id */
  summaryElements: PropTypes.instanceOf(SummaryElements).isRequired,
  /** Obj containing display options from store */
  displayOptions: PropTypes.instanceOf(StatementSummaryDisplayOptions)
    .isRequired,
};

export default StatementSummaryElementsComponent;
