import { createAction } from 'redux-actions';
import {
  getStatementWorkflowsRequest,
  retryFailedDocumentUploadRequest,
  permanentlyDeleteFailedRevisionRequest,
} from 'api/statement-list-api';
import { STATEMENT_LIST_TABS } from 'constants/feature/statement-list-constants';
import { requestGetStatementList } from './statement-list/statement-list-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import { updateSelectedProject } from 'store/actions/selected-project-actions';

export const clearStatementWorkflows = createAction(
  'CLEAR_STATEMENT_WORKFLOWS',
);

export const setProjectIdWorkflowExpanded = createAction(
  'SET_PROJECT_FOR_WORKFLOWS_EXPANDED',
);
export const clearProjectIdWorkflowExpanded = createAction(
  'CLEAR_PROJECT_FOR_WORKFLOWS_EXPANDED',
);

export const statementWorkflowsLoading = createAction(
  'STATEMENT_WORKFLOWS_LOADING',
);
export const statementWorkflowsError = createAction(
  'STATEMENT_WORKFLOWS_LIST_ERROR',
);
export const statementWorkflowsLoaded = createAction(
  'STATEMENT_WORKFLOWS_LIST_LOADED',
);
export const permanentlyDeleteWorkflowRevisionError = createAction(
  'PERMANENTLY_DELETE_WORKFLOW_REVISION_ERROR',
);
export const retryFailedDocumentUploadError = createAction(
  'RETRY_FAILED_DOCUMENT_UPLOAD_ERROR',
);

//This gets called on the first request for workflows so a loading graphic is rendered.
// The next two actions will need to be replaced when sockets are implemented.
export const requestGetStatementWorkflows = (props) => async (dispatch) => {
  dispatch(statementWorkflowsLoading());
  dispatch(getStatementWorkflowsWithoutLoading(props));
};

//This is so we can poll for workflows without rendering a loading screen.
export const getStatementWorkflowsWithoutLoading =
  (props) => async (dispatch, getState) => {
    try {
      const selectedProject = getState().data.selectedProject.project;
      /**
       * First we get the current workflow map from the store, which we will compare later on
       * to check for changes in the workflows. If there are any kind of changes, we will have to try to
       * update the statement list.
       *  */
      const { statementWorkflowSteps } = getState().ui.statementPage;
      const response =
        selectedProject && selectedProject.id
          ? await getStatementWorkflowsRequest(selectedProject.id)
          : { data: [] };
      const previousWorkFlowMap = getState().data.workflowsMap;
      dispatch(statementWorkflowsLoaded({ response }));
      const updatedWorkflowMap = getState().data.workflowsMap;
      const selectedStatementsTab =
        getState().data.statementList.statementListFilters.selectedTab;
      if (
        !isNullOrUndefined(previousWorkFlowMap.getInitializedSize()) &&
        !isNullOrUndefined(updatedWorkflowMap.getInitializedSize()) &&
        previousWorkFlowMap.getInitializedSize() !==
          updatedWorkflowMap.getInitializedSize()
      ) {
        dispatch(requestGetStatementList({ withLoading: false }));
        dispatch(
          updateSelectedProject({
            projectId: selectedProject.id,
          }),
        );
      }
      if (!updatedWorkflowMap.isInitialized()) {
        dispatch(clearStatementWorkflows());
      }
      if (updatedWorkflowMap) {
        updatedWorkflowMap.getWorkflowsListData().forEach((workflow) => {
          if (
            workflow.status ===
            statementWorkflowSteps.isCompletedWorkFlowStep(
              workflow.documentProcessTypeEnum,
            ).status
          ) {
            if (selectedStatementsTab === STATEMENT_LIST_TABS.ACTIVE)
              dispatch(requestGetStatementList({ withLoading: false }));
            dispatch(clearStatementWorkflows());
          }
        });
      }
    } catch (error) {
      dispatch(statementWorkflowsError(error));
    }
  };

export const permanentlyDeleteWorkflowRevision =
  (statementId, revisionId) => async (dispatch) => {
    try {
      await permanentlyDeleteFailedRevisionRequest(statementId, revisionId);
      dispatch(requestGetStatementWorkflows());
    } catch (error) {
      dispatch(permanentlyDeleteWorkflowRevisionError(error));
    }
  };

export const retryFailedDocumentUpload =
  (statementId, revisionId, clientId) => async (dispatch) => {
    try {
      await retryFailedDocumentUploadRequest(statementId, revisionId, clientId);
      dispatch(requestGetStatementWorkflows());
    } catch (error) {
      dispatch(retryFailedDocumentUploadError(error));
    }
  };
