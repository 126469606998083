import React from 'react';
import StatementContentPanelComponentHOC from 'containers/feature/statement-content/statement-content-panel-container';

const LeftSideHTMLStatementPreview = ({ params, statementRef, isContentPanelScrolling }) => {
  return (
    <StatementContentPanelComponentHOC
      urlParams={params}
      centerPanelRef={statementRef}
      isContentPanelScrolling={isContentPanelScrolling}
      leftSideBySideView={false}
      markerDisplayValue={false}
    />
  );
};

export default LeftSideHTMLStatementPreview;
