import { handleActions } from 'redux-actions';
import {
  resetFirstElementVisibleInViewPort,
  setFirstElementVisibleInViewPort,
} from 'store/actions/preview-report-page-actions';

export default handleActions(
  {
    [setFirstElementVisibleInViewPort]: (elementId, { payload }) => {
      return payload;
    },
    [resetFirstElementVisibleInViewPort]: () => null,
  },
  null,
);
