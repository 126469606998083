import React, { Component } from 'react';
import ElementDetails from 'models/api/element-details-api-model';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import AmountAttributeDetails from 'components/common/element-amount-attributes-details-component';
import CreateTickmarkModal from 'components/feature/element-panel/tickmark-panel/create-tickmark-modal';
import AttachTickmark from 'components/feature/element-panel/tickmark-panel/_element-tickmark-panel-attach-component';
import AlreadyAttached from 'components/feature/element-panel/tickmark-panel/_element-tickmark-panel-already-attached-component';
import ElementTickmarkList from 'models/api/element-tickmark-list-model';
import Permissions from 'permissions/permissions';
import { createElementTickmarkRequest } from 'api/tickmark-api';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';

const ELEMENT_TICKMARK_PANEL_BLOCK = 'element-tickmark-panel';
const ELEMENT_TICKMARK_PANEL_ID_BLOCK = 'element-tickmark-panel-id';

class ElementTickmarkPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateTickmarkModal: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { closeTickmarkPanel, elementDetails, showTickmarkPanel } =
      this.props;

    if (!showTickmarkPanel && !nextProps.showTickmarkPanel) {
      // save some performance by only re-rendering during instances where the the content should be displayed
      return false;
    }
    const _clickedNewElementInContentPanel =
      elementDetails.id !== null &&
      nextProps.elementDetails.id !== elementDetails.id;
    if (_clickedNewElementInContentPanel) {
      // close the panel when a new element has been clicked before the component re-renders
      closeTickmarkPanel();
    }

    return true;
  }

  _toggleCreateTickmarkModal = () => {
    this.setState((state) => ({
      showCreateTickmarkModal: !state.showCreateTickmarkModal,
    }));
  };

  _handleCreateNewTickmark = async ({ tickmarkModel }) => {
    const { elementDetails, updateElementCacheByCalloutAction } = this.props;
    await createElementTickmarkRequest({ tickmarkModel });
    const { onCreateTickmark, socketHasBeenDisconnected } = this.props;
    socketHasBeenDisconnected && onCreateTickmark();
    this._toggleCreateTickmarkModal();
    updateElementCacheByCalloutAction({
      elementIds: [elementDetails.id],
      showCallout: true,
    });
  };

  _onSearchTickmark = ({ search }) => {
    const { elementDetails, onSearchTickmark } = this.props;
    return onSearchTickmark({
      revisionId: elementDetails.revisionId,
      elementId: elementDetails.id,
      search,
    });
  };

  render() {
    const {
      showTickmarkPanel,
      closeTickmarkPanel,
      elementDetails,
      tickmarkList,
      onAttachTickmark,
      onDetachTickmark,
      selectedProjectId,
      deallocateItems,
      selectElementContentPanel,
    } = this.props;
    const { showCreateTickmarkModal } = this.state;
    const _permissionToAttach =
      Permissions.Tickmark.canEditTickmark(selectedProjectId);
    return (
      <>
        <Flyout
          className={`${ELEMENT_TICKMARK_PANEL_BLOCK}__flyout`}
          show={showTickmarkPanel}
          isAnimated
        >
          {showTickmarkPanel && (
            <>
              <FlyoutHeader enableBack onBack={closeTickmarkPanel}>
                <h3 className={`${ELEMENT_TICKMARK_PANEL_BLOCK}__title`}>
                  <FormattedMessage
                    id={'element-tickmark-panel.header.title'}
                  />
                </h3>
                <div
                  className={`${ELEMENT_TICKMARK_PANEL_BLOCK}__selected-value`}
                >
                  <FormattedMessage id="common.selected-value" />
                </div>
                <button
                  id={`${ELEMENT_TICKMARK_PANEL_ID_BLOCK}-element-value`}
                  className={`${ELEMENT_TICKMARK_PANEL_BLOCK}__element-value`}
                  onClick={() => {
                    scrollElementIntoView({
                      elementId: elementDetails.id,
                      sectionId: elementDetails.sectionId,
                    });
                    deallocateItems();
                    selectElementContentPanel({
                      elementIds: [elementDetails.id],
                      color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
                    });
                  }}
                >
                  {elementDetails.display}
                </button>
                <AmountAttributeDetails
                  className={`${ELEMENT_TICKMARK_PANEL_BLOCK}__amount-attributes`}
                  elementDetails={elementDetails}
                />
              </FlyoutHeader>
              <FlyoutPanelContent
                className={`${ELEMENT_TICKMARK_PANEL_BLOCK}__flyout-content`}
              >
                <AlreadyAttached
                  tickmarkList={tickmarkList}
                  onDetachTickmark={onDetachTickmark}
                  disabled={!_permissionToAttach}
                />
                <AttachTickmark
                  onCreate={this._toggleCreateTickmarkModal}
                  onAttach={onAttachTickmark}
                  onSearch={this._onSearchTickmark}
                  disabled={!_permissionToAttach}
                />
              </FlyoutPanelContent>
            </>
          )}
        </Flyout>
        {showCreateTickmarkModal && (
          <CreateTickmarkModal
            onClose={this._toggleCreateTickmarkModal}
            onCreate={this._handleCreateNewTickmark}
            elementDetails={elementDetails}
          />
        )}
      </>
    );
  }
}

ElementTickmarkPanel.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** List of tickmarks associated with this element */
  tickmarkList: PropTypes.instanceOf(ElementTickmarkList).isRequired,
  /**A boolean indicating if we should show the tickmark panel or not. */
  showTickmarkPanel: PropTypes.bool.isRequired,
  /**The action that is fired when we need to hide the tickmark panel. */
  closeTickmarkPanel: PropTypes.func.isRequired,
  /**The action that is fired when a user attaches a tickmark from search results */
  onAttachTickmark: PropTypes.func.isRequired,
  /**The action that is fired when a user detaches a tickmark from attached tickmarks */
  onDetachTickmark: PropTypes.func.isRequired,
  /** Currently selected project id */
  selectedProjectId: PropTypes.string.isRequired,
  /* Action to be fired when an element from the list selected */
  selectElementContentPanel: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /**This value will indicate if socket at statement level is connected  */
  socketHasBeenDisconnected: PropTypes.bool,
  /* set element id for tickmark added */
  updateElementCacheByCalloutAction: PropTypes.func.isRequired,
};

export default ElementTickmarkPanel;
