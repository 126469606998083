import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as SideBySideIcon } from 'icons/compare.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import SelectStatement from './_select-statement-tab';
import SelectRevisionTab from './_select-revision-tab';
import { setSourceStatementParams } from 'store/actions/source-statement-actions';
import { connect } from 'react-redux';
import LeftSideStatementView from 'pages/side-by-side-compare/left-side/_left-side-statement-view.jsx';
import { TAB_NAME } from 'constants/util/side-by-side-utils';
import classNames from 'classnames';
import { updateSelectedProjectFromIDIfDoesNotExists } from 'store/actions/selected-project-actions';

export const LEFT_PANEL_BLOCK = 'left-panel';
export const LEFT_PANEL_BLOCK_ID = 'left-panel-id';

const LeftSideView = ({
  setSourceStatementParams,
  loadSourceStatement,
  isSwapped,
  onConfirmStatement,
  selectedTab,
  setSelectedTab,
  selectedProject,
  updateSelectedProjectFromIDIfDoesNotExists,
  setLeftStatementNavPanel,
  leftStatementNavPanel,
}) => {
  const [disabled, setDisabled] = useState(true);
  const noClass = 'undefined';
  const leftActive =
    selectedTab === TAB_NAME.LEFT ? `${LEFT_PANEL_BLOCK}__active` : noClass;
  const rightActive =
    selectedTab === TAB_NAME.RIGHT ? `${LEFT_PANEL_BLOCK}__active` : noClass;
  const isButtonDisabled = (isDisabled) => setDisabled(isDisabled);

  const onSelectTab = (tabname) => {
    setSelectedTab(tabname);
  };

  const setSourceStatementParamsValues = (sourceStatementParams) => {
    setSourceStatementParams(sourceStatementParams);
  };

  return (
    <div className={`${LEFT_PANEL_BLOCK} ${LEFT_PANEL_BLOCK}__split`}>
      {loadSourceStatement ? (
        <LeftSideStatementView
          loadSourceStatement={loadSourceStatement}
          isSwapped={isSwapped}
          onConfirmStatement={onConfirmStatement}
          setLeftStatementNavPanel={setLeftStatementNavPanel}
          leftStatementNavPanel={leftStatementNavPanel}
        />
      ) : (
        <>
          <div className={`${LEFT_PANEL_BLOCK}__subheader`}></div>
          <div
            className={classNames(
              `${LEFT_PANEL_BLOCK}__wrap`,
              `${LEFT_PANEL_BLOCK}__container__left`,
            )}
          >
            <div className={`${LEFT_PANEL_BLOCK}__section`}>
              <div className={`${LEFT_PANEL_BLOCK}__icon`}>
                <SideBySideIcon width={30} height={30} />
              </div>
              <div className={`${LEFT_PANEL_BLOCK}__title`}>
                <FormattedMessage id="side-by-side-view.compare.left-panel.title" />
              </div>
              <p className={`${LEFT_PANEL_BLOCK}__subtitle`}>
                <FormattedMessage id="side-by-side-view.compare.left-panel.subtitle" />
              </p>
              <div className={`${LEFT_PANEL_BLOCK}__tab`}>
                <div
                  className={`${LEFT_PANEL_BLOCK}__left-tab ${leftActive}`}
                  onClick={() => onSelectTab(TAB_NAME.LEFT)}
                >
                  <FormattedMessage id="side-by-side-view.compare.left-panel.left-tab" />
                </div>
                <div
                  className={`${LEFT_PANEL_BLOCK}__right-tab ${rightActive}`}
                  onClick={() => onSelectTab(TAB_NAME.RIGHT)}
                >
                  <FormattedMessage id="side-by-side-view.compare.left-panel.right-tab" />
                </div>
              </div>
              {selectedTab === TAB_NAME.RIGHT ? (
                <SelectStatement
                  selectedProjectData={selectedProject}
                  isButtonDisabled={isButtonDisabled}
                  setSourceStatementParamsValues={
                    setSourceStatementParamsValues
                  }
                />
              ) : (
                <SelectRevisionTab
                  isButtonDisabled={isButtonDisabled}
                  setSourceStatementParamsValues={
                    setSourceStatementParamsValues
                  }
                  updateSelectedProjectFromIDIfDoesNotExists={
                    updateSelectedProjectFromIDIfDoesNotExists
                  }
                />
              )}
              <Button
                id={`${LEFT_PANEL_BLOCK_ID}-save`}
                className={`${LEFT_PANEL_BLOCK}__save`}
                onClick={onConfirmStatement}
                type={BUTTON_TYPES.primary}
                disabled={disabled}
              >
                <FormattedMessage id="side-by-side-view.compare.button.confirm" />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ data: { selectedProject } }) => ({
  selectedProject: selectedProject.project,
});

const mapDispatchToProps = {
  setSourceStatementParams,
  updateSelectedProjectFromIDIfDoesNotExists,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideView);
