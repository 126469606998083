import axios from 'axios';
import { getGeo, CONTEXT_KEY } from 'api/api-client';
import {
  createMSALInstance,
  _getAuthTokenForRequestInterceptors,
} from 'api/api-authentication';
import moment from 'moment';

let docChatClient = {};

Object.keys(window.TIEOUT.ENV.GEOS).forEach(
  (geoKey) =>
    (docChatClient[geoKey] = {
      api: null,
      flags: {
        authenticated: false,
      },
    }),
);

const _createDocChatAPI = ({ geoKey }) => {
  /** axios instace for valid geoKey */
  docChatClient[geoKey].api = axios.create({
    baseURL: window.TIEOUT.ENV.GEOS[geoKey].DOC_CHAT_URL,
    /* Add all custom API settings we will need here. */
  });
  createMSALInstance();
};

const getDocChatAPI = ({ geoKey, apiURL }) => {
  if (!(docChatClient[geoKey] && docChatClient[geoKey].api)) {
    _createDocChatAPI({ geoKey, apiURL });
  }
  return docChatClient[geoKey].api;
};

export const getDocChatApiClient = () => {
  const geoKey = getGeo({
    contextKey: CONTEXT_KEY.SELECTED_PROJECT_CONTEXT,
  });
  return getDocChatAPI({ geoKey });
};

export const getAPIClient = (geoKey) => {
  if (!(docChatClient[geoKey] && docChatClient[geoKey].api)) {
    _createDocChatAPI(geoKey);
  }
  return docChatClient[geoKey];
};

export const getDocChatHeaders = async () => {
  const geoKey = getGeo({
    contextKey: CONTEXT_KEY.SELECTED_PROJECT_CONTEXT,
  });
  const config = {};
  const token = await _getAuthTokenForRequestInterceptors(geoKey);
  config.Authorization = `Bearer ${token}`;
  return config;
};
