import React from 'react';
import { ReactComponent as DisplayEyeIcon } from 'icons/show-elements-on-summary-bar.svg';
import { ReactComponent as HideEyeIcon } from 'icons/hide-elements-on-summary-bar.svg';
import classnames from 'classnames';
import Tooltip from 'components/common/tool-tip-component';

const STATEMENT_SUMMARY_ELEMENTS_DISPLAY_BLOCK =
  'statement-summary-elements-display';
const STATEMENT_SUMMARY_ELEMENTS_DISPLAY_BLOCK_ID =
  'statement-summary-elements-display-id';

const STATEMENT_SUMMARY_ELEMENTS_DISPLAY_EYE_ICON_SIZE = 30;

const StatementSummaryElementsDisplay = ({
  setHideOrDisplayElementsOnStatementPage,
  displayElementDetailsOnStatementPage,
  disabled,
}) => (
  <div
    className={STATEMENT_SUMMARY_ELEMENTS_DISPLAY_BLOCK}
    onClick={() =>
      setHideOrDisplayElementsOnStatementPage({
        displayValue: !displayElementDetailsOnStatementPage,
      })
    }
  >
    <Tooltip
      id={STATEMENT_SUMMARY_ELEMENTS_DISPLAY_BLOCK_ID}
      text={
        disabled
          ? null
          : displayElementDetailsOnStatementPage
          ? 'statement-summary.elements-eye-hide-all.label-tooltip'
          : 'statement-summary.elements-eye-show-all.label-tooltip'
      }
      position="bottom"
    >
      <div
        className={classnames(
          `${STATEMENT_SUMMARY_ELEMENTS_DISPLAY_BLOCK}--icon`,
          {
            [`${STATEMENT_SUMMARY_ELEMENTS_DISPLAY_BLOCK}--icon--disabled`]:
              disabled,
          },
        )}
      >
        {displayElementDetailsOnStatementPage ? (
          <HideEyeIcon
            width={STATEMENT_SUMMARY_ELEMENTS_DISPLAY_EYE_ICON_SIZE}
            height={STATEMENT_SUMMARY_ELEMENTS_DISPLAY_EYE_ICON_SIZE}
          />
        ) : (
          <DisplayEyeIcon
            width={STATEMENT_SUMMARY_ELEMENTS_DISPLAY_EYE_ICON_SIZE}
            height={STATEMENT_SUMMARY_ELEMENTS_DISPLAY_EYE_ICON_SIZE}
          />
        )}
      </div>
    </Tooltip>
  </div>
);

export default StatementSummaryElementsDisplay;
