import { handleActions } from 'redux-actions';
import {
  appendSectionToSectionReviewList,
  fetchSectionReviewsError,
  fetchSectionReviewsLoaded,
  removeSectionReviewFromStore,
  fetchSectionReviewsLoading,
  mergeSectionReviewToSectionReviewList,
  removeBulkSectionReviewFromStore,
} from 'store/actions/sections-actions';
import { SectionReviewList } from 'models/api/section-review-list-api-model';

export default handleActions(
  {
    [fetchSectionReviewsLoaded]: (SectionReviewList, { payload }) => {
      return SectionReviewList.setLoaded(payload);
    },
    [fetchSectionReviewsLoading]: (SectionReviewList) => {
      return SectionReviewList.setLoading();
    },
    [appendSectionToSectionReviewList]: (SectionReviewList, { payload }) => {
      return SectionReviewList.appendSectionReview(payload);
    },
    [mergeSectionReviewToSectionReviewList]: (
      SectionReviewList,
      { payload },
    ) => {
      return SectionReviewList.mergeSectionsReviewList(payload);
    },
    [removeSectionReviewFromStore]: (SectionReviewList, { payload }) => {
      return SectionReviewList.removeSectionReview(payload);
    },
    [removeBulkSectionReviewFromStore]: (SectionReviewList, { payload }) => {
      return SectionReviewList.removeBulkSectionReview(payload);
    },
    [fetchSectionReviewsError]: (SectionReviewList, { payload }) => {
      return SectionReviewList.setError(payload);
    },
  },
  new SectionReviewList(),
);
