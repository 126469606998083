import { getStore } from 'store/store';
import {
  STATEMENT_ELEMENT_ID_PREFIX,
  STATEMENT_PSEUDO_ELEMENT_ID_PREFIX,
  REACT_SECTION_ID_PREFIX,
  REACT_LEFT_STATEMENT_SECTION_ID_PREFIX,
  OCR_SECTION_ID,
} from 'constants/feature/statement-content-constants';
import { isNullOrUndefined } from './object-utils';
import { getElementDetailsRequest } from 'api/element-api';

export const smoothScrollIntoCenterById = (id) => {
  document.getElementById(id).scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  });
};
export const smoothScrollIntoTopById = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
};
export const smoothScrollWithOffset = (offset, elementTop) => {
  const bodyTop = document.body.getBoundingClientRect().top;
  const elementPosition = elementTop - bodyTop;
  const offsetPosition = elementPosition - offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};
const _scrollToSectionWithMutationObserver = ({
  mutationCallback,
  sectionId,
  currentSelectorToScrollTo,
  isOCR,
}) => {
  const sectionName = isOCR ? OCR_SECTION_ID : REACT_SECTION_ID_PREFIX;
  const sectionNodeId = `${sectionName}${sectionId}`;

  // element not rendered, scroll to section
  smoothScrollIntoCenterById(sectionNodeId);

  // Keeping track of last scrollTo elementId so that we can detect outdated calls
  window.__currentSelectorToScrollTo = currentSelectorToScrollTo;

  // Options to observer childList changes only
  const config = { attributes: false, childList: true, subtree: false };

  // Disconnect any existing mutation observers
  if (window.__scrollToElementObserver)
    window.__scrollToElementObserver.disconnect();

  // Create an observer instance linked to the callback function to detect when details are loaded
  window.__scrollToElementObserver = new MutationObserver(mutationCallback);

  // Start observing the target node for configured mutations
  window.__scrollToElementObserver.observe(
    document.getElementById(sectionNodeId),
    config,
  );

  //Ensure we're disconnecting the observer after 30 seconds in case still observing
  setTimeout(() => {
    window.__scrollToElementObserver.disconnect();
  }, 30000);
};

export const immediateScrollToSectionWhenLoaded = ({ sectionId }) => {
  const sectionNodeId = `${REACT_SECTION_ID_PREFIX}${sectionId}`;

  // Options to observer childList changes only
  const config = { attributes: false, childList: true, subtree: false };

  // Disconnect any existing mutation observers
  if (window.__scrollToSectionObserver)
    window.__scrollToSectionObserver.disconnect();

  // Create an observer instance linked to the callback function to detect when details are loaded
  window.__scrollToSectionObserver = new MutationObserver(
    (mutationsList, observer) => {
      if (
        mutationsList.some(
          //if section is among the newly added nodes
          (mutation) =>
            mutation.addedNodes.length > 0 &&
            mutation.addedNodes[0].id === sectionNodeId,
        )
      ) {
        document.getElementById(
          'statement-content-panel-id',
        ).parentNode.scrollTop =
          document.getElementById(sectionNodeId).offsetTop;

        observer.disconnect();
      }
    },
  );

  // Start observing the target node for configured mutations
  window.__scrollToSectionObserver.observe(
    document.getElementById('statement-content-panel-zoom-id'),
    config,
  );

  // Ensure we're disconnecting the observer after 30 seconds in case still observing
  setTimeout(() => {
    window.__scrollToSectionObserver.disconnect();
  }, 30000);
};

export const scrollSectionIntoView = (sectionId) => {
  const sectionNodeId = `${REACT_SECTION_ID_PREFIX}${sectionId}`;
  const section = document.getElementById(sectionNodeId);
  if (section) {
    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
};

export const scrollLeftSectionIntoView = (sectionId) => {
  const sectionNodeId = `${REACT_LEFT_STATEMENT_SECTION_ID_PREFIX}${sectionId}`;
  const section = document.getElementById(sectionNodeId);
  if (section) {
    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
};

export const scrollSelectorIntoView = ({ selector, sectionId }) => {
  const store = getStore();
  const storeState = store.getState();
  const { sectionsInView } = storeState.ui.statementPage;
  const {
    selectedStatement: { isOCR },
  } = storeState.data;

  const sectionName = isOCR ? OCR_SECTION_ID : REACT_SECTION_ID_PREFIX;
  const sectionNodeId = `${sectionName}${sectionId}`;

  const fullSelector = `#${sectionNodeId} ${selector}`;

  let foundSelection = document.querySelector(fullSelector);

  const scrollToContent = (selection) => {
    if (!isNullOrUndefined(selection)) {
      selection.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    } else {
      console.error(`Invalid selector (${fullSelector})`);
    }
  };

  if (sectionsInView.has(sectionId) && !isNullOrUndefined(foundSelection)) {
    // element is already rendered, scroll directly to it
    scrollToContent(foundSelection);
  } else {
    _scrollToSectionWithMutationObserver({
      sectionId,
      currentSelectorToScrollTo: fullSelector,
      mutationCallback: (mutationsList, observer) => {
        const newFoundSelecion = document.querySelector(fullSelector);
        const _shouldScrollToElement =
          window.__currentSelectorToScrollTo === fullSelector &&
          !isNullOrUndefined(newFoundSelecion);

        if (_shouldScrollToElement) {
          scrollToContent(newFoundSelecion);

          window.__currentSelectorToScrollTo = null;
          observer.disconnect();
        }
      },
    });
  }
};

export const scrollOCRSelectorIntoView = ({ selector, sectionId }) => {
  const store = getStore();
  const storeState = store.getState();
  const { sectionsInView } = storeState.ui.statementPage;
  const {
    selectedStatement: { isOCR },
  } = storeState.data;

  const searchContent = 'statement-content-search-id';

  const fullSelector = `${searchContent}-${selector}`;

  let foundSelection = document.querySelector(fullSelector);

  const scrollToContent = (selection) => {
    if (!isNullOrUndefined(selection)) {
      selection.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    } else {
      console.error(`Invalid selector (${fullSelector})`);
    }
  };

  if (sectionsInView.has(sectionId) && !isNullOrUndefined(foundSelection)) {
    // element is already rendered, scroll directly to it
    scrollToContent(foundSelection);
  } else {
    _scrollToSectionWithMutationObserver({
      sectionId,
      currentSelectorToScrollTo: fullSelector,
      mutationCallback: (mutationsList, observer) => {
        const newFoundSelecion = document.querySelector(fullSelector);
        const _shouldScrollToElement =
          window.__currentSelectorToScrollTo === fullSelector &&
          !isNullOrUndefined(newFoundSelecion);

        if (_shouldScrollToElement) {
          scrollToContent(newFoundSelecion);

          window.__currentSelectorToScrollTo = null;
          observer.disconnect();
        }
      },
      isOCR,
    });
  }
};

export const scrollElementIntoView = ({ elementId, sectionId }) => {
  const store = getStore();
  const storeState = store.getState();
  const { sectionsInView } = storeState.ui.statementPage;
  const {
    selectedStatement: { isOCR },
  } = storeState.data;

  const elementNodeId = `${STATEMENT_ELEMENT_ID_PREFIX}${elementId}`;

  if (sectionsInView.has(sectionId) && document.getElementById(elementNodeId)) {
    // element is already rendered, scroll directly to it
    smoothScrollIntoCenterById(elementNodeId);
  } else {
    _scrollToSectionWithMutationObserver({
      sectionId,
      currentSelectorToScrollTo: elementNodeId,
      mutationCallback: (mutationsList, observer) => {
        const _shouldScrollToElement =
          window.__currentSelectorToScrollTo === elementNodeId &&
          document.getElementById(elementNodeId);

        if (_shouldScrollToElement) {
          smoothScrollIntoCenterById(elementNodeId);

          window.__currentSelectorToScrollTo = null;
          observer.disconnect();
        }
      },
      isOCR,
    });
  }
};

export const scrollElementIntoViewAlertOnMissing = async ({
  elementId,
  sectionId,
  missingElementCallback,
}) => {
  const store = getStore();
  const storeState = store.getState();
  const { sectionsInView } = storeState.ui.statementPage;
  const {
    selectedStatement: { isOCR },
  } = storeState.data;

  const elementNodeId = `${STATEMENT_ELEMENT_ID_PREFIX}${elementId}`;

  if (sectionsInView.has(sectionId) && document.getElementById(elementNodeId)) {
    //Scroll directly to element if rendered already
    smoothScrollIntoCenterById(elementNodeId);
  } else {
    let element;
    const { revision } = storeState.data;
    //getElementDetailsRequest contains details about the element such as the carryforward status
    await getElementDetailsRequest({
      elementId,
      revisionId: revision.id,
    }).then((res) => (element = res.data.result));

    if (element.carryForwardStatus === 4) missingElementCallback();

    _scrollToSectionWithMutationObserver({
      sectionId,
      currentSelectorToScrollTo: elementNodeId,
      mutationCallback: (mutationsList, observer) => {
        const _shouldScrollToElement =
          window.__currentSelectorToScrollTo === elementNodeId &&
          document.getElementById(elementNodeId);

        if (_shouldScrollToElement) {
          smoothScrollIntoCenterById(elementNodeId);

          window.__currentSelectorToScrollTo = null;
          observer.disconnect();
        }
      },
      isOCR,
    });
  }
};

export const scrollNoteIntoView = ({ pseudoElementId, sectionId }) => {
  const store = getStore();
  const storeState = store.getState();
  const { sectionsInView } = storeState.ui.statementPage;
  const {
    selectedStatement: { isOCR },
  } = storeState.data;

  const pseudoElementNodeId = `${STATEMENT_PSEUDO_ELEMENT_ID_PREFIX}${pseudoElementId}`;

  if (!sectionsInView.has(sectionId)) {
    _scrollToSectionWithMutationObserver({
      sectionId,
      currentSelectorToScrollTo: pseudoElementNodeId + '_0',
      mutationCallback: (mutationsList, observer) => {
        const _shouldScrollToPseudoElement =
          window.__currentSelectorToScrollTo === pseudoElementNodeId + '_0' &&
          document.getElementById(pseudoElementNodeId + '_0');
        if (_shouldScrollToPseudoElement) {
          smoothScrollIntoCenterById(pseudoElementNodeId + '_0');
          window.__currentSelectorToScrollTo = null;
          observer.disconnect();
        }
      },
      isOCR,
    });
  } else if (document.getElementById(pseudoElementNodeId + '_0')) {
    smoothScrollIntoCenterById(pseudoElementNodeId + '_0');
  } else if (document.getElementById(pseudoElementNodeId)) {
    smoothScrollIntoCenterById(pseudoElementNodeId);
  }
};

export function scrollIntoViewForVirtualizedList(listId, itemHeight, index) {
  document.getElementById(listId).scrollTop = itemHeight * index;
}

// this functions returns the most visible element currently visible in the viewport
//this works for scrolling functionalities that needs to know that information to change the UI
// it receives an array of HTML elements and returns the id of the most visible element.
// took it from https://stackoverflow.com/questions/38360676/get-the-element-which-is-the-most-visible-on-the-screen
export const getMostVisible = (elements) => {
  let element,
    viewportHeight = window.innerHeight,
    max = 0,
    current;
  elements.forEach((elem, index) => {
    let visiblePx = getVisibleHeightPx(elem, viewportHeight);

    if (visiblePx > max) {
      max = visiblePx;
      element = elem;
      current = index;
    }
  });

  return element ? elements[current].id : null;
};

export const getVisibleHeightPx = (element, viewportHeight) => {
  let rect = element.getBoundingClientRect(),
    height = rect.bottom - rect.top,
    visible = {
      top: rect.top >= 0 && rect.top < viewportHeight,
      bottom: rect.bottom > 0 && rect.bottom < viewportHeight,
    },
    visiblePx = 0;

  if (visible.top && visible.bottom) {
    // Whole element is visible
    visiblePx = height;
  } else if (visible.top) {
    visiblePx = viewportHeight - rect.top;
  } else if (visible.bottom) {
    visiblePx = rect.bottom;
  } else if (height > viewportHeight && rect.top < 0) {
    let absTop = Math.abs(rect.top);

    if (absTop < height) {
      // Part of the element is visible
      visiblePx = height - absTop;
    }
  }

  return visiblePx;
};
