import { handleActions } from 'redux-actions';
import { setHighlightedElementIdContentPanel } from 'store/actions/statement-content-actions';
import {
  toggleBlacklineViewMode,
  clearBlacklineSelectedElementId,
} from 'store/actions/blackline-view-actions';

export default handleActions(
  {
    [setHighlightedElementIdContentPanel]: (elementId, { payload }) => {
      return payload;
    },
    [toggleBlacklineViewMode]: () => {
      return null;
    },
    [clearBlacklineSelectedElementId]: () => {
      return null;
    },
  },
  null,
);
