import ApiModel from 'models/api-model';
import { ANNOTATION_POSITION_TYPES } from 'constants/feature/annotation-elements-constants';

export default class AnnotationPositionUpdate extends ApiModel({
  data: {
    annotationPosition: '',
    position: 'left',
  },
}) {
  get annotationPosition() {
    return this.data.annotationPosition;
  }

  get position() {
    return this.data.position;
  }

  setAnnotationPosition({ position }) {
    const displayPosition = Object.keys(ANNOTATION_POSITION_TYPES).filter(
      (key) => ANNOTATION_POSITION_TYPES[key] === position.id,
    )[0];
    return this.merge({
      data: {
        ...this.data,
        annotationPosition: position,
        position: displayPosition.toLowerCase(),
      },
    });
  }
}
