import React from 'react';
import PropTypes from 'prop-types';
import ElementFilters from 'models/data/element-filters-model';
import SourceElementFilter from './statement-nav-source-element-filters-component';

export const TERTIARY_IR_ID_BLOCK = 'tertiary-IR-filters-id';

const ElementFiltersIRTertiary = ({ filters, setFilters, className }) => {
  return (
    <div className={className}>
      <SourceElementFilter
        className={className}
        automatedChecked={filters.automatedInternalReferenceFlag}
        automatedOnChange={() =>
          setFilters(
            filters.setIRAutomated(!filters.automatedInternalReferenceFlag),
          )
        }
        manualChecked={filters.manualInternalReferenceFlag}
        manualOnChange={() =>
          setFilters(filters.setIRManual(!filters.manualInternalReferenceFlag))
        }
      />
    </div>
  );
};

ElementFiltersIRTertiary.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default ElementFiltersIRTertiary;
