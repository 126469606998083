import { getStatementWorkflowStepsMetaData } from 'api/statement-api';
import { createAction } from 'redux-actions';

export const setStatementWorkflowStepsLoading = createAction(
  'SET_STATEMENT_WORKFLOWS_STEPS_LOADING',
);
export const setStatementWorkflowStepsLoaded = createAction(
  'SET_STATEMENT_WORKFLOWS_STEPS_LOADED',
);
export const setStatementWorkflowStepsError = createAction(
  'SET_STATEMENT_WORKFLOWS_STEPS_ERROR',
);

export const getStatementWorkflowStepsRequest =
  () => async (dispatch, getState) => {
    dispatch(setStatementWorkflowStepsLoading());
    try {
      const response = await getStatementWorkflowStepsMetaData();
      dispatch(setStatementWorkflowStepsLoaded(response));
    } catch (e) {
      dispatch(setStatementWorkflowStepsError(e));
    }
  };
