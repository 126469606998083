import { handleActions } from 'redux-actions';

import {
  setStatementWorkflowStepsError,
  setStatementWorkflowStepsLoaded,
  setStatementWorkflowStepsLoading,
} from 'store/actions/statement-workflows-steps-actions';
import { StatementWorkflowStepsData } from 'models/api/statement-workflow-steps-model';

export default handleActions(
  {
    [setStatementWorkflowStepsLoading]: (
      statementWorkflowSteps,
      { payload },
    ) => {
      return statementWorkflowSteps.setLoading(payload);
    },
    [setStatementWorkflowStepsLoaded]: (
      statementWorkflowSteps,
      { payload },
    ) => {
      return statementWorkflowSteps.setLoaded(payload);
    },
    [setStatementWorkflowStepsError]: (statementWorkflowSteps, { payload }) => {
      return statementWorkflowSteps.setError(payload);
    },
  },
  new StatementWorkflowStepsData(),
);
