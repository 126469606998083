import React from 'react';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as FullWidthIcon } from 'icons/fullscreen-increase.svg';
import { ReactComponent as FixedWidthIcon } from 'icons/fullscreen-decrease.svg';
import classnames from 'classnames';

const ExpandAll = ({
  BLOCK,
  contentFullMode,
  setContentFixedMode,
  setContentFullMode,
  disabled,
}) => {
  const ContentViewModeIcon = contentFullMode ? FixedWidthIcon : FullWidthIcon;
  return (
    <div
      className={classnames(`${BLOCK}__container`, [
        `${BLOCK}__container__expand`,
      ])}
    >
      <Tooltip
        id={`${BLOCK}__content-view-mode`}
        position={contentFullMode ? 'left' : 'bottom'}
        text={
          disabled
            ? null
            : contentFullMode
            ? 'global-header.content-view-mode.restore'
            : 'common.expand'
        }
        key={
          contentFullMode
            ? `${BLOCK}__content-view-mode--restore`
            : `${BLOCK}__content-view-mode--expand`
        }
      >
        <ContentViewModeIcon
          className={classnames(`${BLOCK}__content-view-mode-button`, {
            [`${BLOCK}__content-view-mode-button--disabled`]: disabled,
          })}
          role="button"
          onClick={
            !disabled && contentFullMode
              ? setContentFixedMode
              : setContentFullMode
          }
        />
      </Tooltip>
    </div>
  );
};

export default ExpandAll;
