import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export default class StatementSummarySections extends ApiModel({
  data: {
    REVIEWED: 0,
    TOTAL: 0,
  },
}) {
  get reviewed() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.REVIEWED;
  }
  get total() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.TOTAL;
  }

  processResponse({ response }) {
    return {
      data: response.data,
    };
  }

  reviewedPercentage() {
    return this.total === 0
      ? 0
      : ((this.reviewed * 100) / this.total).toFixed(0) || null;
  }

  updateSectionSummaryFromSocketPayload(payload) {
    const { headingsReviewed, headingsCount } = payload;
    return this.mergeData({
      REVIEWED: this.data.REVIEWED + headingsReviewed,
      TOTAL: this.data.TOTAL + headingsCount,
    });
  }
}
