import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FlyoutHeader } from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { toggleChatBotPanel } from 'store/actions/panel-controller-actions';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import { useIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import Button, { BUTTON_TYPES } from 'components/common/button-component';

export const CHATBOT_PANEL_BLOCK = 'chatbot-panel';
export const CHATBOT_PANEL_BLOCK_ID = 'chatbot-panel-id';
export const ICON_SIZE = '18px';
const CLOSE_ICON_DIMENSION_SIZE = '20';

export const ChatBotPanelHeader = ({
  onCancelPanel,
  setShowSecondaryMessage,
  clearSessionModal,
  setClearSessionModal,
  setIndexingCompleted,
  setText,
}) => {
  const intl = useIntl();

  const tooltipContent = () => {
    const mainTitle = intl.formatMessage({
      id: 'chatbot.header.tooltip.main.header',
    });
    const purposeTitle = intl.formatMessage({
      id: 'chatbot.header.tooltip.purpose.header',
    });
    const purposeDescription = intl.formatMessage({
      id: 'chatbot.header.tooltip.purpose.description',
    });
    const internalUseTitle = intl.formatMessage({
      id: 'chatbot.header.tooltip.internal.header',
    });
    const internalUseDescription = intl.formatMessage({
      id: 'chatbot.header.tooltip.internal.description',
    });
    const servicesTitle = intl.formatMessage({
      id: 'chatbot.header.tooltip.services.header',
    });
    const servicesDescription1 = intl.formatMessage({
      id: 'chatbot.header.tooltip.services.description.one',
    });
    const servicesDescription2 = intl.formatMessage({
      id: 'chatbot.header.tooltip.services.description.two',
    });
    const servicesDescription3 = intl.formatMessage({
      id: 'chatbot.header.tooltip.services.description.three',
    });
    const servicesDescription4 = intl.formatMessage({
      id: 'chatbot.header.tooltip.services.description.four',
    });
    const servicesDescription5 = intl.formatMessage({
      id: 'chatbot.header.tooltip.services.description.five',
    });
    const outputTitle = intl.formatMessage({
      id: 'chatbot.header.tooltip.output.header',
    });
    const outputDescription = intl.formatMessage({
      id: 'chatbot.header.tooltip.output.description',
    });

    return `<div class="tooltip-text-container">
      <div class="main-title">
        ${mainTitle}
      </div>
      <div class="title-description">

        <div class="section">
          <div class="heading">${purposeTitle}</div>
          <div>
          ${purposeDescription}
          </div>
        </div>
        <div class="section">
          <div class="heading">${internalUseTitle}</div>
          <p>
          ${internalUseDescription}
          </p>
        </div>
        <div class="section">
          <div class="heading">${servicesTitle}</div>
          <ul>
            <li>${servicesDescription1}</li>
            <li>${servicesDescription2}</li>
            <li>${servicesDescription3}</li>
            <li>${servicesDescription4}</li>
            <li>${servicesDescription5}</li>
          </ul>
        </div>
        <div class="section"> 
          <div class="heading">${outputTitle}</div>
          <p>
          ${outputDescription}
          </p>
        </div>
      </div>
    </div>`;
  };

  const onCloseChatBotPanel = () => {
    onCancelPanel();
    setShowSecondaryMessage(false);
    setIndexingCompleted(true);
    setText('');
  };

  return (
    <FlyoutHeader
      enableClose
      onClose={onCloseChatBotPanel}
      CLOSE_ICON_DIMENSION={CLOSE_ICON_DIMENSION_SIZE}
    >
      <div className={`${CHATBOT_PANEL_BLOCK}__header`}>
        <h3 className={`${CHATBOT_PANEL_BLOCK}__title`}>
          <FormattedMessage id={'chatbot-panel.title'} />
          <>
            <Information
              data-tip
              data-for="customTooltip"
              name={'information'}
              className={`${CHATBOT_PANEL_BLOCK}__info_icon`}
              width={ICON_SIZE}
              height={ICON_SIZE}
            />

            {ReactDOM.createPortal(
              <ReactTooltip
                className={'chatbot-tooltip'}
                id="customTooltip"
                html={true}
                place="right"
                effect="solid"
                getContent={tooltipContent}
              />,
              document.getElementById('tooltip-root'),
            )}
          </>
        </h3>
        <Button
          id={`${CHATBOT_PANEL_BLOCK_ID}-clear-session`}
          className={`${CHATBOT_PANEL_BLOCK}__clear-session-btn`}
          type={BUTTON_TYPES.tertiary}
          onClick={() => setClearSessionModal(true)}
          isTooltipRequiredForDisabled
        >
          <FormattedMessage id={'chatbot-input.clearSession'} />
        </Button>
      </div>
    </FlyoutHeader>
  );
};

ChatBotPanelHeader.propTypes = {
  // action method fired to close toolkit-export flyout panel
  onCancelPanel: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onCancelPanel: toggleChatBotPanel,
};

export default connect(null, mapDispatchToProps)(ChatBotPanelHeader);
