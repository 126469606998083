import { createOrUpdateElementWorkpaperRequest } from 'api/workpaper-api';
import {
  getElementWorkpapersAndUpdateElementCache,
  elementWorkpaperListError,
} from 'store/actions/element-panel-actions';
import {
  fetchWorkpaperListForRevisionWithoutLoading,
  fetchElementsFromWorkpaper,
} from 'store/actions/workpaper-toolkit-list-actions';
import { updateElementCacheByCalloutAction } from './element-cache-actions';

export const updateWorkpaper =
  ({ workpaper }) =>
  async (dispatch, getState) => {
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    try {
      const storeData = getState().data;
      const { elementDetails } = storeData.elementPanel;
      let workpaperModel = workpaper;
      // If Workpaper search list doesn't come with the elementId then
      // assume it is not copy annotation and take it from store
      if (typeof workpaperModel.data.elementId !== 'number') {
        workpaperModel.data.elementId = elementDetails.id;
      }
      await createOrUpdateElementWorkpaperRequest({
        workpaperModel,
      });
      if (socketHasBeenDisconnected) {
        await dispatch(getElementWorkpapersAndUpdateElementCache());
        await dispatch(
          fetchWorkpaperListForRevisionWithoutLoading({
            revisionId: storeData.revision.id,
          }),
        );
        dispatch(
          fetchElementsFromWorkpaper({
            revisionId: workpaper.revisionId,
            wpRefId: workpaper.workpaperRefId,
          }),
        );
      }
      dispatch(
        updateElementCacheByCalloutAction({
          elementIds: [elementDetails.id],
          showCallout: true,
        }),
      );
    } catch (error) {
      dispatch(elementWorkpaperListError(error));
    }
  };
