import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Page from 'components/util/page-component';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import ConditionalRender from 'components/util/conditional-render-component';
import StatementEditInfo from 'components/feature/statement-edit/statement-edit-statement-info-component';

import selectedProjectApi from 'api/selected-project-api';
import statementCreateEditApi from 'api/statement-creation-edit-api';

import { ReactComponent as BackButton } from 'icons/back-button.svg';
import StatementEditCancelModal from 'components/feature/statement-edit/statement-edit-cancel-modal';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import StatementForm from 'models/form/statement-form-model';
import ProjectApi from 'models/api/project-api-model';
import { history } from 'store/store';

const STATEMENT_EDIT_PAGE_BLOCK = 'statement-edit';
const STATEMENT_EDIT_PAGE_ID_BLOCK = 'statement-edit-id';

class StatementEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: new ProjectApi(),
      statementInProgress: new StatementForm(),
      statementHasBeenModified: false,
      showCancelModal: false,
    };
    this.unBlockNavigation = null;
    this.shouldBlockNavigation = true; // instance variable for indicating if navigation away from page should be blocked
  }

  _fetchStatementEditInitData = async () => {
    const {
      projectId,
      statementId,
      updateSelectedProjectFromIDIfDoesNotExists,
    } = this.props;
    await updateSelectedProjectFromIDIfDoesNotExists({ projectId });
    this._fetchSelectedProjectForEdit(projectId);
    this._fetchSelectedStatementForEdit(statementId);
  };

  componentDidMount() {
    this._fetchStatementEditInitData();
    /** Handles logic for blocking navigation with modal prompt and redux clean up */
    this.unBlockNavigation = history.block((toLocation) => {
      if (
        this.shouldBlockNavigation &&
        toLocation &&
        toLocation.pathname !== ROUTE_CONSTANTS.SESSION_TIMEOUT
      ) {
        this.setState({ showCancelModal: true });
        return false;
      }
      return true;
    });
    window.addEventListener('beforeunload', this._blockUrlNavigation);
  }
  componentWillUnmount() {
    this.unBlockNavigation();
    window.removeEventListener('beforeunload', this._blockUrlNavigation);
  }

  _blockUrlNavigation = (e) => {
    if (this.shouldBlockNavigation) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  _fetchSelectedProjectForEdit = async (projectId) => {
    this.setState((state) => ({
      project: state.project.setLoading(),
    }));
    try {
      const response = await selectedProjectApi.getSelectedProjectRequest(
        projectId,
      );
      this.setState((state) => ({
        project: state.project.setLoaded(response),
      }));
    } catch (error) {
      this.setState((state) => ({
        project: state.project.setError(error),
      }));
    }
  };

  _fetchSelectedStatementForEdit = async (statementId) => {
    this.setState((state) => ({
      statementInProgress: state.statementInProgress.setLoading(),
    }));
    try {
      const response = await statementCreateEditApi.getSelectedStatementRequest(
        statementId,
        this.props.projectId,
      );
      this.setState((state) => ({
        statementInProgress: state.statementInProgress.setLoaded({ response }),
      }));
    } catch (error) {
      this.setState((state) => ({
        statementInProgress: state.statementInProgress.setError(error),
      }));
    }
  };

  _toggleCancelModal = () => {
    this.setState((state) => {
      return {
        showCancelModal: !state.showCancelModal,
      };
    });
  };

  _onCancelAndLeavePage = () => {
    const { push } = this.props;
    this.shouldBlockNavigation = false;
    push(ROUTE_CONSTANTS.HOME_PAGE);
  };

  componentDidUpdate(prevProps, prevState) {
    const { statementInProgress, statementHasBeenModified } = this.state;
    if (
      prevState.statementInProgress.isInitialized() &&
      statementInProgress.hasStatementBeenModified(
        prevState.statementInProgress,
      ) &&
      !statementHasBeenModified
    ) {
      // Set the statement as having been modified if it has been changed at all
      this.setState({
        statementHasBeenModified: true,
      });
    }
  }

  _editStatement = async () => {
    const { statementInProgress, project } = this.state;
    this.setState((state) => ({
      statementInProgress: state.statementInProgress.setLoading(),
    }));
    try {
      const response = await statementCreateEditApi.editStatementRequest(
        statementInProgress,
        project,
      );
      this.setState((state) => ({
        statementInProgress: state.statementInProgress.setLoaded({ response }),
      }));
      this.shouldBlockNavigation = false;
      this.props.finishStatementEdit({
        project: project.data,
      });
    } catch (error) {
      this.setState((state) => ({
        statementInProgress: state.statementInProgress.setError(error),
      }));
    }
  };

  render() {
    const {
      project,
      statementInProgress,
      showCancelModal,
      statementHasBeenModified,
    } = this.state;
    return (
      <Page>
        <ConditionalRender dependencies={[project, statementInProgress]}>
          <React.Fragment>
            <div className={STATEMENT_EDIT_PAGE_BLOCK}>
              <div className={`${STATEMENT_EDIT_PAGE_BLOCK}__header`}>
                <div>
                  <Button.IconButton
                    id={`${STATEMENT_EDIT_PAGE_ID_BLOCK}__back-button`}
                    className={`${STATEMENT_EDIT_PAGE_BLOCK}__back-button`}
                    type={BUTTON_TYPES.icon}
                    Icon={BackButton}
                    onClick={() => this._toggleCancelModal()}
                  >
                    <div className={`${STATEMENT_EDIT_PAGE_BLOCK}__back-title`}>
                      {project.name}
                    </div>
                  </Button.IconButton>
                  <h1 className={`${STATEMENT_EDIT_PAGE_BLOCK}__page-title`}>
                    <FormattedMessage id={'statement-edit.title'} />
                  </h1>
                </div>
                <div
                  className={`${STATEMENT_EDIT_PAGE_BLOCK}__button-container`}
                >
                  <Button
                    id={`${STATEMENT_EDIT_PAGE_ID_BLOCK}-cancel-button`}
                    className={`${STATEMENT_EDIT_PAGE_BLOCK}__cancel-button`}
                    type={BUTTON_TYPES.secondary}
                    onClick={this._toggleCancelModal}
                  >
                    <FormattedMessage id={'common.cancel'} />
                  </Button>
                  <Button
                    id={`${STATEMENT_EDIT_PAGE_ID_BLOCK}-save-button`}
                    className={`${STATEMENT_EDIT_PAGE_BLOCK}__save-button`}
                    type={BUTTON_TYPES.primary}
                    onClick={this._editStatement}
                    disabled={
                      !statementHasBeenModified ||
                      !statementInProgress.isStepTwoValid()
                    }
                  >
                    <FormattedMessage id={'common.save'} />
                  </Button>
                </div>
              </div>
              <StatementEditInfo
                statementInProgress={statementInProgress}
                setStatementName={(name) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setStatementName(name),
                  }))
                }
                setLanguageType={(lang) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setLanguageType(lang),
                  }))
                }
                setDateFormat={(date) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setDateFormat(date),
                  }))
                }
                setNumberFormat={(number) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setNumberFormat(number),
                  }))
                }
                setStatementType={(statementType) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setStatementType(statementType),
                  }))
                }
                setQuarter={(val) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setQuarter(val),
                  }))
                }
                setFiscalYear={(year) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setFiscalYear(year),
                  }))
                }
                setPeriodEndingDate={(date) => {
                  if (date instanceof moment) {
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setPeriodEndingDate(date),
                    }));
                  }
                }}
                setAccountingBasis={(option) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setAccountingBasis(option),
                  }))
                }
                setRegistrant={(option) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setRegistrant(option),
                  }))
                }
                setWrapUpDate={(date) => {
                  if (date instanceof moment) {
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setWrapUpDate(date),
                    }));
                  }
                }}
                setLegalHold={(checked) =>
                  this.setState((state) => ({
                    statementInProgress: state.statementInProgress.setLegalHold(
                      checked ? 1 : 0,
                    ),
                  }))
                }
                setAutoIdentifyIrs={(checked) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setAutoIdentifyIrs(
                        checked ? 1 : 0,
                      ),
                  }))
                }
                setComfortLetter={(checked) =>
                  this.setState((prevState) => ({
                    statementInProgress:
                      prevState.statementInProgress.setComfortLetter(
                        checked ? 1 : 0,
                      ),
                  }))
                }
                setDefaultScaling={(option) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setDefaultScaling(option),
                  }))
                }
                setDefaultUnit={(option) =>
                  this.setState((state) => ({
                    statementInProgress:
                      state.statementInProgress.setDefaultUnit(option),
                  }))
                }
              />
            </div>
            {showCancelModal ? (
              <StatementEditCancelModal
                toggleCancelModal={this._toggleCancelModal}
                onCancelAndLeavePage={this._onCancelAndLeavePage}
              />
            ) : null}
          </React.Fragment>
        </ConditionalRender>
      </Page>
    );
  }
}
StatementEdit.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
  /** String id of the project associated with the statement that is going to be edited */
  projectId: PropTypes.string.isRequired,
  /** String id of the statement that is going to be edited */
  statementId: PropTypes.string.isRequired,
  /** Func that fires action which sets the project on the homepage following statement editing */
  finishStatementEdit: PropTypes.func.isRequired,
};

export default StatementEdit;
