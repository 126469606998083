import React, { useEffect, useState } from 'react';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'components/common/checkbox-component';
import Loading from '../../../common/loading-component';
import InternalReference from '../../../../models/api/internal-reference-model';
import PropTypes from 'prop-types';
import TickmarkEntry from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-component';
import Switch from 'react-switch';
import {
  getRelatedTickmarks,
  getRelatedWorkPapers,
  WITHOUT_DUPLICATES,
} from 'utils/copy-annotation-to-ir-utils';
import BatchElementUpdateForm from 'models/form/batch-element-update-form-model';
import { connect } from 'react-redux';
import { batchUpdateElementRequest } from 'api/element-api';
import { handleBatchUpdateSuccess } from 'store/actions/batch-panel-actions';
import { LEFT_PANELS } from '../../../../constants/feature/panel-constants';
import WorkpaperToolkitListPanel from 'models/api/workpaper-toolkit-list-panel-api-model';
import { fetchWorkpaperListForRevision } from 'store/actions/workpaper-toolkit-list-actions';
import { fetchTickmarkListForRevision } from 'store/actions/tickmark-list-panel-actions';
import TickmarkList from '../../../../models/api/tickmark-list-api-model';
import SelectedStatement from 'models/api/selected-statement-model';

const COPY_ANNOTATION_IR_LINKED_ID = 'batch-panel-confirm-modal-id';
const COPY_ANNOTATION_IR_LINKED_BLOCK = 'batch-panel-confirm-modal';

export const ShowLinkedAnnotations = ({
  onClickCancel,
  internalReference,
  leftPanelWorkpaper,
  leftPanelTickmark,
  revision,
  workpaperToolkit,
  socketHasBeenDisconnected,
  tickmarkPanel,
  selectedStatement,
}) => {
  const [copyAnnotation, toggleCopyAnnotation] = useState(true);
  const [verifyLinkedReference, toggleVerifyLinkedReference] = useState(true);

  const [workPapers, setworkPapers] = useState(
    getRelatedWorkPapers(internalReference, WITHOUT_DUPLICATES),
  );

  const [tickmarks, setTickmarks] = useState(
    getRelatedTickmarks(internalReference, WITHOUT_DUPLICATES),
  );

  const elementIds = [];
  internalReference &&
    internalReference.data &&
    internalReference.data.internalReferenceElements &&
    internalReference.data.internalReferenceElements.length > 0 &&
    internalReference.data.internalReferenceElements.forEach((ir) =>
      elementIds.push(ir.elementId),
    );

  const [count, setCount] = useState(0);
  const [masterChecked, setMasterChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  const TOTAL_CHECKBOXES = workPapers.length + tickmarks.length;

  // Called when master checkbox is checked/unchecked
  useEffect(() => {
    setworkPapers((prevWp) => {
      let newWorkPaper = [...prevWp];
      newWorkPaper.forEach(
        (wps, index) => (newWorkPaper[index].checked = masterChecked),
      );
      return newWorkPaper;
    });
    setTickmarks((prevTick) => {
      let newTickmarks = [...prevTick];
      newTickmarks.forEach(
        (ticks, index) => (newTickmarks[index].checked = masterChecked),
      );
      return newTickmarks;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterChecked]);

  // Called to keep track of count everytime workpapers/tickmarks properties are changed
  useEffect(() => {
    let totalSelected =
      workPapers.filter((wps) => wps.checked === true).length +
      tickmarks.filter((ticks) => ticks.checked === true).length;
    count !== totalSelected && setCount(() => totalSelected);

    // If count of checked checkbox is not updated then no need to update the master checkbox value as well
    if (count !== totalSelected) {
      totalSelected === TOTAL_CHECKBOXES &&
        TOTAL_CHECKBOXES !== 0 &&
        masterChecked === false &&
        setMasterChecked(true);
      totalSelected === 0 && masterChecked === true && setMasterChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workPapers, tickmarks]);

  const _onCopyorVerifyAnnotations = async () => {
    let apiRequestPayload = new BatchElementUpdateForm({ isLoaded: true });
    if (copyAnnotation) {
      let checkedWorkPaper = workPapers.filter((wps) => wps.checked === true);
      let checkedTickmarks = tickmarks.filter(
        (ticks) => ticks.checked === true,
      );
      apiRequestPayload = apiRequestPayload
        .setTickmarkList(checkedTickmarks)
        .setWorkpaperList(checkedWorkPaper);
    }
    if (verifyLinkedReference) {
      apiRequestPayload = apiRequestPayload.setVerify(true);
    }

    try {
      await batchUpdateElementRequest({
        elementIds,
        updatesModel: apiRequestPayload,
        revisionId: revision.id,
      });
      await handleBatchUpdateSuccess({
        elementIds,
        revisionId: revision.id,
        updateModel: apiRequestPayload,
      });
      if (
        Object.keys(workpaperToolkit.elementsMap).length !== 0 ||
        leftPanelWorkpaper
      ) {
        if (apiRequestPayload.shouldAddWpRefs && socketHasBeenDisconnected) {
          fetchWorkpaperListForRevision({
            revisionId: revision.id,
          });
        }
      }
      if (
        Object.keys(tickmarkPanel.elementsMap).length !== 0 ||
        leftPanelTickmark
      ) {
        if (apiRequestPayload.shouldAddTickmarks && socketHasBeenDisconnected) {
          fetchTickmarkListForRevision({
            revisionId: revision.id,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await _onCopyorVerifyAnnotations();
      setLoading(false);
      onClickCancel();
    } catch (e) {
      setLoading(false);
      onClickCancel();
    }
  };

  return (
    <Modal
      id={COPY_ANNOTATION_IR_LINKED_ID}
      title={'internal-reference-copy-annotation-modal.title'}
      onClose={onClickCancel}
      primaryModalButton={
        new ModalButton({
          text: 'internal-reference-copy-annotation-modal-button.confirm',
          disabled:
            (copyAnnotation && count === 0) ||
            selectedStatement.isReadOnly() ||
            (!verifyLinkedReference && !copyAnnotation),
          onClick: onSubmit,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClickCancel,
        })
      }
    >
      {loading ? <Loading /> : null}
      <div className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__toggles`}>
        <div className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__verify-toggle`}>
          <span
            className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__verify-toggle-heading`}
          >
            <FormattedMessage id="internal-reference-verify-toggle" />
          </span>
          <Switch
            onChange={toggleVerifyLinkedReference}
            checked={verifyLinkedReference}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={45}
          />
        </div>
        <div
          className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__copy-annotation-toggle`}
        >
          <span
            className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__copy-annotation-toggle-heading`}
          >
            <FormattedMessage id="internal-reference-copy-toggle" />
          </span>
          <Switch
            onChange={toggleCopyAnnotation}
            checked={copyAnnotation}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={45}
          />
        </div>
      </div>

      {copyAnnotation &&
        (!workPapers.length > 0 && !tickmarks.length > 0 ? (
          <FormattedMessage id="internal-reference-copy-annotation-modal-list-empty" />
        ) : (
          <div className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__non-empty`}>
            <div
              className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__master-and-count`}
            >
              <Checkbox
                className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__master-checkboxes`}
                label="common.master-checkbox"
                isNotIntl={false}
                masterChecked={count > 0 && count < TOTAL_CHECKBOXES}
                name="copy-annotation-master-checkbox"
                checked={masterChecked}
                id={`${COPY_ANNOTATION_IR_LINKED_ID}__master-checkbox`}
                onChange={() => setMasterChecked(!masterChecked)}
              />
              <div className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__count`}>
                <FormattedMessage
                  id="internal-reference-copy-annotation-modal-total-count"
                  values={{
                    count: count,
                  }}
                />
              </div>
            </div>
            {workPapers.length > 0 && (
              <div>
                <div className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__heading`}>
                  <FormattedMessage id="internal-reference-copy-annotation-modal-list-workpapers.heading" />
                </div>
                {workPapers.map((wps, index) => (
                  <Checkbox
                    key={`${COPY_ANNOTATION_IR_LINKED_ID}-wp-${wps.workpaperRefId}-${wps.referenceNumber}`}
                    className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__workpapers-checkboxes`}
                    label={wps.referenceNumber}
                    id={`${COPY_ANNOTATION_IR_LINKED_ID}-wp-${index}-${wps.workpaperRefId}`}
                    name={`workPaper-${index}-${wps.workpaperRefId}`}
                    checked={wps.checked}
                    isNotIntl={true}
                    onChange={() => {
                      setworkPapers((prev) => {
                        let newWorkPaper = [...prev];
                        newWorkPaper[index].checked =
                          !newWorkPaper[index].checked;
                        return newWorkPaper;
                      });
                    }}
                  />
                ))}
              </div>
            )}
            {tickmarks.length > 0 && (
              <div>
                <div className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__heading`}>
                  <FormattedMessage id="internal-reference-copy-annotation-modal-list-tickmarks.heading" />
                </div>
                {tickmarks.map((ticks, index) => (
                  <div
                    className={`${COPY_ANNOTATION_IR_LINKED_BLOCK}__tickmark-single-checkboxes`}
                    key={`${COPY_ANNOTATION_IR_LINKED_ID}-ticks-div-${ticks.tickmarkId}`}
                  >
                    <Checkbox
                      key={`${COPY_ANNOTATION_IR_LINKED_ID}-ticks-${ticks.tickmarkId}`}
                      id={`${COPY_ANNOTATION_IR_LINKED_ID}-ticks-${index}-${ticks.tickmarkId}`}
                      name={`tickmarks-${index}-${ticks.tickmarkId}`}
                      checked={ticks.checked}
                      isNotIntl={true}
                      label={''} // label is not required here
                      width={'6%'}
                      onChange={() => {
                        setTickmarks((prev) => {
                          let newTickmark = [...prev];
                          newTickmark[index].checked =
                            !newTickmark[index].checked;
                          return newTickmark;
                        });
                      }}
                    />
                    <TickmarkEntry tickmark={ticks} />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </Modal>
  );
};

ShowLinkedAnnotations.propTypes = {
  /**The model the represents the internal reference which is used in the internal reference panel. */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /**Function changes state to close modal */
  onClickCancel: PropTypes.func.isRequired,
  /** revision info for current document */
  revision: PropTypes.object.isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
  /** Indicates if the tickmark left panel is open */
  leftPanelTickmark: PropTypes.bool.isRequired,
  /** Indicates if the workpaperReference left panel is open */
  leftPanelWorkpaper: PropTypes.bool.isRequired,
  /* Workpapers data from store */
  workpaperToolkit: PropTypes.instanceOf(WorkpaperToolkitListPanel),
  /* tickmark data from store */
  tickmarkPanel: PropTypes.instanceOf(TickmarkList),
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
};

const mapStateToProps = ({
  data: { revision, workpaperToolkit, tickmarkPanel, selectedStatement },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  leftPanelWorkpaper: left === LEFT_PANELS.WORKPAPER,
  leftPanelTickmark: left === LEFT_PANELS.TICKMARK,
  selectedStatement,
  revision,
  workpaperToolkit,
  socketHasBeenDisconnected,
  tickmarkPanel,
});

export default connect(mapStateToProps)(ShowLinkedAnnotations);
