import { handleActions } from 'redux-actions';
import {
  toggleBlacklineViewSyncScrollingMode,
  clearBlacklineViewSyncScrollingMode,
} from 'store/actions/blackline-view-actions';

export default handleActions(
  {
    [toggleBlacklineViewSyncScrollingMode]: (
      blacklineViewSyncScrollingMode,
    ) => {
      return !blacklineViewSyncScrollingMode;
    },
    [clearBlacklineViewSyncScrollingMode]: () => {
      return false;
    },
  },
  false,
);
