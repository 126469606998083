import { handleActions } from 'redux-actions';
import { clearLeftStatementPageStoreAction } from 'store/actions/statement-content-actions';

import {
  leftNotesCacheSectionLoading,
  leftNotesCacheSectionLoaded,
  leftNotesCacheSectionError,
} from 'store/actions/notes-cache-actions';
import NoteCache from 'models/data/note-cache-model';

export default handleActions(
  {
    [leftNotesCacheSectionLoading]: (noteCache, { payload }) => {
      return noteCache.setSectionLoading(payload);
    },
    [leftNotesCacheSectionLoaded]: (noteCache, { payload }) => {
      return noteCache.setSectionLoaded(payload);
    },
    [leftNotesCacheSectionError]: (noteCache, { payload }) => {
      return noteCache.setSectionError(payload);
    },
    /** External listeners */
    [clearLeftStatementPageStoreAction]: () => {
      return new NoteCache();
    },
  },
  new NoteCache(),
);
