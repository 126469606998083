import { createAction } from 'redux-actions';

export const setHideOrDisplayElements = createAction(
  'HIDE_OR_DISPLAY_ELEMENTS',
);

export const setHideOrDisplayElementsOnStatementPage =
  ({ displayValue }) =>
  async (dispatch) => {
    dispatch(setHideOrDisplayElements(displayValue));
  };
