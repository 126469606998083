import {
  withDefaultContextAsSelectedProjectDocChat,
  withDefaultContextAsSelectedProject,
} from './api-default-context';
import { getDocChatHeaders } from 'api/docChat-api-client';

export const sendChatBotUserResponse = async ({ userResponse }) => {
  const docChatHeaders = await getDocChatHeaders();

  return withDefaultContextAsSelectedProjectDocChat()
    .post('/api/chat/chat_message/', userResponse, {
      headers: docChatHeaders,
    })
    .then((response) => {
      return response.data; // Return the response data
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled by the caller
    });
};

export const chatBotPushIndexRequest = (pushIndexData = {}) => {
  return new Promise((resolve, reject) => {
    // Use setTimeout to mimic a delay
    setTimeout(() => {
      // Resolve the promise with the provided data after the specified delay
      resolve({ name: 'aarya' });
    }, 2000);
  });
};

export const submitChatbotResponseFeedback = async (requestparams) => {
  const docChatHeaders = await getDocChatHeaders();

  return withDefaultContextAsSelectedProjectDocChat()
    .post('/api/chat/submit_feedback/', requestparams, {
      headers: docChatHeaders,
    })
    .then((response) => {
      return response.data; // Return the response data
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled by the caller
    });
};

export const getDocumentIndexingStatus = async (fileKey) => {
  return withDefaultContextAsSelectedProject()
    .get(`/tieout/doc-chat/details/${fileKey}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
