import { handleActions } from 'redux-actions';
import {
  sectionHTMLSegmentsStatementContentLoading,
  sectionHTMLSegmentsStatementContentLoaded,
  sectionHTMLSegmentsStatementContentError,
  sectionIdListStatementContentLoading,
  sectionIdListStatementContentLoaded,
  sectionIdListStatementContentError,
  clearSectionContentWithIdsFromCache,
  clearStatementPageStoreAction,
  sectionHTMLSegmentsStatementContentLoadedWithoutResponse,
  clearOCRSectionImageWithIdsFromCache,
} from 'store/actions/statement-content-actions';
import {
  sectionOCRImageStatementContentLoaded,
  sectionOCRImageStatementContentError,
} from 'store/actions/statement-content-actions-ocr';
import { SectionCache } from 'models/api/sections-cache-model';
export default handleActions(
  {
    [sectionIdListStatementContentLoading]: (sectionCache) => {
      return sectionCache.setLoading();
    },
    [sectionIdListStatementContentLoaded]: (sectionCache, { payload }) => {
      return sectionCache.setLoaded(payload);
    },
    [sectionIdListStatementContentError]: (sectionCache, { payload }) => {
      return sectionCache.setError(payload);
    },
    [sectionHTMLSegmentsStatementContentLoading]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeSectionsLoading(payload);
    },
    [sectionHTMLSegmentsStatementContentLoaded]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeSectionsLoaded(payload);
    },
    [sectionHTMLSegmentsStatementContentLoadedWithoutResponse]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeSectionsLoadedWithoutResponse(payload);
    },
    [sectionHTMLSegmentsStatementContentError]: (sectionCache, { payload }) => {
      return sectionCache.setError(payload);
    },
    [clearSectionContentWithIdsFromCache]: (sectionCache, { payload }) => {
      return sectionCache.clearSectionContentFromMap(payload);
    },
    [clearOCRSectionImageWithIdsFromCache]: (sectionCache, { payload }) => {
      return sectionCache.clearSectionImageContentFromMap(payload);
    },
    [clearStatementPageStoreAction]: (sectionCache, { payload }) => {
      return new SectionCache();
    },
    [sectionOCRImageStatementContentLoaded]: (sectionCache, { payload }) => {
      return sectionCache.mergeOCRSectionLoaded(payload);
    },
    [sectionOCRImageStatementContentError]: (sectionCache, { payload }) => {
      return sectionCache.setError(payload);
    },
  },
  new SectionCache(),
);
