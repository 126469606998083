import React from 'react';
import { connect } from 'react-redux';
import Tooltip from 'components/common/tool-tip-component';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as BulkAction } from 'icons/bulk-action.svg';
import SelectedStatement from 'models/api/selected-statement-model';
import Permissions from 'permissions/permissions';

const BATCH_SELECT_CONTROL = 'batch-select-control';
const BATCH_SELECT_ICON_SIZE = '20px';

export const BatchSelectControl = ({
  setBatchModeSelectedIfPossible,
  selectedProjectId,
  selectedStatement,
  showBatchPanelAction,
  disabled,
}) => {
  const _setBatchModeWithBannerAndOpenBatchPanel = () => {
    setBatchModeSelectedIfPossible(); // setElementSelectModeBatchWithBannerAction action inside modes-action.js
    showBatchPanelAction();
  };

  const _userCanBatch = Permissions.Element.canEditElement(selectedProjectId);
  const readOnly = selectedStatement.isReadOnly();
  return (
    <div className={BATCH_SELECT_CONTROL}>
      <div
        className={`${BATCH_SELECT_CONTROL}__fixed-container`}
        disabled={!_userCanBatch || readOnly || disabled}
      >
        <div
          className={`${BATCH_SELECT_CONTROL}__container`}
          disabled={!_userCanBatch || readOnly || disabled}
        >
          <Tooltip
            id={`${BATCH_SELECT_CONTROL}__batch-select-button-tooltip`}
            position={'bottom'}
            text={disabled ? null : 'batch-selection-mode-tooltip'}
            key={`${BATCH_SELECT_CONTROL}__batch-select-button-tooltip`}
          >
            <button
              className={classnames(
                `${BATCH_SELECT_CONTROL}__batch-select-button`,
                {
                  [`${BATCH_SELECT_CONTROL}__batch-select-button--disabled`]:
                    disabled,
                },
              )}
              onClick={_setBatchModeWithBannerAndOpenBatchPanel}
              disabled={!_userCanBatch || readOnly || disabled}
            >
              <BulkAction
                width={BATCH_SELECT_ICON_SIZE}
                height={BATCH_SELECT_ICON_SIZE}
              />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  data: {
    selectedProject: { id: selectedProjectId },
    selectedStatement,
  },
  ui: {
    statementPage: {
      modes: { contentViewMode },
    },
  },
}) => ({
  contentViewMode,
  selectedProjectId,
  selectedStatement,
});

BatchSelectControl.propTypes = {
  /** Function fired to set batch selection mode */
  setBatchModeSelectedIfPossible: PropTypes.func.isRequired,
  /** Currently selected project id of the revision we are viewing */
  selectedProjectId: PropTypes.string.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
};

export default connect(mapStateToProps)(BatchSelectControl);
