import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpandableRow from 'components/common/expandable-row-component';
import { SearchDropdownApi } from 'components/common/search-component';
import Dropdown from 'components/common/dropdown-component';
import ConditionalRender from 'components/util/conditional-render-component';
import TabModel from 'models/utils/common/tab-model';

import {
  SCALING_OPTIONS,
  UNIT_OPTIONS,
} from 'constants/feature/element-attribute-constants';
import ElementDetails from 'models/api/element-details-api-model';
import MenuApiData from 'models/utils/common/menu/menu-api-data-model';
import { PeriodList } from 'models/api/period-list-model';
import { EntityList } from 'models/api/entity-list-model';

const ELEMENT_ATTRIBUTE_BLOCK = 'element-attributes';
const ELEMENT_ATTIBUTE_ID_BLOCK = 'element-attribute-id';

const PERIOD_DROPDOWN_DISPLAY_LIMIT = 5;
const ENTITY_DROPDOWN_DISPLAY_LIMIT = 5;
const MIN_CHAR_LENGTH_TO_SEARCH = 0;

class ElementAttribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      expanded: false,
    };
  }

  componentDidMount() {
    const { elementDetails } = this.props;
    this.setState((state) => ({
      search: elementDetails.entity,
    }));
  }

  componentDidUpdate(prevProps) {
    const { elementDetails } = this.props;
    if (prevProps.elementDetails.entity !== elementDetails.entity) {
      this.setState(() => ({
        search: elementDetails.entity,
      }));
    }
  }

  _searchEntities = (val) => {
    this.setState({ search: val });
  };

  _setEntity = ({ value }) => {
    const { updateOrCreateEntity } = this.props;
    this.setState(
      {
        search: value,
      },
      () => updateOrCreateEntity(value),
    );
  };

  render() {
    const { search, expanded } = this.state;
    const {
      elementDetails,
      updateScaling,
      updateOrCreateEntity,
      updateUnits,
      periodList,
      updatePeriod,
      entityList,
      disabled,
      selectedElementPanelAnnotationsTab,
    } = this.props;
    return (
      <ConditionalRender dependencies={[elementDetails, periodList]}>
        <ExpandableRow
          title="element-panel.attributes.title"
          expandExpandableRow={() => this.setState({ expanded: true })}
          minimizeExpandableRow={() => this.setState({ expanded: false })}
          showExpandableRow={expanded}
          tooltipId={`${ELEMENT_ATTRIBUTE_BLOCK}__tooltip`}
          isElementPanel={true}
        >
          <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__body`}>
            <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__row`}>
              <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__col`}>
                <Dropdown
                  id={`${ELEMENT_ATTIBUTE_ID_BLOCK}__scaling`}
                  label="element-panel.attributes.scaling.label"
                  options={SCALING_OPTIONS}
                  value={elementDetails.scaling}
                  placeholder="element-panel.attributes.scaling.label"
                  onSelectOption={(val) =>
                    updateScaling(val, selectedElementPanelAnnotationsTab)
                  }
                  disabled={disabled}
                />
              </div>
              <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__col`}>
                <Dropdown
                  id={`${ELEMENT_ATTIBUTE_ID_BLOCK}__unit-dropdown`}
                  label={'element-panel.attributes.unit.label'}
                  options={UNIT_OPTIONS}
                  value={elementDetails.units}
                  placeholder="element-panel.attributes.unit.label"
                  onSelectOption={(option) =>
                    updateUnits(option, selectedElementPanelAnnotationsTab)
                  }
                  disabled={disabled}
                />
              </div>
            </div>

            <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__row`}>
              <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__col`}>
                <Dropdown
                  id={`${ELEMENT_ATTIBUTE_ID_BLOCK}__period-dropdown`}
                  label={'element-panel.attributes.period.label'}
                  options={periodList.getPeriodDropdownValues()}
                  value={
                    periodList.hasPeriodDropdownValue(elementDetails.period)
                      ? periodList.getPeriodDropdownValue(elementDetails.period)
                      : null
                  }
                  placeholder={
                    !periodList.hasPeriodDropdownValue(elementDetails.period)
                      ? elementDetails.period
                      : null
                  }
                  onSelectOption={(period) =>
                    updatePeriod(period, selectedElementPanelAnnotationsTab)
                  }
                  numberOfVisibleRows={PERIOD_DROPDOWN_DISPLAY_LIMIT}
                  isNotIntl
                  disabled={disabled}
                />
              </div>
            </div>

            <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__row`}>
              <div className={`${ELEMENT_ATTRIBUTE_BLOCK}__col`}>
                <SearchDropdownApi
                  id={`${ELEMENT_ATTIBUTE_ID_BLOCK}-entity-search`}
                  label={'element-panel.attributes.entity.label'}
                  className={`${ELEMENT_ATTRIBUTE_BLOCK}--search`}
                  placeholder={'common.search'}
                  onChange={this._searchEntities}
                  value={search}
                  isValid={true}
                  resultsApiModel={
                    new MenuApiData({
                      apiModel: entityList,
                      options: entityList.getEntityDropdownValues(search),
                    })
                  }
                  onClear={() => {
                    this.setState({ search: '' });
                  }}
                  isCreate={true}
                  onCreateClick={() => {
                    updateOrCreateEntity(
                      search,
                      selectedElementPanelAnnotationsTab,
                    );
                  }}
                  minSearchCharLength={MIN_CHAR_LENGTH_TO_SEARCH}
                  showSearchButton={true}
                  onSelectResult={this._setEntity}
                  numberOfVisibleRows={ENTITY_DROPDOWN_DISPLAY_LIMIT}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </ExpandableRow>
      </ConditionalRender>
    );
  }
}
ElementAttribute.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** The list of periods that can be chosen for this element */
  periodList: PropTypes.instanceOf(PeriodList).isRequired,
  /** The list of periods that can be chosen for this element */
  entityList: PropTypes.instanceOf(EntityList).isRequired,
  /** Function for updating scaling value */
  updateScaling: PropTypes.func.isRequired,
  /** Function for updating units value */
  updateUnits: PropTypes.func.isRequired,
  /** Function for updating entities */
  updateOrCreateEntity: PropTypes.func.isRequired,
  /** Function for updating period value */
  updatePeriod: PropTypes.func.isRequired,
  /** Selected tab for the annotations table */
  selectedElementPanelAnnotationsTab: PropTypes.instanceOf(TabModel).isRequired,
};
export default ElementAttribute;
