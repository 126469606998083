import { createAction } from 'redux-actions';
import {
  onStatementNavSectionSelect,
  replaceSelectedSectionsContentPanel,
} from '../statement-content-actions';
import { scrollLeftSectionIntoView } from 'utils/scrolling-utils';
import { getSectionReviewersRequest } from 'api/sections-api';
import { CONTEXT_KEY } from 'api/api-client';
import { getStore } from 'store/store';
import {
  SIDE_BY_SIDE_VIEW_LEGACY_COMBINATION_STATEMENT,
  SIDE_BY_SIDE_VIEW_OCR_COMBINATION_STATEMENT,
} from 'constants/feature/statement-content-constants';
import {
  deRegisterLeftScrollListeners,
  deRegisterRightScrollListeners,
  getTotalSectionHeight,
  registerLeftScrollListeners,
  registerRightScrollListeners,
} from 'utils/sync-scroll-utils';

export const setSourceStatementElementMapAction = createAction(
  'SET_SOURCE_STATEMENT_ELEMENT_MAP_ACTION',
);

export const setTargetStatementElementMapAction = createAction(
  'SET_TARGET_STATEMENT_ELEMENT_MAP_ACTION',
);

export const clearSideBySideElementsMap = createAction(
  'CLEAR_SIDE_BY_SIDE_ELEMENTS_MAP',
);

export const replaceSelectedSectionsInLeftContentPanel = createAction(
  'REPLACE_SELECTED_SECTIONS_LEFT_STATEMENT_CONTENT_PANEL',
);
export const deallocateSelectedSectionsInLeftContentPanel = createAction(
  'DEALLOCATE_SELECTED_SECTIONS_LEFT_STATEMENT_CONTENT_PANEL',
);

export const fetchLeftSectionReviewsError = createAction(
  'LEFT_FETCH_SECTIONS_REVIEWS_ERROR',
);

export const fetchLeftSectionReviewsLoaded = createAction(
  'LEFT_FETCH_SECTIONS_REVIEWS_LOADED',
);

export const setTargetStatementBatchElementsMapAction = createAction(
  'SET_TARGET_STATEMENT_BATCH_ELEMENTS_MAP_ACTION',
);
export const setSourceStatementBatchElementsMapAction = createAction(
  'SET_SOURCE_STATEMENT_BATCH_ELEMENTS_MAP_ACTION',
);

export const setSideBySideSyncScroll = createAction(
  'SET_SIDE_BY_SIDE_SYNC_SCROLL',
);

export const onRightStatementNavSectionClick =
  ({ sectionId, color }) =>
  async (dispatch, getState) => {
    const { data, ui } = getState();
    const {
      leftSelectedStatement,
      selectedStatement: { isOCR },
    } = data;
    const {
      modes: { sideBySideSyncScroll },
      syncScrollIds,
    } = ui.statementPage;
    const sourceStatement = document.getElementById(syncScrollIds.sourceId);
    const targetStatement = document.getElementById(syncScrollIds.targetId);

    if (
      sideBySideSyncScroll &&
      leftSelectedStatement &&
      leftSelectedStatement.isLoaded
    ) {
      deRegisterLeftScrollListeners(sourceStatement);
      deRegisterRightScrollListeners(targetStatement);

      dispatch(
        replaceSelectedSectionsContentPanel({
          sectionIds: [sectionId],
          color,
        }),
      );

      const totalPageHeight = getTotalSectionHeight(
        sectionId,
        !isOCR, // to return is Legacy Statement Selected
        syncScrollIds.targetId,
      );
      const top = totalPageHeight;
      const options = { top, behavior: 'smooth' };

      sourceStatement.scrollTo(options);
      targetStatement.scrollTo(options);

      setTimeout(() => {
        registerLeftScrollListeners(sourceStatement);
        registerRightScrollListeners(targetStatement);
      }, 2000);
    } else {
      dispatch(onStatementNavSectionSelect({ sectionId, color }));
    }
  };

export const onLeftStatementNavSectionClick =
  ({ sectionId, color }) =>
  async (dispatch, getState) => {
    const { data, ui } = getState();
    const {
      leftSelectedStatement: { isOCR },
    } = data;
    const {
      modes: { sideBySideSyncScroll },
      syncScrollIds,
    } = ui.statementPage;

    const sourceStatement = document.getElementById(syncScrollIds.sourceId);
    const targetStatement = document.getElementById(syncScrollIds.targetId);

    if (sideBySideSyncScroll) {
      deRegisterLeftScrollListeners(sourceStatement);
      deRegisterRightScrollListeners(targetStatement);

      await dispatch(
        replaceSelectedSectionsInLeftContentPanel({
          sectionIds: [sectionId],
          color,
        }),
      );

      const totalPageHeight = getTotalSectionHeight(
        sectionId,
        !isOCR, // to return is Legacy Statement Selected
        syncScrollIds.sourceId,
      );

      const top = totalPageHeight;
      const options = { top, behavior: 'smooth' };
      sourceStatement.scrollTo(options);
      targetStatement.scrollTo(options);

      setTimeout(() => {
        registerLeftScrollListeners(sourceStatement);
        registerRightScrollListeners(targetStatement);
      }, 2000);
    } else {
      dispatch(
        replaceSelectedSectionsInLeftContentPanel({
          sectionIds: [sectionId],
          color,
        }),
      );
      scrollLeftSectionIntoView(sectionId);
    }
  };

export const fetchLeftSectionReviews =
  (statementId) => async (dispatch, getStore) => {
    const { leftRevision } = getStore().data;
    const { sourceStatementParams } = getStore().ui;
    try {
      const response = await getSectionReviewersRequest({
        revisionId: leftRevision.id,
        contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
        contextValue: sourceStatementParams.projectId,
        statementId,
      });
      dispatch(fetchLeftSectionReviewsLoaded({ response }));
    } catch (error) {
      dispatch(fetchLeftSectionReviewsError(error));
    }
  };

export const isBothLegacyOrOCRInSideBySideView = () => {
  const { selectedStatement, leftSelectedStatement } =
    getStore().getState().data;
  const isSourceLegacy = !selectedStatement.isOCR;
  const isTargetLegacy = !leftSelectedStatement.isOCR;

  if (isSourceLegacy === isTargetLegacy) {
    return isSourceLegacy
      ? SIDE_BY_SIDE_VIEW_LEGACY_COMBINATION_STATEMENT // it returns true if both source and target statement are Legacy statements
      : SIDE_BY_SIDE_VIEW_OCR_COMBINATION_STATEMENT; // it returns true if both source and target statement are OCR statements
  }
};
