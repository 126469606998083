import ApiModel from 'models/api-model';

export default class SectionContentImageModel extends ApiModel({
  data: {
    image: null,
    sectionId: null,
  },
}) {
  get sectionId() {
    if (this.data) {
      return this.data.sectionId;
    }
    return null;
  }

  get image() {
    if (this.data) {
      return this.data.image;
    }
    return null;
  }

  processResponse(response) {
    return {
      data: {
        image: response.image,
        sectionId: response.sectionId,
      },
    };
  }
  clearImageContent() {
    return this.merge({
      isLoaded: false,
      isLoading: true,
      data: {
        ...this.data,
        image: null,
      },
    });
  }
}
