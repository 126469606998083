import ApiModel from 'models/api-model';
import clonedeep from 'lodash.clonedeep';

export default class ElementWorkpaperList extends ApiModel({
  data: {
    workpapers: [],
  },
}) {
  get workpapers() {
    if (this.data) {
      return this.data.workpapers;
    }
    return [];
  }
  processResponse({ response }) {
    return {
      data: {
        workpapers: response.data.result,
      },
    };
  }

  getWorkpaperCount() {
    if (this.hasWorkpapers()) {
      return this.data.workpapers.length;
    }
    return 0;
  }

  getWorkpapers() {
    if (this.data && this.data.workpapers) {
      return this.data.workpapers;
    }
    return [];
  }

  get(index) {
    if (
      this.data &&
      this.data.workpapers &&
      index >= 0 &&
      index < this.data.workpapers.length
    ) {
      return this.data.workpapers[index];
    }
    return null;
  }

  hasWorkpapers() {
    return this.data && this.data.workpapers && this.data.workpapers.length > 0;
  }

  setWorkpaperListFromWebSocketPayload({ webSocketPayload, elementId }) {
    const workpapersCopy = clonedeep(this.workpapers);
    const workpapersMap = workpapersCopy.reduce((elementMap, elementData) => {
      elementMap[elementData.workpaperRefId] = elementData;
      return elementMap;
    }, {});

    webSocketPayload.forEach((workpaper) => {
      if (
        workpaper.elementId === elementId &&
        !(workpaper.workpaperRefId in workpapersMap)
      ) {
        workpapersCopy.push(workpaper);
      }
    });
    workpapersCopy.sort((a, b) =>
      a.referenceNumber.localeCompare(b.referenceNumber),
    );
    return this.mergeData({
      workpapers: workpapersCopy,
    });
  }

  removeWorkpaperFromElementPanelWithSocketPayload(payload) {
    let workpapersCopy = clonedeep(this.workpapers);
    workpapersCopy = workpapersCopy.filter(
      (workpaper) => !payload.has(workpaper.workpaperRefId),
    );
    return this.mergeData({
      workpapers: workpapersCopy,
    });
  }

  removeWPFromElementPanel(payload) {
    let workpapersCopy = [...this.data.workpapers];

    workpapersCopy = workpapersCopy.filter(
      (workpaper) => workpaper.workpaperRefId !== payload.wpRefId,
    );

    return this.mergeData({
      workpapers: workpapersCopy,
    });
  }

  updateWPFromElementPanel(payload) {
    let workpapersCopy = [...this.data.workpapers];

    for (let i = 0; i < workpapersCopy.length; i++) {
      if (payload.wpRefId === workpapersCopy[i].workpaperRefId) {
        workpapersCopy[i].referenceNumber = payload.referenceNumber;
        workpapersCopy[i].referenceName = payload.referenceName;
        workpapersCopy[i].creationType = payload.creationType;
        break;
      }
    }

    return this.mergeData({
      workpapers: workpapersCopy,
    });
  }
}
