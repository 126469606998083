import ApiModel from 'models/api-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';

export default class SupportedLanguages extends ApiModel({
  data: {
    languageMenuOptions: [], // type MenuOptions
  },
}) {
  processResponse({ response }) {
    const supportedLanguageMenuOptions = [];
    response.forEach((lang) => {
      const languageMenu = new MenuOption({
        id: lang.LocaleCode,
        value: lang.LocaleCode,
        title: lang.Language,
        isIntl: false,
      });
      supportedLanguageMenuOptions.push(languageMenu);
    });
    return {
      data: {
        languageMenuOptions: supportedLanguageMenuOptions,
      },
      status: response.status,
    };
  }

  getLanguagesMenu() {
    return this.data && this.data.languageMenuOptions;
  }

  getLangaugeMenuByLanguageCode = (languageCode) => {
    return (
      this.data &&
      Array.isArray(this.data.languageMenuOptions) &&
      this.data.languageMenuOptions.find(
        (langMenu) => langMenu.value === languageCode,
      )
    );
  };
}
