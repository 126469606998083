import React from 'react';
import PropTypes from 'prop-types';

export const ELEMENT_BLOCK = 'tieout-element';

const PreviewReportCalloutComponent = ({ elementDetails }) => {
  return (
    <div className={`${ELEMENT_BLOCK}__preview-sequence-id`}>
      {elementDetails.previewSequenceID}
    </div>
  );
};

export default PreviewReportCalloutComponent;

PropTypes.PreviewReportCalloutComponent = {
  // details of element for which preview sequence id has to be rendered
  elementDetails: PropTypes.object.isRequired,
};
