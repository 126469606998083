import React, { useEffect, useState, useCallback } from 'react';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Card from 'components/common/card-component';
import { getUserListRequest } from 'api/project-creation-edit-api';
import { UserList } from 'models/api/users-list-model';
import { isNullOrUndefined } from 'utils/object-utils';
import DataGridHeader from 'components/common/data-grid/data-grid-header.component';
import {
  ADMIN_MODULE_TABS,
  getAdminModuleTabs,
} from 'constants/feature/admin-module-constants';
import { requestCurrentAdminModuleTab } from 'store/actions/admin-module-actions';
import ManageUsersAdmin from './admin-module-manage-users/_tab-admin-module-manage-users';
import { MaintenanceBannerAdmin } from './_tab-admin-module-maintenance-banner';
import ConditionalRender from 'components/util/conditional-render-component';
import PropTypes from 'prop-types';
import {
  maintenanceBannerSaveAction,
  clearAdminPageDataAction,
  getContainerListWhereUserIsCountryManagerAction,
} from 'store/actions/admin-module-actions';
import AdminModuleMaintenanceList from 'models/data/admin-module-maintenance-page-list-model';
import TabModel from 'models/utils/common/tab-model';
import SummaryOfKeyChanges from './_tab-admin-summary-of-key-changes';
import Monitoring from './_tab-admin-module-monitoring';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import ContainerForm from 'models/form/container-form-model';

export const AdminModulePage = ({
  push,
  selectAdminModuleTab,
  selectedTab,
  currentUser,
  adminModuleMaintenacePageList,
  maintenanceBannerSaveAction,
  getContainerListWhereUserIsCountryManagerAction,
  clearAdminPageDataAction,
  countryManagerContainerList,
}) => {
  const ADMIN_MODULE_BLOCK = 'admin-module-page';
  const [search, setSearch] = useState(EMPTY_STRING);
  const [userListModel, setUserListModel] = useState(new UserList());
  const [userEntityArray, setUserEntityArray] = useState([]);
  const adminModuleTabs = getAdminModuleTabs(currentUser);
  const defaultSelectedTab = Object.values(adminModuleTabs)[0];

  useEffect(() => {
    selectAdminModuleTab({ tab: defaultSelectedTab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { admin, countryManager } = currentUser;
    if (admin || countryManager) {
      getContainerListWhereUserIsCountryManagerAction();
    }

    return () => clearAdminPageDataAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryManagerContainerList.isLoaded) {
      setUserEntityArray([...countryManagerContainerList.users]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryManagerContainerList.isLoaded, countryManagerContainerList.users]);

  const _fetchSearchUserList = async ({ search }) => {
    //Setting clientId as 0 bacause the search logic is only dependent on the searchString
    //and is independent of the projectID
    try {
      const response = await getUserListRequest({
        clientId: 0,
        searchString: search,
        numberOfRows: 50,
        pageNumber: 1,
      });

      setUserListModel(userListModel.setLoaded({ response }));
    } catch (error) {
      setUserListModel(userListModel.setError(error));
    }
  };

  const debouncedSearch = useCallback(debounceSearch(_fetchSearchUserList));

  const _searchUser = (val) => {
    setSearch(val);
    if (isSearchCharLengthValid(val)) {
      debouncedSearch({ search: val });
    }
  };

  const switchAdminModuleTabs = (tabSelected) => {
    switch (tabSelected) {
      case ADMIN_MODULE_TABS.MANAGEUSERS:
        return (
          <ManageUsersAdmin
            userEntityArray={userEntityArray}
            currentUser={currentUser}
            setSearch={setSearch}
            _searchUser={_searchUser}
            setUserListModel={setUserListModel}
            setUserEntityArray={setUserEntityArray}
            search={search}
            userListModel={userListModel}
          />
        );
      case ADMIN_MODULE_TABS.MAINTENANCEBANNER:
        return (
          <ConditionalRender dependencies={[adminModuleMaintenacePageList]}>
            <>
              {/* As of now we want to display only the top banner in the list */}
              {/* {adminModuleMaintenacePageList.data.maintenanceBannerDataList.map(
                (bannerData) => ( */}
              <MaintenanceBannerAdmin
                bannerData={
                  adminModuleMaintenacePageList.data
                    .maintenanceBannerDataList[0]
                }
                maintenanceBannerSaveAction={maintenanceBannerSaveAction}
              />
              {/* ),
              )} */}
            </>
          </ConditionalRender>
        );
      case ADMIN_MODULE_TABS.SUMMARY_OF_KEY_CHANGES:
        return <SummaryOfKeyChanges />;
      case ADMIN_MODULE_TABS.MONITORING:
        return <Monitoring />;
      default:
        return (
          <ManageUsersAdmin
            userEntityArray={userEntityArray}
            currentUser={currentUser}
            setSearch={setSearch}
            _searchUser={_searchUser}
            setUserListModel={setUserListModel}
            setUserEntityArray={setUserEntityArray}
            search={search}
            userListModel={userListModel}
          />
        );
    }
  };

  return (
    <div className={ADMIN_MODULE_BLOCK}>
      <Button.IconButton
        id={`${ADMIN_MODULE_BLOCK}__back-button`}
        className={`${ADMIN_MODULE_BLOCK}__back-button`}
        type={BUTTON_TYPES.icon}
        Icon={BackButton}
        onClick={() => push(ROUTE_CONSTANTS.HOME_PAGE)}
      >
        <div className={`${ADMIN_MODULE_BLOCK}__back-title`}>
          <FormattedMessage id={'admin-module.page.heading'} />
        </div>
      </Button.IconButton>
      <Card>
        <div className={`${ADMIN_MODULE_BLOCK}__search`}>
          <DataGridHeader
            tableId={`${ADMIN_MODULE_BLOCK}-list`}
            tabsArray={Object.values(adminModuleTabs)}
            selectedTab={!isNullOrUndefined(selectedTab) ? selectedTab : {}}
            onSelectTab={(tab) => selectAdminModuleTab({ tab })}
          />
          {switchAdminModuleTabs(selectedTab)}
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = ({
  data: {
    selectedProject: { id },
    adminModuleCurrentTab: { selectedTab },
    currentUser,
    adminModuleMaintenacePageData,
    countryManagerContainerList,
  },
}) => ({
  projectId: id,
  selectedTab,
  currentUser: currentUser.data,
  adminModuleMaintenacePageList: adminModuleMaintenacePageData,
  countryManagerContainerList,
});

const mapDispatchToProps = {
  push,
  selectAdminModuleTab: requestCurrentAdminModuleTab,
  maintenanceBannerSaveAction,
  getContainerListWhereUserIsCountryManagerAction,
  clearAdminPageDataAction,
};
AdminModulePage.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
  /**Project Id */
  projectId: PropTypes.string,
  /**Function fired to select tab in admin module */
  selectAdminModuleTab: PropTypes.func.isRequired,
  /**model to set/get selected tab in admin module */
  selectedTab: PropTypes.instanceOf(TabModel).isRequired,
  /**To get current user related data */
  currentUser: PropTypes.instanceOf(Object).isRequired,
  /**Model for maintaining list of banners in admin module maintenance tab */
  adminModuleMaintenacePageList: PropTypes.instanceOf(
    AdminModuleMaintenanceList,
  ).isRequired,
  /**function fired to save maintenance page data */
  maintenanceBannerSaveAction: PropTypes.func.isRequired,
  /**function to get country list where user is country-manager. */
  getContainerListWhereUserIsCountryManagerAction: PropTypes.func.isRequired,
  /**Action method to clear admin page data on umount of a admin module component */
  clearAdminPageDataAction: PropTypes.func.isRequired,
  /** Model that contains admins containers list data including default users data */
  countryManagerContainerList: PropTypes.instanceOf(ContainerForm),
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminModulePage);
