import { handleActions } from 'redux-actions';

import OCRNavigationModel from 'models/api/ocr-navigation-api-model';
import {
  navigationDataLoading,
  navigationDataLoaded,
  navigationDataError,
} from 'store/actions/ocr-navigation-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [navigationDataLoading]: (ocrnavigation) => {
      return ocrnavigation.setLoading();
    },
    [navigationDataLoaded]: (ocrnavigation, { payload }) => {
      return ocrnavigation.setLoaded(payload);
    },
    [navigationDataError]: (ocrnavigation, { payload }) => {
      return ocrnavigation.setError(payload);
    },
    [clearStatementPageStoreAction]: () => {
      return new OCRNavigationModel();
    },
  },
  new OCRNavigationModel(),
);
