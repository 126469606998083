import ApiModel from 'models/api-model';

export class PinnedDetials extends ApiModel({
  data: {
    projects: [],
    statements: [],
  },
  status: null,
}) {
  processResponse(response) {
    return {
      data: {
        projects: response.data
          ? response.data.filter(
              (data) => data.statementId === 0 && data.pinType === 'CLIENT',
            )
          : [],
        statements: response.data
          ? response.data.filter(
              (data) => data.statementId !== 0 && data.pinType === 'STATEMENT',
            )
          : [],
      },
      status: response.status,
    };
  }

  //getting the pinned projects length
  getPinnedProjectsLength() {
    return this.data.projects.length;
  }

  //getting the pinned statements length
  getPinnedStatementsLength() {
    return this.data.statements.length;
  }

  //getting the pinned statements list
  get pinnedStatements() {
    return this.data.statements.length ? this.data.statements : [];
  }

  //getting the pinned project list
  get pinnedProjects() {
    return this.data.projects.length ? this.data.projects : [];
  }

  // adding new item(project or statement) to existing list
  addNewPinnedItem(payload) {
    return payload &&
      payload.data &&
      payload.data.statementId === 0 &&
      payload.data.pinType === 'CLIENT'
      ? this.merge({
          data: {
            ...this.data,
            projects: this.data.projects.concat([payload.data]),
          },
        })
      : this.merge({
          data: {
            ...this.data,
            statements: this.data.statements.concat([payload.data]),
          },
        });
  }

  // remove new item(project or statement) to existing list
  removingPinnedItem(payload) {
    return payload &&
      payload.data &&
      payload.data.statementId === 0 &&
      payload.data.pinType === 'CLIENT'
      ? this.merge({
          data: {
            ...this.data,
            projects: this.data.projects.filter(
              (item) => item.clientId !== payload.data.clientId,
            ),
          },
        })
      : this.merge({
          data: {
            ...this.data,
            statements: this.data.statements.filter(
              (item) => item.statementId !== payload.data.statementId,
            ),
          },
        });
  }
}
