import { handleActions } from 'redux-actions';

import {
  leftRevisionLoading,
  leftRevisionLoaded,
  leftRevisionError,
} from 'store/actions/left-revision-actions';
import {
  clearStatementPageStoreAction,
  clearLeftStatementPageStoreAction,
  clearLeftStatementContent,
} from 'store/actions/statement-content-actions';

import Revision from 'models/api/revision-api-model';

export default handleActions(
  {
    [leftRevisionLoading]: (revision) => {
      return revision.setLoading();
    },
    [leftRevisionError]: (revision, { payload }) => {
      return revision.setError(payload);
    },
    [leftRevisionLoaded]: (revision, { payload }) => {
      return revision.setLoaded(payload);
    },
    [clearLeftStatementContent]: () => {
      return new Revision();
    },
    [clearStatementPageStoreAction]: (revision, { payload }) => {
      return new Revision();
    },
    [clearLeftStatementPageStoreAction]: (revision, { payload }) => {
      return new Revision();
    },
  },
  new Revision(),
);
