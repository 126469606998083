import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { LEFT_PANELS, RIGHT_PANELS } from 'constants/feature/panel-constants';
import { toggleComfortAnnotationPanel } from 'store/actions/panel-controller-actions';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import ComfortLetterList from 'models/api/comfort-letter-list-api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  fetchComfortAnnotationListForRevision,
  fetchComfortAnnotationListForRevisionWithoutLoading,
  deleteComfortAnnotation,
} from 'store/actions/comfort-annotations-list-actions';
import { EditComfortLetterModal } from 'components/feature/element-panel/comfort-letter-panel/edit-comfort-letter-modal';
import ElementDetails from 'models/api/element-details-api-model';
import Loading from 'components/common/loading-component';
import { DeleteComfortAnnotationModal } from 'components/feature/element-panel/comfort-letter-panel/delete-comfort-annotation-modal';
import { COMFORT_ANNOTATION_INFORMATION_TOOLTIP } from 'constants/feature/panel-constants';
import { getElementComfortLettersWithoutLoading } from 'store/actions/element-panel-actions';
import { comfortLetterElementIdsUpdatedOrDeleted } from 'store/actions/statement-content-annotation-creation-actions';

export const COMFORT_ANNOTATION_PANEL_BLOCK = 'comfort-annotation-panel';
const COMFORT_ANNOTATION_INFO_ICON_SIZE = '18px';
const ComfortAnnotationList = lazy(() =>
  import(
    'components/feature/toolkit/toolkit-comfort-annotation/_toolkit-comfort-annotation-panel-comfort-annotation-list'
  ),
);
export const ComfortAnnotationPanel = ({
  show,
  onCancelPanel,
  comfortAnnotationList,
  fetchComfortAnnotationList,
  fetchComfortAnnotationListWithoutLoading,
  revisionId,
  deleteComfortAnnotation,
  rightPanel,
  getElementComfortLettersWithoutLoading,
  elementDetails,
  comfortLetterElementIdsUpdatedOrDeleted,
  isOCR,
}) => {
  const [showEditComfortLetterModal, setShowEditComfortLetterModal] =
    useState(false);
  const [comfortLetterToEdit, setComfortLetterToEdit] = useState(null);
  const [comfortAnnotationToDelete, setComfortAnnotationToDelete] =
    useState(null);

  const _handleComfortletterEditClick = useCallback(({ comfortAnnotation }) => {
    setComfortLetterToEdit(comfortAnnotation);
    setShowEditComfortLetterModal(true);
  }, []);

  const _handleComfortAnnotationDeleteClick = useCallback(
    ({ comfortAnnotation }) => {
      setComfortAnnotationToDelete(comfortAnnotation);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // executes code only if comfort letter toolkit and right panels is opened based on element details is modified
  useEffect(() => {
    show &&
      rightPanel &&
      getElementComfortLettersWithoutLoading({
        elementId: elementDetails.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementDetails]);

  useEffect(() => {
    show &&
      !isNullOrUndefined(revisionId) &&
      fetchComfortAnnotationList({ revisionId });
  }, [show, revisionId, fetchComfortAnnotationList]);
  return (
    <>
      <Flyout
        className={COMFORT_ANNOTATION_PANEL_BLOCK}
        show={show}
        position={'left'}
      >
        <FlyoutHeader enableClose onClose={onCancelPanel}>
          <div className={`${COMFORT_ANNOTATION_PANEL_BLOCK}__title`}>
            <FormattedMessage id={'comfort-annotation-panel.title'} />
          </div>
          <Tooltip {...COMFORT_ANNOTATION_INFORMATION_TOOLTIP}>
            <Information
              name={'information'}
              className={`${COMFORT_ANNOTATION_PANEL_BLOCK}__info_icon`}
              width={COMFORT_ANNOTATION_INFO_ICON_SIZE}
              height={COMFORT_ANNOTATION_INFO_ICON_SIZE}
            />
          </Tooltip>
        </FlyoutHeader>
        <FlyoutPanelContent>
          <Suspense fallback={<Loading />}>
            <ComfortAnnotationList
              comfortAnnotationList={comfortAnnotationList}
              onDeleteComfortAnnotation={_handleComfortAnnotationDeleteClick}
              onEditComfortletter={_handleComfortletterEditClick}
            />
          </Suspense>
        </FlyoutPanelContent>
      </Flyout>
      {comfortAnnotationToDelete && (
        <DeleteComfortAnnotationModal
          onDelete={() => {
            deleteComfortAnnotation({
              comfortAnnotation: comfortAnnotationToDelete,
            });
            setComfortAnnotationToDelete(null);
          }}
          onClose={() => {
            setComfortAnnotationToDelete(null);
          }}
        />
      )}
      {showEditComfortLetterModal && (
        <EditComfortLetterModal
          comfortLetterToEdit={comfortLetterToEdit}
          onCancel={() => setShowEditComfortLetterModal(false)}
          onSave={() => {
            setShowEditComfortLetterModal(false);
            fetchComfortAnnotationListWithoutLoading({ revisionId });
            comfortLetterElementIdsUpdatedOrDeleted(
              comfortLetterToEdit.customLabel,
              isOCR,
            );
          }}
          elementDetails={new ElementDetails({ data: { revisionId } })}
        />
      )}
    </>
  );
};

ComfortAnnotationPanel.propTypes = {
  /** Indicates if the Comfort Annotation panel should be shown */
  show: PropTypes.bool.isRequired,
  /** Indicates if the right panel opened */
  rightPanel: PropTypes.bool.isRequired,
  /** function to handle close/cancel panel */
  onCancelPanel: PropTypes.func,
  /** List of comfort letters for current revision id */
  comfortAnnotationList: PropTypes.instanceOf(ComfortLetterList).isRequired,
  /** Action to fetch comfort annotation list for a revision */
  fetchComfortAnnotationList: PropTypes.func.isRequired,
  /** Action to fetch comfort letter list for a revision without loading */
  fetchComfortAnnotationListWithoutLoading: PropTypes.func.isRequired,
  /** Action to fetch comfort letter list based on selected element in right panel without loading */
  getElementComfortLettersWithoutLoading: PropTypes.func.isRequired,
  /** Current revision id */
  revisionId: PropTypes.number,
  /** Action to delete comfort annotation */
  deleteComfortAnnotation: PropTypes.func.isRequired,
  /** selected element details in right panel */
  elementDetails: PropTypes.instanceOf(ElementDetails),
  /** returns true if statement is OCR statement */
  isOCR: PropTypes.bool,
};

const mapStateToProps = ({
  data: {
    comfortAnnotationPanel,
    revision,
    elementPanel: { elementDetails },
    selectedStatement: { isOCR },
  },
  ui: {
    statementPage: {
      panels: { left, right },
    },
  },
}) => ({
  show: left === LEFT_PANELS.COMFORT_ANNOTATION,
  rightPanel: right === RIGHT_PANELS.ELEMENT,
  revisionId: revision.id,
  comfortAnnotationList: comfortAnnotationPanel,
  elementDetails,
  isOCR,
});

const mapDispatchToProps = {
  onCancelPanel: toggleComfortAnnotationPanel,
  fetchComfortAnnotationList: fetchComfortAnnotationListForRevision,
  fetchComfortAnnotationListWithoutLoading:
    fetchComfortAnnotationListForRevisionWithoutLoading,
  deleteComfortAnnotation,
  getElementComfortLettersWithoutLoading,
  comfortLetterElementIdsUpdatedOrDeleted,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComfortAnnotationPanel);
