import htmlToText from 'html-to-text';
import enMessages from 'i18n/en.json';

export const EMPTY_STRING = '';
export const SINGLE_COMMA_SPACE_STRING = ', ';
export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
export const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
export const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;

export const LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER = 'tieout_';
export const TIME_TO_LIVE_FOR_LOCALE_LANGUAGE_JSON = ONE_DAY_IN_MS;
export const TIEOUT_EVENT_FOR_LANGUAGE_UPDATE = 'UserUpdatedLanguagePreference';

export const fireTieoutEventForLanguageUpdate = () => {
  const userUpdatedLanguageEvent = new Event(TIEOUT_EVENT_FOR_LANGUAGE_UPDATE);
  window.dispatchEvent(userUpdatedLanguageEvent);
};

export const getKeyForLocale = (locale) =>
  `${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}${locale}`;

export const updateLocaleMessageInStorage = (locale, localeMessagesWithTTL) => {
  localStorage.setItem(
    getKeyForLocale(locale),
    JSON.stringify(localeMessagesWithTTL),
  );
  const event = new StorageEvent('storage', {
    key: getKeyForLocale(locale),
  });
  window.dispatchEvent(event);
};

export const getMessagesForLocale = (locale = 'en-US') =>
  JSON.parse(
    localStorage.getItem(`${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}${locale}`),
  );

export const clearLocaleMessagesFromLocalStorage = (locale = 'en-US') => {
  localStorage.removeItem(`${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}${locale}`);
};

export const getTranslationByKey = ({ locale, key }) => {
  let messages = getMessagesForLocale(locale);
  if (messages && messages.data && messages.data[key]) {
    return messages.data[key];
  } else {
    return enMessages[key];
  }
};

export const getTextFromHtml = (richText) => {
  const text = htmlToText.fromString(richText, {
    wordwrap: false, // by default it adds new lines after 80 chars, need to disable to ensure no new characters are added
  });
  // we need to remove ascii codes from strings
  // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
  // eslint-disable-next-line no-control-regex
  return text.replace(/[^\x00-\x7F]/g, '');
};

export const checkIfStringContainsOnlySpaces = (text) => {
  // after removing all whitespaces, If the length of the resulting string is 0,
  // then you can be sure the original string only contained whitespace
  return text && text.replace(/\s/g, '').length === 0;
};
