import React, { useEffect, useState } from 'react';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropdown from 'components/common/dropdown-component';
import SupportedLanguages from 'models/api/supported-languages-api-model';
import ConditionalRender from 'components/util/conditional-render-component';
import { useDispatch } from 'react-redux';
import { savePreferredLanguageAndUpdateLocale } from 'store/actions/locale-actions';
import { fetchSupportedLanguage } from 'api/statement-creation-page-data-api';
import { isNullOrUndefined } from 'utils/object-utils';
import { CurrentUser } from 'models/api/current-user-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';

const USER_SETTINGS_MODAL_BLOCK = 'user-settings';
const USER_SETTINGS_MODAL_BLOCK_ID = 'user-settings-id';

const UserSettingsModal = ({ onClose, currentUser, locale }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(locale);
  const [languageOptions, setLanguageOptions] = useState(
    new SupportedLanguages().setLoading(),
  );
  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(
      savePreferredLanguageAndUpdateLocale({
        selectedLanguage,
        userId: currentUser.data.id,
      }),
    );
    onClose();
  };

  const fetchSupportedLanguageAndSetOptions = async () => {
    try {
      const supportedLanguageResponse = await fetchSupportedLanguage();
      setLanguageOptions(languageOptions.setLoaded(supportedLanguageResponse));
    } catch (err) {
      console.error(
        'An error occured while fetching list of supported language',
      );
      console.error(err);
      setLanguageOptions(languageOptions.setError(err));
    }
  };

  useEffect(() => {
    fetchSupportedLanguageAndSetOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      id={USER_SETTINGS_MODAL_BLOCK}
      title={'user-settings.header'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.confirm',
          onClick: onConfirm,
          // disable until new language is chosen
          disabled:
            isNullOrUndefined(locale) ||
            isNullOrUndefined(selectedLanguage) ||
            locale === selectedLanguage,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
    >
      <div className={`${USER_SETTINGS_MODAL_BLOCK}__label`}>
        <FormattedMessage id="user-settings-select-language-guidance-message" />
      </div>
      <ConditionalRender dependencies={[languageOptions]}>
        {selectedLanguage && (
          <Dropdown
            id={`${USER_SETTINGS_MODAL_BLOCK_ID}-pagintation-dropdown-id`}
            options={languageOptions.getLanguagesMenu()}
            onSelectOption={(val) => {
              setSelectedLanguage(val.value);
            }}
            value={languageOptions.getLangaugeMenuByLanguageCode(
              selectedLanguage,
            )}
            width={'50%'}
            isNotIntl={true}
          />
        )}
      </ConditionalRender>
      <div
        className={`${USER_SETTINGS_MODAL_BLOCK}__additional-information-message`}
      >
        <Banner
          width={'100%'}
          id={`${USER_SETTINGS_MODAL_BLOCK_ID}-information-guidance`}
          className={`${USER_SETTINGS_MODAL_BLOCK}__guidance`}
          type={BANNER_TYPES.INFORMATION}
          bannerCopy={'user-settings.additional-information-message'}
          isInternationalized
        />
      </div>
    </Modal>
  );
};

UserSettingsModal.propTypes = {
  // Function to be called when user clicks on cross or cancel button of modal
  onClose: PropTypes.func.isRequired,
  // current logged in user details. Used to save user's preferred language
  currentUser: PropTypes.instanceOf(CurrentUser).isRequired,
  // represents current language in which application has rendered
  locale: PropTypes.string.isRequired,
};

export default UserSettingsModal;
