import React from 'react';
import moment from 'moment';

import { convertUTCTimeStampToLocalTime } from 'utils/time-formatter-util';
import { DataGridColumn } from 'components/common/data-grid/data-grid-component';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { DataGridKebabColumn } from 'components/common/data-grid/data-grid-component';
import TabModel from 'models/utils/common/tab-model';
import StatementStatusModel from 'models/utils/common/statement-status-model';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { ReactComponent as RevisionIcon } from 'icons/open-folder.svg';
import { ReactComponent as AsteriskIcon } from 'icons/asterisk.svg';
import Tooltip from 'components/common/tool-tip-component';
import StatementProgressBar from 'components/feature/statement-list/statement-list-progress-bar-component';
import { STATEMENT_LIST_BLOCK } from 'components/feature/statement-list/statement-list-component';
import Permissions from 'permissions/permissions';
import { KebabMenuOption } from 'models/utils/common/menu/menu-option-model';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from 'utils/object-utils';
import { ReactComponent as SharingCompleteIcon } from 'icons/status-complete.svg';
import { ReactComponent as SharingInProgressIcon } from 'icons/status-in-progress.svg';
import { ReactComponent as SharingErrorIcon } from 'icons/status-error.svg';
import RolesUtil from 'permissions/utils/roles';
import classnames from 'classnames';
import { ReactComponent as Information } from 'icons/info.svg';
import { ReactComponent as FilledPin } from 'icons/FilledPin.svg';

const PIN_ICON_SIZE = '22px';
const STATEMENT_TYPE_MAP = {
  TEN_K: '10-K',
  TEN_Q: '10-Q',
  SIX_K: '6-K',
  PRESS_RELEASE: 'Press Release',
  TWENTY_F: '20-F',
  FORTY_F: '40-F',
  NCSR: 'N-CSR',
  PRIVATE_ANNUAL: 'Private Annual',
  PRIVATE_QUARTERLY: 'Private Quarterly',
  PROXY_STATEMENT: 'Proxy Statement',
  COMFORT_LETTER: 'Comfort Letter',
  REGISTRATION_STATEMENT: 'Registration Statement',
  INSURANCE_STATEMENT: 'Insurance Statement',
  IM_FUND_REPORT: 'IM fund report',
  AGENCY_REPORT: 'Agency Report',
};

/** these ENUM string values should correspond to the keys in STATEMENT_STATUS_LABELS */
export const STATEMENT_STATUS_ENUM = {
  1: 'IN_PROGRESS',
  2: 'PENDING_APPROVAL',
  3: 'ARCHIVED',
  4: 'DELETED',
  5: 'APPROVAL_INITIATED',
};

export const STATEMENT_STATUS_LABELS = {
  IN_PROGRESS: new StatementStatusModel({
    id: 1,
    name: 'In Progress',
    desc: 'In Progress',
    lname: 'in progress',
    intlIdentifier: 'inProgress',
  }),

  PENDING_APPROVAL: new StatementStatusModel({
    id: 2,
    name: 'Pending Approval',
    desc: 'Read Only',
    lname: 'is pending approval',
    intlIdentifier: 'pendingApproval',
  }),
  ARCHIVED: new StatementStatusModel({
    id: 3,
    name: 'Archived',
    desc: 'Read Only',
    lname: 'archived',
    intlIdentifier: 'archived',
  }),
  DELETED: new StatementStatusModel({
    id: 4,
    name: 'Deleted',
    desc: 'Read Only',
    lname: 'deleted',
    intlIdentifier: 'deleted',
  }),
  APPROVAL_INITIATED: new StatementStatusModel({
    id: 5,
    name: 'Approval Initiated',
    desc: 'Read Only',
    lname: 'deleted',
    intlIdentifier: 'approvalInitiated',
  }),
};

export const VIEW_REVISIONS_MODAL_TOOLTIP = new TooltipOptions({
  text: 'statement-list.column.revision-tool-tip',
  id: 'statement-list-id-view-revisions-modal-button-tool-tip',
  position: 'top',
});

export const VIEW_DELETED_REVISIONS_MODAL_TOOLTIP = new TooltipOptions({
  text: 'statement-list.column.deleted-revision-tool-tip',
  id: 'statement-list-id-view-revisions-modal-button-tool-tip',
  position: 'top',
});

export const PIN_TOOLTIP = new TooltipOptions({
  text: 'common.pin',
  id: 'statement-list-pin-tool-tip',
  position: 'top',
});

export const DELETE_STATEMENT_OPTIONS = {
  CURRENT: 1,
  ALL: 2,
};

export const STATEMENT_LIST_TABS = {
  ACTIVE: new TabModel({
    id: 1,
    apiParam: 'active',
    intlTitle: 'statement-list.tabs.active',
  }),
  ARCHIVED: new TabModel({
    id: 2,
    apiParam: 'archived',
    intlTitle: 'statement-list.tabs.wrapped-up',
  }),
  DELETED: new TabModel({
    id: 3,
    apiParam: 'deleted',
    intlTitle: 'statement-list.tabs.deleted',
  }),
};

export const STATEMENT_LIST_SORT_COLUMN_KEYS = {
  percentageCompleted: 'percentageCompleted',
  statementName: 'statementName',
  fiscalYear: 'fiscalYear',
  periodEnding: 'periodEnding',
  type: 'type',
  latestRevision: 'latestRevision',
  lastModifiedDate: 'lastModifiedDate',
  archiveDate: 'archiveDate',
  revisionDeletedDate: 'revisionDeletedDate',
  pinnedStatement: 'pinnedStatement',
  deletedBy: 'deletedBy',
};
const copyStatementPendingApprovalTooltip = new TooltipOptions({
  text: 'permissions.copy-statement.pending-approval.disabled',
  id: 'copy-statement-disabled-tooltip-id',
  position: 'left',
});
const copyStatementNoPermissionTooltip = new TooltipOptions({
  text: 'permissions.copy-statement.no-permission.disabled',
  id: 'copy-statement-disabled-tooltip-id',
  position: 'left',
});

const copyStatementNoFailedWorkflow = new TooltipOptions({
  text: 'permissions.copy-statement.failed-workflow.disabled',
  id: 'copy-statement-disabled-tooltip-id',
  position: 'left',
});

const copyStatementRevisionUpload = new TooltipOptions({
  text: 'permissions.copy-statement.revision-upload.disabled',
  id: 'copy-statement-disabled-tooltip-id',
  position: 'left',
});

const EDITED_STATEMENT_ADMIN_DISPLAY = (tooltip, displayText, colId) => (
  <div className={'statement-list__list--admin-columns-container'}>
    <Tooltip {...tooltip}>
      <AsteriskIcon
        className={'statement-list__list--admin-columns-icon'}
        width={'14'}
        height={'14'}
      />
    </Tooltip>
    <MultiLineEllipsis
      toolTipID={colId}
      text={displayText}
      isNotInternationalized
    />
  </div>
);

const PERCENT_VERIFIED_COLUMN = new DataGridColumn({
  key: STATEMENT_LIST_SORT_COLUMN_KEYS.percentageCompleted,
  header: 'statement-list.column.percentage-complete',
  sortable: true,
  width: '10%',
  formatter: (statement) => {
    return (
      <>
        <div>{`${statement.percentageComplete}%`}</div>
        <StatementProgressBar
          percentVerified={
            Math.round(statement.percentageCompleted * 100 * 100) / 100
          }
        />
      </>
    );
  },
});
const STATEMENT_NAME_COLUMN = (linkStatus) =>
  new DataGridColumn({
    key: STATEMENT_LIST_SORT_COLUMN_KEYS.statementName,
    header: 'statement-list.column.statement-name',
    sortable: true,
    filterable: true,
    className: 'statement-name-filter',
    width: !isNullOrUndefined(linkStatus) ? '16%' : '23%',
    formatter: (statement, { rowKey }) => {
      const renderName = () => (
        <MultiLineEllipsis
          toolTipID={colId}
          text={statement.statementName}
          isNotInternationalized
        />
      );
      const colId = `statement-name-col-${rowKey}`;
      if (
        statement.getStatementStatus() ===
        STATEMENT_STATUS_LABELS.PENDING_APPROVAL
      ) {
        return (
          <div>
            {statement.legalHold ? (
              <div className={`${STATEMENT_LIST_BLOCK}__legal-hold`}>
                <div className={`${STATEMENT_LIST_BLOCK}__legal-hold-prefix`}>
                  <FormattedMessage id="common.hold" />
                </div>
                {renderName()}
              </div>
            ) : (
              renderName()
            )}
            <div className="statement-name-pending-approval-text">
              <FormattedMessage id="statement-list.column.statement-name.suffix.status.pending-approval" />
            </div>
          </div>
        );
      } else if (
        statement.getStatementStatus() ===
        STATEMENT_STATUS_LABELS.APPROVAL_INITIATED
      ) {
        return (
          <div>
            {statement.legalHold ? (
              <div className={`${STATEMENT_LIST_BLOCK}__legal-hold`}>
                <div className={`${STATEMENT_LIST_BLOCK}__legal-hold-prefix`}>
                  <FormattedMessage id="common.hold" />
                </div>
                {renderName()}
              </div>
            ) : (
              renderName()
            )}
            <div>
              <FormattedMessage id="statement-list.column.statement-name.suffix.status.approval-initiated" />
            </div>
          </div>
        );
      } else if (statement.legalHold) {
        return (
          <div className={`${STATEMENT_LIST_BLOCK}__legal-hold`}>
            <div className={`${STATEMENT_LIST_BLOCK}__legal-hold-prefix`}>
              <FormattedMessage id="common.hold" />
            </div>
            {renderName()}
          </div>
        );
      }
      return renderName();
    },
  });

const FISCAL_YEAR_COLUMN = new DataGridColumn({
  key: STATEMENT_LIST_SORT_COLUMN_KEYS.fiscalYear,
  sortable: true,
  header: 'statement-list.column.fiscal-year',
  width: '9%',
  formatter: (statement, { rowKey }) => {
    const colId = `statement-fiscal-col-${rowKey}`;
    const _userCanSeeDisplayValueOfModifiedStatement =
      Permissions.Statement.canSeeDisplayValueOfModifiedStatement();
    let displayText = `${statement.fiscalYear}`;
    let originalFiscalYearTooltip = new TooltipOptions({
      text: `${statement.originalFiscalYear}`,
      id: `statement-list-fiscal-year-tooltip-${rowKey}`,
      position: 'left',
    });
    if (
      _userCanSeeDisplayValueOfModifiedStatement &&
      statement.fiscalYear !== statement.originalFiscalYear
    ) {
      return EDITED_STATEMENT_ADMIN_DISPLAY(
        originalFiscalYearTooltip,
        displayText,
        colId,
      );
    }
    return displayText;
  },
});

const PERIOD_ENDING_COLUMN = new DataGridColumn({
  key: STATEMENT_LIST_SORT_COLUMN_KEYS.periodEnding,
  sortable: true,
  header: 'statement-list.column.period-ending',
  width: '12%',
  formatter: (statement, { rowKey }) => {
    const colId = `statement-period-col-${rowKey}`;
    const _userCanSeeDisplayValueOfModifiedStatement =
      Permissions.Statement.canSeeDisplayValueOfModifiedStatement();
    let displayText = `${moment
      .utc(statement.periodEnding)
      .format(DATE_FORMATS.MONTH_DAY_YEAR)}`;
    const tooltipText = `${moment
      .utc(statement.originalPeriodEnding)
      .format(DATE_FORMATS.MONTH_DAY_YEAR)}`;
    let originalPeriodEndingTooltip = new TooltipOptions({
      text: tooltipText,
      id: `statement-list-period-ending-tooltip-${rowKey}`,
      position: 'left',
    });

    if (
      _userCanSeeDisplayValueOfModifiedStatement &&
      statement.periodEnding !== statement.originalPeriodEnding
    ) {
      return EDITED_STATEMENT_ADMIN_DISPLAY(
        originalPeriodEndingTooltip,
        displayText,
        colId,
      );
    }

    return displayText;
  },
});

const STATEMENT_TYPE_COLUMN = new DataGridColumn({
  key: STATEMENT_LIST_SORT_COLUMN_KEYS.type,
  sortable: true,
  header: 'statement-list.column.type',
  width: '8%',
  formatter: (statement, { rowKey }) => {
    const colId = `statement-type-col-${rowKey}`;
    const _userCanSeeDisplayValueOfModifiedStatement =
      Permissions.Statement.canSeeDisplayValueOfModifiedStatement();
    let displayText = statement.type;
    let statementTypeTooltipText = statement.originalType;
    if (statement.originalType === STATEMENT_TYPE_MAP.TEN_Q) {
      statementTypeTooltipText = `${statement.originalType}${statement.originalQuarterNumber}`;
    } else if (
      statement.originalType === STATEMENT_TYPE_MAP.PRIVATE_QUARTERLY
    ) {
      statementTypeTooltipText = `${statement.originalType} Q${statement.originalQuarterNumber}`;
    }

    const originalStatementTypeTooltip = new TooltipOptions({
      text: statementTypeTooltipText,
      id: `statement-list-statement-type-tooltip-${rowKey}`,
      position: 'left',
    });

    if (
      _userCanSeeDisplayValueOfModifiedStatement &&
      (statement.type !== statement.originalType ||
        statement.quarter !== statement.originalQuarter)
    ) {
      if (statement.type === STATEMENT_TYPE_MAP.TEN_Q) {
        displayText = `${statement.type}${statement.quarterNumber}`; // e.g. 10-Q1
        return EDITED_STATEMENT_ADMIN_DISPLAY(
          originalStatementTypeTooltip,
          displayText,
          colId,
        );
      } else if (statement.type === STATEMENT_TYPE_MAP.PRIVATE_QUARTERLY) {
        displayText = `${statement.type} Q${statement.quarterNumber}`; // e.g. Private Quarterly Q1
        return EDITED_STATEMENT_ADMIN_DISPLAY(
          originalStatementTypeTooltip,
          displayText,
          colId,
        );
      }

      return EDITED_STATEMENT_ADMIN_DISPLAY(
        originalStatementTypeTooltip,
        displayText,
        colId,
      );
    }

    return (
      <MultiLineEllipsis
        toolTipID={colId}
        text={displayText}
        isNotInternationalized
      />
    );
  },
});

const REVISION_COLUMN = ({
  onRevisonClick,
  viewRevisionToolTip,
  displayIcon = true,
}) =>
  new DataGridColumn({
    key: STATEMENT_LIST_SORT_COLUMN_KEYS.latestRevision,
    sortable: true,
    header: 'statement-list.column.revision',
    width: '9%',
    formatter: (statement) => (
      <div className={'statement-list__list--revision-container'}>
        {statement.revisionNumber}
        {displayIcon && (
          <Tooltip {...viewRevisionToolTip}>
            <RevisionIcon
              role={'button'}
              className={'statement-list__list--revision-icon'}
              onClick={(e) => {
                e.stopPropagation();
                onRevisonClick(statement);
              }}
            />
          </Tooltip>
        )}
      </div>
    ),
  });

const PIN_COLUMN = new DataGridColumn({
  key: STATEMENT_LIST_SORT_COLUMN_KEYS.pinnedStatement,
  header: '',
  width: '4%',
  formatter: (statement) => {
    if (!isNullOrUndefined(statement.pinnedDatetime)) {
      return (
        <div className={'statement-list--pinned'}>
          <FilledPin width={PIN_ICON_SIZE} height={PIN_ICON_SIZE} />
        </div>
      );
    }
  },
});

const TARGET_ARCHIVE_DATE_COLUMN = new DataGridColumn({
  key: STATEMENT_LIST_SORT_COLUMN_KEYS.archiveDate,
  sortable: true,
  header: 'statement-list.column.wrap-up-date-notifier',
  width: '14%',
  formatter: (statement) => {
    const currentDate = moment().utc().startOf('day');
    const archiveDate = moment.utc(statement.archiveDate);
    const diff = currentDate.diff(archiveDate, 'days');
    const renderRemainingDays = () => {
      if (diff > 0) {
        return (
          <div
            className={classnames(
              diff <= 30
                ? `${STATEMENT_LIST_BLOCK}__overdue-within-thirty-days`
                : `${STATEMENT_LIST_BLOCK}__overdue-past-thirty-days`,
            )}
          >
            <FormattedMessage
              id={'statement-list.column.wrap-up-date-notifier.overdue'}
              values={{
                days: diff,
              }}
            />
          </div>
        );
      } else {
        return (
          <div>
            <FormattedMessage
              id={
                'statement-list.column.wrap-up-date-notifier.with-days-remaining'
              }
              values={{
                days: Math.abs(diff),
              }}
            />
          </div>
        );
      }
    };

    return (
      <div className={`${STATEMENT_LIST_BLOCK}__days-container`}>
        <Tooltip
          id={`statement-list.column.wrap-up-date.tooltip-id-${statement.id}`}
          text={{
            id: 'statement-list.column.target-wrap-up-date.tooltip',
            values: {
              date: moment
                .utc(statement.archiveDate)
                .format(DATE_FORMATS.MONTH_DAY_YEAR),
            },
          }}
        >
          {renderRemainingDays()}
        </Tooltip>
        {diff > 0 && (
          <Tooltip
            id={`statement-list.column.wrap-up-date.statement-overdue.tooltip-id-${statement.id}`}
            text={{
              id: 'statement-list.column.statement-overdue.tooltip',
            }}
          >
            <Information
              className={`${STATEMENT_LIST_BLOCK}__information-icon`}
            />
          </Tooltip>
        )}
      </div>
    );
  },
});

const OMNIA_EXPORT_COLUMN = new DataGridColumn({
  key: STATEMENT_LIST_SORT_COLUMN_KEYS.omExportStatus,
  header: 'statement-list.column.omnia-export',
  sortable: true,
  width: '7%',
  formatter: (statement, { rowKey }) => {
    if (statement.omExportStatus) {
      if (statement.omExportStatus === 1 || statement.omExportStatus === 2) {
        const documentState =
          statement.omExportStatus === 2 ? 'in progress' : 'initiated';

        return (
          <div>
            <Tooltip
              id={`statement-list.column.omnia-export-in-progress-tool-tip-${rowKey}`}
              text={{
                id: 'statement-list.column.omnia-export-in-progress-tool-tip',
                values: { documentState: documentState },
              }}
              isNotInternationalized={false}
            >
              <SharingInProgressIcon />
            </Tooltip>
          </div>
        );
      } else if (
        statement.omExportStatus === 3 ||
        statement.omExportStatus === 4
      ) {
        return (
          <div>
            <Tooltip
              id={
                statement.omExportErrorInfo === 1
                  ? `statement-list.column.omnia-export-observer-error-tool-tip-${rowKey}`
                  : `statement-list.column.omnia-export-error-tool-tip-${rowKey}`
              }
              text={
                statement.omExportErrorInfo === 1
                  ? 'statement-list.column.omnia-export-observer-error-tool-tip'
                  : 'statement-list.column.omnia-export-error-tool-tip'
              }
            >
              <SharingErrorIcon />
            </Tooltip>
          </div>
        );
      } else if (statement.omExportStatus === 5) {
        const documentState =
          statement.omDocumentState === 1 ? 'Draft' : 'Final';
        const sentDate = isNullOrUndefined(statement.sentDate)
          ? null
          : `${moment(statement.sentDate)
              .local()
              .format(DATE_FORMATS.MONTH_DAY_YEAR_TIME)}`;
        return (
          <div>
            <div>
              <Tooltip
                id={`statement-list.column.omnia-export-complete-tool-tip-${rowKey}`}
                text={{
                  id: 'statement-list.column.omnia-export-complete-tool-tip',
                  values: {
                    documentState: documentState,
                    sentDate: sentDate,
                    sentBy: statement.sentBy,
                  },
                }}
                isNotInternationalized={false}
              >
                <SharingCompleteIcon />
              </Tooltip>
            </div>
          </div>
        );
      }
    }
    return null;
  },
});

export const getStatementListColumns = ({
  softDeleteAllRevisionsAction,
  softDeleteLatestRevisionAction,
  onRevisonClick,
  project,
  addRevisionAction,
  navigateToEditStatement,
  workflowsMap,
  currentUser,
  onSubmitWrapUpClick,
  onApproveWrapUpClick,
  copyStatement,
  containerRef,
  onPinClick,
  pinnedStatements,
  statementWorkflowSteps,
}) => {
  let options = [
    PERCENT_VERIFIED_COLUMN,
    STATEMENT_NAME_COLUMN(project.data.linkStatus),
    FISCAL_YEAR_COLUMN,
    PERIOD_ENDING_COLUMN,
    STATEMENT_TYPE_COLUMN,
    REVISION_COLUMN({
      onRevisonClick,
      viewRevisionToolTip: VIEW_REVISIONS_MODAL_TOOLTIP,
    }),
    new DataGridColumn({
      key: STATEMENT_LIST_SORT_COLUMN_KEYS.lastModifiedDate,
      sortable: true,
      header: 'statement-list.column.last-modified',
      width: '12%',
      formatter: (statement) => (
        <>
          {moment(statement.lastModifiedDate).format(
            DATE_FORMATS.MONTH_DAY_YEAR,
          )}
          <br />
          {convertUTCTimeStampToLocalTime(
            statement.lastModifiedDate,
            DATE_FORMATS.HOURS_MINUTES,
          )}
        </>
      ),
    }),
    TARGET_ARCHIVE_DATE_COLUMN,
    PIN_COLUMN,
  ];
  if (!isNullOrUndefined(project.data.linkStatus)) {
    options.push(OMNIA_EXPORT_COLUMN);
  }
  options.push(
    new DataGridKebabColumn({
      options: getStatementListKebabOptions({
        softDeleteAllRevisionsAction: softDeleteAllRevisionsAction,
        softDeleteLatestRevisionAction: softDeleteLatestRevisionAction,
        project: project,
        addRevisionAction: addRevisionAction,
        navigateToEditStatement: navigateToEditStatement,
        workflowsMap,
        currentUser,
        onSubmitWrapUpClick,
        onApproveWrapUpClick,
        copyStatement,
        onPinClick,
        pinnedStatements,
        statementWorkflowSteps,
      }),
      dropdownId: 'statement-kebab-id',
      className: 'statement-list__kebab-menu',
      usePortalMenu: false,
      containerRef: containerRef,
    }),
  );

  return options;
};

export const getArchivedStatementListColumns = ({
  project,
  copyStatement,
  workflowsMap,
  containerRef,
  statementWorkflowSteps,
}) => {
  const _userCanEdit = Permissions.Statement.canEdit();
  const _statementHasProcessingRevisions = (statement) =>
    workflowsMap &&
    workflowsMap.statementHasProcessingRevision({
      statementId: statement.id,
    });

  const _statementIsPendingApproval = (statement) =>
    statement.getStatementStatus() === STATEMENT_STATUS_LABELS.PENDING_APPROVAL;

  const _statementHasFailedWorkflow = (statement) => {
    const workflowsList = workflowsMap.getWorkflowsByStatementId(statement.id);
    return workflowsList.some(
      (w) =>
        w.status ===
        statementWorkflowSteps.isFailedWorkflowStep(w.documentProcessTypeEnum)
          .status,
    );
  };

  const _userCanCopyStatement =
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(project.id) ||
    RolesUtil.doesUserHavePPMDRoleForProject(project.id) ||
    RolesUtil.doesUserHavePreparerReviewerRoleForProject(project.id) ||
    RolesUtil.doesUserHaveAdminSupportAccess(project.id);

  const _statementIsImported = (statement) => statement.isImported;

  let options = [
    PERCENT_VERIFIED_COLUMN,
    STATEMENT_NAME_COLUMN(project.data.linkStatus),
    FISCAL_YEAR_COLUMN,
    PERIOD_ENDING_COLUMN,
    STATEMENT_TYPE_COLUMN,
    REVISION_COLUMN({ displayIcon: false }),
    new DataGridColumn({
      key: STATEMENT_LIST_SORT_COLUMN_KEYS.lastModifiedDate,
      sortable: true,
      header: 'statement-list.column.wrap-up-date',
      width: '12%',
      formatter: (statement) => (
        <>
          {moment(statement.lastModifiedDate).format(
            DATE_FORMATS.MONTH_DAY_YEAR,
          )}
          <br />
          {convertUTCTimeStampToLocalTime(
            statement.lastModifiedDate,
            DATE_FORMATS.HOURS_MINUTES,
          )}
        </>
      ),
    }),
  ];

  if (!isNullOrUndefined(project.data.linkStatus)) {
    options.push(OMNIA_EXPORT_COLUMN);
  }
  options.push(
    new DataGridKebabColumn({
      options: [
        new KebabMenuOption({
          title: 'statement-list.kebab.copy-statement',
          disabled: (statement) => {
            return (
              !_userCanEdit ||
              !_userCanCopyStatement ||
              _statementIsPendingApproval(statement) ||
              _statementHasFailedWorkflow(statement) ||
              _statementHasProcessingRevisions(statement)
            );
          },
          shouldShowOption: (statement) => !_statementIsImported(statement),
          onSelectOption: (statement) => copyStatement(statement),
          tooltip: (statement) => {
            if (!_userCanCopyStatement) {
              return copyStatementNoPermissionTooltip;
            }
            if (_statementIsPendingApproval(statement)) {
              return copyStatementPendingApprovalTooltip;
            }
            if (_statementHasFailedWorkflow(statement)) {
              return copyStatementNoFailedWorkflow;
            }
            if (_statementHasProcessingRevisions(statement)) {
              return copyStatementRevisionUpload;
            }
            return null;
          },
        }),
      ],
      dropdownId: 'statement-kebab-id',
      className: 'statement-list__kebab-menu',
      usePortalMenu: false,
      containerRef: containerRef,
    }),
  );

  return options;
};

export const getDeletedStatementListColumns = ({
  project,
  deleteAction,
  restoreAction,
  onRevisonClick,
  containerRef,
}) => {
  const _canRestoreSoftDeleted = Permissions.Statement.canRestoreSoftDeleted();
  const _canPermanentlyDelete = Permissions.Statement.canPermanentlyDelete();
  let permanentlyDeleteTooltip, restoreSoftDeletedTooltip;
  if (!_canRestoreSoftDeleted) {
    restoreSoftDeletedTooltip = new TooltipOptions({
      text: 'permissions.delete.statement',
      id: 'delete-statement-tooltip-id',
      position: 'left',
    });
  }

  permanentlyDeleteTooltip = new TooltipOptions({
    text: 'permissions.delete.statement',
    id: 'delete-statement-tooltip-id',
    position: 'left',
  });

  let options = [
    PERCENT_VERIFIED_COLUMN,
    STATEMENT_NAME_COLUMN(project.data.linkStatus),
    FISCAL_YEAR_COLUMN,
    PERIOD_ENDING_COLUMN,
    STATEMENT_TYPE_COLUMN,
    REVISION_COLUMN({
      onRevisonClick,
      viewRevisionToolTip: VIEW_DELETED_REVISIONS_MODAL_TOOLTIP,
    }),
    new DataGridColumn({
      key: STATEMENT_LIST_SORT_COLUMN_KEYS.revisionDeletedDate,
      sortable: true,
      header: 'statement-list.column.deleted-date',
      width: '12%',
      formatter: (statement) => (
        <>
          {moment(statement.revisionLastModifiedDate)
            .local()
            .format(DATE_FORMATS.MONTH_DAY_YEAR)}
          <br />
          {convertUTCTimeStampToLocalTime(
            statement.revisionLastModifiedDate,
            DATE_FORMATS.HOURS_MINUTES,
          )}
        </>
      ),
    }),
    new DataGridColumn({
      key: STATEMENT_LIST_SORT_COLUMN_KEYS.deletedBy,
      header: 'statement-list.column.deleted-by',
      width: '15%',
      formatter: (statement, { rowKey }) => {
        const colId = `statement-deleted-by-col-${rowKey}`;
        return (
          <MultiLineEllipsis
            toolTipID={colId}
            text={statement.revisionLastModifiedByName}
            isNotInternationalized
          />
        );
      },
    }),
  ];
  if (!isNullOrUndefined(project.data.linkStatus)) {
    options.push(OMNIA_EXPORT_COLUMN);
  }
  options.push(
    new DataGridKebabColumn({
      options: [
        new KebabMenuOption({
          title: 'statement-list.kebab.restore',
          disabled: !_canRestoreSoftDeleted,
          tooltip: restoreSoftDeletedTooltip,
          shouldShowOption: (statement) => !statement.isInArchivalFlow(),
          onSelectOption: (statement) =>
            restoreAction(statement.revisionId, statement.id),
        }),
        new KebabMenuOption({
          title: 'statement-list.kebab.delete-permanently',
          disabled: (statement) =>
            statement.isLegalHoldSet() || !_canPermanentlyDelete,
          tooltip: (statement) =>
            statement.isLegalHoldSet() || !_canPermanentlyDelete
              ? permanentlyDeleteTooltip
              : null,
          onSelectOption: deleteAction,
        }),
      ],
      dropdownId: 'statement-kebab-id',
      className: 'statement-list__kebab-menu',
      usePortalMenu: false,
      containerRef: containerRef,
    }),
  );
  return options;
};

const getStatementListKebabOptions = ({
  softDeleteAllRevisionsAction,
  softDeleteLatestRevisionAction,
  project,
  addRevisionAction,
  navigateToEditStatement,
  workflowsMap,
  currentUser,
  onApproveWrapUpClick,
  onSubmitWrapUpClick,
  copyStatement,
  onPinClick,
  statementWorkflowSteps,
}) => {
  // TODO: Change project-level legal hold on statements to statement-level legal hold once we support that on the back-end

  let options;
  const _canUploadRevision =
    currentUser && Permissions.Statement.canUploadRevision();
  const _userCanEdit = Permissions.Statement.canEdit();
  const _userCanSoftDelete = Permissions.Statement.canSoftDelete();
  const _userCanApproveWrapUp = Permissions.Statement.canApproveWrapUp();
  const _userCanSubmitForWrapUpApproval =
    Permissions.Statement.canSubmmitForWrapUpApproval();

  const _userCanCopyStatement =
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(project.id) ||
    RolesUtil.doesUserHavePPMDRoleForProject(project.id) ||
    RolesUtil.doesUserHavePreparerReviewerRoleForProject(project.id) ||
    RolesUtil.doesUserHaveAdminSupportAccess(project.id);

  let editTooltip, softDeleteTooltip;
  const disableduploadRevisionTooltip = new TooltipOptions({
    text: 'statement-list.kebab.upload-revision.disabled-tool-tip',
    id: 'upload-revision-disabled-tooltip-id',
    position: 'left',
  });

  const revisionIsUploadingTooltip = new TooltipOptions({
    text: 'statement-list.kebab.add-revision.disabled-tool-tip',
    id: 'add-revision-disabled-tooltip-id',
    position: 'left',
  });
  const revisionIsUploadingSubmitForWrapUpTooltip = new TooltipOptions({
    text: 'statement-list.kebab.submit-for-wrap-up-approval.disabled-tool-tip',
    id: 'submit-for-wrap-up-approval-disabled-tooltip-id',
    position: 'left',
  });
  if (!_userCanEdit) {
    editTooltip = new TooltipOptions({
      text: 'permissions.edit.statement',
      id: 'edit-statement-tooltip-id',
      position: 'left',
    });
  }
  //If a statement is on legal hold, we cannot delete it.
  if (!_userCanSoftDelete || ((statement) => statement.isLegalHoldSet())) {
    //If a project is not on legal hold, default to showing a tool tip if the user does not have access to the statement as a fallback.
    softDeleteTooltip = new TooltipOptions({
      text: 'permissions.delete.statement.on-legal-hold',
      id: 'delete-statement-tooltip-id',
      position: 'left',
    });
  }

  const wrapUpApproveTooltip = new TooltipOptions({
    text: 'permissions.wrap-up-approve.statement.disabled',
    id: 'approve-statement-wrap-up-tooltip-id',
  });
  const wrapUpApproveOnLegalHoldTooltip = new TooltipOptions({
    text: 'permissions.wrap-up-approve.statement.on-legal-hold',
    id: 'approve-statement-wrap-up-on-legal-hold-tooltip-id',
    position: 'left',
  });
  const wrapUpTooltip = new TooltipOptions({
    text: 'permissions.wrap-up.statement.disabled',
    id: 'wrap-up-statement-disabled-tooltip-id',
    position: 'left',
  });

  const archivalInitiatedDisableEditTooltip = new TooltipOptions({
    text: 'statement-list.kebab.archival-flow.disable-edit',
    id: 'disable-statement-edit-from-wrap-up-tooltip-id',
  });

  const _statementHasProcessingRevisions = (statement) =>
    workflowsMap &&
    workflowsMap.statementHasProcessingRevision({
      statementId: statement.id,
    });

  const _statementHasFailedWorkflow = (statement) => {
    const workflowsList = workflowsMap.getWorkflowsByStatementId(statement.id);
    return workflowsList.some(
      (w) =>
        w.status ===
        statementWorkflowSteps.isFailedWorkflowStep(w.documentProcessTypeEnum)
          .status,
    );
  };

  options = [
    new KebabMenuOption({
      title: 'statement-list.kebab.add-revision',
      disabled: (statement) =>
        statement.isInArchivalFlow() ||
        _statementHasProcessingRevisions(statement) ||
        !_canUploadRevision,
      tooltip: (statement) => {
        if (statement.isInArchivalFlow()) {
          return archivalInitiatedDisableEditTooltip;
        } else if (_statementHasProcessingRevisions(statement)) {
          return revisionIsUploadingTooltip;
        } else if (!_canUploadRevision) {
          return disableduploadRevisionTooltip;
        }
      },
      onSelectOption: (statement) => addRevisionAction(statement),
    }),
    new KebabMenuOption({
      title: 'statement-list.kebab.edit',
      disabled: (statement) => statement.isInArchivalFlow() || !_userCanEdit,
      tooltip: (statement) => {
        if (statement.isInArchivalFlow()) {
          return archivalInitiatedDisableEditTooltip;
        }
        return editTooltip;
      },
      onSelectOption: navigateToEditStatement,
    }),

    new KebabMenuOption({
      title: 'statement-list.kebab.soft-delete-latest-revision',
      disabled: (statement) =>
        statement.isLegalHoldSet() || !_userCanSoftDelete,
      tooltip: (statement) => statement.isLegalHoldSet() && softDeleteTooltip,
      shouldShowOption: (statement) =>
        statement.shouldShowRevertToPreviousRevisionOption() &&
        !statement.isInArchivalFlow(),
      onSelectOption: (statement) => softDeleteLatestRevisionAction(statement),
    }),
    new KebabMenuOption({
      title: 'statement-list.kebab.soft-delete-statement',
      /** TODO: Once statement-level legal hold becomes possible, supply a function here of the flavor:
       *
       * disabled: statement => (!userCanDelete || statement.isOnLegalHold)
       *
       * as at that point, we won't be considering legal hold on a project level, so we can remove the
       * _projectOnLegalHold check here.
       *
       */
      disabled: (statement) =>
        statement.isLegalHoldSet() || !_userCanSoftDelete,
      tooltip: (statement) => statement.isLegalHoldSet() && softDeleteTooltip,
      shouldShowOption: (statement) => !statement.isInArchivalFlow(),
      onSelectOption: (statement) => softDeleteAllRevisionsAction(statement),
    }),
    new KebabMenuOption({
      title: 'statement-list.kebab.submit-wrap-up',
      disabled: (statement) =>
        statement.isLegalHoldSet() ||
        !_userCanSubmitForWrapUpApproval ||
        _statementHasProcessingRevisions(statement),
      tooltip: (statement) => {
        if (statement.isLegalHoldSet() || !_userCanSubmitForWrapUpApproval) {
          return wrapUpTooltip;
        } else if (_statementHasProcessingRevisions(statement)) {
          return revisionIsUploadingSubmitForWrapUpTooltip;
        }
        return null;
      },
      shouldShowOption: (statement) => !statement.isInArchivalFlow(),
      onSelectOption: onSubmitWrapUpClick,
    }),
    new KebabMenuOption({
      title: 'statement-list.kebab.approve-wrap-up',
      disabled: (statement) =>
        statement.wrapUpSubmissionUserId === currentUser.id ||
        statement.isLegalHoldSet() ||
        !_userCanApproveWrapUp,
      tooltip: (statement) =>
        statement.isLegalHoldSet()
          ? wrapUpApproveOnLegalHoldTooltip
          : statement.wrapUpSubmissionUserId === currentUser.id ||
            !_userCanApproveWrapUp
          ? wrapUpApproveTooltip
          : null,
      shouldShowOption: (statement) => statement.isPendingApproval(),
      onSelectOption: onApproveWrapUpClick,
    }),
    new KebabMenuOption({
      title: 'statement-list.kebab.copy-statement',
      disabled: (statement) => {
        return (
          !_userCanEdit ||
          !_userCanCopyStatement ||
          _statementHasFailedWorkflow(statement) ||
          _statementHasProcessingRevisions(statement)
        );
      },
      shouldShowOption: true, // (statement) => !_statementIsImported(statement),
      onSelectOption: (statement) => copyStatement(statement),
      tooltip: (statement) => {
        if (!_userCanCopyStatement) {
          return copyStatementNoPermissionTooltip;
        }
        if (_statementHasFailedWorkflow(statement)) {
          return copyStatementNoFailedWorkflow;
        }
        if (_statementHasProcessingRevisions(statement)) {
          return copyStatementRevisionUpload;
        }
        return null;
      },
    }),
    new KebabMenuOption({
      id: 'statement-kebab-option-pin-or-unpin',
      title: (statement) => {
        if (isNullOrUndefined(statement.pinnedDatetime)) {
          return 'common.pin';
        } else {
          return 'common.unpin';
        }
      },
      onSelectOption: (statement) => onPinClick(statement),
    }),
  ];
  return options;
};
