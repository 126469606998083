import BaseModel from 'models/base-model';

export default class AnnotationDisplayOptions extends BaseModel({
  workpaper: true,
  tickmarks: true,
  internalRef: true,
  formula: true,
  comfortLetter: true,
  flag: true,
  status: true,
  notes: true,
  note: true,
  showAll: false,
  expandAllElements: false,
}) {}
