import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import SourceElementFilter from './statement-nav-source-element-filters-component';
import ElementFilters from 'models/data/element-filters-model';
import Search from 'components/common/search-component';
import { WorkpaperReferenceList } from 'models/api/workpaper-reference-list-model';
import { getWorkpapersForRevisionRequest } from 'api/workpaper-api';
import { connect } from 'react-redux';
import { usePrevious } from 'utils/hooks-util';

export const TERTIARY_WPR_BLOCK = 'tertiary-workpaper-filters';
export const TERTIARY_WPR_ID_BLOCK = 'tertiary-workpaper-filters-id';

const ElementFiltersWorkpaperTertiary = ({
  filters,
  setFilters,
  className,
  revisionId,
}) => {
  const [searchTerm, setSearch] = useState('');
  const [workpapers, setWorkpapers] = useState(new WorkpaperReferenceList());

  const prevFilters = usePrevious(filters);

  useEffect(() => {
    const fetchWorkpapers = async () => {
      setWorkpapers(workpapers.setLoading());
      try {
        const response = await getWorkpapersForRevisionRequest({
          revisionId,
        });
        setWorkpapers(workpapers.setLoaded({ response }));
      } catch (error) {
        setWorkpapers(workpapers.setError(error));
      }
    };
    if (prevFilters !== filters) {
      fetchWorkpapers();
    }
  }, [filters, prevFilters, revisionId, workpapers]);

  return (
    <>
      <div className={`${className} ${className}__border-bottom`}>
        <SourceElementFilter
          className={className}
          automatedChecked={filters.automatedWorkpaperFlag}
          automatedOnChange={() =>
            setFilters(filters.setWPRAutomated(!filters.automatedWorkpaperFlag))
          }
          manualChecked={filters.manualWorkpaperFlag}
          manualOnChange={() =>
            setFilters(filters.setWPRManual(!filters.manualWorkpaperFlag))
          }
        />
      </div>

      <div className={className}>
        <Search
          className={`${TERTIARY_WPR_BLOCK}__search`}
          id={`${TERTIARY_WPR_ID_BLOCK}-search`}
          onChange={(val) => setSearch(val)}
          onClear={() => {
            setSearch('');
          }}
          placeholder="statement-nav-element-filters.wpr.search.placeholder"
          showSearchButton
          value={searchTerm}
          isValid
          disableAutoComplete
        />
        {workpapers.getFilteredWorkpaperRefs(searchTerm).map((workpaper) => (
          <div className={`${TERTIARY_WPR_BLOCK}__checkbox-container`}>
            <Checkbox
              className={`${TERTIARY_WPR_BLOCK}__wpr-checkbox`}
              key={workpaper.workpaperRefId}
              id={`${TERTIARY_WPR_ID_BLOCK}-${workpaper.workpaperRefId}`}
              isNotIntl={true}
              value={workpaper.referenceNumber}
              checked={filters.isWPReferenceSelected(workpaper.referenceNumber)}
              onChange={(val) => setFilters(filters.selectWPReferences(val))}
              width={'15%'}
            />
            <div className={`${TERTIARY_WPR_BLOCK}__checkbox-label`}>
              <strong>{workpaper.referenceNumber}</strong>
              <span>{workpaper.referenceName}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

ElementFiltersWorkpaperTertiary.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
  /** Revision id of current statement */
  revisionId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export { ElementFiltersWorkpaperTertiary };
export default connect(mapStateToProps)(ElementFiltersWorkpaperTertiary);
