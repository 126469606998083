import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { FormulaList } from 'models/api/formula-list-model';
import TabModel from 'models/utils/common/tab-model';
import { ELEMENT_ANNOTATIONS_TABS } from 'constants/feature/element-panel-constants';
import ConditionalRender from 'components/util/conditional-render-component';

import ElementPanelAnnotationsTab from 'components/feature/element-panel/annotations/_element-panel-annotations-tab-component';
import ElementFormulaListContainer from 'containers/feature/element-panel/element-panel-formula-list-annotation-container';
import InternalReferenceList from 'components/feature/element-panel/annotations/_element-panel-internal-reference-list-annotation-component';
import WorkpaperList from './_element-panel-workpaper-list-annotation-component';
import TickmarkList from './_element-panel-tickmark-list-annotation-component';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';
import InternalReference from 'models/api/internal-reference-model';
import ExpandableRow from 'components/common/expandable-row-component';
import ElementComfortLetterList from 'models/api/element-comfort-letter-list-model';
import ElementComfortLetterListComponent from './_element-panel-comfort-letter-list-annotation-component';

const ELEMENT_ANNOTATIONS_BLOCK = 'element-annotations';

const ElementPanelAnnotations = ({
  elementDetails,
  revisionId,
  formulaList,
  selectedElementPanelAnnotationsTab,
  setCurrentElementAnnotationsTab,
  primaryAnnotationActions: {
    onCreateNewFormula,
    onAddWorkpaper,
    onAddInternalReference,
    onAttachTickmark,
  },
  secondaryAnnotationActions: { onCopyAllFormula },
  onEditFormula,
  internalReference,
  selectElementFromInternalReference,
  detachElementFromInternalReference,
  workpaperList,
  comfortLetterList,
  onDetachWorkpaper,
  tickmarkList,
  onDetachTickmark,
  readOnly,
  getElementComfortLettersAndUpdateElementCache,
  onDetachComfortLetter,
}) => {
  const [showExpandableRow, setShowExpandableRow] = useState(true);

  const getContentForTab = () => {
    if (
      selectedElementPanelAnnotationsTab.id ===
      ELEMENT_ANNOTATIONS_TABS.FORMULA.id
    ) {
      return (
        <ElementFormulaListContainer
          onNew={onCreateNewFormula}
          onCopyAll={onCopyAllFormula}
          onEdit={onEditFormula}
          readOnly={readOnly}
        />
      );
    } else if (
      selectedElementPanelAnnotationsTab.id ===
      ELEMENT_ANNOTATIONS_TABS.WORK_PAPER_REFERENCE.id
    ) {
      return (
        <WorkpaperList
          onAddLink={onAddWorkpaper}
          workpaperList={workpaperList}
          onDetach={onDetachWorkpaper}
          readOnly={readOnly}
        />
      );
    } else if (
      selectedElementPanelAnnotationsTab.id ===
      ELEMENT_ANNOTATIONS_TABS.INTERNAL_REFERENCE.id
    ) {
      return (
        <InternalReferenceList
          elementDetails={elementDetails}
          internalReference={internalReference}
          onAddLink={onAddInternalReference}
          selectElementFromInternalReference={
            selectElementFromInternalReference
          }
          detachElementFromInternalReference={
            detachElementFromInternalReference
          }
          readOnly={readOnly}
        />
      );
    } else if (
      selectedElementPanelAnnotationsTab.id ===
      ELEMENT_ANNOTATIONS_TABS.TICKMARK.id
    ) {
      return (
        <TickmarkList
          onAttach={onAttachTickmark}
          tickmarkList={tickmarkList}
          onDetach={onDetachTickmark}
          readOnly={readOnly}
        />
      );
    } else if (
      selectedElementPanelAnnotationsTab.id ===
      ELEMENT_ANNOTATIONS_TABS.COMFORT_LETTER.id
    ) {
      return (
        <ElementComfortLetterListComponent
          elementDetails={elementDetails}
          comfortLetterList={comfortLetterList}
          getElementComfortLettersAndUpdateElementCache={
            getElementComfortLettersAndUpdateElementCache
          }
          revisionId={revisionId}
          onDetachComfortLetter={onDetachComfortLetter}
          readOnly={readOnly}
        />
      );
    }
    return null;
  };
  return (
    <ConditionalRender
      dependencies={[elementDetails, formulaList, internalReference]}
    >
      <ExpandableRow
        title="element-panel.annotations.title"
        showExpandableRow={showExpandableRow}
        minimizeExpandableRow={() => setShowExpandableRow(false)}
        expandExpandableRow={() => setShowExpandableRow(true)}
        tooltipId={`${ELEMENT_ANNOTATIONS_BLOCK}__tooltip`}
        isElementPanel={true}
      >
        <div className={ELEMENT_ANNOTATIONS_BLOCK}>
          <ElementPanelAnnotationsTab
            elementDetails={elementDetails}
            formulaList={formulaList}
            selectedElementPanelAnnotationsTab={
              selectedElementPanelAnnotationsTab
            }
            setCurrentElementAnnotationsTab={setCurrentElementAnnotationsTab}
            internalReference={internalReference}
            tickmarkList={tickmarkList}
            comfortLetterList={comfortLetterList}
            workpaperList={workpaperList}
          />
          <div className={`${ELEMENT_ANNOTATIONS_BLOCK}__content`}>
            {getContentForTab()}
          </div>
        </div>
      </ExpandableRow>
    </ConditionalRender>
  );
};

ElementPanelAnnotations.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** The currently selected tab in the element panel */
  selectedElementPanelAnnotationsTab: PropTypes.instanceOf(TabModel).isRequired,
  /** Map of actions fired when a user clicks on the primary button of the annotations content */
  primaryAnnotationActions: PropTypes.object.isRequired,
  /** Map of actions fired when a user clicks on the secondary button of the annotations content */
  secondaryAnnotationActions: PropTypes.object,
  /** The list of formulas for the current element*/
  formulaList: PropTypes.instanceOf(FormulaList).isRequired,
  /** Action fired to get details for edit */
  onEditFormula: PropTypes.func.isRequired,
  /** Internal reference that is currently linked to this element */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /**The action that is fired to highlight elements from the content panel after we click on them in the internal reference */
  selectElementFromInternalReference: PropTypes.func.isRequired,
  /**The action that is fired when we wish to detach an element from an internal reference */
  detachElementFromInternalReference: PropTypes.func.isRequired,
  /** List of workpapers associated with this element */
  workpaperList: PropTypes.instanceOf(ElementWorkpaperList).isRequired,
  /** action fired to detach a work paper from this element */
  onDetachWorkpaper: PropTypes.func.isRequired,
  /** action fired to detach a tickmark from this element */
  onDetachTickmark: PropTypes.func.isRequired,
  /** Boolean for read only mode */
  readOnly: PropTypes.bool.isRequired,
  /**List of comfort letters attached to this element */
  comfortLetterList: PropTypes.instanceOf(ElementComfortLetterList).isRequired,
  /**Action fired to get latest comfort letter details and update cache and element panel */
  getElementComfortLettersAndUpdateElementCache: PropTypes.func.isRequired,
  /**Number to represent revision id */
  revisionId: PropTypes.number.isRequired,
  /**ation method fired to detach comfort letter */
  onDetachComfortLetter: PropTypes.func.isRequired,
};

export default memo(ElementPanelAnnotations);
