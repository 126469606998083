import React from 'react';
import TieoutContentSearchOCR from 'components/feature/statement-content-panel/tieout-content-search-component-ocr';

const StatementContentSearchOverlayMap = ({
  content,
  polygonInPixel,
  contentSearchResult,
  index,
}) => {
  return (
    <TieoutContentSearchOCR
      contentDetails={content}
      polygonInPixel={polygonInPixel}
      contentSearchResult={contentSearchResult}
      index={index}
    />
  );
};
export default StatementContentSearchOverlayMap;
