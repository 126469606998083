import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import { FormattedMessage } from 'react-intl';

export const TERTIARY_SOURCE_FILTER_ID_BLOCK = 'tertiary-source-filters-id';

const SourceElementFilter = ({
  className,
  automatedChecked,
  automatedOnChange,
  manualChecked,
  manualOnChange,
}) => {
  return (
    <div>
      <div className={`${className}__subtitle`}>
        <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.col-3.formula.type-source" />
      </div>
      <Checkbox
        id={`${TERTIARY_SOURCE_FILTER_ID_BLOCK}-automated`}
        label="statement-nav-panel.tab.elements.filters.modal.col-3.element.Automated"
        checked={!!automatedChecked}
        onChange={automatedOnChange}
      />
      <Checkbox
        id={`${TERTIARY_SOURCE_FILTER_ID_BLOCK}-manual`}
        label="statement-nav-panel.tab.elements.filters.modal.col-3.element.Manual"
        checked={!!manualChecked}
        onChange={manualOnChange}
      />
    </div>
  );
};

SourceElementFilter.propTypes = {
  automatedChecked: PropTypes.bool,
  automatedOnChange: PropTypes.func,
  manualChecked: PropTypes.bool,
  manualOnChange: PropTypes.func,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default SourceElementFilter;
