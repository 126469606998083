import { PinnedDetials } from 'models/api/pinned-details-model';
import { handleActions } from 'redux-actions';

import {
  pinnedDetailsLoading,
  pinnedDetailsError,
  pinnedDetailsLoaded,
  updatePinnedDetails,
  removePinnedDetails,
} from 'store/actions/pinned-details-actions';

export default handleActions(
  {
    [pinnedDetailsLoading]: (pinnedDetails) => {
      return pinnedDetails.setLoading();
    },
    [pinnedDetailsError]: (pinnedDetails, { payload }) => {
      return pinnedDetails.setError(payload);
    },
    [pinnedDetailsLoaded]: (pinnedDetails, { payload }) => {
      return pinnedDetails.setLoaded(payload);
    },
    [updatePinnedDetails]: (pinnedDetails, { payload }) => {
      return pinnedDetails.addNewPinnedItem(payload);
    },
    [removePinnedDetails]: (pinnedDetails, { payload }) => {
      return pinnedDetails.removingPinnedItem(payload);
    },
  },
  new PinnedDetials(),
);
