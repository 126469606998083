import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import ToolTip from 'components/common/tool-tip-component';
import useOutsideClick from 'components/hooks/useOutsideClick';

const BLOCK = 'icon-dropdown';

const IconDropDown = ({
  displayIconPlaceholder,
  arrowIcon,
  onSelectOption,
  options,
  value,
  tooltip,
  disabled,
}) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const [iconDropDownContainer] = useOutsideClick(() =>
    setDisplayOptions(false),
  ); // used to handle outside click

  const getHighlightedDisplayValue = (display) => {
    const children = (
      <div className={`${BLOCK}__display-container`}>
        <div
          className={classnames(`${BLOCK}__display-highlight`, {
            [`${BLOCK}__display-highlight--disabled`]: disabled,
          })}
        >
          {display}
        </div>
        {arrowIcon}
      </div>
    );

    return tooltip.id ? (
      <ToolTip id={tooltip.id} text={tooltip.text} position={tooltip.position}>
        {children}
      </ToolTip>
    ) : (
      <>{children}</>
    );
  };
  return (
    <div
      className={classnames(`${BLOCK}__icon-dropdown-container`, {
        [`${BLOCK}__icon-dropdown-container--disabled`]: disabled,
      })}
      onClick={() => !disabled && setDisplayOptions(!displayOptions)}
      ref={iconDropDownContainer}
    >
      {value && value.icon
        ? getHighlightedDisplayValue(value.icon)
        : getHighlightedDisplayValue(displayIconPlaceholder)}

      {displayOptions && (
        <ul className={`${BLOCK}__list-container`}>
          {options.map((item) => {
            return (
              <li
                key={item.id}
                className={classnames(`${BLOCK}__list-container--items`, {
                  [`${BLOCK}__list-container--item-selected`]:
                    item.id === value.id,
                })}
                onClick={() => onSelectOption(item)}
              >
                {item.icon}
                <div className={`${BLOCK}__list-title`}>
                  <FormattedMessage id={item.title} />
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default IconDropDown;
