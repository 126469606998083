import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRevisionsSectionsRequest } from 'api/sections-api';

import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';

import TabsGroup from 'components/common/tabs-group-component';
import { FormattedMessage } from 'react-intl';
import {
  STATEMENT_NAV_TABS,
  CONTENT_MAX_SEARCH_RESULTS,
  getOcrNavigationTabs,
} from 'constants/feature/statement-navigator-constants';
import Search from 'components/common/search-component';
import { searchStatementContentRequest } from 'api/statement-content-api';
import ContentSearchResults from 'models/api/statement-content-search-results-api-model';
import StatementNavigatorBody from './statement-navigator-panel-body-component';
import {
  clearSearchFromContent,
  searchForTermInContent,
} from 'store/actions/statement-content-actions';
import Revision from 'models/api/revision-api-model';
import Button from 'components/common/button-component';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import StatementNavElementFiltersModal from './elements/statement-nav-element-filters-modal';
import ElementsSearchResults from 'models/api/statements-element-search-results-api-model';
import ElementFilters from 'models/data/element-filters-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { hideStatementNavPanelAction } from 'store/actions/panel-controller-actions';
import { setSelectedTabAction } from 'store/actions/statement-navigator/navigator-panel-actions';
import { setTOCRefresh, setTOCExpandAll } from 'store/actions/TOC-actions';
import {
  clearElementSearchResults,
  searchElements,
  clearElementFilters,
} from 'store/actions/statement-navigator/elements-search-panel-actions';
import ContentSearchResultEntry from 'models/data/content-search-result-entry-model';
import {
  scrollOCRSelectorIntoView,
  scrollSelectorIntoView,
} from 'utils/scrolling-utils';
import {
  setSelectedElementResult,
  showElementSearchRefreshButtonAction,
} from 'store/actions/statement-navigator/elements-search-panel-actions';
import {
  setContentHighlightSelector,
  selectElementFromElementSearch,
} from 'store/actions/statement-content-actions';
import SearchResultsControls from './statement-navigator-search-controls-component';
import {
  sectionTreeListLoading,
  sectionTreeListLoaded,
  sectionTreeListError,
} from 'store/actions/section-tree-list-actions';
import classNames from 'classnames';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import { fetchAndUpdateBatchLimit } from 'utils/statement-content-page-utils';
import SelectedStatement from 'models/api/selected-statement-model';
import { BLOCK_ID } from 'components/feature/statement-content-panel/statement-content-panel-component-ocr';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import {
  clearContentSearchResult,
  contentSearchResultError,
  contentSearchResultLoaded,
  contentSearchResultLoading,
  setContentSearchResultSelectedIndex,
} from 'store/actions/content-search-result-actions';

export const STATEMENT_NAV_BLOCK = 'statement-nav';
const STATEMENT_NAV_ID_BLOCK = 'statement-nav-id';
const MIN_SEARCH_LENGTH = 1;

const StatementNavigatorPanel = ({
  show,
  revision,
  onClearSearch,
  onClosePanel,
  onContentSearch,
  selectedTab,
  setSelectedTab,
  setTOCRefresh,
  TOCRefresh,
  setTOCExpandAll,
  elementFilters,
  clearElementSearchResults,
  elementSearchResults,
  fetchElementSearchResults,
  onSelectElementResult,
  setContentHighlightSelector,
  selectElementFromElementSearch,
  clearElementFilters,
  showElementSearchRefreshButtonAction,
  sectionTreeListLoading,
  sectionTreeListLoaded,
  sectionTreeListError,
  sectionTreeList,
  isCreateSectionModalOpen,
  selectedStatement,
  setOcrCurrentViewPageNumberDispatch,
  contentSearchResultLoading,
  contentSearchResultLoaded,
  contentSearchResultError,
  clearContentSearchResult,
  ocrContentSearchResult,
  setContentSearchResultSelectedIndex,
  isOCR,
}) => {
  const flyoutRef = useRef();
  const [searchTerm, setSearch] = useState('');
  const [searchResultsContent, setSearchResultsContent] = useState(
    new ContentSearchResults(),
  );
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isSearchExecuted, setIsSearchExecuted] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [sectionAssignmentsFilterMode, setSectionAssigmentsFilterMode] =
    useState(false);
  const [resultsFromAssignmentFilter, setResultsFromAssignmentFilter] =
    useState(0);
  const toggleFilterModal = () => setShowFilterModal(!showFilterModal);
  const isStatementOCR = selectedStatement && selectedStatement.isOCR;
  useEffect(() => {
    fetchAndUpdateBatchLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTab === STATEMENT_NAV_TABS.headings) {
      setSectionAssigmentsFilterMode(false);
      setResultsFromAssignmentFilter(0);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!isNullOrUndefined(flyoutRef.current)) {
      if (isCreateSectionModalOpen) {
        flyoutRef.current.classList.add(`${STATEMENT_NAV_BLOCK}--create`);
        setTOCExpandAll(true);
      } else {
        flyoutRef.current.classList.remove(`${STATEMENT_NAV_BLOCK}--create`);
      }
    }
  }, [isCreateSectionModalOpen, setTOCExpandAll]);
  useEffect(() => {
    if (!sectionTreeList.isLoaded) {
      sectionTreeListLoading();
    }

    const fetchSectionTree = async () => {
      try {
        const response = await getRevisionsSectionsRequest({
          revisionId: revision.id,
          onlyBookmarkSection: true,
          asTreeList: true,
        });
        sectionTreeListLoaded(response);
        if (TOCRefresh) {
          setTOCRefresh(null);
          setTOCExpandAll(true);
        }
      } catch (error) {
        sectionTreeListError(error);
      }
    };
    if (show && (!sectionTreeList.hasBeenFetched || TOCRefresh)) {
      fetchSectionTree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    show,
    revision,
    TOCRefresh,
    setTOCRefresh,
    setTOCExpandAll,
    clearElementSearchResults,
  ]);

  // for performance improvements, we will execute the element filter search
  // only when the user clicks on the Elements tab
  useEffect(() => {
    if (isSearchExecuted && selectedTab === STATEMENT_NAV_TABS.elements) {
      if (
        searchTerm.length >= MIN_SEARCH_LENGTH ||
        elementFilters.hasFilters()
      ) {
        _searchElements({
          filters: elementFilters,
          scrollIntoView: selectedTab === STATEMENT_NAV_TABS.elements,
        });
        setIsSearchExecuted(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchExecuted, selectedTab]);

  const _searchElements = useCallback(
    ({ filters, immediateSearchTerm, scrollIntoView = true }) => {
      /** Search doesn't always have the most recent value. immediateSearchTerm prop is for when
       * we want to force search with a different value to searchTerm
       */
      const _searchTerm = !isNullOrUndefined(immediateSearchTerm)
        ? immediateSearchTerm
        : searchTerm;
      return fetchElementSearchResults({
        filters,
        searchTerm: _searchTerm,
        scrollIntoView,
      });
    },
    [fetchElementSearchResults, searchTerm],
  );

  const applyFiltersFromModal = ({ filters }) => {
    switch (selectedTab) {
      case STATEMENT_NAV_TABS.elements: {
        toggleFilterModal();
        setIsFilterApplied(true);
        if (!filters.checkIfWithWPRIsSelected) {
          filters = filters.removeWPFilters();
        }
        if (!filters.checkIfWithTickmarksIsSelected) {
          filters = filters.removeTickmarkFilters();
        }
        const updatedFilters = filters.resetPagination();
        _searchElements({ filters: updatedFilters });
        showElementSearchRefreshButtonAction(false);
        break;
      }
      default: {
        return null;
      }
    }
  };
  const onSearchClear = () => {
    setSearch('');
    setSearchResultsContent(new ContentSearchResults());
    isStatementOCR && clearContentSearchResult();
    onClearSearch();
    if (elementFilters.hasFilters()) {
      _searchElements({
        filters: elementFilters,
        immediateSearchTerm: '',
      });
    } else {
      clearElementSearchResults();
    }
  };

  const _executeSearch = async () => {
    if (searchTerm.length >= MIN_SEARCH_LENGTH) {
      _searchContent({
        scrollIntoView: selectedTab === STATEMENT_NAV_TABS.content,
      });
    }
    setIsSearchExecuted(true);
    // third case: user makes an empty search
    // this is only going to be executed the first time
    if (searchTerm.length === 0 && elementSearchResults.isLoaded === true) {
      onSearchClear();
    }
  };

  const _searchContent = ({ scrollIntoView = true }) => {
    setSearchResultsContent(searchResultsContent.setLoading());
    isStatementOCR && contentSearchResultLoading();
    onContentSearch({ searchTerm, isOcr: isStatementOCR });
    searchStatementContentRequest({
      revisionId: revision.id,
      searchTerm,
      isOcr: isStatementOCR,
      maxResults: CONTENT_MAX_SEARCH_RESULTS,
    })
      .then((response) => {
        setSearchResultsContent(
          searchResultsContent.setLoaded({
            response,
          }),
        );
        isStatementOCR && contentSearchResultLoaded({ response });
        const { occurrences } = response.data;
        if (occurrences && occurrences.length > 0) {
          const [firstOccurrence] = occurrences;
          const { sectionId, selector } = firstOccurrence;
          setContentHighlightSelector({ sectionId, selector });
          if (scrollIntoView) {
            isStatementOCR
              ? scrollOCRSelectorIntoView(
                  new ContentSearchResultEntry(firstOccurrence),
                )
              : scrollSelectorIntoView(
                  new ContentSearchResultEntry(firstOccurrence),
                );
          }
        }
      })
      .catch((error) => {
        setSearchResultsContent(searchResultsContent.setError(error));
        isStatementOCR && contentSearchResultError(error);
      });
  };

  const getResultCount = () => {
    switch (selectedTab) {
      case STATEMENT_NAV_TABS.headings: {
        const { totalLoadedResults } = searchResultsContent;
        let intlProps = {
          id: 'common.count-results',
          values: { count: totalLoadedResults },
        };
        // BE clips results at 500, decided to show when >= to save effort
        const hasTooManyResults =
          totalLoadedResults >= CONTENT_MAX_SEARCH_RESULTS;
        if (hasTooManyResults) {
          intlProps = {
            id: 'statement-nav-panel.tab.content.count.above-max',
            values: { count: CONTENT_MAX_SEARCH_RESULTS },
          };
        }
        if (sectionAssignmentsFilterMode) {
          let intlProps = {
            id: 'common.count-results',
            values: {
              count: resultsFromAssignmentFilter,
            },
          };
          return <FormattedMessage {...intlProps} />;
        }
        return (
          searchResultsContent.isLoaded && <FormattedMessage {...intlProps} />
        );
      }
      case STATEMENT_NAV_TABS.pages: {
        const { totalLoadedResults } = ocrContentSearchResult;
        let intlProps = {
          id: 'common.count-results',
          values: { count: totalLoadedResults },
        };
        // BE clips results at 500, decided to show when >= to save effort
        const hasTooManyResults =
          totalLoadedResults >= CONTENT_MAX_SEARCH_RESULTS;
        if (hasTooManyResults) {
          intlProps = {
            id: 'statement-nav-panel.tab.content.count.above-max',
            values: { count: CONTENT_MAX_SEARCH_RESULTS },
          };
        }
        if (sectionAssignmentsFilterMode) {
          let intlProps = {
            id: 'common.count-results',
            values: {
              count: resultsFromAssignmentFilter,
            },
          };
          return <FormattedMessage {...intlProps} />;
        }
        return (
          ocrContentSearchResult.isLoaded && <FormattedMessage {...intlProps} />
        );
      }
      case STATEMENT_NAV_TABS.content: {
        const { selectedIndex, totalLoadedResults } = isStatementOCR
          ? ocrContentSearchResult
          : searchResultsContent;
        let intlProps = {
          id: 'common.selected-result-with-total',
          values: {
            selected: Math.min(selectedIndex + 1, totalLoadedResults),
            total: totalLoadedResults,
          },
        };
        // BE clips results at 500, decided to show when >= to save effort
        const hasTooManyResults =
          totalLoadedResults >= CONTENT_MAX_SEARCH_RESULTS;
        if (hasTooManyResults) {
          intlProps = {
            id: 'statement-nav-panel.tab.content.count.above-max',
            values: { count: CONTENT_MAX_SEARCH_RESULTS },
          };
        }
        return isStatementOCR
          ? ocrContentSearchResult.isLoaded && (
              <FormattedMessage {...intlProps} />
            )
          : searchResultsContent.isLoaded && (
              <FormattedMessage {...intlProps} />
            );
      }
      case STATEMENT_NAV_TABS.elements: {
        const { selectedIndex, totalOverallResults } = elementSearchResults;
        return (
          elementSearchResults.isLoaded && (
            <FormattedMessage
              id="common.selected-result-with-total"
              values={{
                selected: Math.min(selectedIndex + 1, totalOverallResults),
                total: totalOverallResults,
              }}
            />
          )
        );
      }
      default: {
        return null;
      }
    }
  };

  const onSelectContentResult = useCallback(
    (index) => {
      isStatementOCR && setContentSearchResultSelectedIndex(index);
      setSearchResultsContent(searchResultsContent.setSelectedIndex(index));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResultsContent],
  );

  //method used to navigate to the selected thumbnail(page) in navigation panel
  const navigateToSelectedPage = (page) => {
    setOcrCurrentViewPageNumberDispatch(page);
    const element = document.getElementById(`${BLOCK_ID}${page}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  return (
    <>
      <Flyout
        className={classNames(STATEMENT_NAV_BLOCK)}
        show={show}
        position={'left'}
        flyoutRef={flyoutRef}
      >
        <FlyoutHeader enableClose onClose={onClosePanel}>
          <h3 className={`${STATEMENT_NAV_BLOCK}__title`}>
            <FormattedMessage id="statement-nav-panel.title" />
          </h3>
          <Search
            className={`${STATEMENT_NAV_BLOCK}__search`}
            id={`${STATEMENT_NAV_ID_BLOCK}-search`}
            onSearch={_executeSearch}
            onChange={setSearch}
            onClear={() => onSearchClear()}
            placeholder="statement-nav-panel.search.placeholder"
            showSearchButton
            value={searchTerm}
            isValid
            disableAutoComplete
          />
          <TabsGroup
            id={`${STATEMENT_NAV_ID_BLOCK}-tabs`}
            className={`${STATEMENT_NAV_BLOCK}__tabs`}
            tabsArray={getOcrNavigationTabs(isStatementOCR)}
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
          />
          <div
            className={classNames(
              `${STATEMENT_NAV_BLOCK}__row`,
              selectedTab === STATEMENT_NAV_TABS.elements &&
                `${STATEMENT_NAV_BLOCK}__row--with-top-border`,
            )}
          >
            <div className={`${STATEMENT_NAV_BLOCK}__search-count`}>
              {getResultCount()}
            </div>
            <div className={`${STATEMENT_NAV_BLOCK}__search-navigation`}>
              <SearchResultsControls
                onSelectContentResult={onSelectContentResult}
                onSelectElementResult={onSelectElementResult}
                searchResultsContent={searchResultsContent}
                searchResultsElement={elementSearchResults}
                selectedTab={selectedTab}
                setContentHighlightSelector={setContentHighlightSelector}
                selectElementFromElementSearch={selectElementFromElementSearch}
                isStatementOCR={isStatementOCR}
              />
            </div>
            {selectedTab === STATEMENT_NAV_TABS.elements && (
              <div
                className={`${STATEMENT_NAV_BLOCK}__filter-button-container`}
              >
                <Button.IconButton
                  id={`${STATEMENT_NAV_ID_BLOCK}-filter-button`}
                  className={`${STATEMENT_NAV_BLOCK}__filter-button`}
                  onClick={() => toggleFilterModal()}
                  Icon={PlusIcon}
                >
                  {elementFilters.filtersCount === 0 ? (
                    <FormattedMessage id="common.filter" />
                  ) : (
                    <FormattedMessage
                      id="statement-nav-panel.tab.elements.filters-counter"
                      values={{ count: elementFilters.filtersCount }}
                    />
                  )}
                </Button.IconButton>
              </div>
            )}
          </div>
        </FlyoutHeader>
        <FlyoutPanelContent>
          <StatementNavigatorBody
            navigateToSelectedPage={navigateToSelectedPage}
            selectedTab={selectedTab}
            sections={sectionTreeList}
            searchTerm={searchTerm}
            searchResultsContent={searchResultsContent}
            ocrContentSearchResult={ocrContentSearchResult}
            searchResultsElements={elementSearchResults}
            onFilterClick={toggleFilterModal}
            onSelectContentResult={onSelectContentResult}
            searchElements={_searchElements}
            filters={elementFilters}
            isFilterApplied={isFilterApplied}
            setIsFilterApplied={setIsFilterApplied}
            setSectionAssigmentsFilterMode={setSectionAssigmentsFilterMode}
            setResultsFromAssignmentFilter={setResultsFromAssignmentFilter}
          />
        </FlyoutPanelContent>
      </Flyout>
      {showFilterModal && selectedTab === STATEMENT_NAV_TABS.elements && (
        <StatementNavElementFiltersModal
          toggleModal={toggleFilterModal}
          currFilters={elementFilters}
          onApply={applyFiltersFromModal}
          clearElementFilterResults={() => {
            clearElementFilters();
            clearElementSearchResults();
          }}
          isOCR={isOCR}
        />
      )}
    </>
  );
};

StatementNavigatorPanel.propTypes = {
  /** indicates when the panel should be shown */
  show: PropTypes.bool.isRequired,
  /** Function fired to close  */
  onClosePanel: PropTypes.func.isRequired,
  /** Currently selected revision */
  revision: PropTypes.instanceOf(Revision).isRequired,
  /** Element filter used to fetch results */
  elementFilters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Element search results */
  elementSearchResults: PropTypes.instanceOf(ElementsSearchResults).isRequired,
  /** Function fired when executing search for statement content */
  onContentSearch: PropTypes.func.isRequired,
  /** Function fired when clearing the search box */
  onClearSearch: PropTypes.func.isRequired,
  /** Function fired to clear element search results */
  clearElementSearchResults: PropTypes.func.isRequired,
  /** Action fired to search for elements */
  fetchElementSearchResults: PropTypes.func.isRequired,
  /** Set an element search result as actively selected */
  onSelectElementResult: PropTypes.func.isRequired,
  /** function fired to set the content highlight selector */
  setContentHighlightSelector: PropTypes.func.isRequired,
  /** Action called to clear element filters */
  clearElementFilters: PropTypes.func.isRequired,
  /** function that will be called to remove the refresh button from the element filter list component */
  showElementSearchRefreshButtonAction: PropTypes.func.isRequired,
  /* action to set the section list loading */
  sectionTreeListLoading: PropTypes.func.isRequired,
  /* action to set the section list loaded */
  sectionTreeListLoaded: PropTypes.func.isRequired,
  /* action to set the section list error */
  sectionTreeListError: PropTypes.func.isRequired,
  /* object that contains the section tree */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList),
  /* bool that indicates if the add navigation modal is open */
  isCreateSectionModalOpen: PropTypes.bool,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Ocr function to navigate to selected page/section */
  navigateToSelectedPage: PropTypes.func,
  /** search content data based on OCR statements */
  ocrContentSearchResult: PropTypes.instanceOf(ContentSearchResults),
};

const mapStateToProps = ({
  data: {
    revision,
    sectionTreeList,
    selectedStatement,
    sectionTreeList: {
      data: { isCreateSectionModalOpen },
    },
    selectedStatement: { isOCR },
    contentSearchResult,
  },
  ui: {
    statementPage: {
      panels: { left },
      statementNavigatorPanel: {
        elementFilters,
        elementSearchResults,
        selectedTab,
        TOCRefresh,
      },
    },
  },
}) => ({
  revision,
  show: left === LEFT_PANELS.STATEMENT_NAV,
  selectedTab,
  TOCRefresh,
  elementFilters,
  elementSearchResults,
  sectionTreeList,
  isCreateSectionModalOpen,
  selectedStatement,
  ocrContentSearchResult: contentSearchResult,
  isOCR,
});

const mapDispatchToProps = {
  onClosePanel: hideStatementNavPanelAction,
  onContentSearch: searchForTermInContent,
  onClearSearch: clearSearchFromContent,
  setSelectedTab: setSelectedTabAction,
  setTOCRefresh,
  setTOCExpandAll,
  clearElementSearchResults,
  fetchElementSearchResults: searchElements,
  onSelectElementResult: setSelectedElementResult,
  setContentHighlightSelector,
  selectElementFromElementSearch,
  clearElementFilters,
  showElementSearchRefreshButtonAction,
  sectionTreeListLoading,
  sectionTreeListLoaded,
  sectionTreeListError,
  setOcrCurrentViewPageNumberDispatch,
  contentSearchResultLoading,
  contentSearchResultLoaded,
  contentSearchResultError,
  clearContentSearchResult,
  setContentSearchResultSelectedIndex,
};

export { StatementNavigatorPanel };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatementNavigatorPanel);
