import classNames from 'classnames';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SectioContentOverlayOCR from './section-content-overlay-ocr';
import Loading from 'components/common/loading-component';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { SCALE_ALTERATION } from 'constants/feature/statement-content-constants';
import {
  batchSelectElements,
  batchSelectElementsInSideBySideMode,
} from 'store/actions/statement-content-actions';
import withURLRouter from 'withURLRouter';
import { connect } from 'react-redux';
import {
  getText,
  getClosestWord,
  renderSelection,
  processCharacterRangeAsTextLines,
  selectedElementInBatch,
  clearCanvas,
  checkIfPolygonIsInsideAnotherPolygon,
  checkIsArrayOfArrays,
} from 'utils/ocr-text-selection-utils';
import { throttle } from 'lodash';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import { shouldElementBatchSelection } from 'utils/modes-utils';

const SectionContentOCR = ({
  BLOCK,
  BLOCK_ID,
  sectionMetaData,
  sectionCache,
  sectionOCRCache,
  elementCache,
  blacklineCache,
  batchSelectElements,
  batchSelectElementsInSideBySideMode,
  URLString,
  isContentPanelScrolling,
  isBatchModeSelected,
  markerDisplayValue,
  calloutDisplayValue,
  showElementStatusAndFlag,
  clickedSectionId,
  setClickedSectionId,
  selectedStatement,
  leftSelectedStatement,
  isLeftView = false,
  blacklineViewMode,
  LEFT_BLOCK_ID,
  blacklineGuidDetails,
  setSectionInView,
  clearSectionInView,
  notesCache,
  contentSearchResult,
  selectMode,
}) => {
  const [shouldShow, setShouldShow] = useState(false);
  const [selection, setSelection] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [box, setBox] = useState(null);
  const containerRef = useRef(null);
  const [annotationsList, setAnnotaionsList] = useState([]);
  const { ui } = useSelector((state) => state);
  const overlayCanvas = useRef(null);
  const {
    statementPage: { zoom, leftZoom, rightZoom } = {},
    sideBySideView: { sideBySideElementMap },
  } = ui;
  const elementIdToElementMap = (elementCache && elementCache.elements) || {};
  const elementList = Object.values(elementIdToElementMap);
  const notesList = Object.values(notesCache || {});
  const { width, height, sectionId } = sectionMetaData;
  const isSideBySideView = window.location.pathname.includes(
    ROUTE_CONSTANTS.SIDE_BY_SIDE,
  );
  const dpi =
    isSideBySideView && isLeftView
      ? leftSelectedStatement.dpi
      : selectedStatement.dpi;
  const scale = SCALE_ALTERATION * (150 / dpi);
  const zoomValue = isSideBySideView
    ? isLeftView
      ? leftZoom
      : rightZoom
    : zoom;
  const scaleWithZoom = scale * zoomValue.zoomRatio;
  const pageStyles = {
    width: `${width * scaleWithZoom}px`,
    height: `${height * scaleWithZoom}px`,
  };

  const isLoading = sectionCache && sectionCache.isLoading;
  const isSectionLoaded = sectionCache && sectionCache.isLoaded;
  const isLoaded = shouldShow && isSectionLoaded;
  const {
    ocrWords: ocr = [],
    ocrLines = [],
    ocrTables = [],
  } = sectionOCRCache || {};
  const [currentSelection, setCurrentSelectionState] = useState([]);
  const [contextMenuData, setContextMenuData] = useState({});
  const [selectedLines, setSelectedLines] = useState([]);
  const numberOfSourceElementSelected =
    sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping;
  const numberOfTargetElementSelected =
    sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping;

  useEffect(() => {
    const handleMouseDown = () => {
      setClickedSectionId(sectionId);
    };
    const container = containerRef && containerRef.current;
    container && container.addEventListener('mousedown', handleMouseDown);
    return () =>
      container && container.removeEventListener('mousedown', handleMouseDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clickedSectionId !== sectionId) {
      clearSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedSectionId]);

  //We are not getting polygonInPixel data for lines.
  const ocrLinesData = useMemo(() => {
    return ocrLines.map((item) => {
      return {
        ...item,
        polygon: item.polygon.map((item) => item * dpi * scaleWithZoom),
      };
    });
  }, [dpi, ocrLines, scaleWithZoom]);

  //We are not getting polygonInPixel data for words.
  const ocrWords = useMemo(() => {
    return ocr.map((item) => {
      return {
        ...item,
        polygon: item.polygon.map((item) => item * dpi * scaleWithZoom),
      };
    });
  }, [dpi, ocr, scaleWithZoom]);

  const ocrTablesData = useMemo(() => {
    return ocrTables
      .map((item) => {
        return item.cells.map((cell) => {
          return {
            ...cell,
            ...cell.bounding_regions[0],
            polygon: cell.bounding_regions[0].polygon.map(
              (item) => item * dpi * scaleWithZoom,
            ),
          };
        });
      })
      .flat();
  }, [dpi, ocrTables, scaleWithZoom]);

  const highlightSelection = throttle((coordinates) => {
    const char = getClosestWord(
      coordinates.x,
      coordinates.y,
      ocrWords,
      sectionMetaData,
    );
    const charExists = char.polygon && char.polygon.length ? char : null;
    if (!charExists) {
      return;
    }

    const startDefined =
      currentSelection && currentSelection.startingWord !== null;

    setCurrentSelection({
      startingWord: startDefined ? currentSelection.startingWord : char,
      endingWord: startDefined ? char : null,
    });
  }, 50);

  const setCurrentSelection = (curentSelection) => {
    setCurrentSelectionState(curentSelection);
    const { startingWord, endingWord } = curentSelection;
    let endOffset, startOffset;

    if (startingWord) {
      if (endingWord) {
        const distance = startingWord.span.offset < endingWord.span.offset;
        startOffset = distance
          ? startingWord.span.offset
          : endingWord.span.offset;
        endOffset = distance
          ? endingWord.span.offset
          : startingWord.span.offset;
      } else {
        startOffset = startingWord.span.offset;
        endOffset = startingWord.span.offset;
      }

      const { text, characterRange } = getText(
        startOffset,
        endOffset,
        ocrWords,
      );
      setSelection({
        startOffset,
        endOffset,
        text,
        characterRange,
      });

      const canvas = overlayCanvas.current;
      const lines = processCharacterRangeAsTextLines(characterRange);
      setSelectedLines(lines);
      clearCanvas(canvas);
      renderSelection(lines, canvas);
    }
  };

  const handleMouseDown = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const startX = e.clientX - rect.left;
    const startY = e.clientY - rect.top;
    setIsDrawing(true);

    setBox({
      startX,
      startY,
      endX: startX,
      endY: startY,
      width: 0,
      height: 0,
    });
    if (!isSideBySideView) {
      if (!isBatchModeSelected && !shouldElementBatchSelection(selectMode)) {
        const canvas = overlayCanvas.current;
        clearCanvas(canvas);
        setCurrentSelectionState(null);
        setSelection(null);
        renderSelection([], overlayCanvas.current);
        setSelectedLines([]);
        const char = getClosestWord(startX, startY, ocrWords, sectionMetaData);
        const charExists = char.polygon && char.polygon.length ? char : null;
        setCurrentSelection({
          startingWord: charExists ? char : null,
          endingWord: null,
        });
      }
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const rect = containerRef.current.getBoundingClientRect();
    const currentX = e.clientX - rect.left;
    const currentY = e.clientY - rect.top;

    setBox((prevBox) => ({
      ...prevBox,
      endX: currentX,
      endY: currentY,
      width: Math.abs(currentX - prevBox.startX),
      height: Math.abs(currentY - prevBox.startY),
      left: Math.min(currentX, prevBox.startX),
      top: Math.min(currentY, prevBox.startY),
    }));

    if (!isSideBySideView) {
      if (!isBatchModeSelected && !shouldElementBatchSelection(selectMode)) {
        const coordinates = { x: currentX, y: currentY };
        highlightSelection(coordinates);
      }
    }
  };

  //convert polygon data back to inches without scaling.
  const polygonConvertor = (polygon) => {
    const apiPolygons = [];

    polygon.forEach((polygonData) => {
      const { x1, y1, x2, y2 } = polygonData;

      const X1 = x1 / (dpi * scaleWithZoom);
      const Y1 = y1 / (dpi * scaleWithZoom);
      const X2 = x2 / (dpi * scaleWithZoom);
      const Y2 = y1 / (dpi * scaleWithZoom);
      const X3 = x2 / (dpi * scaleWithZoom);
      const Y3 = y2 / (dpi * scaleWithZoom);
      const X4 = x1 / (dpi * scaleWithZoom);
      const Y4 = y2 / (dpi * scaleWithZoom);

      apiPolygons.push([X1, Y1, X2, Y2, X3, Y3, X4, Y4]);
    });

    return apiPolygons;
  };

  const mouseUpActions = () => {
    let containsElement = false;
    let containesElementArray = [];
    let containsNote = false;

    !!elementList.length &&
      elementList.forEach((element) => {
        if (element.isIncluded) {
          const { coordinatesInPixel, id } = element;
          if (coordinatesInPixel && checkIsArrayOfArrays(coordinatesInPixel)) {
            coordinatesInPixel.forEach((coord) => {
              const polygon = coord.map((childCoord) => {
                return childCoord * scaleWithZoom;
              });
              let [x1, y1, , , x3, y3] = polygon;
              selectedLines.forEach((lines) => {
                const {
                  innerRectangleLiesWithinOuterRectangle,
                  innerRectangleIntersectsOuterRectangle,
                  outerRectangleIntersectsInnerRectangle,
                } = checkIfPolygonIsInsideAnotherPolygon(
                  lines.x1,
                  lines.y1,
                  lines.x2,
                  lines.y2,
                  x1,
                  y1,
                  x3,
                  y3,
                );
                if (
                  innerRectangleLiesWithinOuterRectangle ||
                  innerRectangleIntersectsOuterRectangle ||
                  outerRectangleIntersectsInnerRectangle
                ) {
                  containesElementArray.push(id);
                  containsElement = true;
                }
              });
            });
          } else if (coordinatesInPixel && coordinatesInPixel.length) {
            const polygon = coordinatesInPixel.map((coord) => {
              return coord * scaleWithZoom;
            });
            let [x1, y1, , , x3, y3] = polygon;
            selectedLines.forEach((lines) => {
              const {
                innerRectangleLiesWithinOuterRectangle,
                innerRectangleIntersectsOuterRectangle,
                outerRectangleIntersectsInnerRectangle,
              } = checkIfPolygonIsInsideAnotherPolygon(
                lines.x1,
                lines.y1,
                lines.x2,
                lines.y2,
                x1,
                y1,
                x3,
                y3,
              );
              if (
                innerRectangleLiesWithinOuterRectangle ||
                innerRectangleIntersectsOuterRectangle ||
                outerRectangleIntersectsInnerRectangle
              ) {
                containesElementArray.push(id);
                containsElement = true;
              }
            });
          }
        }
      });

    !!notesList.length &&
      notesList.forEach((note) => {
        const {
          ocrSelector: { polygon },
        } = note;
        if (polygon && checkIsArrayOfArrays(polygon)) {
          polygon.forEach((coord) => {
            const polygonData = coord.map((childCoord) => {
              return childCoord * dpi * scaleWithZoom;
            });
            let [x1, y1, , , x3, y3] = polygonData;
            selectedLines.forEach((lines) => {
              const {
                innerRectangleLiesWithinOuterRectangle,
                innerRectangleIntersectsOuterRectangle,
                outerRectangleIntersectsInnerRectangle,
              } = checkIfPolygonIsInsideAnotherPolygon(
                lines.x1,
                lines.y1,
                lines.x2,
                lines.y2,
                x1,
                y1,
                x3,
                y3,
              );
              if (
                innerRectangleLiesWithinOuterRectangle ||
                innerRectangleIntersectsOuterRectangle ||
                outerRectangleIntersectsInnerRectangle
              ) {
                containsNote = true;
              }
            });
          });
        } else if (polygon && polygon.length) {
          const polygonData = polygon.map((coord) => {
            return coord * dpi * scaleWithZoom;
          });
          let [x1, y1, , , x3, y3] = polygonData;
          selectedLines.forEach((lines) => {
            const {
              innerRectangleLiesWithinOuterRectangle,
              innerRectangleIntersectsOuterRectangle,
              outerRectangleIntersectsInnerRectangle,
            } = checkIfPolygonIsInsideAnotherPolygon(
              lines.x1,
              lines.y1,
              lines.x2,
              lines.y2,
              x1,
              y1,
              x3,
              y3,
            );
            if (
              innerRectangleLiesWithinOuterRectangle ||
              innerRectangleIntersectsOuterRectangle ||
              outerRectangleIntersectsInnerRectangle
            ) {
              containsNote = true;
            }
          });
        }
      });

    let selectedText = selection.text;
    let contextLabel = '';
    let rowIndex = -1;
    let columnIndex = -1;
    let polygon = selectedLines;
    let isTable = false;
    let tableId = -1;
    let tableDataSelected = [];

    let ocrLinesSelected = [];

    ocrLinesData.forEach((ocrLines, index) => {
      let [x1, y1, , , x3, y3] = ocrLines.polygon;

      selectedLines.forEach((lines) => {
        const { innerRectangleLiesWithinOuterRectangle } =
          checkIfPolygonIsInsideAnotherPolygon(
            x1,
            y1,
            x3,
            y3,
            lines.x1,
            lines.y1,
            lines.x2,
            lines.y2,
          );
        if (innerRectangleLiesWithinOuterRectangle) {
          ocrLinesSelected.push({ ...ocrLines, index });
        }
      });
    });

    selectedLines.forEach((lines) => {
      ocrTablesData.forEach((tablesData, index) => {
        let [x1, y1, , , x3, y3] = tablesData.polygon;
        const { innerRectangleLiesWithinOuterRectangle } =
          checkIfPolygonIsInsideAnotherPolygon(
            x1,
            y1,
            x3,
            y3,
            lines.x1,
            lines.y1,
            lines.x2,
            lines.y2,
            true,
          );
        if (innerRectangleLiesWithinOuterRectangle) {
          tablesData.content && tableDataSelected.push(tablesData);
        }
      });
    });

    const table_column_row_ids = tableDataSelected.map(
      (item) => item.spans[0].offset,
    );
    const filteredTableData = tableDataSelected.filter(
      (item, index) =>
        !table_column_row_ids.includes(item.spans[0].offset, index + 1),
    );
    const offsets = ocrLinesSelected.map((item) => item.spans[0].offset);
    const filtered = ocrLinesSelected
      .filter(
        (item, index) => !offsets.includes(item.spans[0].offset, index + 1),
      )
      .slice(0, selectedLines.length);

    const startIndex = filtered[0].index;
    const endIndex = filtered[filtered.length - 1].index;

    const preText = startIndex > 0 ? ocrLinesData[startIndex - 1] : null;
    const postText =
      endIndex < ocrLinesData.length - 1 ? ocrLinesData[endIndex + 1] : null;

    const lastWord = ocrWords.find(
      ({ span }) => span.offset === selection.endOffset,
    );

    filtered.forEach((item) => {
      contextLabel += item.content + ' ';
    });

    const updatedPolygon = polygonConvertor(polygon);

    setContextMenuData({
      preText: preText ? preText.content.trim() : '',
      postText: postText ? postText.content.trim() : '',
      selectedText: selectedText.trim(),
      contextLabel: contextLabel.trim(),
      rowIndex,
      columnIndex,
      polygon: updatedPolygon,
      isTable,
      tableId,
      sectionId,
      containsElement,
      containsNote,
      filteredTableData,
      containesElementArray,
      x: lastWord.polygon[4],
      y: lastWord.polygon[5],
    });
  };

  const findElementsForBacthUpdate = (batchUpdateCoordinates) => {
    const canvas = overlayCanvas.current;
    clearCanvas(canvas);
    const context = canvas.getContext('2d');
    let batchUpdateElements = [];
    elementList.forEach((elementDetails) => {
      if (elementDetails.isIncluded) {
        selectedElementInBatch({
          context,
          elementDetails,
          scale: scaleWithZoom,
          batchUpdateCoordinates,
          batchUpdateElements,
        });
      }
    });
    isSideBySideView
      ? batchSelectElementsInSideBySideMode({
          elements: batchUpdateElements,
          isLeftSideView: isLeftView,
        })
      : !!batchUpdateElements.length &&
        batchSelectElements({
          elements: batchUpdateElements,
        });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    if (
      isBatchModeSelected ||
      isSideBySideView ||
      shouldElementBatchSelection(selectMode)
    ) {
      let batchUpdateCoordinatesObject = {
        starting: {
          x: box && box.startX ? box.startX : null,
          y: box && box.startY ? box.startY : null,
        },
        ending: {
          x: box && box.endX ? box.endX : null,
          y: box && box.endY ? box.endY : null,
        },
      };
      findElementsForBacthUpdate(batchUpdateCoordinatesObject);
      setBox(null);
    } else {
      if (!selection) {
        setContextMenuData({});
      } else {
        mouseUpActions();
      }
    }
  };

  useEffect(() => {
    if (isContentPanelScrolling) {
      setContextMenuData({});
    }
  }, [isContentPanelScrolling]);

  const clearSelection = () => {
    const canvas = overlayCanvas.current;
    setContextMenuData({});
    clearCanvas(canvas);
    setCurrentSelectionState(null);
    setSelection(null);
    renderSelection([], overlayCanvas.current);
    setSelectedLines([]);
  };

  useEffect(() => {
    // Updating the position of selection and context menu on zoom change.
    let lastWord = {};
    if (selection) {
      lastWord = ocrWords.find(
        ({ span }) => span.offset === selection.endOffset,
      );
      const canvas = overlayCanvas.current;

      let newArrayOfCharacters = [];

      selection.characterRange.forEach((obj1) => {
        let matchingObj = ocrWords.find(
          (obj2) =>
            JSON.stringify(obj2.polygon_in_pixels) ===
            JSON.stringify(obj1.polygon_in_pixels),
        );

        if (matchingObj) {
          newArrayOfCharacters.push(matchingObj);
        }
      });
      const lines = processCharacterRangeAsTextLines(newArrayOfCharacters);
      setSelectedLines(lines);
      clearCanvas(canvas);
      renderSelection(lines, canvas);
    }

    if (Object.entries(lastWord).length) {
      setContextMenuData((prevData) => {
        return {
          ...prevData,
          x: lastWord.polygon[4],
          y: lastWord.polygon[5],
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom.zoomRatio, overlayCanvas.current]);

  return (
    <div
      id={
        isLeftView
          ? `${LEFT_BLOCK_ID}${sectionMetaData.sectionId}`
          : `${BLOCK_ID}${sectionMetaData.sectionId}`
      }
      style={pageStyles}
      className={`${BLOCK}__page-container`}
    >
      <div
        id={`${BLOCK}__container`}
        style={pageStyles}
        className={classNames(`${BLOCK}__page`)}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <SectioContentOverlayOCR
          BLOCK={BLOCK}
          ocrWords={ocrWords}
          sectionMetaData={sectionMetaData}
          isContentPanelScrolling={isContentPanelScrolling}
          selection={selection}
          setSelection={setSelection}
          sectionOCRCache={sectionOCRCache}
          elementList={isLoaded ? elementList : []}
          blacklineCache={blacklineCache}
          blacklineGuidDetails={blacklineGuidDetails}
          scaleWithZoom={scaleWithZoom}
          isSectionLoaded={isSectionLoaded}
          shouldShow={shouldShow}
          setShouldShow={setShouldShow}
          box={box}
          selectMode={selectMode}
          overlayCanvas={overlayCanvas}
          isDrawing={isDrawing}
          contextMenuData={contextMenuData}
          clearSelection={clearSelection}
          isBatchModeSelected={isBatchModeSelected}
          annotationsList={annotationsList}
          setAnnotaionsList={setAnnotaionsList}
          markerDisplayValue={markerDisplayValue}
          calloutDisplayValue={calloutDisplayValue}
          showElementStatusAndFlag={showElementStatusAndFlag}
          isLeftView={isLeftView}
          blacklineViewMode={blacklineViewMode}
          isSideBySideView={isSideBySideView}
          numberOfSourceElementSelected={numberOfSourceElementSelected}
          numberOfTargetElementSelected={numberOfTargetElementSelected}
          notesList={isLoaded ? notesList : []}
          contentSearchResult={contentSearchResult}
          dpi={dpi}
          setSectionInView={setSectionInView}
          clearSectionInView={clearSectionInView}
        />
        {isLoading && (
          <div className={`${BLOCK}__page--loading`}>
            <Loading />
          </div>
        )}
        {isLoaded && <img style={pageStyles} src={sectionCache.image} alt="" />}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  ui: {
    statementPage: {
      modes: { selectMode, blacklineViewMode },
    },
  },
  data: { selectedStatement, leftSelectedStatement, contentSearchResult },
}) => ({
  isBatchModeSelected:
    selectMode &&
    (selectMode.id === ELEMENT_SELECT_MODES.BATCH_WITH_BANNER.id ||
      selectMode.id === ELEMENT_SELECT_MODES.BATCH.id ||
      selectMode.id === ELEMENT_SELECT_MODES.FORMULA.id),
  selectMode,
  selectedStatement,
  leftSelectedStatement,
  blacklineViewMode,
  contentSearchResult,
});

const mapDispatchToProps = {
  batchSelectElementsInSideBySideMode,
  batchSelectElements,
};

export default withURLRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionContentOCR),
);
