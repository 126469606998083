import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, forwardRef } from 'react';
import useAutoSizeTextArea from 'components/hooks/useAutoSizeTextArea';
import useAutoFocus from 'components/hooks/useAutoFocus';
import { useIntl } from 'react-intl';

const TEXT_AREA_BLOCK = 'text-area-dynamic';

const DynamicTextArea = (
  {
    className,
    id,
    maxLength,
    onChange,
    onBlur,
    placeholder,
    value,
    rows = 1,
    readOnly,
    focusOnMount,
    maxVisibleRows,
    disabled,
  },
  ref,
) => {
  const intl = useIntl();
  useAutoSizeTextArea(ref, value, maxVisibleRows);
  useAutoFocus(ref, focusOnMount);

  return (
    <textarea
      ref={ref}
      className={classNames(TEXT_AREA_BLOCK, className)}
      id={id}
      maxLength={maxLength}
      name={id}
      onChange={(e) => onChange(e.target.value)}
      placeholder={intl.formatMessage({ id: placeholder })}
      rows={rows}
      value={value}
      onBlur={onBlur}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

DynamicTextArea.propTypes = {
  /** String custom class */
  className: PropTypes.string,
  /** Unique string id for text area */
  id: PropTypes.string.isRequired,
  /** Maximum text area length */
  maxLength: PropTypes.number,
  /** Unique string name for text area */
  name: PropTypes.string.isRequired,
  /** Function fired on user input return value*/
  onChange: PropTypes.func.isRequired,
  /** Function fired when focus is lost from text area*/
  onBlur: PropTypes.func,
  /** Internationalized sting id */
  placeholder: PropTypes.string,
  /** Current value of the text area*/
  value: PropTypes.string,
  /** Initial number of rows to be visible in text area*/
  rows: PropTypes.number,
  /** Read only boolean */
  readOnly: PropTypes.bool,
};

export default memo(forwardRef(DynamicTextArea));
