import ContentSearchResults from 'models/api/statement-content-search-results-api-model';
import { handleActions } from 'redux-actions';
import {
  contentSearchResultLoading,
  contentSearchResultLoaded,
  contentSearchResultError,
  clearContentSearchResult,
  setContentSearchResultSelectedIndex,
} from 'store/actions/content-search-result-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [contentSearchResultLoading]: (contentSearchResult) => {
      return contentSearchResult.setLoading();
    },
    [contentSearchResultLoaded]: (contentSearchResult, { payload }) => {
      return contentSearchResult.setLoaded(payload);
    },
    [contentSearchResultError]: (contentSearchResult, { payload }) => {
      return contentSearchResult.setError(payload);
    },
    [setContentSearchResultSelectedIndex]: (
      contentSearchResult,
      { payload },
    ) => {
      return contentSearchResult.setSelectedIndex(payload);
    },
    [clearContentSearchResult]: () => {
      return new ContentSearchResults();
    },
    [clearStatementPageStoreAction]: () => {
      return new ContentSearchResults();
    },
  },
  new ContentSearchResults(),
);
