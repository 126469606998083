import React from 'react';
import TabModel from 'models/utils/common/tab-model';
import { ReactComponent as FlaggedVerifiedIcon } from 'icons/flagged-and-verified.svg';
import { ReactComponent as FlaggedIcon } from 'icons/flagged.svg';
import { ReactComponent as VerifiedIcon } from 'icons/verified.svg';
import { ReactComponent as UnverifiedIcon } from 'icons/unverified.svg';
import { ReactComponent as ReviewedIcon } from 'icons/unverified.svg';
import { ReactComponent as FlaggedReviewedIcon } from 'icons/unverified.svg';
import { isNullOrUndefined } from 'utils/object-utils';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';

const CONTENT_PANEL_ICON_SIZE = '12px';

export const STATEMENT_CONTENT_TABS = {
  STATEMENT: new TabModel({
    id: 1,
    intlTitle: 'global-header.statement',
  }),
  SPLIT: new TabModel({
    id: 2,
    intlTitle: 'global-header.split',
  }),
};

export const SCALE_ALTERATION = 0.5;
export const STATEMENT_ELEMENT_ID_PREFIX = 'CFTO_ELEMENT_';
export const STATEMENT_LEFT_ELEMENT_ID_PREFIX = 'CFTO_LEFT_ELEMENT_';
export const STATEMENT_SECTION_ID_PREFIX = 'CFTO_SECTION_';
export const STATEMENT_PSEUDO_ELEMENT_ID_PREFIX = 'CFTO_PSEUDO_ELEMENT_';
/** Since SectionContent components render always with a loader they have their own ID */
export const REACT_SECTION_ID_PREFIX = 'REACT_SECTION_';
export const OCR_SECTION_ID = 'statement-content-panel-page-';
export const OCR_LEFT_SECTION_ID = 'statement-content-panel-page-left-';
export const OCR_CONTAINER_BLOCK = 'statement-content-panel-ocr';
export const STATEMENT_CONTENT_PANEL_ZOOM_BLOCK =
  'statement-content-panel-zoom-id';
export const STATEMENT_CONTENT_PANEL_ZOOM_LEFT_BLOCK =
  'statement-content-panel-zoom-left-id';
export const SIDE_BY_SIDE_VIEW_RIGHT_BLOCK =
  'statement-content-page-id__center-panel'; //side-by-side right view legacy doc ID
export const SIDE_BY_SIDE_VIEW_LEFT_BLOCK =
  'statement-content-page-id__center-panel-left'; //side-by-side left view legacy doc ID
export const LEFT_VIEW_DOCUMENT_ID = `${OCR_CONTAINER_BLOCK}__document-id-left-view`; //side-by-side left view OCR doc ID
export const RIGHT_VIEW_DOCUMENT_ID = `${OCR_CONTAINER_BLOCK}__document-id`; //side-by-side right view OCR doc ID
export const SIDE_BY_SIDE_VIEW_OCR_COMBINATION_STATEMENT =
  'OCR_Combination_statements'; // represents both statements in side-by-side view as OCR statements
export const SIDE_BY_SIDE_VIEW_LEGACY_COMBINATION_STATEMENT =
  'Legacy_Combination_statements'; // represents both statements in side-by-side view as Legacy statements
export const SIDE_BY_SIDE_VIEW_LEFT_STATEMENT =
  'statement-content-panel-page-left-'; //side-by-side left view OCR sections doc ID
export const SIDE_BY_SIDE_VIEW_RIGHT_STATEMENT =
  'statement-content-panel-page-'; //side-by-side right view OCR sections doc ID
export const REPORT_PREVIEW_PAGE_HTML_CONTAINER_ID =
  'report-preview-page-left-panel-id__left-preview-html';

// In Side by side page we have two statements side by side. Hence we should have different section id prefix
// for the two statements, so that id across the DOM is unique.
export const REACT_LEFT_STATEMENT_SECTION_ID_PREFIX = 'REACT_LEFT_SECTION_';

export const CONTENT_SEARCH_HIGHLIGHT_CLASS = 'content-search-highlight';

export const getStatusIcon = ({ elementData }) => {
  if (isNullOrUndefined(elementData)) {
    return null;
  } else if (elementData.isFlaggedAndReviewed()) {
    return (
      <FlaggedReviewedIcon
        width={CONTENT_PANEL_ICON_SIZE}
        height={CONTENT_PANEL_ICON_SIZE}
      />
    );
  } else if (elementData.isFlaggedAndVerified()) {
    return (
      <FlaggedVerifiedIcon
        width={CONTENT_PANEL_ICON_SIZE}
        height={CONTENT_PANEL_ICON_SIZE}
      />
    );
  } else if (elementData.isReviewed()) {
    return (
      <ReviewedIcon
        width={CONTENT_PANEL_ICON_SIZE}
        height={CONTENT_PANEL_ICON_SIZE}
      />
    );
  } else if (elementData.isUserFlagged()) {
    return (
      <FlaggedIcon
        width={CONTENT_PANEL_ICON_SIZE}
        height={CONTENT_PANEL_ICON_SIZE}
      />
    );
  } else if (elementData.isVerified()) {
    return (
      <VerifiedIcon
        width={CONTENT_PANEL_ICON_SIZE}
        height={CONTENT_PANEL_ICON_SIZE}
      />
    );
  } else {
    return (
      <UnverifiedIcon
        width={CONTENT_PANEL_ICON_SIZE}
        height={CONTENT_PANEL_ICON_SIZE}
      />
    );
  }
};

export const EXPORT_PAGE_SIZE_ID = {
  LETTER: 1, // Letter (8.5 x 11)
  LEGAL: 2, // Legal (8.5 x 14)
  TABLOID: 3, // Tabloid (11 x 17)
  LEDGER: 4, // Ledger (17 x 11)
  A4: 5, // A4 (8.27 x 11.69)
  A3: 6, // A3 (11.69 x 16.54)
  A2: 7, // A2 (16.54 x 23.39)
};

export const EXPORT_PAGE_SIZE_OBJECT = {
  1: new MenuOption({
    id: 'page-size-option-letter',
    title: 'export-workpaper-pagesize-option-letter',
    value: EXPORT_PAGE_SIZE_ID.LETTER,
  }),
  2: new MenuOption({
    id: 'page-size-option-legal',
    title: 'export-workpaper-pagesize-option-legal',
    value: EXPORT_PAGE_SIZE_ID.LEGAL,
  }),
  3: new MenuOption({
    id: 'page-size-option-tabloid',
    title: 'export-workpaper-pagesize-option-tabloid',
    value: EXPORT_PAGE_SIZE_ID.TABLOID,
  }),
  4: new MenuOption({
    id: 'page-size-option-ledger',
    title: 'export-workpaper-pagesize-option-ledger',
    value: EXPORT_PAGE_SIZE_ID.LEDGER,
  }),
  5: new MenuOption({
    id: 'page-size-option-a4',
    title: 'export-workpaper-pagesize-option-a4',
    value: EXPORT_PAGE_SIZE_ID.A4,
  }),
  6: new MenuOption({
    id: 'page-size-option-a3',
    title: 'export-workpaper-pagesize-option-a3',
    value: EXPORT_PAGE_SIZE_ID.A3,
  }),
  7: new MenuOption({
    id: 'page-size-option-a2',
    title: 'export-workpaper-pagesize-option-a2',
    value: EXPORT_PAGE_SIZE_ID.A2,
  }),
};

export const EXPORT_PAGE_ORIENTATION_PORTRAIT = 'Portrait';

export const getElementHightlightState = (
  panel,
  elementDetailsId,
  elementId,
) => {
  // interntional double equal
  // eslint-disable-next-line eqeqeq
  if (panel === RIGHT_PANELS.ELEMENT && elementDetailsId == elementId) {
    return ELEMENT_HIGHLIGHT_STATES.OPENED;
  } else {
    return ELEMENT_HIGHLIGHT_STATES.DEFAULT;
  }
};

export const BLACKLINE_VIEW_STATUS = {
  BLK_REQ_RECEIVED: 'BLK_REQ_RECEIVED',
  GENERATING: 'GENERATING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};
