import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import TextArea from 'components/common/text-area-component';
import KebabDropdown from 'components/common/dropdown-component';
import { FormattedMessage } from 'react-intl';
import { setTOCRefresh } from 'store/actions/TOC-actions';
import {
  fetchAllSectionRenderingData,
  fetchSectionIdListStatementContent,
} from 'store/actions/statement-content-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import { getContentSectionMapRequest } from 'store/actions/section-cache-actions';
import { OK } from 'http-status-codes';
import { immediateScrollToSectionWhenLoaded } from 'utils/scrolling-utils';
import { updateRevisionsSectionRequest } from 'api/sections-api';
import PropTypes from 'prop-types';
import { EntityList } from 'models/api/entity-list-model';
import SectionContentModel from 'models/api/section-content-api-model';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { updateSectionDetailsStore } from 'store/actions/statement-content-actions';
import { updateCacheWithMostRecentElementChanges } from 'store/actions/element-changes-since-tracker-actions';
import { fetchSectionHistoryDetails } from 'store/actions/section-review-history-actions';
import { replaceSelectedSectionsContentPanel } from 'store/actions/statement-content-actions';
import { SECTION_HIGHLIGHT_STATES } from 'constants/feature/section-details-constants';

const EDIT_SECTION_ID_BLOCK = 'EDIT_SECTION_ID_BLOCK';
const EDIT_SECTION_MODAL = 'statement-content-panel--edit-section';

class EditSectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.node.name,
      disableSaveBtn: false,
      // We are setting the default entity value to the entity value we get back for each section
      entity: props.entityList.data.entitiesDropdownMap[props.node.entity],
    };
  }

  onChange = (e) => {
    this.setState({ name: e });
  };

  componentDidUpdate(prevProps, prevState) {
    const { name, disableSaveBtn } = this.state;

    if (!name && !disableSaveBtn) {
      this.setState({ disableSaveBtn: true });
    } else if (name && disableSaveBtn) {
      this.setState({ disableSaveBtn: false });
    }
  }

  highlightCreatedNavigation = (id) => {
    const { replaceSelectedSectionsContentPanel } = this.props;
    replaceSelectedSectionsContentPanel({
      color: SECTION_HIGHLIGHT_STATES.CREATED_NAV,
      sectionIds: [id],
    });
    /** Set one time click event listener to clear green selected elements after
     * successful editing a section navigation
     */
    document.addEventListener(
      'click',
      (e) => {
        if (!e.ctrlKey) {
          replaceSelectedSectionsContentPanel({
            color: SECTION_HIGHLIGHT_STATES.DEFAULT,
            sectionIds: [id],
          });
        }
      },
      {
        once: true,
      },
    );
  };

  onSave = async (node, name, entity) => {
    const section = { ...node };
    const sectionId = section.id;
    const {
      onClose,
      fetchSectionIdListStatementContent,
      getContentSectionMapRequest,
      setTOCRefresh,
      fetchStatementSummaryElements,
      fetchAllSectionRenderingData,
      updateSectionDetailsStore,
      panels,
      updateCacheWithMostRecentElementChanges,
      fetchSectionHistoryDetails,
      revision,
      sectionReviewList,
      socketHasBeenDisconnected,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    let isSectionReviewed = 0;
    if (sectionReviewList && sectionReviewList.sectionReviews) {
      for (let i = 0; i < sectionReviewList.sectionReviews.length; i++) {
        if (sectionReviewList.sectionReviews[i].sectionId === sectionId) {
          isSectionReviewed = 1;
          break;
        }
      }
    }
    onClose();

    updateRevisionsSectionRequest(
      section.revisionId,
      sectionId,
      name,
      entity.value,
    ).then((resp) => {
      if (resp && resp.status === OK) {
        fetchSectionIdListStatementContent(section.revisionId);
        getContentSectionMapRequest({
          revisionId: section.revisionId,
        });
        setTOCRefresh(true);
        if (socketHasBeenDisconnected) {
          fetchStatementSummaryElements({
            revisionId: section.revisionId,
          });
        }
        fetchAllSectionRenderingData({ sectionId });
        immediateScrollToSectionWhenLoaded({ sectionId });
        fetchSectionHistoryDetails({
          revisionId: revision.id,
          sectionId,
        });
        if (panels.right === RIGHT_PANELS.SECTION) {
          updateSectionDetailsStore({ section: resp.data });
        }
        if (panels.right === RIGHT_PANELS.ELEMENT) {
          updateCacheWithMostRecentElementChanges();
        }

        this.highlightCreatedNavigation(sectionId);
      }
    });
  };

  render() {
    const { node, entityList, onClose } = this.props;
    const { entity, name, disableSaveBtn } = this.state;
    return (
      <Modal
        id={EDIT_SECTION_ID_BLOCK}
        title={'edit-section-modal.title'}
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'common.save',
            disabled: disableSaveBtn,
            onClick: () => this.onSave(node, name, entity),
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.cancel',
            onClick: onClose,
          })
        }
      >
        <TextArea
          label="edit-section-modal.section.label"
          isValid={true}
          placeholder="edit-section-modal.section.placeholder"
          value={name}
          width={'100%'}
          readonly={false}
          onChange={(e) => this.onChange(e)}
          id="edit-section-modal.section.label.id"
        />

        <div className={`${EDIT_SECTION_MODAL}__entity-list`}>
          <FormattedMessage id="add-navigation-modal.form.entity.label" />
        </div>
        <KebabDropdown
          className={'entity-type__kebab'}
          menuClassName={'entity__kebab-menu'}
          id={'project-kebab'}
          options={Object.values(entityList.data.entitiesDropdownMap)}
          width={'100%'}
          onClickFormItem={(e) => {
            e.stopPropagation();
          }}
          value={entity}
          onSelectOption={(option) => {
            this.setState({
              entity: option,
            });
          }}
        />
      </Modal>
    );
  }
}

EditSectionModal.propTypes = {
  /** Section the segment is responsible for fetching and rendering */
  node: PropTypes.instanceOf(SectionContentModel),
  /** Entity list of project */
  entityList: PropTypes.instanceOf(EntityList).isRequired,
  /** Modal panel onClose fucntion  */
  onClose: PropTypes.func.isRequired,
  /** redux action to refresh table of content panel  */
  setTOCRefresh: PropTypes.func.isRequired,
  /** redux action to retrieve section details  */
  fetchAllSectionRenderingData: PropTypes.func.isRequired,
  /** redux action to retrieve statement summary elements  */
  fetchStatementSummaryElements: PropTypes.func.isRequired,
  /** redux action to retrieve content section map  */
  getContentSectionMapRequest: PropTypes.func.isRequired,
  /** redux action to retrieve section id list  */
  fetchSectionIdListStatementContent: PropTypes.func.isRequired,
  /** redux action to load and scroll to selected section in content body  */
  immediateScrollToSectionWhenLoaded: PropTypes.func.isRequired,
  /** Action fired to update or delete the selection section details in store */
  updateSectionDetailsStore: PropTypes.func.isRequired,
  /** action that updates elements cache */
  updateCacheWithMostRecentElementChanges: PropTypes.func.isRequired,
  /** action that fetches the section history with the loading animation */
  fetchSectionHistoryDetails: PropTypes.func.isRequired,
  /*action to highlight sections on content panel*/
  replaceSelectedSectionsContentPanel: PropTypes.func.isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  data: {
    elementPanel: { entityList },
    revision,
    sectionPanel: { sectionReviewList },
  },
  ui: {
    statementPage: { panels },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  entityList,
  revision,
  panels,
  socketHasBeenDisconnected,
  sectionReviewList,
});

const mapDispatchToProps = {
  setTOCRefresh,
  fetchStatementSummaryElements,
  fetchAllSectionRenderingData,
  getContentSectionMapRequest,
  fetchSectionIdListStatementContent,
  immediateScrollToSectionWhenLoaded,
  updateSectionDetailsStore,
  updateCacheWithMostRecentElementChanges,
  fetchSectionHistoryDetails,
  replaceSelectedSectionsContentPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSectionModal);
