import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import {
  ELEMENT_SELECT_MODES,
  ELEMENT_SELECT_MODES_ARRAY,
  CONTENT_HIGHLIGHT_MODES_ARRAY,
} from 'constants/feature/modes-constants';
import { TOOLKIT_OPTIONS } from 'constants/feature/toolkit-constants';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { isProjectExistInAMERegion } from 'utils/project-utils';
import Tooltip from 'components/common/tool-tip-component';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  toggleNotesPanel,
  toggleStatementNavPanel,
  toggleTickmarkPanel,
  toggleComparePanel,
  toggleExportPanel,
  toggleWorkPaperPanel,
  toggleAuditChecklistPanel,
  toggleComfortAnnotationPanel,
  toggleChatBotPanel,
} from 'store/actions/panel-controller-actions';
import {
  LEFT_PANELS,
  RIGHT_PANELS_ARRAY,
  LEFT_PANELS_ARRAY,
  RIGHT_PANELS,
} from 'constants/feature/panel-constants';
import {
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  toggleFormulaCancelModal,
} from 'store/actions/panel-cancel-modal-actions';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import SelectedStatement from 'models/api/selected-statement-model';

export const TOOLKIT_BLOCK = 'toolkit';
const TOOLKIT_ID_BLOCK = 'toolkit-id';
const TOOLKIT_ICON_SIZE = '28px';
const NOTES_ICON_SIZE = '20px';

class Toolkit extends Component {
  render() {
    const {
      statementNav,
      notes,
      tickmarks,
      compare,
      exportOption,
      workPaper,
      auditChecklist,
      comfortAnnotationPanel,
      sideBySidePanel,
      chatBotpanel,
    } = TOOLKIT_OPTIONS;
    const {
      onStatementNavClick,
      onNotesClick,
      onTickmarksClick,
      onCompareClick,
      currLeftPanel,
      currRightPanel,
      blacklineViewMode,
      onExportClick,
      toggleFormulaCancelModal,
      selectMode,
      toggleInternalReferenceCancelModal,
      openCancelModalAfterModalConfirm,
      onWorkPaperClick,
      onChatBotIconClick,
      onAuditChecklistClick,
      onComfortAnnotationButtonClick,
      push,
      match,
      project,
      selectedStatement,
    } = this.props;
    return (
      <div className={TOOLKIT_BLOCK}>
        <Tooltip
          id={`${TOOLKIT_ID_BLOCK}__statement-nav-tooltip`}
          text={blacklineViewMode ? null : 'toolkit.icon.statement-nav.tooltip'}
          position={'right'}
        >
          <button
            id={statementNav.id}
            className={classnames(
              `${TOOLKIT_BLOCK}__option`,
              LEFT_PANELS.STATEMENT_NAV === currLeftPanel
                ? `${TOOLKIT_BLOCK}__option--selected`
                : null,
              { [`${TOOLKIT_BLOCK}__option--disabled`]: blacklineViewMode },
            )}
            disabled={blacklineViewMode}
            onClick={() => {
              onStatementNavClick();
            }}
          >
            <statementNav.Icon
              width={TOOLKIT_ICON_SIZE}
              height={TOOLKIT_ICON_SIZE}
            />
          </button>
        </Tooltip>
        <Tooltip
          id={`${TOOLKIT_ID_BLOCK}__notes-tooltip`}
          text={blacklineViewMode ? null : 'toolkit.icon.notes.tooltip'}
          position={'right'}
        >
          <button
            id={notes.id}
            className={classnames(
              `${TOOLKIT_BLOCK}__option`,
              RIGHT_PANELS.NOTES === currRightPanel
                ? `${TOOLKIT_BLOCK}__option--selected`
                : null,
              { [`${TOOLKIT_BLOCK}__option--disabled`]: blacklineViewMode },
            )}
            disabled={blacklineViewMode}
            onClick={() => {
              if (selectMode.title === ELEMENT_SELECT_MODES.FORMULA.title) {
                openCancelModalAfterModalConfirm(true);
                toggleFormulaCancelModal(true);
              } else if (
                selectMode.title ===
                ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.title
              ) {
                toggleInternalReferenceCancelModal(true);
                openCancelModalAfterModalConfirm(true);
              } else {
                onNotesClick();
              }
            }}
          >
            <notes.Icon width={NOTES_ICON_SIZE} height={NOTES_ICON_SIZE} />
          </button>
        </Tooltip>
        <Tooltip
          id={`${TOOLKIT_ID_BLOCK}__tickmark-tooltip`}
          text={blacklineViewMode ? null : 'toolkit.icon.tickmark.tooltip'}
          position={'right'}
        >
          <button
            id={tickmarks.id}
            className={classnames(
              `${TOOLKIT_BLOCK}__option`,
              LEFT_PANELS.TICKMARK === currLeftPanel
                ? `${TOOLKIT_BLOCK}__option--selected`
                : null,
              { [`${TOOLKIT_BLOCK}__option--disabled`]: blacklineViewMode },
            )}
            disabled={blacklineViewMode}
            onClick={() => {
              onTickmarksClick();
            }}
          >
            <tickmarks.Icon width={NOTES_ICON_SIZE} height={NOTES_ICON_SIZE} />
          </button>
        </Tooltip>
        <Tooltip
          id={`${TOOLKIT_ID_BLOCK}__workpaper-tooltip`}
          text={blacklineViewMode ? null : 'toolkit.icon.workpaper.tooltip'}
          position={'right'}
        >
          <button
            id={workPaper.id}
            className={classnames(
              `${TOOLKIT_BLOCK}__option`,
              LEFT_PANELS.WORKPAPER === currLeftPanel
                ? `${TOOLKIT_BLOCK}__option--selected`
                : null,
              { [`${TOOLKIT_BLOCK}__option--disabled`]: blacklineViewMode },
            )}
            disabled={blacklineViewMode}
            onClick={() => {
              onWorkPaperClick();
            }}
          >
            <workPaper.Icon width={NOTES_ICON_SIZE} height={NOTES_ICON_SIZE} />
          </button>
        </Tooltip>
        <Tooltip
          id={`${TOOLKIT_ID_BLOCK}__export-tooltip`}
          text={blacklineViewMode ? null : 'toolkit.icon.export.tooltip'}
          position={'right'}
        >
          <button
            id={exportOption.id}
            className={classnames(
              `${TOOLKIT_BLOCK}__option`,
              LEFT_PANELS.EXPORT === currLeftPanel ||
                LEFT_PANELS.DOWNLOAD === currLeftPanel
                ? `${TOOLKIT_BLOCK}__option--selected`
                : null,
              { [`${TOOLKIT_BLOCK}__option--disabled`]: blacklineViewMode },
            )}
            disabled={blacklineViewMode}
            onClick={() => {
              onExportClick();
            }}
          >
            <exportOption.Icon
              width={NOTES_ICON_SIZE}
              height={NOTES_ICON_SIZE}
            />
          </button>
        </Tooltip>
        {isProjectExistInAMERegion(project) && (
          <Tooltip
            id={`${TOOLKIT_ID_BLOCK}__auditChecklist-tooltip`}
            text={
              blacklineViewMode ? null : 'toolkit.icon.auditChecklist.tooltip'
            }
            position={'right'}
          >
            <button
              id={auditChecklist.id}
              className={classnames(
                `${TOOLKIT_BLOCK}__option`,
                LEFT_PANELS.AUDIT_CHECKLIST === currLeftPanel
                  ? `${TOOLKIT_BLOCK}__option--selected`
                  : null,
                { [`${TOOLKIT_BLOCK}__option--disabled`]: blacklineViewMode },
              )}
              disabled={blacklineViewMode}
              onClick={() => {
                onAuditChecklistClick();
              }}
            >
              <auditChecklist.Icon
                width={NOTES_ICON_SIZE}
                height={NOTES_ICON_SIZE}
              />
            </button>
          </Tooltip>
        )}
        <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
          <Tooltip
            id={`${TOOLKIT_ID_BLOCK}__comfort-annotation-tooltip`}
            text={
              blacklineViewMode
                ? null
                : 'toolkit.icon.comfort-annotation.tooltip'
            }
            position={'right'}
          >
            <button
              id={comfortAnnotationPanel.id}
              className={classnames(
                `${TOOLKIT_BLOCK}__option`,
                LEFT_PANELS.COMFORT_ANNOTATION === currLeftPanel
                  ? `${TOOLKIT_BLOCK}__option--selected`
                  : null,
                { [`${TOOLKIT_BLOCK}__option--disabled`]: blacklineViewMode },
              )}
              disabled={blacklineViewMode}
              onClick={() => {
                onComfortAnnotationButtonClick();
              }}
            >
              <comfortAnnotationPanel.Icon
                width={NOTES_ICON_SIZE}
                height={NOTES_ICON_SIZE}
              />
            </button>
          </Tooltip>
        </FeatureFlagComponent>
        <FeatureFlagComponent
          name={FEATURE_NAMES.SIDE_BY_SIDE_STATEMENT_ELEMENTS_MAP}
        >
          <Tooltip
            id={`${TOOLKIT_ID_BLOCK}__sidebyside-tooltip`}
            text={blacklineViewMode ? null : 'toolkit.icon.sidebyside.tooltip'}
            position={'right'}
          >
            <button
              id={sideBySidePanel.id}
              className={classnames(`${TOOLKIT_BLOCK}__option`, {
                [`${TOOLKIT_BLOCK}__option--selected`]:
                  LEFT_PANELS.SIDE_BY_SIDE_STATEMENT_COMPARISON ===
                  currLeftPanel,
                [`${TOOLKIT_BLOCK}__option--disabled`]:
                  (selectedStatement &&
                    selectedStatement.isSideBySideMenuDisabled()) ||
                  blacklineViewMode,
              })}
              disabled={
                (selectedStatement &&
                  selectedStatement.isSideBySideMenuDisabled()) ||
                blacklineViewMode
              }
              onClick={() => {
                push(`${match.url}${ROUTE_CONSTANTS.SIDE_BY_SIDE}`);
              }}
            >
              <div
                className={classnames({
                  [`${TOOLKIT_BLOCK}__option--disabled-icon`]:
                    selectedStatement &&
                    selectedStatement.isSideBySideMenuDisabled(),
                })}
                disabled={
                  (selectedStatement &&
                    selectedStatement.isSideBySideMenuDisabled()) ||
                  blacklineViewMode
                }
              >
                <sideBySidePanel.Icon
                  width={NOTES_ICON_SIZE}
                  height={NOTES_ICON_SIZE}
                />
              </div>
            </button>
          </Tooltip>
        </FeatureFlagComponent>
        <FeatureFlagComponent name={FEATURE_NAMES.CHATBOT_ENABLED}>
          {selectedStatement.isOCR && (
            <Tooltip
              id={`${TOOLKIT_ID_BLOCK}__chat-bot-tooltip`}
              text={'toolkit.icon.chatbot.tooltip'}
              position={'right'}
            >
              <button
                id={chatBotpanel.id}
                className={classnames(`${TOOLKIT_BLOCK}__option`, {
                  [`${TOOLKIT_BLOCK}__option--selected`]:
                    LEFT_PANELS.CHAT_BOT === currLeftPanel,
                })}
                onClick={() => {
                  onChatBotIconClick();
                }}
              >
                <chatBotpanel.Icon
                  width={NOTES_ICON_SIZE}
                  height={NOTES_ICON_SIZE}
                />
              </button>
            </Tooltip>
          )}
        </FeatureFlagComponent>
        {blacklineViewMode && (
          <Tooltip
            id={`${TOOLKIT_ID_BLOCK}__compare-tooltip`}
            text="toolkit.icon.compare.tooltip"
            position={'right'}
          >
            <button
              id={compare.id}
              className={classnames(
                `${TOOLKIT_BLOCK}__option`,
                LEFT_PANELS.COMPARE === currLeftPanel
                  ? `${TOOLKIT_BLOCK}__option--selected`
                  : null,
              )}
              onClick={() => {
                onCompareClick();
              }}
            >
              <compare.Icon width={NOTES_ICON_SIZE} height={NOTES_ICON_SIZE} />
            </button>
          </Tooltip>
        )}
      </div>
    );
  }
}

Toolkit.propTypes = {
  /** Current select mode, controls the tools available in the toolkit */
  selectMode: PropTypes.oneOf(ELEMENT_SELECT_MODES_ARRAY).isRequired,
  /** Current highlight mode, controls the tools available in the toolkit */
  highlightMode: PropTypes.oneOf(CONTENT_HIGHLIGHT_MODES_ARRAY),
  /** action fired when the statement nav button is clicked  */
  onStatementNavClick: PropTypes.func.isRequired,
  /** Action fired to reveal the notes panel */
  onNotesClick: PropTypes.func.isRequired,
  /** Action fired to reveal the tickmarks panel */
  onTickmarksClick: PropTypes.func.isRequired,
  /** Currently open left panel */
  currLeftPanel: PropTypes.oneOf(LEFT_PANELS_ARRAY).isRequired,
  /** Currently open right panel */
  currRightPanel: PropTypes.oneOf(RIGHT_PANELS_ARRAY).isRequired,
  /** action fired to open the formula cancel modal */
  toggleFormulaCancelModal: PropTypes.func,
  /** action fired to open the internal reference cancel modal */
  toggleInternalReferenceCancelModal: PropTypes.func,
  /** action fired to indicate the note panel will be opened after modal is confirmed */
  openCancelModalAfterModalConfirm: PropTypes.func,
  /** action fired to open the WP toolkit panel */
  onWorkPaperClick: PropTypes.func,
  /** action fired to open the Audit Checklist toolkit panel */
  onAuditChecklistClick: PropTypes.func,
  /** action fired to open the Comfort Annotation toolkit panel */
  onComfortAnnotationButtonClick: PropTypes.func,
  /** selected statement used to check whethe side-by-side icon should be disabled or not */
  selectedStatement: PropTypes.instanceOf(SelectedStatement),
};

const mapStateToProps = ({
  data: { revision, selectedProject, selectedStatement },
  ui: {
    statementPage: {
      modes: { selectMode, highlightMode, blacklineViewMode },
      panels: { left, right },
    },
  },
}) => ({
  revision,
  selectMode,
  highlightMode,
  currLeftPanel: left,
  currRightPanel: right,
  blacklineViewMode,
  project: selectedProject.project,
  selectedStatement,
});

const mapDispatchToProps = {
  onNotesClick: toggleNotesPanel,
  onTickmarksClick: toggleTickmarkPanel,
  onStatementNavClick: toggleStatementNavPanel,
  onCompareClick: toggleComparePanel,
  onExportClick: toggleExportPanel,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  onWorkPaperClick: toggleWorkPaperPanel,
  onChatBotIconClick: toggleChatBotPanel,
  onAuditChecklistClick: toggleAuditChecklistPanel,
  onComfortAnnotationButtonClick: toggleComfortAnnotationPanel,
  push,
};
export { Toolkit };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Toolkit),
);
