import React from 'react';
import MessageBubble from './toolkit-chatbot-panel-message-bubble';
import { FormattedMessage } from 'react-intl';

const ChatWindow = ({
  BLOCK,
  messages,
  setMessages,
  currentUser,
  containerCode,
  cbFeedbackEnabled,
  isIndexingCompleted,
  geoCode,
}) => {
  return (
    <>
      {!isIndexingCompleted ? (
        <div className={`${BLOCK}__chat-not-available-message`}>
          <FormattedMessage id={'chatbot-body.indexing.incomplete.message'} />
        </div>
      ) : (
        <div className={`${BLOCK}__chat-window`}>
          {messages.map((message) => (
            <MessageBubble
              key={message.id}
              message={message}
              BLOCK={BLOCK}
              setMessages={setMessages}
              currentUser={currentUser}
              containerCode={containerCode}
              cbFeedbackEnabled={cbFeedbackEnabled}
              geoCode={geoCode}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ChatWindow;
