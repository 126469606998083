import { RadioOption } from 'models/utils/common/radio-options-model';

const BULK = 'bulk';
export const SECTIONS_BULK_ACTIONS_TYPE = {
  ASSIGN: 'assign',
  UNASSIGN: 'unassign',
  REVIEW: 'review',
  UNREVIEW: 'unreview',
};

const BULK_ACTIONS_RADIO_OPTIONS = {
  assign: new RadioOption({
    id: `${BULK}__${SECTIONS_BULK_ACTIONS_TYPE.ASSIGN}`,
    label: 'statement-navigator.bulk-action.radio.label.assign',
    value: SECTIONS_BULK_ACTIONS_TYPE.ASSIGN,
  }),
  unassign: new RadioOption({
    id: `${BULK}__${SECTIONS_BULK_ACTIONS_TYPE.UNASSIGN}`,
    label: 'statement-navigator.bulk-action.radio.label.unassign',
    value: SECTIONS_BULK_ACTIONS_TYPE.UNASSIGN,
  }),
  review: new RadioOption({
    id: `${BULK}__${SECTIONS_BULK_ACTIONS_TYPE.REVIEW}`,
    label: 'statement-navigator.bulk-action.radio.label.review',
    value: SECTIONS_BULK_ACTIONS_TYPE.REVIEW,
  }),
  unreview: new RadioOption({
    id: `${BULK}__${SECTIONS_BULK_ACTIONS_TYPE.UNREVIEW}`,
    label: 'statement-navigator.bulk-action.radio.label.unreview',
    value: SECTIONS_BULK_ACTIONS_TYPE.UNREVIEW,
  }),
};

export const BULK_ACTIONS_RADIO_OPTIONS_ARRAY = Object.values(
  BULK_ACTIONS_RADIO_OPTIONS,
);
