import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as AnnotationIndicator } from 'icons/annotation-indicator.svg';
import {
  isElementReportPreviewPage,
  getCalloutVisibilityDetails,
  getInitialCalloutVisibilityDetails,
} from 'utils/ocr-annotation-utils';
import {
  getMarkerPosition,
  getCalloutPosition,
} from 'utils/ocr-annotation-utils';
import { updateSideBySideElementCacheByCallouts } from 'store/actions/statement-content-annotation-creation-actions';
import AnnotationCalloutComponent from './tieout-element-common/annotation-callout-component';
import { setSelectedElementDetails } from 'store/actions/report-preview-page-actions';
import { getElementBoundaryInfo } from 'utils/ocr-element-utils';

const OcrCallouts = ({
  elementId,
  BLOCK,
  polygonInPixel,
  elementDetails,
  width,
  children,
  annotationDisplayFilter,
  annotation,
  annotationPosition,
  setAnnotation,
  sectionId,
  canvasID,
  isLeftView = false,
}) => {
  const dispatch = useDispatch();
  const url = useLocation().pathname;
  const isPreviewPage = isElementReportPreviewPage(url);

  const ANNOTATION_INDICATOR_WIDTH = '13px';
  const ANNOTATION_INDICATOR_HEIGHT = '8px';

  const [annotationStyle, setAnnotationStyle] = useState({
    marker: {},
    callout: {},
  });

  const handleIndicatorClick = (elementId) => {
    if (isPreviewPage) {
      dispatch(
        setSelectedElementDetails({
          selectedElementId: elementId,
          isClickedOnReport: false,
        }),
      );
    } else {
      const initialCalloutVisibilityDetails = {
        displayMarker: !annotation['marker'],
        displayCallout: !annotation['callout'],
        displayAnnotation: !annotation['display'],
      };

      const { displayCallout } = getCalloutVisibilityDetails(
        initialCalloutVisibilityDetails,
        annotation.annotationsConfig,
        annotationDisplayFilter,
      );

      dispatch(
        updateSideBySideElementCacheByCallouts({
          elementIds: [elementId],
          showCallout: displayCallout,
          isLeftSideView: isLeftView,
        }),
      );
    }
  };

  useEffect(() => {
    if (annotationDisplayFilter.showAll) {
      const initialCalloutVisibilityDetails = {
        displayMarker: false,
        displayCallout: true,
        displayAnnotation: true,
      };

      const calloutVisibilityDetails = getCalloutVisibilityDetails(
        initialCalloutVisibilityDetails,
        annotation.annotationsConfig,
        annotationDisplayFilter,
      );

      setAnnotation({
        ...annotation,
        marker: calloutVisibilityDetails.displayMarker,
        display: calloutVisibilityDetails.displayAnnotation,
        callout: calloutVisibilityDetails.displayCallout,
        showAll: true,
      });
    } else {
      setAnnotation({
        ...annotation,
        display: false,
        marker: isPreviewPage ? annotation.marker : true,
        callout: isPreviewPage ? annotation.callout : false,
        showAll: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationDisplayFilter.showAll]);

  useEffect(() => {
    const initialCalloutVisibilityDetails = getInitialCalloutVisibilityDetails(
      annotation,
      annotationDisplayFilter,
    );
    const calloutVisibilityDetails = getCalloutVisibilityDetails(
      initialCalloutVisibilityDetails,
      annotation.annotationsConfig,
      annotationDisplayFilter,
    );

    setAnnotation({
      ...annotation,
      marker: isPreviewPage
        ? annotation.marker
        : calloutVisibilityDetails.displayMarker,
      display: calloutVisibilityDetails.displayAnnotation,
      callout: isPreviewPage
        ? annotation.callout
        : calloutVisibilityDetails.displayCallout,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    annotationDisplayFilter.workpaper,
    annotationDisplayFilter.tickmarks,
    annotationDisplayFilter.internalRef,
    annotationDisplayFilter.formula,
    annotationDisplayFilter.comfortLetter,
  ]);

  const { polygonHeight } = getElementBoundaryInfo(polygonInPixel);

  useEffect(() => {
    const canvasElement = document.getElementById(canvasID);
    const element = document.getElementById(elementId);
    const { clientHeight, clientWidth } = canvasElement ? canvasElement : {};
    const canvasWidth = clientWidth ? clientWidth : 0;
    const canvasHeight = clientHeight ? clientHeight : 0;
    const offsetWidth = element ? { [elementId]: element.offsetWidth } : {};
    const offsetHeight = element ? { [elementId]: element.offsetHeight } : {};
    const { position } = annotationPosition;
    const style = {
      marker: {
        ...getMarkerPosition({ position, polygonInPixel, canvasHeight }),
      },
      callout: {
        ...getCalloutPosition({
          position,
          polygonInPixel,
          canvasHeight,
          canvasWidth,
          offsetHeight,
          offsetWidth,
          elementId,
          isPreviewPage,
        }),
      },
    };

    setAnnotationStyle(style);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    annotation,
    annotationDisplayFilter,
    annotationPosition.position,
    polygonInPixel,
    elementDetails,
  ]);
  return (
    <>
      {annotation.marker && (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            ...annotationStyle.marker,
            width: 'fit-content',
            zIndex: 47,
            cursor: 'pointer',
          }}
          className={classNames({
            [`${BLOCK}__annotation-indicator--left`]:
              annotationPosition.position === 'left',
            [`${BLOCK}__annotation-indicator--right`]:
              annotationPosition.position === 'right',
            [`${BLOCK}__annotation-indicator--bottom`]:
              annotationPosition.position === 'bottom',
          })}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={() => {
            !isPreviewPage && handleIndicatorClick(elementId);
          }}
        >
          <AnnotationIndicator
            width={ANNOTATION_INDICATOR_WIDTH}
            height={ANNOTATION_INDICATOR_HEIGHT}
          />
        </div>
      )}
      {annotation.callout && (
        <div>
          <AnnotationCalloutComponent
            elementId={elementId}
            BLOCK={BLOCK}
            position={annotationPosition.position}
            maxWidth={`${width}px`}
            customStyle={annotationStyle.callout}
            onClick={() => {
              handleIndicatorClick(elementId);
            }}
            fontSize={
              elementDetails && elementDetails.display == '―'
                ? polygonHeight
                : polygonHeight - 3
            }
          >
            {children}
          </AnnotationCalloutComponent>
        </div>
      )}
    </>
  );
};

export default OcrCallouts;
