import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

export const CHAT_BOT_CLEAR_SESSION_BLOCK = 'chat-bot-clear-session-modal';
export const CHAT_BOT_CLEAR_SESSION_ID_BLOCK =
  'chat-bot-clear-session-modal-id';

export const ChatBotClearSessionAcknowledgeModal = ({ onClose, onSubmit }) => {
  return (
    <Modal
      id={`${CHAT_BOT_CLEAR_SESSION_ID_BLOCK}`}
      primaryModalButton={
        new ModalButton({
          text: 'common.submit',
          onClick: onSubmit,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
      onClose={onClose}
    >
      <div className={`${CHAT_BOT_CLEAR_SESSION_BLOCK}__tech-support-access`}>
        <FormattedMessage
          id={'chatbot-clearSession-acknowledgement-description'}
        />
      </div>
    </Modal>
  );
};

ChatBotClearSessionAcknowledgeModal.propTypes = {
  /** function fired to close the modal */
  onClose: PropTypes.func.isRequired,
  /** function fired to submit acknowledgement */
  onSubmit: PropTypes.func.isRequired,
};
