import { handleActions } from 'redux-actions';
import {
  createLeftSectionCacheTracker,
  addLeftSectionToCache,
} from 'store/actions/section-cache-actions';
import {
  clearStatementPageStoreAction,
  clearLeftStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import {
  updateLeftPurgedCacheTracker,
  updateLeftSectionViewedTimestamp,
} from 'store/actions/left-statement-section-content-actions';
import SectionCacheTracker from 'models/data/section-cache-tracker-model';

export default handleActions(
  {
    [createLeftSectionCacheTracker]: (tracker, { payload }) => {
      return tracker.init(payload);
    },
    [updateLeftSectionViewedTimestamp]: (
      tracker,
      { payload: { sectionId } },
    ) => {
      return tracker.setSectionTimestamp(sectionId);
    },
    [addLeftSectionToCache]: (tracker, { payload }) => {
      return tracker.initSection(payload);
    },
    [updateLeftPurgedCacheTracker]: (tracker, { payload }) => {
      return tracker.updateCache(payload);
    },
    [clearStatementPageStoreAction]: (tracker, { payload }) => {
      return new SectionCacheTracker();
    },
    [clearLeftStatementPageStoreAction]: (tracker, { payload }) => {
      return new SectionCacheTracker();
    },
  },
  new SectionCacheTracker(),
);
