import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CALLOUT_MAX_WIDTH, ELEMENT_BLOCK } from '../tieout-element-component';
import { ANNOTATION_DISPLAY_FUNCTIONS } from 'constants/feature/tieout-element-constants';
import { getElementHighlightStatus } from 'utils/ocr-annotation-utils';
import { setSelectedElementDetails } from 'store/actions/report-preview-page-actions';
import { useDispatch, useSelector } from 'react-redux';
import AnnotationCalloutComponent from '../tieout-element-common/annotation-callout-component';
import ElementDetails from 'models/api/element-details-api-model';
import Zoom from 'models/data/zoom-model';

const ANNOTATION_ICON_SIZE = '10px';
const PREVIEW_REPORT_SELECTEDABLE_ELEMENT_BLOCK_ID =
  'preview-report-selectedable-element-id';

const PREVIEW_REPORT_SELECTEDABLE_ELEMENT_BLOCK =
  'preview-report-selectedable-element';

const CALLOUT_POINTER_WIDTH_AND_MARGIN = 12;

const PreviewReportSelectedableElement = ({
  elementDetails,
  href,
  id,
  elementId,
  sectionId,
  children,
  zoom,
}) => {
  const elementRef = useRef(false);

  const elementSize =
    elementRef && elementRef.current
      ? parseFloat(window.getComputedStyle(elementRef.current).fontSize) - 3
      : 0;

  const {
    reportPreviewPage,
    previewPage: { shouldShowElementId, shouldShowStatusAndFlag },
  } = useSelector((store) => store.ui);

  const isElementSelected =
    reportPreviewPage.selectedElementId === parseInt(elementId);

  const [screenSize, setScreenSize] = useState(
    window.innerWidth * window.innerHeight,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenSize(window.innerWidth * window.innerHeight);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setScreenSize(window.innerWidth * window.innerHeight);
      });
    };
  }, []);

  const [calloutRightPosition, setCalloutRightPosition] = useState('0px');
  const [calloutTopPosition, setCalloutTopPosition] = useState('0px');

  const tieoutElement = document.getElementById(id);
  const tieoutElementBoundingInfo =
    tieoutElement && tieoutElement.getBoundingClientRect();
  const tieoutElementLeft =
    tieoutElementBoundingInfo && tieoutElementBoundingInfo.left;
  const tieoutElementTop =
    tieoutElementBoundingInfo && tieoutElementBoundingInfo.top;
  const tieoutElementHeight =
    tieoutElementBoundingInfo && tieoutElementBoundingInfo.height;

  useEffect(() => {
    const callOut = document.getElementById(
      `${PREVIEW_REPORT_SELECTEDABLE_ELEMENT_BLOCK_ID}__${id}`,
    );
    const calloutBoundingInfo =
      (callOut && callOut.getBoundingClientRect()) || {};
    const { height: calloutHeight, width: calloutWidth } = calloutBoundingInfo;
    const { left: parentLeftPosition } = document
      .getElementById('statement-content-panel-id')
      .getBoundingClientRect();
    const { top: parentTopPosition } = document
      .getElementById('statement-content-panel-zoom-id')
      .getBoundingClientRect();

    const elementCenter = tieoutElementHeight / 2;
    const calloutCenter = calloutHeight / 2;
    const calloutRight =
      tieoutElementLeft &&
      `${
        (tieoutElementLeft -
          parentLeftPosition -
          calloutWidth -
          CALLOUT_POINTER_WIDTH_AND_MARGIN) /
        zoom.zoomRatio
      }px`;
    const calloutTop = `${
      (tieoutElementTop - parentTopPosition - calloutCenter + elementCenter) /
      zoom.zoomRatio
    }px`;

    setCalloutTopPosition(calloutTop);
    setCalloutRightPosition((preVal) =>
      zoom.zoomRatio < 1 ? preVal : calloutRight,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elementDetails,
    zoom.zoomRatio,
    screenSize,
    tieoutElementLeft,
    tieoutElementTop,
    tieoutElementHeight,
    shouldShowElementId,
    shouldShowStatusAndFlag,
  ]);

  const { isReviewed, isVerified, isUnverified } = getElementHighlightStatus({
    elementDetails,
    isElementHighlighted: false,
    isPreviewPage: true,
    showElementStatusAndFlag: shouldShowStatusAndFlag,
  });

  const getElementStatusColor = () =>
    isReviewed
      ? `${ELEMENT_BLOCK}--reviewed`
      : isVerified
      ? `${ELEMENT_BLOCK}--verified`
      : isUnverified && `${ELEMENT_BLOCK}--unverified`;

  const navigateToElementRow = () => {
    dispatch(
      setSelectedElementDetails({
        selectedElementId: parseInt(elementId),
        isClickedOnReport: false,
      }),
    );
  };

  return (
    <>
      <a
        ref={elementRef}
        className={classNames(
          ELEMENT_BLOCK,
          isElementSelected &&
            `${PREVIEW_REPORT_SELECTEDABLE_ELEMENT_BLOCK}--selected`,
        )}
        id={id}
        onClick={navigateToElementRow}
        data-element-id={elementId}
        data-section-id={sectionId}
        href={href}
      >
        {shouldShowStatusAndFlag &&
          ANNOTATION_DISPLAY_FUNCTIONS.flagged({
            elementDetails,
            iconSize: ANNOTATION_ICON_SIZE,
            BLOCK: `${ELEMENT_BLOCK}--flag`,
          })}
        <span
          className={classNames(ELEMENT_BLOCK, {
            [getElementStatusColor()]: shouldShowStatusAndFlag,
          })}
        >
          {children}
        </span>
      </a>
      {shouldShowElementId &&
        elementDetails &&
        elementDetails.previewSequenceID && (
          <AnnotationCalloutComponent
            elementId={`${PREVIEW_REPORT_SELECTEDABLE_ELEMENT_BLOCK_ID}__${id}`}
            BLOCK={`${PREVIEW_REPORT_SELECTEDABLE_ELEMENT_BLOCK}__annotation-callout`}
            position={'left'}
            maxWidth={`${CALLOUT_MAX_WIDTH}px`}
            customStyle={{
              top: calloutTopPosition,
              left: calloutRightPosition,
            }}
            onClick={navigateToElementRow}
            fontSize={elementSize}
          >
            {elementDetails.previewSequenceID}
          </AnnotationCalloutComponent>
        )}
    </>
  );
};

export default PreviewReportSelectedableElement;

PreviewReportSelectedableElement.propTypes = {
  /**Element details for the element to be rendered */
  elementDetails: PropTypes.instanceOf(ElementDetails),
  /**ID - CFTO_ELEMENT_ID */
  id: PropTypes.string.isRequired,
  /**ID of element */
  elementId: PropTypes.string.isRequired,
  /**section id of section where element is rendered */
  sectionId: PropTypes.number.isRequired,
  /**element node to be rendered */
  children: PropTypes.node,
  /**zoom details of screen */
  zoom: PropTypes.instanceOf(Zoom).isRequired,
  /**flag to decide whether to show callout or not */
  shouldShowElementId: PropTypes.bool,
  /**flag to decide whether to show  color for element status and flag or not*/
  shouldShowStatusAndFlag: PropTypes.bool,
};
