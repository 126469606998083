import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export default class ChatBot extends ApiModel({
  data: {
    name: '',
  },
}) {
  processResponse(response) {
    return { data: response };
  }
}
