import { isNullOrUndefined } from 'utils/object-utils';
import { formatTieoutSelectorForApi } from 'constants/feature/notes-panel-constants';

export default class NoteSelection {
  revisionId;
  sectionId;
  revisionNumber;
  selectedText;
  selector;
  endSelector;
  offset;
  endOffset;
  length;
  hasElement;
  ocrSelector;
  isOCR;
  elementId;
  constructor(data) {
    if (!isNullOrUndefined(data)) {
      this.revisionId = data.revisionId;
      this.sectionId = data.sectionId;
      this.revisionNumber = data.revisionNumber;
      this.selectedText = data.selectedText;
      this.length = data.length;
      this.hasElement = data.hasElement;
      this.selector = data.selector;
      this.isOCR = data.isOCR;
      if (data.isOCR) {
        this.elementId = data.elementId;
        this.ocrSelector = {
          postText: data.postText,
          preText: data.preText,
          contextLabel: data.contextLabel,
          polygon: data.polygon,
        };
      } else {
        this.endSelector = data.endSelector;
        this.offset = data.offset;
        this.endOffset = data.endOffset;
      }
    }
  }

  hasSelection() {
    return !isNullOrUndefined(this.selectedText);
  }

  toApiFormat() {
    return this.isOCR
      ? this
      : {
          ...this,
          selector: formatTieoutSelectorForApi({ selector: this.selector }),
          endSelector: formatTieoutSelectorForApi({
            selector: this.endSelector,
          }),
        };
  }
}
