import React from 'react';
import TieoutElementOCR from 'components/feature/statement-content-panel/tieout-elements-component-ocr';
import { useSelector } from 'react-redux';
import { hoverCopyFormulaDisabledTooltip } from 'constants/feature/tieout-element-constants';

const StatementContentOverlayElementMap = ({
  clearSelection,
  element,
  polygonInPixel,
  showElementStatusAndFlag,
  annotationDisplayFilter,
  annotation,
  isLeftView,
}) => {
  const { data } = useSelector((store) => store);

  const {
    copyFormula: { tableMatrix },
  } = data;

  const isPartOfTableMatrix = () => {
    if (tableMatrix.length > 0) {
      return !!tableMatrix[element.id];
    } else {
      // Since Formula is not copied - tableMatrix size is zero
      return true;
    }
  };

  const tooltip = !isPartOfTableMatrix()
    ? hoverCopyFormulaDisabledTooltip
    : false;

  return (
    <TieoutElementOCR
      elementDetails={element}
      clearSelection={clearSelection}
      polygonInPixel={polygonInPixel}
      tooltip={tooltip}
      isDisabled={!isPartOfTableMatrix()}
      showElementStatusAndFlag={showElementStatusAndFlag}
      annotationDisplayFilter={annotationDisplayFilter}
      annotation={annotation}
      isLeftView={isLeftView}
    />
  );
};
export default StatementContentOverlayElementMap;
