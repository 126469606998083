import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  fetchAllOCRSectionRenderingData,
  fetchCurrentBlacklineDataBySectionOCR,
  fetchPreviousBlacklineDataBySectionOCR,
} from 'store/actions/statement-content-actions-ocr';
import { isElementReportPreviewPage } from 'utils/ocr-annotation-utils';
import StatementContextMenu from './statement-context-menu-ocr';
import HtmlOverlay from './html-overlay';
import ElementDetailsContainerOCR from './element-details-container-ocr';
import classNames from 'classnames';
import BlacklineViewChanges from './blackline-view-changes-ocr';
import NotesDetailsContainerOCR from './notes-details-container-ocr';
import StatementContentSearchComponentOCR from './statement-content-search-component-ocr';
import { OCR_CONTAINER_BLOCK } from 'constants/feature/statement-content-constants';
import {
  isModeForAddCursor,
  shouldElementBatchSelection,
} from 'utils/modes-utils';

const SectionContentOverlayOCR = ({
  BLOCK,
  shouldShow,
  elementList,
  setShouldShow,
  sectionMetaData,
  isSectionLoaded,
  box,
  ocrWords,
  overlayCanvas,
  contextMenuData,
  isDrawing,
  isContentPanelScrolling,
  isBatchModeSelected,
  annotationsList,
  scaleWithZoom,
  clearSelection,
  markerDisplayValue,
  calloutDisplayValue,
  showElementStatusAndFlag = true,
  isLeftView,
  blacklineViewMode,
  isSideBySideView,
  numberOfSourceElementSelected,
  numberOfTargetElementSelected,
  notesList,
  contentSearchResult,
  dpi,
  setSectionInView,
  clearSectionInView,
  blacklineCache,
  blacklineGuidDetails,
}) => {
  const canvasID = isLeftView
    ? `canvas-left-${sectionMetaData.sectionId}`
    : `canvas-${sectionMetaData.sectionId}`;
  const SEGMENT_LOADING_HEIGHT = 600; // px
  const dispatch = useDispatch();
  const url = useLocation().pathname;
  const isPreviewPage = isElementReportPreviewPage(url);
  const isApiCallMadeToRenderSection = useRef(false);

  const { ui } = useSelector((store) => store);
  const {
    statementPage: {
      modes: { selectMode },
    },
  } = ui;

  //Fetch the OCR section.
  useEffect(() => {
    const _shouldFetchSection =
      !isApiCallMadeToRenderSection.current &&
      shouldShow &&
      !isSectionLoaded &&
      !isContentPanelScrolling;
    if (_shouldFetchSection) {
      dispatch(
        fetchAllOCRSectionRenderingData({
          sectionMetaData,
          isLeftView,
        }),
      );
      isApiCallMadeToRenderSection.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSectionLoaded, shouldShow, isContentPanelScrolling]);

  useEffect(() => {
    !shouldShow && (isApiCallMadeToRenderSection.current = false);
  }, [shouldShow]);

  //Set sectionsInView using IO.
  useEffect(() => {
    const rootElementId = isLeftView
      ? `${OCR_CONTAINER_BLOCK}__document-id-left-view`
      : `${OCR_CONTAINER_BLOCK}__document-id`;
    const intersectionObserverConfig = {
      root: document.getElementById(rootElementId), //the element that we check for intersection with
      rootMargin: `${SEGMENT_LOADING_HEIGHT}px 0px`,
    };
    const abortController = new AbortController();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!shouldShow) {
            setSectionInView({ sectionId: sectionMetaData.sectionId });
            setShouldShow(true);
          }
        } else {
          if (shouldShow) {
            setShouldShow(false);
            clearSectionInView({ sectionId: sectionMetaData.sectionId });
          }
        }
      });
    }, intersectionObserverConfig);

    const currentOverlayCanvas = overlayCanvas.current;
    if (currentOverlayCanvas) observer.observe(currentOverlayCanvas);
    return () => {
      if (currentOverlayCanvas) {
        observer.unobserve(currentOverlayCanvas);
      }
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionMetaData.sectionId, shouldShow]);

  useEffect(() => {
    if (blacklineViewMode) {
      const rootElementId = isLeftView
        ? `${OCR_CONTAINER_BLOCK}__document-id-left-view`
        : `${OCR_CONTAINER_BLOCK}__document-id`;
      const intersectionObserverConfig = {
        root: document.getElementById(rootElementId), //the element that we check for intersection with
        rootMargin: `${SEGMENT_LOADING_HEIGHT}px 0px`,
      };
      const abortController = new AbortController();
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (isLeftView) {
              !!blacklineGuidDetails &&
                dispatch(
                  fetchPreviousBlacklineDataBySectionOCR({
                    sectionId: sectionMetaData.sectionId,
                    blacklineGuidDetails,
                  }),
                );
            } else {
              !!blacklineGuidDetails &&
                dispatch(
                  fetchCurrentBlacklineDataBySectionOCR({
                    sectionId: sectionMetaData.sectionId,
                    blacklineGuidDetails,
                  }),
                );
            }
          }
        });
      }, intersectionObserverConfig);

      const currentOverlayCanvas = overlayCanvas.current;
      if (currentOverlayCanvas) observer.observe(currentOverlayCanvas);
      return () => {
        if (currentOverlayCanvas) {
          observer.unobserve(currentOverlayCanvas);
        }
        abortController.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blacklineViewMode]);

  //set the canvas width and height
  useEffect(() => {
    const canvas = overlayCanvas.current;
    const context = canvas.getContext('2d');
    context.canvas.width = sectionMetaData.width * scaleWithZoom;
    context.canvas.height = sectionMetaData.height * scaleWithZoom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionMetaData.height, sectionMetaData.width, scaleWithZoom]);

  const rightStatementCursorClassName =
    numberOfSourceElementSelected > numberOfTargetElementSelected &&
    `${BLOCK}--right-statement-selection-cursor`;

  const shouldDisplayBatchBox = () => {
    if (!isSideBySideView) return false;

    if (isLeftView) return true;

    return numberOfSourceElementSelected > numberOfTargetElementSelected;
  };

  return (
    <>
      {blacklineViewMode && (
        <BlacklineViewChanges
          ocrWords={ocrWords}
          isSectionLoaded={isSectionLoaded}
          elementList={elementList}
          isLeftView={isLeftView}
          scaleWithZoom={scaleWithZoom}
          sectionId={sectionMetaData.sectionId}
          blacklineCache={blacklineCache}
          dpi={dpi}
        />
      )}
      <NotesDetailsContainerOCR
        notesList={notesList}
        sectionId={sectionMetaData.sectionId}
        scale={scaleWithZoom}
        dpi={dpi}
      />
      {contentSearchResult.isLoaded && (
        <StatementContentSearchComponentOCR
          contentSearchResult={contentSearchResult}
          scale={scaleWithZoom}
          BLOCK={BLOCK}
          overlayCanvas={overlayCanvas}
          markerDisplayValue={false}
          calloutDisplayValue={false}
          showElementStatusAndFlag={showElementStatusAndFlag}
          dpi={dpi}
          pageSectionId={sectionMetaData.sectionId}
        />
      )}
      <ElementDetailsContainerOCR
        elementList={elementList}
        scale={scaleWithZoom}
        clearSelection={clearSelection}
        isPreviewPage={isPreviewPage}
        BLOCK={BLOCK}
        overlayCanvas={overlayCanvas}
        markerDisplayValue={markerDisplayValue}
        calloutDisplayValue={calloutDisplayValue}
        showElementStatusAndFlag={showElementStatusAndFlag}
        sectionId={sectionMetaData.sectionId}
        isLeftView={isLeftView}
        canvasID={canvasID}
      />
      {(!!isBatchModeSelected ||
        shouldDisplayBatchBox() ||
        shouldElementBatchSelection(selectMode)) && <HtmlOverlay box={box} />}
      <canvas
        id={canvasID}
        contentEditable={true}
        ref={overlayCanvas}
        className={classNames(`${BLOCK}__overlay`, {
          [`${BLOCK}--left-statement-cursor`]:
            (isSideBySideView && isLeftView) ||
            (!isSideBySideView && isModeForAddCursor(selectMode)),
          [`${rightStatementCursorClassName}`]: isSideBySideView && !isLeftView,
          [`${BLOCK}--right-statement-cursor`]:
            isSideBySideView &&
            !isLeftView &&
            numberOfSourceElementSelected === numberOfTargetElementSelected,
        })}
      ></canvas>
      {!isBatchModeSelected &&
        contextMenuData.selectedText &&
        !isDrawing &&
        !isPreviewPage &&
        !blacklineViewMode && (
          <StatementContextMenu
            contextMenuData={contextMenuData}
            clearSelection={clearSelection}
            BLOCK={BLOCK}
            overlayCanvas={overlayCanvas}
          />
        )}
    </>
  );
};

export default SectionContentOverlayOCR;
