import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Checkbox from 'components/common/checkbox-component';
import shortid from 'shortid';

export const ADDITIONAL_CONSIDERATION_MODAL_BLOCK =
  'additional-consideration-modal';
export const ADDITIONAL_CONSIDERATION_MODAL_ID =
  'additional-consideration-modal-id';

export const AdditionalConsiderationModal = ({ onClose, onSubmit }) => {
  const [toggleAcknowledge, setToggleAcknowledge] = useState(false);
  return (
    <Modal
      id={`${ADDITIONAL_CONSIDERATION_MODAL_ID}`}
      className={`${ADDITIONAL_CONSIDERATION_MODAL_BLOCK}__modal`}
      title={'statement-creation-review-consideration.modal.header'}
      primaryModalButton={
        new ModalButton({
          text: 'common.create',
          onClick: onSubmit,
          disabled: !toggleAcknowledge,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
      onClose={onClose}
    >
      <div className={`${ADDITIONAL_CONSIDERATION_MODAL_BLOCK}__para-2`}>
        <FormattedMessage
          id="statement-creation-review-consideration-modal.body.consideration-list"
          values={{
            ol: (...chunks) => <ol>{chunks}</ol>,
            li: (...chunks) => (
              <li
                key={`${ADDITIONAL_CONSIDERATION_MODAL_ID}-${shortid.generate()}`}
              >
                {chunks}
              </li>
            ),
          }}
        />
      </div>
      <div
        className={`${ADDITIONAL_CONSIDERATION_MODAL_BLOCK}__checkbox-block`}
      >
        <Checkbox
          checked={toggleAcknowledge}
          checkboxClassName={`${ADDITIONAL_CONSIDERATION_MODAL_BLOCK}__checkbox`}
          checkboxInputClassName={`${ADDITIONAL_CONSIDERATION_MODAL_BLOCK}__checkbox-input`}
          onChange={() => setToggleAcknowledge(!toggleAcknowledge)}
          label={
            'statement-creation-review-consideration.modal.acknowledge-checkbox.label'
          }
          id={`${ADDITIONAL_CONSIDERATION_MODAL_ID}-disclaimer-checkbox`}
          width={'100%'}
          isValid={true}
        />
      </div>
    </Modal>
  );
};

AdditionalConsiderationModal.propTypes = {
  /** function fired to close the modal */
  onClose: PropTypes.func.isRequired,
  /**function fired to close the modal and initiate statement creation */
  onSubmit: PropTypes.func.isRequired,
};
