import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import {
  fetchAndUpdateLocaleMessage,
  fetchUserPreferredLanguageAndUpdateLocale,
} from 'store/actions/locale-actions';

import enMessages from 'i18n/en.json';
import frMessages from 'i18n/fr.json';
import {
  getKeyForLocale,
  getMessagesForLocale,
} from 'constants/common/feature-common-utils';

const mapStateToProps = ({ ui: { locale } }) => ({
  locale,
});

const mapDispatchToProps = {
  fetchAndUpdateLocaleMessage,
  fetchUserPreferredLanguageAndUpdateLocale,
};

export const messages = {
  'en-US': enMessages,
  fr: frMessages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    locale,
    children,
    fetchAndUpdateLocaleMessage,
    fetchUserPreferredLanguageAndUpdateLocale,
  }) => {
    const [updatedMessages, setUpdatedMessage] = useState(
      getMessagesForLocale(locale),
    );
    const { currentUser } = useSelector((state) => state.data);
    useEffect(() => {
      fetchAndUpdateLocaleMessage();
      // if messages exists for the new locale then update it
      if (getMessagesForLocale(locale))
        setUpdatedMessage(getMessagesForLocale(locale));

      const setMessageFromLocalStorage = (e) => {
        if (e.key === getKeyForLocale(locale)) {
          setUpdatedMessage(getMessagesForLocale(locale));
        }
      };
      window.addEventListener('storage', setMessageFromLocalStorage);
      return () =>
        window.removeEventListener('storage', setMessageFromLocalStorage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    useEffect(() => {
      currentUser &&
        currentUser.data &&
        currentUser.data.id &&
        fetchUserPreferredLanguageAndUpdateLocale();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser.data.id]);

    return (
      <IntlProvider
        localeKey={locale}
        locale={locale}
        messages={
          updatedMessages && updatedMessages.data
            ? updatedMessages.data
            : enMessages
        }
      >
        {children}
      </IntlProvider>
    );
  },
);
