import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactComponent as OpenIcon } from 'icons/open-non-priority.svg';
import { ReactComponent as ClosedIcon } from 'icons/closed-non-priority.svg';
import { ReactComponent as OpenPriorityIcon } from 'icons/open-priority.svg';
import { ReactComponent as GhostIcon } from 'icons/note-ghost.svg';
import Tooltip from 'components/common/tool-tip-component';
import { applyQuickFilter } from 'store/actions/notes-panel-actions';
import {
  FILTER_STATUS,
  FILTER_PRIORITY,
  FILTER_GHOST,
} from 'constants/feature/notes-panel-constants';

export const NOTES_SUMMARY = 'notes-summary';
const NOTES_SUMMARY_ID = 'notes-summary-id';

export const NoteSummary = ({ notesList, applyQuickFilter }) => {
  const getNoteCount = (notes) => {
    let countObject = {
      priorityNotes: 0,
      openNotes: 0,
      closedNotes: 0,
      ghostNotes: 0,
    };

    notes.forEach((note) => {
      const isNotePriority = note.notePriorityType === 'HIGH' ? true : false;
      const isGHostNote = note.ghost === 1 ? true : false;
      const isNoteClosed = !note.closedBy ? false : true;
      if (isNotePriority && !isGHostNote && !isNoteClosed) {
        countObject.priorityNotes++;
      } else if (!isNotePriority && !isGHostNote && !isNoteClosed) {
        countObject.openNotes++;
      } else if (!isGHostNote && isNoteClosed) {
        countObject.closedNotes++;
      } else if (isGHostNote) {
        countObject.ghostNotes++;
      }
    });

    return countObject;
  };

  const noteCount = useMemo(() => getNoteCount(notesList), [notesList]);

  return (
    <div className={NOTES_SUMMARY}>
      <Tooltip
        id={`${NOTES_SUMMARY_ID}__open-priority`}
        position="top"
        text="notes-panel.note-summary.open-priority"
      >
        <button
          className={`${NOTES_SUMMARY}__icon-button`}
          onClick={() => applyQuickFilter(true, FILTER_PRIORITY)}
        >
          <OpenPriorityIcon className={`${NOTES_SUMMARY}__note-icon`} />
          <p className={`${NOTES_SUMMARY}__count`}>
            ({noteCount.priorityNotes})
          </p>
        </button>
      </Tooltip>
      <Tooltip
        id={`${NOTES_SUMMARY_ID}__open-notes`}
        position="top"
        text="notes-panel.note-summary.open-notes"
      >
        <button
          className={`${NOTES_SUMMARY}__icon-button`}
          onClick={() => applyQuickFilter(true, FILTER_STATUS)}
        >
          <OpenIcon className={`${NOTES_SUMMARY}__note-icon`} />
          <p className={`${NOTES_SUMMARY}__count`}>({noteCount.openNotes})</p>
        </button>
      </Tooltip>
      <Tooltip
        id={`${NOTES_SUMMARY_ID}__closed-notes`}
        position="top"
        text="notes-panel.note-summary.closed-notes"
      >
        <button
          className={`${NOTES_SUMMARY}__icon-button`}
          onClick={() => applyQuickFilter(false, FILTER_STATUS)}
        >
          <ClosedIcon className={`${NOTES_SUMMARY}__note-icon`} />
          <p className={`${NOTES_SUMMARY}__count`}>({noteCount.closedNotes})</p>
        </button>
      </Tooltip>
      <div className={`${NOTES_SUMMARY}__divider`}>|</div>
      <Tooltip
        id={`${NOTES_SUMMARY_ID}__ghost-notes`}
        position="left"
        text="notes-panel.note-summary.ghost-notes"
      >
        <button
          className={`${NOTES_SUMMARY}__icon-button`}
          onClick={() => applyQuickFilter(true, FILTER_GHOST)}
        >
          <GhostIcon className={`${NOTES_SUMMARY}__note-icon`} />
          <p className={`${NOTES_SUMMARY}__count`}>({noteCount.ghostNotes})</p>
        </button>
      </Tooltip>
    </div>
  );
};

NoteSummary.propTypes = {
  /** list of all the notes */
  notesList: PropTypes.array.isRequired,
};

const mapStateToProps = ({
  data: {
    notesPanel: {
      notesList: {
        data: { notes },
      },
    },
  },
}) => ({
  notesList: notes,
});

const mapDispatchToProps = {
  applyQuickFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteSummary);
