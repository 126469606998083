import { createAction } from 'redux-actions';
import WorkpaperForm from 'models/form/workpaper-form-model';
import {
  updateElementCacheByCalloutAction,
  updateLeftElementCacheByCalloutAction,
} from './element-cache-actions';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

export const workpaper = createAction('WORKPAPER_CREATED');
export const tickmark = createAction('TICKMARK_CREATED');
export const formula = createAction('FORMULA_CREATED');
export const internalReference = createAction('INTERNAL_REFERENCE_CREATED');
export const comfortLetter = createAction('COMFORT_LETTER_CREATED');

export const workPaperElementIdsUpdatedOrDeleted =
  (workPaperToEditOrDeleted, isOCR) => async (dispatch, getStore) => {
    const {
      statementContent: { sectionIdList, elementCache },
    } = getStore().data;
    // optimisation work item required later. Need to get a list of all element IDs attached with the workpaper
    const workPaperToEditReferenceNumber = new WorkpaperForm().fromWorkpaper(
      workPaperToEditOrDeleted,
    ).referenceNumber;

    const workPaperUpdatedElementIds = [];
    sectionIdList.sectionIds.forEach((sectionItem) => {
      const sectionId = isOCR ? sectionItem.sectionId : sectionItem;
      const elementsData = elementCache.getSection(sectionId)
        ? elementCache.getSection(sectionId).elements
        : null;

      if (elementsData) {
        Object.keys(elementsData).forEach((elementId) => {
          const { workpaperReferenceNumberList, id } = elementsData[elementId];
          if (
            workpaperReferenceNumberList.includes(
              workPaperToEditReferenceNumber,
            )
          ) {
            workPaperUpdatedElementIds.push(id);
          }
        });
      }
    });
    dispatch(
      updateElementCacheByCalloutAction({
        elementIds: workPaperUpdatedElementIds,
        showCallout: true,
      }),
    );
  };

export const comfortLetterElementIdsUpdatedOrDeleted =
  (comfortLetterLabel, isOCR) => async (dispatch, getStore) => {
    const {
      statementContent: { sectionIdList, elementCache },
    } = getStore().data;

    // optimization work item required later. Need to get a list of all element IDs attached with the comfortLetter
    const comfortLetterUpdatedElementIds = [];
    sectionIdList.sectionIds.forEach((sectionItem) => {
      const sectionId = isOCR ? sectionItem.sectionId : sectionItem;
      const elementsData = elementCache.getSection(sectionId)
        ? elementCache.getSection(sectionId).elements
        : null;

      if (elementsData) {
        Object.keys(elementsData).forEach((elementId) => {
          const { comfortLetterLabelList, id } = elementsData[elementId];
          if (comfortLetterLabelList.includes(comfortLetterLabel)) {
            comfortLetterUpdatedElementIds.push(id);
          }
        });
      }
    });
    dispatch(
      updateElementCacheByCalloutAction({
        elementIds: comfortLetterUpdatedElementIds,
        showCallout: true,
      }),
    );
  };

const getElementsDataByLeftSideView = (elementsData) => {
  let elementIds = [];
  Object.keys(elementsData).forEach((section) => {
    Object.keys(elementsData[section]).forEach((item) => {
      if (elementsData[section][item])
        elementIds.push(elementsData[section][item].id);
    });
  });
  return elementIds;
};

export const disableAllElementsCallout =
({ showCallout }) =>
  async (dispatch, getStore) => {
    const {
      statementContent: { elementCache },
    } = getStore().data;

    const elementsData = elementCache.getAllSectionElements();

    let elementIds = [];
    elementIds = getElementsDataByLeftSideView(elementsData);

    if (elementIds.length) {
      dispatch(
        updateSideBySideElementCacheByCallouts({
          elementIds,
          showCallout: showCallout,
        }),
      );
      const isSideBySideView = window.location.pathname.includes(
        ROUTE_CONSTANTS.SIDE_BY_SIDE,
      );
      if (isSideBySideView) {
        const {
          leftStatementContent: { leftElementCache },
          leftSelectedStatement,
        } = getStore().data;

        const isleftSelectedStatement =
          leftSelectedStatement && leftSelectedStatement.isLoaded;

        if (isleftSelectedStatement) {
          const leftElementsData = leftElementCache.getAllSectionElements();

          let leftElementIds = getElementsDataByLeftSideView(leftElementsData);
          if (leftElementIds.length) {
            dispatch(
              updateSideBySideElementCacheByCallouts({
                elementIds: leftElementIds,
                showCallout: showCallout,
                isLeftSideView: true,
              }),
            );
          }
        }
      }
    }
  };

export const updateSideBySideElementCacheByCallouts =
  ({ elementIds, showCallout, isLeftSideView = false }) =>
  (dispatch) => {
    isLeftSideView
      ? dispatch(
          updateLeftElementCacheByCalloutAction({
            elementIds,
            showCallout: showCallout,
          }),
        )
      : dispatch(
          updateElementCacheByCalloutAction({
            elementIds,
            showCallout: showCallout,
          }),
        );
  };
