import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import ElementDetails from 'models/api/element-details-api-model';

export default class ElementsBySectionMap extends ApiModel({
  data: {
    elements: {
      /*
        {
          [elementId]: ElementDetails,
          ...
        }
      */
    },
    sectionId: null,
  },
}) {
  get elements() {
    if (this.data && this.data.elements) {
      return this.data.elements;
    }
    return {};
  }

  get sectionId() {
    if (this.data) {
      return this.data.sectionId;
    }
    return null;
  }

  processResponse({ response, sectionId }) {
    let _instantiatedMap = {};
    response.data.forEach((elementData) => {
      _instantiatedMap[elementData.id] =
        new ElementDetails().setLoadedFromElementData({
          data: elementData,
        });
    });
    return {
      data: {
        elements: _instantiatedMap,
        sectionId,
      },
    };
  }

  /** Updates this sections element cache based on the new element data passed
   * NOTE: the `element` arg should already be of type ElementDetail
   */
  updateElement({ element }) {
    if (this.has(element.id)) {
      return this.merge({
        data: {
          ...this.data,
          elements: {
            ...this.data.elements,
            [element.id]: element,
          },
        },
      });
    }
  }

  /**
   * This function is used to update specific elements in the current element by section map. It will update only the
   * elements passed in the `elementsToUpdate` array, while all other elements in the section remain the same.
   * @param {ElementDetails[]} elementsToUpdate
   */
  updateMultipleElements({ elementsToUpdate }) {
    let updatedMapOfElements = { ...this.data.elements };
    elementsToUpdate.forEach((elementToUpdate) => {
      updatedMapOfElements[elementToUpdate.id] = elementToUpdate;
    });
    return this.merge({
      data: {
        ...this.data,
        elements: updatedMapOfElements,
      },
    });
  }

  setElementLoaded({ response, elementId }) {
    const updatedElement = this.has(elementId)
      ? this.get(elementId).setLoaded({ response })
      : new ElementDetails().setLoaded({ response });

    return this.merge({
      data: {
        ...this.data,
        elements: {
          ...this.data.elements,
          [elementId]: updatedElement,
        },
      },
    });
  }

  get(id) {
    return this.elements[id];
  }

  has(id) {
    return !isNullOrUndefined(this.elements[id]);
  }
}
