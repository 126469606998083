import { connect } from 'react-redux';
import ProjectListComponent from 'components/feature/project-list/project-list-component';
import { push } from 'connected-react-router';
import {
  fetchProjectList,
  softDeleteProject,
  restoreDeletedProject,
  permanentlyDeleteProject,
  setProjectFilterGeo,
  setAppliedSearchTerm,
  clearAppliedSearchTerm,
  setFirstProjectAsSelectedFromProjectList,
} from 'store/actions/project-list-actions';
import { setSelectedProjectFromProjectList } from 'store/actions/selected-project-actions';
import {
  getPinnedDetails,
  updatePinDetails,
  updateUnpinDetails,
} from 'store/actions/pinned-details-actions';

const mapStateToProps = ({
  data: {
    projectList,
    pinnedDetails,
    selectedProject: { id },
    currentUser,
  },
}) => ({
  projectList,
  pinnedDetails,
  selectedProjectID: id,
  currentUser: currentUser.data,
});

const mapDispatchToProps = {
  fetchProjectList,
  setSelectedProjectFromProjectList,
  setFirstProjectAsSelectedFromProjectList,
  softDeleteProject,
  restoreDeletedProject,
  permanentlyDeleteProject,
  push,
  setProjectFilterGeo,
  setAppliedSearchTerm,
  clearAppliedSearchTerm,
  getPinnedDetails,
  updatePinDetails,
  updateUnpinDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectListComponent);
