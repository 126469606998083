import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import {
  getElementBoundaryInfo,
  isPolygonExistInElementList,
} from 'utils/ocr-element-utils';
import { isNullOrUndefined } from 'utils/object-utils';

const BLACKLINE_VIEW_CHANGES_BLOCK = 'blackline_view_changes';
const BLACKLINE_VIEW_CHANGES_BLOCK_ID = 'blackline_view_changes_id';
const BLACKLINE_VIEW_CHANGE_TYPE = {
  ADDED: 'Added',
  CHANGED: 'Changed',
  DELETED: 'Deleted',
};

const BlacklineViewChanges = ({
  isSectionLoaded,
  elementList,
  isLeftView,
  scaleWithZoom,
  blacklineCache = {},
  dpi,
}) => {
  const { ui } = useSelector((store) => store);
  const {
    modes: { blacklineViewShowElementMode },
    blacklineHighlightedElementId,
  } = ui.statementPage;

  const { blacklineDetails = {} } = blacklineCache;
  const { blacklineChanges = [] } = blacklineDetails;
  const { addedOrChanged } = blacklineHighlightedElementId;

  if (blacklineCache.isLoaded && isSectionLoaded) {
    const blacklineDataToHTMLMap = blacklineChanges.map(({ polygon, type }) => {
      const blacklinePolygon = JSON.parse(polygon);
      const polygonInPixel = blacklinePolygon.map((coord) => {
        return parseFloat(coord) * dpi * scaleWithZoom;
      });
      const { isElement, elementId } = isPolygonExistInElementList(
        elementList,
        polygonInPixel,
        scaleWithZoom,
      );
      const { polygonWidth, polygonHeight, polygonLeft, polygonTop } =
        getElementBoundaryInfo(polygonInPixel);
      const styles = {
        position: 'absolute',
        zIndex: blacklineViewShowElementMode ? 40 : 50,
        top: polygonTop,
        left: polygonLeft,
        width: polygonWidth,
        height: polygonHeight,
      };

      let isElementHighlighted = false;
      if(blacklineViewShowElementMode) {
        isElementHighlighted = false;
      } else {
        if(addedOrChanged && isElement) {
          isElementHighlighted = elementId === addedOrChanged && !isLeftView;
        }
      }


      return (
        <div
          id={
            isElement
              ? `${BLACKLINE_VIEW_CHANGES_BLOCK_ID}_${elementId}`
              : BLACKLINE_VIEW_CHANGES_BLOCK_ID
          }
          style={styles}
          className={classnames(`${BLACKLINE_VIEW_CHANGES_BLOCK}`, {
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--selected--blackline-view`]:
              isElementHighlighted,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--added`]:
              blacklineViewShowElementMode
                ? !isLeftView &&
                  type === BLACKLINE_VIEW_CHANGE_TYPE.ADDED &&
                  !isElement
                : !isLeftView && type === BLACKLINE_VIEW_CHANGE_TYPE.ADDED,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--changed`]:
              blacklineViewShowElementMode
                ? !isLeftView &&
                  type === BLACKLINE_VIEW_CHANGE_TYPE.CHANGED &&
                  !isElement
                : !isLeftView && type === BLACKLINE_VIEW_CHANGE_TYPE.CHANGED,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--deleted`]:
              blacklineViewShowElementMode
                ? isLeftView &&
                  type === BLACKLINE_VIEW_CHANGE_TYPE.DELETED &&
                  !isElement
                : isLeftView && type === BLACKLINE_VIEW_CHANGE_TYPE.DELETED,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--added-border`]:
              !isLeftView &&
              type === BLACKLINE_VIEW_CHANGE_TYPE.ADDED &&
              blacklineViewShowElementMode &&
              isElement,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--changed-border`]:
              !isLeftView &&
              type === BLACKLINE_VIEW_CHANGE_TYPE.CHANGED &&
              blacklineViewShowElementMode &&
              isElement,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--deleted-border`]:
              isLeftView &&
              type === BLACKLINE_VIEW_CHANGE_TYPE.DELETED &&
              blacklineViewShowElementMode &&
              isElement,
          })}
          onMouseEnter={(e) => {
            isElement &&
              e.target.classList.add(`${BLACKLINE_VIEW_CHANGES_BLOCK}--hover`);
          }}
          onMouseLeave={(e) => {
            isElement &&
              e.target.classList.remove(
                `${BLACKLINE_VIEW_CHANGES_BLOCK}--hover`,
              );
          }}
        ></div>
      );
    });

    return <>{blacklineDataToHTMLMap}</>;
  }
  return null;
};

export default BlacklineViewChanges;
