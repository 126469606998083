import { handleActions } from 'redux-actions';
import ReportPreviewPage from 'models/data/report-preview-page-model';

import {
  setSelectedElementDetails,
  clearReportPreviewPageData,
} from 'store/actions/report-preview-page-actions';

export default handleActions(
  {
    [setSelectedElementDetails]: (reportPreviewPage, { payload }) => {
      return reportPreviewPage.setSelectedElementDetails(payload);
    },
    [clearReportPreviewPageData]: () => {
      return new ReportPreviewPage();
    },
  },
  new ReportPreviewPage(),
);
