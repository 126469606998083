export const NOTIFICATION_EVENT_TYPES = {
  ELEMENT_UPDATE: 'Element Update',
  ELEMENT_ADD: 'Element Add',
  ELEMENT_EXCLUDE: 'Element Exclude',
  ELEMENT_SUMMARY: 'Element Summary',
  HEADING_SUMMARY: 'Heading Summary',
  FORMULA_DELETED: 'Formula Deleted',
  FORMULA_SAVED: 'Formula Saved',
  INTERNAL_REFERENCE_UPDATE: 'Internal Reference Update',
  INTERNAL_REFERENCE_SUGGESTION_UPDATE: 'Internal Reference Suggestion Update',
  WORKPAPER_LINKED: 'Workpaper Linked',
  WORKPAPER_UNLINKED: 'Workpaper Unlinked',
  NOTE_DELETE: 'Note Delete',
  NOTE_ADD: 'Note Add',
  NOTE_UPDATE: 'Note Update',
  WORKPAPER_UPDATE: 'Work Paper Reference Update',
  WORKPAPER_DELETED: 'Work Paper Reference Deleted',
  USER_JOINED_REVISION_GROUP: 'User Joined Revision Group',
  USER_LEFT_REVISION_GROUP: 'User Left Revision Group',
  HEADING_UPDATE: 'Heading Update',
  HEADING_REVIEW: 'Heading Review',
  HEADING_UNREVIEW: 'Heading Unreview',
  TICKMARK_UPDATE: 'Tickmark Update',
  TICKMARK_DELETE: 'Tickmark Delete',
  ADD_SECTION_HEADING: 'Heading Assignment Update',
  HEADING_DELETE: 'Heading Delete',
  TICKMARK_LINKED: 'Tickmark Linked',
  TICKMARK_UNLINKED: 'Tickmark Unlinked',
  WORKFLOW_STATUS: 'Workflow Status',
  TOOLKIT_EXPORT_REPORT_STATUS: 'Report Status',
  BULK_SECTION_ASSIGN: 'Bulk Section Assignment',
  BULK_SECTION_UNASSIGN: 'Bulk Section Unassignment',
  BLACK_LINE_VIEW_GENERATED: 'Blackline view generated',
  STATEMENT_COPY_SUCCESSFUL: 'Statement Copy Successfull',
  STATEMENT_COPY_FAILED: 'Statement Copy Failure',
  BULK_SECTION_REVIEW: 'Bulk Section Review',
  BULK_SECTION_UNREVIEW: 'Bulk Section Unreview',
  FORMULA_TYPE_CHANGED: 'Formula Type Updated',
  // USER_DISCONNECTED: 'User Disconnected',
};
