import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

const getUpdateNoteApi = ({ revisionId, noteModel }) =>
  `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${
    noteModel.sectionId
  }/notes/${noteModel.noteId}`;

export const createNotesRequest = ({ revisionId, notesForm, selection }) =>
  withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${
        selection.sectionId
      }/notes`,
      { ...notesForm.toApiFormat(), ...selection.toApiFormat() },
    )
    .then((response) => {
      return response;
    });

export const updateNoteRequest = ({ revisionId, noteModel }) =>
  withDefaultContextAsSelectedProject().put(
    getUpdateNoteApi({ revisionId, noteModel }),
    noteModel.toApiFormat(),
  );

export const updateNoteReplyRequest = ({ revisionId, noteModel }) =>
  withDefaultContextAsSelectedProject().put(
    getUpdateNoteApi({ revisionId, noteModel }),
    noteModel.toNoteReplyApiFormat(),
  );

export const EditNoteRequest = ({ revisionId, noteModel }) =>
  withDefaultContextAsSelectedProject().put(
    getUpdateNoteApi({ revisionId, noteModel }),
    noteModel.toEditNoteApiFormat(),
  );

export const getNoteByRevisionAndNoteIdRequest = ({ revisionId, noteId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/notes/${noteId}`,
  );
};

export const getNoteByRevisionAndPseudoElementIdRequest = ({
  revisionId,
  pseudoElementId,
}) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/pseudoelements/${pseudoElementId}/notes`,
  );
};

export const getNoteByRevisionAndElementIdRequest = ({
  revisionId,
  elementId,
}) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/notes`,
  );

export const getNotesByRevisionRequest = ({ revisionId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/notes`,
  );
};

export const getNotesByRevisionAndSectionRequest = ({
  statementId,
  revisionId,
  sectionId,
  contextKey,
  contextValue,
}) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue }).get(
    `/tieout/statements/${getStatementIdUtil(
      statementId,
    )}/revisions/${revisionId}/sections/${sectionId}/notes`,
  );

export const getNotesOCR = ({ statementId, revisionId, sectionId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil(
      statementId,
    )}/revisions/${revisionId}/sections/${sectionId}/notes`,
  );

export const deleteNoteRequest = ({ revisionId, note }) =>
  withDefaultContextAsSelectedProject().delete(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${
      note.sectionId
    }/notes/${note.noteId}`,
    { data: note.toDeleteApiFormat() },
  );

export const sendEmailToTaggedUsersRequest = ({
  revisionId,
  emailRequestBody,
}) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/email`,
    emailRequestBody,
  );
};
