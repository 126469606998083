import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AttachWorkpaper from '../element-panel/workpaper-panel/_workpaper-panel-attach-component';
import WorkpaperEntry from 'components/feature/element-panel/annotations/workpaper-entry/workpaper-entry-component';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as RemoveIcon } from 'icons/cross.svg';
import { WORKPAPER_ENTRY_REMOVE_TOOLTIP } from 'constants/feature/batch-panel-constants';
import BatchElementUpdateForm from 'models/form/batch-element-update-form-model';
import ElementDetails from 'models/api/element-details-api-model';
import { createWorkpaperRequest } from 'api/workpaper-api';
import CreateWorkpaperModal from 'components/feature/element-panel/workpaper-panel/create-work-paper-modal';
import Revision from 'models/api/revision-api-model';

const BATCH_WORKPAPER_BLOCK_ID = 'batch-workpaper-section-id';
const BATCH_WORKPAPER_BLOCK = 'batch-workpaper-section';
const REMOVE_ICON_SIZE = '12px';
export const BatchPanelWorkpapers = ({
  updates,
  setUpdates,
  revision,
  fetchWorkpaperListForRevision,
}) => {
  const [showCreateWorkpaperModal, setShowCreateWorkpaperModal] =
    useState(false);

  const _toggleCreateWorkpaperModal = useCallback(() => {
    setShowCreateWorkpaperModal(!showCreateWorkpaperModal);
  }, [showCreateWorkpaperModal]);

  const _onCreateWorkpaper = useCallback(
    ({ workpaperModel }) =>
      createWorkpaperRequest({ workpaperModel }).then(async (response) => {
        _toggleCreateWorkpaperModal();
        setUpdates((u) => u.attachWpr(response.data.result));
      }),
    [_toggleCreateWorkpaperModal, setUpdates],
  );

  return (
    <div className={BATCH_WORKPAPER_BLOCK}>
      <AttachWorkpaper
        onCreate={_toggleCreateWorkpaperModal}
        onSelect={({ workpaper }) => setUpdates((u) => u.attachWpr(workpaper))}
        alreadyAddedWorkpaperIds={updates.workpaperRefs.map(
          (wpr) => wpr.workpaperRefId,
        )}
        hideTitle
      />
      <div className={`${BATCH_WORKPAPER_BLOCK}__entries`}>
        {updates.workpaperRefs.map((workpaper) => (
          <WorkpaperEntry
            key={workpaper.referenceNumber}
            workpaper={workpaper}
            rightComponent={
              <IconButton
                id={`${BATCH_WORKPAPER_BLOCK_ID}-remove-wpr-${workpaper.referenceNumber}`}
                className={`${BATCH_WORKPAPER_BLOCK}__remove`}
                tooltip={WORKPAPER_ENTRY_REMOVE_TOOLTIP}
                onClick={() => setUpdates((u) => u.removeWpr(workpaper))}
                Icon={RemoveIcon}
                iconSize={REMOVE_ICON_SIZE}
              />
            }
          />
        ))}
      </div>
      {showCreateWorkpaperModal && (
        <CreateWorkpaperModal
          onClose={_toggleCreateWorkpaperModal}
          onCreate={_onCreateWorkpaper}
          elementDetails={
            new ElementDetails({ data: { revisionId: revision.id } })
          }
        />
      )}
    </div>
  );
};

BatchPanelWorkpapers.propTypes = {
  /** Current batch updates model */
  updates: PropTypes.instanceOf(BatchElementUpdateForm).isRequired,
  /** Function for updating current batch updates model */
  setUpdates: PropTypes.func.isRequired,
  /** Currently selected revision */
  revision: PropTypes.instanceOf(Revision).isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export default connect(mapStateToProps)(BatchPanelWorkpapers);
