import ApiModel from 'models/api-model';

export default class OCRNavigationModel extends ApiModel({
  data: {
    navigationData: [],
  },
}) {
  get navigationData() {
    return this.data.navigationData;
  }

  processResponse(response) {
    return {
      data: {
        navigationData: response.data,
      },
    };
  }
}
