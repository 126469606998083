import { useEffect } from 'react';

function useAutoFocus(ref, focusOnMount) {
  useEffect(() => {
    if (ref && ref.current && focusOnMount) {
      ref.current.focus();
    }
  }, [focusOnMount]);
}

export default useAutoFocus;
