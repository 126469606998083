import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

export const RIGHT_PANELS = {
  CLOSED: 'CLOSED',
  BATCH: 'BATCH',
  ELEMENT: 'ELEMENT', // controls sub panels like element-workpaper, element-tickmark, element-formula, element-ir
  NOTES: 'NOTES',
  SECTION: 'SECTION',
  EXPORT: 'EXPORT',
  WORKPAPER: 'WORKPAPER',
};

export const RIGHT_PANELS_ARRAY = Object.values(RIGHT_PANELS);

export const LEFT_PANELS = {
  CLOSED: 'CLOSED',
  STATEMENT_NAV: 'BATCH',
  TICKMARK: 'ELEMENT', // controls sub panels like element-workpaper, element-tickmark, element-formula, element-ir,
  COMPARE: 'COMPARE',
  EXPORT: 'EXPORT',
  WORKPAPER: 'WORKPAPER',
  CUSTOM: 'CUSTOM',
  AUDIT_CHECKLIST: 'AUDIT_CHECKLIST',
  DOWNLOAD: 'DOWNLOAD',
  COMFORT_ANNOTATION: 'COMFORT_ANNOTATION',
  SIDE_BY_SIDE_STATEMENT_COMPARISON: 'SIDE_BY_SIDE_STATEMENT_COMPARISON',
  CHAT_BOT: 'CHAT_BOT',
};

export const LEFT_PANELS_ARRAY = Object.values(LEFT_PANELS);

export const COMFORT_ANNOTATION_INFORMATION_TOOLTIP = new TooltipOptions({
  text: 'comfort-annotation-panel.information',
  id: 'comfort-annotation-panel-tooltip-information',
  position: 'right',
});

export const AUDIT_CHECKLIST_INFORMATION_TOOLTIP = new TooltipOptions({
  text: 'audit-checklist-panel.information',
  id: 'audit-checklist-panel-tooltip-extra-info',
  position: 'right',
});

export const AUDIT_CHECKLIST_STATUS_INFORMATION_TOOLTIP = new TooltipOptions({
  text: 'audit-checklist-panel-non-coe.information',
  id: 'audit-checklist-panel-tooltip-complete',
  position: 'top',
});

export const AUDIT_CHECKLIST_EDIT_INFORMATION_TOOLTIP = new TooltipOptions({
  text: 'audit-checklist-panel-non-coe.information',
  id: 'audit-checklist-panel-tooltip-edit',
  position: 'top',
});
