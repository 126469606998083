import { TIE_OUT_CHECK_BOXES } from 'constants/feature/toolkit-export-custom-report-default-checkbox-state-constants';
import React from 'react';
import { connect } from 'react-redux';

export const RenderCustomReportCheckbox = ({
  children,
  checkboxName,
  isOCR,
}) => {
  const _shouldRenderCheckbox = (checkboxName) => {
    switch (checkboxName) {
      case TIE_OUT_CHECK_BOXES.REVIEW_STATUS_HEADINGS.name:
      case 'BLACKLINE_CHECK_BOXES':
        return !isOCR;
      default:
        return true;
    }
  };
  return _shouldRenderCheckbox(checkboxName) ? <>{children}</> : <></>;
};

const mapStateToProps = ({
  data: {
    selectedStatement: { isOCR },
  },
}) => ({
  isOCR,
});
export default connect(mapStateToProps, null)(RenderCustomReportCheckbox);
