import { isNullOrUndefined } from 'utils/object-utils';
export class MenuOption {
  /** Unique id for option */
  id;
  /** text or func(that returns text) to display for option */
  title;
  /** value to be passed when selection is made */
  value;
  /** boolean or func that results in boolean for whether option should be disabled
   */
  disabled;
  /** TooltipOptionsModel or func that results in TooltipOptionsModel, indicates contents of and if a tooltip should be displayed */
  tooltip;
  /** indicates if this option has an internationalized id for it's title prop */
  isIntl = true;
  /** boolean or func that results in boolean for whether option should be shown,
   * ONLY USED FOR DATA GRID DROPDOWNS/KEBABS where row data decides what should be filtered
   * To only show options in certain instances instead, pass the data necessary to determine
   * whether options should be shown to a function that evaluates this data and returns the
   * appropriate options for a given scenario.
   */
  shouldShowOption = true;
  /** boolean value represents whether the menu option to be represents as label rather than usual dropdown option  */
  isLabel = false;
  /** boolean value indicates if this option has an customized internationalized perameter for it's title property */
  isNotIntl = false;
  /** display icon for icon dropdown component */
  icon = '';

  constructor({
    id,
    title,
    value,
    disabled,
    tooltip,
    shouldShowOption,
    isIntl,
    isLabel,
    isNotIntl,
    icon,
  }) {
    this.id = id;
    this.title = title;
    this.value = value;
    this.disabled = disabled;
    this.tooltip = tooltip;
    this.isLabel = isLabel;
    this.icon = icon;
    if (!isNullOrUndefined(shouldShowOption)) {
      this.shouldShowOption = shouldShowOption;
    }
    if (!isNullOrUndefined(isIntl)) {
      this.isIntl = isIntl;
    }
    if (!isNullOrUndefined(isNotIntl)) {
      this.isNotIntl = isNotIntl;
    }
  }
}

export class KebabMenuOption extends MenuOption {
  /** function to fire for this selection */
  onSelectOption;

  constructor({
    id,
    title,
    onSelectOption,
    disabled,
    tooltip,
    shouldShowOption,
  }) {
    super({ id, title, disabled, tooltip, shouldShowOption });
    this.onSelectOption = onSelectOption;
  }
}
