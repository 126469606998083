import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataGrid, {
  DataGridData,
} from 'components/common/data-grid/data-grid-component';
import Dropdown from 'components/common/dropdown-component';
import {
  getContainerMonitoringDropDownList,
  setMonitoringDropDownSelectedOptions,
} from 'store/actions/admin-module-actions';
import { getMonitoringColumns } from 'constants/feature/admin-module-constants';
import ConditionalRender from 'components/util/conditional-render-component';
import { FormattedMessage } from 'react-intl';
import ContainerForm from 'models/form/container-form-model';
import MonitoringForm from 'models/form/monitoring-form-model';
import { isNullOrUndefined } from 'utils/object-utils';

export const MONITORING_BLOCK = 'monitoring-list';
export const ADMIN_MODULE_MONITORING_BLOCK = 'monitoring';

const Monitoring = ({
  countryManagerContainerList,
  monitoringList,
  setMonitoringDropDownSelectedOptions,
  getContainerMonitoringDropDownList,
}) => {
  const [monitoringCountrySelected, setMonitoringCountrySelected] = useState(
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCountryOption = (option) => {
    const index = monitoringCountrySelected.findIndex(
      (item) => item.value === option.value,
    );
    const newData =
      index >= 0
        ? [
            ...monitoringCountrySelected.slice(0, index),
            ...monitoringCountrySelected.slice(index + 1),
          ]
        : [...monitoringCountrySelected, option];

    setMonitoringCountrySelected(newData);
    return newData;
  };

  const getContainerCodes = (monitoringOptionsList) => {
    if (!isNullOrUndefined(monitoringOptionsList)) {
      return monitoringOptionsList.map(({ value }) => value);
    }
    return '';
  };

  useEffect(() => {
    if (
      countryManagerContainerList.containersOptions.length > 0 &&
      countryManagerContainerList.containersOptions[0].value
    ) {
      setMonitoringDropDownSelectedOptions(
        setCountryOption(countryManagerContainerList.containersOptions[0]),
      );
      getContainerMonitoringDropDownList(
        countryManagerContainerList.containersOptions[0].value,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryManagerContainerList.containersOptions.length]);

  const onSelectOption = (option) => {
    let monitoringOptionsList = setCountryOption(option);
    setMonitoringDropDownSelectedOptions(monitoringOptionsList);
    getContainerMonitoringDropDownList(
      getContainerCodes(monitoringOptionsList).join(','),
    );
  };

  return (
    <>
      <div className={`${ADMIN_MODULE_MONITORING_BLOCK}__header`}>
        <FormattedMessage id="monitoring.header" />
      </div>

      <div className={`${ADMIN_MODULE_MONITORING_BLOCK}__drop-down`}>
        <span className={`${ADMIN_MODULE_MONITORING_BLOCK}__drop-down-label`}>
          <FormattedMessage id="select.country" />
        </span>
        <Dropdown
          id={`${MONITORING_BLOCK}-country-dropdown-id`}
          placeholder={'monitoring-drop-down-select-country-label'}
          width={'25%'}
          onSelectOption={onSelectOption}
          isValid={true}
          options={Array.from(countryManagerContainerList.containersOptions)}
          value={monitoringList.monitoringOptionsSelected}
          isMultiselect={true}
          isMultiselectDisplayValue={
            'admin-module.monitor.data-dropdown.countries-selected.label'
          }
        />
      </div>
      <ConditionalRender dependencies={[monitoringList]}>
        <DataGrid
          columns={getMonitoringColumns()}
          className={`${ADMIN_MODULE_MONITORING_BLOCK}__added-countries`}
          tableId={`${ADMIN_MODULE_MONITORING_BLOCK}-added-countries`}
          dataModel={
            new DataGridData({
              rowItems: [
                ...monitoringList.totalStatementsInEachStatus,
                ...monitoringList.data.monitoringList,
              ],
            })
          }
          numberOfVisibleRows={
            monitoringList.data.monitoringList.length + 1 || 1
          }
        />
      </ConditionalRender>
    </>
  );
};

const mapStateToProps = ({
  data: { countryManagerContainerList, monitoringList },
}) => ({
  countryManagerContainerList,
  monitoringList,
});

const mapDispatchToProps = {
  getContainerMonitoringDropDownList,
  setMonitoringDropDownSelectedOptions,
};

Monitoring.propTypes = {
  countryManagerContainerList: PropTypes.instanceOf(ContainerForm).isRequired,
  monitoringList: PropTypes.instanceOf(MonitoringForm).isRequired,
  /**function to get list of countries for the dropDown Menu */
  getContainerMonitoringDropDownList: PropTypes.func.isRequired,
  setMonitoringDropDownSelectedOptions: PropTypes.func.isRequired,
  setSortedColumn: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
