import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StatementReadOnlyBanners from 'pages/statement-read-only-banner';
import SectionContentOCR from './section-content-component-ocr';
import ConditionalRender from 'components/util/conditional-render-component';
import { SectionIdList } from 'models/api/section-id-list-model';
import { SectionCache } from 'models/api/sections-cache-model';
import { LeftSectionCache } from 'models/api/left-sections-cache-model';
import { SectionCacheOCR } from 'models/api/sections-cache-model-ocr-data';
import { LeftSectionCacheOCR } from 'models/api/left-sections-cache-model-ocr-data';
import ElementCache from 'models/data/element-cache-model';
import { BlacklineGuidList } from 'models/api/blackline-guid-list-api-model';
import BlacklineCache from 'models/data/blackline-cache-model';
import NoteCache from 'models/data/note-cache-model';
import Note from 'models/data/note-model';
import { OCR_CONTAINER_BLOCK } from 'constants/feature/statement-content-constants';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

const SCROLL_TIMEOUT = 150;
const SUFFIX = '-';
const BLOCK = OCR_CONTAINER_BLOCK;
let scrollingTimeout = null;
let leftScrollingTimeout = null;
export const BLOCK_ID = `statement-content-panel-page${SUFFIX}`;
export const LEFT_BLOCK_ID = `statement-content-panel-page-left${SUFFIX}`;

const StatementContentPanelOCR = (props) => {
  const statementRef = useRef(null);
  const leftStatementRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isContentPanelScrolling, setContentPanelScrolling] = useState(false);
  const [isLeftContentPanelScrolling, setLeftContentPanelScrolling] =
    useState(false);
  const [clickedSectionId, setClickedSectionId] = useState(null);
  const { zoom, leftZoom, rightZoom, isLeftView, URLString } = props;
  const _centerElementBeforeZoom = useRef(null);

  const _isSideBySideView = URLString.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE);
  const isPreviewPage = URLString.includes(ROUTE_CONSTANTS.REPORT_PREVIEW);

  const zoomValue = _isSideBySideView
    ? isLeftView
      ? leftZoom
      : rightZoom
    : zoom;

  useEffect(() => {
    const { setOcrCurrentViewPageNumberDispatch } = props;
    setOcrCurrentViewPageNumberDispatch(currentPage, isLeftView);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    statementRef &&
      statementRef.current &&
      statementRef.current.addEventListener('scroll', () => {
        if (!isContentPanelScrolling) {
          setContentPanelScrolling(true);
        }
        window.clearTimeout(scrollingTimeout);
        scrollingTimeout = setTimeout(() => {
          setContentPanelScrolling(false);
        }, SCROLL_TIMEOUT);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    leftStatementRef &&
      leftStatementRef.current &&
      leftStatementRef.current.addEventListener('scroll', () => {
        if (!isLeftContentPanelScrolling) {
          setLeftContentPanelScrolling(true);
        }
        window.clearTimeout(leftScrollingTimeout);
        leftScrollingTimeout = setTimeout(() => {
          setLeftContentPanelScrolling(false);
        }, SCROLL_TIMEOUT);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    //set first element in viewport in ocr only for report preview page
    useEffect(() => {
      if(isPreviewPage && props.isSyncScrollingEnabled) {
        props.setFirstElementVisibleInViewPortAction(true);
      }
    }, [isContentPanelScrolling, props.elementCache]);

  const onScrollPage = (event) => {
    const { scrollTop, clientHeight, children } = event.target;
    const statementViewerCenter = scrollTop + clientHeight / 2;
    const childrens = Array.from(children);
    const visibleChild = childrens.find(
      (c) => c.offsetTop + c.offsetHeight >= statementViewerCenter,
    );
    if (visibleChild && visibleChild.id) {
      const newPage = isLeftView
        ? visibleChild.id.replace(LEFT_BLOCK_ID, '')
        : visibleChild.id.replace(BLOCK_ID, '');
      if (newPage !== currentPage) setCurrentPage(newPage);
    }
  };


  const {
    sectionIdList,
    leftSectionIdList,
    cache,
    leftCache,
    ocrCache,
    leftOcrCache,
    elementCache,
    leftElementCache,
    blacklineCache,
    leftBlacklineCache,
    setLeftSectionInView,
    clearLeftSectionInView,
    setSectionInView,
    clearSectionInView,
    blacklineSourceGuidList,
    blacklineTargetGuidList,
    notesCache,
    leftNotesCache,
    selectedStatement,
    isBatchModeSelected,
    urlParams,
    workflowsMap,
    blacklineViewMode,
  } = props;
  const sectionIdListData = isLeftView ? leftSectionIdList : sectionIdList;
  return (
    <div className={classNames(BLOCK)}>
      <div
        id={
          isLeftView
            ? `${BLOCK}__document-id-left-view`
            : `${BLOCK}__document-id`
        }
        className={`${BLOCK}__document`}
        ref={isLeftView ? leftStatementRef : statementRef}
        onScroll={onScrollPage}
      >
        {!blacklineViewMode && (
          <StatementReadOnlyBanners
            selectedStatement={selectedStatement}
            isBatchModeSelected={isBatchModeSelected}
            urlParams={urlParams}
            workflowsMap={workflowsMap}
          />
        )}
        <ConditionalRender dependencies={[sectionIdListData]}>
          {sectionIdListData.sectionIds.map((sectionMetaData, index) => {
            const imageCache = isLeftView
              ? leftCache.get(sectionMetaData.sectionId)
              : cache.get(sectionMetaData.sectionId);
            const jsonCache = isLeftView
              ? leftOcrCache.get(sectionMetaData.sectionId)
              : ocrCache.get(sectionMetaData.sectionId);
            const elementListCache = isLeftView
              ? leftElementCache.getSection(sectionMetaData.sectionId)
              : elementCache.getSection(sectionMetaData.sectionId);
            const blacklineListCache = isLeftView
              ? leftBlacklineCache.getSection(sectionMetaData.sectionId)
              : blacklineCache.getSection(sectionMetaData.sectionId);
            const blacklineGuidDetails = isLeftView
              ? blacklineSourceGuidList.guidList.filter(
                  (item) => sectionMetaData.sectionId === item.sectionId,
                )[0]
              : blacklineTargetGuidList.guidList.filter(
                  (item) => sectionMetaData.sectionId === item.sectionId,
                )[0];

            const notesCacheData = isLeftView
              ? leftNotesCache.getSection(sectionMetaData.sectionId)
              : notesCache.getSection(sectionMetaData.sectionId);

            let notesListCache = {};

            if (notesCacheData) {
              let currNote = notesCacheData.pseudoNotes;
              if (!currNote && notesCacheData.getElementNote) {
                currNote = notesCacheData.elementNotes;
              }
              if (!currNote) {
                currNote = new Note();
              }

              notesListCache = currNote;
            }
            return (
              <SectionContentOCR
                {...props}
                BLOCK={BLOCK}
                BLOCK_ID={isLeftView ? LEFT_BLOCK_ID : BLOCK_ID}
                LEFT_BLOCK_ID={LEFT_BLOCK_ID}
                key={
                  isLeftView
                    ? `${LEFT_BLOCK_ID}${index}`
                    : `${BLOCK_ID}${index}`
                }
                sectionMetaData={sectionMetaData}
                currentPage={currentPage}
                isContentPanelScrolling={
                  isLeftView
                    ? isLeftContentPanelScrolling
                    : isContentPanelScrolling
                }
                sectionCache={imageCache} //image
                sectionOCRCache={jsonCache} //json
                elementCache={elementListCache} //elements
                blacklineCache={blacklineListCache} //blackline changes
                blacklineGuidDetails={blacklineGuidDetails} //blackline section guid
                notesCache={notesListCache} // notes
                setClickedSectionId={setClickedSectionId}
                clickedSectionId={clickedSectionId}
                isLeftView={isLeftView}
                setSectionInView={
                  isLeftView ? setLeftSectionInView : setSectionInView
                }
                clearSectionInView={
                  isLeftView ? clearLeftSectionInView : clearSectionInView
                }
              />
            );
          })}
        </ConditionalRender>
      </div>
    </div>
  );
};
export default StatementContentPanelOCR;

StatementContentPanelOCR.propTypes = {
  /** Api model of all the section id's */
  sectionIdList: PropTypes.instanceOf(SectionIdList).isRequired,
  /** Api model of all the  left section id's */
  leftSectionIdList: PropTypes.instanceOf(SectionIdList),
  /** Api model of all the section segments */
  cache: PropTypes.instanceOf(SectionCache),
  /** Api model of all the left section segments */
  leftCache: PropTypes.instanceOf(LeftSectionCache),
  /** Api model of all the ocr section segments */
  ocrCache: PropTypes.instanceOf(SectionCacheOCR),
  /** Api model of all the left ocr section segments */
  leftOcrCache: PropTypes.instanceOf(LeftSectionCacheOCR),
  /** Api model of all the sections elements */
  elementCache: PropTypes.instanceOf(ElementCache),
  /** Api model of all the left sections elements */
  leftElementCache: PropTypes.instanceOf(ElementCache),
  /** Api model of current blackline changes */
  blacklineCache: PropTypes.instanceOf(BlacklineCache),
  /** Api model of previous blackline changes */
  leftBlacklineCache: PropTypes.instanceOf(BlacklineCache),
  /** Action to set left section in view */
  setLeftSectionInView: PropTypes.func.isRequired,
  /** Action to clear left section in view */
  clearLeftSectionInView: PropTypes.func.isRequired,
  /** Action to set section in view */
  setSectionInView: PropTypes.func.isRequired,
  /** Action to clear left section in view */
  clearSectionInView: PropTypes.func.isRequired,
  /** Api model of blackline guids */
  blacklineGuidList: PropTypes.instanceOf(BlacklineGuidList),
  /** Api model of sections notes */
  notesCache: PropTypes.instanceOf(NoteCache),
  /** Api model of sections notes for source statements in side-by-side view */
  leftNotesCache: PropTypes.instanceOf(NoteCache),
  /** Booloean to check left or right view */
  isLeftView: PropTypes.bool,
  /** Booloean to check blackline view mode*/
  blacklineViewMode: PropTypes.bool,
};
