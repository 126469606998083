import { createAction } from 'redux-actions';

export const setSelectedElementDetails = createAction(
  'SET_SELECTED_ELEMENT_DETAILS_ACTION',
);

export const clearReportPreviewPageData = createAction(
  'CLEAR_REPORT_PREVIEW_PAGE_DATA_ACTION',
);

export const setPreviewPageDisplayOptionAction = createAction(
  'SET_PREVIEW_PAGE_DISPLAY_OPTION',
);
