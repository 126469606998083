import React, { memo, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isNullOrUndefined } from 'utils/object-utils';

import FormItem from 'components/common/form-item-component';
import { DrawerActionHandler } from 'components/util/drawer-action-handler-component';
import Menu from 'components/common/menu-component';
import { ReactComponent as ArrowDown } from 'icons/arrow-simple-thick-down.svg';
import { ReactComponent as Kebab } from 'icons/kebab-bold.svg';

import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { usePrevious } from 'utils/hooks-util';

const DROPDOWN_BLOCK = 'dropdown';
const ARROW_DOWN_SIZE = '18px';
const KEBAB_SIZE = '28px';

const Dropdown = ({
  className,
  disabled,
  errorText,
  id,
  intl,
  isKebab,
  isOpen,
  isValid,
  isNotIntl,
  label,
  onKeyDown,
  onSelectOption,
  onBlur,
  options,
  placeholder,
  required,
  setWrapperRef,
  wrapperRef,
  toggleDrawer,
  tooltip,
  value,
  width,
  onClickFormItem,
  menuClassName,
  usePortalMenu,
  hideDropDownArrow,
  hideCheckmarks,
  centerMenuDisplay,
  numberOfVisibleRows,
  borderless,
  containerRef,
  iconConfig,
  isMultiselect = false,
  showCheckbox = false,
  isMultiselectDisplayValue = '',
}) => {
  const prevOpen = usePrevious(isOpen);
  // this hook determines if focus has been lost, essentially determining the blur logic for this custom dropdown
  useEffect(() => {
    if (!isNullOrUndefined(onBlur) && prevOpen === true && isOpen === false) {
      onBlur();
    }
  });
  return (
    <FormItem
      className={className}
      disabled={disabled}
      errorText={errorText}
      id={id}
      isValid={isValid}
      label={label}
      tooltip={tooltip}
      width={width}
      onClickFormItem={onClickFormItem}
      iconConfig={iconConfig}
    >
      <div
        ref={setWrapperRef}
        className={classNames(
          DROPDOWN_BLOCK,
          borderless && `${DROPDOWN_BLOCK}--borderless`,
        )}
      >
        {!isKebab ? (
          <div
            className={classNames(
              `${DROPDOWN_BLOCK}__header`,
              !isNullOrUndefined(isValid) && !isValid
                ? `${DROPDOWN_BLOCK}__header--invalid`
                : null,
              centerMenuDisplay ? `${DROPDOWN_BLOCK}__header--centered` : null,
              borderless && `${DROPDOWN_BLOCK}__header--borderless`,
            )}
            onClick={!disabled ? toggleDrawer : undefined}
            onKeyDown={(e) => onKeyDown(e)}
            tabIndex={0}
            disabled={disabled}
          >
            <div
              className={classNames(
                `${DROPDOWN_BLOCK}__title`,
                value ? `${DROPDOWN_BLOCK}__title--selected` : null,
              )}
            >
              {isMultiselect
                ? `${value.length} ${intl.formatMessage({
                    id: isMultiselectDisplayValue,
                  })}`
                : value
                ? value.isIntl
                  ? intl.formatMessage({ id: value.title })
                  : value.title
                : isNotIntl
                ? placeholder
                : intl.formatMessage({ id: placeholder })}
            </div>
            {hideDropDownArrow ? null : (
              <ArrowDown
                name={'arrow-simple-thick-down'}
                className={`${DROPDOWN_BLOCK}__toggle`}
                width={ARROW_DOWN_SIZE}
                height={ARROW_DOWN_SIZE}
                style={isOpen ? { transform: 'rotate(180deg)' } : null}
                disabled={disabled}
              />
            )}
          </div>
        ) : (
          /* Wrapping this component allows us to modify it's styles elsewhere without affecting the menu display styles*/
          <div className={`${DROPDOWN_BLOCK}__kebab-wrapper`}>
            <Kebab
              name={'kebab-menu'}
              className={`${DROPDOWN_BLOCK}__kebab`}
              width={KEBAB_SIZE}
              height={KEBAB_SIZE}
              onClick={!disabled && toggleDrawer}
              onKeyDown={(e) => onKeyDown(e)}
              tabIndex={0}
              disabled={disabled}
            />
          </div>
        )}
        {isOpen ? (
          <Menu
            options={options}
            wrapperRef={wrapperRef}
            onSelectOption={onSelectOption}
            toggleDrawer={toggleDrawer}
            isNotIntl={isNotIntl}
            selection={value}
            isMultiselect={isMultiselect}
            showCheckbox={showCheckbox}
            className={menuClassName}
            usePortal={usePortalMenu}
            hideCheckmarks={hideCheckmarks}
            centerMenuDisplay={centerMenuDisplay}
            numberOfVisibleRows={numberOfVisibleRows}
            containerRef={containerRef}
          />
        ) : null}
      </div>
    </FormItem>
  );
};

const sharedPropTypes = {
  /** String custom class */
  className: PropTypes.string,
  /** class passed directly to the menu component */
  menuClassName: PropTypes.string,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** Unique string id for drop-down */
  id: PropTypes.string,
  /** Boolean representing if dropdown should be a kebab */
  isKebab: PropTypes.bool,
  /** HOC Boolean representing whether drop down is open */
  isOpen: PropTypes.bool.isRequired,
  /** Boolean representing if drop-down output is valid */
  isValid: PropTypes.bool,
  /** Flag for non internationalized dropdown and menu options */
  isNotIntl: PropTypes.bool,
  /** Internationalized Sting id passed to form item */
  label: PropTypes.string,
  /** Should rarely be used, onSelectOption should handle the selecting of menu options*/
  onClickFormItem: PropTypes.func,
  /** Function fired when drop down item is selected passing the value of that item not required when each selection fires its own action*/
  onSelectOption: PropTypes.func,
  /** Function fired on blur */
  onBlur: PropTypes.func,
  /** Array of drop down menu items  */
  options: PropTypes.arrayOf(PropTypes.instanceOf(MenuOption)),
  /** Internationalized Sting id */
  placeholder: PropTypes.string,
  /** Boolean representing whether dropdown must be selected */
  required: PropTypes.bool,
  /** HOC Function to set a ref of the drop down wrapper */
  setWrapperRef: PropTypes.func.isRequired,
  /** HOC prop. Reference to this element, passed directly to the menu to help position it, since it is a portal */
  wrapperRef: PropTypes.instanceOf(Element),
  /** HOC Function fired to toggle drop down menu */
  toggleDrawer: PropTypes.func.isRequired,
  /** Object with tool tip options passed to form item */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** Option model containing title, id and value of selection, need whole model in order to display title in case the value variable needs to be different for sending to the api*/
  value: PropTypes.instanceOf(MenuOption),
  /** String percentage representing width of component passed to form item*/
  width: PropTypes.string,
  /** indicates if menu should use a react portal or normal child element implementation */
  usePortalMenu: PropTypes.bool,
  /** indicates if we should not render the dropdown arrow for the dropdown */
  hideDropDownArrow: PropTypes.bool,
  /** indicates if we should not render the checkmark icon on the dropdown for selected values */
  hideCheckmarks: PropTypes.bool,
  /** indicates if we should center the menu or not */
  centerMenuDisplay: PropTypes.bool,
  /* Number of visual data rows in the nested menu component, controls scrolling on it */
  numberOfVisibleRows: PropTypes.number,
  /* Ref assigned to the father element if virtualized list is being used */
  containerRef: PropTypes.object,
};

Dropdown.propTypes = {
  ...sharedPropTypes,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** String error copy passed to form item */
  errorText: PropTypes.string,
  /** Boolean representing if dropdown should be a kebab */
  isKebab: PropTypes.bool,
  /** Boolean representing if drop-down output is valid */
  isValid: PropTypes.bool,
  /** Internationalized Sting id passed to form item */
  label: PropTypes.string,
  /** Function fired when drop down item is selected passing the value of that item not required when each selection fires its own action*/
  onSelectOption: PropTypes.func,
  /** Internationalized Sting id */
  placeholder: PropTypes.string,
  /** Boolean representing whether dropdown must be selected */
  required: PropTypes.bool,
  /** Option model containing title, id and value of selection, need whole model in order to display title in case the value variable needs to be different for sending to the api*/
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(MenuOption),
    PropTypes.string,
  ]),
};

const DefaultKebabDropdown = ({
  options,
  width,
  className,
  tooltip,
  onKeyDown,
  id,
  isNotIntl,
  toggleDrawer,
  isOpen,
  setWrapperRef,
  wrapperRef,
  onClickFormItem,
  menuClassName,
  onBlur,
  usePortalMenu,
  containerRef,
}) => {
  return (
    <Dropdown
      isKebab
      className={className}
      options={options}
      width={width}
      tooltip={tooltip}
      onKeyDown={onKeyDown}
      id={id}
      isNotIntl={isNotIntl}
      toggleDrawer={toggleDrawer}
      isOpen={isOpen}
      setWrapperRef={setWrapperRef}
      wrapperRef={wrapperRef}
      onClickFormItem={onClickFormItem}
      menuClassName={menuClassName}
      onBlur={onBlur}
      usePortalMenu={usePortalMenu}
      containerRef={containerRef}
    />
  );
};

DefaultKebabDropdown.propTypes = {
  ...sharedPropTypes,
};

const KebabDropdown = DrawerActionHandler(memo(DefaultKebabDropdown));
export default DrawerActionHandler(injectIntl(memo(Dropdown)));
export { KebabDropdown };
