import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'utils/object-utils';
import revisionApi from 'api/revision-api';
import ConditionalRenderComponent from 'components/util/conditional-render-component';
import { getBlacklineGuidDetailsAction } from 'store/actions/blackline-view-actions';
import { fetchLeftSelectedStatement } from 'store/actions/left-selected-statement-actions';
import { initLeftStatementContent } from 'store/actions/statement-content-actions';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import StatementContentPanelContainerOCR from 'containers/feature/statement-content/statement-content-panel-container-ocr';
import { setSourceStatementParams } from 'store/actions/source-statement-actions';

export const BLACKLINE_PREVIOUS_PANEL_BLOCK = 'blackline-previous-panel';
export const BLACKLINE_PREVIOUS_PANEL_BLOCK_ID = 'blackline-previous-panel-id';

const BlacklinePreviousVersionView = ({
  getBlacklineGuidDetailsAction,
  blacklineViewShowElementMode,
  fetchLeftSelectedStatement,
  initLeftStatementContent,
  ocrCurrentViewPageNumber,
  setSourceStatementParams,
  leftRevision,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { projectId, statementId, revisionId } = useParams();

  const setDataLoaded = () => {
    setLoading(false);
    setLoaded(true);
  };

  const setDataLoading = () => {
    setLoading(true);
    setLoaded(false);
  };

  useEffect(() => {
    const getAllRevisionsAndStatementData = async () => {
      try {
        setDataLoading();
        const { data: revisionStatements } =
          await revisionApi.getActiveRevisionsForStatement(statementId);

        if (
          !isNullOrUndefined(revisionStatements) &&
          Array.isArray(revisionStatements) &&
          revisionStatements.length > 1
        ) {
          const index = revisionStatements
            .map((revision) => revision.id)
            .indexOf(parseInt(revisionId));
          const { id } = revisionStatements[index + 1];
          const params = {
            projectId: projectId,
            statementId: statementId,
            revisionId: id,
            readOnly: false,
          };
          setSourceStatementParams(params);
          if (!leftRevision.isLoaded) {
            await getBlacklineGuidDetailsAction({
              statementId,
              revisionId,
              blackline_view_type: 'BLACKLINE_SOURCE',
            });
            initLeftStatementContent(params);
            fetchLeftSelectedStatement(params);
          }
        }
        setDataLoaded();
      } catch (e) {
        setError(e);
      }
    };

    getAllRevisionsAndStatementData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMarkerOrCallout = blacklineViewShowElementMode ? null : false;
  return (
    <ConditionalRenderComponent dependencies={[{ error, isLoading, isLoaded }]}>
      <div
        className={BLACKLINE_PREVIOUS_PANEL_BLOCK}
        style={{ height: 'calc(100% - 40px )' }}
      >
        <StatementContentPanelContainerOCR
          isLeftView={true}
          setOcrCurrentViewPageNumberDispatch={
            setOcrCurrentViewPageNumberDispatch
          }
          ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
          markerDisplayValue={showMarkerOrCallout}
          calloutDisplayValue={showMarkerOrCallout}
          showElementStatusAndFlag={blacklineViewShowElementMode}
        />
      </div>
    </ConditionalRenderComponent>
  );
};

const mapStateToProps = ({
  data: { leftRevision },
  ui: {
    statementPage: {
      modes: { ocrCurrentViewPageNumber, blacklineViewShowElementMode },
    },
  },
}) => ({
  leftRevision,
  ocrCurrentViewPageNumber,
  blacklineViewShowElementMode,
});

const mapDispatchToProps = {
  setSourceStatementParams,
  initLeftStatementContent,
  fetchLeftSelectedStatement,
  getBlacklineGuidDetailsAction,
  setOcrCurrentViewPageNumberDispatch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlacklinePreviousVersionView);
