import { handleActions } from 'redux-actions';
import {
  showStatusAndFlagAction,
  hideStatusAndFlagAction,
} from 'store/actions/preview-report-page-actions';

export default handleActions(
  {
    [showStatusAndFlagAction]: () => true,
    [hideStatusAndFlagAction]: () => false,
  },
  false,
);
