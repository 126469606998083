import React from 'react';
import classnames from 'classnames';
import {
  getElementFlaggedIcon,
  getElementStatusIcon,
} from 'constants/feature/element-panel-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { LEFT_PANELS } from './panel-constants';

export const ELEMENT_HIGHLIGHT_STATES = {
  DEFAULT: 'default',
  POST_BATCH: 'post-batch',
  OPENED: 'opened',
  SELECTED_FORMULA_ROW: 'selected-formula-row',
  PANEL_SELECTED: 'panel-selected',
  HOVERED_FROM_FORMULA: 'hovered-from-formula',
  SELECTED_FOR_COPY_FORMULA: 'selected-for-copy-formula',
  FORMULA_PASTE_SUCCESS: 'formula-paste-success',
  FORMULA_PASTE_FAILURE: 'formula-paste-failure',
  ELEMENT_MAP_SUCCESS: 'element-map-success',
  ELEMENT_MAP_FAILURE: 'element-map-failure',
};

const ANNOTATION_DETAILS_BLOCK = 'annotation-details';

export const ANNOTATION_BASE_BLOCK = 'element-annotation';

export const hoverCopyFormulaDisabledTooltip = new TooltipOptions({
  text: 'element-panel.annotation.content.copy-formula.disabled.tool-tip',
  id: 'batch-panel.checkbox.copy-formula-disable-tooltip-id',
  position: 'bottom',
});

const getElementInternalReferenceGroupName = ({
  elementDetails,
  customCSS = false,
}) => {
  if (
    isNullOrUndefined(elementDetails) ||
    !elementDetails.hasInternalReferenceGroup()
  ) {
    return null;
  } else {
    return (
      <span
        className={classnames(
          ANNOTATION_BASE_BLOCK,
          `${ANNOTATION_BASE_BLOCK}-internal-reference-group`,
          elementDetails.isInternalRefFlagged() &&
            `${ANNOTATION_BASE_BLOCK}-internal-reference-group--flagged`,
          { [`${ANNOTATION_DETAILS_BLOCK}__contents`]: customCSS },
        )}
      >
        {elementDetails.internalReferenceGroupName}
      </span>
    );
  }
};

const getElementWorkpaperContentDisplay = ({
  elementDetails,
  currLeftPanel,
  customCSS = false,
}) => {
  if (isNullOrUndefined(elementDetails) || !elementDetails.hasWorkpapers()) {
    return null;
  } else {
    return (
      <span
        title={
          currLeftPanel === LEFT_PANELS.WORKPAPER
            ? null
            : elementDetails.workpaperReferenceNumberList
        }
        className={classnames(
          ANNOTATION_BASE_BLOCK,
          `${ANNOTATION_BASE_BLOCK}-workpaper-reference`,
          { [`${ANNOTATION_DETAILS_BLOCK}__contents`]: customCSS },
        )}
      >
        {elementDetails.workpaperReferenceNumberList.join([', '])}
      </span>
    );
  }
};

const getElementTickmarksContentDisplay = ({
  elementDetails,
  customCSS = false,
}) => {
  if (isNullOrUndefined(elementDetails) || !elementDetails.hasTickmarks()) {
    return null;
  } else {
    return (
      <span
        className={classnames(
          ANNOTATION_BASE_BLOCK,
          `${ANNOTATION_BASE_BLOCK}-tickmark`,
          { [`${ANNOTATION_DETAILS_BLOCK}__contents`]: customCSS },
        )}
      >
        {customCSS
          ? elementDetails.tickmarks.replace(/}{/g, '},{')
          : elementDetails.tickmarks}
      </span>
    );
  }
};

const getElementTickmarkTextContentDisplay = ({ elementDetails }) => {
  if (isNullOrUndefined(elementDetails) || !elementDetails.hasTickmarkText()) {
    return null;
  } else {
    return (
      <span
        className={classnames(
          ANNOTATION_BASE_BLOCK,
          `${ANNOTATION_BASE_BLOCK}-tickmark`,
        )}
      >
        {elementDetails.tickmarkText}
      </span>
    );
  }
};

const getElementComfortLetterContentDisplay = ({
  elementDetails,
  customCSS = false,
}) => {
  if (
    isNullOrUndefined(elementDetails) ||
    !elementDetails.hasComfortLetters()
  ) {
    return null;
  } else {
    return (
      <span
        className={classnames(
          ANNOTATION_BASE_BLOCK,
          `${ANNOTATION_BASE_BLOCK}-comfort-letter-label`,
          { [`${ANNOTATION_DETAILS_BLOCK}__contents`]: customCSS },
        )}
      >
        {customCSS
          ? elementDetails.comfortLetterLabelList
              .map((label) => `[${label}]`)
              .join(', ')
          : elementDetails.comfortLetterLabelList
              .map((label) => `[${label}]`)
              .join('')}
      </span>
    );
  }
};

const getElementFormulaTypeContentDisplay = ({ elementDetails }) => {
  const FORMULA_ANNOTATION_BLOCK = `${ANNOTATION_BASE_BLOCK}-formula`;
  if (isNullOrUndefined(elementDetails) || !elementDetails.hasFormula()) {
    return null;
  } else {
    return (
      <>
        {elementDetails.hasFootedFormula() && (
          <span
            className={classnames(
              ANNOTATION_BASE_BLOCK,
              FORMULA_ANNOTATION_BLOCK,
              elementDetails.hasFootedFormulaFlagged()
                ? `${FORMULA_ANNOTATION_BLOCK}--flagged`
                : null,
            )}
          >
            F
          </span>
        )}
        {elementDetails.hasCrossFootedFormula() && (
          <span
            className={classnames(
              ANNOTATION_BASE_BLOCK,
              FORMULA_ANNOTATION_BLOCK,
              elementDetails.hasCrossFootedFormulaFlagged()
                ? `${FORMULA_ANNOTATION_BLOCK}--flagged`
                : null,
            )}
          >
            Cf
          </span>
        )}
        {elementDetails.hasRecalcFormula() && (
          <span
            className={classnames(
              ANNOTATION_BASE_BLOCK,
              FORMULA_ANNOTATION_BLOCK,
              elementDetails.hasRecalcFormulaFlagged()
                ? `${FORMULA_ANNOTATION_BLOCK}--flagged`
                : null,
            )}
          >
            Rx
          </span>
        )}
      </>
    );
  }
};

const getElementPanelFormulaContentDisplay = ({
  elementDetails,
  customCSS = false,
}) => {
  const FORMULA_ANNOTATION_BLOCK = `${ANNOTATION_BASE_BLOCK}-formula`;
  if (isNullOrUndefined(elementDetails) || !elementDetails.hasFormula()) {
    return null;
  } else {
    let formulaContent = [];
    const formulaMapping = {
      hasFootedFormula: 'F',
      hasCrossFootedFormula: 'Cf',
      hasRecalcFormula: 'Rx',
    };

    for (const [method, label] of Object.entries(formulaMapping)) {
      if (elementDetails[method]()) {
        formulaContent.push(label);
      }
    }

    if (formulaContent.length === 0) {
      return null;
    }

    return (
      <span
        className={classnames(
          ANNOTATION_BASE_BLOCK,
          FORMULA_ANNOTATION_BLOCK,
          elementDetails.hasRecalcFormulaFlagged()
            ? `${FORMULA_ANNOTATION_BLOCK}--flagged`
            : null,
          { [`${ANNOTATION_DETAILS_BLOCK}__contents`]: customCSS },
        )}
      >
        {formulaContent.join(', ')}
      </span>
    );
  }
};

export const ANNOTATION_DISPLAY_FUNCTIONS = {
  flagged: getElementFlaggedIcon,
  workpaper: getElementWorkpaperContentDisplay,
  status: getElementStatusIcon,
  internalRef: getElementInternalReferenceGroupName,
  tickmarks: getElementTickmarksContentDisplay,
  tickmarkText: getElementTickmarkTextContentDisplay,
  formula: getElementFormulaTypeContentDisplay,
  comfortLetter: getElementComfortLetterContentDisplay,
  elementFormula: getElementPanelFormulaContentDisplay,
};
