import { useEffect, useState } from 'react';

function useScreenSize() {
  const [screenSize, setScreenSize] = useState(
    window.innerWidth * window.innerHeight,
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenSize(window.innerWidth * window.innerHeight);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setScreenSize(window.innerWidth * window.innerHeight);
      });
    };
  }, []);

  return screenSize;
}

export default useScreenSize;
