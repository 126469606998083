import React, { useEffect } from 'react';
import { ReactComponent as CalloutDirectionSetterLeft } from 'icons/callouts-direction-setter-left.svg';
import { ReactComponent as CalloutDrodownArrow } from 'icons/callouts-dropdown-arrow.svg';
import { ANNOTATION_POSITIONS } from 'constants/feature/annotation-elements-constants';
import IconDropDown from 'components/common/icon-dropdown';

export const STATEMENT_ANNOTATIONS_UPDATE_BLOCK =
  'statement-annotations-update-block';
const ANNOTATION_SUMMARY_ICON_SIZE = '30px';
const STATEMENT_ANNOTATIONS_UPDATE_BLOCK_ID =
  'statement-annotations-update-block-id';
const CALLOUT_DROP_DOWN_ARROW_WIDTH = 15;
const CALLOUT_DROP_DOWN_ARROW_HEIGHT = 30;

const StatementAnnotationsUpdate = ({
  setAnnotationsDisplayPosition,
  annotationDisplayPosition,
  disabled,
}) => {
  useEffect(() => {
    setAnnotationsDisplayPosition({ position: ANNOTATION_POSITIONS[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectAnnotationPosition = (position) => {
    setAnnotationsDisplayPosition({ position });
  };

  return (
    <div className={`${STATEMENT_ANNOTATIONS_UPDATE_BLOCK}`}>
      <div className={`${STATEMENT_ANNOTATIONS_UPDATE_BLOCK}__icons`}>
        <IconDropDown
          displayIconPlaceholder={
            <CalloutDirectionSetterLeft
              width={ANNOTATION_SUMMARY_ICON_SIZE}
              height={ANNOTATION_SUMMARY_ICON_SIZE}
            />
          }
          tooltip={{
            id: STATEMENT_ANNOTATIONS_UPDATE_BLOCK_ID,
            text: disabled
              ? null
              : 'statement-annotations-update-block-tooltip',
            position: 'top',
          }}
          arrowIcon={
            <CalloutDrodownArrow
              width={CALLOUT_DROP_DOWN_ARROW_WIDTH}
              height={CALLOUT_DROP_DOWN_ARROW_HEIGHT}
            />
          }
          options={ANNOTATION_POSITIONS}
          value={annotationDisplayPosition.annotationPosition}
          onSelectOption={(position) => selectAnnotationPosition(position)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default StatementAnnotationsUpdate;
