/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useMemo, useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  STATEMENT_ELEMENT_ID_PREFIX,
  STATEMENT_LEFT_ELEMENT_ID_PREFIX,
  getElementHightlightState,
} from 'constants/feature/statement-content-constants';

import { initElementPanel } from 'store/actions/element-panel-actions';
import {
  addElementFormulaRow,
  setSelectedRowAction,
} from 'store/actions/formula-actions';
import { addElementToInternalReference } from 'store/actions/internal-reference-actions';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import {
  ANNOTATION_DISPLAY_FUNCTIONS,
  hoverCopyFormulaDisabledTooltip,
} from 'constants/feature/tieout-element-constants';
import { clickElementInContentWhileBatch } from 'store/actions/batch-panel-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import { clearContentHighlightSelector } from 'store/actions/statement-content-actions';
import { addElementForCopyFormula } from 'store/actions/copy-formula-actions';
import Tooltip from 'components/common/tool-tip-component';
import { deselectElementContentPanel } from 'store/actions/statement-content-actions';
import { removeElementFromBatchSelection } from 'store/actions/copy-formula-actions';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from '../../../constants/feature-flag-constants';
import {
  getShowCalloutValue,
  getElementHighlightStatus,
  hasAnnotations,
  isElementReportPreviewPage,
} from 'utils/ocr-annotation-utils';
import { useDispatch } from 'react-redux';
import { updateSideBySideElementCacheByCallouts } from 'store/actions/statement-content-annotation-creation-actions';
import PreviewReportSelectedableElement from './tieout-element-html/_preview-report-selectedable-element-component';
import TieoutElementCallouts from 'components/feature/statement-content-panel/tieout-element-callouts';
import { SelectedableElement as AnnotationDisplay } from 'components/feature/statement-content-panel/tieout-callout-element-component-ocr';
import useHoverEffect from 'components/hooks/useHoverEffects';

export const ELEMENT_BLOCK = 'tieout-element';
export const COMFORT_ASSIGN_ELEMENT = 'comfort-assign-element';
export const SIDE_BY_SIDE_VIEW_SELECTED_ELEMENT =
  'selected-element-for-side-by-side-view';
export const TIEOUT_ELEMENT_LEFT = 'tieout-element-left';

const ANNOTATION_ICON_SIZE = '10px';
export const CALLOUT_MAX_WIDTH = '120px';

/* LOCAL THUNKS */
/**
 * Handles what happens when an element is clicked in the content panel
 * different modes will dictate what happens when an element is clicked
 * @param {int} param.elementId
 * @param {int} param.sectionId
 */
export const onElementClick =
  ({ elementId, sectionId, color, isClickedFromPanel, isDisabled }) =>
  async (dispatch, getStore) => {
    const { modes } = getStore().ui.statementPage;
    const { copyFormulaSelectedElement } = getStore().data.copyFormula;
    const { selectMode } = modes;
    switch (selectMode) {
      case ELEMENT_SELECT_MODES.BATCH_WITH_BANNER:
      case ELEMENT_SELECT_MODES.BATCH: {
        dispatch(clickElementInContentWhileBatch({ elementId, sectionId }));
        break;
      }
      case ELEMENT_SELECT_MODES.RANGE: {
        break;
      }
      case ELEMENT_SELECT_MODES.INTERNAL_REFERENCE: {
        dispatch(
          addElementToInternalReference({
            targetElementId: elementId,
            isFromSuggestion: false,
          }),
        );
        break;
      }
      case ELEMENT_SELECT_MODES.FORMULA: {
        await dispatch(
          addElementFormulaRow({
            elementId,
            sectionId,
          }),
        );
        //if an element for a formula is already selected, set the selected row as null
        dispatch(setSelectedRowAction(null));
        break;
      }
      case ELEMENT_SELECT_MODES.COPY_FORMULA: {
        if (!isDisabled) {
          if (
            copyFormulaSelectedElement.selectedElementIds.some(
              // eslint-disable-next-line eqeqeq
              (eleId) => eleId == elementId,
            )
          ) {
            dispatch(removeElementFromBatchSelection({ elementId }));
            dispatch(deselectElementContentPanel({ elementId }));
          } else {
            dispatch(
              addElementForCopyFormula({
                elementId,
              }),
            );
          }
        }
        break;
      }

      default: {
        if (!isClickedFromPanel) {
          await dispatch(clearContentHighlightSelector());
        }
        await dispatch(initElementPanel({ elementId, color }));
      }
    }
  };

/* COMPONENT */
export const TieoutElement = ({
  id,
  sectionId,
  name,
  children,
  isSideBySideViewDisabled,
  elementDataparts,
  elementData,
  handleElementClick,
  handleAnnotationClick,
  isCursorAlias,
  isElementSelectedForSideBySideMap,
  getCountForSelectedElementForSideBySideMapping,
  selectedElementsMap,
  displayElementDetailsOnStatementPage,
  zoom,
  leftSideView,
  numberOfSourceElementSelected,
  numberOfTargetElementSelected,
  elementPanelElementId,
  annotationDisplayFilter,
  isCftEnabled,
  annotationPosition,
  rightPanelState,
  highlightedElementId,
  isElementSelected,
  elementHighlightState,
  isPartOfTableMatrix,
  isSideBySideView,
}) => {
  let elementId = useMemo(
    () => id.substring(STATEMENT_ELEMENT_ID_PREFIX.length),
    [id],
  );
  const customElementId = useMemo(
    () => elementId && !!elementId.split('_').length && elementId.split('_')[0],
    [elementId],
  );
  const hideAnnotations = useMemo(
    () => ({ flag: false, annotations: false }),
    [],
  );

  const TIEOUT_ELEMENT_CLASS = useMemo(
    () => (leftSideView ? TIEOUT_ELEMENT_LEFT : ELEMENT_BLOCK),
    [leftSideView],
  );

  const isHighlighted = useMemo(
    () => highlightedElementId === elementId,
    [highlightedElementId, elementId],
  );

  const { position } = annotationPosition;

  if (elementId && elementId.indexOf('_') >= 0) {
    const elementIdWithDatapart = elementId;
    elementId = elementId.substr(0, elementId.indexOf('_'));
    if (elementDataparts.elements && elementId in elementDataparts.elements) {
      const datapartId = elementIdWithDatapart.substr(
        elementIdWithDatapart.indexOf('_') + 1,
        elementIdWithDatapart.length,
      );

      if (!isNullOrUndefined(datapartId)) {
        if (parseInt(datapartId) !== 0) {
          hideAnnotations.flag = true;
        }

        switch (position) {
          case 'left':
          case 'top':
            if (parseInt(datapartId) !== 0) {
              hideAnnotations.annotations = true;
            }
            break;
          case 'right':
          case 'bottom':
            if (datapartId !== elementDataparts.elements[elementId]) {
              hideAnnotations.annotations = true;
            }
            break;
          default:
            break;
        }
      }
    }
  }

  const handleClick = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
      }
      handleElementClick({
        elementId,
        sectionId,
        isDisabled: !isPartOfTableMatrix,
      });
    },
    [elementId, sectionId],
  );

  const handleOnAnnotationClick = useCallback(
    (showCallout) => {
      handleAnnotationClick({
        elementId,
        showCallout,
      });
    },
    [elementId],
  );

  return isElementReportPreviewPage(window.location.pathname) ? (
    <PreviewReportSelectedableElement
      elementDetails={elementData}
      id={id}
      elementId={elementId}
      sectionId={sectionId}
      children={children}
      zoom={zoom}
    />
  ) : (
    <MemoizedSelectedableComponent
      selectableKey={id}
      id={id}
      name={name}
      isHighlighted={isHighlighted}
      onClick={handleClick}
      onAnnotationClick={handleOnAnnotationClick}
      isCursorAlias={isCursorAlias}
      elementData={elementData}
      selected={isElementSelected}
      elementHighlightState={elementHighlightState}
      elementId={elementId}
      sectionId={sectionId}
      panel={rightPanelState}
      elementPanelElementId={elementPanelElementId}
      hideAnnotations={hideAnnotations}
      isDisabled={!isPartOfTableMatrix}
      isSideBySideViewDisabled={isSideBySideViewDisabled(elementId)}
      tooltip={!isPartOfTableMatrix ? hoverCopyFormulaDisabledTooltip : false}
      isCftEnabled={isCftEnabled}
      annotationDisplayFilter={annotationDisplayFilter}
      annotationPosition={annotationPosition}
      isElementSelectedForSideBySideMap={isElementSelectedForSideBySideMap}
      leftSideView={leftSideView}
      getCountForSelectedElementForSideBySideMapping={
        getCountForSelectedElementForSideBySideMapping
      }
      isElementSelected={isElementSelected}
      displayElementDetailsOnStatementPage={
        displayElementDetailsOnStatementPage
      }
      zoom={zoom}
      customElementId={customElementId}
      TIEOUT_ELEMENT_CLASS={TIEOUT_ELEMENT_CLASS}
      numberOfSourceElementSelected={numberOfSourceElementSelected}
      numberOfTargetElementSelected={numberOfTargetElementSelected}
      selectedElementsMap={selectedElementsMap}
      isSideBySideView={isSideBySideView}
    >
      {children}
    </MemoizedSelectedableComponent>
  );
};

const _getSelectedModifierClass = ({
  selected,
  panel,
  elementPanelElementId,
  elementId,
  elementHighlightState,
  TIEOUT_ELEMENT_CLASS,
}) =>
  selected
    ? `${TIEOUT_ELEMENT_CLASS}--selected--${getElementHightlightState(
        panel,
        elementPanelElementId,
        elementId,
      )} ${TIEOUT_ELEMENT_CLASS}--selected--${elementHighlightState}`
    : null;

const SelectedableElement = ({
  id,
  name,
  selected,
  elementHighlightState,
  onClick,
  elementData,
  elementId,
  sectionId,
  children,
  panel,
  elementPanelElementId,
  isHighlighted,
  hideAnnotations,
  href,
  isDisabled,
  isSideBySideViewDisabled,
  tooltip,
  annotationDisplayFilter,
  annotationPosition,
  isElementSelectedForSideBySideMap,
  getCountForSelectedElementForSideBySideMapping,
  isCursorAlias,
  isCftEnabled,
  isElementSelected,
  displayElementDetailsOnStatementPage,
  onAnnotationClick,
  zoom,
  leftSideView,
  customElementId,
  TIEOUT_ELEMENT_CLASS,
  numberOfSourceElementSelected,
  numberOfTargetElementSelected,
  selectedElementsMap,
  isSideBySideView,
}) => {
  const [isShowCallout, setShowCalloutVal] = useState(false);
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const { isReviewed, isVerified, isUnverified } = getElementHighlightStatus({
    elementDetails: elementData,
    isElementSelected,
  });

  const _isElementSelectedForSideBySideMap =
    isElementSelectedForSideBySideMap(elementId);
  const _countForSelectedElementForSideBySideMapping =
    getCountForSelectedElementForSideBySideMapping(elementId);

  const getElementStatusColor = useCallback(() => {
    if (isNullOrUndefined(_countForSelectedElementForSideBySideMapping)) {
      return isReviewed
        ? `${TIEOUT_ELEMENT_CLASS}--reviewed`
        : isVerified
        ? `${TIEOUT_ELEMENT_CLASS}--verified`
        : isUnverified && `${TIEOUT_ELEMENT_CLASS}--unverified`;
    }
  }, [
    isReviewed,
    isVerified,
    isUnverified,
    _countForSelectedElementForSideBySideMapping,
    TIEOUT_ELEMENT_CLASS,
  ]);

  const handleIndicatorOnHover = useCallback(
    (elementId, showCallout) => {
      const { marker = false, callout = false } = elementData || {};
      if (!annotationDisplayFilter.showAll) {
        isShowCallout &&
          (marker || callout) &&
          dispatch(
            updateSideBySideElementCacheByCallouts({
              elementIds: [elementId],
              showCallout: showCallout,
              isLeftSideView: leftSideView,
            }),
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      annotationDisplayFilter.showAll,
      isShowCallout,
      leftSideView,
      annotationPosition.position,
    ],
  );

  const { handleMouseEnter, handleMouseLeave } = useHoverEffect(
    `${TIEOUT_ELEMENT_CLASS}`,
    customElementId,
  );

  const TIEOUT_ELEMENT_ID_PREFIX = leftSideView
    ? `${STATEMENT_LEFT_ELEMENT_ID_PREFIX}${elementId}`
    : id;

  const renderSelectedableElement = () => {
    return (
      <>
        <a
          ref={elementRef}
          className={classnames(
            isDisabled && `${TIEOUT_ELEMENT_CLASS}--disabled`,
            isSideBySideViewDisabled &&
              `${TIEOUT_ELEMENT_CLASS}--cursor-disabled`,
            TIEOUT_ELEMENT_CLASS,
            `${TIEOUT_ELEMENT_CLASS}-${customElementId}`,
            selected ? `${TIEOUT_ELEMENT_CLASS}--selected` : null,
            _getSelectedModifierClass({
              selected,
              panel,
              elementPanelElementId,
              elementId,
              elementHighlightState,
              TIEOUT_ELEMENT_CLASS,
            }),
            isHighlighted && `${TIEOUT_ELEMENT_CLASS}--highlighted`,
            isSideBySideView &&
              isCursorAlias &&
              `${TIEOUT_ELEMENT_CLASS}--cursor-alias`,
            _isElementSelectedForSideBySideMap &&
              SIDE_BY_SIDE_VIEW_SELECTED_ELEMENT,
          )}
          id={TIEOUT_ELEMENT_ID_PREFIX}
          disabled={isDisabled}
          name={name}
          onClick={() => {
            onClick();
          }}
          onMouseEnter={(e) => {
            e.stopPropagation();
            handleIndicatorOnHover(elementId, true);
            handleMouseEnter();
          }}
          onMouseLeave={(e) => {
            e.stopPropagation();
            if (!annotationDisplayFilter.expandAllElements || !selected) {
              handleIndicatorOnHover(elementId, false);
            }
            handleMouseLeave();
          }}
          data-element-id={elementId}
          data-section-id={sectionId}
          href={href}
        >
          {displayElementDetailsOnStatementPage &&
          !hideAnnotations.flag &&
          annotationDisplayFilter.flag
            ? ANNOTATION_DISPLAY_FUNCTIONS.flagged({
                elementDetails: elementData,
                iconSize: ANNOTATION_ICON_SIZE,
                BLOCK: `${TIEOUT_ELEMENT_CLASS}--flag`,
              })
            : null}
          <FeatureFlagComponent
            name={FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE}
            fallback={
              <span
                className={classnames(
                  TIEOUT_ELEMENT_CLASS,
                  isDisabled && `${TIEOUT_ELEMENT_CLASS}--disabled`,
                  getElementStatusColor(),
                  {
                    [`${TIEOUT_ELEMENT_CLASS}--cursor-disabled`]:
                      isSideBySideViewDisabled,
                    [`${TIEOUT_ELEMENT_CLASS}--cursor-alias`]:
                      isSideBySideView && isCursorAlias,
                  },
                )}
              >
                {children}
              </span>
            }
          >
            <span
              className={classnames(
                TIEOUT_ELEMENT_CLASS,
                isDisabled && `${TIEOUT_ELEMENT_CLASS}--disabled`,
                `${TIEOUT_ELEMENT_CLASS}-${customElementId}`,
                getElementStatusColor(),
                {
                  [`${TIEOUT_ELEMENT_CLASS}--selectable-element-cursor`]:
                    _isElementSelectedForSideBySideMap,
                  [`${COMFORT_ASSIGN_ELEMENT}`]:
                    elementData && elementData.isComfortAssign(),
                  [`${TIEOUT_ELEMENT_CLASS}--cursor-disabled`]:
                    isSideBySideViewDisabled,
                  [`${TIEOUT_ELEMENT_CLASS}--cursor-alias`]:
                    isSideBySideView && isCursorAlias,
                },
              )}
              onMouseEnter={(e) => {
                handleMouseEnter();
              }}
              onMouseLeave={(e) => {
                handleMouseLeave();
              }}
            >
              {children}
            </span>
          </FeatureFlagComponent>
          {displayElementDetailsOnStatementPage &&
            !isNullOrUndefined(
              _countForSelectedElementForSideBySideMapping,
            ) && (
              <span className={`${SIDE_BY_SIDE_VIEW_SELECTED_ELEMENT}__count`}>
                {_countForSelectedElementForSideBySideMapping}
              </span>
            )}
        </a>

        {displayElementDetailsOnStatementPage &&
          !hideAnnotations.annotations &&
          elementData &&
          hasAnnotations(elementData, isCftEnabled) && (
            <TieoutElementCallouts
              elementRef={elementRef}
              annotationPosition={annotationPosition}
              updateSideBySideElementCacheByCallouts={
                updateSideBySideElementCacheByCallouts
              }
              leftSideView={leftSideView}
              getShowCalloutValue={getShowCalloutValue}
              isShowCallout={isShowCallout}
              setShowCalloutVal={setShowCalloutVal}
              elementData={elementData}
              annotationDisplayFilter={annotationDisplayFilter}
              id={TIEOUT_ELEMENT_ID_PREFIX}
              zoom={zoom}
              ELEMENT_BLOCK={TIEOUT_ELEMENT_CLASS}
              displayElementDetailsOnStatementPage={
                displayElementDetailsOnStatementPage
              }
              sectionId={sectionId}
              onAnnotationClick={onAnnotationClick}
              numberOfSourceElementSelected={numberOfSourceElementSelected}
              numberOfTargetElementSelected={numberOfTargetElementSelected}
              selectedElementsMap={selectedElementsMap}
            >
              <AnnotationDisplay
                hideAnnotations={hideAnnotations}
                annotationDisplayFilter={annotationDisplayFilter}
                elementDetails={elementData}
                isOCR={false}
              />
            </TieoutElementCallouts>
          )}
      </>
    );
  };

  return tooltip ? (
    <Tooltip {...tooltip} active={tooltip ? true : false}>
      {renderSelectedableElement()}
    </Tooltip>
  ) : (
    renderSelectedableElement()
  );
};

const MemoizedSelectedableComponent = React.memo(SelectedableElement);

TieoutElement.propTypes = {
  /** unique id for this element (should be CFTO_ELEMENT_XXXXXXX) */
  id: PropTypes.string.isRequired,
  /** name attribute for element, NOTE: probably can be filtered/deleted if we decide we don't need it */
  name: PropTypes.string,
  /** Link attribute */
  href: PropTypes.string,
  /** this function is used mediator to have handle API call triggering in side by side view */
  handleElementClick: PropTypes.func,
  /** object of statmentContent.elementDataParts or leftStatmentContent.leftElementDataParts will be used based on the left or right statements in SideBySide View */
  elementDataparts: PropTypes.object,
  /** object of statmentContent.elementCache or leftStatmentContent.leftElementCache will be used based on the left or right statements in SideBySide View */
  elementData: PropTypes.func,
  /** boolean value used to handle the pointer for left statement in SideBySide View */
  isCursorAlias: PropTypes.bool,
  /**A function which tells us, if an element is part of side by side view mapping */
  isElementSelectedForSideBySideMap: PropTypes.func,
  /**It gives us info about the sequence number of mapped element. For example, an element is nth mapping in the sequence */
  getCountForSelectedElementForSideBySideMapping: PropTypes.func,
  /** A function to help us decide if a particular element in source or target statement (when in side by side view mode) should be disabled or enabled*/
  isSideBySideViewDisabled: PropTypes.func,
};

export default memo(TieoutElement);
