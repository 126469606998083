import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { ANNOTATION_DISPLAY_FUNCTIONS } from 'constants/feature/tieout-element-constants';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import OcrCallouts from 'components/feature/statement-content-panel/ocr-callouts';
import PreviewReportCalloutComponent from './tieout-element-common/_tieout-preview-report-callout-component';
import classNames from 'classnames';

const ELEMENT_BLOCK = 'tieout-element';

export const TieoutCalloutElementOCR = ({
  elementDetails,
  BLOCK,
  elementId,
  annotationDisplayFilter,
  annotation,
  annotationPosition,
  polygonInPixel,
  setAnnotation,
  isOCR,
  sectionId,
  isLeftView,
  canvasID,
}) => {
  let hideAnnotations = { flag: false, annotations: false };

  const url = useLocation().pathname;
  const isPreviewPage = url.includes(ROUTE_CONSTANTS.REPORT_PREVIEW);

  return (
    <OcrCallouts
      elementId={elementId}
      width={120}
      BLOCK={BLOCK}
      annotationDisplayFilter={annotationDisplayFilter}
      annotation={annotation}
      annotationPosition={annotationPosition}
      elementDetails={elementDetails}
      polygonInPixel={polygonInPixel}
      setAnnotation={setAnnotation}
      sectionId={sectionId}
      isLeftView={isLeftView}
      canvasID={canvasID}
    >
      <div
        className={classNames({
          [`${ELEMENT_BLOCK}`]: !isPreviewPage,
        })}
      >
        <SelectedableElement
          hideAnnotations={hideAnnotations}
          annotationDisplayFilter={annotationDisplayFilter}
          elementDetails={elementDetails}
          isOCR={isOCR}
          isPreviewPage={isPreviewPage}
        />
      </div>
    </OcrCallouts>
  );
};

export const SelectedableElement = ({
  hideAnnotations,
  annotationDisplayFilter,
  elementDetails,
  isOCR,
  isPreviewPage,
}) => {
  if (isPreviewPage) {
    return <PreviewReportCalloutComponent elementDetails={elementDetails} />;
  } else {
    return (
      <>
        <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
          {!hideAnnotations.annotations && annotationDisplayFilter.comfortLetter
            ? ANNOTATION_DISPLAY_FUNCTIONS.comfortLetter({
                elementDetails,
              })
            : null}
        </FeatureFlagComponent>
        {!hideAnnotations.annotations && annotationDisplayFilter.tickmarks
          ? ANNOTATION_DISPLAY_FUNCTIONS.tickmarks({
              elementDetails,
            })
          : null}
        {!hideAnnotations.annotations && annotationDisplayFilter.formula
          ? ANNOTATION_DISPLAY_FUNCTIONS.formula({
              elementDetails,
            })
          : null}
        {!hideAnnotations.annotations && annotationDisplayFilter.internalRef
          ? ANNOTATION_DISPLAY_FUNCTIONS.internalRef({
              elementDetails,
            })
          : null}
        {!hideAnnotations.annotations && annotationDisplayFilter.workpaper
          ? ANNOTATION_DISPLAY_FUNCTIONS.workpaper({
              elementDetails,
              isOCR,
            })
          : null}
      </>
    );
  }
};

export default memo(TieoutCalloutElementOCR);
