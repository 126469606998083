import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { SectionTreeListEntry } from 'models/data/section-tree-list-entry-model';
import {
  getArrayWithNewSection,
  deleteNewSection,
  isSectionInTree,
} from 'utils/section-tree-utils';

export class SectionTreeList extends ApiModel({
  data: {
    sectionsHierarchy: [],
    isCreateSectionModalOpen: false,
    newSectionId: null,
  },
}) {
  get sectionsHierarchy() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.sectionsHierarchy;
    }
    return [];
  }
  get hasBeenFetched() {
    return this.isLoaded || !this.isLoading;
  }
  get newSectionId() {
    return this.data.newSectionId;
  }

  processResponse({ response }) {
    return {
      data: {
        sectionsHierarchy:
          response &&
          response.data &&
          response.data.result &&
          response.data.result.map(
            (topSection) => new SectionTreeListEntry(topSection),
          ),
        newSectionId: this.data.newSectionId ? this.data.newSectionId : null,
      },
    };
  }

  /**
   * method used for flattening the section hirearchy into an array
   * @param {Array} arg.sections array of sections to flatten
   * @param {boolean} arg.includeChildless boolean indicator if want childless sections included in the result output
   */
  _flattenHirearchyToIds = ({ sections, includeChildless }) =>
    sections
      .flatMap((section) => {
        const _hasChildren =
          Array.isArray(section.children) && section.children.length > 0;
        if (_hasChildren) {
          return [
            ...this._flattenHirearchyToIds({
              sections: section.children,
              includeChildless,
            }),
            section.id,
          ];
        } else if (includeChildless) {
          return [section.id];
        }
      })
      .filter((id) => !isNullOrUndefined(id));

  /**
   * extendible method for creting an one dimensionsal es6 Map of
   * [sectionId]: initNodeValue object
   * @param {any} arg.initNodeValue initial value of each map items key
   * @param {boolean} arg.includeChildless boolean indicator if want childless sections included in the result output
   */
  _createFlatMapOfNodesWithValue = ({ initNodeValue, includeChildless }) =>
    new Map(
      this._flattenHirearchyToIds({
        sections: this.sectionsHierarchy,
        includeChildless,
      }).map((id) => [id, initNodeValue]),
    );

  /** Method used to initialize the collapsible node state of the table-of-contents-treeview */
  createCollapseStateMapFromNodeIds = ({ initNodeValue }) =>
    this._createFlatMapOfNodesWithValue({
      initNodeValue,
      includeChildless: false,
    });

  /** Method used to initialize the hidden/highlight state of the table-of-contents-treeview based on search results */
  createContentSearchFilterState = ({ initNodeValue }) =>
    this._createFlatMapOfNodesWithValue({
      initNodeValue,
      includeChildless: true,
    });

  filteredSectionsHierarchy(term) {
    return this.data.sectionsHierarchy.filter(
      (section) =>
        section.name.toLowerCase().indexOf(term.toLowerCase()) !== -1,
    );
  }

  setIsCreateSectionModalOpen(payload) {
    return this.merge({
      data: {
        ...this.data,
        isCreateSectionModalOpen: payload,
      },
    });
  }

  addNewSectionByParentId(payload) {
    const { section, parentId, inline } = payload;
    const isSectionAlreadyInTree = isSectionInTree(
      { children: this.sectionsHierarchy },
      section.id,
    );
    getArrayWithNewSection(
      { children: this.sectionsHierarchy },
      section,
      parentId,
      inline,
      isSectionAlreadyInTree,
    );
    return this.merge({
      data: {
        ...this.data,
        sectionsHierarchy: this.sectionsHierarchy,
      },
    });
  }
  removeNewSection(sectionId) {
    deleteNewSection({ children: this.sectionsHierarchy }, sectionId);
    return this.merge({
      data: {
        ...this.data,
        sectionsHierarchy: this.sectionsHierarchy,
      },
    });
  }
  setNewSectionId(id) {
    return this.merge({
      data: {
        ...this.data,
        newSectionId: id,
      },
    });
  }
}
