import { FEATURE_NAMES } from 'constants/feature-flag-constants';

export const ANNOTATION_DISPLAY_FILTER_OPTIONS =
  'annotation-display-filter-options';

export const ANNOTATION_DISPLAY_FILTER_OPTIONS_ID =
  'annotation-display-filter-options-id';

const annotations = {
  workpaper: 'workpaper',
  tickmarks: 'tickmarks',
  internalRef: 'internalRef',
  comfortLetter: 'comfortLetter',
  formula: 'formula',
  flag: 'flag',
  note: 'note',
  status: 'status',
};

export const DISPLAY_ANNOTATION_CHECKBOX_ARRAY = [
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-workpaper-checkbox`,
    name: annotations.workpaper,
    key: '1',
    label: 'annotation-display-filter-workpaper.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-tickmarks-checkbox`,
    name: annotations.tickmarks,
    key: '2',
    label: 'annotation-display-filter-tickmarks.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-internalRef-checkbox`,
    name: annotations.internalRef,
    key: '3',
    label: 'annotation-display-filter-internalRef.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-formula-checkbox`,
    name: annotations.formula,
    key: '4',
    label: 'annotation-display-filter-formula.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-comfortLetter-checkbox`,
    name: annotations.comfortLetter,
    featureName: FEATURE_NAMES.COMFORT_LETTER_FEATURE,
    key: '5',
    label: 'annotation-display-filter-comfortLetter.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-flag-checkbox`,
    name: annotations.flag,
    key: '6',
    label: 'annotation-display-filter-flag.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-status-checkbox`,
    name: annotations.status,
    key: '7',
    label: 'annotation-display-filter-status.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-notes-checkbox`,
    name: annotations.note,
    key: '8',
    label: 'annotation-display-filter-notes.label',
  },
];

export const DISPLAY_ANNOTATION_SUMMARY_TAB_ARRAY = [
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-workpaper`,
    name: annotations.workpaper,
    key: '1',
    label: 'annotation-display-filter-workpaper-initial.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-formula`,
    name: annotations.formula,
    key: '2',
    label: 'annotation-display-filter-formula-initial.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-internalRef`,
    name: annotations.internalRef,
    key: '3',
    label: 'annotation-display-filter-internalRef-initial.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-tickmarks`,
    name: annotations.tickmarks,
    key: '4',
    label: 'annotation-display-filter-tickmarks-initial.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-comfortLetter`,
    name: annotations.comfortLetter,
    featureName: FEATURE_NAMES.COMFORT_LETTER_FEATURE,
    key: '5',
    label: 'annotation-display-filter-comfortLetter-initial.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-notes`,
    name: annotations.note,
    key: '6',
  },
];

const tooltipTexts = {
  workpaper: {
    selected: 'annotation-display-filter-selected-workpaper-tooltip',
    unselected: 'annotation-display-filter-unselected-workpaper-tooltip',
  },
  formula: {
    selected: 'annotation-display-filter-selected-formula-tooltip',
    unselected: 'annotation-display-filter-unselected-formula-tooltip',
  },
  internalRef: {
    selected: 'annotation-display-filter-selected-internalRef-tooltip',
    unselected: 'annotation-display-filter-unselected-internalRef-tooltip',
  },
  tickmarks: {
    selected: 'annotation-display-filter-selected-tickmarks-tooltip',
    unselected: 'annotation-display-filter-unselected-tickmarks-tooltip',
  },
  comfortLetter: {
    selected: 'annotation-display-filter-selected-comfortLetter-tooltip',
    unselected: 'annotation-display-filter-unselected-comfortLetter-tooltip',
  },
};

export const renderTooltipTextBySelection = ({ name, isOptionSelected }) => {
  const tooltip = tooltipTexts[name];
  if (!tooltip) return;
  return isOptionSelected ? tooltip.selected : tooltip.unselected;
};
