import { handleActions } from 'redux-actions';
import {
  monitoringListLoading,
  monitoringListLoaded,
  monitoringListError,
  clearMonitoringListLoaded,
  setCountryOption,
} from 'store/actions/admin-module-actions';
import MonitoringForm from 'models/form/monitoring-form-model';

export default handleActions(
  {
    [monitoringListLoading]: (monitoringList) => {
      return monitoringList.setLoading();
    },
    [monitoringListLoaded]: (monitoringList, { payload }) => {
      return monitoringList.setLoaded(payload);
    },
    [setCountryOption]: (monitoringList, { payload }) => {
      return monitoringList.setCountryOption(payload);
    },
    [monitoringListError]: (monitoringList, { payload }) => {
      return monitoringList.setError(payload);
    },
    [clearMonitoringListLoaded]: () => {
      return new MonitoringForm();
    },
  },
  new MonitoringForm(),
);
