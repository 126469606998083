import { handleActions } from 'redux-actions';
import {
  blacklineTargetGuidListLoading,
  blacklineTargetGuidListLoaded,
  blacklineTargetGuidListError,
  resetBlacklineGuidList,
} from 'store/actions/blackline-view-actions';
import { BlacklineGuidList } from 'models/api/blackline-guid-list-api-model';

export default handleActions(
  {
    [blacklineTargetGuidListLoading]: (blacklineList) => {
      return blacklineList.setLoading();
    },
    [blacklineTargetGuidListLoaded]: (blacklineList, { payload }) => {
      return blacklineList.setLoaded(payload);
    },
    [blacklineTargetGuidListError]: (blacklineList, { payload }) => {
      return blacklineList.setError(payload);
    },
    [resetBlacklineGuidList]: () => {
      return new BlacklineGuidList();
    },
  },
  new BlacklineGuidList(),
);
