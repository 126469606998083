import { handleActions } from 'redux-actions';
import LeftBlacklineCache from 'models/data/blackline-cache-model';
import {
  previousBlacklineViewChangesLoading,
  previousBlacklineViewChangesLoaded,
  previousBlacklineViewChangesError,
  clearPreviousBlacklineContentWithIdsFromCache,
  resetPreviousBlacklineCache,
} from '../../../actions/blackline-view-actions';

export default handleActions(
  {
    [previousBlacklineViewChangesLoading]: (
      leftBlacklineCache,
      { payload },
    ) => {
      return leftBlacklineCache.setSectionLoading(payload);
    },
    [previousBlacklineViewChangesLoaded]: (leftBlacklineCache, { payload }) => {
      return leftBlacklineCache.setSectionLoaded(payload);
    },
    [previousBlacklineViewChangesError]: (leftBlacklineCache, { payload }) => {
      return leftBlacklineCache.setSectionError(payload);
    },
    [resetPreviousBlacklineCache]: () => {
      return new LeftBlacklineCache();
    },
    [clearPreviousBlacklineContentWithIdsFromCache]: (
      leftBlacklineCache,
      { payload },
    ) => {
      return leftBlacklineCache.clearSectionBySectionIds(payload);
    },
  },
  new LeftBlacklineCache(),
);
