import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpandableRow from 'components/common/expandable-row-component';
import ConditionalRender from 'components/util/conditional-render-component';
import Checkbox from 'components/common/checkbox-component';
import ElementDetails from 'models/api/element-details-api-model';
import {
  getReviewTooltip,
  hoverVerifyTooltip,
  reviewInfoButtonTooltip,
  getOverrideSystemFlagTooltip,
  disableComfortAssignTooltip,
} from 'constants/feature/element-panel-constants';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as infoIcon } from 'icons/info.svg';
import { ReactComponent as infoHoverIcon } from 'icons/icon-information-tooltip.svg';
import ConfirmOverrideSystemFlag from './confirm-override-system-flag-modal-component';
import { searchTickmarksByRevisionAndElementRequest } from 'api/tickmark-api';
import { createTickmarkRequest } from 'api/tickmark-api';
import TickmarkForm from 'models/form/tickmark-form-model';
import { ELEMENT_ANNOTATIONS_TABS } from 'constants/feature/element-panel-constants';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';

const ELEMENT_STATUS_BLOCK = 'element-status';
const ELEMENT_STATUS_ID_BLOCK = 'element-status-id';
const TOOLTIP_EVENT = 'click';

class ElementStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      showInformationMessage: false,
      showConfirmOverrideSystemFlag: false,
    };
  }
  componentDidMount() {
    //set tooltip specific size for info button on the right panel when the component is mounted
    const infoTooltip = document.getElementById(
      `${reviewInfoButtonTooltip().id}-tooltip`,
    );
    if (infoTooltip) infoTooltip.style.width = '300px';
  }
  componentDidUpdate() {
    //set tooltip specific size for info button on the right panel when the component change (like open and close the tab)
    if (this.state.expanded) {
      const infoTooltip = document.getElementById(
        `${reviewInfoButtonTooltip().id}-tooltip`,
      );
      if (infoTooltip) infoTooltip.style.width = '300px';
    }
  }
  resetState = () => {
    if (this.state.showInformationMessage) {
      this.setState({
        showInformationMessage: false,
      });
    }
  };

  _fetchTickmarks = async ({ search }) => {
    const { elementDetails } = this.props;
    const response = await searchTickmarksByRevisionAndElementRequest({
      revisionId: elementDetails.revisionId,
      elementId: elementDetails.id,
      search,
    });
    return response;
  };

  _createNewTickmark = async (search) => {
    let response;
    const { elementDetails, setCurrentElementAnnotationsTab } = this.props;
    response = await createTickmarkRequest({
      tickmark: new TickmarkForm()
        .initFromElement({ elementDetails })
        .setText(search),
    });
    setCurrentElementAnnotationsTab(ELEMENT_ANNOTATIONS_TABS.TICKMARK);

    return response;
  };

  onTickmarkAttach = async ({ tickmark }) => {
    const {
      elementDetails,
      onAttachTickmark,
      setCurrentElementAnnotationsTab,
    } = this.props;
    tickmark.revisionId = elementDetails.revisionId;
    tickmark.elementId = elementDetails.elementId;
    await onAttachTickmark({ tickmark });
    setCurrentElementAnnotationsTab(ELEMENT_ANNOTATIONS_TABS.TICKMARK);
  };

  render() {
    const { expanded, showInformationMessage } = this.state;
    const {
      elementDetails,
      updateVerify,
      updateReviewed,
      updateUserFlag,
      updateOverrideSystemFlag,
      updateComfortAssignAnnotation,
      disabled,
    } = this.props;
    return (
      <ConditionalRender dependencies={[elementDetails]}>
        <ExpandableRow
          title="element-panel.status.title"
          expandExpandableRow={() => this.setState({ expanded: true })}
          minimizeExpandableRow={() =>
            this.setState({ expanded: false, showInformationMessage: false })
          }
          showExpandableRow={expanded}
          tooltipId={`${ELEMENT_STATUS_BLOCK}__tooltip`}
          isElementPanel={true}
        >
          <div className={`${ELEMENT_STATUS_BLOCK}__checkbox-group`}>
            <div>
              <Checkbox
                id={`${ELEMENT_STATUS_ID_BLOCK}-verify`}
                className={`${ELEMENT_STATUS_BLOCK}__checkbox-container-verify`}
                checked={elementDetails.isVerified()}
                label={'element-panel.checkbox.verify'}
                width={'auto'}
                onChange={() => {
                  updateVerify({ verified: !elementDetails.internalVerified });
                }}
                disabled={
                  disabled ||
                  (elementDetails.isVerified() && elementDetails.isReviewed())
                }
                tooltip={disabled ? null : hoverVerifyTooltip}
              />
              <Checkbox
                id={`${ELEMENT_STATUS_ID_BLOCK}-user-flag`}
                className={`${ELEMENT_STATUS_BLOCK}__checkbox-container-flag-user-flag`}
                checked={elementDetails.isUserFlagged()}
                label={'element-panel.checkbox.user-flag'}
                onChange={() => {
                  updateUserFlag({ userFlag: !elementDetails.flagUser });
                }}
                width={'auto'}
                disabled={disabled}
              />
              <FeatureFlagComponent
                name={FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE}
              >
                <Checkbox
                  id={`${ELEMENT_STATUS_ID_BLOCK}-comfort-assign-element`}
                  checked={elementDetails.isComfortAssign()}
                  label={'element-panel.checkbox.comfortAssign-option-label'}
                  onChange={() =>
                    updateComfortAssignAnnotation({
                      comfortAssignVal: !elementDetails.comfortAssign,
                    })
                  }
                  width={'auto'}
                  disabled={disabled}
                  tooltip={disabled ? disableComfortAssignTooltip : null}
                />
              </FeatureFlagComponent>
            </div>
            <div>
              <div
                className={`${ELEMENT_STATUS_BLOCK}__checkbox-container-review`}
              >
                <Checkbox
                  id={`${ELEMENT_STATUS_ID_BLOCK}-review`}
                  checked={elementDetails.isReviewed()}
                  label={'element-panel.checkbox.review'}
                  width={'auto'}
                  onChange={() => {
                    updateReviewed({ reviewed: !elementDetails.reviewed });
                  }}
                  disabled={disabled || !elementDetails.isVerified()}
                  tooltip={getReviewTooltip(elementDetails)}
                />
                <IconButton
                  id={`${ELEMENT_STATUS_ID_BLOCK}-review-info-${elementDetails.id}`}
                  className={`${ELEMENT_STATUS_BLOCK}__info-icon`}
                  Icon={showInformationMessage ? infoHoverIcon : infoIcon}
                  onClick={() => {
                    this.setState({
                      showInformationMessage: !showInformationMessage,
                    });
                  }}
                  tooltip={reviewInfoButtonTooltip(TOOLTIP_EVENT)}
                  position={'bottom'}
                  tooltipOnHideFunction={this.resetState}
                />
              </div>

              <Checkbox
                id={`${ELEMENT_STATUS_ID_BLOCK}-override-system-flag`}
                checked={elementDetails.isSystemOverrideFlagged()}
                label={'element-panel.checkbox.override-system-flag'}
                onChange={() =>
                  elementDetails.isSystemOverrideFlagged()
                    ? updateOverrideSystemFlag({
                        systemFlag: false,
                      })
                    : this.setState({
                        showConfirmOverrideSystemFlag: true,
                      })
                }
                width={'auto'}
                disabled={
                  disabled ||
                  !(
                    elementDetails.isFormulaFlagged() ||
                    elementDetails.isInternalRefFlagged() ||
                    elementDetails.isSystemOverrideFlagged()
                  )
                }
                tooltip={getOverrideSystemFlagTooltip(elementDetails)}
              />
            </div>
          </div>
        </ExpandableRow>
        {this.state.showConfirmOverrideSystemFlag && (
          <ConfirmOverrideSystemFlag
            onClickCancel={() =>
              this.setState({
                showConfirmOverrideSystemFlag: false,
              })
            }
            updateOverrideSystemFlag={updateOverrideSystemFlag}
            _fetchTickmarks={this._fetchTickmarks}
            _createNewTickmark={this._createNewTickmark}
            onTickmarkAttach={this.onTickmarkAttach}
            disabled={disabled}
          ></ConfirmOverrideSystemFlag>
        )}
      </ConditionalRender>
    );
  }
}
ElementStatus.propTypes = {
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Action that set element as verified */
  updateVerify: PropTypes.func.isRequired,
  /** Action that set element as reviewed */
  updateReviewed: PropTypes.func.isRequired,
  /** Action to update user flag for element */
  updateUserFlag: PropTypes.func.isRequired,
  /**Action to update system flag */
  updateOverrideSystemFlag: PropTypes.func.isRequired,
  /**Action to handle request for attaching tickmark */
  onAttachTickmark: PropTypes.func.isRequired,
  /**function to make API call and fetch tickmarklist */
  fetchTickmarkList: PropTypes.func.isRequired,
  /**String that represnts type of left panel opened */
  leftPanel: PropTypes.string.isRequired,
  /** Action to set boolean value for comfortAssign elements variable */
  updateComfortAssignAnnotation: PropTypes.func.isRequired,
  /** Action to set the annotations tab */
  setCurrentElementAnnotationsTab: PropTypes.func.isRequired,
};
export default ElementStatus;
