import ApiModel from 'models/api-model';
import { JOB_TYPES } from 'constants/common/job-type-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import { USER_ROLE_OPTIONS_MAP } from 'constants/common/job-type-constants';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import {
  getMatDateFormat,
  NON_AUDIT_REASON_MAP,
} from 'constants/feature/project-creation-constants';
import { DATE_FORMATS } from 'constants/util/date-constants';
import moment from 'moment';
import Statement from 'models/data/statement-model';
import cloneDeep from 'lodash.clonedeep';

export const RESERVED_CLIENT_NAME = 'Tie out support';
export default class ProjectForm extends ApiModel({
  data: {
    active: null,
    adminGroupAddDate: null,
    coeGroupAddDate: null,
    adminGroupEnabledByUserName: null,
    techSupportAccess: false,
    supportId: null,
    resetSupportId: null,
    enabledTechSupportStatementList: [],
    resetEnabledTechSupportStatementList: [],
    coeGroupEnabledByUserName: null,
    cik: null,
    deleteDate: null,
    engagementEntity: null,
    hasAdminGroup: false,
    hasCoeGroup: false,
    id: null,
    geoCode: null,
    isNewClient: true,
    legalHold: false,
    name: '',
    symbol: null,
    sic: null,
    type: null,
    userEntities: [], // not of type [User] because we need the array to be of type MenuOption because this fills a dropdown
    auditClient: true,
    wrappedUpStatements: [],
    deletedStatements: [],
    activeStatements: [],
    failedStatements: [],
    containerCode: null,
    countryCode: null,
    countryOfIssuanceDropdownValue: null,
    statementsListForTechSupportOptions: [],
    resetStatementsListForTechSupportOptions: [],
  },
  isAuditClient: -1,
  /**
   * This is used for the permissions setup on toggling support team access when editing projects. We need to know if it has been
   * toggled as we want users to be able to toggle the support team access after they change it once for a better UX.
   */
  adminGroupHasBeenModified: false,
  techSupportAccessHasBeenModified: false,
  /**
   * This is used for the permissions setup on toggling coe team access when editing projects. We need to know if it has been
   * toggled as we want users to be able to toggle the coe team access after they change it once for a better UX.
   */
  coeGroupHasBeenModified: false,
  /**
   * This is used for the permissions setup on toggling legal hold when editing projects. We need to know if it has been
   * toggled as we want users to be able to toggle legal hold after they change it once for a better UX.
   */
  legalHoldHasBeenModified: false,
  clientNameEdited: false,
  nonAuditEngagementEntityDataHasBeenModified: false,
}) {
  get engagementEntity() {
    return this.data.engagementEntity;
  }
  get name() {
    return this.data.name;
  }
  get legalHold() {
    return this.data.legalHold;
  }
  get hasAdminGroup() {
    return this.data.hasAdminGroup;
  }
  get techSupportAccess() {
    return this.data.techSupportAccess;
  }
  get supportId() {
    return this.data.supportId;
  }
  get enabledTechSupportStatementList() {
    return this.data.enabledTechSupportStatementList;
  }
  get statementsListForTechSupportOptions() {
    return this.data.statementsListForTechSupportOptions;
  }
  get hasCoeGroup() {
    return this.data.hasCoeGroup;
  }
  get userEntities() {
    if (this.data && this.data.userEntities) {
      return this.data.userEntities;
    }
    return [];
  }
  get id() {
    return this.data.id;
  }
  get engagementFye() {
    if (this.data && this.data.engagementEntity) {
      return this.data.engagementEntity.engagementFye;
    }
    return null;
  }
  get nonAuditReason() {
    if (this.data && this.data.engagementEntity) {
      return this.data.engagementEntity.nonAuditReason;
    }
    return null;
  }
  get auditClient() {
    return this.data.auditClient;
  }
  get clientName() {
    if (this.data && this.data.engagementEntity) {
      return this.data.engagementEntity.clientName;
    }
    return null;
  }
  get engagementName() {
    if (this.data && this.data.engagementEntity) {
      return this.data.engagementEntity.engagementName;
    }
    return null;
  }
  get adminGroupAddDate() {
    if (this.data && this.data.adminGroupAddDate) {
      return moment
        .utc(this.data.adminGroupAddDate)
        .format(DATE_FORMATS.MONTH_DAY_YEAR);
    }
    return null;
  }
  get coeGroupAddDate() {
    if (this.data && this.data.coeGroupAddDate) {
      return moment
        .utc(this.data.coeGroupAddDate)
        .format(DATE_FORMATS.MONTH_DAY_YEAR);
    }
    return null;
  }

  get adminGroupEnabledByUserName() {
    if (this.data && this.data.adminGroupEnabledByUserName) {
      return this.data.adminGroupEnabledByUserName;
    }
    return null;
  }

  get coeGroupEnabledByUserName() {
    if (this.data && this.data.coeGroupEnabledByUserName) {
      return this.data.coeGroupEnabledByUserName;
    }
    return null;
  }

  get activeStatements() {
    return this.data.activeStatements;
  }
  get failedStatements() {
    return this.data.failedStatements;
  }
  get wrappedUpStatements() {
    return this.data.wrappedUpStatements;
  }
  get deletedStatements() {
    return this.data.deletedStatements;
  }

  get containerCode() {
    return this.data.containerCode;
  }

  get countryOfIssuanceDropdownValue() {
    return this.data.countryOfIssuanceDropdownValue;
  }

  get countryCode() {
    return this.data.countryCode;
  }

  getContainerCodeDropdownValue(countryOfIssuanceDropdownValue) {
    const [containerCode, countryCode] =
      countryOfIssuanceDropdownValue.value.split('-');
    return { countryCode, containerCode };
  }

  toApiFormat() {
    let tickerSymbol = 'N/A';
    if (
      !isNullOrUndefined(this.data.engagementEntity) &&
      !isNullOrUndefined(this.data.engagementEntity.tickerSymbol) &&
      this.data.engagementEntity.tickerSymbol !== 'NULL'
    ) {
      tickerSymbol = this.data.engagementEntity.tickerSymbol;
    }
    if (this.data) {
      const { countryCode, containerCode } = this.getContainerCodeDropdownValue(
        this.data.countryOfIssuanceDropdownValue,
      );
      return {
        ...this.data,
        id: this.data.id ? parseInt(this.data.id) : null,
        legalHold: this.data.legalHold ? 1 : 0,
        userEntities: this.data.userEntities.map((user) => ({
          ...user,
          jobType: this.getDropdownValue(user.jobType),
        })),
        symbol: tickerSymbol,
        engagementEntity: {
          ...this.data.engagementEntity,
          engagementFye:
            typeof this.engagementEntity.engagementFye === 'object'
              ? this.getDropdownValue(this.data.engagementEntity.engagementFye)
              : this.data.engagementEntity.engagementFye,
          nonAuditReason: this.data.engagementEntity.nonAuditReason
            ? this.getDropdownValue(this.data.engagementEntity.nonAuditReason)
            : null,
        },
        supportId: this.data.techSupportAccess ? this.data.supportId : null,
        disabledTechSupportStatements: this.getDisabledStatementsByResponse(
          this.data,
        ),
        enabledTechSupportStatements:
          this.data.techSupportAccess && this.techSupportAccessHasBeenModified
            ? this.data.enabledTechSupportStatementList.map((item) => item.id)
            : [],
        isAuditClient: this.isAuditClient,
        activeStatements: this.activeStatements,
        wrappedUpStatements: this.wrappedUpStatements,
        deletedStatements: this.deletedStatements,
        failedStatements: this.failedStatements,
        containerCode: containerCode ? containerCode : null,
        countryCode: countryCode ? countryCode : null,
      };
    }
  }

  // returns the isSelected statement id's which was previously enabled for tech support access
  getDisabledStatementsByResponse(statementData) {
    const techSupportData = this.getTechSupportDropdownData(statementData);

    const disabledIds = techSupportData
      .filter((item) => item.isSelected && !item.isLabel)
      .map((item) => item.id);

    const enabledIds = statementData.enabledTechSupportStatementList.map(
      (item) => item.id,
    );

    return enabledIds.length
      ? disabledIds.filter((element) => !enabledIds.includes(element))
      : disabledIds;
  }

  isInitialized() {
    return !isNullOrUndefined(this.data.id);
  }

  getProjectData() {
    if (this.data) {
      return this.data;
    }
  }

  get geoCode() {
    return this.data.geoCode;
  }

  processResponse({ response }) {
    //this if statement is necessary because of the the inconsistent returned api data structure
    if (response && response.data && !response.data.result) {
      const fiscalYear =
        response &&
        response.data &&
        response.data.engagementEntity &&
        moment(response.data.engagementEntity.engagementFye).format(
          DATE_FORMATS.YEAR,
        );
      // tech/developer access dropdown options.
      const techSupportDropdownOptions = this.getTechSupportDropdownData(
        response.data,
      );
      // filtering statements list which already enabled for tech/developer access
      const alreadyTechSupportEnabledStatementsData =
        this.getEnabledTechSupportStatementByResponse(
          techSupportDropdownOptions,
        );
      // getting supportId from first tech/developer access enabled statement
      const supportIdFromTechSupportEnabledStatement =
        this.getSupportIdFromStatementLevel(
          alreadyTechSupportEnabledStatementsData,
          'supportId',
        );
      return {
        data: {
          ...response.data,
          id: `${parseInt(response.data.id)}-${response.data.geoCode}`,
          userEntities: response.data.userEntities.map((user) => ({
            ...user,
            jobType: USER_ROLE_OPTIONS_MAP[user.jobType],
          })),
          legalHold: response.data.legalHold === 0 ? false : true,
          hasCoeGroup: response.data.hasCoeGroup ? true : false, // If undefined or null, then it will be set to false.
          wrappedUpStatements: response.data.wrappedUpStatements
            ? response.data.wrappedUpStatements.map(
                (statement) => new Statement(statement),
              )
            : [],
          deletedStatements: response.data.deletedStatements
            ? response.data.deletedStatements.map(
                (statement) => new Statement(statement),
              )
            : [],
          activeStatements: response.data.activeStatements
            ? response.data.activeStatements.map(
                (statement) => new Statement(statement),
              )
            : [],
          failedStatements: response.data.failedStatements
            ? response.data.failedStatements.map(
                (statement) => new Statement(statement),
              )
            : [],
          engagementEntity: {
            ...response.data.engagementEntity,
            nonAuditReason: NON_AUDIT_REASON_MAP.get(
              response.data.engagementEntity.nonAuditReason,
            ),
            engagementFye: response.data.auditClient
              ? response.data.engagementEntity.engagementFye
              : new MenuOption({
                  id: fiscalYear,
                  title: fiscalYear,
                  value: getMatDateFormat(fiscalYear),
                  isIntl: false,
                }),
          },
          supportId: supportIdFromTechSupportEnabledStatement,
          statementsListForTechSupportOptions: techSupportDropdownOptions, // getting the list of statements that are available accross the different status
          resetStatementsListForTechSupportOptions: techSupportDropdownOptions,
          enabledTechSupportStatementList:
            alreadyTechSupportEnabledStatementsData, // getting the list of tech support enabled statements that are available accross the different status
          resetSupportId: response.data.techSupportAccess
            ? supportIdFromTechSupportEnabledStatement
            : null,
          resetEnabledTechSupportStatementList:
            response.data.techSupportAccess &&
            alreadyTechSupportEnabledStatementsData
              ? alreadyTechSupportEnabledStatementsData
              : [], // used to reset the modified tech support statements list when user clicked on cancel or close model.
        },
        nonAuditEngagementEntityDataHasBeenModified: false,
        status: response.status,
      };
    } else {
      return {
        status: response.status,
      };
    }
  }

  resetEnabledTechSupportStatementListData() {
    return this.merge({
      data: {
        ...this.data,
        enabledTechSupportStatementList:
          this.data.resetEnabledTechSupportStatementList,
        supportId: this.data.resetSupportId,
        statementsListForTechSupportOptions:
          this.data.resetStatementsListForTechSupportOptions,
      },
      techSupportAccessHasBeenModified: false,
    });
  }

  getSupportIdFromStatementLevel(data, key) {
    // Check if array is empty
    if (!data.length) {
      return null;
    }

    // Extract first supportId value if any developer access enabled statement
    return data.filter((obj) => obj[key])[0][key];
  }

  // return an array by merge all the list of statements(having different status) for statementList dropdown in developer support modal dropdown
  getTechSupportDropdownData(statementData) {
    return [
      ...this.transformArray(
        statementData.activeStatements
          ? statementData.activeStatements.map(
              (statement) => new Statement(statement),
            )
          : [],
        'project-edit.manage-legal-hold-active-card.title',
        1,
      ),
      ...this.transformArray(
        statementData.wrappedUpStatements
          ? statementData.wrappedUpStatements.map(
              (statement) => new Statement(statement),
            )
          : [],
        'project-edit.manage-legal-hold-wrapped-card.title',
        2,
      ),
      ...this.transformArray(
        statementData.deletedStatements
          ? statementData.deletedStatements.map(
              (statement) => new Statement(statement),
            )
          : [],
        'project-edit.manage-legal-hold-deleted-card.title',
        3,
      ),
      ...this.transformArray(
        statementData.failedStatements
          ? statementData.failedStatements.map(
              (statement) => new Statement(statement),
            )
          : [],
        'project-edit.manage-legal-hold-failed-card.title',
        3,
      ),
    ];
  }

  // returns an array from the all statements by tech support is enabled which was based on techsupportaccess from individual statement
  getEnabledTechSupportStatementByResponse(statementData) {
    return statementData.filter((item) => item.isSelected);
  }

  // returns an array by category (generic method to get based on category attribute)
  transformArray = (arr, category, id) => {
    if (arr.length) {
      let newArr = arr.map((item) => ({
        id: item.id,
        title: item.statementName,
        value: item.id,
        isCheckboxRequired: true,
        isSelected: item.techSupportAccess,
        supportId: item.supportId,
        isIntl: false,
      }));
      newArr.unshift({
        id: id,
        title: category,
        value: category,
        isIntl: true,
        isLabel: true,
      });

      return newArr;
    }
    return [];
  };

  /**
   * Initializes the first user added to a project as the current user and sets their role as engagement owner
   * @param {CurrentUser} currentUser
   */
  initCurrentUser(currentUser) {
    return this.merge({
      data: {
        ...this.data,
        userEntities: [
          {
            ...currentUser,
            jobType: USER_ROLE_OPTIONS_MAP[JOB_TYPES.ENGAGEMENT_OWNER],
          },
        ],
      },
    });
  }

  addUser(user) {
    const _userAlreadyAdded = this.data.userEntities.find(
      (alreadyAddedUser) => alreadyAddedUser.id === user.id,
    );
    if (_userAlreadyAdded) {
      // ensure no duplicate users are added
      return this;
    }
    return this.merge({
      data: { ...this.data, userEntities: [user, ...this.data.userEntities] },
    });
  }

  removeUser(userToRemove) {
    const userEntities = this.data.userEntities.filter(
      (user) => userToRemove.id !== user.id,
    );
    return this.merge({
      data: { ...this.data, userEntities },
    });
  }

  /**
   * Assigns the user for given userId the given role on this project
   * @param {string|number} param0.userId id of user to assign role to
   * @param {string} param0.role role value to assign to user. should be one of the JOB_TYPES constants
   */
  assignRoleToUser({ userId, role }) {
    const userEntities = this.data.userEntities.map((curUser) => {
      if (curUser.id === userId) {
        return {
          ...curUser,
          jobType: role,
        };
      }
      return curUser;
    });

    return this.merge({
      data: {
        ...this.data,
        userEntities,
      },
    });
  }
  setName(name) {
    return this.merge({
      data: { ...this.data, name },
    });
  }

  setEngagementEntity(engagementEntity) {
    return this.merge({
      isAuditClient: 1,
      data: { ...this.data, engagementEntity },
    });
  }

  setLegalHold(legalHold) {
    return this.merge({
      data: { ...this.data, legalHold },
      legalHoldHasBeenModified: true,
    });
  }
  setActiveStatements(activeStatements) {
    return this.merge({
      data: {
        ...this.data,
        activeStatements,
      },
    });
  }
  setWrappedUpStatements(wrappedUpStatements) {
    return this.merge({
      data: {
        ...this.data,
        wrappedUpStatements,
      },
    });
  }
  setDeletedStatements(deletedStatements) {
    return this.merge({
      data: {
        ...this.data,
        deletedStatements,
      },
    });
  }
  containsUser(compareUser) {
    return this.userEntities.find((user) => user.id === compareUser.id);
  }

  isEngagementOwnerUserAdded() {
    return !isNullOrUndefined(
      this.data.userEntities.find(
        (user) =>
          user.jobType && user.jobType.value === JOB_TYPES.ENGAGEMENT_OWNER,
      ),
    );
  }
  isPpmdUserAdded() {
    return !isNullOrUndefined(
      this.data.userEntities.find(
        (user) => user.jobType && user.jobType.value === JOB_TYPES.PPMD,
      ),
    );
  }

  /**
   * Ensures all users added to project are assigned roles and PPMD and Engagement Owner are also added
   */
  _allUsersHaveAppropriateRoles() {
    const roleSet = new Set();
    let allUsersHaveRoles = true;
    for (let user of this.data.userEntities) {
      const userHasRole = user.jobType && user.jobType.value;
      if (!userHasRole) {
        allUsersHaveRoles = false;
        break;
      }
      roleSet.add(user.jobType.value);
    }
    return (
      allUsersHaveRoles &&
      roleSet.has(JOB_TYPES.PPMD) &&
      roleSet.has(JOB_TYPES.ENGAGEMENT_OWNER)
    );
  }

  toggleHasAdminGroup() {
    return this.merge({
      data: { ...this.data, hasAdminGroup: !this.data.hasAdminGroup },
      adminGroupHasBeenModified: true,
    });
  }

  // updated the techsupport access boolean value
  toggleTechSupportAccess() {
    return this.merge({
      data: {
        ...this.data,
        techSupportAccess:
          this.data.enabledTechSupportStatementList.length &&
          this.data.supportId
            ? true
            : false,
      },
      techSupportAccessHasBeenModified: true,
    });
  }

  disableTechSupportAccess() {
    const modifyStatementsListForTechSupport =
      this.data.statementsListForTechSupportOptions.map((item) => {
        return { ...item, isSelected: false };
      });
    return this.merge({
      data: {
        ...this.data,
        techSupportAccess: false,
        supportId: null,
        enabledTechSupportStatementList: [],
        statementsListForTechSupportOptions: modifyStatementsListForTechSupport,
      },
    });
  }

  //sets the support id for tech support access
  setSupportId(value) {
    return this.merge({
      data: { ...this.data, supportId: value || null },
    });
  }

  // return an array by filtering the tech support enabled statments
  getEnabledTechSupportStatementById(id) {
    return this.data.enabledTechSupportStatementList.filter(
      (item) => item.id === id,
    );
  }

  // add selected statement to enable tech support and if statement already exists then it ignores in the list
  setEnabledTechSupportStatementList(statement) {
    const isStatementExist = this.getEnabledTechSupportStatementById(
      statement.id,
    ).length;
    return this.merge({
      data: {
        ...this.data,
        enabledTechSupportStatementList:
          isStatementExist === 0
            ? this.data.enabledTechSupportStatementList.concat(statement)
            : this.data.enabledTechSupportStatementList.filter(
                (item) => item.id !== statement.id,
              ),
        statementsListForTechSupportOptions:
          this.modifySelectableTechSupportStatements(statement, true),
      },
    });
  }

  // remove the statement data from already enabled tech support statements data
  removeSelectedTechSupportStatement(statement) {
    return this.merge({
      data: {
        ...this.data,
        enabledTechSupportStatementList:
          this.data.enabledTechSupportStatementList.filter(
            (item) => item.id !== statement.id,
          ),
        statementsListForTechSupportOptions:
          this.modifySelectableTechSupportStatements(statement, false),
      },
    });
  }

  modifySelectableTechSupportStatements(statement, isSelection) {
    return this.data.statementsListForTechSupportOptions.map((item) => {
      return item.id === statement.id
        ? { ...item, isSelected: isSelection ? !item.isSelected : false }
        : item;
    });
  }

  toggleHasCoeGroup() {
    return this.merge({
      data: { ...this.data, hasCoeGroup: !this.data.hasCoeGroup },
      coeGroupHasBeenModified: true,
    });
  }

  hasAdminGroupBeenModified() {
    return this.adminGroupHasBeenModified;
  }
  hasTechSupportAccessHasBeenModified() {
    return this.techSupportAccessHasBeenModified;
  }

  hasCoeGroupBeenModified() {
    return this.coeGroupHasBeenModified;
  }

  hasLegalHoldBeenModified() {
    return this.legalHoldHasBeenModified;
  }

  isStepOneValid() {
    const {
      name,
      engagementEntity,
      auditClient,
      countryOfIssuanceDropdownValue,
    } = this.data;
    if (
      auditClient &&
      name &&
      engagementEntity &&
      engagementEntity.clientName &&
      engagementEntity.engagementFye &&
      engagementEntity.engagementName &&
      countryOfIssuanceDropdownValue &&
      // for invalid data user is going to see empty dropdown
      countryOfIssuanceDropdownValue.title
    ) {
      /** Disable save if engagementFye contains an obj. In this scenario project is audit client and engagementEntity contains obj of non audit: In this case let the user reset the values by performing another search */
      if (engagementEntity.engagementFye instanceof MenuOption) {
        return false;
      }
      return true;
    }
    if (
      !auditClient &&
      name &&
      engagementEntity &&
      engagementEntity.clientName &&
      engagementEntity.engagementName &&
      engagementEntity.engagementFye &&
      engagementEntity.nonAuditReason &&
      countryOfIssuanceDropdownValue
    ) {
      return true;
    }
    return false;
  }

  isStepTwoValid() {
    return this._allUsersHaveAppropriateRoles();
  }

  isProjectValid() {
    return this.isStepOneValid() && this.isStepTwoValid();
  }

  isClientNameValid() {
    if (this.engagementEntity && this.engagementEntity.clientName) {
      return (
        this.engagementEntity.clientName
          .toLowerCase()
          .indexOf(RESERVED_CLIENT_NAME.toLowerCase()) < 0
      );
    }
    return true;
  }

  initNotAuditClient(checked) {
    const currentYear = new Date().getFullYear();

    const engagementFyeClone = cloneDeep(this.data.engagementEntity);

    return this.merge({
      isAuditClient: checked ? 0 : -1,
      data: {
        ...this.data,
        auditClient: !this.data.auditClient,
        engagementEntity: {
          ...this.data.engagementEntity,
          engagementFye:
            this.data.isNewClient || !engagementFyeClone
              ? new MenuOption({
                  id: currentYear,
                  value: getMatDateFormat(currentYear),
                  title: currentYear,
                  isIntl: false,
                })
              : new MenuOption({
                  id: this.data.engagementEntity.id,
                  value:
                    typeof engagementFyeClone.engagementFye === 'string'
                      ? engagementFyeClone.engagementFye
                      : this.getDropdownValue(engagementFyeClone.engagementFye),
                  title: moment(
                    this.data.engagementEntity.engagementFye,
                  ).format(DATE_FORMATS.YEAR),
                  isIntl: false,
                }),
        },
      },
    });
  }

  setClientName(clientName) {
    return this.merge({
      isAuditClient: 0,
      clientNameEdited: true,
      data: {
        ...this.data,
        engagementEntity: { ...this.data.engagementEntity, clientName },
      },
    });
  }

  setEngagementName(engagementName) {
    return this.merge({
      isAuditClient: 0,
      data: {
        ...this.data,
        engagementEntity: { ...this.data.engagementEntity, engagementName },
      },
    });
  }

  setNonAuditEngagementEntityDataHasBeenModified(
    nonAuditEngagementEntityDataHasBeenModified,
  ) {
    return this.merge({
      nonAuditEngagementEntityDataHasBeenModified,
      data: {
        ...this.data,
      },
    });
  }

  setEngagementFye(engagementFye) {
    return this.merge({
      isAuditClient: 0,
      data: {
        ...this.data,
        engagementEntity: { ...this.data.engagementEntity, engagementFye },
      },
    });
  }

  setNonAuditReason(nonAuditReason) {
    return this.merge({
      isAuditClient: 0,
      data: {
        ...this.data,
        engagementEntity: { ...this.data.engagementEntity, nonAuditReason },
      },
    });
  }

  setCountryOfIssuanceDropdownValue(country) {
    return this.merge({
      data: {
        ...this.data,
        countryOfIssuanceDropdownValue: country,
      },
    });
  }
}
