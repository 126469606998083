import ApiModel from 'models/api-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import WorkpaperForm from '../form/workpaper-form-model';
import { isNullOrUndefined } from '../../utils/object-utils';

export class WorkpaperReferenceList extends ApiModel({
  data: {
    workpapers: [],
  },
}) {
  get workpapers() {
    return this.data.workpapers;
  }

  processResponse({ response }) {
    return {
      data: {
        workpapers: response.data.result,
      },
      status: response.status,
    };
  }

  getSelectedWorkpaper(id) {
    const filteredWorkpapers = this.data.workpapers.find(
      (workpaper) => workpaper.workpaperRefId === id,
    );
    return new WorkpaperForm({
      data: {
        revisionId: filteredWorkpapers.revisionId,
        elementId: filteredWorkpapers.elementId,
        referenceNumber: filteredWorkpapers.referenceNumber,
        referenceName: filteredWorkpapers.referenceName,
        workpaperRefId: filteredWorkpapers.workpaperRefId,
        workpaperRefCreatedBy: filteredWorkpapers.workpaperRefCreatedBy,
        workpaperRefCreatedDate: filteredWorkpapers.workpaperRefCreatedDate,
        creationType: filteredWorkpapers.creationType,
      },
    });
  }

  getWorkpaperDropdownValues({ alreadyAddedWorkpaperIds }) {
    let workpaperDropdownOptions = {};
    this.data.workpapers.forEach((workpaper) => {
      workpaperDropdownOptions[workpaper.workpaperRefId] = new MenuOption({
        id: workpaper.workpaperRefId,
        title: workpaper.referenceNumber,
        value: workpaper.referenceNumber,
        isIntl: false,
        disabled: !isNullOrUndefined(
          alreadyAddedWorkpaperIds.find(
            (id) => id === workpaper.workpaperRefId,
          ),
        ),
      });
    });

    return Object.values(workpaperDropdownOptions);
  }

  getFilteredWorkpaperRefs(term) {
    return this.data.workpapers.filter(
      (workpaper) =>
        workpaper.referenceNumber.toLowerCase().indexOf(term.toLowerCase()) !==
        -1,
    );
  }
}
