import React from 'react';
import { createAction } from 'redux-actions';
import {
  sofDeleteCurrentRevisionRequest,
  softDeleteAllRevisionsRequest,
  permanentlyDeleteRevisionRequest,
  permanentlyDeleteAllSoftDeletedRevisionsForStatementRequest,
  getStatementListRequest,
  exportWorkpaperStatementRequest,
  exportElementReportRequest,
  exportAuditTrailRequest,
  exportComfortLetterReportRequest,
  exportClientNotesRequest,
  copyStatementRequest,
  exportCustomReportRequest,
  exportWorkpaperStatementRequestFallback,
  exportElementReportRequestFallback,
  exportAuditTrailRequestFallback,
  exportClientNotesRequestFallback,
  exportCustomReportRequestFallback,
  exportComfortLetterReportRequestFallback,
} from 'api/statement-list-api';
import {
  uploadRevisionRequest,
  editStatementRequest,
} from 'api/statement-creation-edit-api';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import { push } from 'connected-react-router';
import {
  STATEMENT_LIST_TABS,
  STATEMENT_LIST_SORT_COLUMN_KEYS,
} from 'constants/feature/statement-list-constants';
import {
  setProjectIdWorkflowExpanded,
  getStatementWorkflowsWithoutLoading,
} from 'store/actions/statement-workflows-actions';
import {
  setCurrentStatementListTab,
  setStatementListSort,
  initStatementListFilters,
} from 'store/actions/statement-list/statement-list-filters-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import { DataGridSort } from 'components/common/data-grid/data-grid-component';
import {
  EXPORT_PAGE_ORIENTATION_PORTRAIT,
  EXPORT_PAGE_SIZE_ID,
} from 'constants/feature/statement-content-constants';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Information } from 'icons/info.svg';

import Notification from 'components/common/notification-component';
import { ReactComponent as CloseButton } from 'icons/close-button-red.svg';
import {
  downloadReportNotification,
  NOTIFICATION_TYPE,
} from 'utils/statement-content-page-utils';
import { reportProcessingRequestAction } from '../toolkit-export-panel-actions';
import { downloadFile } from '../../../utils/statement-export-utils';
import { NOT_ACCEPTABLE } from 'http-status-codes';

export const REPORT_TYPES = {
  WORKPAPER: 'Workpaper statement',
  ELEMENT_REPORT: 'Element',
  CLIENT_NOTES: 'Client notes',
  BUILD_CUSTOMIZED_REPORT: 'Custom',
  AUDIT_TRAIL_REPORT: 'Audit',
  COMFORT_LETTER_REPORT: 'Comfort letter',
};

const AUDIT_TRAIL_REPORT = `${REPORT_TYPES.AUDIT_TRAIL_REPORT}  Trail}`;

export const ERROR_TEXT_FOR_COPY_FAILURE_DUE_TO_WRONG_SOURCE_CLIENT =
  'tieout source project can only copy statement to the project where it came from';

export const _isCopyErrorForWrongSourceProject = (rootCause) =>
  rootCause.includes(ERROR_TEXT_FOR_COPY_FAILURE_DUE_TO_WRONG_SOURCE_CLIENT);

export const copyErrorNotificationForWrongSourceProject = () => (
  <Notification
    icon={<CloseButton />}
    message={{
      id: 'notifications.source-statement.copy-error.message',
    }}
    title={{ id: 'notifications.copy-statement.error.title' }}
  />
);

export const clearStatementList = createAction('CLEAR_STATEMENT_LIST');
export const statementListLoading = createAction('STATEMENT_LIST_LOADING');
export const statementListError = createAction('STATEMENT_LIST_ERROR');
export const statementListLoaded = createAction('STATEMENT_LIST_LOADED');
export const softDeleteCurrentRevisionError = createAction(
  'DELETE_CURRENT_REVISION_ERROR',
);
export const softDeleteAllRevisionsError = createAction(
  'DELETE_ALL_REVISIONS_ERROR',
);
export const permanentlyDeleteCurrentRevisionError = createAction(
  'PERMANENTLY_DELETE_CURRENT_REVISION_ERROR',
);
export const permanentlyDeleteAllRevisionsError = createAction(
  'PERMANENTLY_DELETE_ALL_REVISIONS_ERROR',
);
export const restoreDeletedRevisionError = createAction(
  'RESTORE_DELETED_REVISION_ERROR',
);
export const navigateToStatementError = createAction(
  'NAVIGATE_TO_STATEMENT_ERROR',
);
export const setUploadedFilesAddRevision = createAction(
  'STATEMENT_LIST_SET_UPLOADED_FILES_ADD_REVISION',
);
export const setUploadedFilesAddRevisionError = createAction(
  'STATEMENT_LIST_SET_UPLOADED_FILES_ADD_REVISION_ERROR',
);
export const clearAddRevision = createAction(
  'CLEAR_ADD_REVISION_STATEMENT_LIST',
);
export const addRevisionLoading = createAction(
  'ADD_REVISION_STATEMENT_LIST_LOADING',
);
export const addRevisionLoaded = createAction(
  'ADD_REVISION_STATEMENT_LIST_LOADED',
);
export const addRevisionError = createAction(
  'ADD_REVISION_STATEMENT_LIST_ERROR',
);
export const exportWorkpaperError = createAction(
  'EXPORT_WORKPAPER_STATEMENT_LIST_ERROR',
);
export const exportElementReportError = createAction(
  'EXPORT_ELEMENT_REPORT_ERROR',
);
export const exportAuditTrailError = createAction('EXPORT_AUDIT_TRAIL_ERROR');

export const exportComfortLetterReportError = createAction(
  'EXPORT_COMFORT_LETTER_REPORT_ERROR',
);

export const exportClientNotesError = createAction('EXPORT_CLIENT_NOTES_ERROR');

export const copyStatementError = createAction('COPY_STATEMENT_ERROR');

export const exportCustomReportError = createAction(
  'EXPORT_CUSTOM_REPORT_ERROR',
);

const STATEMENT_COPY_BLOCK = 'statement-page';
const BANNER_ICON_SIZE = '18px';
/**
 * `withLoading` is so we can update the active list of statements while polling
 * for workflow changes without a loading animation appearing on the active statement
 * tab. Once we migrate to an event/socket based system of notifying the front-end of
 * statement changes we can either update or retire this workaround
 *
 * TODO: Update this once sockets are introduced for events on the front-end.
 * */
export const requestGetStatementList =
  ({ withLoading = true, contextKey } = { withLoading: true }) =>
  async (dispatch, getState) => {
    if (withLoading) {
      dispatch(statementListLoading());
    }
    try {
      const stateData = getState().data;
      const selectedProject = stateData.selectedProject.project;
      const { sort, selectedTab } =
        stateData.statementList.statementListFilters;
      const isAuditClient = selectedProject.data.isAuditClient;
      const response =
        selectedProject && selectedProject.id
          ? await getStatementListRequest({
              status: selectedTab.apiParam,
              projectId: selectedProject.id,
              sort: sort.key,
              order: sort.order,
              //first and size are going to let us get an specific amount of statements, pagination.
              first: 0,
              // temporarily we are going to set the max amount of statements as 1000
              size: 1000,
              isAuditClient,
              contextKey,
              selectedProject,
            })
          : { data: { result: { elements: [] } } };

      dispatch(statementListLoaded({ response }));
    } catch (error) {
      dispatch(statementListError(error));
    }
  };

export const requestStatementListWithParams =
  ({ tab, sort, withLoading }) =>
  async (dispatch, getState) => {
    if (!isNullOrUndefined(tab)) {
      await dispatch(setCurrentStatementListTab(tab));
      await dispatch(_rectifySortForChangingTabs(tab));
    }
    if (!isNullOrUndefined(sort)) {
      await dispatch(setStatementListSort(sort));
    }
    dispatch(requestGetStatementList({ withLoading }));
  };

export const requestStatementListForNewProject = () => async (dispatch) => {
  await dispatch(initStatementListFilters());
  dispatch(
    requestGetStatementList({
      withLoading: true,
    }),
  );
};

const _rectifySortForChangingTabs = (newTab) => async (dispatch, getState) => {
  const currentSort = getState().data.statementList.statementListFilters.sort;
  /** lastModifiedDate and revisionDeleteDate are to be treated the same when changing tabs */
  const _newTabDeletedAndSortIsLastModified =
    newTab.id === STATEMENT_LIST_TABS.DELETED.id &&
    currentSort.key === STATEMENT_LIST_SORT_COLUMN_KEYS.lastModifiedDate;
  const _newTabActiveAndSortIsDeletedDate =
    newTab.id === STATEMENT_LIST_TABS.ACTIVE.id &&
    currentSort.key === STATEMENT_LIST_SORT_COLUMN_KEYS.revisionDeletedDate;

  if (_newTabDeletedAndSortIsLastModified) {
    await dispatch(
      // must be awaited so filters are changed before fetch
      setStatementListSort(
        new DataGridSort({
          order: currentSort.order,
          key: STATEMENT_LIST_SORT_COLUMN_KEYS.revisionDeletedDate,
        }),
      ),
    );
  } else if (_newTabActiveAndSortIsDeletedDate) {
    await dispatch(
      // must be awaited so filters are changed before fetch
      setStatementListSort(
        new DataGridSort({
          order: currentSort.order,
          key: STATEMENT_LIST_SORT_COLUMN_KEYS.lastModifiedDate,
        }),
      ),
    );
  }
};

export const softDeleteCurrentRevision =
  ({ statementId, revisionId }) =>
  async (dispatch) => {
    try {
      await sofDeleteCurrentRevisionRequest({ statementId, revisionId });
      dispatch(
        requestStatementListWithParams({ tab: STATEMENT_LIST_TABS.DELETED }),
      );
    } catch (error) {
      dispatch(softDeleteCurrentRevisionError(error));
    }
  };

export const softDeleteAllRevisions = (statementId) => async (dispatch) => {
  try {
    await softDeleteAllRevisionsRequest(statementId);
    dispatch(
      requestStatementListWithParams({ tab: STATEMENT_LIST_TABS.DELETED }),
    );
  } catch (error) {
    dispatch(softDeleteAllRevisionsError(error));
  }
};

export const permanentlyDeleteAllSoftDeletedRevisionsForStatement =
  (statementId) => async (dispatch) => {
    try {
      await permanentlyDeleteAllSoftDeletedRevisionsForStatementRequest(
        statementId,
      );
      dispatch(
        requestStatementListWithParams({ tab: STATEMENT_LIST_TABS.DELETED }),
      );
    } catch (error) {
      dispatch(permanentlyDeleteCurrentRevisionError(error));
    }
  };

export const permanentlyDeleteRevision =
  (revisionId, statementId) => async (dispatch) => {
    try {
      await permanentlyDeleteRevisionRequest(revisionId, statementId);
    } catch (error) {
      dispatch(permanentlyDeleteCurrentRevisionError(error));
    }
  };

export const permanentlyDeleteAllRevisions =
  (statementId) => async (dispatch) => {
    try {
      await softDeleteAllRevisionsRequest(statementId);
      dispatch(
        requestStatementListWithParams({ tab: STATEMENT_LIST_TABS.DELETED }),
      );
    } catch (error) {
      dispatch(permanentlyDeleteAllRevisionsError(error));
    }
  };

export const navigateToStatement =
  (statement) => async (dispatch, getState) => {
    try {
      const selectedTab =
        getState().data.statementList.statementListFilters.selectedTab;
      const isNotReadOnlyStatement =
        !statement.isInArchivalFlow() &&
        !statement.isSoftDeleted() &&
        statement.latestRevision === statement.revisionId;
      if (
        selectedTab === STATEMENT_LIST_TABS.ACTIVE &&
        !statement.hasBeenOpenedByUser &&
        isNotReadOnlyStatement
      ) {
        // only update read status when viewed from active tab
        const readStatement = statement.setHasBeenOpenedByUser(true);
        // Send an edit statement request to update this statement's read status.
        await editStatementRequest(readStatement);
      }
      // Navigate to the statement content page
      await dispatch(
        push(
          parseRoute(ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE, {
            params: {
              projectId: statement.clientId,
              statementId: statement.id,
              revisionId: statement.revisionId,
              readOnly: false,
            },
          }),
        ),
      );
    } catch (error) {
      dispatch(navigateToStatementError(error));
    }
  };

export const uploadRevision =
  ({ statement, revision }) =>
  async (dispatch) => {
    dispatch(addRevisionLoading());
    try {
      const response = await uploadRevisionRequest({ statement, revision });
      dispatch(addRevisionLoaded({ response }));
      dispatch(clearAddRevision());
      dispatch(setProjectIdWorkflowExpanded(statement.clientId));
      dispatch(getStatementWorkflowsWithoutLoading());
    } catch (error) {
      dispatch(addRevisionError(error));
    }
  };

export const handleSuccessfulSubmissionForWrapUp =
  () => async (dispatch, getState) => {
    dispatch(requestGetStatementList({ withLoading: true }));
  };

export const exportWorkpaperStatement =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
    options,
  }) =>
  async (dispatch) => {
    try {
      let response = await exportWorkpaperStatementRequest({
        revisionId,
        pageSize,
        pageOrientation,
        options,
      });
      response &&
        response.data &&
        (response.data.reportType = REPORT_TYPES.WORKPAPER);
      dispatch(reportProcessingRequestAction(response));
    } catch (error) {
      if (error.response && error.response.status === NOT_ACCEPTABLE) {
        await dispatch(
          exportWorkpaperStatementFallback({
            revisionId,
            pageSize,
            pageOrientation,
            options,
          }),
        );
      } else {
        downloadReportNotification(
          REPORT_TYPES.WORKPAPER,
          NOTIFICATION_TYPE.FAILURE,
        );
        console.error(error);
      }
    }
  };

export const exportElementReport =
  ({ revisionId }) =>
  async (dispatch) => {
    try {
      let response = await exportElementReportRequest({ revisionId });
      response &&
        response.data &&
        (response.data.reportType = REPORT_TYPES.ELEMENT_REPORT);
      dispatch(reportProcessingRequestAction(response));
    } catch (error) {
      if (error.response && error.response.status === NOT_ACCEPTABLE) {
        await dispatch(exportElementReportFallback({ revisionId }));
      } else {
        downloadReportNotification(
          REPORT_TYPES.ELEMENT_REPORT,
          NOTIFICATION_TYPE.FAILURE,
        );
        console.error(error);
      }
    }
  };

export const exportAuditTrail =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
  }) =>
  async (dispatch) => {
    try {
      let response = await exportAuditTrailRequest({
        revisionId,
        pageSize,
        pageOrientation,
        reportType: 'AuditTrail',
      });
      response &&
        response.data &&
        (response.data.reportType = REPORT_TYPES.AUDIT_TRAIL_REPORT);
      dispatch(reportProcessingRequestAction(response));
    } catch (error) {
      if (error.response && error.response.status === NOT_ACCEPTABLE) {
        await dispatch(
          exportAuditTrailFallback({
            revisionId,
            pageSize,
            pageOrientation,
          }),
        );
      } else {
        downloadReportNotification(
          AUDIT_TRAIL_REPORT,
          NOTIFICATION_TYPE.FAILURE,
        );
        console.error(error);
      }
    }
  };

export const exportComfortLetterReport =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
  }) =>
  async (dispatch) => {
    try {
      let response = await exportComfortLetterReportRequest({
        revisionId,
        pageSize,
        pageOrientation,
        reportType: 'ComfortLetterReport',
      });
      response &&
        response.data &&
        (response.data.reportType = REPORT_TYPES.COMFORT_LETTER_REPORT);
      dispatch(reportProcessingRequestAction(response));
    } catch (error) {
      if (error.response && error.response.status === NOT_ACCEPTABLE) {
        await dispatch(
          exportComfortLetterFallback({
            revisionId,
            pageSize,
            pageOrientation,
          }),
        );
      } else {
        downloadReportNotification(
          REPORT_TYPES.COMFORT_LETTER_REPORT,
          NOTIFICATION_TYPE.FAILURE,
        );
        console.error(error);
      }
    }
  };

export const exportClientNotes =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
  }) =>
  async (dispatch) => {
    try {
      let response = await exportClientNotesRequest({
        revisionId,
        pageSize,
        pageOrientation,
      });
      response &&
        response.data &&
        (response.data.reportType = REPORT_TYPES.CLIENT_NOTES);
      dispatch(reportProcessingRequestAction(response));
    } catch (error) {
      if (error.response && error.response.status === NOT_ACCEPTABLE) {
        dispatch(
          exportClientNotesFallback({
            revisionId,
            pageSize,
            pageOrientation,
          }),
        );
      } else {
        downloadReportNotification(
          REPORT_TYPES.CLIENT_NOTES,
          NOTIFICATION_TYPE.FAILURE,
        );
        console.error(error);
      }
    }
  };

export const exportCustomReport =
  ({ revisionId, pageSize, pageOrientation, exportCustomParam }) =>
  async (dispatch) => {
    try {
      let response = await exportCustomReportRequest({
        revisionId,
        pageSize,
        pageOrientation,
        exportCustomParam,
      });
      response &&
        response.data &&
        (response.data.reportType = REPORT_TYPES.BUILD_CUSTOMIZED_REPORT);
      dispatch(reportProcessingRequestAction(response));
    } catch (error) {
      if (error.response && error.response.status === NOT_ACCEPTABLE) {
        await dispatch(
          exportCustomReportFallback({
            revisionId,
            pageSize,
            pageOrientation,
            exportCustomParam,
          }),
        );
      } else {
        downloadReportNotification(
          REPORT_TYPES.BUILD_CUSTOMIZED_REPORT,
          NOTIFICATION_TYPE.FAILURE,
        );
        console.error(error);
      }
    }
  };

export const onCopyStatementSuccess = () => async (dispatch, getState) => {
  toast.success(
    <>
      <Information
        name={'information'}
        className={`${STATEMENT_COPY_BLOCK}__info_icon`}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <FormattedMessage id="notifications.copy-statement.async.success.title" />
    </>,
    { autoClose: 5000, pauseOnHover: true },
  );
  dispatch(requestGetStatementList());
};

export const onCopyStatementFailure = () => async (dispatch, getState) => {
  toast.error(
    <>
      <Information
        name={'information'}
        className={`${STATEMENT_COPY_BLOCK}__info_icon`}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <FormattedMessage id="notifications.copy-statement.async.error.message" />
    </>,
    { autoClose: 5000, pauseOnHover: true },
  );
  dispatch(requestGetStatementList());
};

export const copyStatement =
  (statementId, targetClientId) => async (dispatch) => {
    try {
      await copyStatementRequest(statementId, targetClientId);
      toast.success(
        <>
          <Information
            name={'information'}
            className={`${STATEMENT_COPY_BLOCK}__info_icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage id="notifications.copy-statement.status-display" />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
    } catch (error) {
      if (
        _isCopyErrorForWrongSourceProject(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.rootCause,
        )
      ) {
        toast.error(copyErrorNotificationForWrongSourceProject());
      } else
        toast.error(
          <Notification
            icon={<CloseButton />}
            message={{ id: 'notifications.copy-statement.error.message' }}
            title={{ id: 'notifications.copy-statement.error.title' }}
          />,
        );
      dispatch(copyStatementError(error));
    }
  };

/**
 * Action methods to download report directly into local.
 * This shall be used when blob storage service is not actively running.
 * This is a kind of fallback approach
 */

export const exportWorkpaperStatementFallback =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
    options,
  }) =>
  async (dispatch) => {
    try {
      const response = await exportWorkpaperStatementRequestFallback({
        revisionId,
        pageSize,
        pageOrientation,
        options,
      });
      downloadFile(response);
    } catch (error) {
      downloadReportNotification(
        REPORT_TYPES.WORKPAPER,
        NOTIFICATION_TYPE.FAILURE,
      );
      console.error(error);
    }
  };

export const exportElementReportFallback =
  ({ revisionId }) =>
  async (dispatch) => {
    try {
      const response = await exportElementReportRequestFallback({ revisionId });
      downloadFile(response);
    } catch (error) {
      downloadReportNotification(
        REPORT_TYPES.ELEMENT_REPORT,
        NOTIFICATION_TYPE.FAILURE,
      );
      console.error(error);
    }
  };

export const exportComfortLetterFallback =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
  }) =>
  async (dispatch) => {
    try {
      const response = await exportComfortLetterReportRequestFallback({
        revisionId,
        pageSize,
        pageOrientation,
        reportType: 'ComfortLetterReport',
      });
      downloadFile(response);
    } catch (error) {
      downloadReportNotification(
        REPORT_TYPES.COMFORT_LETTER_REPORT,
        NOTIFICATION_TYPE.FAILURE,
      );
      console.error(error);
    }
  };

export const exportAuditTrailFallback =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
  }) =>
  async (dispatch) => {
    try {
      const response = await exportAuditTrailRequestFallback({
        revisionId,
        pageSize,
        pageOrientation,
        reportType: 'AuditTrail',
      });
      downloadFile(response);
    } catch (error) {
      downloadReportNotification(AUDIT_TRAIL_REPORT, NOTIFICATION_TYPE.FAILURE);
      console.error(error);
    }
  };

export const exportClientNotesFallback =
  ({
    revisionId,
    pageSize = EXPORT_PAGE_SIZE_ID.LETTER,
    pageOrientation = EXPORT_PAGE_ORIENTATION_PORTRAIT,
  }) =>
  async (dispatch) => {
    try {
      const response = await exportClientNotesRequestFallback({
        revisionId,
        pageSize,
        pageOrientation,
      });
      downloadFile(response);
    } catch (error) {
      downloadReportNotification(
        REPORT_TYPES.CLIENT_NOTES,
        NOTIFICATION_TYPE.FAILURE,
      );
      console.error(error);
    }
  };

export const exportCustomReportFallback =
  ({ revisionId, pageSize, pageOrientation, exportCustomParam }) =>
  async (dispatch) => {
    try {
      const response = await exportCustomReportRequestFallback({
        revisionId,
        pageSize,
        pageOrientation,
        exportCustomParam,
      });
      downloadFile(response);
    } catch (error) {
      downloadReportNotification(
        REPORT_TYPES.BUILD_CUSTOMIZED_REPORT,
        NOTIFICATION_TYPE.FAILURE,
      );
      console.error(error);
    }
  };

/** END of fallback approach for downloading report directly to local */
