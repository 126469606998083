import { handleActions } from 'redux-actions';
import BlacklineCache from 'models/data/blackline-cache-model';
import {
  currentBlacklineViewChangesLoading,
  currentBlacklineViewChangesLoaded,
  currentBlacklineViewChangesError,
  clearCurrentBlacklineContentWithIdsFromCache,
  resetCurrentBlacklineCache,
} from '../../../actions/blackline-view-actions';

export default handleActions(
  {
    [currentBlacklineViewChangesLoading]: (
      currentBlacklineCache,
      { payload },
    ) => {
      return currentBlacklineCache.setSectionLoading(payload);
    },
    [currentBlacklineViewChangesLoaded]: (
      currentBlacklineCache,
      { payload },
    ) => {
      return currentBlacklineCache.setSectionLoaded(payload);
    },
    [currentBlacklineViewChangesError]: (
      currentBlacklineCache,
      { payload },
    ) => {
      return currentBlacklineCache.setSectionError(payload);
    },
    [resetCurrentBlacklineCache]: () => {
      return new BlacklineCache();
    },
    [clearCurrentBlacklineContentWithIdsFromCache]: (
      currentBlacklineCache,
      { payload },
    ) => {
      return currentBlacklineCache.clearSectionBySectionIds(payload);
    },
  },
  new BlacklineCache(),
);
