import BaseModel from 'models/base-model';
import shortid from 'shortid';
import { isNullOrUndefined } from 'utils/object-utils';

export class SideBySideViewElementMap extends BaseModel({
  sourceStatementElementMap: new Map(), // it is having structure of the form ['element-id',{'mapKey','elementDetails'}]
  targetStatementElementMap: new Map(), // it is also having structure of the form ['element-id',{'mapKey','elementDetails'}]
  mapKeys: new Map(), //  [map-key, {sourceElementId:32, targetElementId:32, sequenceNumber:2}],
  // sequenceNumber is the value that says - this is the nth element to get linked

  // elements in source and target having same map key should be considered linked.
}) {
  get sizeOfSourceMapping() {
    return (
      (this.sourceStatementElementMap && this.sourceStatementElementMap.size) ||
      0
    );
  }

  get sizeOfTargetMapping() {
    return (
      (this.targetStatementElementMap && this.targetStatementElementMap.size) ||
      0
    );
  }

  getMapKey(key) {
    return this.mapKeys.get(key);
  }

  get mapKeyOfFirstUnmappedSourceElement() {
    for (const [mapKey, mapValue] of this.mapKeys) {
      if (mapValue && isNullOrUndefined(mapValue.sourceElementId))
        return mapKey;
    }
    return null;
  }

  get mapKeyOfFirstUnmappedTargetElement() {
    for (const [mapKey, mapValue] of this.mapKeys) {
      if (
        mapValue &&
        !isNullOrUndefined(mapValue.sourceElementId) &&
        isNullOrUndefined(mapValue.targetElementId)
      )
        return mapKey;
    }
    return null;
  }

  get lastMappedElementSequenceNumber() {
    let lastMappedElementDetails = [...this.mapKeys] && [...this.mapKeys].pop();

    return (
      lastMappedElementDetails && lastMappedElementDetails[1].sequenceNumber
    );
  }

  toApiFormat() {
    const elementsList = [...this.mapKeys.values()];
    let apiFormatResponse = [];
    elementsList.forEach((element) => {
      let object = {};
      object.sourceElementId = element.sourceElementId;
      object.targetElementId = element.targetElementId;
      object.elementStatus = false;
      apiFormatResponse.push(object);
    });
    return apiFormatResponse;
  }

  getCountOfElementsSelected() {
    return (
      this.targetStatementElementMap && this.targetStatementElementMap.size
    );
  }

  sideBySideSourceElementsId() {
    return (
      this.sourceStatementElementMap && [
        ...this.sourceStatementElementMap.keys(),
      ]
    );
  }

  sideBySideTargetElementsId() {
    return (
      this.targetStatementElementMap && [
        ...this.targetStatementElementMap.keys(),
      ]
    );
  }

  updateMapKeyForSourceElement(key, sourceElementId) {
    let existsingMapKey = this.mapKeys.get(key);

    // if it is a new mapping then update with sequence number.
    let sequenceNumber = existsingMapKey
      ? existsingMapKey.sequenceNumber
      : Number.isInteger(this.lastMappedElementSequenceNumber)
      ? this.lastMappedElementSequenceNumber + 1
      : this.mapKeys.size + 1;
    return this.merge({
      mapKeys: this.mapKeys.set(key, {
        ...this.getMapKey(key),
        sequenceNumber,
        sourceElementId,
      }),
    });
  }

  updateMapKeyForTargetElement(key, targetElementId) {
    return this.merge({
      mapKeys: this.mapKeys.set(key, {
        ...this.getMapKey(key),
        targetElementId: targetElementId,
      }),
    });
  }

  getSourceElementMapByElementId(sourceElementId) {
    if (this.sourceStatementElementMap instanceof Map)
      return this.sourceStatementElementMap.get(parseInt(sourceElementId));
    else return null;
  }

  getTargetElementMapByElementId(targetElementId) {
    if (this.targetStatementElementMap instanceof Map)
      return this.targetStatementElementMap.get(parseInt(targetElementId));
    else return null;
  }

  getCountForMappedSourceElement(elementId) {
    let sourceElementMapForId = this.getSourceElementMapByElementId(
      parseInt(elementId),
    );
    let mapKey = sourceElementMapForId && sourceElementMapForId.mapKey;
    return (
      mapKey &&
      this.mapKeys &&
      this.mapKeys.get(mapKey) &&
      this.mapKeys.get(mapKey).sequenceNumber
    );
  }

  getCountForMappedTargetElement(elementId) {
    let targetElementMapForId = this.getTargetElementMapByElementId(
      parseInt(elementId),
    );
    let mapKey = targetElementMapForId && targetElementMapForId.mapKey;
    return (
      mapKey &&
      this.mapKeys &&
      this.mapKeys.get(mapKey) &&
      this.mapKeys.get(mapKey).sequenceNumber
    );
  }

  // this function assumed source mapping already exists
  isTargetElementExistsForSourceElementId(sourceElementId) {
    // get source map and find map key for source elementId
    let sourceMap = this.getSourceElementMapByElementId(sourceElementId);
    let mapKey = sourceMap && sourceMap.mapKey;
    // Now check in the mapKey, if targetElementId exists
    return mapKey && this.mapKeys.get(mapKey).targetElementId;
  }

  deleteTargetMappingForMapKey(mapKey) {
    for (const [targetElementMapKey, targetElementMapValue] of this
      .targetStatementElementMap) {
      if (targetElementMapValue.mapKey === mapKey) {
        this.targetStatementElementMap.delete(targetElementMapKey);
        return this.targetStatementElementMap;
      }
    }

    // if target element does not already exists then return the original object
    return this.targetStatementElementMap;
  }

  deleteElementMappingForSourceElementId(sourceElementId, mapKeyToBeDeleted) {
    // delete source mapping
    this.sourceStatementElementMap.delete(sourceElementId);
    // then delete target mapping
    this.deleteTargetMappingForMapKey(mapKeyToBeDeleted);
    // then delete from mapKeys
    this.mapKeys.delete(mapKeyToBeDeleted);

    return this.merge({
      sourceStatementElementMap: this.sourceStatementElementMap,
      targetStatementElementMap: this.targetStatementElementMap,
      mapKeys: this.mapKeys,
    });
  }

  setSourceStatementElementMap(elementDetails) {
    let elementId = elementDetails && elementDetails.id;
    let lastMappedElementDetails = [...this.mapKeys] && [...this.mapKeys].pop();
    let isSourceElementAlreadyMapped =
      lastMappedElementDetails && lastMappedElementDetails[1].sourceElementId;
    let isTargetElementAlreadyMapped =
      lastMappedElementDetails && lastMappedElementDetails[1].targetElementId;

    // if source element has already been mapped and it is again clicked then it means user expects entire(both source, target and mapkey) mapping it to be deleted
    if (this.getSourceElementMapByElementId(elementId)) {
      // get mapKey first
      let mapKeyToBeDeleted =
        this.getSourceElementMapByElementId(elementId).mapKey;
      let toBeDeletedSourceElementId =
        this.getSourceElementMapByElementId(elementId).id;
      return this.deleteElementMappingForSourceElementId(
        toBeDeletedSourceElementId,
        mapKeyToBeDeleted,
      );
    }
    // if source is already mapped and corresponding target is not yet assigned then for the existing mapKey re-assign new source
    if (
      !isNullOrUndefined(isSourceElementAlreadyMapped) &&
      isNullOrUndefined(isTargetElementAlreadyMapped) &&
      elementDetails.id !== isSourceElementAlreadyMapped
    ) {
      // get existing mapKey
      let existingMapKey =
        lastMappedElementDetails && lastMappedElementDetails[0];

      // delete old mapped source element
      lastMappedElementDetails &&
        !isNullOrUndefined(lastMappedElementDetails[1].sourceElementId) &&
        this.sourceStatementElementMap.delete(
          lastMappedElementDetails[1].sourceElementId,
        );
      return this.merge({
        sourceStatementElementMap: !isNullOrUndefined(existingMapKey)
          ? this.sourceStatementElementMap.set(elementDetails.id, {
              mapKey: existingMapKey,
              ...elementDetails,
            })
          : this.sourceStatementElementMap,
        mapKeys: !isNullOrUndefined(existingMapKey)
          ? this.updateMapKeyForSourceElement(existingMapKey, elementDetails.id)
              .mapKeys
          : this.mapKeys,
      });
    }

    // if source element has not been mapped yet, and in the previous steps target element also does
    // not exists then create new mapping  OR
    // if source and target mapping both exists, then we need to create another source element to be mapped to new target
    else if (
      isNullOrUndefined(isSourceElementAlreadyMapped) ||
      (!isNullOrUndefined(isSourceElementAlreadyMapped) &&
        !isNullOrUndefined(isTargetElementAlreadyMapped))
    ) {
      let newMapKey = shortid.generate();
      return this.merge({
        sourceStatementElementMap: this.sourceStatementElementMap.set(
          elementDetails.id,
          {
            mapKey: newMapKey,
            ...elementDetails,
          },
        ),
        mapKeys: this.updateMapKeyForSourceElement(newMapKey, elementDetails.id)
          .mapKeys,
      });
    }
  }

  // it is expected that target mapping is called just after source mapping has finished.
  // such that element linking happens in order (for each source element, target element is linked) correct.
  setTargetStatementElementMap(elementDetails) {
    let elementId = elementDetails && elementDetails.id;
    let mapKeyOfFirstUnmappedTargetElement =
      this.mapKeyOfFirstUnmappedTargetElement;

    let lastMappedElementDetails =
      [...this.mapKeys].pop() && [...this.mapKeys].pop();

    //Condition1: If user has clicked target element directly, then, ignore this click.
    // This might happen at the first click when without selecting source user has clicked target.
    if (
      isNullOrUndefined(mapKeyOfFirstUnmappedTargetElement) &&
      this.mapKeys.size === 0
    )
      return this;

    //Conditon2: if target element mapping already exists for the given element id and user again clicked on it, then remove it from the corresponding mapKeys and target element mapping
    let isMappingAlreadyExistsForElementId =
      this.getTargetElementMapByElementId(elementId);
    if (isMappingAlreadyExistsForElementId) {
      let mapKeyForDeletingTargetElementId =
        isMappingAlreadyExistsForElementId &&
        isMappingAlreadyExistsForElementId.mapKey;

      return this.merge({
        mapKeys: this.updateMapKeyForTargetElement(
          mapKeyForDeletingTargetElementId,
          undefined,
        ).mapKeys,
        targetStatementElementMap: this.deleteTargetMappingForMapKey(
          mapKeyForDeletingTargetElementId,
        ),
      });
    }

    //Condition 3: If user has clicked target element and all target elements are already mapped to some source, then
    // replace the last target element with this new element
    if (isNullOrUndefined(mapKeyOfFirstUnmappedTargetElement)) {
      // delete old target for the given map key
      this.deleteTargetMappingForMapKey(lastMappedElementDetails[0]);
      return this.merge({
        mapKeys: this.updateMapKeyForTargetElement(
          lastMappedElementDetails[0],
          elementId,
        ).mapKeys,
        targetStatementElementMap: this.targetStatementElementMap.set(
          elementId,
          {
            mapKey: lastMappedElementDetails[0],
            ...elementDetails,
          },
        ),
      });
    }

    //Condition4: if target element mapping does not exists for the last target unmapped mapKey, then add target mapping for it.
    else if (
      !isMappingAlreadyExistsForElementId &&
      !isNullOrUndefined(mapKeyOfFirstUnmappedTargetElement)
    ) {
      return this.merge({
        mapKeys: this.updateMapKeyForTargetElement(
          mapKeyOfFirstUnmappedTargetElement,
          elementId,
        ).mapKeys,
        targetStatementElementMap: this.targetStatementElementMap.set(
          elementId,
          {
            mapKey: mapKeyOfFirstUnmappedTargetElement,
            ...elementDetails,
          },
        ),
      });
    } else return this;
  }

  clearElementsMap() {
    return this.merge({
      sourceStatementElementMap: new Map(),
      targetStatementElementMap: new Map(),
      mapKeys: new Map(),
    });
  }

  // used to map elements from both source and target tables selection
  setStatementSideBySideBatchElementsMap(targetArray) {
    // getting all target elements which is not mapped
    let filteredTargetElementsByIdExists = targetArray.filter(
      (item) => !this.getTargetElementMapByElementId(item.id),
    );
    // getting the count of to map elements based on the source element count
    let mapRemainingTargetElementCount =
      this.sizeOfSourceMapping - this.sizeOfTargetMapping;
    // Using map function instead of for loop for better performance
    filteredTargetElementsByIdExists
      .slice(0, mapRemainingTargetElementCount)
      .map((item) => {
        // Checking if targetElement is not null or undefined
        if (item) {
          this.setTargetStatementElementMap(item);
        }
      });
    return this.merge({ data: this.data });
  }

  // used to add source table elements in sourceElementMap
  setSourceSideBySideBatchElementMap(elementDetails) {
    let elementId = elementDetails && elementDetails.id;
    let lastMappedElementDetails = [...this.mapKeys];
    let isSourceElementAlreadyMapped =
      lastMappedElementDetails &&
      lastMappedElementDetails.filter(
        (item) => item.sourceElementId === elementId,
      );
    // if source element has not been mapped yet, and in the previous steps target element also does
    // not exists then create new mapping  OR
    // if source and target mapping both exists, then we need to create another source element to be mapped to new target
    if (isSourceElementAlreadyMapped.length === 0) {
      let newMapKey = shortid.generate();
      return this.merge({
        sourceStatementElementMap: this.sourceStatementElementMap.set(
          elementId,
          {
            mapKey: newMapKey,
            ...elementDetails,
          },
        ),
        mapKeys: this.updateMapKeyForSourceElement(newMapKey, elementId)
          .mapKeys,
      });
    }
  }
  // used to add selected source table elements only if elements is not present sourceElementMap
  setSourceStatementBatchElementsMap(sourceArray) {
    sourceArray.forEach((element, i) => {
      isNullOrUndefined(this.getSourceElementMapByElementId(element.id)) &&
        this.setSourceSideBySideBatchElementMap(element);
    });
    return this.merge({
      ...this.sourceStatementElementMap,
      ...this.mapKeys,
    });
  }
}
