import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import { FormattedMessage } from 'react-intl';
import SourceElementFilter from './statement-nav-source-element-filters-component';

export const TERTIARY_FORMULA_ID_BLOCK = 'tertiary-formula-filters-id';

const ElementFiltersFormulaTertiary = ({ filters, setFilters, className }) => {
  return (
    <div className={className}>
      <SourceElementFilter
        className={className}
        automatedChecked={filters.automatedFormulaFlag}
        automatedOnChange={() =>
          setFilters(
            filters.setAutomatedFormulaFlag(!filters.automatedFormulaFlag),
          )
        }
        manualChecked={filters.manualFormulaFlag}
        manualOnChange={() =>
          setFilters(filters.setManualFormulaFlag(!filters.manualFormulaFlag))
        }
      />
      <div className={`${className}__top-border`}>
        <div className={`${className}__subtitle`}>
          <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.col-3.formula.type" />
        </div>
        <Checkbox
          id={`${TERTIARY_FORMULA_ID_BLOCK}-reclac`}
          label="statement-nav-panel.tab.elements.filters.modal.col-3.formula.reclac"
          checked={filters.recalculatedFormula === true}
          onChange={() =>
            setFilters(filters.setFormulaRecalc(!filters.recalculatedFormula))
          }
        />
        <Checkbox
          id={`${TERTIARY_FORMULA_ID_BLOCK}-foot`}
          label="statement-nav-panel.tab.elements.filters.modal.col-3.formula.foot"
          checked={filters.footedFormula === true}
          onChange={() =>
            setFilters(filters.setFormulaFoot(!filters.footedFormula))
          }
        />
        <Checkbox
          id={`${TERTIARY_FORMULA_ID_BLOCK}-cross-foot`}
          label="statement-nav-panel.tab.elements.filters.modal.col-3.formula.cross-foot"
          checked={filters.crossFootedFormula === true}
          onChange={() =>
            setFilters(filters.setFormulaCrossFoot(!filters.crossFootedFormula))
          }
          className={`${className}__last-checkbox`}
        />
      </div>
      <div className={`${className}__top-border`}>
        <div className={`${className}__subtitle`}>
          <FormattedMessage id="statement-nav-panel.tab.elements.filters.modal.col-3.formula.type-round" />
        </div>
        <Checkbox
          id={`${TERTIARY_FORMULA_ID_BLOCK}-rounded`}
          label="statement-nav-panel.tab.elements.filters.modal.col-3.formula.rounded"
          checked={filters.roundedFormula === true}
          onChange={() =>
            setFilters(filters.setFormulaRounded(!filters.roundedFormula))
          }
        />
      </div>
    </div>
  );
};

ElementFiltersFormulaTertiary.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default ElementFiltersFormulaTertiary;
