import React from 'react';
import { connect } from 'react-redux';
import ZoomControl from 'components/common/zoom-control-component';
import { SCALE_ALTERATION } from 'constants/feature/statement-content-constants';
import {
  incrementZoom,
  decrementZoom,
  leftIncrementZoom,
  leftDecrementZoom,
  rightIncrementZoom,
  rightDecrementZoom,
} from 'store/actions/zoom-actions';
import {
  setContentFixedMode,
  setContentFullMode,
} from 'store/actions/content-view-modes-actions';

export const ZoomControlHOC = (props) => {
  const {
    zoom,
    leftZoom,
    leftDecrementZoom,
    leftIncrementZoom,
    incrementZoom,
    decrementZoom,
    isLeftSideView,
    isSideBySidePage,
    rightIncrementZoom,
    rightDecrementZoom,
    rightZoom,
    sectionIdList,
    leftSectionIdList,
    selectedStatement,
    leftSelectedStatement,
    ocrCurrentViewPageNumber,
    leftOcrCurrentViewPageNumber,
  } = props;
  const selectedStatementData = isLeftSideView
    ? leftSelectedStatement
    : selectedStatement;
  const { dpi, isOCR } = selectedStatementData;
  const scale = SCALE_ALTERATION * (150 / dpi);
  const zoomValue = isSideBySidePage
    ? isLeftSideView
      ? leftZoom
      : rightZoom
    : zoom;
  const currentPageNumber = isSideBySidePage
    ? isLeftSideView
      ? leftOcrCurrentViewPageNumber
      : ocrCurrentViewPageNumber
    : ocrCurrentViewPageNumber;
  const containerId = isLeftSideView
    ? 'statement-content-panel-ocr__document-id-left-view'
    : 'statement-content-panel-ocr__document-id';
  const sectionIdListData = isLeftSideView ? leftSectionIdList : sectionIdList;

  const getPageTopPosition = ({ isPlusClicked }) => {
    const currentZoom = isPlusClicked
      ? zoomValue.currentZoom + zoomValue.stepValue
      : zoomValue.currentZoom - zoomValue.stepValue;
    const currentZoomRatio = currentZoom / 100;
    const scaleWithZoom = scale * currentZoomRatio;
    const height = sectionIdListData.sectionIds.length
      ? sectionIdListData.sectionIds[0].height
      : 0;
    const actualPageHeight =
      (currentPageNumber - 1) * (height * scaleWithZoom + 32);
    const container = document.getElementById(containerId);
    container.scrollTo({ top: actualPageHeight });
  };

  const onPlus = () => {
    if (isOCR) {
      getPageTopPosition({ isPlusClicked: true });
    }
    isSideBySidePage
      ? isLeftSideView
        ? leftIncrementZoom()
        : rightIncrementZoom()
      : incrementZoom();
  };

  const onMinus = () => {
    if (isOCR) {
      getPageTopPosition({ isPlusClicked: false });
    }
    isSideBySidePage
      ? isLeftSideView
        ? leftDecrementZoom()
        : rightDecrementZoom()
      : decrementZoom();
  };
  return (
    <ZoomControl
      {...props}
      zoomModel={zoomValue}
      onPlus={onPlus}
      onMinus={onMinus}
    />
  );
};

const mapStateToProps = ({
  data: {
    copyFormula: { copiedFormula },
    selectedStatement,
    leftSelectedStatement,
    statementContent: { sectionIdList },
    leftStatementContent: { leftSectionIdList },
  },
  ui: {
    statementPage: {
      zoom,
      leftZoom,
      rightZoom,
      modes: {
        contentViewMode,
        ocrCurrentViewPageNumber,
        leftOcrCurrentViewPageNumber,
      },
    },
  },
}) => ({
  contentViewMode,
  zoom,
  leftZoom,
  rightZoom,
  sectionIdList,
  leftSectionIdList,
  selectedStatement,
  leftSelectedStatement,
  ocrCurrentViewPageNumber,
  leftOcrCurrentViewPageNumber,
  isCopiedFormula:
    copiedFormula &&
    copiedFormula.data &&
    copiedFormula.data.formulas &&
    copiedFormula.data.formulas.length > 0,
});

const mapDispatchToProps = {
  setContentFixedMode,
  setContentFullMode,
  leftIncrementZoom,
  leftDecrementZoom,
  incrementZoom,
  decrementZoom,
  rightIncrementZoom,
  rightDecrementZoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoomControlHOC);
