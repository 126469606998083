import { createAction } from 'redux-actions';

export const setOcrCurrentViewPageNumber = createAction(
  'SET_OCR_CURRENT_VIEW_PAGE_NUMBER',
);

export const setLeftOcrCurrentViewPageNumber = createAction(
  'SET_LEFT_OCR_CURRENT_VIEW_PAGE_NUMBER',
);

export const setOcrCurrentViewPageNumberDispatch =
  (currentPage, isLeftSideView = false) =>
  async (dispatch, getState) => {
    isLeftSideView
      ? dispatch(setLeftOcrCurrentViewPageNumber(parseInt(currentPage)))
      : dispatch(setOcrCurrentViewPageNumber(parseInt(currentPage)));
  };
