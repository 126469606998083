import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import { useSelector } from 'react-redux';
import Search from 'components/common/search-component';

export const SEGMENT_FILTERS_ID_BLOCK = 'element-filters-segment-id';

const ElementFiltersStatementPages = ({ filters, setFilters, className }) => {
  const [searchTerm, setSearch] = useState('');
  const [sectionIdList, setSectionIdList] = useState([]);

  const {
    sectionsCache: { ocrCache },
  } = useSelector((state) => state.data.statementContent);

  useEffect(() => {
    ocrCache &&
      ocrCache.data &&
      ocrCache.data.map &&
      setSectionIdList(
        Object.keys(ocrCache.data.map).map((key) => key.toString()),
      );
  }, [ocrCache]);

  return (
    <div className={`${className}__statement-segment`}>
      <Search
        className={`${className}__statement-segment-search`}
        id={`${className}-search`}
        onChange={(val) => setSearch(val)}
        onClear={() => {
          setSearch('');
        }}
        placeholder="statement-nav-element-filters.statement-segment.search.placeholder"
        showSearchButton
        value={searchTerm}
        isValid
        disableAutoComplete
      />
      {sectionIdList
        .filter((sectionId) => sectionId.includes(searchTerm))
        .map((sectionId) => (
          <Checkbox
            key={sectionId}
            id={`${SEGMENT_FILTERS_ID_BLOCK}-${sectionId}`}
            isNotIntl
            label={`Page ${sectionId}`}
            value={sectionId}
            checked={filters.isSectionSelected(sectionId)}
            onChange={(val) => setFilters(filters.selectPages(val))}
          />
        ))}
    </div>
  );
};

ElementFiltersStatementPages.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default ElementFiltersStatementPages;
