import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Page from 'components/util/page-component';
import GlobalHeaderContainer from 'containers/global-header-container';
import StatementContentPanelComponentHOC from 'containers/feature/statement-content/statement-content-panel-container';
import StatementContentPanelContainerOCR from 'containers/feature/statement-content/statement-content-panel-container-ocr';
import ElementPanelContainer from 'containers/feature/element-panel/element-panel-container';
import SectionDetailsPanel from 'components/feature/section-details-panel/section-details-panel-component';
import ConditionalRender from 'components/util/conditional-render-component';
import Toolkit from 'components/feature/toolkit/toolkit-component';
import StatementNavigatorPanel from 'components/feature/toolkit/statement-nav/statement-navigator-panel-component';
import StatementContentSearchHighlightSelectorStyle from 'components/feature/statement-content-panel/statement-content-search-highlight-style-component';
import BatchPanel from 'components/feature/batch-panel/batch-panel-component';
import Revision from 'models/api/revision-api-model';
import NotesPanel from 'components/feature/notes-panel/notes-panel-component';
import TickmarkPanel from 'components/feature/tickmark-panel/tickmark-panel-component';
import ComparePanel from 'components/feature/statement-summary/compare-panel/compare-panel-component';
import SelectedStatement from 'models/api/selected-statement-model';
import { ReactComponent as Lock } from 'icons/lock.svg';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import classnames from 'classnames';
import ExportPanel from 'components/feature/toolkit/toolkit-export-panel-component';
import AuditCheckListPanel from 'components/feature/toolkit/toolkit-audit-panel/toolkit-audit-checklist-panel';
import ComfortAnnotationPanel from 'components/feature/toolkit/toolkit-comfort-annotation/toolkit-comfort-annotation-panel';
import WorkPaperPanel from 'components/feature/toolkit/workpaper-toolkit-panel/toolkit-workpaper-list-panel-component';
import ChatBotPanel from 'components/feature/toolkit/chatbot-toolkit-panel/toolkit-chatbot-panel-component';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';
import CustomMenuPanel from 'components/feature/toolkit/statement-nav/customized-report/toolkit-export-customized-report-component';
import shortid from 'shortid';
import BatchBanner from './batch-banner';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import BlacklineVersionView from 'pages/blackline-version/blackline-version-component';
import { isNullOrUndefined } from 'utils/object-utils';
import StatementReadOnlyBanners from 'pages/statement-read-only-banner';
import RecentStatementUpdatesModal from './recent-statement-content-updates-modal';
import { LOCAL_STORAGE_DATA_VERSION } from './home-page';
// import { addUserToRevision } from 'store/actions/active-users-actions';

export const STATEMENT_CONTENT_BLOCK = 'statement-content-page';
const STATEMENT_CONTENT_ID_BLOCK = 'statement-content-page-id';
export const CENTER_PANEL_ID = `${STATEMENT_CONTENT_ID_BLOCK}__center-panel`;
const TABLE_CONTROLS_ID = `${STATEMENT_CONTENT_ID_BLOCK}__table-controls`;
const SCROLL_TIMEOUT = 150;
const BANNER_CLOSE_ICON_SIZE = '28px';

class StatementContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // indicates to child components that the user is currently scrolling the content panel
      // i.e. more sections are being revealed
      isContentPanelScrolling: false,
      showBlackLineViewReadOnlyBanner: true,
      showStatementRecentUpdatesModal: false,
    };
    this.centerPanelRef = createRef();
    this._scrollingTimeout = null;
    this._scrollingInitialized = false;
    this._ctrlPressed = false;
  }

  componentDidMount() {
    const { initStatementContent, urlParams, selectedStatementLoading } =
      this.props;
    const prepareStatementContent = async () => {
      selectedStatementLoading();
      initStatementContent(urlParams);
    };
    prepareStatementContent();
    const displayStatementUpdatesData = localStorage.getItem(
      'SHOW_STATEMENT_UPDATES_MODAL',
    );
    if (!window.TIEOUT.ENV.FEATURE.ENABLE_RECENT_UPDATES_NOTIFICATION) {
      this.setState({ showStatementRecentUpdatesModal: false });
    } else {
      // we check if the data exist in the local storage
      if (isNullOrUndefined(displayStatementUpdatesData)) {
        //we add the current version local storage data and set the state to true to show the modal.
        this._setLocalStorageValueForShowingRecentUpdates(true);
        this._setSessionStorageValueForShowingRecentUpdates(true);
        this.setState({ showStatementRecentUpdatesModal: true });
      } else {
        // we use try to avoid error in thr application if the user changes the local storage data manually
        try {
          const localStorageData = JSON.parse(displayStatementUpdatesData);
          // if local storage data exist and the version is different we need to set the data for the new version
          if (
            localStorageData.version &&
            localStorageData.version !== LOCAL_STORAGE_DATA_VERSION
          ) {
            this._setLocalStorageValueForShowingRecentUpdates(true);
            this._setSessionStorageValueForShowingRecentUpdates(true);
            this.setState({ showStatementRecentUpdatesModal: true });
          } else {
            //if the local storage data exist and we didn't changed the version, we check the value
            // if its true, we set the state to true to show the modal
            /*recent updates local storage management block of code */
            let displayStatementUpdatesSessionData = sessionStorage.getItem(
              'TEMPORARY_SHOW_STATEMENT_UPDATES_MODAL',
            );
            if (isNullOrUndefined(displayStatementUpdatesSessionData)) {
              this._setSessionStorageValueForShowingRecentUpdates(true);
              displayStatementUpdatesSessionData = sessionStorage.getItem(
                'TEMPORARY_SHOW_STATEMENT_UPDATES_MODAL',
              );
            }

            if (localStorageData.show && localStorageData.show === true) {
              if (
                displayStatementUpdatesSessionData &&
                displayStatementUpdatesSessionData === 'true'
              ) {
                this.setState({ showStatementRecentUpdatesModal: true });
              }
            } else {
              this._setSessionStorageValueForShowingRecentUpdates(false);
            }
          }
        } catch (error) {
          this._setLocalStorageValueForShowingRecentUpdates(true);
          this._setSessionStorageValueForShowingRecentUpdates(true);
          this.setState({ showStatementRecentUpdatesModal: true });
        }
      }
    }
  }

  _setLocalStorageValueForShowingRecentUpdates(value) {
    localStorage.setItem(
      'SHOW_STATEMENT_UPDATES_MODAL',
      JSON.stringify({ version: LOCAL_STORAGE_DATA_VERSION, show: value }),
    );
  }

  _setSessionStorageValueForShowingRecentUpdates(value) {
    sessionStorage.setItem('TEMPORARY_SHOW_STATEMENT_UPDATES_MODAL', value);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      revision,
      selectedStatement,
      urlParams,
      fetchSelectedStatement,
      blacklineViewMode,
      // selectedProjectId,
      // socketHasBeenDisconnected,
    } = this.props;
    // let isIntervalAlreadySet = null;

    if (
      revision.isLoaded &&
      selectedStatement.isLoaded &&
      (prevProps.revision.isLoaded !== revision.isLoaded ||
        prevProps.selectedStatement.isLoaded !== selectedStatement.isLoaded)
    ) {
      // Need to ensure revision and selected statement is loaded (thus center panel is rendered) before attaching event listener
      if (!isNullOrUndefined(this.centerPanelRef.current)) {
        this.centerPanelRef.current.addEventListener(
          'scroll',
          this._scrollDetection,
        );
      }

      if (!selectedStatement.isReadOnly()) {
        this._initBatchCtrlListeners();
      }
    }
    if (prevProps.urlParams.statementId !== urlParams.statementId) {
      fetchSelectedStatement(urlParams);
    }

    if (prevProps.blacklineViewMode !== blacklineViewMode) {
      this.setState({ showBlackLineViewReadOnlyBanner: true });
    }

    // TODO : Code to be uncommented when we are developing feature related to instant update of active users on statement.
    // if (prevProps.socketHasBeenDisconnected !== socketHasBeenDisconnected) {
    //   if (socketHasBeenDisconnected && !isIntervalAlreadySet) {
    //     // We need to refresh the users in the room every 1hour
    //     isIntervalAlreadySet = setInterval(() => {
    //       addUserToRevision(revision.id, selectedProjectId);
    //     }, 3600000);
    //   } else {
    //     clearInterval(isIntervalAlreadySet);
    //   }
    // }
  }

  componentWillUnmount() {
    const {
      clearStatementPageStore,
      clearSelectedStatement,
      clearStatementNavElementTab,
      setContentFixedMode,
      clearElementsFromWorkpaper,
      closeSocketClient,
      clearElementsFromTickmark,
      clearSectionTreeListAction,
      projectUsersList,
      revision,
      cancelCopyFormula,
      clearBatchModeSelected,
      selectedProjectId,
      clearSelectedElementsContentPanel,
    } = this.props;
    if (!window.location.pathname.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)) {
      if (this.centerPanelRef && this.centerPanelRef.current) {
        this.centerPanelRef.current.removeEventListener(
          'scroll',
          this._scrollDetection,
        );
      }
      this._cleanUpBatchCtrlListeners();
      window.clearTimeout(this._scrollingTimeout);
      this._scrollingTimeout = null;
      clearStatementPageStore();
      clearSelectedStatement();
      clearStatementNavElementTab();
      setContentFixedMode();
      clearElementsFromWorkpaper();
      clearElementsFromTickmark();
      clearSectionTreeListAction();
      cancelCopyFormula();
      clearBatchModeSelected();
    }
    clearSelectedElementsContentPanel();
    closeSocketClient({
      revisionId: revision.id,
      projectUserIds: projectUsersList.data.userIdToUserNameMap,
      projectId: selectedProjectId,
    });
  }

  _scrollDetection = (event) => {
    const { isContentPanelScrolling } = this.state;
    if (!isContentPanelScrolling) {
      this.setState({
        isContentPanelScrolling: true,
      });
    }
    window.clearTimeout(this._scrollingTimeout);
    this._scrollingTimeout = setTimeout(() => {
      this.setState({
        isContentPanelScrolling: false,
      });
    }, SCROLL_TIMEOUT);
  };

  _initBatchCtrlListeners = () => {
    window.addEventListener('keydown', this._ctrlPressListener);
    window.addEventListener('keyup', this._ctrlReleaseListener);
  };

  _cleanUpBatchCtrlListeners = () => {
    window.removeEventListener('keydown', this._ctrlPressListener);
    window.removeEventListener('keyup', this._ctrlReleaseListener);
  };

  _ctrlPressListener = (event) => {
    const {
      setElementSelectModeBatchIfPossible,
      clearBatchModeIfPossible,
      isBatchModeSelected,
      blacklineViewMode,
    } = this.props;
    if (event.key === 'Control' && !isBatchModeSelected && !blacklineViewMode) {
      setElementSelectModeBatchIfPossible();
      this._ctrlPressed = true;
    } else {
      if (this._ctrlPressed && !isBatchModeSelected && !blacklineViewMode) {
        clearBatchModeIfPossible();
        this._ctrlPressed = false;
      }
    }
  };

  _ctrlReleaseListener = (event) => {
    const { clearBatchModeIfPossible, isBatchModeSelected } = this.props;
    if (event.key === 'Control' && !isBatchModeSelected) {
      clearBatchModeIfPossible();
      this._ctrlPressed = false;
    }
  };

  render() {
    const {
      urlParams,
      revision,
      selectedStatement,
      blacklineViewMode,
      contentViewMode,
      workflowsMap,
      isBatchModeSelected,
      setOcrCurrentViewPageNumberDispatch,
      ocrCurrentViewPageNumber,
    } = this.props;
    const {
      isContentPanelScrolling,
      showBlackLineViewReadOnlyBanner,
      showStatementRecentUpdatesModal,
    } = this.state;
    const isOCRStatement = selectedStatement.isOCR;

    return (
      <Page className={STATEMENT_CONTENT_BLOCK}>
        <StatementContentSearchHighlightSelectorStyle />
        <ConditionalRender dependencies={[revision, selectedStatement]}>
          <GlobalHeaderContainer urlParams={urlParams} />
          <div className={`${STATEMENT_CONTENT_BLOCK}__container`}>
            <div
              className={classnames(
                `${STATEMENT_CONTENT_BLOCK}__left-panel`,
                contentViewMode.contentFullMode &&
                  `${STATEMENT_CONTENT_BLOCK}__left-panel--full-mode`,
              )}
              id={`${STATEMENT_CONTENT_ID_BLOCK}__left-panel`}
            >
              <Toolkit />
              <StatementNavigatorPanel />
              <TickmarkPanel />
              <ExportPanel />
              <WorkPaperPanel />
              <CustomMenuPanel />
              <AuditCheckListPanel />
              <ComfortAnnotationPanel />
              <ChatBotPanel />
              {blacklineViewMode && <ComparePanel />}
            </div>
            {blacklineViewMode && isOCRStatement && <BlacklineVersionView />}
            {(!blacklineViewMode || (blacklineViewMode && !isOCRStatement)) && (
              <div
                ref={this.centerPanelRef}
                className={classnames(
                  `${STATEMENT_CONTENT_BLOCK}__center-panel`,
                  {
                    [`${STATEMENT_CONTENT_BLOCK}__center-panel--scroll-bar`]:
                      !isOCRStatement,
                    [`${STATEMENT_CONTENT_BLOCK}__center-panel--full-mode`]:
                      contentViewMode.contentFullMode,
                  },
                )}
                id={CENTER_PANEL_ID}
              >
                <div id={TABLE_CONTROLS_ID} />
                <BatchBanner />
                {!isOCRStatement && (
                  <>
                    <StatementReadOnlyBanners
                      selectedStatement={selectedStatement}
                      isBatchModeSelected={isBatchModeSelected}
                      urlParams={urlParams}
                      workflowsMap={workflowsMap}
                    />

                    {blacklineViewMode && showBlackLineViewReadOnlyBanner && (
                      <div
                        className={classnames(
                          `${STATEMENT_CONTENT_BLOCK}__center-panel-banner`,
                          isBatchModeSelected && selectedStatement.isReadOnly()
                            ? `${STATEMENT_CONTENT_BLOCK}__blackline-top-fix`
                            : isBatchModeSelected &&
                              !selectedStatement.isReadOnly()
                            ? `${STATEMENT_CONTENT_BLOCK}__readonly-top-fix`
                            : !isBatchModeSelected &&
                              selectedStatement.isReadOnly()
                            ? `${STATEMENT_CONTENT_BLOCK}__top-fix`
                            : null,
                        )}
                      >
                        <div>
                          <Lock
                            className={`${STATEMENT_CONTENT_BLOCK}__center-panel-banner-icon`}
                          />
                          <FormattedMessage
                            id="selected-statement.blackline-banner"
                            values={{
                              br: (
                                <br
                                  key={`${STATEMENT_CONTENT_BLOCK}__blackline-banner-brTag-${shortid.generate()}`}
                                />
                              ),
                            }}
                          />
                        </div>

                        <CloseButton
                          name={`{${STATEMENT_CONTENT_BLOCK}__button`}
                          role={'button'}
                          className={`{${STATEMENT_CONTENT_BLOCK}__close`}
                          width={BANNER_CLOSE_ICON_SIZE}
                          height={BANNER_CLOSE_ICON_SIZE}
                          onClick={() => {
                            this.setState({
                              showBlackLineViewReadOnlyBanner: false,
                            });
                          }}
                        />
                      </div>
                    )}
                  </>
                )}

                {isOCRStatement ? (
                  <StatementContentPanelContainerOCR
                    urlParams={urlParams}
                    leftSideBySideView={false}
                    isLeftView={false}
                    setOcrCurrentViewPageNumberDispatch={
                      setOcrCurrentViewPageNumberDispatch
                    }
                    ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
                    isOCR={isOCRStatement}
                    selectedStatement={selectedStatement}
                    isBatchModeSelected={isBatchModeSelected}
                    workflowsMap={workflowsMap}
                  />
                ) : (
                  <StatementContentPanelComponentHOC
                    urlParams={urlParams}
                    centerPanelRef={this.centerPanelRef}
                    isContentPanelScrolling={isContentPanelScrolling}
                    leftSideBySideView={false}
                  />
                )}
              </div>
            )}
            {(!blacklineViewMode || (blacklineViewMode && !isOCRStatement)) && (
              <div
                className={classnames(
                  `${STATEMENT_CONTENT_BLOCK}__right-panel`,
                  contentViewMode.contentFullMode &&
                    `${STATEMENT_CONTENT_BLOCK}__right-panel--full-mode`,
                )}
                id={`${STATEMENT_CONTENT_ID_BLOCK}__right-panel`}
              >
                <ElementPanelContainer />
                <BatchPanel />
                <NotesPanel />
                <SectionDetailsPanel />
                {showStatementRecentUpdatesModal && (
                  <RecentStatementUpdatesModal
                    onClose={() => {
                      this.setState({ showStatementRecentUpdatesModal: false });
                      this._setSessionStorageValueForShowingRecentUpdates(
                        false,
                      );
                    }}
                    onSave={() => {
                      this._setLocalStorageValueForShowingRecentUpdates(false);
                      this._setSessionStorageValueForShowingRecentUpdates(
                        false,
                      );
                      this.setState({ showStatementRecentUpdatesModal: false });
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </ConditionalRender>
      </Page>
    );
  }
}
StatementContent.propTypes = {
  /** url params object based on route */
  urlParams: PropTypes.object.isRequired,
  /** Action to initialize statement page data */
  initStatementContent: PropTypes.func.isRequired,
  /** Action to clear statement page data */
  clearStatementPageStore: PropTypes.func.isRequired,
  /** function fired to try setting element select mode to batch with banner */
  setElementSelectModeBatchWithBannerAction: PropTypes.func.isRequired,
  /** function fired to return element selct mode to default setting */
  clearBatchModeIfPossible: PropTypes.func.isRequired,
  /** Currently selected revision */
  revision: PropTypes.instanceOf(Revision).isRequired,
  /** function fired to fetch statement details */
  fetchSelectedStatement: PropTypes.func.isRequired,
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Action to clear elements from workpapers elements list */
  clearElementsFromWorkpaper: PropTypes.func.isRequired,
  /** Action to disconnect signalr client */
  closeSocketClient: PropTypes.func.isRequired,
  /** Action to clear elements from tickmark elements list */
  clearElementsFromTickmark: PropTypes.func.isRequired,
  /**For tracing revisions processing/failed*/
  workflowsMap: PropTypes.instanceOf(WorkflowsMap).isRequired,
  /** Action to clear elements from section tree list */
  clearSectionTreeListAction: PropTypes.func.isRequired,
  /**Action to clear copy formula selection, data, if any*/
  cancelCopyFormula: PropTypes.func.isRequired,
  /** Flag to check if batch mode is selected */
  isBatchModeSelected: PropTypes.bool.isRequired,
  /**action to set loading icon while statement content loads */
  selectedStatementLoading: PropTypes.func.isRequired,
  // project id for the currently selected project
  selectedProjectId: PropTypes.string,
};

export default StatementContent;
