import React, { useState, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CollapseAllIcon } from 'icons/collapse-all.svg';
import { ReactComponent as ExpandAllIcon } from 'icons/expand-all.svg';
import TreeView from 'components/common/treeview';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import { FormattedMessage } from 'react-intl';
import ContentSearchResults from 'models/api/statement-content-search-results-api-model';
import { isNullOrUndefined } from 'utils/object-utils';

import EditSectionModal from 'components/feature/statement-content-panel/edit-section-modal';
import DeleteSectionModal from 'components/feature/statement-content-panel/delete-section-modal';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';
import { toast } from 'react-toastify';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import RolesUtil from 'permissions/utils/roles';
import classNames from 'classnames';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import SectionAssignmentsList from 'models/api/section-assignments-list-api-model';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import shortid from 'shortid';
import { ReactComponent as Refresh } from 'icons/refresh-element-filter.svg';
import Tooltip from 'components/common/tool-tip-component';
import BulkAssignSectionsModal from './statement-nav-bulk-assignment/statement-nav-bulk-assignment-modal-component';
import SelectedStatement from 'models/api/selected-statement-model';
import ProjectApi from 'models/api/project-api-model';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import { useRef } from 'react';
import ProjectUsersList from 'models/api/project-users-list-api-model';
import Permissions from 'permissions/permissions';

const TABLE_OF_CONTENTS = 'table-of-contents';
const TABLE_OF_CONTENTS_ID = 'table-of-contents-id';
const EDIT_SECTION_MODAL = 'statement-content-panel--edit-section';
const REVISION_ID_TIMESTAMP_MAP = 'REVISION_ID_TIMESTAMP_MAP';

export const DEFAULT_EXPANDED_VALUE = false;
const DEFAULT_HIDDEN_HIGHLIGHT_VALUE = {
  hidden: false,
  highlighted: false,
};

const HIGHLIGHTED_SECTION = {
  hidden: false,
  highlighted: true,
};

const HIDDEN_SECTION = {
  hidden: true,
  highlighted: false,
};

const TableOfContentsTreeView = ({
  sectionTreeList,
  onSectionClick,
  searchTerm,
  searchResultsContent,
  contentSectionMap,
  currentSectionIds,
  sectionIds,
  TOCExpandAll,
  setTOCExpandAll,
  navigationEditMode,
  toggleNavigationEditModeDispatch,
  selectedStatement,
  workflowsMap,
  sectionReview,
  left,
  setNewSectionIdAction,
  sectionAssignmentsList,
  setSectionAssigmentsFilterMode,
  setResultsFromAssignmentFilter,
  sectionAssignmentHeadingRefresh,
  headingAssignmentRefreshAction,
  revisionId,
  projectUsersList,
  selectedSection,
  updateBulkSectionAssignmentsList,
  updateBulkSectionsReviewRequest,
  updateBulkSectionsUnReviewRequest,
  selectedProject,
  socketHasBeenDisconnected,
  isSideBySideViewMode = false,
  selectModeId,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  storeSelectedSectionIdAction,
  openSectionPanelAfterCancelConfirmAction,
  showEditAndDeleteIconInRow = false,
}) => {
  const [collapsibleNodesState, setCollapsibleNodesState] = useState(null);
  const [searchResultNodesState, setSearchResultNodesState] = useState(null);
  const [isOpenEditSection, setIsOpenEditSection] = useState(false);
  const [isOpenDeleteSection, setIsOpenDeleteSection] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [searchForSectionAssignmentsMode, setSearchForSectionAssignmentsMode] =
    useState(false);
  const [showRefreshButtonTooltip, setShowRefreshButtonTooltip] =
    useState(true);
  const [showBulkAssignModal, setShowBulkAssignModal] = useState(false);

  const _isBulkAssignNotPermitted =
    (selectedStatement && selectedStatement.isReadOnly()) ||
    (selectedProject &&
      selectedProject.id &&
      !Permissions.Section.canAssignSection(selectedProject.id));

  const _isEditBookmarkNotPermitted =
    (selectedStatement && selectedStatement.isReadOnly()) ||
    RolesUtil.doesUserHaveObserverRoleForProject() ||
    RolesUtil.doesUserHaveCoeRoleForProject() ||
    RolesUtil.doesUserHaveAdminSupportAccess();

  const containerRef = useRef();

  const doesNotHaveEditPermission =
    RolesUtil.doesUserHaveObserverRoleForProject() ||
    RolesUtil.doesUserHaveCoeRoleForProject();

  useEffect(() => {
    setCollapsibleNodesState(
      sectionTreeList.createCollapseStateMapFromNodeIds({
        initNodeValue: DEFAULT_EXPANDED_VALUE,
      }),
    );
    setSearchResultNodesState(
      sectionTreeList.createContentSearchFilterState({
        initNodeValue: DEFAULT_HIDDEN_HIGHLIGHT_VALUE,
      }),
    );
  }, [sectionTreeList]);

  useEffect(() => {
    if (localStorage.getItem(REVISION_ID_TIMESTAMP_MAP) !== null) {
      const revisionIdToTimestampMap = JSON.parse(
        localStorage.getItem(REVISION_ID_TIMESTAMP_MAP),
      );
      if (revisionId in revisionIdToTimestampMap) {
        const currentTime = new Date();
        const startingTime = new Date(
          revisionIdToTimestampMap[revisionId].startingTime,
        );
        const endingTime = new Date(
          revisionIdToTimestampMap[revisionId].endingTime,
        );
        if (currentTime >= startingTime && currentTime <= endingTime) {
          setShowRefreshButtonTooltip(false);
        }
      }
    }
  }, [revisionId]);

  const currentBookmark = contentSectionMap.getClosestBookmarkSection({
    sectionId: sectionIds
      .filter((id) => currentSectionIds.includes(id))
      .shift(), //pick the first highlighted section (based on documents sections order)
  });

  const toggleAll = useCallback(
    (expanded) =>
      setCollapsibleNodesState(
        (collapsibleNodesState) =>
          new Map(
            [...collapsibleNodesState.keys()].map((key) => [key, expanded]),
          ),
      ),
    [],
  );

  useEffect(() => {
    const _hasNewSearchResults =
      searchResultsContent &&
      searchResultsContent.isLoaded &&
      searchResultsContent.hasOccurrences();
    const _searchHasBeenCleared =
      searchTerm === '' && !searchResultsContent.isInitiated();

    if (_searchHasBeenCleared) {
      if (searchForSectionAssignmentsMode) {
        let resultsCount = 0;
        toggleAll(true);
        let newMap = sectionTreeList.createContentSearchFilterState({
          initNodeValue: DEFAULT_HIDDEN_HIGHLIGHT_VALUE,
        });
        newMap.forEach((value, key) => {
          if (key in sectionAssignmentsList.assignedSectionsToUser) {
            resultsCount += 1;
            newMap.set(key, HIGHLIGHTED_SECTION);
          } else {
            newMap.set(key, HIDDEN_SECTION);
          }
        });
        setResultsFromAssignmentFilter(resultsCount);
        setSearchResultNodesState(newMap);
      } else {
        setSearchResultNodesState(
          sectionTreeList.createContentSearchFilterState({
            initNodeValue: DEFAULT_HIDDEN_HIGHLIGHT_VALUE,
          }),
        );
      }
    } else if (contentSectionMap.isLoaded && _hasNewSearchResults) {
      let newMap = sectionTreeList.createContentSearchFilterState({
        initNodeValue: HIDDEN_SECTION, // assume all sections will be hidden, we will unhide them as we find them
      });

      let highlightOnlyMap = {}; // map of all bookmark sections that will be highlighted

      searchResultsContent &&
        searchResultsContent.occurrences.forEach((occurrence) => {
          // find closest owning bookmark section
          const closestBookmark = contentSectionMap.getClosestBookmarkSection({
            sectionId: occurrence.sectionId,
          });
          if (isNullOrUndefined(closestBookmark)) {
            console.error(
              'Occurrence does not belong to a bookmark section',
              occurrence,
            );
            return;
          }

          const bookmarkSectionId = closestBookmark.id;

          const _bookmarkAlreadyHighlighted =
            !isNullOrUndefined(bookmarkSectionId) &&
            !isNullOrUndefined(highlightOnlyMap[bookmarkSectionId]) &&
            highlightOnlyMap[bookmarkSectionId] === true;

          if (!_bookmarkAlreadyHighlighted) {
            // Limit of 500 search results may limit efficacy of this implementation as sections that would be included with the 501+ results
            // will not be shown/highlighted
            highlightOnlyMap[bookmarkSectionId] = true;
            newMap.set(bookmarkSectionId, HIGHLIGHTED_SECTION);
          }
        });
      // recursive function usd to un-hide parents of highlighted sections
      const unHideParentSections = (parentId) => {
        if (!contentSectionMap.has(parentId)) {
          return;
        }

        newMap.set(parentId, { ...newMap.get(parentId), hidden: false });
        unHideParentSections(contentSectionMap.get(parentId).parentId);
      };
      // recurse over parents of highlighted sections to un-hide them
      Object.keys(highlightOnlyMap).forEach((sectionId) => {
        unHideParentSections(contentSectionMap.get(sectionId).parentId);
      });

      // set new state once all highlight and hidden properties are determined

      if (searchForSectionAssignmentsMode) {
        let resultsCount = 0;
        newMap.forEach((value, key) => {
          if (
            key in sectionAssignmentsList.assignedSectionsToUser &&
            value.hidden === false &&
            value.highlighted === true
          ) {
            resultsCount += 1;
            newMap.set(key, HIGHLIGHTED_SECTION);
          } else {
            newMap.set(key, HIDDEN_SECTION);
          }
        });
        setResultsFromAssignmentFilter(resultsCount);
        setSearchResultNodesState(newMap);
      } else {
        setSearchResultNodesState(newMap);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultsContent, sectionTreeList, contentSectionMap, searchTerm]);

  const toggleNode = (id) => {
    setCollapsibleNodesState(
      new Map([...collapsibleNodesState, [id, !collapsibleNodesState.get(id)]]),
    );
  };

  const REFRESH_ELEMENT_TOOLTIP = new TooltipOptions({
    text: {
      id: 'table-of-contents.assigned-to-me-refresh.tooltip',
      values: {
        linebreak: (
          <br key={`${TABLE_OF_CONTENTS}-brTag-${shortid.generate()}}`} />
        ),
        button: (...chunks) => (
          <button
            className={`${TABLE_OF_CONTENTS}__dismiss-button`}
            id={`${TABLE_OF_CONTENTS}-dismiss-button`}
            onClick={(e) => {
              e.stopPropagation();
              _setTimeStampInLocalStorage(revisionId);
              setShowRefreshButtonTooltip(false);
            }}
            key={`${TABLE_OF_CONTENTS}-buttonTag-${shortid.generate()}}`}
          >
            {chunks}
          </button>
        ),
      },
    },
    id: `${TABLE_OF_CONTENTS}-tooltip-information`,
    position: 'left',
  });

  const _setTimeStampInLocalStorage = (revisionId) => {
    const startingTime = new Date();
    const endingTime = new Date(
      new Date(startingTime).getTime() + 60 * 60 * 24 * 1000,
    );
    if (localStorage.getItem(REVISION_ID_TIMESTAMP_MAP) === null) {
      const timestampMap = {
        [revisionId]: {
          startingTime: startingTime,
          endingTime: endingTime,
        },
      };
      localStorage.setItem(
        REVISION_ID_TIMESTAMP_MAP,
        JSON.stringify(timestampMap),
      );
    } else {
      let timestampMap = localStorage.getItem(REVISION_ID_TIMESTAMP_MAP);
      timestampMap = JSON.parse(timestampMap);
      if (!(revisionId in timestampMap)) {
        timestampMap[revisionId] = {
          startingTime: startingTime,
          endingTime: endingTime,
        };
      } else {
        const endingTime = new Date(timestampMap[revisionId].endingTime);
        const currentTime = new Date();
        if (currentTime > endingTime) {
          const newEndingTime = new Date(
            new Date(currentTime).getTime() + 60 * 60 * 24 * 1000,
          );
          timestampMap[revisionId] = {
            startingTime: currentTime,
            endingTime: newEndingTime,
          };
        }
      }
      localStorage.setItem(
        REVISION_ID_TIMESTAMP_MAP,
        JSON.stringify(timestampMap),
      );
    }
  };

  const handleRefresh = () => {
    const _hasNewSearchResults =
      searchResultsContent &&
      searchResultsContent.isLoaded &&
      searchResultsContent.hasOccurrences();

    updateAssignedSectionsToUser(_hasNewSearchResults);
  };
  const handleSectionAssignmentClick = () => {
    const _hasNewSearchResults =
      searchResultsContent &&
      searchResultsContent.isLoaded &&
      searchResultsContent.hasOccurrences();

    setSearchForSectionAssignmentsMode((prevState) => !prevState);

    if (searchForSectionAssignmentsMode) {
      toggleAll(false);
      setSectionAssigmentsFilterMode(false);

      if (contentSectionMap.isLoaded && _hasNewSearchResults) {
        let newMap = sectionTreeList.createContentSearchFilterState({
          initNodeValue: HIDDEN_SECTION, // assume all sections will be hidden, we will unhide them as we find them
        });

        let highlightOnlyMap = {}; // map of all bookmark sections that will be highlighted

        searchResultsContent &&
          searchResultsContent.occurrences.forEach((occurrence) => {
            // find closest owning bookmark section
            const closestBookmark = contentSectionMap.getClosestBookmarkSection(
              {
                sectionId: occurrence.sectionId,
              },
            );
            if (isNullOrUndefined(closestBookmark)) {
              console.error(
                'Occurrence does not belong to a bookmark section',
                occurrence,
              );
              return;
            }

            const bookmarkSectionId = closestBookmark.id;

            const _bookmarkAlreadyHighlighted =
              !isNullOrUndefined(bookmarkSectionId) &&
              !isNullOrUndefined(highlightOnlyMap[bookmarkSectionId]) &&
              highlightOnlyMap[bookmarkSectionId] === true;

            if (!_bookmarkAlreadyHighlighted) {
              // Limit of 500 search results may limit efficacy of this implementation as sections that would be included with the 501+ results
              // will not be shown/highlighted
              highlightOnlyMap[bookmarkSectionId] = true;
              newMap.set(bookmarkSectionId, HIGHLIGHTED_SECTION);
            }
          });
        // recursive function usd to un-hide parents of highlighted sections
        const unHideParentSections = (parentId) => {
          if (!contentSectionMap.has(parentId)) {
            return;
          }

          newMap.set(parentId, { ...newMap.get(parentId), hidden: false });
          unHideParentSections(contentSectionMap.get(parentId).parentId);
        };
        // recurse over parents of highlighted sections to un-hide them
        Object.keys(highlightOnlyMap).forEach((sectionId) => {
          unHideParentSections(contentSectionMap.get(sectionId).parentId);
        });

        // set new state once all highlight and hidden properties are determined
        setSearchResultNodesState(newMap);
      } else {
        setSearchResultNodesState(
          sectionTreeList.createContentSearchFilterState({
            initNodeValue: DEFAULT_HIDDEN_HIGHLIGHT_VALUE,
          }),
        );
      }
    } else {
      updateAssignedSectionsToUser(_hasNewSearchResults);
    }
  };

  const updateAssignedSectionsToUser = (_hasNewSearchResults) => {
    let newMap = new Map(searchResultNodesState);

    let resultsCount = 0;
    toggleAll(true);
    setSectionAssigmentsFilterMode(true);
    if (!_hasNewSearchResults) {
      newMap.forEach((value, key) => {
        if (key in sectionAssignmentsList.assignedSectionsToUser) {
          resultsCount += 1;
          newMap.set(key, HIGHLIGHTED_SECTION);
        } else {
          newMap.set(key, HIDDEN_SECTION);
        }
      });
    } else {
      newMap.forEach((value, key) => {
        if (
          key in sectionAssignmentsList.assignedSectionsToUser &&
          value.hidden === false &&
          value.highlighted === true
        ) {
          resultsCount += 1;
          newMap.set(key, HIGHLIGHTED_SECTION);
        } else {
          newMap.set(key, HIDDEN_SECTION);
        }
      });
    }
    setResultsFromAssignmentFilter(resultsCount);
    setSearchResultNodesState(newMap);
  };

  useEffect(() => {
    if (TOCExpandAll) {
      toggleAll(true);
      setTOCExpandAll(null);
    }
  }, [TOCExpandAll, setTOCExpandAll, toggleAll]);

  const allNodesCollapsed = () =>
    [...collapsibleNodesState.values()].some((expanded) => expanded === false);

  const onNodeSelect = (id) => {
    if (selectModeId === ELEMENT_SELECT_MODES.FORMULA.id) {
      toggleFormulaCancelModal(true);
      storeSelectedSectionIdAction(id);
      openSectionPanelAfterCancelConfirmAction(true);
    } else if (selectModeId === ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.id) {
      toggleInternalReferenceCancelModal(true);
      storeSelectedSectionIdAction(id);
      openSectionPanelAfterCancelConfirmAction(true);
    } else {
      onSectionClick({ sectionId: id });
    }
  };

  const findChildNodeById = (node, id) =>
    node.children &&
    node.children.find(
      (x) => x.id === id || (x.children && findChildNodeById(x, id)),
    );

  const onClickEditSection = () => {
    toggleAll(true);
    toggleNavigationEditModeDispatch();
    if (!navigationEditMode) {
      setIsOpenEditSection(false);
    }
  };

  const onOpenEditNavigation = (node) => {
    onNodeSelect(node.id);
    setCurrentNode(node);
    setIsOpenEditSection(true);
  };
  const onOpenDeleteNavigation = (node) => {
    onNodeSelect(node.id);
    setCurrentNode(node);
    setIsOpenDeleteSection(true);
  };

  const onClose = () => {
    setIsOpenEditSection(false);
  };

  const onDeleteSectionClose = () => {
    setIsOpenDeleteSection(false);
  };

  const onSuccess = () => {
    if (currentNode) {
      const message =
        currentNode.name +
        (currentNode.children ? ' and its associated headings have' : ' has') +
        ' been successfully deleted';
      toast.success(message);
    }
  };

  const readOnly = selectedStatement.isReadOnly();

  const statementHasProcessingRevisions = (statement) =>
    workflowsMap &&
    workflowsMap.statementHasProcessingRevision({
      statementId: statement.id,
    });

  const isInProgress = statementHasProcessingRevisions(
    selectedStatement.data.statement,
  );

  const renderTree = (nodes) =>
    Array.isArray(nodes) &&
    nodes.map((node, i) => {
      const { hidden, highlighted } =
        searchResultNodesState.get(node.id) || DEFAULT_HIDDEN_HIGHLIGHT_VALUE;
      const scrollBasedHighlighted = Boolean(
        !isNullOrUndefined(currentBookmark) &&
          (node.id === currentBookmark.id ||
            (collapsibleNodesState.get(node.id) === false &&
              findChildNodeById(node, currentBookmark.id))),
      ); // highlight parent node if parent node is collapsed
      return (
        <TreeView
          key={`${node.id} - ${node.isNew ? 'new' : 'existing'}`}
          id={node.id}
          parentId={node.parentId}
          scrollBasedHighlighted={scrollBasedHighlighted}
          label={node.name}
          expanded={collapsibleNodesState.get(node.id)}
          hidden={hidden}
          highlighted={highlighted}
          toggle={(id) => toggleNode(id)}
          navigationEditMode={navigationEditMode}
          onOpenEditNavigation={() => onOpenEditNavigation(node)}
          onOpenDeleteNavigation={() => onOpenDeleteNavigation(node)}
          onSelect={() => onNodeSelect(node.id)}
          sectionReview={sectionReview}
          panel={left}
          isNew={node.isNew}
          recentlyAdded={
            sectionTreeList.newSectionId === node.id ? true : false
          }
          setNewSectionId={setNewSectionIdAction}
          isSideBySideViewMode={isSideBySideViewMode}
          showEditAndDeleteIconInRow={showEditAndDeleteIconInRow}
          isEditAndDeleteNotPermitted={_isEditBookmarkNotPermitted}
        >
          {renderTree(node.children)}
        </TreeView>
      );
    });

  const _noSearchResults =
    searchResultsContent &&
    searchResultsContent.isLoaded &&
    !searchResultsContent.hasOccurrences();

  if (_noSearchResults) {
    return (
      <div className={`${TABLE_OF_CONTENTS}__no-results`}>
        <FormattedMessage
          id="common.search.no-results"
          values={{
            searchValue: searchTerm,
          }}
        />
      </div>
    );
  }

  const _noAssignments =
    searchForSectionAssignmentsMode &&
    Object.keys(sectionAssignmentsList.assignedSectionsToUser).length === 0;

  const refreshButton = () => {
    return (
      <button
        onClick={() => {
          handleRefresh();
          headingAssignmentRefreshAction(false);
        }}
        className={`${TABLE_OF_CONTENTS}__refresh-button`}
      >
        <Refresh />
      </button>
    );
  };
  const refreshButtonWrapper = () => {
    if (searchForSectionAssignmentsMode && sectionAssignmentHeadingRefresh) {
      if (showRefreshButtonTooltip) {
        return (
          <Tooltip
            {...REFRESH_ELEMENT_TOOLTIP}
            className={`${TABLE_OF_CONTENTS}__refresh-button-tooltip`}
            delay={1000}
            clickable={true}
          >
            {refreshButton()}
          </Tooltip>
        );
      }
      return refreshButton();
    }
  };

  return (
    collapsibleNodesState && (
      <div className={TABLE_OF_CONTENTS}>
        <div className={`${TABLE_OF_CONTENTS}__buttons-container`}>
          <div className={`${TABLE_OF_CONTENTS}__left-buttons`}>
            {allNodesCollapsed() ? (
              <div
                onClick={() => toggleAll(true)}
                className={classNames(
                  `${EDIT_SECTION_MODAL}__edit-section`,
                  `${TABLE_OF_CONTENTS}__edit-button`,
                )}
              >
                <span id={`${TABLE_OF_CONTENTS_ID}-expand-all`}>
                  <ExpandAllIcon />
                </span>
              </div>
            ) : (
              <div
                onClick={() => toggleAll(false)}
                className={classNames(
                  `${EDIT_SECTION_MODAL}__edit-section`,
                  `${TABLE_OF_CONTENTS}__edit-button`,
                )}
              >
                <span id={`${TABLE_OF_CONTENTS_ID}-collapse-all`}>
                  <CollapseAllIcon />
                </span>
              </div>
            )}
            {!isSideBySideViewMode && (
              <>
                <div
                  className={classNames(
                    `${TABLE_OF_CONTENTS}__assigned-to-me`,
                    searchForSectionAssignmentsMode &&
                      `${TABLE_OF_CONTENTS}__assigned-to-me--active`,
                  )}
                >
                  <button
                    onClick={() => {
                      handleSectionAssignmentClick();
                    }}
                    className={`${TABLE_OF_CONTENTS}__assigned-to-me-button`}
                    disabled={searchForSectionAssignmentsMode}
                  >
                    <FormattedMessage
                      id={'table-of-contents.assigned-to-me.button'}
                    />
                  </button>
                  {searchForSectionAssignmentsMode && (
                    <IconButton
                      id={`${TABLE_OF_CONTENTS_ID}__close-assignments-filter`}
                      onClick={() => {
                        handleSectionAssignmentClick();
                      }}
                      className={`${TABLE_OF_CONTENTS}__assigned-to-me-close-button`}
                      Icon={CloseButton}
                    />
                  )}
                </div>

                <div
                  disabled={doesNotHaveEditPermission}
                  onClick={() => onClickEditSection()}
                  className={classNames(
                    `${TABLE_OF_CONTENTS}__edit-button`,
                    `${TABLE_OF_CONTENTS}__edit-button__navigation`,
                  )}
                >
                  {!readOnly && !isInProgress && navigationEditMode && (
                    <FormattedMessage
                      id={'edit-section-modal.mode.disable.edit'}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {!isSideBySideViewMode && (
            <div
              className={classNames(
                `${TABLE_OF_CONTENTS}_right-menu-option-button`,
                {
                  [`${TABLE_OF_CONTENTS}_right-menu-option-button__disabled`]:
                    _isBulkAssignNotPermitted,
                },
              )}
              ref={containerRef}
            >
              <div
                className={classNames(
                  `${TABLE_OF_CONTENTS}_right-menu-option-button-title-wrapper`,
                  {
                    [`${TABLE_OF_CONTENTS}_right-menu-option-button-title-wrapper__disabled`]:
                      _isBulkAssignNotPermitted,
                  },
                )}
                onClick={() =>
                  !_isBulkAssignNotPermitted && setShowBulkAssignModal(true)
                }
              >
                <FormattedMessage
                  id={'statement-nav-heading-action-dropdown.bulk.assign'}
                />
              </div>
            </div>
          )}
        </div>
        {!isSideBySideViewMode && _noAssignments && (
          <div className={`${TABLE_OF_CONTENTS}__no-results`}>
            <FormattedMessage id="table-of-contents.no-assigned-sections" />
          </div>
        )}
        <div className={`${TABLE_OF_CONTENTS}__tree-view-container`}>
          {renderTree(sectionTreeList.sectionsHierarchy)}
        </div>
        {!_isEditBookmarkNotPermitted &&
          isOpenEditSection &&
          !readOnly &&
          !isInProgress && (
            <EditSectionModal
              node={currentNode}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          )}
        {!_isEditBookmarkNotPermitted &&
          isOpenDeleteSection &&
          !readOnly &&
          !isInProgress && (
            <DeleteSectionModal
              node={currentNode}
              onClose={onDeleteSectionClose}
              onSuccess={onSuccess}
            />
          )}
        <div>{refreshButtonWrapper()}</div>
        {!_isBulkAssignNotPermitted && showBulkAssignModal && (
          <BulkAssignSectionsModal
            onClose={() => setShowBulkAssignModal(false)}
            usersList={projectUsersList.users}
            revisionId={revisionId}
            sectionTreeList={sectionTreeList}
            sectionAssignments={sectionAssignmentsList}
            currentSection={selectedSection}
            updateBulkSectionAssignmentsList={updateBulkSectionAssignmentsList}
            updateBulkSectionsReviewRequest={updateBulkSectionsReviewRequest}
            updateBulkSectionsUnReviewRequest={
              updateBulkSectionsUnReviewRequest
            }
            socketHasBeenDisconnected={socketHasBeenDisconnected}
          />
        )}
      </div>
    )
  );
};

TableOfContentsTreeView.propTypes = {
  /** Function fired on section click  */
  onSectionClick: PropTypes.func.isRequired,
  /** Tree list of section hirearchy */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList),
  /** Search string */
  searchTerm: PropTypes.string,
  /** Api model of search results */
  searchResultsContent: PropTypes.instanceOf(ContentSearchResults),
  /** Currently processing/failed statements */
  workflowsMap: PropTypes.instanceOf(WorkflowsMap),
  /** contains list of section reviews */
  sectionReview: PropTypes.instanceOf(SectionReviewList),
  /** Indicates which left panel is open */
  left: PropTypes.string,
  /* action to set the new section value */
  setNewSectionIdAction: PropTypes.func,
  /* section assignment list model */
  sectionAssignmentsList: PropTypes.instanceOf(SectionAssignmentsList),
  /* function to set mode when user filters on sections */
  setSectionAssigmentsFilterMode: PropTypes.func,
  /* function to get the number of filtered sections */
  setResultsFromAssignmentFilter: PropTypes.func,
  /**Field to determine if the refresh button should be displayed or not */
  sectionAssignmentHeadingRefresh: PropTypes.bool,
  /**Redux Action to set the refresh button based on the heading update */
  headingAssignmentRefreshAction: PropTypes.func,
  /** the id of the selected revision */
  revisionId: PropTypes.number,
  /**An object which contains projects users details and userID to username map */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList),
  /** Section from the section detail store */
  selectedSection: PropTypes.object,
  /** Action to update/fetch bulk assignments for a section */
  updateBulkSectionAssignmentsList: PropTypes.func,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement),
  /** Currently selected project id of the revision we are viewing */
  selectedProject: PropTypes.instanceOf(ProjectApi),
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool,
  /** the id of the selected section */
  selectModeId: PropTypes.number,
  /**The action to open the formula cancel modal. */
  toggleFormulaCancelModal: PropTypes.func,
  /**The action to open the IR cancel modal. */
  toggleInternalReferenceCancelModal: PropTypes.func,
  /**The action to store the selected section Id. */
  storeSelectedSectionIdAction: PropTypes.func,
  /**The action to Confirm cancel*/
  openSectionPanelAfterCancelConfirmAction: PropTypes.func,
  /** Action to update/fetch bulk un review for a section */
  updateBulkSectionsReviewRequest: PropTypes.func.isRequired,
  /** Action to update/fetch bulk un review for a section */
  updateBulkSectionsUnReviewRequest: PropTypes.func,
};

export { TableOfContentsTreeView };
export default memo(TableOfContentsTreeView);
