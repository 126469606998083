import { createAction } from 'redux-actions';

export const contentSearchResultLoading = createAction(
  'CONTENT_SEARCH_RESULT_LOADING',
);
export const contentSearchResultLoaded = createAction(
  'CONTENT_SEARCH_RESULT_LOADED',
);
export const contentSearchResultError = createAction(
  'CONTENT_SEARCH_RESULT_ERROR',
);
export const clearContentSearchResult = createAction(
  'CLEAR_CONTENT_SEARCH_RESULT',
);
export const setContentSearchResultSelectedIndex = createAction(
  'SET_CONTENT_SEARCH_RESULT_SELECTED_INDEX',
);
