import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AnnotationCalloutComponent = ({
  elementId,
  BLOCK,
  position,
  maxWidth,
  customStyle,
  onClick,
  children,
  Id = elementId,
  fontSize,
}) => {
  return (
    <span
      id={Id}
      className={classNames(`${BLOCK}__callout-container`, {
        [`${BLOCK}__callout-container--left`]: position === 'left',
        [`${BLOCK}__callout-container--right`]: position === 'right',
        [`${BLOCK}__callout-container--bottom`]: position === 'bottom',
        [`${BLOCK}__callout-container--top`]: position === 'top',
      })}
      style={{
        position: 'absolute',
        ...customStyle,
        width: 'fit-content',
        maxWidth,
        zIndex: 52,
        fontSize: fontSize,
      }}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={() => {
        onClick();
      }}
    >
      {children}
    </span>
  );
};

export default AnnotationCalloutComponent;

PropTypes.AnnotationCalloutComponent = {
  /**Id for an element */
  elementId: PropTypes.string.isRequired,
  /**name of the parent class. To be used for style purpose */
  BLOCK: PropTypes.string.isRequired,
  /**position where to show callout */
  position: PropTypes.string.isRequired,
  /**max width of callout */
  maxWidth: PropTypes.number,
  /**additional style to be added at element level */
  customStyle: PropTypes.object,
  /**function to be fired when callout is clicked */
  onClick: PropTypes.func,
  /**node to be rendered within callout */
  children: PropTypes.node,
  /**Id for a callout */
  Id: PropTypes.string.isRequired,
};
