import { handleActions } from 'redux-actions';
import {
  toggleBlacklineViewShowElementMode,
  resetBlacklineViewShowElementMode,
} from 'store/actions/blackline-view-actions';

export default handleActions(
  {
    [toggleBlacklineViewShowElementMode]: (blacklineViewShowElementMode) => {
      return !blacklineViewShowElementMode;
    },
    [resetBlacklineViewShowElementMode]: () => {
      return false;
    },
  },
  false,
);
