import React, { Fragment, useState } from 'react';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'components/common/checkbox-component';
import { OPTIONS_CHECKBOX_ARRAY } from 'constants/feature/report-preview-page-contants';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import classNames from 'classnames';

export const REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN =
  'report-preview-page-options-dropdown';
export const REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID =
  'report-preview-page-options-dropdown-id';
const ICON_SIZE = 20;
const ELEMENT_TITLE_INDEX = 1;
const COMFORT_LETTER_INDEX = 11; // This key should be same as in OPTIONS_CHECKBOX_ARRAY for comfortLetter
export const BATCH_BLOCK = 'batch-panel';

const ReportPreviewPageDisplayOptions = ({
  reportPreviewPageDisplayOptions,
  setPreviewPageDisplayOptionAction,
  isCftEnabled,
  toggleViews,
}) => {
  const [displayMenu, setDisplayMenu] = useState(false);

  return (
    <div className={REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}>
      <div
        className={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__button-container`}
      >
        <Tooltip
          text="report.preview.header.info.label"
          id={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-info-tooltip`}
        >
          <Information width={ICON_SIZE} height={ICON_SIZE} />
        </Tooltip>
        <div
          className={classNames(
            `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__button-container--item`,
            toggleViews &&
              `${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__button-container--item__reverse`,
          )}
          onClick={() => setDisplayMenu(!displayMenu)}
        >
          <span
            className={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__button-container--text`}
          >
            <FormattedMessage id={'report.preview.header.button.label'} />
          </span>
        </div>
      </div>
      <Flyout className={BATCH_BLOCK} show={displayMenu}>
        <FlyoutHeader enableClose onClose={() => setDisplayMenu(!displayMenu)}>
          <h3 className={`${BATCH_BLOCK}__title`}>
            <FormattedMessage id={'report.preview.header.label'} />
          </h3>
        </FlyoutHeader>
        <FlyoutPanelContent className={`${BATCH_BLOCK}__body`}>
          <div className={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__menu`}>
            <div
              className={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__menu-options`}
            >
              <div
                className={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__options`}
              >
                {OPTIONS_CHECKBOX_ARRAY.map((item, iter) => {
                  return COMFORT_LETTER_INDEX === iter && !isCftEnabled ? (
                    <Fragment key={`${item.name}-${item.key}`}></Fragment>
                  ) : (
                    <div
                      className={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__checkbox-container`}
                      key={`${item.name}-${item.key}`}
                    >
                      {iter === ELEMENT_TITLE_INDEX + 1 ? (
                        <div
                          className={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN}__line-break`}
                        ></div>
                      ) : (
                        ''
                      )}
                      <Checkbox
                        name={item.name}
                        checked={reportPreviewPageDisplayOptions[item.name]}
                        value={item.name}
                        onChange={(key) =>
                          setPreviewPageDisplayOptionAction({ key })
                        }
                        label={item.label}
                        id={`${REPORT_PREVIEW_PAGE_OPTIONS_DROPDOWN_ID}-${item.key}`}
                        disabled={iter === ELEMENT_TITLE_INDEX}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </FlyoutPanelContent>
      </Flyout>
    </div>
  );
};

export default ReportPreviewPageDisplayOptions;
