import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BlacklineViewToggle from 'components/common/blackline-view-toggle-component';
import Tooltip from 'components/common/tool-tip-component';
import StatementSummary from 'components/feature/statement-summary/statement-summary-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import Revision from 'models/api/revision-api-model';
import StatementContentFiltersModel from 'models/data/statement-content-filters-model';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import SelectedStatement from 'models/api/selected-statement-model';
import ActiveUsersList from 'components/feature/active-users/active-users-list-component';
import ActiveUsers from 'models/api/active-users-api-model';
import { ReactComponent as RefreshIcon } from 'icons/refresh-heading.svg';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import useScreenSize from 'components/hooks/useScreenSize';

const GLOBAL_HEADER_BLOCK = 'global-header';
const GLOBAL_HEADER_ID_BLOCK = 'global-header-id';

const GlobalHeader = ({
  revision,
  push,
  blacklineViewMode,
  toggleBlacklineViewMode,
  selectedStatement,
  activeUsers,
  showNavigationRefreshButton,
  blacklineSourceGuidList,
  blacklineTargetGuidList,
}) => {
  const centerSectionRef = useRef();
  const [centerSectionWidth, setCenterSectionWidth] = useState(0);
  const [showBlacklinebanner, setShowBlacklineBanner] = useState(false);
  const screenSize = useScreenSize();
  const shouldShowBlackLineToggle =
    revision.revisionNumber > 1 && !selectedStatement.statement.isWrappedUp();
  const isBlacklineGuidListEmpty =
    blacklineTargetGuidList.isGuidListEmpty ||
    blacklineSourceGuidList.isGuidListEmpty;

  useEffect(() => {
    if (blacklineViewMode && isBlacklineGuidListEmpty) {
      setShowBlacklineBanner(true);
    }
    if (!blacklineViewMode) {
      setShowBlacklineBanner(false);
    }
  }, [blacklineViewMode, isBlacklineGuidListEmpty]);

  useEffect(() => {
    if (centerSectionRef && centerSectionRef.current) {
      setCenterSectionWidth(centerSectionRef.current.offsetWidth);
    }
  }, [blacklineViewMode, isBlacklineGuidListEmpty, screenSize]);

  return (
    <div className={GLOBAL_HEADER_BLOCK}>
      <div
        className={classNames(
          `${GLOBAL_HEADER_BLOCK}__container`,
          `${GLOBAL_HEADER_BLOCK}__container--left`,
        )}
      >
        <Button.IconButton
          id={`${GLOBAL_HEADER_ID_BLOCK}__back-button`}
          className={`${GLOBAL_HEADER_BLOCK}__back-button`}
          type={BUTTON_TYPES.icon}
          Icon={BackButton}
          onClick={() => push(ROUTE_CONSTANTS.HOME_PAGE)}
        />
        {revision.isInitialized() ? (
          <Tooltip
            isNotInternationalized={true}
            {...new TooltipOptions({
              text: revision.statementName,
              id: `${GLOBAL_HEADER_ID_BLOCK}-statement-tooltip`,
              position: 'bottom',
            })}
          >
            <div className={`${GLOBAL_HEADER_BLOCK}__statement-name`}>
              {revision.statementName}
            </div>
          </Tooltip>
        ) : null}
        {revision.isInitialized() ? (
          <div
            className={`${GLOBAL_HEADER_BLOCK}__revision-number`}
          >{`V${revision.revisionNumber}`}</div>
        ) : null}
        {showNavigationRefreshButton && (
          <Button.IconButton
            id={`${GLOBAL_HEADER_ID_BLOCK}__refresh-navigation-button`}
            className={`${GLOBAL_HEADER_BLOCK}__refresh-icon`}
            type={BUTTON_TYPES.icon}
            Icon={RefreshIcon}
            onClick={() => window.location.reload()}
            tooltip={
              new TooltipOptions({
                text: 'global-header-statement-navigation-refresh.button',
                id: `${GLOBAL_HEADER_ID_BLOCK}-statement-tooltip-refresh-nav`,
                position: 'bottom',
              })
            }
          />
        )}
      </div>
      <div
        ref={centerSectionRef}
        className={classNames(
          `${GLOBAL_HEADER_BLOCK}__container`,
          `${GLOBAL_HEADER_BLOCK}__container--center`,
        )}
      >
        {showBlacklinebanner && (
          <Banner
            id={`${GLOBAL_HEADER_ID_BLOCK}-correct-parameters-banner`}
            className={`${GLOBAL_HEADER_BLOCK}__banner`}
            type={BANNER_TYPES.WARNING}
            isInteractive
            width={`${centerSectionWidth}px`}
            bannerCopy={'ocr.revision-upload.blackline-banner'}
            isInternationalized={true}
            onClose={() => setShowBlacklineBanner(false)}
          />
        )}
        <StatementSummary className={`${GLOBAL_HEADER_BLOCK}__summary`} />
      </div>
      <div
        className={classNames(
          `${GLOBAL_HEADER_BLOCK}__container`,
          `${GLOBAL_HEADER_BLOCK}__container--right`,
        )}
      >
        <ActiveUsersList
          shouldShowBlackLineToggle={shouldShowBlackLineToggle}
          activeUsersList={activeUsers}
        />
        {revision.revisionNumber > 1 &&
          !selectedStatement.statement.isWrappedUp() && (
            <BlacklineViewToggle
              blacklineViewMode={blacklineViewMode}
              toggleBlacklineViewMode={toggleBlacklineViewMode}
            />
          )}
      </div>
    </div>
  );
};

GlobalHeader.propTypes = {
  /** The revision whose statement name and revision version number are displayed on the global header */
  revision: PropTypes.instanceOf(Revision),
  /** The current filters being set on the statement content page. This can include things like the currently selected tab. */
  statementContentFilters: PropTypes.instanceOf(StatementContentFiltersModel),
  /** Action fired when a user changes the currently selected tab in the header*/
  setCurrentStatementContentTab: PropTypes.func.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /* array of active users on the current statement */
  activeUsers: PropTypes.instanceOf(ActiveUsers).isRequired,
  /** Setting this to to false will hide the Refresh Navigation Button */
  showNavigationRefreshButton: PropTypes.bool,
};

export default memo(GlobalHeader);
