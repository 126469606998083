import {
  sectionReviewRequest,
  sectionUnReviewRequest,
} from 'api/section-bulk-review-unreview-api';
import {
  fetchSectionReviewsError,
  removeBulkSectionReviewFromStore,
} from 'store/actions/sections-actions';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { fetchSectionHistoryDetails } from 'store/actions/section-review-history-actions';
import { fetchStatementSummarySections } from 'store/actions/statement-summary/sections-summary-actions';
import { mergeSectionReviewToSectionReviewList } from 'store/actions/sections-actions';

export const updateBulkSectionsReviewRequest =
  ({ revisionId, sectionIds, currentSection }) =>
  async (dispatch, getState) => {
    const {
      ui: {
        statementPage: {
          panels: { right },
        },
      },
    } = getState();
    try {
      const response = await sectionReviewRequest({ revisionId, sectionIds });
      if (response && response.status === 201) {
        await dispatch(mergeSectionReviewToSectionReviewList(response.data));
        await dispatch(fetchStatementSummarySections({ revisionId }));
        const currentSectionId = currentSection.id;
        if (
          right === RIGHT_PANELS.SECTION &&
          sectionIds.indexOf(currentSectionId) >= 0
        ) {
          dispatch(
            fetchSectionHistoryDetails({
              revisionId,
              sectionId: currentSection.id,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(fetchSectionReviewsError(error));
    }
  };

export const updateBulkSectionsUnReviewRequest =
  ({ revisionId, sectionIds, currentSection }) =>
  async (dispatch, getState) => {
    const {
      ui: {
        statementPage: {
          panels: { right },
        },
      },
    } = getState();
    try {
      const response = await sectionUnReviewRequest({ revisionId, sectionIds });
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.length
      ) {
        await dispatch(removeBulkSectionReviewFromStore(response.data));
        await dispatch(fetchStatementSummarySections({ revisionId }));
        const currentSectionId = currentSection.id;
        if (
          right === RIGHT_PANELS.SECTION &&
          sectionIds.indexOf(currentSectionId) >= 0
        ) {
          dispatch(
            fetchSectionHistoryDetails({
              revisionId,
              sectionId: currentSection.id,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(fetchSectionReviewsError(error));
    }
  };
