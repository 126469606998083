import React from 'react';
import { connect } from 'react-redux';
import StatementContentPanelOCR from 'components/feature/statement-content-panel/statement-content-panel-component-ocr';
import withURLRouter from 'withURLRouter';
import {
  setLeftSectionInView,
  clearLeftSectionInView,
} from 'store/actions/left-statement-section-content-actions';
import {
  setSectionInView,
  clearSectionInView,
} from 'store/actions/statement-content-actions';
import { setFirstElementVisibleInViewPortAction } from 'store/actions/preview-report-page-actions';

const StatementContentPanelContainerOCR = (props) => {
  return <StatementContentPanelOCR {...props} />;
};

const mapStateToProps = ({
  data: {
    blacklineSourceGuidList,
    blacklineTargetGuidList,
    statementContent: {
      sectionIdList,
      sectionsCache: { cache, ocrCache, blacklineCache },
      elementCache,
      notesCache,
    },
    leftStatementContent: {
      leftSectionIdList,
      leftSectionsCache: { leftCache, leftOcrCache, leftBlacklineCache },
      leftElementCache,
      leftNotesCache,
    },
  },
  ui: {
    statementPage: {
      zoom,
      leftZoom,
      rightZoom,
      modes: { blacklineViewMode, ocrCurrentViewPageNumber, leftOcrCurrentViewPageNumber },
    },
    previewPage: { isSyncScrollingEnabled },
  },
}) => ({
  sectionIdList,
  leftSectionIdList,
  cache,
  leftCache,
  ocrCache,
  leftOcrCache,
  elementCache,
  leftElementCache,
  leftNotesCache,
  blacklineCache,
  leftBlacklineCache,
  blacklineSourceGuidList,
  blacklineTargetGuidList,
  zoom,
  leftZoom,
  rightZoom,
  blacklineViewMode,
  notesCache,
  isSyncScrollingEnabled,
  ocrCurrentViewPageNumber, 
  leftOcrCurrentViewPageNumber
});

const mapDispatchToProps = {
  setSectionInView,
  clearSectionInView,
  setLeftSectionInView,
  clearLeftSectionInView,
  setFirstElementVisibleInViewPortAction,
};

export default withURLRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StatementContentPanelContainerOCR),
);
