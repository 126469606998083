import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserSettingsModal from './user-settings-modal';

const DAUserSettings = () => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const { currentUser } = useSelector((state) => state.data);
  const { locale } = useSelector((state) => state.ui);
  useEffect(() => {
    window.addEventListener('onHeaderPluginSettings', () => {
      console.debug('show settings modal');
      setShowSettingsModal(true);
    });
  }, []);
  return showSettingsModal &&
    currentUser &&
    currentUser.data &&
    currentUser.data.id ? (
    <UserSettingsModal
      onClose={() => setShowSettingsModal(false)}
      currentUser={currentUser}
      locale={locale}
    />
  ) : null;
};

export default DAUserSettings;
