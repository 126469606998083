import { handleActions } from 'redux-actions';
import {
  leftSectionHTMLSegmentsStatementContentLoading,
  leftSectionHTMLSegmentsStatementContentLoaded,
  leftSectionHTMLSegmentsStatementContentError,
  leftSectionIdListStatementContentLoading,
  leftSectionIdListStatementContentLoaded,
  leftSectionIdListStatementContentError,
  clearStatementPageStoreAction,
  clearLeftStatementPageStoreAction,
  leftSectionHTMLSegmentsStatementContentLoadedWithoutResponse,
  clearLeftOCRSectionImageWithIdsFromCache,
  clearLeftStatementContent,
} from 'store/actions/statement-content-actions';
import {
  leftSectionOCRImageStatementContentLoaded,
  leftSectionOCRImageStatementContentError,
} from 'store/actions/statement-content-actions-ocr';
import { clearLeftSectionContentWithIdsFromCache } from 'store/actions/left-statement-section-content-actions';

import { LeftSectionCache } from 'models/api/left-sections-cache-model';
export default handleActions(
  {
    [leftSectionIdListStatementContentLoading]: (sectionCache) => {
      return sectionCache.setLoading();
    },
    [leftSectionIdListStatementContentLoaded]: (sectionCache, { payload }) => {
      return sectionCache.setLoaded(payload);
    },
    [leftSectionIdListStatementContentError]: (sectionCache, { payload }) => {
      return sectionCache.setError(payload);
    },
    [leftSectionHTMLSegmentsStatementContentLoading]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeSectionsLoading(payload);
    },
    [leftSectionHTMLSegmentsStatementContentLoaded]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeSectionsLoaded(payload);
    },
    [leftSectionHTMLSegmentsStatementContentLoadedWithoutResponse]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeSectionsLoadedWithoutResponse(payload);
    },
    [leftSectionHTMLSegmentsStatementContentError]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.setError(payload);
    },
    [clearLeftSectionContentWithIdsFromCache]: (sectionCache, { payload }) => {
      return sectionCache.clearSectionContentFromMap(payload);
    },
    [clearLeftStatementContent]: () => {
      return new LeftSectionCache();
    },
    [clearStatementPageStoreAction]: (sectionCache, { payload }) => {
      return new LeftSectionCache();
    },
    [clearLeftStatementPageStoreAction]: (sectionCache, { payload }) => {
      return new LeftSectionCache();
    },
    [clearLeftOCRSectionImageWithIdsFromCache]: (sectionCache, { payload }) => {
      return sectionCache.clearLeftSectionImageContentFromMap(payload);
    },
    [leftSectionOCRImageStatementContentLoaded]: (
      sectionCache,
      { payload },
    ) => {
      return sectionCache.mergeOCRSectionLoaded(payload);
    },
    [leftSectionOCRImageStatementContentError]: (sectionCache, { payload }) => {
      return sectionCache.setError(payload);
    },
  },
  new LeftSectionCache(),
);
