import React from 'react';
import PropTypes from 'prop-types';
import DeleteConfirmationModal from './delete-confirmation-modal';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { fetchTheListOfNotes } from 'store/actions/notes-panel-actions';
import { fetchStatementSummarySections } from 'store/actions/statement-summary/sections-summary-actions';
import { connect } from 'react-redux';
import { deleteRevisionsSectionRequest } from 'api/sections-api';
import { OK } from 'http-status-codes';
import { immediateScrollToSectionWhenLoaded } from 'utils/scrolling-utils';
import {
  fetchAllSectionRenderingData,
  fetchSectionIdListStatementContent,
  updateSectionDetailsStore,
} from 'store/actions/statement-content-actions';
import { getContentSectionMapRequest } from 'store/actions/section-cache-actions';
import { setTOCRefresh } from 'store/actions/TOC-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import { sectionTreeListLoading } from 'store/actions/section-tree-list-actions';
import SectionContentModel from 'models/api/section-content-api-model';

const DeleteSectionModal = ({
  onClose,
  onSuccess,
  node,
  socketHasBeenDisconnected,
  fetchTheListOfNotes,
  fetchStatementSummarySections,
  fetchSectionIdListStatementContent,
  getContentSectionMapRequest,
  setTOCRefresh,
  fetchStatementSummaryElements,
  fetchAllSectionRenderingData,
  updateSectionDetailsStore,
  sectionTreeListLoading,
  revision,
  sectionReviewList,
  panels,
}) => {
  const onSave = async (node, name, onSuccess) => {
    const section = { ...node };
    const sectionId = section.id;

    let isSectionReviewed = 0;
    if (sectionReviewList && sectionReviewList.sectionReviews) {
      for (let i = 0; i < sectionReviewList.sectionReviews.length; i++) {
        if (sectionReviewList.sectionReviews[i].sectionId === sectionId) {
          isSectionReviewed = 1;
          break;
        }
      }
    }
    onClose();

    sectionTreeListLoading();
    await deleteRevisionsSectionRequest(
      section.revisionId,
      sectionId,
      name,
      isSectionReviewed,
    ).then((resp) => {
      if (resp && resp.status === OK) {
        onSuccess();
        fetchSectionIdListStatementContent(section.revisionId);
        getContentSectionMapRequest({
          revisionId: section.revisionId,
        });
        setTOCRefresh(true);
        if (socketHasBeenDisconnected) {
          fetchStatementSummaryElements({
            revisionId: section.revisionId,
          });
        }
        if (resp.data && resp.data.id) {
          fetchAllSectionRenderingData({
            sectionId: resp.data.id,
          });
          immediateScrollToSectionWhenLoaded({ sectionId: resp.data.id });
          if (panels.right === RIGHT_PANELS.SECTION) {
            updateSectionDetailsStore({ sectionId, isDelete: true });
          }
        }
      }
    });
  };

  const onConfirmDelete = async () => {
    await onSave(node, node.name, onSuccess);
    if (panels.right === RIGHT_PANELS.NOTES) {
      fetchTheListOfNotes({ revisionId: revision.id });
    }
    if (socketHasBeenDisconnected) {
      fetchStatementSummarySections({ revisionId: revision.id });
    }
  };

  return (
    <>
      <DeleteConfirmationModal onClose={onClose} onDelete={onConfirmDelete} />
    </>
  );
};

const mapStateToProps = ({
  data: {
    revision,
    sectionPanel: { sectionReviewList },
  },
  ui: {
    statementPage: { panels },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  revision,
  panels,
  socketHasBeenDisconnected,
  sectionReviewList,
});

const mapDispatchToProps = {
  fetchTheListOfNotes,
  fetchStatementSummarySections,
  fetchSectionIdListStatementContent,
  getContentSectionMapRequest,
  setTOCRefresh,
  fetchStatementSummaryElements,
  fetchAllSectionRenderingData,
  updateSectionDetailsStore,
  sectionTreeListLoading,
};

DeleteSectionModal.propTypes = {
  /** Section the segment is responsible for fetching and rendering */
  node: PropTypes.instanceOf(SectionContentModel),
  /** Modal panel onClose fucntion  */
  onClose: PropTypes.func.isRequired,
  /** redux action to refresh table of content panel  */
  setTOCRefresh: PropTypes.func.isRequired,
  /** redux action to retrieve section details  */
  fetchAllSectionRenderingData: PropTypes.func.isRequired,
  /** redux action to retrieve statement summary elements  */
  fetchStatementSummaryElements: PropTypes.func.isRequired,
  /** redux action to retrieve content section map  */
  getContentSectionMapRequest: PropTypes.func.isRequired,
  /** redux action to retrieve section id list  */
  fetchSectionIdListStatementContent: PropTypes.func.isRequired,
  /** redux action to load and scroll to selected section in content body  */
  immediateScrollToSectionWhenLoaded: PropTypes.func.isRequired,
  /** Action fired to update or delete the selection section details in store */
  updateSectionDetailsStore: PropTypes.func.isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
  /** method to fetch the notes list  */
  fetchTheListOfNotes: PropTypes.func.isRequired,
  /** action that updates the number of sections in the header */
  fetchStatementSummarySections: PropTypes.func.isRequired,
  /**action to set loading state for section tree list */
  sectionTreeListLoading: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSectionModal);
