import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ConditionalRender from 'components/util/conditional-render-component';
import { FormattedMessage } from 'react-intl';
import Draggable from 'react-draggable';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as ArrowDown } from 'icons/arrow-simple-thick-down.svg';
import {
  MAXIMIZE_TOOLTIP,
  MINIMIZE_TOOLTIP,
  RETRY_TOOLTIP,
  DELETE_PERMANENTLY_TOOLTIP,
} from 'constants/feature/statement-processing-constants';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';
import { DEFAULT_SELECTED_PROJECT_ID } from 'constants/feature/selected-project-constants';
import RetryConfirmationModal from 'components/feature/statement-processing/retry-confirmation-modal';
import CancelConfirmationModal from 'components/feature/statement-processing/cancel-confirmation-modal';
import { ReactComponent as Cancel } from 'icons/close-no-circle.svg';
import { ReactComponent as Refresh } from 'icons/refresh-no-circle.svg';
import { toast } from 'react-toastify';
import Notification from 'components/common/notification-component';
import { ReactComponent as CloseButton } from 'icons/close-button-red.svg';
import StatementWorkflowController from './statement-workflow-controller-component';
import { StatementWorkflowStepsData } from 'models/api/statement-workflow-steps-model';

export const STATEMENT_PROCESSING_BLOCK = 'statement-processing';
const STATEMENT_PROCESSING_ID_BLOCK = 'statement-processing-id';
const CANCEL_ICON_SIZE = '24px';
const REFRESH_ICON_SIZE = '24px';
const ARROW_DOWN_SIZE = '24px';

class StatementProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showRetryConfirmModal: false,
      showCancelConfirmModal: false,
      currentWorkflowRevisionId: null,
      currentWorkflowStatementId: null,
      isDiplayedInfectedErrorToast: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      clearProjectIdWorkflowExpanded,
      selectedProjectID,
      workflowsMap,
      permanentlyDeleteWorkflowRevision,
      statementWorkflowSteps,
    } = this.props;
    const _shouldExpandByDefault =
      prevProps.expandByDefault === false &&
      this.props.expandByDefault === true;
    const _userSwitchedProject =
      selectedProjectID !== DEFAULT_SELECTED_PROJECT_ID &&
      prevProps.selectedProjectID !== selectedProjectID;
    if (_shouldExpandByDefault) {
      this.setState({
        isOpen: true,
      });
    } else if (_userSwitchedProject) {
      this.setState({ isOpen: false });
      clearProjectIdWorkflowExpanded();
    }

    const workflowsChanged = !workflowsMap.areWorkFlowsEqual(
      prevProps.workflowsMap,
      statementWorkflowSteps,
    );

    if (workflowsChanged) {
      workflowsMap &&
        workflowsMap.getWorkflowsListData().forEach((workflow) => {
          const workFlowStatus = workflow.getWorkFlowStatus(
            statementWorkflowSteps.getWorkflowStepsByDocProcessType(
              workflow.documentProcessTypeEnum,
            ),
          );
          const previousWorkFlowStatus = workflow.getPreviousWorkflowStatus(
            statementWorkflowSteps.getWorkflowStepsByDocProcessType(
              workflow.documentProcessTypeEnum,
            ),
          );
          if (
            workFlowStatus &&
            previousWorkFlowStatus &&
            this._hasWorkFlowFailed(workFlowStatus.status, workflow) &&
            this._isWorkFlowScanning(previousWorkFlowStatus.status, workflow) &&
            workflow.errorInfo === 'symantec scan failed' &&
            !this.state.isDiplayedInfectedErrorToast[workflow.revisionId]
          ) {
            this._handleInfected(workflow);
            permanentlyDeleteWorkflowRevision(
              workflow.statementId,
              workflow.revisionId,
            );
          }
        });
    }
  }

  componentWillUnmount() {
    const { clearProjectIdWorkflowExpanded } = this.props;
    clearProjectIdWorkflowExpanded();
  }

  _toggleProcessingList = () => {
    // clear client expanded prop after user interacts
    this.props.clearProjectIdWorkflowExpanded();
    this.setState((state, props) => {
      return {
        isOpen: !state.isOpen,
      };
    });
  };

  _hasWorkFlowFailed = (workFlowStatus, workflow) => {
    const { statementWorkflowSteps } = this.props;
    const docWorkflowSteps = statementWorkflowSteps.isFailedWorkflowStep(
      workflow.documentProcessTypeEnum,
    );
    return workFlowStatus === docWorkflowSteps.status;
  };

  _isWorkFlowScanning = (workFlowStatus, workflow) => {
    const { statementWorkflowSteps } = this.props;
    const docWorkflowSteps = statementWorkflowSteps.isScanningWorkflowStep(
      workflow.documentProcessTypeEnum,
    );
    return workFlowStatus === docWorkflowSteps.status;
  };

  _setRetryConfirmModal = () => {
    this.setState({
      showRetryConfirmModal: !this.state.showRetryConfirmModal,
    });
  };

  _setCancelConfirmModal = () => {
    this.setState({
      showCancelConfirmModal: !this.state.showCancelConfirmModal,
    });
  };

  _handleInfected = (workflow) => {
    this.setState(
      (prevState) => ({
        isDiplayedInfectedErrorToast: {
          [workflow.revisionId]: true,
          ...prevState.isDiplayedInfectedErrorToast,
        },
      }),
      () => {
        toast.error(
          <Notification
            icon={<CloseButton />}
            message={{
              id: 'statement-processing.step-failed-for-scanning-message',
            }}
            title={{
              id: 'statement-processing.step-failed-for-scanning-title',
            }}
          />,
          { autoClose: 15000 },
        );
      },
    );
  };

  _minifiedPopover = () => {
    const { workflowsMap, statementWorkflowSteps } = this.props;
    const processingWorkflowsTotal = workflowsMap.getProcessingWorkflowsTotal(
      statementWorkflowSteps,
    );
    const failedWorkflowsTotal = workflowsMap.getFailedWorkflowsTotal(
      statementWorkflowSteps,
    );
    return (
      <ConditionalRender dependencies={[workflowsMap]}>
        <div className={`${STATEMENT_PROCESSING_BLOCK}__statement-count`}>
          {processingWorkflowsTotal ? (
            <div
              className={`${STATEMENT_PROCESSING_BLOCK}__statement-count--processing`}
            >
              {processingWorkflowsTotal}
              <FormattedMessage id="statement-processing.statement-count.processing" />
            </div>
          ) : null}
          {failedWorkflowsTotal ? (
            <div
              className={`${STATEMENT_PROCESSING_BLOCK}__statement-count--failed`}
            >
              {failedWorkflowsTotal}
              <FormattedMessage id="statement-processing.statement-count.failed" />
            </div>
          ) : null}
        </div>
      </ConditionalRender>
    );
  };

  _expandedPopover = () => {
    const { workflowsMap, statementWorkflowSteps } = this.props;
    return (
      <ConditionalRender dependencies={[workflowsMap]}>
        <div className={`${STATEMENT_PROCESSING_BLOCK}__body`}>
          <div className={`${STATEMENT_PROCESSING_BLOCK}__info`}>
            <FormattedMessage id="statement-processing.info.content" />
          </div>
          {workflowsMap.getWorkflowsListData().map((workflow) => {
            const workFlowStatus =
              workflow.getWorkFlowStatus(
                statementWorkflowSteps.getWorkflowStepsByDocProcessType(
                  workflow.documentProcessTypeEnum,
                ),
              ) || {};
            const previousWorkFlowStatus =
              workflow.getPreviousWorkflowStatus(
                statementWorkflowSteps.getWorkflowStepsByDocProcessType(
                  workflow.documentProcessTypeEnum,
                ),
              ) || {};
            return (
              <div
                className={`${STATEMENT_PROCESSING_BLOCK}__row`}
                key={workflow.revisionId}
              >
                <div className={`${STATEMENT_PROCESSING_BLOCK}__col--left`}>
                  <div
                    className={classNames(
                      `${STATEMENT_PROCESSING_BLOCK}__step`,
                      this._hasWorkFlowFailed(workFlowStatus.status, workflow)
                        ? `${STATEMENT_PROCESSING_BLOCK}__step--failed`
                        : null,
                    )}
                  >
                    {!this._hasWorkFlowFailed(
                      workFlowStatus.status,
                      workflow,
                    ) ? (
                      <FormattedMessage
                        id="statement-processing.step"
                        values={{
                          stepNumber: workFlowStatus.currentStep,
                          totalSteps: workFlowStatus.totalSteps,
                          status: workFlowStatus.statusDisplay,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="statement-processing.step-failed"
                        values={{
                          stepNumber:
                            previousWorkFlowStatus &&
                            previousWorkFlowStatus.currentStep,
                          totalSteps:
                            previousWorkFlowStatus &&
                            previousWorkFlowStatus.totalSteps,
                        }}
                      />
                    )}
                  </div>
                  <div
                    className={classNames(
                      `${STATEMENT_PROCESSING_BLOCK}__statement`,
                      this._hasWorkFlowFailed(workFlowStatus.status, workflow)
                        ? `${STATEMENT_PROCESSING_BLOCK}__statement--failed`
                        : null,
                    )}
                  >
                    {workflow.statementName}
                  </div>
                </div>
                {this._hasWorkFlowFailed(workFlowStatus.status, workflow) ? (
                  <div className={`${STATEMENT_PROCESSING_BLOCK}__col--right`}>
                    {workflow.retryable ? (
                      <Tooltip {...RETRY_TOOLTIP}>
                        <Refresh
                          id={`${STATEMENT_PROCESSING_ID_BLOCK}-tooltip-retry`}
                          role={'button'}
                          className={`${STATEMENT_PROCESSING_BLOCK}__refresh`}
                          width={REFRESH_ICON_SIZE}
                          height={REFRESH_ICON_SIZE}
                          onClick={() => {
                            this._setRetryConfirmModal();
                            this.setState({
                              currentWorkflowRevisionId: workflow.revisionId,
                              currentWorkflowStatementId: workflow.statementId,
                            });
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                ) : null}
                <Tooltip {...DELETE_PERMANENTLY_TOOLTIP}>
                  <Cancel
                    id={`${STATEMENT_PROCESSING_ID_BLOCK}-tooltip-delete-permanently`}
                    role={'button'}
                    className={`${STATEMENT_PROCESSING_BLOCK}__cancel`}
                    width={CANCEL_ICON_SIZE}
                    height={CANCEL_ICON_SIZE}
                    onClick={() => {
                      this._setCancelConfirmModal();
                      this.setState({
                        currentWorkflowRevisionId: workflow.revisionId,
                        currentWorkflowStatementId: workflow.statementId,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            );
          })}
        </div>
      </ConditionalRender>
    );
  };

  render() {
    const {
      workflowsMap,
      retryFailedDocumentUpload,
      permanentlyDeleteWorkflowRevision,
      selectedProjectID,
    } = this.props;
    const {
      isOpen,
      showRetryConfirmModal,
      showCancelConfirmModal,
      currentWorkflowRevisionId,
      currentWorkflowStatementId,
    } = this.state;
    return (
      <React.Fragment>
        <StatementWorkflowController />
        {workflowsMap.isInitialized() ? (
          <Draggable axis="both" handle=".statement-processing__header">
            <div className={`${STATEMENT_PROCESSING_BLOCK}`}>
              <div className={`${STATEMENT_PROCESSING_BLOCK}__header`}>
                <div className={`${STATEMENT_PROCESSING_BLOCK}__title`}>
                  <FormattedMessage id="statement-processing.header.title" />
                </div>
                {isOpen ? (
                  <Tooltip {...MINIMIZE_TOOLTIP}>
                    <ArrowDown
                      id={`${STATEMENT_PROCESSING_ID_BLOCK}-tooltip-minimize`}
                      role={'button'}
                      className={`${STATEMENT_PROCESSING_BLOCK}__toggle`}
                      onClick={this._toggleProcessingList}
                      width={ARROW_DOWN_SIZE}
                      height={ARROW_DOWN_SIZE}
                      style={{ transform: 'rotate(180deg)' }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip {...MAXIMIZE_TOOLTIP}>
                    <ArrowDown
                      id={`${STATEMENT_PROCESSING_ID_BLOCK}-tooltip-maximize`}
                      role={'button'}
                      className={`${STATEMENT_PROCESSING_BLOCK}__toggle`}
                      onClick={this._toggleProcessingList}
                      width={ARROW_DOWN_SIZE}
                      height={ARROW_DOWN_SIZE}
                    />
                  </Tooltip>
                )}
              </div>
              {isOpen ? this._expandedPopover() : this._minifiedPopover()}
            </div>
          </Draggable>
        ) : null}
        {showRetryConfirmModal && (
          <RetryConfirmationModal
            onClose={() => this.setState({ showRetryConfirmModal: false })}
            onRetry={() => {
              retryFailedDocumentUpload(
                currentWorkflowStatementId,
                currentWorkflowRevisionId,
                selectedProjectID,
              );
              this._setRetryConfirmModal();
            }}
          />
        )}
        {showCancelConfirmModal && (
          <CancelConfirmationModal
            onClose={() => this.setState({ showCancelConfirmModal: false })}
            onDelete={() => {
              permanentlyDeleteWorkflowRevision(
                currentWorkflowStatementId,
                currentWorkflowRevisionId,
              );
              this._setCancelConfirmModal();
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

StatementProcessing.propTypes = {
  /** Api model of all workflows for the selected project in a map of revision id to workflow */
  workflowsMap: PropTypes.instanceOf(WorkflowsMap).isRequired,
  /** Currently selected project id */
  selectedProjectID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Action that deletes a failed workflow permanently */
  permanentlyDeleteWorkflowRevision: PropTypes.func.isRequired,
  /** Action that retries a failed workflow */
  retryFailedDocumentUpload: PropTypes.func.isRequired,
  /** indicates if workflows should be expanded by default */
  expandByDefault: PropTypes.bool.isRequired,
  /** clears the project id from redux that would cause this component to be expanded by default */
  clearProjectIdWorkflowExpanded: PropTypes.func.isRequired,
  /** getting statement workflow steps metadata */
  statementWorkflowSteps: PropTypes.instanceOf(StatementWorkflowStepsData),
};

export default StatementProcessing;
