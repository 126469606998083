import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExpandableRow from 'components/common/expandable-row-component';
import ConditionalRender from 'components/util/conditional-render-component';
import classnames from 'classnames';
import { ReactComponent as AddLink } from 'icons/plus-circle.svg';
import ToolTip from 'components/common/tool-tip-component';
import shortid from 'shortid';
import { connect } from 'react-redux';
import { removeSuggestedReferenceRequest } from 'api/internal-reference-api';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import AmountAttributeDetails from 'components/common/element-amount-attributes-details-component';
import {
  UNIT_OPTIONS_MAP,
  SCALING_OPTIONS_MAP,
} from 'constants/feature/element-attribute-constants';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import {
  fetchSuggestedListed,
  internalReferenceSuggestedListError,
} from 'store/actions/internal-reference-suggestion-list-actions';
import InternalReferenceSuggestionList from 'models/api/internal-reference-suggestion-list-model';
import ElementDetails from 'models/api/element-details-api-model';
import { getOCRElementStyleByElementStatus } from 'constants/feature/element-panel-constants';

export const INTERNAL_REFERENCE_SUGGESTED_REFERENCES_BLOCK =
  'internal-reference-panel-suggested-references';
export const SUGGESTED_REFERENCES_ELEMENT_ENTRY =
  'internal-reference-suggestion-element-entry';

const SUGGESTED_REFERENCE_ID_BLOCK = 'suggested-references-id';

export const InternalReferenceSuggestedReferences = ({
  revisionId,
  elementId,
  onAddSuggestion,
  selectElementFromInternalReference,
  expandExpandableRow,
  minimizeExpandableRow,
  showExpandableRow,
  selectedElement,
  selectElementContentPanel,
  internalReferenceSuggestionList,
  fetchSuggestedListed,
  internalReferenceSuggestedListError,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <div className={`${INTERNAL_REFERENCE_SUGGESTED_REFERENCES_BLOCK}`}>
      <ConditionalRender dependencies={[internalReferenceSuggestionList]}>
        <ExpandableRow
          title={{
            id: 'internal-reference-panel.suggested-references.title',
            values: {
              suggestedReferenceCount: internalReferenceSuggestionList.count,
            },
          }}
          expandExpandableRow={expandExpandableRow}
          minimizeExpandableRow={minimizeExpandableRow}
          showExpandableRow={showExpandableRow}
          tooltipId={`${INTERNAL_REFERENCE_SUGGESTED_REFERENCES_BLOCK}__tooltip`}
        >
          {internalReferenceSuggestionList.count > 0 ? (
            internalReferenceSuggestionList.suggestions.map(
              (suggestedElement, iter) => {
                const elementDetails = {
                  scaling: SCALING_OPTIONS_MAP.get(suggestedElement.scalingId),
                  units: UNIT_OPTIONS_MAP.get(suggestedElement.unitsId),
                  period: `${suggestedElement.periodType} ${suggestedElement.fiscalYear}`,
                  entity: suggestedElement.entity,
                };
                const elementData = new ElementDetails().setLoaded({
                  response: { data: { result: suggestedElement } },
                });
                return (
                  <div
                    className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY} ${
                      selectedRow === iter
                        ? `${SUGGESTED_REFERENCES_ELEMENT_ENTRY}--selected`
                        : null
                    }`}
                    onClick={() => {
                      scrollElementIntoView({
                        elementId: suggestedElement.id,
                        sectionId: suggestedElement.sectionId,
                      });
                      selectElementFromInternalReference({
                        elementId: suggestedElement.id,
                      });
                      setSelectedRow(iter);
                      selectElementContentPanel({
                        elementIds: [suggestedElement.id],
                        color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
                      });
                    }}
                    key={shortid.generate()}
                  >
                    <div
                      className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__text-container`}
                    >
                      <div
                        className={classnames(
                          getOCRElementStyleByElementStatus(elementData),
                          `${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__value-container`,
                          suggestedElement.display !== selectedElement.display
                            ? `${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__value-container--flagged`
                            : null,
                        )}
                      >
                        {suggestedElement.display}
                      </div>
                      <ToolTip
                        id={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__section-name-tooltip-${suggestedElement.id}`}
                        text={suggestedElement.sectionname}
                        isNotInternationalized
                      >
                        <div
                          className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__section-name`}
                        >
                          {suggestedElement.sectionname}
                        </div>
                      </ToolTip>
                      <ToolTip
                        id={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__element-label-tooltip-${suggestedElement.id}`}
                        className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__element-label-tooltip`}
                        text={suggestedElement.label}
                        isNotInternationalized
                        position={'left'}
                      >
                        <div
                          className={classnames(
                            `${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__element-label`,
                            suggestedElement.label !== selectedElement.label
                              ? `${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__element-label--flagged`
                              : null,
                          )}
                        >
                          {suggestedElement.label}
                        </div>
                      </ToolTip>
                      <AmountAttributeDetails
                        className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__amount-attributes`}
                        elementDetails={elementDetails}
                        flagMap={{
                          scaling:
                            selectedElement.scaling === elementDetails.scaling,
                          units: selectedElement.units === elementDetails.units,
                          period:
                            selectedElement.period === elementDetails.period,
                          entity:
                            selectedElement.entity === elementDetails.entity,
                        }}
                      />

                      <Button
                        id={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}-remove-suggestion-${elementId}`}
                        type={BUTTON_TYPES.tertiary}
                        className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__remove-suggestion`}
                        tooltip={
                          new TooltipOptions({
                            id: `${SUGGESTED_REFERENCE_ID_BLOCK}-remove-${suggestedElement.id}`,
                            text: 'internal-reference-panel.suggested-references.remove.tooltip',
                            position: 'top',
                          })
                        }
                        onClick={async (e) => {
                          try {
                            e.stopPropagation();
                            await removeSuggestedReferenceRequest({
                              revisionId: revisionId,
                              sourceElementId: elementId,
                              suggestedElementId: suggestedElement.id,
                            });
                            fetchSuggestedListed({ revisionId, elementId });
                          } catch (error) {
                            internalReferenceSuggestedListError(error);
                          }
                        }}
                      >
                        <FormattedMessage id={'common.remove'} />
                      </Button>
                    </div>
                    <div
                      className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__button-container`}
                    >
                      <ToolTip
                        id={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}-add-link-${elementId}`}
                        className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__addlink-tooltip`}
                        text={'internal-reference-panel-element-entry.addlink'}
                        position={'left'}
                      >
                        <button
                          id={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}-addlink-${elementId}`}
                          className={`${SUGGESTED_REFERENCES_ELEMENT_ENTRY}__add-link`}
                          onClick={(e) => {
                            e.stopPropagation();
                            onAddSuggestion({
                              targetElementId: suggestedElement.id,
                              isFromSuggestion: true,
                            });
                          }}
                        >
                          <AddLink />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                );
              },
            )
          ) : (
            <div
              className={`${INTERNAL_REFERENCE_SUGGESTED_REFERENCES_BLOCK}__empty-text`}
            >
              <FormattedMessage
                id={'internal-reference-panel.suggested-references.empty-text'}
              />
            </div>
          )}
        </ExpandableRow>
      </ConditionalRender>
    </div>
  );
};

InternalReferenceSuggestedReferences.prototypes = {
  /** Specified element's id */
  elementId: PropTypes.number.isRequired,
  /** Specified revisions's id */
  revisionId: PropTypes.number.isRequired,
  /** Function for expanding the expandableRow Component */
  expandExpandableRow: PropTypes.func.isRequired,
  /** Function for hiding the expandableRow Component */
  minimizeExpandableRow: PropTypes.func.isRequired,
  /** Boolean set to false by default and toggles the expandableRow */
  showExpandableRow: PropTypes.bool.isRequired,
  /**The action that is fired when we wish to add a suggested reference to an internal reference */
  onAddSuggestion: PropTypes.func.isRequired,
  /**The action that is fired to highlight elements from the content panel after we click on them in the internal reference */
  selectElementFromInternalReference: PropTypes.func.isRequired,
  /* Action to highlight an element on content panel */
  selectElementContentPanel: PropTypes.func.isRequired,
  /* Action to call when an error occurs */
  internalReferenceSuggestedListError: PropTypes.func.isRequired,
  /* List of suggested elements for that particular IR */
  internalReferenceSuggestionList: PropTypes.instanceOf(
    InternalReferenceSuggestionList,
  ),
  /* The details of the selected element */
  selectedElement: PropTypes.instanceOf(ElementDetails),
};

const mapStateToProps = ({
  data: {
    internalReferencePanel: { internalReferenceSuggestionList },
  },
}) => ({
  internalReferenceSuggestionList,
});

const mapDispatchToProps = {
  fetchSuggestedListed,
  internalReferenceSuggestedListError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternalReferenceSuggestedReferences);
