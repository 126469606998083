import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const CHATBOT_PANEL_BLOCK = 'chatbot-panel';

export const ChatBotPanelGuidanceMessage = ({ showSecondaryMessage }) => {
  return (
    <>
      {!showSecondaryMessage && (
        <div className={`${CHATBOT_PANEL_BLOCK}__guidance-message`}>
          <div>
            <div className={`${CHATBOT_PANEL_BLOCK}__placeholder-title`}>
              <FormattedMessage id={'chatbot-body.welcome.placeholder'} />
            </div>
            <div className={`${CHATBOT_PANEL_BLOCK}__placeholder`}>
              <FormattedMessage id={'chatbot-body.placeholder'} />
            </div>
          </div>
        </div>
      )}
      {showSecondaryMessage && (
        <div className={`${CHATBOT_PANEL_BLOCK}__secondary-guidance-message`}>
          <div className={`${CHATBOT_PANEL_BLOCK}__placeholder`}>
            <FormattedMessage id={'chatbot-body.secondary.placeholder'} />
          </div>
        </div>
      )}
    </>
  );
};

ChatBotPanelGuidanceMessage.propTypes = {
  // boolean value to switch b/w secondary message
  showSecondaryMessage: PropTypes.bool.isRequired,
};

export default ChatBotPanelGuidanceMessage;
