import React from 'react';
import Tooltip from 'components/common/tool-tip-component';
import PropTypes from 'prop-types';
import SummaryElements from 'models/api/statement-summary-elements-model';
import { flaggedElementFilter } from 'constants/feature/statement-summary-constants';
import { ReactComponent as FlaggedIcon } from 'icons/flag-icon.svg';
import classnames from 'classnames';

export const STATEMENT_SUMMARY_FLAGGED = 'statement-summary-flagged';

const StatementSummaryFlaggedComponent = ({
  shouldShowFlagsSummary,
  summaryElements,
  showElementListPanel,
  disabled,
}) => {
  return (
    shouldShowFlagsSummary && (
      <div className={`${STATEMENT_SUMMARY_FLAGGED}`}>
        <Tooltip
          id={`${STATEMENT_SUMMARY_FLAGGED}-flagged`}
          text={
            disabled ? null : 'statement-summary.elements-flagged.label-tooltip'
          }
          position="bottom"
        >
          <button
            className={classnames(
              `${STATEMENT_SUMMARY_FLAGGED}-flagged,
               ${STATEMENT_SUMMARY_FLAGGED}__button`,
              { [`${STATEMENT_SUMMARY_FLAGGED}__button--disabled`]: disabled },
            )}
            onClick={() => showElementListPanel(flaggedElementFilter)}
            disabled={disabled}
          >
            {<FlaggedIcon className={`${STATEMENT_SUMMARY_FLAGGED}__icon`} />}
            <div
              className={classnames(`${STATEMENT_SUMMARY_FLAGGED}__label`, {
                [`${STATEMENT_SUMMARY_FLAGGED}__label--disabled`]: disabled,
              })}
            >
              ({summaryElements.flagged})
            </div>
          </button>
        </Tooltip>
      </div>
    )
  );
};

StatementSummaryFlaggedComponent.propTypes = {
  /** Action fired when button is clicked to open element list panel */
  showElementListPanel: PropTypes.func.isRequired,
  /** The count of elements for the selected revision id */
  summaryElements: PropTypes.instanceOf(SummaryElements).isRequired,
  /** Obj containing boolean for displaying flags option */
  shouldShowFlagsSummary: PropTypes.bool.isRequired,
};

export default StatementSummaryFlaggedComponent;
