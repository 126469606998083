import React, { memo } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ConditionalRender from 'components/util/conditional-render-component';
import ContentSearchResults from 'models/api/statement-content-search-results-api-model';
import {
  scrollOCRSelectorIntoView,
  scrollSelectorIntoView,
} from 'utils/scrolling-utils';
import {
  setContentHighlightSelector,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';

export const CONTENT_SEARCH_BLOCK = 'statement-content-search';
export const CONTENT_SEARCH_ID_BLOCK = 'statement-content-search-id';

const StatementContentSearchResults = ({
  searchResultsContent,
  searchTerm,
  setContentHighlightSelector,
  contentSectionMap,
  setActiveEntry,
  deallocateItems,
  isOCR,
}) => {
  if (!searchResultsContent.isInitiated()) {
    return (
      <div className={`${CONTENT_SEARCH_BLOCK}__cta`}>
        <FormattedMessage id="statement-nav-panel.tab.content.search.cta" />
      </div>
    );
  }

  if (
    searchResultsContent.isLoaded &&
    searchResultsContent.totalOverallResults === 0
  ) {
    return (
      <div className={`${CONTENT_SEARCH_BLOCK}__no-results`}>
        <FormattedMessage
          id="common.search.no-results"
          values={{
            searchValue: searchTerm,
          }}
        />
      </div>
    );
  }

  return (
    <ConditionalRender dependencies={[searchResultsContent]}>
      {searchResultsContent.occurrences.map((entry, index) => {
        const { sectionId, selector, context } = entry;
        const sectionData = contentSectionMap.getClosestBookmarkSection({
          sectionId,
        });
        const CONTENT_SEARCH_RESULT_BLOCK = `${CONTENT_SEARCH_BLOCK}__entry`;
        const regex = new RegExp(
          searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'),
          'gi',
        );
        return (
          <button
            id={`${CONTENT_SEARCH_ID_BLOCK}-${entry.id}`}
            key={entry.id}
            onClick={() => {
              isOCR
                ? scrollOCRSelectorIntoView(entry)
                : scrollSelectorIntoView(entry);
              deallocateItems();
              !isOCR && setContentHighlightSelector({ sectionId, selector });
              setActiveEntry(index);
            }}
            title={!isOCR ? sectionData.name : sectionId}
            className={classnames(
              CONTENT_SEARCH_RESULT_BLOCK,
              searchResultsContent.selectedIndex === index
                ? `${CONTENT_SEARCH_RESULT_BLOCK}--active`
                : null,
            )}
            dangerouslySetInnerHTML={{
              __html: isOCR ? context.replace(regex, '<b>$&</b>') : context,
            }}
          />
        );
      })}
    </ConditionalRender>
  );
};

StatementContentSearchResults.propTypes = {
  /** Search string */
  searchTerm: PropTypes.string.isRequired,
  /** Api model of search results */
  searchResultsContent: PropTypes.instanceOf(ContentSearchResults).isRequired,
  /** function fired to set the content highlight selector */
  setContentHighlightSelector: PropTypes.func.isRequired,
  /** Map of content section id to section meta-data used for populating results tooltip */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap).isRequired,
  /** Set a content search result as actively selected */
  setActiveEntry: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
};

export { StatementContentSearchResults };

const mapStateToProps = ({
  data: {
    statementContent: {
      sectionsCache: { contentSectionMap },
    },
    selectedStatement: { isOCR },
  },
}) => ({ contentSectionMap, isOCR });

const mapDispatchToProps = {
  setContentHighlightSelector,
  deallocateItems,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(StatementContentSearchResults));
