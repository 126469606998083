import BaseModel from 'models/base-model';
import BlacklineDataBySectionMap from 'models/api/blackline-data-by-section-map';
import { isNullOrUndefined } from 'utils/object-utils';

export default class BlacklineCache extends BaseModel({}) {
  getSection(sectionId) {
    return this[sectionId];
  }

  hasSection(sectionId) {
    const sectionMap = this.getSection(sectionId);
    return !isNullOrUndefined(sectionMap);
  }

  setSectionError({ sectionId, error }) {
    let errorSection;
    if (this.hasSection(sectionId)) {
      errorSection = this.getSection(sectionId).setError(error);
    } else {
      errorSection = new BlacklineDataBySectionMap().setError(error);
    }
    return this.merge({
      [sectionId]: errorSection,
    });
  }

  setSectionLoading({ sectionId }) {
    let loadingSection = this.getSection(sectionId);
    if (!loadingSection || (loadingSection && !loadingSection.isLoaded)) {
      if (this.hasSection(sectionId)) {
        loadingSection = this.getSection(sectionId).setLoading();
      } else {
        loadingSection = new BlacklineDataBySectionMap().setLoading();
      }
    }
    return this.merge({
      [sectionId]: loadingSection,
    });
  }

  setSectionLoaded({ response, sectionId }) {
    let loadedSection;
    if (this.hasSection(sectionId)) {
      loadedSection = this.getSection(sectionId).setLoaded({
        response,
        sectionId,
      });
    } else {
      loadedSection = new BlacklineDataBySectionMap().setLoaded({
        response,
        sectionId,
      });
    }

    return this.merge({
      [sectionId]: loadedSection,
    });
  }

  clearSectionBySectionIds({ removedSectionIds }) {
    let _clearedSectionsMap = {};
    removedSectionIds.forEach((id) => {
      if (this[id]) _clearedSectionsMap[id] = this[id].clearBlacklineContent();
    });

    return this.merge({
      ...this,
      ..._clearedSectionsMap,
    });
  }
}
