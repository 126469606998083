import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { FormulaList } from 'models/api/formula-list-model';
import FormulaEntry from 'components/feature/element-panel/annotations/_formula-entry-component';
import { FormattedMessage } from 'react-intl';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import Permissions from 'permissions/permissions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { ReactComponent as CopyFormulaIcon } from 'icons/copy.svg';
import { getCurrentTable } from 'utils/copy-formula-utils';
import BatchUpdateElementList from 'models/data/batch-update-element-list-model';
import Dropdown from 'components/common/dropdown-component';
import {
  FORMULA_OPTIONS,
  FORMULA_OPTIONS_MAP,
} from 'constants/feature/element-panel-constants';
import { updateFormulaTypeRequest } from 'api/formula-api';
import ConditionalRender from 'components/util/conditional-render-component';

export const ELEMENT_FORMULA_LIST_BLOCK = 'element-formula-list';
const ELEMENT_FORMULA_LIST_ID_BLOCK = 'element-formula-list-id';
export const DEFAULT_HOVERED_FORMULA_INDEX = null;
const FORMULA_BUTTON_ICON_SIZE = 28;
class ElementFormulaList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredFormulaIndex: DEFAULT_HOVERED_FORMULA_INDEX,
      isLoading: false,
      isLoaded: true,
      error: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { hoveredFormulaIndex } = this.state;
    const {
      replaceSelectedElements,
      replaceSelectedElementWithoutRemovingOld,
      elementDetails,
      formulaList,
      copyFormulaSelectedElement,
    } = this.props;
    const _shouldClearHighlightedElements =
      hoveredFormulaIndex === DEFAULT_HOVERED_FORMULA_INDEX &&
      prevState.hoveredFormulaIndex !== DEFAULT_HOVERED_FORMULA_INDEX;
    const _shouldHighlightNewElements =
      !_shouldClearHighlightedElements &&
      hoveredFormulaIndex !== prevState.hoveredFormulaIndex;
    if (_shouldClearHighlightedElements) {
      // Clear Hovered Formula elements colour
      replaceSelectedElements({ elementIds: [elementDetails.id] });
      // Switch to highlighting Elements selected for copy formula
      if (
        copyFormulaSelectedElement &&
        copyFormulaSelectedElement.selectedElementIds &&
        copyFormulaSelectedElement.selectedElementIds.length > 0
      ) {
        replaceSelectedElementWithoutRemovingOld({
          elementIds: [...copyFormulaSelectedElement.selectedElementIds],
          color: ELEMENT_HIGHLIGHT_STATES.SELECTED_FOR_COPY_FORMULA,
        });
      }
    } else if (_shouldHighlightNewElements) {
      // highlight root element and other elements
      replaceSelectedElements({
        elementIds: [
          elementDetails.id,
          ...formulaList.get(hoveredFormulaIndex).includedElementIds,
        ],
        color: ELEMENT_HIGHLIGHT_STATES.HOVERED_FROM_FORMULA,
      });
    }
  }

  _handleFormulaEntryHover = (newIndex) => {
    const { hoveredFormulaIndex } = this.state;
    if (hoveredFormulaIndex !== newIndex) {
      this.setState({
        hoveredFormulaIndex: newIndex,
      });
    }
  };

  _clearHoveredFormulaIndex = () => {
    this.setState({
      hoveredFormulaIndex: DEFAULT_HOVERED_FORMULA_INDEX,
    });
  };

  render() {
    const {
      onNew,
      formulaList,
      elementDetails,
      formulaDeleteAction,
      onEdit,
      selectedProjectId,
      readOnly,
      initcopyFormula,
      stopCopyFormula,
      isCopiedFormula,
      copiedFormula,
      copyFormulaSelectedElement,
      getTableMatrix,
      getHistoryForElementPanel,
      getElementFormulasWithoutLoading,
      numberFormatId,
      isOCR,
      getCurrentOCRTable,
      getOcrTableMatrix,
      updateElementCacheByCalloutAction,
    } = this.props;

    const _permissionToEdit =
      Permissions.Formula.canEditFormula(selectedProjectId);

    const _onCopyFormula = (copiedFormula) => {
      if (isOCR) {
        const ocrTable = getCurrentOCRTable(elementDetails);
        if (ocrTable.currentTable) {
          const tableMatrix = getOcrTableMatrix(ocrTable);
          initcopyFormula(tableMatrix, copiedFormula);
        }
      } else {
        const table = getCurrentTable(elementDetails.id);
        if (table) {
          const tableMatrix = getTableMatrix(table);
          initcopyFormula(tableMatrix, copiedFormula);
        }
      }
    };

    const _onStopCopyFormula = () => {
      stopCopyFormula();
    };

    const setFormulaPanelLoadingState = () => {
      this.setState({ isLoading: true, isLoaded: false });
    };
    const setFormulaPanelLoadedState = () => {
      this.setState({ isLoading: false, isLoaded: true });
    };

    const _saveFormulaOnSelect = async (option, formula) => {
      setFormulaPanelLoadingState();

      try {
        await updateFormulaTypeRequest({
          id: formula.id,
          revisionId: formula.revisionId,
          elementId: formula.elementId,
          type: option.value,
          formulaOverride: 1,
        });
        await getHistoryForElementPanel();
        await getElementFormulasWithoutLoading({
          elementId: formula.elementId,
        });
        setFormulaPanelLoadedState();
        updateElementCacheByCalloutAction({
          elementIds: [formula.elementId],
          showCallout: true,
        });
      } catch (error) {
        this.setState({
          isLoading: false,
          isLoaded: false,
          error: error,
        });
      }
    };

    return (
      <div className={ELEMENT_FORMULA_LIST_BLOCK}>
        <div className={`${ELEMENT_FORMULA_LIST_BLOCK}__button-container`}>
          {!readOnly && (
            <Button
              id={`${ELEMENT_FORMULA_LIST_ID_BLOCK}-formula-new-button`}
              className={`${ELEMENT_FORMULA_LIST_BLOCK}__formula-new-button`}
              type={BUTTON_TYPES.primary}
              onClick={onNew}
              disabled={!_permissionToEdit}
            >
              <FormattedMessage id={'common.new'} />
            </Button>
          )}

          {!readOnly &&
            _permissionToEdit &&
            formulaList.hasFormulas() &&
            (!isCopiedFormula ? (
              <Button.IconButton
                id={`${ELEMENT_FORMULA_LIST_ID_BLOCK}-formula-copy-all-button`}
                className={`${ELEMENT_FORMULA_LIST_BLOCK}__formula-copy-all-button`}
                type={BUTTON_TYPES.secondary}
                disabled={!formulaList.hasFormulas()}
                onClick={(e) => {
                  e.stopPropagation();
                  _onCopyFormula(formulaList);
                }}
                Icon={CopyFormulaIcon}
                iconSize={FORMULA_BUTTON_ICON_SIZE}
              >
                <FormattedMessage
                  id={'element-panel.annotation.content.formula.copy-all'}
                />
              </Button.IconButton>
            ) : (
              copyFormulaSelectedElement &&
              copyFormulaSelectedElement.selectedElementIds &&
              copyFormulaSelectedElement.selectedElementIds.length > 0 && (
                <Button
                  id={`${ELEMENT_FORMULA_LIST_ID_BLOCK}-formula-copy-all-button`}
                  className={`${ELEMENT_FORMULA_LIST_BLOCK}__formula-copy-all-button`}
                  type={BUTTON_TYPES.secondary}
                  disabled={false}
                  onClick={_onStopCopyFormula}
                >
                  <FormattedMessage
                    id={
                      'element-panel.annotation.content.formula.copy-formula-stop-pasting'
                    }
                  />
                </Button>
              )
            ))}
        </div>
        <div
          className={`${ELEMENT_FORMULA_LIST_BLOCK}__listing-container`}
          onMouseLeave={this._clearHoveredFormulaIndex}
        >
          {formulaList.hasFormulas() ? (
            formulaList.getFormulas().map((formula, index) => {
              return (
                <>
                  <FormulaEntry
                    key={formula.formulaId}
                    formulaEntry={formula}
                    units={elementDetails.units}
                    onHover={() => {
                      this._handleFormulaEntryHover(index);
                    }}
                    numberFormatId={numberFormatId}
                    formulaDeleteAction={formulaDeleteAction}
                    onClick={onEdit}
                    isSystemFlagOverride={elementDetails.isSystemOverrideFlagged()}
                    isSelectedForCopy={
                      copiedFormula &&
                      copiedFormula.data &&
                      copiedFormula.data.formulas &&
                      copiedFormula.data.formulas.length > 0 &&
                      copiedFormula.data.formulas.findIndex(
                        (copiedObj) =>
                          copiedObj.formulaId === formula.formulaId,
                      ) !== -1
                    }
                    isFormulaCopied={isCopiedFormula}
                    CopyFormulaAction={_onCopyFormula}
                    selectedProjectId={selectedProjectId}
                    disabled={!_permissionToEdit || readOnly}
                  />
                  <ConditionalRender
                    dependencies={[
                      {
                        isLoading: this.state.isLoading,
                        isLoaded: this.state.isLoaded,
                        error: this.state.error,
                      },
                    ]}
                  >
                    <div
                      className={`${ELEMENT_FORMULA_LIST_BLOCK}__formula-dropdown`}
                    >
                      <Dropdown
                        id={`${ELEMENT_FORMULA_LIST_BLOCK}__formula-dropdown`}
                        options={FORMULA_OPTIONS}
                        value={FORMULA_OPTIONS_MAP.get(formula.type)}
                        placeholder="element-panel.amount-attribute.formula.placeholder"
                        onSelectOption={(option) => {
                          _saveFormulaOnSelect(option, formula);
                        }}
                        numberOfVisibleRows={5}
                        menuClassName={`${ELEMENT_FORMULA_LIST_BLOCK}__formula-dropdown-menu`}
                        disabled={!_permissionToEdit || readOnly}
                      />
                    </div>
                  </ConditionalRender>
                </>
              );
            })
          ) : (
            <div className={`${ELEMENT_FORMULA_LIST_BLOCK}__no-formulas`}>
              <FormattedMessage
                id={'element-panel.annotation.content.no-formulas'}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ElementFormulaList.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Action fired when user clicks the new formula button */
  onNew: PropTypes.func.isRequired,
  /** Action fired when user clicks the copy all formulas button */
  onCopyAll: PropTypes.func.isRequired,
  /** The list of formulas for the current element*/
  formulaList: PropTypes.instanceOf(FormulaList).isRequired,
  /** Action fired to highlight elements in the content panel on hover of a formula entry */
  replaceSelectedElements: PropTypes.func.isRequired,
  /** Action fired to highlight elements in the content panel without replacing old highlight */
  replaceSelectedElementWithoutRemovingOld: PropTypes.func.isRequired,
  /** Action for deleting a formula from the formula list */
  formulaDeleteAction: PropTypes.func.isRequired,
  /** Action fired to get details for edit */
  onEdit: PropTypes.func.isRequired,
  /** Boolean for read only mode */
  readOnly: PropTypes.bool.isRequired,
  /** The action used to set copied formulas and Table matrix to the store*/
  initcopyFormula: PropTypes.func.isRequired,
  /** The action used to clear copied formulas and Table matrix from the store*/
  stopCopyFormula: PropTypes.func.isRequired,
  /** The action used to obtain table matrix for the concerned copy formula table*/
  getTableMatrix: PropTypes.func.isRequired,
  /** to check if user has copied formula*/
  isCopiedFormula: PropTypes.bool.isRequired,
  /** to check if user has copied formula*/
  copiedFormula: PropTypes.instanceOf(FormulaList).isRequired,
  copyFormulaSelectedElement: PropTypes.instanceOf(BatchUpdateElementList)
    .isRequired,
  /** action fired to get history for selected element */
  getHistoryForElementPanel: PropTypes.func.isRequired,
  /** action fired to get updated formulas without loading */
  getElementFormulasWithoutLoading: PropTypes.func.isRequired,
  /**numberFormatId is a number to get the format which amount field of selected statement to be displayed in element/formula panels */
  numberFormatId: PropTypes.number,
  /** returns true if statement is OCR statement */
  isOCR: PropTypes.bool,
  /** function used to get current table based on select element in OCR statement */
  getCurrentOCRTable: PropTypes.func,
  /** function used to get table matrix of current table in OCR statement */
  getOcrTableMatrix: PropTypes.func,
  /** set formula updated or added element ids  */
  updateElementCacheByCalloutAction: PropTypes.func,
};

export default ElementFormulaList;
