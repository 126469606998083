import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import AdditionalDetails from 'components/feature/notes-panel/_notes-panel-notes-additional-details-component';
import {
  OPEN_NOTE_TOOLTIP,
  CLOSED_NOTE_TOOLTIP,
  PRIORITY_HIGH_NOTE_TOOLTIP,
  PRIORITY_NORMAL_NOTE_TOOLTIP,
  DELETE_NOTE_TOOLTIP,
  GHOST_NOTE_TOOLTIP,
  REPLY_NOTE_TOOLTIP,
  EDIT_NOTE_TOOLTIP,
  DELETE_NOTE_REPLY_TOOLTIP,
  getNoteStatusIcon,
} from 'constants/feature/notes-panel-constants';
import NoteReplyInput from 'components/feature/notes-panel/notes-panel-reply-text-input-component';
import NoteEditInput from 'components/feature/notes-panel/notes-panel-note-edit-text-input-component';
import Note from 'models/data/note-model';
import {
  selectNoteFromNoteListAction,
  updateNoteAttribute,
  createOrDeleteNoteReply,
  deleteNote,
  editNote,
  setPreviousNoteBody,
} from 'store/actions/notes-panel-actions';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Permissions from 'permissions/permissions';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as OpenIcon } from 'icons/open-note-check.svg';
import { ReactComponent as ClosedIcon } from 'icons/closed-note.svg';
import { ReactComponent as PrioritySelectedIcon } from 'icons/priority-selected.svg';
import { ReactComponent as PriorityDefaultIcon } from 'icons/priority-default.svg';
import { scrollNoteIntoView } from 'utils/scrolling-utils';
import { ReactComponent as TrashCanIcon } from 'icons/trashcan.svg';
import { ReactComponent as GhostIcon } from 'icons/note-ghost.svg';
import { ReactComponent as ReplyIcon } from 'icons/reply.svg';
import { ReactComponent as EditIcon } from 'icons/edit-pencil.svg';
import SelectedStatement from 'models/api/selected-statement-model';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { deallocateItems } from 'store/actions/statement-content-actions';
import ProjectUsersList from 'models/api/project-users-list-api-model';
import { toDisplayNoteFormat } from 'utils/note-filter-util';
import { NOTE_TYPES } from 'constants/feature/notes-panel-constants';
import {
  getSectionNameBySectionId,
  getUserNameByUserId,
} from 'utils/statement-content-page-utils';

const NOTES_ENTRY_BLOCK = 'notes-entry';
const NOTES_ENTRY_ID_BLOCK = 'notes-entry-id';

const NOTE_ICON_SIZE = '24';
const OPEN_NOTE_ICON_SIZE = '20';
const OPEN_NOTE_TRASH_ICON_SIZE = '28';

const NOTES_STATUS_ICON_PROPS = {
  height: NOTE_ICON_SIZE,
  width: NOTE_ICON_SIZE,
};

const NotesEntry = ({
  note,
  isSelected,
  onNoteClick,
  updateNoteAttribute,
  onDeleteNote,
  onCreateOrDeleteNoteReply,
  currentUserId,
  selectedStatement,
  onEditNote,
  selectedProjectId,
  currRevisionNum,
  contentSectionMap,
  deallocateItems,
  projectUsersList,
  setPreviousNoteBody,
}) => {
  const [showReplyTextArea, setReplyTextArea] = useState(false);
  const [showEditTextArea, setEditTextArea] = useState(false);
  const [showNoteReplies, setShowNoteReplies] = useState(false);

  const htmlId = `${NOTES_ENTRY_ID_BLOCK}-${note.noteId}`;
  const section = contentSectionMap.getClosestBookmarkSection({
    sectionId: note.sectionId,
  });

  const sectionName = getSectionNameBySectionId(note.sectionId);
  const sectionHeader =
    contentSectionMap.isLoaded && section ? (
      section.name
    ) : sectionName ? (
      sectionName
    ) : (
      <FormattedMessage id={'notes-panel.ghost-note.section-heading-name'} />
    );

  useEffect(() => {
    if (isSelected) {
      document.getElementById(htmlId).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      setReplyTextArea(false);
      setShowNoteReplies(false);
    }
  }, [isSelected, htmlId]);

  const handleOnNoteClick = () => {
    //reset isNew to avoid malfunction of highlighting when the note is new
    note.isNew = false;
    deallocateItems();
    onNoteClick({ note, color: 'panel-selected' });

    /** Setting timeout here is the only way to force the second getElementById inside the scrollNoteIntoView to execute after the one in the useEffect above */
    setTimeout(() => {
      scrollNoteIntoView({
        pseudoElementId: note.pseudoElementId,
        sectionId: note.sectionId,
      });
    }, 0);
  };

  const readOnly = selectedStatement.isReadOnly();
  // Notes can be managed by whomsoever having manage permission + observer notes
  // has manage permission for observer, coe
  const _permissionToManageNotes =
    Permissions.Note.canManageNotes(selectedProjectId) ||
    (note.noteType === NOTE_TYPES.OBSERVER.short &&
      Permissions.Note.canManageCoeNotesType(selectedProjectId));

  return (
    <div
      id={htmlId}
      key={note.noteId}
      className={classnames(
        `${NOTES_ENTRY_BLOCK}`,
        isSelected ? `${NOTES_ENTRY_BLOCK}--selected` : null,
      )}
      onClick={handleOnNoteClick}
    >
      {showEditTextArea ? (
        <NoteEditInput
          cancelEditNote={() => setEditTextArea(false)}
          note={note}
          onEditNote={(text, noteType) => {
            onEditNote({
              newNoteModel: note.setNoteEdit({
                newNote: text,
                type: noteType,
                revision: currRevisionNum,
              }),
            });
            setEditTextArea(false);
          }}
          projectUsersList={projectUsersList.usersListForMentionsTextArea}
          setPreviousNoteBody={setPreviousNoteBody}
        />
      ) : (
        <>
          <div className={`${NOTES_ENTRY_BLOCK}__mark-note`}>
            <div className={`${NOTES_ENTRY_BLOCK}__status-icon`}>
              {getNoteStatusIcon({
                note,
                iconProps: NOTES_STATUS_ICON_PROPS,
              })}
            </div>
            {!readOnly && (
              <>
                {' |'}
                {note.isGhostNote() && (
                  <IconButton
                    className={`${NOTES_ENTRY_BLOCK}__ghost-note`}
                    iconSize={NOTE_ICON_SIZE}
                    tooltip={GHOST_NOTE_TOOLTIP}
                    Icon={GhostIcon}
                  />
                )}
                {note.isHighPriority() ? (
                  <IconButton
                    id={`${NOTES_ENTRY_ID_BLOCK}-priority-high-${note.noteId}`}
                    className={`${NOTES_ENTRY_BLOCK}__priority`}
                    iconSize={NOTE_ICON_SIZE}
                    tooltip={PRIORITY_NORMAL_NOTE_TOOLTIP(note.noteId)}
                    Icon={PrioritySelectedIcon}
                    disabled={!_permissionToManageNotes}
                    onClick={() =>
                      updateNoteAttribute({
                        newNoteModel: note.setNormalPriority(),
                      })
                    }
                    position={'left'}
                  />
                ) : (
                  <IconButton
                    id={`${NOTES_ENTRY_ID_BLOCK}-priority-normal-${note.noteId}`}
                    className={`${NOTES_ENTRY_BLOCK}__priority`}
                    iconSize={NOTE_ICON_SIZE}
                    tooltip={PRIORITY_HIGH_NOTE_TOOLTIP(note.noteId)}
                    Icon={PriorityDefaultIcon}
                    disabled={!_permissionToManageNotes}
                    onClick={() =>
                      updateNoteAttribute({
                        newNoteModel: note.setHighPriority(),
                      })
                    }
                    position={'left'}
                  />
                )}
                {note.isClosed() ? (
                  <IconButton
                    id={`${NOTES_ENTRY_ID_BLOCK}-status-closed-${note.noteId}`}
                    className={`${NOTES_ENTRY_BLOCK}__status`}
                    iconSize={OPEN_NOTE_ICON_SIZE}
                    tooltip={CLOSED_NOTE_TOOLTIP(note.noteId)}
                    Icon={ClosedIcon}
                    disabled={!_permissionToManageNotes}
                    onClick={() =>
                      updateNoteAttribute({ newNoteModel: note.setOpen() })
                    }
                    position={'left'}
                  />
                ) : (
                  <IconButton
                    id={`${NOTES_ENTRY_ID_BLOCK}-status-open-${note.noteId}`}
                    className={`${NOTES_ENTRY_BLOCK}__status`}
                    iconSize={OPEN_NOTE_ICON_SIZE}
                    tooltip={OPEN_NOTE_TOOLTIP(note.noteId)}
                    Icon={OpenIcon}
                    disabled={!_permissionToManageNotes}
                    onClick={() =>
                      updateNoteAttribute({ newNoteModel: note.setClosed() })
                    }
                    position={'left'}
                  />
                )}
              </>
            )}
          </div>
          <div
            className={classnames(
              `${NOTES_ENTRY_BLOCK}__name`,
              note.isGhostNote() && `${NOTES_ENTRY_BLOCK}__name--ghost`,
            )}
          >
            {note.noteTakerName}
          </div>
          <div className={`${NOTES_ENTRY_BLOCK}__section-header`}>
            <MultiLineEllipsis
              toolTipID={`${NOTES_ENTRY_ID_BLOCK}-${note.noteId}-section-header`}
              text={
                note.isGhostNote() ? (
                  <FormattedMessage
                    id={'notes-panel.ghost-note.section-heading-name'}
                  />
                ) : (
                  sectionHeader
                )
              }
              maxLines={1}
              isNotInternationalized={true}
              tooltipPosition={'top'}
            />
          </div>
          <div
            className={`${NOTES_ENTRY_BLOCK}__note-body`}
            dangerouslySetInnerHTML={{
              __html: toDisplayNoteFormat(note.getNote()),
            }}
          />
          <AdditionalDetails note={note} />
          {isSelected && (
            <div className={`${NOTES_ENTRY_BLOCK}__reply-container`}>
              {note.getNoteReplyCount() > 0 && (
                <div className={`${NOTES_ENTRY_BLOCK}__expand-replies`}>
                  {!showNoteReplies ? (
                    <span onClick={() => setShowNoteReplies(true)}>
                      <FormattedMessage
                        id="notes-panel.note-entry.show-reply"
                        values={{ count: note.getNoteReplyCount() }}
                      />
                    </span>
                  ) : (
                    <span onClick={() => setShowNoteReplies(false)}>
                      <FormattedMessage
                        id="notes-panel.note-entry.hide-reply"
                        values={{ count: note.getNoteReplyCount() }}
                      />
                    </span>
                  )}
                </div>
              )}
              <div className={`${NOTES_ENTRY_BLOCK}__note-edit-actions`}>
                {!readOnly && (
                  <>
                    <IconButton
                      id={`${NOTES_ENTRY_ID_BLOCK}-reply-${note.noteId}`}
                      className={`${NOTES_ENTRY_BLOCK}__reply-button`}
                      iconSize={NOTE_ICON_SIZE}
                      tooltip={showReplyTextArea ? null : REPLY_NOTE_TOOLTIP}
                      Icon={ReplyIcon}
                      onClick={() => {
                        setReplyTextArea(true);
                        setShowNoteReplies(true);
                      }}
                      disabled={showReplyTextArea || !_permissionToManageNotes}
                    />
                    <IconButton
                      id={`${NOTES_ENTRY_ID_BLOCK}-edit-${note.noteId}`}
                      className={`${NOTES_ENTRY_BLOCK}__edit-button`}
                      iconSize={NOTE_ICON_SIZE}
                      tooltip={EDIT_NOTE_TOOLTIP}
                      Icon={EditIcon}
                      onClick={() => setEditTextArea(true)}
                      disabled={
                        showEditTextArea ||
                        showReplyTextArea ||
                        !_permissionToManageNotes
                      }
                    />
                    <IconButton
                      id={`${NOTES_ENTRY_ID_BLOCK}-delete-${note.noteId}`}
                      className={`${NOTES_ENTRY_BLOCK}__delete`}
                      iconSize={OPEN_NOTE_TRASH_ICON_SIZE}
                      tooltip={DELETE_NOTE_TOOLTIP}
                      Icon={TrashCanIcon}
                      onClick={() => onDeleteNote({ note })}
                      disabled={!_permissionToManageNotes}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          {showReplyTextArea && (
            <NoteReplyInput
              cancelAddNoteReply={() => {
                setReplyTextArea(false);
                setShowNoteReplies(false);
              }}
              createNoteReply={(text) => {
                const currentDate = new Date();
                onCreateOrDeleteNoteReply({
                  newNoteModel: note.setNoteReply({
                    data: {
                      lastModified: currentDate.toISOString(),
                      reply: text,
                      userId: currentUserId,
                      revision: currRevisionNum,
                    },
                  }),
                });
                setReplyTextArea(false);
              }}
              projectUsersList={projectUsersList.usersListForMentionsTextArea}
            />
          )}
          <div className={`${NOTES_ENTRY_BLOCK}__replies-container`}>
            {isSelected && showNoteReplies && (
              <div className={`${NOTES_ENTRY_BLOCK}__reply-list`}>
                {note.getSortedNoteRepliesByLastModified().map((reply) => (
                  <div
                    key={reply.lastModified}
                    className={`${NOTES_ENTRY_BLOCK}__reply-entry`}
                  >
                    <div>
                      <div className={`${NOTES_ENTRY_BLOCK}__reply-user`}>
                        {getUserNameByUserId(reply.userId)}
                      </div>
                      <p
                        className={`${NOTES_ENTRY_BLOCK}__reply-body`}
                        dangerouslySetInnerHTML={{
                          __html: toDisplayNoteFormat(reply.reply),
                        }}
                      />
                      <div className={`${NOTES_ENTRY_BLOCK}__reply-details`}>
                        <span className={`${NOTES_ENTRY_BLOCK}__item`}>
                          {moment(reply.lastModified).format('l')}
                        </span>
                        <span className={`${NOTES_ENTRY_BLOCK}__item`}>
                          {moment(reply.lastModified).format('LT')}
                        </span>
                        <span className={`${NOTES_ENTRY_BLOCK}__item`}>
                          <FormattedMessage
                            id="notes-panel.additional-details.version-number"
                            values={{ revision: reply.revision }}
                          />
                        </span>
                      </div>
                    </div>
                    {!readOnly && (
                      <div>
                        <IconButton
                          id={`${NOTES_ENTRY_ID_BLOCK}-delete-reply-${note.noteId}`}
                          className={`${NOTES_ENTRY_BLOCK}__delete`}
                          iconSize={OPEN_NOTE_TRASH_ICON_SIZE}
                          tooltip={DELETE_NOTE_REPLY_TOOLTIP}
                          Icon={TrashCanIcon}
                          disabled={!_permissionToManageNotes}
                          onClick={() => {
                            onCreateOrDeleteNoteReply({
                              newNoteModel: note.setNoteReplyDelete(reply),
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

NotesEntry.propTypes = {
  /** This note's data model */
  note: PropTypes.instanceOf(Note).isRequired,
  /** Indicates if this entry is "selected" */
  isSelected: PropTypes.bool.isRequired,
  /** Action to fire when a note in the list is clicked */
  onNoteClick: PropTypes.func.isRequired,
  /** action fired to update note attributes like status and priority */
  updateNoteAttribute: PropTypes.func.isRequired,
  /** Function fired on click of delete note icon */
  onDeleteNote: PropTypes.func.isRequired,
  /** action to create or delete a new note reply */
  onCreateOrDeleteNoteReply: PropTypes.func.isRequired,
  /** action to edit a note   */
  onEditNote: PropTypes.func.isRequired,
  /** id of currently loged in user */
  currentUserId: PropTypes.number.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** id of the current revision */
  currRevisionNum: PropTypes.number.isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.object.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /** The model of all the users on the project */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList).isRequired,
  /** Action to store the previous note in redux */
  setPreviousNoteBody: PropTypes.func.isRequired,
};

const mapStateToProps = (
  {
    data: {
      notesPanel: { selectedNote },
      currentUser,
      selectedStatement,
      selectedProject: { id },
      revision: { revisionNumber },
      statementContent: {
        sectionsCache: { contentSectionMap },
      },
      projectUsersList,
    },
  },
  ownProps,
) => {
  return {
    isSelected: ownProps.note.noteId === selectedNote.noteId,
    currentUserId: currentUser.id,
    selectedStatement,
    selectedProjectId: id,
    currRevisionNum: revisionNumber,
    contentSectionMap,
    projectUsersList,
  };
};

const mapDispatchToProps = {
  onNoteClick: selectNoteFromNoteListAction,
  updateNoteAttribute,
  onDeleteNote: deleteNote,
  onCreateOrDeleteNoteReply: createOrDeleteNoteReply,
  onEditNote: editNote,
  deallocateItems,
  setPreviousNoteBody,
};

export { NotesEntry };
export default connect(mapStateToProps, mapDispatchToProps)(NotesEntry);
