import BaseModel from 'models/base-model';

export default class SyncScrollIds extends BaseModel({
  sourceId: null,
  targetId: null,
}) {
  get syncScrollIds() {
    return this;
  }

  clearSourceId() {
    return this.merge({
      sourceId: null,
    });
  }

  setSourceId(val) {
    return this.merge({
      sourceId: val,
    });
  }

  setTargetId(val) {
    return this.merge({
      targetId: val,
    });
  }
}
