import BaseModel from 'models/base-model';

export default class StatementSummaryDisplayOptions extends BaseModel({
  verified: true,
  reviewed: true,
  unverified: true,
  signedOff: true,
  notes: true,
  note: true,
  flags: true,
  percentage: true,
  count: false,
  viewAll: true,
  workpaper: true,
  formula: true,
  internalRef: true,
  tickmarks: true,
  comfortLetter: true,
}) {
  getDisplayOption(isOCR, optionDisplayObject) {
    let displayOptionsToSet;
    if (isOCR) {
      const { signedOff, ...rest } = optionDisplayObject;
      displayOptionsToSet = rest;
    } else {
      displayOptionsToSet = optionDisplayObject;
    }

    return displayOptionsToSet;
  }

  setIsComfortLetterEnabled(value) {
    return this.merge({
      comfortLetter: value,
    });
  }

  setDisplayOption({ key, isOCR }) {
    if (key === 'viewAll') {
      this[key] = !this[key];
      return this.setViewAllDisplayOptions(key, isOCR);
    }

    const optionDisplayObject = this.merge({
      [key]: !this[key],
    });

    const { count, percentage, viewAll, ...displayOptionsToSet } =
      this.getDisplayOption(isOCR, optionDisplayObject);

    const displayOptionsToCheckLength = Object.keys(
      displayOptionsToSet || {},
    ).length;

    let checkedCount = 0;
    let uncheckedCount = 0;

    Object.keys(displayOptionsToSet || {}).map((item) => {
      if (!displayOptionsToSet[item]) {
        uncheckedCount += 1;
      } else {
        checkedCount += 1;
      }
    });

    if (this.viewAll && displayOptionsToCheckLength === uncheckedCount) {
      this.viewAll = false;
      return this.setViewAllDisplayOptions('viewAll', isOCR);
    }

    if (!this.viewAll && displayOptionsToCheckLength === checkedCount) {
      this.viewAll = true;
      return this.setViewAllDisplayOptions('viewAll', isOCR);
    }

    return this.merge({
      [key]: !this[key],
    });
  }

  setViewAllDisplayOptions(key, isOCR) {
    if (this[key]) {
      const optionDisplayObject = {
        ...new StatementSummaryDisplayOptions(),
        count: this.count,
      };
      return this.merge(this.getDisplayOption(isOCR, optionDisplayObject));
    }
    const optionDisplayObject = {
      verified: false,
      reviewed: false,
      unverified: false,
      signedOff: false,
      notes: false,
      note: false,
      flags: false,
      percentage: true,
      count: this.count,
      viewAll: false,
      workpaper: false,
      formula: false,
      internalRef: false,
      tickmarks: false,
      comfortLetter: false,
    };
    return this.merge(this.getDisplayOption(isOCR, optionDisplayObject));
  }
}
