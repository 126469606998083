import React from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Lock } from 'icons/lock.svg';
import { STATEMENT_CONTENT_BLOCK } from './statement-content-page';
import SelectedStatement from 'models/api/selected-statement-model';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';
import { getReadOnlyBannerId } from 'utils/statement-content-page-utils';

const StatementReadOnlyBanners = ({
  selectedStatement,
  isBatchModeSelected,
  urlParams,
  workflowsMap,
}) => {
  if (selectedStatement && selectedStatement.isReadOnly()) {
    return (
      <div
        className={classnames(
          `${STATEMENT_CONTENT_BLOCK}__center-panel-banner`,
          isBatchModeSelected
            ? `${STATEMENT_CONTENT_BLOCK}__readonly-top-fix`
            : null,
        )}
      >
        <div>
          <Lock
            className={`${STATEMENT_CONTENT_BLOCK}__center-panel-banner-icon`}
          />
          <FormattedMessage
            id={getReadOnlyBannerId(selectedStatement, urlParams, workflowsMap)}
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
              br: (
                <br
                  key={`${STATEMENT_CONTENT_BLOCK}__read-only-info-brTag-${shortid.generate()}`}
                />
              ),
            }}
          />
        </div>
      </div>
    );
  }
  return null;
};

StatementReadOnlyBanners.propTypes = {
  /** url params object based on route */
  urlParams: PropTypes.object,
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement),
  /**For tracing revisions processing/failed*/
  workflowsMap: PropTypes.instanceOf(WorkflowsMap),
  /** Flag to check if batch mode is selected */
  isBatchModeSelected: PropTypes.bool,
};

export default StatementReadOnlyBanners;
