import { handleActions } from 'redux-actions';
import {
  showElementIdAction,
  hideElementIdAction,
} from 'store/actions/preview-report-page-actions';

export default handleActions(
  {
    [showElementIdAction]: () => true,
    [hideElementIdAction]: () => false,
  },
  true,
);
