import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { SelectedableElement as AnnotationDisplay } from 'constants/feature/element-panel-constants';
import { getElementDataFromElementCache } from 'utils/statement-content-page-utils';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';

const ANNOTATION_DETAILS_BLOCK = 'annotation-details';

const AnnotationDetails = ({ elementDetails, className, isCftEnabled }) => {
  const [elementData, setElementData] = useState(elementDetails);
  const { id, sectionId } = elementDetails;

  useEffect(() => {
    const elementDataFromElementCache =
      getElementDataFromElementCache({ elementId: id, sectionId }) || {};

    if (!isEqual(elementDataFromElementCache, elementDetails)) {
      setElementData(elementDataFromElementCache);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (elementDetails.isLoaded === false) {
    return null;
  }

  return (
    <div
      className={classnames(ANNOTATION_DETAILS_BLOCK, className)}
      id="element-annotation-details"
    >
      <AnnotationDisplay
        elementDetails={elementData}
        isCftEnabled={isCftEnabled}
      />
    </div>
  );
};

AnnotationDetails.protoTypes = {
  /** selected element details */
  elementDetails: PropTypes.instanceOf(ElementDetails),
  /** parent className */
  className: PropTypes.string,
  /** boolean based on comfort letter is enabled from statement level */
  isCftEnabled: PropTypes.bool,
};

export default AnnotationDetails;
