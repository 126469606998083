import React from 'react';
import PropTypes from 'prop-types';
import { AUTOMATION_CREATION_TYPE } from 'constants/feature/element-panel-constants';
import AutomaticallyIdentifiedAnnotations from 'components/common/automatically-identified-annotations';
import { shouldRestrictAutomaticIdentifier } from 'utils/statement-content-page-utils';

export const WORKPAPER_ENTRY_BLOCK = 'workpaper-entry';
export const WORKPAPER_ENTRY_ID_BLOCK = 'workpaper-entry-id';

const WorkpaperEntry = ({ workpaper, rightComponent }) => {
  return (
    <div key={workpaper.referenceNumber} className={`${WORKPAPER_ENTRY_BLOCK}`}>
      <div className={`${WORKPAPER_ENTRY_BLOCK}__info`}>
        {!shouldRestrictAutomaticIdentifier() &&
          Object.values(AUTOMATION_CREATION_TYPE).includes(
            workpaper.creationType,
          ) && (
            <AutomaticallyIdentifiedAnnotations
              tooltipId={WORKPAPER_ENTRY_ID_BLOCK}
            />
          )}
        <div className={`${WORKPAPER_ENTRY_BLOCK}__ordinal`}>
          <span className={`${WORKPAPER_ENTRY_BLOCK}__ref-num`}>
            {workpaper.referenceNumber}
          </span>
          <span className={`${WORKPAPER_ENTRY_BLOCK}__ref-name`}>
            {workpaper.referenceName}
          </span>
        </div>
      </div>
      {rightComponent}
    </div>
  );
};

WorkpaperEntry.propTypes = {
  /** Workpaper object from the workpaper list endpoint */
  workpaper: PropTypes.shape({
    workpaperRefId: PropTypes.number.isRequired,
    referenceName: PropTypes.string,
    referenceNumber: PropTypes.string.isRequired,
  }),
  /** Component used to children to the right of the main content, displayed as flex container with content*/
  rightComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default WorkpaperEntry;
