import React, { useEffect, useState, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { toggleWorkPaperPanel } from 'store/actions/panel-controller-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  fetchWorkpaperListForRevision,
  deleteWorkpaper,
  fetchElementsFromWorkpaper,
} from 'store/actions/workpaper-toolkit-list-actions';
import WorkpaperToolkitListPanel from 'models/api/workpaper-toolkit-list-panel-api-model';
import ToolkitDeleteWorkpaperModal from 'components/feature/toolkit/workpaper-toolkit-panel/toolkit-delete-workpaper-modal-component';
import ToolkitEditWorkpaperModal from 'components/feature/toolkit/workpaper-toolkit-panel/toolkit-edit-workpaper-modal-component';
import Loading from 'components/common/loading-component';
import { workPaperElementIdsUpdatedOrDeleted } from 'store/actions/statement-content-annotation-creation-actions';
export const WORKPAPER_PANEL_BLOCK = 'workpaper-toolkit-panel';
const WorkPaperPanelContent = lazy(() =>
  import(
    'components/feature/toolkit/workpaper-toolkit-panel/toolkit-workpaper-list-content-component'
  ),
);
export const WorkPaperPanel = ({
  show,
  onCancelPanel,
  workpaperList,
  revisionId,
  fetchWorkpaperList,
  deleteWorkpaper,
  fetchElementsFromWorkpaper,
  workPaperElementIdsUpdatedOrDeleted,
  isOCR,
}) => {
  const [workpaperToEdit, setWorkPaperToEdit] = useState(null);
  const [workpaperToDelete, setWorkpaperToDelete] = useState(null);

  useEffect(() => {
    if (show && !isNullOrUndefined(revisionId)) {
      fetchWorkpaperList({ revisionId });
    }
  }, [show, revisionId, fetchWorkpaperList]);

  const _handleWorkpaper = ({ workpaper }) => {
    setWorkPaperToEdit(workpaper);
  };

  const _handleWorkpaperDeleteClick = ({ workpaper }) => {
    setWorkpaperToDelete(workpaper);
  };

  return (
    <>
      <Flyout className={WORKPAPER_PANEL_BLOCK} position={'left'} show={show}>
        <FlyoutHeader enableClose onClose={onCancelPanel}>
          <h3 className={`${WORKPAPER_PANEL_BLOCK}__title`}>
            <FormattedMessage id={'workpaper-panel.title'} />
          </h3>
        </FlyoutHeader>
        <FlyoutPanelContent>
          <Suspense fallback={<Loading />}>
            <WorkPaperPanelContent
              workpaperList={workpaperList}
              onEditWorkpaper={_handleWorkpaper}
              onDeleteWorkpaper={_handleWorkpaperDeleteClick}
              fetchElementsFromWorkpaper={fetchElementsFromWorkpaper}
            />
          </Suspense>
        </FlyoutPanelContent>
      </Flyout>
      {workpaperToDelete && (
        <ToolkitDeleteWorkpaperModal
          onDelete={() => {
            deleteWorkpaper({ workpaper: workpaperToDelete });
            setWorkpaperToDelete(null);
          }}
          onClose={() => {
            setWorkpaperToDelete(null);
          }}
        />
      )}
      {workpaperToEdit && (
        <ToolkitEditWorkpaperModal
          workpaperToEdit={workpaperToEdit}
          onCancel={() => {
            setWorkPaperToEdit(null);
          }}
          onSave={() => {
            setWorkPaperToEdit(null);
            workPaperElementIdsUpdatedOrDeleted(workpaperToEdit, isOCR);
          }}
        />
      )}
    </>
  );
};

WorkPaperPanel.propTypes = {
  /** Indicates if the batch panel should be shown */
  show: PropTypes.bool.isRequired,
  /** List of workpapers for current revision id */
  workpaperList: PropTypes.instanceOf(WorkpaperToolkitListPanel).isRequired,
  /** revision ID of the statement */
  revisionId: PropTypes.number.isRequired,
  /** action fetch the list of workpapers */
  fetchWorkpaperList: PropTypes.func.isRequired,
  /** action to close the panel */
  onCancelPanel: PropTypes.func.isRequired,
  /** action to delete a WP */
  deleteWorkpaper: PropTypes.func.isRequired,
  /**action method fired to fetch elements related to workpaper id */
  fetchElementsFromWorkpaper: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    revision,
    workpaperToolkit,
    selectedStatement: { isOCR },
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  show: left === LEFT_PANELS.WORKPAPER,
  revisionId: revision.id,
  workpaperList: workpaperToolkit,
  isOCR,
});

const mapDispatchToProps = {
  onCancelPanel: toggleWorkPaperPanel,
  fetchWorkpaperList: fetchWorkpaperListForRevision,
  deleteWorkpaper,
  fetchElementsFromWorkpaper,
  workPaperElementIdsUpdatedOrDeleted,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkPaperPanel);
