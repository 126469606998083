import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const getElementDetailsRequest = ({ revisionId, elementId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}`,
    )
    .then((response) => {
      return response;
    });

export const getElementsBySectionRequest = ({
  statementId,
  revisionId,
  sectionId,
  contextKey,
  contextValue,
  isPreviewPage,
}) => {
  const params = isPreviewPage
    ? {
        includeChildSections: false,
        includePreviewSequence: true,
        excludeElement: true,
      }
    : {
        includeChildSections: false,
      };
  return withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .get(
      `/tieout/statements/${getStatementIdUtil(
        statementId,
      )}/revisions/${revisionId}/section/${sectionId}`,
      { params },
    )
    .then((response) => {
      return response;
    });
};

export const updateElementRequest = ({
  revisionId,
  elementId,
  elementDetails,
}) => {
  return withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}`,
      elementDetails.toEditApiFormat(),
      {
        params: {
          since: Date.now().toString(),
        },
      },
    )
    .then((response) => {
      return response;
    });
};

export const batchUpdateElementRequest = ({
  revisionId,
  updatesModel,
  elementIds,
}) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements`,
    updatesModel.toApiFormat({ elementIds }),
  );
};

export const documentChangesSinceRequest = ({ revisionId, sinceTimestamp }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/changes`,
    {
      params: {
        since: sinceTimestamp,
      },
    },
  );

export const excludeElementFromElementListRequest = ({
  revisionId,
  elementId,
}) => {
  return withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/exclude
    `,
    )
    .then((response) => {
      return response;
    });
};

export const addElementRequest = ({ revisionId, elementDetails }) => {
  return withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/`,
      elementDetails,
    )
    .then((response) => {
      return response;
    });
};
export const addElementRequestOCR = ({ revisionId, elementDetails }) => {
  return withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/ocrElements`,
      elementDetails,
    )
    .then((response) => {
      return response;
    });
};

export const searchStatementElementsRequest = ({
  revisionId,
  searchTerm,
  filters,
}) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elementFilter`,
    {
      searchElementText: searchTerm,
      ...filters.toApiFormat(),
      ...filters.getPageParams(),
    },
  );
};

export const getUpdatedElementsByIdsRequest = ({
  revisionId,
  elementIds,
  filters,
}) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elementFilter`,
    {
      elementIds,
      ...filters.toApiFormat(),
    },
  );
};

export const getAllRevisionElementsRequest = ({ revisionId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/summary`,
    )
    .then((response) => {
      return response;
    });

export const getDiffRevisionElementsRequest = ({ revisionId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/diff`,
    )
    .then((response) => {
      return response;
    });

export const getHistoryForElementRequest = ({ elementId, statementId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${statementId}/elements/${elementId}/history`,
  );

export const getElementsByWorkpaperReference = ({ revisionId, wpRefId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/wpref/${wpRefId}`,
    )
    .then((response) => {
      return response;
    });

export const getAllElements = ({ revisionId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/preview/elements`,
    )
    .then((response) => {
      return response;
    });
