import { createAction } from 'redux-actions';

export const setStatementAnnotationsPosition = createAction(
  'STATEMENT_ANNOTATIONS_POSITION',
);

export const setAnnotationsPosition =
  ({ position }) =>
  (dispatch) => {
    dispatch(setStatementAnnotationsPosition({ position }));
  };
