import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const sectionReviewRequest = ({ revisionId, sectionIds }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/add/sectionsReviewsBulk`,
    {
      sectionIds,
      revisionId,
    },
  );

export const sectionUnReviewRequest = ({ revisionId, sectionIds, userIds }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/delete/sectionsReviewsBulk`,
    {
      revisionId,
      sectionIds,
    },
  );
