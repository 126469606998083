import BaseModel from 'models/base-model';
export default class ReportPreviewPage extends BaseModel({
  selectedElementId: null,
  isClickedOnReport: null,
}) {
  get selectedElementDetails() {
    return this;
  }

  setSelectedElementDetails({ selectedElementId, isClickedOnReport }) {
    return this.merge({ selectedElementId, isClickedOnReport });
  }
}
