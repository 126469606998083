import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/common/loading-component';
import SectionContentModel from 'models/api/section-content-api-model';
import { CENTER_PANEL_ID } from 'pages/statement-content-page';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import Permissions from 'permissions/permissions';
import {
  initTableControls,
  getselectedTextLabel,
} from 'utils/section-rendering-utils';
import { isElementReportPreviewPage } from 'utils/ocr-annotation-utils';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { SIDE_BY_SIDE_VIEW_LEFT_BLOCK, REPORT_PREVIEW_PAGE_HTML_CONTAINER_ID } from 'constants/feature/statement-content-constants';

export const SEGMENT_BLOCK = 'statement-content-segment';
const SEGMENT_LOADING_HEIGHT = 1000; // px
const CONTEXT_MENU_MARGIN = 10;
const MAXIMUM_SELECTION_CHARACTERS = 5000;

class SectionContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldShow: false,
      height: SEGMENT_LOADING_HEIGHT,
    };
    this.segmentRef = createRef();
    this.sectionSegmentRef = createRef(false);
    this.sectionSegmentRef.current = false;
    this.intersectionObserver = null;
  }
  _userCanChangeContent =
    Permissions.Statement.canChangeContent() ||
    Permissions.Note.canManageCoeNotesType(this.props.selectedProjectId);

  componentDidMount() {
    const {
      section,
      shouldRenderImmediately,
      sectionId,
      addToCurrentSectionIds,
      removeFromCurrentSectionIds,
    } = this.props;
    this._initializeViewportDetection();

    if (!this.intersectionObserverTOC) {
      this.intersectionObserverTOC = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              addToCurrentSectionIds(sectionId);
            } else {
              removeFromCurrentSectionIds(sectionId);
            }
          });
        },
        {
          threshold: 0.05,
        },
      );

      this.intersectionObserverTOC.observe(this.segmentRef.current);
    }

    if (section.isLoaded) {
      initTableControls();
    }
    if (shouldRenderImmediately && !section.isLoaded) {
      this._fetchSectionContent();
    }
  }

  componentWillUnmount() {
    this.intersectionObserver.unobserve(this.segmentRef.current);

    if (this.intersectionObserverTOC) {
      this.intersectionObserverTOC.unobserve(this.segmentRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { section, isContentPanelScrolling } = this.props;
    const { shouldShow } = this.state;

    const _scrollingHasStopped =
      prevProps.isContentPanelScrolling && !isContentPanelScrolling;
    const _shouldFetchSection =
      !this.sectionSegmentRef.current &&
      shouldShow &&
      !section.isLoaded &&
      _scrollingHasStopped;

    if (_shouldFetchSection) {
      this._fetchSectionContent();
      this.sectionSegmentRef.current =  true;
    }

    initTableControls();

    this._setSectionHeight();
  }

  _enforceSelectionLengthLimit = (selection, intl) => {
    if (
      selection.getRangeAt(0).toString().length <= MAXIMUM_SELECTION_CHARACTERS
    ) {
      return true;
    } else {
      toast.warn(
        intl.formatMessage({
          id: 'section-content.too-many-characters.warning',
        }),
      );

      selection.removeAllRanges();
      return false;
    }
  };

  _validateSelection = (selection) =>
    selection.type === 'Range' &&
    selection.toString().trim() !== '' &&
    this.segmentRef.current.contains(selection.focusNode);

  handleMouseUp = (e) => {
    // somewhat hackey solution for this bug https://symphonyvsts.visualstudio.com/TieOut2/_workitems/edit/813709
    // wrapping the block in setTimeout 0 forces execution to wait for the current eventloop cycle to go through
    // otherwise the browser and therefore `_validateSelection` would incorrectly report selection exists although the selection is cleared out immediately
    e.persist();
    setTimeout(() => {
      const selection = window.getSelection();
      const selectionLabel = getselectedTextLabel(selection);
      const { sectionId, onStatementContextMenu, intl, blacklineViewMode } =
        this.props;

      if (
        this._validateSelection(selection) &&
        this._enforceSelectionLengthLimit(selection, intl) &&
        !blacklineViewMode &&
        this._userCanChangeContent
      ) {
        onStatementContextMenu({
          position: {
            x: e.clientX + CONTEXT_MENU_MARGIN,
            y: e.clientY + CONTEXT_MENU_MARGIN,
          },
          sectionId,
          selection: selection,
          selectionLabel,
        });
        e.stopPropagation();
      }
    }, 0);
  };

  _initializeViewportDetection = () => {
    const { setSectionInView, clearSectionInView, leftSideBySideView } =
      this.props;
    const url = window.location.pathname;
    const isSideBySideView = url.includes(
      ROUTE_CONSTANTS.SIDE_BY_SIDE,
    );
    const isReportPreviewPage = url.includes(
      ROUTE_CONSTANTS.REPORT_PREVIEW,
    );

    let contentPanelId;
    if(isSideBySideView && leftSideBySideView) {
      contentPanelId = SIDE_BY_SIDE_VIEW_LEFT_BLOCK;
    } else if(isReportPreviewPage) {
      contentPanelId = REPORT_PREVIEW_PAGE_HTML_CONTAINER_ID;
    } else {
      contentPanelId = CENTER_PANEL_ID;
    }
    const config = {
      root: document.getElementById(contentPanelId), //the element that we check for intersection with
      /**
       * similar to a CSS margin, extends the vertical intersection
       * zone of the viewport with sections by 1000px vertically to pre-load sections
       * just before the get into view from either scrolling up or down, for a more
       * smooth viewing experience.
       **/
      rootMargin: `${SEGMENT_LOADING_HEIGHT}px 0px`,
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!this.state.shouldShow) {
            setSectionInView({ sectionId: this.props.sectionId });
            this.setState({
              shouldShow: true,
            });
          }
        } else {
          if (this.state.shouldShow) {
            this.setState({
              shouldShow: false,
            });
            clearSectionInView({ sectionId: this.props.sectionId });
            this.sectionSegmentRef.current = false;
          }
        }
      });
    }, config);

    this.intersectionObserver.observe(this.segmentRef.current);
  };

  _fetchSectionContent = () => {
    const { fetchAllSectionRenderingData, sectionId } = this.props;
    const isReportPreviewPage = isElementReportPreviewPage(
      window.location.pathname,
    );
    fetchAllSectionRenderingData({ sectionId, isReportPreviewPage });
  };

  _setSectionHeight = () => {
    const { height, shouldShow } = this.state;
    const { section } = this.props;
    const currSegRef = this.segmentRef.current;
    const newHeight = currSegRef.scrollHeight;
    if (shouldShow && section.isLoaded && height !== newHeight) {
      this.setState({
        height: newHeight,
      });
    }
  };

  _getUnrenderedSection = (children) => {
    const { height } = this.state;
    return (
      <div
        className={`${SEGMENT_BLOCK}__unrendered`}
        style={{
          height, // ensure unrendered height is always same size as content
        }}
      >
        {children}
      </div>
    );
  };

  _renderSegment = () => {
    const { shouldShow } = this.state;
    const { section } = this.props;
    if (shouldShow && section.isLoaded) {
      return section.processedReactSegment;
    } else if (shouldShow) {
      return this._getUnrenderedSection(<Loading />);
    }

    return this._getUnrenderedSection();
  };

  render() {
    const { height } = this.state;
    const { sectionId, reactSectionPrefix } = this.props;
    return (
      <React.Fragment>
        <div
          ref={this.segmentRef}
          className={`${SEGMENT_BLOCK}`}
          data-height={height}
          style={{ width: 'fit-content', minWidth: '100%' }}
          data-section-id={sectionId}
          id={`${reactSectionPrefix}${sectionId}`} // MUST BE sectionId prop from sectionIdList, the section may not be fetched yet so section.sectionId could be null
          onMouseUp={this.handleMouseUp}
        >
          {this._renderSegment()}
        </div>
      </React.Fragment>
    );
  }
}

SectionContent.propTypes = {
  /** Section the segment is responsible for fetching and rendering */
  section: PropTypes.instanceOf(SectionContentModel),
  /** Bool representing if the user is scrolling*/
  isContentPanelScrolling: PropTypes.bool.isRequired,
  /** Number representing the section id, loaded from the sectionIdList which is the array of sectionIds in the order they should be displayed
   * the sectionId list is fetched before anything on the page so if we need sectionId for something, better to rely on this prop */
  sectionId: PropTypes.number.isRequired,
  /** Action that fetches the currently in view section content and element annotation data */
  fetchAllSectionRenderingData: PropTypes.func.isRequired,
  /** Action that sets the current section in view to redux*/
  setSectionInView: PropTypes.func.isRequired,
  /** Flag for whether content should be renderd immediately or wait till in view */
  shouldRenderImmediately: PropTypes.bool.isRequired,
  /** Actions fired when a section is no longer in view */
  clearSectionInView: PropTypes.func.isRequired,
  /** Get blacklineViewMode from Redux */
  blacklineViewMode: PropTypes.bool,
  /**Project Id */
  selectedProjectId: PropTypes.string.isRequired,
  /**string to prefix section id with a constant to identify type (section or element etc.) of node. */
  reactSectionPrefix: PropTypes.string.isRequired,
};

export default injectIntl(SectionContent);
