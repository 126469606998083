import { isNullOrUndefined } from 'utils/object-utils';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';

/**
 * Determines, based on the ELEMENT_SELECT_MODE provided, if we are in a select mode that supports
 * drag select
 * @param {object} mode one of ELEMENT_SELECT_MODES constant values
 */
export const isRangeSelectMode = (mode) => {
  const {
    BATCH,
    RANGE,
    FORMULA,
    COPY_FORMULA,
    BATCH_WITH_BANNER,
    SIDE_BY_SIDE,
  } = ELEMENT_SELECT_MODES;
  return (
    !isNullOrUndefined(mode) &&
    (mode.id === BATCH.id ||
      mode.id === BATCH_WITH_BANNER.id ||
      mode.id === RANGE.id ||
      mode.id === FORMULA.id ||
      mode.id === COPY_FORMULA.id ||
      mode.id === SIDE_BY_SIDE.id)
  );
};

export const shouldElementBatchSelection = (mode) => {
  const { COPY_FORMULA } = ELEMENT_SELECT_MODES;
  return !isNullOrUndefined(mode) && mode.id === COPY_FORMULA.id;
};

/**
 * Determines, based on the ELEMENT_SELECT_MODE provided, if we are in a select mode that only
 * allows for single element selection/clicking (i.e. drag select is not supported)
 * @param {object} mode one of ELEMENT_SELECT_MODES constant values
 */
export const isSingleSelectOnlyMode = (mode) => {
  const { DEFAULT, INTERNAL_REFERENCE } = ELEMENT_SELECT_MODES;
  return (
    !isNullOrUndefined(mode) &&
    (mode.id === DEFAULT.id || mode.id === INTERNAL_REFERENCE.id)
  );
};

/** Determines if the current mode should have the alternative cursor
 * @param {object} mode one of ELEMENT_SELECT_MODES constant values
 */
export const isModeForAddCursor = (mode) => {
  const {
    BATCH,
    BATCH_WITH_BANNER,
    FORMULA,
    INTERNAL_REFERENCE,
    COPY_FORMULA,
  } = ELEMENT_SELECT_MODES;
  return (
    !isNullOrUndefined(mode) &&
    (mode.id === BATCH.id ||
      mode.id === BATCH_WITH_BANNER.id ||
      mode.id === FORMULA.id ||
      mode.id === INTERNAL_REFERENCE.id ||
      mode.id === COPY_FORMULA.id)
  );
};

/**
 * Useful in `componentDidUpdate` lifecycle for making decisions in components
 * when the select mode changes
 * @param {object} param.prevMode old element-select-mode
 * @param {object} param.newMode new element-select-mode
 */
export const isNowDragSelectMode = ({ prevMode, newMode }) => {
  return !isRangeSelectMode(prevMode) && isRangeSelectMode(newMode);
};

/**
 * Useful in `componentDidUpdate` lifecycle for making decisions in components
 * when the select mode changes
 * @param {object} param.prevMode old element-select-mode
 * @param {object} param.newMode new element-select-mode
 */
export const isNowNonDragSelectMode = ({ prevMode, newMode }) => {
  return isRangeSelectMode(prevMode) && !isRangeSelectMode(newMode);
};
