import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const getRevisionsSectionsRequest = ({
  statementId,
  revisionId,
  onlyBookmarkSection = true,
  asTreeList = false,

  // for pages like side by side, we want to fetch section values for different (other than selected project) project,
  // hence we can pass context key and context value to make api call in desired project geo.

  // If context key is not passed, then by default call will be made in selected project geo
  contextKeyForApiCall,
  contextValue,
}) =>
  withDefaultContextAsSelectedProject({
    contextKey: contextKeyForApiCall,
    contextValue,
  }).get(
    `/tieout/statements/${getStatementIdUtil(
      statementId,
    )}/revisions/${revisionId}/sections`,
    {
      params: {
        bookmarkSection: onlyBookmarkSection ? 1 : 0,
        treeList: asTreeList ? 1 : 0,
      },
    },
  );

export const updateRevisionsSectionRequest = (
  revisionId,
  sectionId,
  sectionName,
  entity,
) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/section`,
    {
      sectionId: sectionId,
      sectionName: sectionName,
      entity: entity,
    },
  );

export const deleteRevisionsSectionRequest = (
  revisionId,
  sectionId,
  sectionName,
  sectionReviewed,
) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/section/delete`,
    {
      sectionId: sectionId,
      sectionName: sectionName,
      sectionReviewed: sectionReviewed,
    },
  );

export const reviewSectionRequest = (
  revisionId,
  sectionId,
  isSectionAlreadyReviewed,
) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${sectionId}/reviews/add/reviewstatus/${
      isSectionAlreadyReviewed ? 1 : 0
    }`,
  );

export const deleteReviewSectionRequest = (
  revisionId,
  sectionId,
  isSectionAlreadyReviewed,
) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${sectionId}/reviews/delete/reviewstatus/${
      isSectionAlreadyReviewed ? 1 : 0
    }`,
  );

export const getSectionReviewersRequest = ({
  revisionId,
  contextKey,
  contextValue,
  statementId,
}) =>
  // for side by side page we want to make request in other project than currently selected project, so we need context key and value
  withDefaultContextAsSelectedProject({ contextKey, contextValue }).get(
    `/tieout/statements/${getStatementIdUtil(
      statementId,
    )}/revisions/${revisionId}/reviews`,
  );

export const getSectionHistoryRequest = ({ revisionId, sectionId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${sectionId}/history`,
  );
};

export const getSectionAssignments = ({ revisionId, sectionId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${sectionId}/assignments`,
  );
};

export const createRevisionSectionAssignment = ({
  revisionId,
  sectionId,
  userIds,
}) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/assignments`,
    {
      revisionId: revisionId,
      sectionId: sectionId,
      userIds: userIds,
    },
  );
};

export const getAllSectionAssignments = ({ revisionId, statementId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil(
      statementId,
    )}/revisions/${revisionId}/assignments`,
  );
};

export const getElementsCountBySectionID = ({ revisionId, sectionId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${sectionId}/summary/elements`,
  );
};

export const getNotesCountBySectionID = ({ revisionId, sectionId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/sections/${sectionId}/summary/notes`,
  );
};
