import ApiModel from 'models/api-model';
import {
  getLanguagesMenuOptions,
  getDateFormatMenuOptions,
  getNumberFormatMenuOptions,
} from 'constants/feature/statement-creation-constants';
import { isNullOrUndefined } from 'utils/object-utils';

export default class StatementCreationPageData extends ApiModel({
  data: {
    languages: [],
    dateFormat: [],
    numberFormat: [],
  },
}) {
  languageMenuOptions(isPdfSelected) {
    return !isNullOrUndefined(this.data.languages)
      ? getLanguagesMenuOptions(this.data.languages, isPdfSelected)
      : [];
  }

  getLanguageDropdownValues(isPdfSelected, searchTerm) {
    if (searchTerm.length) {
      const languageData = this.languageMenuOptions(isPdfSelected);
      return Object.values(languageData).filter(
        (obj) =>
          obj.title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
      );
    }
  }

  get languagesMenuOptions() {
    return !isNullOrUndefined(this.data.languages)
      ? getLanguagesMenuOptions(this.data.languages)
      : [];
  }

  get dateFormatMenuOptions() {
    return !isNullOrUndefined(this.data.dateFormat)
      ? getDateFormatMenuOptions(this.data.dateFormat)
      : [];
  }

  get numberFormatMenuOptions() {
    return !isNullOrUndefined(this.data.numberFormat)
      ? getNumberFormatMenuOptions(this.data.numberFormat)
      : [];
  }

  selectedLanguageValue(languageValue) {
    if (isNullOrUndefined(languageValue)) {
      return [];
    } else {
      return (
        this.languagesMenuOptions &&
        this.languagesMenuOptions.filter(
          (item) => item.value === languageValue,
        )[0]
      );
    }
  }

  selectedDateFormatValue(dateFormatValue) {
    if (isNullOrUndefined(dateFormatValue)) {
      return [];
    } else {
      return (
        this.dateFormatMenuOptions &&
        this.dateFormatMenuOptions.filter(
          (item) => item.id === dateFormatValue,
        )[0]
      );
    }
  }

  selectedNumberFormatValue(numberFormatValue) {
    if (isNullOrUndefined(numberFormatValue)) {
      return [];
    } else {
      return (
        this.numberFormatMenuOptions &&
        this.numberFormatMenuOptions.filter(
          (item) => item.value === numberFormatValue,
        )[0]
      );
    }
  }

  processResponse({ result }) {
    return {
      data: {
        languages: result && result.languageDetails,
        dateFormat: result && result.dateFormats,
        numberFormat: result && result.numberFormats,
      },
    };
  }
}
